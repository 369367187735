import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { Sort } from '@angular/material/sort';
import { FormGroup } from '@angular/forms';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class PGIService {

  public hideTopMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public agencyId: string = '';
  public isWebview: boolean = false;

  public bookoverviewData: any = undefined;
  public targetedOppportunitiesData: any = undefined;
  public productionData: any = undefined;
  public profitablityRetentionData: any = undefined;

  constructor(private http: HttpClient, private requestService: RequestService) { }

  resetPagesData() {
    this.profitablityRetentionData = undefined;
    this.bookoverviewData = undefined;
    this.productionData = undefined;
    this.targetedOppportunitiesData = undefined;

  }

  async getPGIOverview(filters: any = {}, getCache: boolean = false, agencyId = this.requestService.currentUser?.mirror_1__1): Promise<void> {
    // console.log(postData);
    if (!agencyId) {
      this.requestService.logout();

    }
    return new Promise((resolve, reject) => {
      if (!this.bookoverviewData || !getCache) {
        let postData: any = {};
        let userID = this.requestService.currentUser?.id;

        // if (postData.agency) {
        //   postData.agency_name = postData.agency;
        // }

        postData.agency = agencyId;
        postData.agency_name = filters.agency_name;
        postData.agent = filters.agent;
        postData.carrier = filters.carrier;
        postData.state = filters.state;
        postData.productline = filters.productline;
        postData.lob = filters.lob;
        postData.userid = userID;

        if (filters.dateType) {
          if (filters.dateType == 'CUSTOM') {
            postData.date = `${moment(filters.startDate).format('yyyyMM')}-${moment(filters.endDate).format('yyyyMM')}`;
          }
          else {
            postData.date = filters.dateType;
          }
        }
        // postData = {
        //   agency: agencyID, // 'y7q56QsPOb98kC_yCtrmXN5DR4_6-z_wH4xqykpxaYY1',
        //   // agency: 'WplIQFgC0iUOXClzDYn6V5BLDABzqHesm7Q7RUZUGpg1',
        //   // lob: 'Homeowners',
        //   // state: 'Colorado',
        //   // productline: 'Personal',
        //   // agent: 'Osmaylin Henriquez',
        //   // carrier: 'Allied',
        //   // test: false,
        // };

        this.requestService.postRequest('pgi', '', postData, (data, error) => {
          if (data) {
            this.bookoverviewData = data;
            resolve(data);
          }
          else {
            reject(error);
          }
        });
      }
      else {
        resolve(this.bookoverviewData);
      }
    });
  }

  async getPGIOverviewFilter(agencyName: string = '', lob: any = [], state: any = [], productline: any = [], agent: any = [], carrier: any = []): Promise<void> {
    return new Promise((resolve, reject) => {
      let agencyID = this.requestService.currentUser?.mirror_1__1;
      let userID = this.requestService.currentUser?.id;
      let isAgent = this.requestService.isUserAgent();
      if (isAgent) {
        agencyName = this.requestService.currentUser?.link_to_agencies5;
      }

      let postData: any = {
        agency: agencyID,
        userid: userID
        // lob: 'Homeowners',
        // state: 'Colorado',
        // productline: 'Personal',
        // agent: 'Osmaylin Henriquez',
        // carrier: 'Allied',
        // test: false,
      };

      if (agencyName) {
        postData.agency_name = agencyName;
      }

      if (lob?.length) {
        postData.lob = lob;
      }

      if (state?.length) {
        postData.state = state;
      }

      if (productline?.length) {
        postData.productline = productline;
      }

      if (agent?.length) {
        postData.agent = agent;
      }

      if (carrier?.length) {
        postData.carrier = carrier;
      }

      this.requestService.postRequest('pgifilter', '', postData, (data, error) => {
        if (data) {
          resolve(data);
        }
        else {
          reject(error);
        }
      });
    });
  }


  async getProfitabilityRetentionOverview(getCache: boolean = false): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this.profitablityRetentionData || !getCache) {
        let agencyID = this.requestService.currentUser?.mirror_1__1;
        let userID = this.requestService.currentUser?.id;
        let postData = {
          agency: agencyID,
          userid: userID

        };

        this.requestService.postRequest('profitabilityall', '', postData, (data, error) => {
          if (data) {
            this.profitablityRetentionData = data;
            resolve(data);
          }
          else {
            reject(error);
          }
        });
      }
      else {
        resolve(this.profitablityRetentionData);
      }
    });
  }

  async getPGITargetedOpportunities(getCache: boolean = false): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this.targetedOppportunitiesData || !getCache) {
        let agencyID = this.requestService.currentUser?.mirror_1__1;
        let userID = this.requestService.currentUser?.id;
        let postData = {
          agency: agencyID,
          userid: userID

        };

        this.requestService.postRequest('opportunitiesAll', '', postData, (data, error) => {
          if (data) {
            this.targetedOppportunitiesData = data;
            resolve(data);
          }
          else {
            reject(error);
          }
        });
      }
      else {
        resolve(this.targetedOppportunitiesData);
      }
    });
  }

  async getOpportunitiesDetails(
    pageIndex: number,
    pageSize: number,
    type: string,
    dimension: string,
    sort: Sort
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let agencyID = this.requestService.currentUser?.mirror_1__1;
      let userID = this.requestService.currentUser?.id;
      let postData: any = {
        agency: agencyID,
        userid: userID,
        page_num: pageIndex,
        page_size: pageSize,
        type: type,
        dimension: dimension,
      };

      if (sort) {
        postData.sort_by = sort.active;
        postData.sort_direction = sort.direction.toUpperCase() || 'ASC';
      }

      this.requestService.postRequest('opportunitiesDetails', '', postData, (data, error) => {
        if (data) {
          resolve(data);
        }
        else {
          reject(error);
        }
      });
    });
  }

  // getFilter() {
  //   return this.http.get<any[]>('assets/data/dashboard.json');
  // }

  // getAgencyList() {
  //   return this.http.get<any[]>('assets/data/agencies.json');
  // }
  // getAgentList() {
  //   return this.http.get<any[]>('assets/data/agents.json');
  // }
  // getlobsList() {
  //   return this.http.get<any[]>('assets/data/lobs.json');
  // }
  // getStateList() {
  //   return this.http.get<any[]>('assets/data/states.json');
  // }
  // getProductList() {
  //   return this.http.get<any[]>('assets/data/products.json');
  // }
  // getCarrierList() {
  //   return this.http.get<any[]>('assets/data/carriers.json');
  // }
  getVideoList() {
    return this.http.get<any[]>('assets/data/videos.json');
  }
  // getNotifications() {
  //   return this.http.get<any[]>('assets/data/previousNotifications.json');
  // }
  // getPgi() {
  //   return this.http.get<any[]>('assets/data/pgi.json');
  // }
  // getOpportunities() {
  //   return this.http.get<any[]>('assets/data/opportunitiesDetails.json');
  // }

  async getPGIPoliciesOverviewDetails(pageSize: any = 10, pageNum: any = 0, sort: Sort, form: FormGroup): Promise<any> {
    return new Promise((resolve, reject) => {
      let agencyID = this.requestService.currentUser?.mirror_1__1;
      let userID = this.requestService.currentUser?.id;
      let postData: any = {
        agency: agencyID,
        userid: userID,
        type: 'policies',
        page_num: pageNum,
        page_size: pageSize,
      };

      if (form.controls.city.getRawValue() != 'All Cities') {
        postData.city = form.controls.city.getRawValue();
      }

      if (form.controls.lob.getRawValue() != 'All LOBs') {
        postData.lob = form.controls.lob.getRawValue();
      }

      if (form.controls.term.getRawValue() != 'All Terms') {
        postData.term = form.controls.term.getRawValue();
      }

      if (form.controls.carrier.getRawValue() != 'All Carriers') {
        postData.carriername = form.controls.carrier.getRawValue();
      }

      if (form.controls.agency.getRawValue() != 'All Agencies') {
        postData.agency_name = form.controls.agency.getRawValue();
      }

      if (form.controls.type.getRawValue() != 'All Product Lines') {
        postData.productline = form.controls.type.getRawValue();
      }

      if (form.controls.ratingState.getRawValue() != 'All States') {
        postData.state = form.controls.ratingState.getRawValue();
      }

      if (form.controls.transactionType.getRawValue() != 'All Transaction Types') {
        postData.transactiontype = form.controls.transactionType.getRawValue();
      }

      if (sort) {
        postData.sort_by = sort.active;
        postData.sort_direction = sort.direction.toUpperCase();
      }

      this.requestService.postRequest('overviewdetails', '', postData, (data, error) => {
        if (data) {
          resolve(data);
        }
        else {
          reject(error);
        }
      });
    });
  }

  public getProductionData(getCache: boolean = false): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.productionData || !getCache) {
        this.requestService.postRequest('productionall', '', {
          agency: this.requestService.currentUser?.mirror_1__1,
          userid: this.requestService.currentUser?.id,
        }, (data, error) => {
          if (data) {
            this.productionData = data;
            resolve(data);
          }
          else {
            reject(error);
          }
        })
      }
      else {
        resolve(this.productionData);
      }
    });
  }

  async getProductionDetails(
    pageIndex: number,
    pageSize: number,
    postData: any,
    sort: Sort
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let agencyID = this.requestService.currentUser?.mirror_1__1;
      let userID = this.requestService.currentUser?.id;
      let data: any = {
        agency: agencyID,
        userid: userID,
        page_num: pageIndex,
        page_size: pageSize,
        ...postData
      };

      if (sort) {
        data.sort_by = sort.active;
        data.sort_direction = sort.direction.toUpperCase() || 'ASC';
      }

      this.requestService.postRequest('productionDetails', '', data, (data, error) => {
        if (data) {
          resolve(data);
        }
        else {
          reject(error);
        }
      });
    });
  }

  getWebviewLink(url): string {
    if (this.isWebview) {
      // console.log(`/mobile${url ? '/' + url : ''}?token=${this.requestService.getToken()}&agencyId=${this.agencyId}`)
      return `/mobile${url ? '/' + url : ''}?token=${this.requestService.getToken()}&agencyId=${this.agencyId}&jobTitle=${this.requestService.currentUser.dup__of_type}`;
    }
    else
      return url;
  }

  validateToken(token: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.requestService.postRequest('user', 'auth/token', {
        accessToken: token
      }, (data, error) => {
        if (data) {
          resolve(data);
        }
        else {
          reject(error);
        }
      });
    });
  }
}
