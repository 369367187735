import { Injectable } from '@angular/core';
import SendbirdChat, { ConnectionHandler, OperatorListQuery, SendbirdError, User, UserUpdateParams } from '@sendbird/chat';
import { GroupChannel, GroupChannelCollection, GroupChannelCollectionEventHandler, GroupChannelCollectionParams, GroupChannelCreateParams, GroupChannelFilter, GroupChannelHandler, GroupChannelListOrder, GroupChannelListQuery, GroupChannelModule, MembershipFilter, MyMemberStateFilter, PublicChannelFilter, SendbirdGroupChat, SuperChannelFilter } from '@sendbird/chat/groupChannel';
import { environment } from 'src/environments/environment';
import { RequestService } from './request.service';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from './notification.service';
import { LoaderService } from './loader.service';

export interface Channel {
    name: string;
    createdAt: string;
    creator: { _iid: string, userId: string, nickname: string };
    members: any;
    joinedMemberCount: number;
    isPublic: boolean;
    status: string;
    description: string;
    _id: string;
    unreadMessageCount: number;
    url: string;
}

export interface CreateChannel {
    name: string;
    members: any;
    isPublic: boolean;
    isDistinct: boolean;
    data: string;
    type: string;
    channelUrl: string;
    operatorIds: any;
    isSuper: boolean;
}

export interface Message {
    message: string;
    createdAt: string;
    reactions: any;
    messageId: string;
    metadata: any;
    createdTimeAgo: string;
    isSameDay: boolean;

    image: string;
    name: string;
    removedBy: string;
}

export enum Status {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive',
    ARCHIVED = 'Archived',
}

export enum Type {
    PRIVATE = 'Private',
    PUBLIC = 'Public',
}

@Injectable({
    providedIn: 'root',
})
export class SendbirdService {

    public sendbirdToken: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public isConnectedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public leaveSendbird: boolean = false;
    public channelCollection: GroupChannelCollection;
    public groupChannelHandler: GroupChannelCollectionEventHandler;

    private token: string = '';
    private sendbird!: any;
    private storageKey = 'unreadChats';

    constructor(private requestService: RequestService, private notificationService: NotificationService, private loader: LoaderService) {
        const APP_ID = environment.sendBird;
        this.sendbird = SendbirdChat.init({
            appId: APP_ID,
            modules: [new GroupChannelModule()],
            localCacheEnabled: true,
        });
    }

    setToken(token: string) {
        this.token = token;
    }

    async connect(userId: string): Promise<boolean> {
        try {
            const connectionHandler: ConnectionHandler = new ConnectionHandler({
                onConnected: () => {
                    console.log('event connnectde')
                },
                onDisconnected: () => {
                    console.log('event disconnected')
                },
                onReconnectStarted: () => { },
                onReconnectSucceeded: () => { },
                onReconnectFailed: () => { },
            });

            this.sendbird.addConnectionHandler('sendbird_handler', connectionHandler);
            await this.sendbird.connect(userId);

            console.log(`User connected to Sendbird: ${userId}`);
            return true;
        } catch (error) {
            console.error('Failed to connect user:', error);
            return false;
        }
    }

    getToken(userId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.requestService.getRecord('', `sendbird/user/${userId}/token`, (data, error) => {
                if (data) {
                    resolve(data.results?.token);
                }
                else if (error) {
                    reject(error);
                }
            });
        });
    }

    async connectWithAccessToken(userId: string, token: string): Promise<boolean> {
        try {
            const connectionHandler: ConnectionHandler = new ConnectionHandler({
                onConnected: () => {
                    console.log('sendbird connnected')
                },
                onDisconnected: async () => {
                    console.log('sendbird disconnected');
                    this.sendbird.channelCollection?.dispose();
                    if (!this.leaveSendbird) {
                        this.loader.display(true);
                        await this.sendbird.connect(userId, token);
                        this.loader.display(false);
                    }
                },
                onReconnectStarted: () => { },
                onReconnectSucceeded: () => {
                    console.log('sendbird reconnection successful');
                },
                onReconnectFailed: () => {
                    console.log('sendbird reconnection failed');
                },
            });

            this.sendbird.addConnectionHandler('sendbird_handler', connectionHandler);

            console.log(`User connected to Sendbird: ${userId}`);
            // this.sendbird.setAutoBackgroundDetection(true);

            await this.sendbird.connect(userId, token);
            console.log(`User connected to Sendbird: ${userId}`);
            return true;
        } catch (error) {
            console.error('Failed to connect user:', error);
            return false;
        }
    }

    async disconnect() {
        try {
            this.leaveSendbird = true;
            if (this.sendbird) {
                await this.sendbird.disconnect((response, error) => {
                    if (error) {
                        console.error("Error disconnecting", error);
                        return;
                    }
                    // console.log("Successfully disconnected from Sendbird");
                });
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    getSendbird() {
        return this.sendbird;
    }

    async updateUser(nickName, profileUrl = `${environment.serverHostUrl}assets/images/profile.png`) {
        const isConnected = await this.ensureConnection();

        if (isConnected) {
            try {
                const params: UserUpdateParams = {
                    nickname: nickName,
                    profileUrl: profileUrl,
                };
                const user = await this.sendbird.updateCurrentUserInfo(params);
                return user;
            }
            catch (e) {
                console.log(e);
            }
        }
    }

    async createCollection() {
        const isConnected = await this.ensureConnection();

        if (isConnected) {
            try {
                const groupChannelFilter: GroupChannelFilter = new GroupChannelFilter();
                groupChannelFilter.includeEmpty = true;

                const params: GroupChannelCollectionParams = {
                    filter: groupChannelFilter,
                };

                this.channelCollection = this.sendbird.groupChannel.createGroupChannelCollection(params);
                await this.channelCollection.loadMore()
                // console.log('this.channelCollection', this.channelCollection, await this.channelCollection.loadMore())
            }
            catch (e) {
                console.log(e);
            }
        }
    }

    async getChannels(searchText: string = '', limit: number = 20, type: string = '', status: string = ''): Promise<GroupChannel[]> {
        const isConnected = await this.ensureConnection();

        if (isConnected) {
            try {
                const groupChannelFilter: GroupChannelFilter = new GroupChannelFilter();
                groupChannelFilter.includeEmpty = true;

                if (status) {
                    groupChannelFilter.customTypesFilter = [status];
                }

                // groupChannelFilter.publicChannelFilter = type == 'Public' ? PublicChannelFilter.PUBLIC : type == Type.PRIVATE ? PublicChannelFilter.PRIVATE : PublicChannelFilter.ALL;
                if (type == 'Private') {
                    groupChannelFilter.publicChannelFilter = PublicChannelFilter.PRIVATE;
                }
                else if (type == 'Public') {
                    groupChannelFilter.myMemberStateFilter = MyMemberStateFilter.ALL;
                    groupChannelFilter.publicChannelFilter = PublicChannelFilter.PUBLIC;
                    // groupChannelFilter.superChannelFilter = SuperChannelFilter.SUPER;
                }
                else {
                    groupChannelFilter.publicChannelFilter = PublicChannelFilter.ALL;
                }

                if (searchText) {
                    groupChannelFilter.channelNameContainsFilter = searchText;
                }
                console.log('groupChannelFilter', groupChannelFilter)
                const params: GroupChannelCollectionParams = {
                    filter: groupChannelFilter,
                    order: GroupChannelListOrder.CHRONOLOGICAL,
                    limit: limit,
                };

                this.channelCollection = this.sendbird.groupChannel.createGroupChannelCollection(params);

                let channels: GroupChannel[] = [];
                if (this.channelCollection.hasMore) {
                    channels = await this.channelCollection.loadMore();
                    channels = this.updateMemberCount(channels);
                    console.log('collection', channels);
                }

                return channels;
            }
            catch (e) {
                console.log(e);
                return [];
            }
        }
        // let allChannels = [];
        // while (this.channelCollection.hasMore) {
        //     await new Promise(async (resolve) => {
        //         const groupChannels = await this.channelCollection.loadMore();
        //         allChannels = allChannels.concat(groupChannels);
        //         resolve(groupChannels);
        //     });
        // }

        // console.log('All Group Channels:', allChannels);
        // return allChannels;
    }

    async loadMoreChannels() {
        const isConnected = await this.ensureConnection();

        if (isConnected) {
            try {
                let channels: GroupChannel[] = [];
                if (this.channelCollection.hasMore) {
                    channels = await this.channelCollection.loadMore();
                    channels = this.updateMemberCount(channels);
                    console.log('collection', channels);
                }
                return channels;
            }
            catch (e) {
                console.log(e);
            }
        }
    }

    updateMemberCount(channels) {
        return channels.map((i: any) => {
            let nonAdminMembers = i.members.filter(k => k.metaData?.role != environment.customKeys.roleAdmin);
            i.nonAdminMembers = nonAdminMembers;
            i.nonAdminMemberCount = nonAdminMembers.length;
            // if (i.data) {
            // if (typeof i.data == 'string')
            // i.data = JSON.parse(i.data);
            // if (i.data.type == 'one-one') {
            // console.log('one', this.requestService.currentUser)
            // let findMe = i.members.findIndex(k => k.userId == this.requestService.currentUser._id);
            // if (findMe != -1 && i.members.length == 2) {
            //     if (findMe == 0) {
            //         i.name = i.members[1].nickname;
            //     }
            //     else {
            //         i.name = i.members[0].nickname;
            //     }
            // }
            // }
            // }
            // console.log(i)
            return i;
        });
    }

    async createChannel(channel: CreateChannel): Promise<GroupChannel> {
        const isConnected = await this.ensureConnection();

        if (isConnected) {
            try {
                const params: GroupChannelCreateParams = {
                    invitedUserIds: channel.members,
                    name: channel.name,
                    operatorUserIds: [],
                    data: channel.data,
                    customType: channel.type,
                    isDistinct: channel.isDistinct,
                    channelUrl: channel.channelUrl,
                    isPublic: channel.isPublic,
                    isSuper: channel.isSuper,
                };
                const createdChannel: GroupChannel = await this.sendbird.groupChannel.createChannel(params);
                return createdChannel;
            }
            catch (e) {
                console.log(e);
            }
        }
    }

    async updateChannel(channelUrl: string, params: GroupChannelCreateParams): Promise<GroupChannel> {
        if (channelUrl) {
            const isConnected = await this.ensureConnection();

            if (isConnected) {
                try {
                    const groupChannel = await this.getChannel(channelUrl);

                    const updatedChannel = await groupChannel.updateChannel(params,
                        (updatedChannel, error) => {
                            if (error) {
                                throw new Error('Failed to update channel: ' + error);
                            }
                            return updatedChannel;
                        }
                    );
                    console.log(updatedChannel)
                    return updatedChannel;
                }
                catch (e) {
                    console.log(e);
                }
            }
        }
        return null;
    }

    async fetchUsersBySearchText(searchText: string, limit: number = 20): Promise<any> {
        const isConnected = await this.ensureConnection();

        if (isConnected) {
            try {
                const query = await this.sendbird.createApplicationUserListQuery({
                    metaDataKeyFilter: 'role',
                    metaDataValuesFilter: [environment.customKeys.roleAgencyAdmin, environment.customKeys.roleAgent]
                });
                query.nicknameStartsWithFilter = searchText;
                // query.nicknameContainsFilter = searchText;
                query.limit = limit;

                return await query.next();
            }
            catch (e) {
                console.log(e);
            }
        }
    }

    async getRegisteredUsers(limit: number = 100): Promise<string[]> {
        const isConnected = await this.ensureConnection();

        if (isConnected) {
            try {
                const query = this.sendbird.createApplicationUserListQuery();
                query.limit = limit;

                let allUsers = [];
                console.log('query', query)
                while (query.hasNext) {
                    await new Promise(async (resolve) => {
                        let users = await query.next();
                        allUsers = allUsers.concat(users);
                        resolve(users);
                    });
                }

                return allUsers;
            }
            catch (e) {
                console.log(e);
            }
        }
    }

    async getChannel(channelUrl: string): Promise<any> {
        const isConnected = await this.ensureConnection();

        if (isConnected) {
            try {
                return await this.sendbird.groupChannel.getChannel(channelUrl);
            }
            catch (e) {
                console.log(e);
            }
        }
    }

    async getMessages(channel): Promise<any> {
        const isConnected = await this.ensureConnection();

        if (isConnected) {
            try {
                const query = channel.createPreviousMessageListQuery();
                query.limit = 30; // Number of messages to fetch
                query.reverse = false; // Set to `true` to get messages in reverse order

                return await query.load();
            }
            catch (e) {
                console.log(e);
            }
        }
    }

    async getMessagesByTimestamp(channel, lastTimestamp, limit): Promise<any> {
        if (channel) {
            const isConnected = await this.ensureConnection();

            if (isConnected) {
                try {
                    const params = {
                        prevResultSize: limit,
                        reverse: false,
                    };

                    return await channel.getMessagesByTimestamp(lastTimestamp, params);
                }
                catch (e) {
                    console.log(e);
                }
            }
        }
    }

    async sendMessage(message: string, channel): Promise<any> {
        const isConnected = await this.ensureConnection();

        if (isConnected) {
            try {
                const params = {
                    message: message,
                    customType: channel.isPublic ? Type.PUBLIC : Type.PRIVATE,
                };

                return new Promise(async (resolve, reject) => {
                    await channel.sendUserMessage(params).onSucceeded((message) => {
                        console.log("Message sent successfully:", message);
                        resolve(message);
                    }).onFailed((error) => {
                        console.error("Message failed to send:", error);
                        reject(error);
                    });
                });
            }
            catch (e) {
                console.log(e);
            }
        }
    }

    async searchMessages(searchText: string, channelUrl: string, limit: number = 20,): Promise<any> {
        const isConnected = await this.ensureConnection();

        if (isConnected) {
            try {
                const params = {
                    keyword: searchText,
                    channelUrl: channelUrl,
                    limit: limit,
                    exactMatch: false,
                    messageTimestampFrom: null,
                    messageTimestampTo: null,
                    order: 'score',
                    reverse: false,
                };

                const query = this.sendbird.createMessageSearchQuery(params);

                return await query.next();
            }
            catch (e) {
                console.log(e);
            }
        }
    }

    async removeHandler(handlerId: string) {
        if (handlerId) {
            const isConnected = await this.ensureConnection();

            if (isConnected) {
                try {
                    this.sendbird.groupChannel.removeGroupChannelHandler(handlerId);
                }
                catch (e) {
                    console.log(e);
                }
            }
        }
    }

    async deleteMessage(message: any, channel) {
        const isConnected = await this.ensureConnection();

        if (isConnected) {
            try {
                return await channel.deleteMessage(message);
            }
            catch (e) {
                console.log(e);
            }
        }
    }

    async markMessageAsDeleted(message: any, channel, removedBy) {
        const isConnected = await this.ensureConnection();

        if (isConnected) {
            try {
                const params: any = {};
                params.customType = 'removed';
                params.data = JSON.stringify({ removedBy: removedBy });

                return await channel.updateUserMessage(message.messageId, params);
            }
            catch (e) {
                console.log(e);
            }
        }
    }

    async markMessageAsRestored(message: any, channel) {
        const isConnected = await this.ensureConnection();

        if (isConnected) {
            try {
                const params: any = {};
                params.customType = '';
                params.data = '';

                return await channel.updateUserMessage(message.messageId, params);
            }
            catch (e) {
                console.log(e);
            }
        }
    }

    async markMessageAsDeletedByApi(messageId: string, channelUrl: string, removedBy): Promise<any> {
        return new Promise((resolve, reject) => {
            this.requestService.postRequest('sendbird', 'update/messagemetadata', {
                channel_url: channelUrl,
                message_id: messageId,
                channel_type: 'group_channels',
                upsert: true,
                mode: 'add',
                sorted_metaarray: [
                    {
                        key: 'customType',
                        value: ['removed'],
                    },
                    {
                        key: 'removedBy',
                        value: [removedBy],
                    }
                ],
            }, (data, error) => {
                if (data) {
                    resolve(data);
                }
                if (error) {
                    reject(error);
                }
            });
        });
    }

    async markMessageAsRestoredByApi(messageId: string, channelUrl: string, removedBy): Promise<any> {
        return new Promise((resolve, reject) => {
            const params: any = {};
            params.customType = '';
            params.data = '';

            this.requestService.postRequest('sendbird', 'update/messagemetadata', {
                channel_url: channelUrl,
                message_id: messageId,
                channel_type: 'group_channels',
                upsert: true,
                mode: 'remove',
                sorted_metaarray: [
                    {
                        key: 'customType',
                        value: ['removed'],
                    },
                    {
                        key: 'removedBy',
                        value: [removedBy],
                    }
                ],
            }, (data, error) => {
                if (data) {
                    resolve(data);
                }
                if (error) {
                    reject(error);
                }
            });
        });
    }

    async addModerators(channel, userIds: []) {
        if (userIds.length) {
            const isConnected = await this.ensureConnection();

            if (isConnected) {
                try {
                    await channel.addOperators(userIds);
                }
                catch (e) {
                    console.log(e);
                }
            }
        }
    }

    handleError(error: SendbirdError) {
        if (error.code == 400108) {
            return 'Not Authorized';
        }
        else {
            return error.message;
        }
    }

    createUser(userId, userName, userProfilePic, role, agencyName): Promise<any> {
        return new Promise((resolve, reject) => {
            if (userId && userName) {
                this.requestService.postRequest('sendbird', 'user/create', {
                    userId: userId,
                    profile: userProfilePic,
                    name: userName,
                    metadata: {
                        role: role,
                        agency: agencyName,
                    },
                }, (data, error) => {
                    if (data) {
                        resolve(data);
                    }
                    if (error) {
                        reject(error);
                    }
                });
            }
            else {
                reject('Please try again later.');
            }
        });
    }

    async getChannelOperators(channel) {
        const isConnected = await this.ensureConnection();

        if (isConnected) {
            const query = channel.createOperatorListQuery();
            try {
                const operators = await query.next();
                console.log("Operators:", operators);
                return operators;
            } catch (error) {
                console.error("Failed to retrieve operators:", error);
            }
        }
    }

    assignOperator(userId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.requestService.postRequest(`sendbird/user/${userId}/register_operating_channel`, '', {
                channel_custom_types: Object.values(Status)
            }, (data, error) => {
                if (data) {
                    resolve(data);
                }
                else if (error) {
                    reject(error);
                }
            });
        });
    }

    assignOperators(userIds: string[]): Promise<any> {
        return new Promise((resolve, reject) => {
            this.requestService.postRequest(`sendbird/user/register_operating_channel`, '', {
                channel_custom_types: Object.values(Status),
                userIds: userIds,
            }, (data, error) => {
                if (data) {
                    resolve(data);
                }
                else if (error) {
                    reject(error);
                }
            });
        });
    }

    unassignOperators(userIds: string[], channelUrl: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.requestService.postRequest(`sendbird/user/unregister_group_channel`, '', {
                operator_ids: userIds,
                channel_url: channelUrl,
                delete_all: false,
            }, (data, error) => {
                if (data) {
                    resolve(data);
                }
                else if (error) {
                    reject(error);
                }
            });
        });
    }

    async addOperatorToAllChannels(userId) {
        const isConnected = await this.ensureConnection();

        if (isConnected) {
            try {
                const query = this.sendbird.groupChannel.createMyGroupChannelListQuery();
                let channels = await query.next();

                const addOperatorPromises = channels.map(channel => {
                    return new Promise((resolve, reject) => {
                        channel.addOperators([userId], (response, error) => {
                            if (error) {
                                reject(error);
                            } else {
                                resolve(response);
                            }
                        });
                    });
                });
                return await Promise.all(addOperatorPromises);
            }
            catch (e) {
                console.log(e);
            }
        }
    }

    async markAsRead(channel) {
        if (channel) {
            const isConnected = await this.ensureConnection();

            if (isConnected) {
                try {
                    channel.markAsRead().catch((error) => {
                        console.error("mark read failed:", error);
                    });
                }
                catch (e) {
                    console.log(e);
                }
            }
        }
    }

    listChannels(searchText: string = '', limit: number = 20, type: string = '', status: string = '', next: string = '', distinctMode: string = 'nondistinct'): Promise<any> {
        return new Promise((resolve, reject) => {
            let postData: any = {
                limit: limit + '',
                show_empty: true,
                order: 'chronological',
                show_member: true,
                show_metadata: true,
                distinct_mode: distinctMode,
            };

            if (status) {
                postData.custom_types = [status];
            }

            postData.public_mode = type == 'Public' ? PublicChannelFilter.PUBLIC : type == Type.PRIVATE ? PublicChannelFilter.PRIVATE : PublicChannelFilter.ALL;

            if (searchText) {
                postData.name_contains = searchText;
                // postData.members_include_in = searchText;
                // postData.query_type = 'OR';
            }

            if (next != '') {
                {
                    postData.token = next;
                }
            }

            this.requestService.postRequest('sendbird/user/listgroupchannel', '', postData, (data, error) => {
                if (data?.status) {
                    data.results.channels = data.results.channels.map((i: any) => {
                        // let nonAdminMembers = i.members.filter(k => k.metadata?.role != environment.customKeys.roleAdmin);
                        // i.nonAdminMembers = nonAdminMembers;
                        // i.nonAdminMemberCount = nonAdminMembers.length;
                        i.url = i.channel_url;
                        i.customType = i.custom_type;
                        i.isPublic = i.is_public;
                        i.data = i.data ? JSON.parse(i.data) : undefined;
                        i.joinedMemberCount = i.joined_member_count;
                        i.unreadMessageCount = i.unread_message_count;
                        i.creator = i.created_by;
                        i.createdAt = i.created_at * 1000;
                        i.members = i.members.map(m => m.userId = m.user_id);
                        return i;
                    });
                    resolve(data);
                }
                if (error) {
                    reject(error);
                }
            });
        });
    }

    getEmojis(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.requestService.getRecord('', 'sendbird/emoji/list', (data, error) => {
                if (data) {
                    resolve(data);
                }
                else if (error) {
                    reject(error);
                }
            });
        });
    }

    async getOperators(channelUrl): Promise<User[]> {
        return new Promise((resolve, reject) => {
            this.requestService.postRequest('sendbird', 'operatorsgroupchannel/list', {
                channel_url: channelUrl,
                limit: 100,
                // token: '0',
            }, (data, error) => {
                if (data) {
                    resolve(data.results.operators);
                    // resolve(data.results.operators.map(i => {
                    //     return {
                    //         ...i,
                    //         userId: i.user_id,
                    //     }
                    // }));
                }
                else if (error) {
                    resolve([]);
                }
            });
        });
        // const isConnected = await this.ensureConnection();

        // if (isConnected) {
        //     try {
        //         const query: OperatorListQuery = channel.createOperatorListQuery();

        //         let operators = [];
        //         while (query.hasNext) {
        //             await new Promise(async (resolve) => {
        //                 let users = await query.next();
        //                 operators = operators.concat(users);
        //                 resolve(users);
        //             });
        //         }

        //         return operators;
        //     }
        //     catch (e) {
        //         console.log(e);
        //     }
        // }
        // else
        //     return [];
    }

    getOperatorsByApi(channelUrl: string, limit: number = 100): Promise<any> {
        return new Promise((resolve, reject) => {
            this.requestService.postRequest('sendbird', 'operatorsgroupchannel/list', {
                limit: limit,
                channel_url: channelUrl,
                // token: 0,
            }, (data, error) => {
                if (data) {
                    resolve(data);
                }
                if (error) {
                    reject(error);
                }
            });
        });
    }

    inviteUsers(members: string[], inviterId: string, channelUrl: string) {
        return new Promise((resolve, reject) => {
            this.requestService.postRequest('sendbird', 'invite/member', {
                user_ids: members,
                channel_url: channelUrl,
                inviter_id: inviterId,
            }, (data, error) => {
                if (data) {
                    resolve(data);
                }
                if (error) {
                    reject(error);
                }
            });
        });
    }

    removeUsers(members: string[], channelUrl: string) {
        return new Promise((resolve, reject) => {
            this.requestService.postRequest('sendbird', 'leave/channel', {
                user_ids: members,
                channel_url: channelUrl,
                reason: 'admin_removed'
            }, (data, error) => {
                if (data) {
                    resolve(data);
                }
                if (error) {
                    reject(error);
                }
            });
        });
    }

    async getAdmins(limit: number = 100) {
        const isConnected = await this.ensureConnection();

        if (isConnected) {
            try {
                const query = this.sendbird.createApplicationUserListQuery({
                    metaDataKeyFilter: 'role',
                    metaDataValuesFilter: [environment.customKeys.roleAdmin]
                });
                // query.metaDataKey = 'role';
                // query.metaDataValues = [environment.customKeys.roleAdmin];
                query.limit = limit;

                return await query.next();
            }
            catch (e) {
                console.log(e);
            }
        }
    }

    async getMessagesByApi(channelUrl, lastTimestamp, limit): Promise<any> {
        return new Promise(async (resolve, reject) => {
            this.requestService.postRequest('sendbird', 'user/listmessages', {
                message_ts: lastTimestamp,
                next_limit: limit,
                channel_url: channelUrl,
                channel_type: 'group_channels',
                with_sorted_meta_array: true,
            }, (data, error) => {
                if (data) {
                    resolve(data);
                }
                else if (error) {
                    reject(error);
                }
            });
        });
    }

    async sendMessageByApi(channelUrl, message, messageType, userId): Promise<any> {
        return new Promise(async (resolve, reject) => {
            this.requestService.postRequest('sendbird', 'user/sendmessage', {
                channel_url: channelUrl,
                channel_type: 'group_channels',
                message: message,
                message_type: messageType,
                user_id: userId,
            }, (data, error) => {
                if (data) {
                    resolve(data);
                }
                else if (error) {
                    reject(error);
                }
            });
        });
    }

    async registerToken(userId: string, firebaseToken: string): Promise<any> {
        return new Promise(async (resolve, reject) => {
            this.requestService.postRequest('sendbird', 'registeruserdevice', {
                user_id: userId,
                token_type: 'gcm',
                gcm_reg_token: firebaseToken,
            }, (data, error) => {
                if (data) {
                    resolve(data);
                }
                else if (error) {
                    reject(error);
                }
            });
        });
    }

    async unRegisterToken(userId: string, firebaseToken: string): Promise<any> {
        return new Promise(async (resolve, reject) => {
            this.requestService.postRequest('sendbird', 'unregisteruserdevice', {
                user_id: userId,
                token_type: 'gcm',
                token: firebaseToken,
            }, (data, error) => {
                if (data) {
                    resolve(data);
                }
                else if (error) {
                    reject(error);
                }
            });
        });
    }

    async updateChannelByApi(name: string, channelUrl: string, customType: string, isPublic: boolean, data: string): Promise<any> {
        return new Promise(async (resolve, reject) => {
            this.requestService.postRequest('sendbird', 'user/updateGroupChannel', {
                name: name,
                custom_type: customType,
                channel_url: channelUrl,
                is_public: isPublic,
                data: data,
            }, (data, error) => {
                if (data) {
                    resolve(data);
                }
                else if (error) {
                    reject(error);
                }
            });
        });
    }

    getUnreadChats(): string[] {
        // if (!localStorage.getItem(this.storageKey)) {
        //     localStorage.setItem(this.storageKey, '[]');
        // }
        const unreadChats = localStorage.getItem(this.storageKey);
        return unreadChats ? JSON.parse(unreadChats) : [];
    }

    addUnreadChat(channelId: string): void {
        const unreadChats = this.getUnreadChats();
        if (!unreadChats.includes(channelId)) {
            unreadChats.push(channelId);
            localStorage.setItem(this.storageKey, JSON.stringify(unreadChats));
            this.notificationService.showChatUnRead.next(unreadChats);
        }
    }

    removeUnreadChat(channelId: string): void {
        const unreadChats = this.getUnreadChats();
        const updatedChats = unreadChats.filter((id) => id !== channelId);
        localStorage.setItem(this.storageKey, JSON.stringify(updatedChats));
        this.notificationService.showChatUnRead.next(updatedChats);
    }

    clearUnreadChats(): void {
        localStorage.removeItem(this.storageKey);
        this.notificationService.showChatUnRead.next([]);
    }

    async autoAcceptInvite() {
        const isConnected = await this.ensureConnection();

        if (isConnected) {
            try {
                const autoAccept = true;
                await this.sendbird.setChannelInvitationPreference(autoAccept);
            }
            catch (e) {
                console.log(e)
            }
        }
    }

    async ensureConnection() {
        if (this.sendbird.currentUser && this.token) {
            // Connection is already open
            return true;
        } else {
            console.warn('Connection is not open. Attempting to reconnect...');
            if (this.requestService.currentUser._id && this.token)
                // Attempt to reconnect
                return new Promise((resolve) => {
                    this.sendbird.connect(this.requestService.currentUser._id, this.token, (user, error) => {
                        if (error) {
                            console.error('Reconnection failed:', error);
                            resolve(false); // Return false on failure
                        } else {
                            console.log('Reconnected successfully.');
                            resolve(true); // Return true on success
                        }
                    });
                });
        }
    }
}