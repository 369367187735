import { c as t, b9 as n } from "./__bundle-2f7467b8.js";
var e = function (t) {
    return ["-lastMessageUpdatedAt", "-createdAt", "syncIndex"];
  },
  r = function (n) {
    function e() {
      return null !== n && n.apply(this, arguments) || this;
    }
    return t(e, n), e;
  }(n);
export { r as N, e as g };