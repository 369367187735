import { _ as e, V as i, b as s, D as h, F as f, ba as _, bb as v, s as g, p as y, q as E, a2 as A, K as L, E as T, t as F, G as N, n as I, u as R, g as z, bc as B, a9 as J, B as $, c as Z, A as ee, aA as te, e as ne, f as ae, h as re, a as ie, Q as se, X as oe, aP as le, aB as ue, bd as ce, ar as he, w as de, a$ as pe } from "./lib/__bundle-2f7467b8.js";
export { be as MessageEventSource, bf as MutedState } from "./lib/__bundle-2f7467b8.js";
import { S as fe, T as _e, G as ve, s as me, a as ge, P as ye, b as Ce, c as Se, d as Me, e as Ee, v as Ae, f as we, g as Le, h as Te, i as Fe } from "./lib/__bundle-1a70f031.js";
export { C as CountPreference, D as DeliveryStatus, b as GroupChannel, j as GroupChannelEventSource, a as GroupChannelFilter, d as GroupChannelListQuery, k as GroupChannelSearchField, H as HiddenChannelFilter, l as HiddenState, M as Member, m as MemberListOrder, n as MemberListQuery, o as MemberState, p as MemberStateFilter, r as MessageCollection, t as MessageCollectionInitHandler, u as MessageCollectionInitPolicy, w as MutedMemberFilter, q as MyMemberStateFilter, O as OperatorFilter, P as PublicChannelFilter, Q as QueryType, c as SuperChannelFilter, U as UnreadChannelFilter, x as UnreadItemKey } from "./lib/__bundle-1a70f031.js";
import { Q as ke, T as Ne, V as xe } from "./lib/__bundle-d4f5ef6d.js";
export { K as MessageFilter, W as PinnedMessage, X as PinnedMessageListQuery, Y as ReadStatus, V as ScheduledStatus } from "./lib/__bundle-d4f5ef6d.js";
import { G as Ge, P as Ue, S as Oe } from "./lib/__bundle-04894092.js";
export { G as GroupChannelListOrder, P as PublicGroupChannelListOrder, S as ScheduledMessageListOrder } from "./lib/__bundle-04894092.js";
import { B as Ie } from "./lib/__bundle-b1d4b77d.js";
import "./lib/__bundle-5d883d52.js";
import "./lib/__bundle-acd77193.js";
var De,
  Pe = {},
  He = function () {
    function t(t) {
      var n = t._iid,
        a = t.limit,
        r = void 0 === a ? 100 : a,
        o = this;
      this.ref = 0, this._iid = n, this._limit = r;
      var l,
        u = i.of(this._iid),
        c = u.sdkState,
        d = u.dispatcher,
        p = u.logger,
        m = u.cacheContext,
        C = u.statManager;
      this._metadataKey = (l = c.userId, "sendbird:".concat(l, "@groupchannel/sync.meta"));
      var b = function (e) {
        return "sendbird:".concat(e, "@groupchannel/sync");
      }(c.userId);
      this._sync = new fe(b, function () {
        return e(o, void 0, void 0, function () {
          var e, t, n, a, r, i, o, l, u, h, d, f, b, S, M, w;
          return s(this, function (s) {
            switch (s.label) {
              case 0:
                return e = {
                  hasNext: !0,
                  nextToken: ""
                }, [4, this.loadMetadata()];
              case 1:
                if (s.sent(), p.debug("channel background sync from", null === (u = this._metadata) || void 0 === u ? void 0 : u.token), null === (h = this._metadata) || void 0 === h ? void 0 : h.completed) return [3, 14];
                t = {
                  includeEmpty: !0,
                  includeMetaData: !0,
                  order: Ge.CHRONOLOGICAL
                }, s.label = 2;
              case 2:
                if (s.trys.push([2, 9, 12, 13]), t.order !== Ge.LATEST_LAST_MESSAGE) return [3, 6];
                s.label = 3;
              case 3:
                return s.trys.push([3, 5,, 6]), [4, m.preference.get(L(c.appId))];
              case 4:
                return n = s.sent(), t.includeChatNotification = Boolean(n), [3, 6];
              case 5:
                return s.sent(), t.includeChatNotification = !1, [3, 6];
              case 6:
                return [4, ve.of(this._iid).getMyGroupChannels(null !== (f = null === (d = this._metadata) || void 0 === d ? void 0 : d.token) && void 0 !== f ? f : "", t, this._limit, A.SYNC_CHANNEL_BACKGROUND)];
              case 7:
                return a = s.sent(), r = a.channels, i = a.token, e.hasNext = r.length >= this._limit && !!i, e.nextToken = i, this._metadata && (this._metadata.token = i, (l = this._metadata.range).extends.apply(l, y([], E(r.map(function (e) {
                  return e.createdAt;
                })), !1)), this._metadata.completed = !e.hasNext), p.debug("channel background sync progress", e), [4, this.saveMetadata()];
              case 8:
                return s.sent(), [3, 13];
              case 9:
                return o = s.sent(), p.debug("channel background sync error", o), o instanceof g && o.isInvalidTokenError ? [4, this.clearMetaData()] : [3, 11];
              case 10:
                s.sent(), s.label = 11;
              case 11:
                throw o;
              case 12:
                return C.put(new _({
                  type: v.FEATURE_LOCALCACHE_EVENT,
                  ts: Date.now(),
                  data: {
                    channel_url: "",
                    measured_on: "channel_back_sync",
                    event: "cache_fetch",
                    max_db_size: null !== (S = null === (b = m.localCacheConfig) || void 0 === b ? void 0 : b.maxSize) && void 0 !== S ? S : 0,
                    use_local_cache: m.localCacheEnabled,
                    starting_point: 0,
                    message_init_policy: null !== (w = null === (M = t.order) || void 0 === M ? void 0 : M.toString()) && void 0 !== w ? w : "",
                    collection_id: c.userId
                  }
                })), [7];
              case 13:
                return [3, 15];
              case 14:
                e.hasNext = !1, e.nextToken = "", s.label = 15;
              case 15:
                return [2, e];
            }
          });
        });
      }), this._connectionEventContext = d.on(function (e) {
        if (e instanceof h) if (e.stateType === f.CONNECTED) o.resume();else o.pause();
      });
    }
    return t.of = function (e) {
      return Pe[e] || (Pe[e] = new t({
        _iid: e
      })), Pe[e].ref++, Pe[e];
    }, t.clear = function (e) {
      Pe[e] && (Pe[e].close(), delete Pe[e]);
    }, Object.defineProperty(t.prototype, "range", {
      get: function () {
        var e, t;
        return null !== (t = null === (e = this._metadata) || void 0 === e ? void 0 : e.range) && void 0 !== t ? t : new _e({});
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t.prototype, "completed", {
      get: function () {
        var e;
        return !!(null === (e = this._metadata) || void 0 === e ? void 0 : e.completed);
      },
      enumerable: !1,
      configurable: !0
    }), t.prototype.loadMetadata = function () {
      return e(this, void 0, void 0, function () {
        var e;
        return s(this, function (t) {
          switch (t.label) {
            case 0:
              return this._metadata ? [3, 2] : [4, i.of(this._iid).cacheContext.preference.get(this._metadataKey)];
            case 1:
              e = t.sent(), this._metadata = {
                token: e ? e.token : "",
                range: new _e(e ? e.range : {
                  top: Number.MAX_SAFE_INTEGER,
                  bottom: 0
                }),
                completed: !!e && e.completed
              }, t.label = 2;
            case 2:
              return [2, this._metadata];
          }
        });
      });
    }, t.prototype.saveMetadata = function () {
      return e(this, void 0, void 0, function () {
        return s(this, function (e) {
          switch (e.label) {
            case 0:
              return this._metadata ? [4, i.of(this._iid).cacheContext.preference.set(this._metadataKey, this._metadata)] : [3, 2];
            case 1:
              return e.sent(), [2, !0];
            case 2:
              return [2, !1];
          }
        });
      });
    }, t.prototype.clearMetaData = function () {
      return e(this, void 0, void 0, function () {
        return s(this, function (e) {
          switch (e.label) {
            case 0:
              return [4, i.of(this._iid).cacheContext.preference.remove(this._metadataKey)];
            case 1:
              return e.sent(), this._metadata = void 0, [2];
          }
        });
      });
    }, t.prototype.resume = function () {
      var e,
        t,
        n = i.of(this._iid),
        a = n.logger;
      n.sessionManager.session.hasSession && (a.debug("channel background sync resume()"), this._sync.start(null !== (t = null === (e = this._metadata) || void 0 === e ? void 0 : e.token) && void 0 !== t ? t : ""));
    }, t.prototype.pause = function () {
      i.of(this._iid).logger.debug("channel background sync stop()"), this._sync.stop();
    }, t.prototype.close = function () {
      this.ref--, this.ref <= 0 && (this.ref = 0, this.pause(), this._connectionEventContext.close(), delete Pe[this._iid]);
    }, t;
  }(),
  Re = {},
  Ve = function () {
    function t(t) {
      var n = t._iid,
        a = this;
      this.ref = 0, this._iid = n;
      var r,
        o = i.of(this._iid),
        l = o.logger,
        u = o.sdkState,
        c = o.dispatcher,
        d = o.cacheContext,
        p = o.statManager;
      this._metadataKey = (r = u.userId, "sendbird:".concat(r, "@groupchannel/changelogs.meta")), this._subscribers = new T();
      var m = function (e) {
        return "sendbird:".concat(e, "@groupchannel/changelogs");
      }(u.userId);
      this._sync = new fe(m, function () {
        return e(a, void 0, void 0, function () {
          var e, t, n, a, r, i, o, c, h, f, m, y;
          return s(this, function (s) {
            switch (s.label) {
              case 0:
                return e = {
                  hasNext: !0,
                  nextToken: 0
                }, [4, this.loadMetadata()];
              case 1:
                s.sent(), l.debug("channel changelog sync from", null === (f = this._metadata) || void 0 === f ? void 0 : f.token), s.label = 2;
              case 2:
                s.trys.push([2, 9,, 12]), t = {
                  includeEmpty: !0,
                  includeMetaData: !0
                }, s.label = 3;
              case 3:
                return s.trys.push([3, 5,, 6]), [4, d.preference.get(L(u.appId))];
              case 4:
                return n = s.sent(), t.includeChatNotification = Boolean(n), [3, 6];
              case 5:
                return s.sent(), t.includeChatNotification = !1, [3, 6];
              case 6:
                return [4, ve.of(this._iid).getMyGroupChannelChangeLogs(null !== (y = null === (m = this._metadata) || void 0 === m ? void 0 : m.token) && void 0 !== y ? y : "", t, A.SYNC_CHANNEL_CHANGELOGS)];
              case 7:
                return a = s.sent(), r = a.updatedChannels, i = a.deletedChannelUrls, o = a.hasMore, c = a.token, this._subscribers.dispatch("progress", {
                  updatedChannels: r,
                  deletedChannelUrls: i,
                  hasMore: o,
                  token: c
                }), e.hasNext = o, e.nextToken = c, this._metadata && (this._metadata.token = c), l.debug("channel changelog sync progress", e), [4, this.saveMetadata()];
              case 8:
                return s.sent(), d.localCacheEnabled && p.put(new _({
                  type: v.FEATURE_LOCALCACHE_EVENT,
                  ts: Date.now(),
                  data: {
                    channel_url: "",
                    measured_on: "channel_change_log_sync",
                    event: r.length > 0 || i.length > 0 ? "cache_miss" : "cache_hit",
                    max_db_size: r.length,
                    use_local_cache: d.localCacheEnabled,
                    starting_point: 0,
                    message_init_policy: i.length.toString(),
                    collection_id: u.userId
                  }
                })), [3, 12];
              case 9:
                return h = s.sent(), l.debug("channel changelog sync error", h), h instanceof g && h.isInvalidTokenError ? [4, this.clearMetadata()] : [3, 11];
              case 10:
                s.sent(), s.label = 11;
              case 11:
                throw h;
              case 12:
                return [2, e];
            }
          });
        });
      }), this._sync.on("stop", function () {
        a._subscribers.dispatch("pause");
      }), this._connectionEventContext = c.on(function (e) {
        if (e instanceof h) if (e.stateType === f.CONNECTED) a.resume();else a.pause();
      });
    }
    return t.of = function (e) {
      return Re[e] || (Re[e] = new t({
        _iid: e
      })), Re[e].ref++, Re[e];
    }, t.clear = function (e) {
      Re[e] && (Re[e].close(), delete Re[e]);
    }, Object.defineProperty(t.prototype, "isRunning", {
      get: function () {
        return this._sync.isRunning;
      },
      enumerable: !1,
      configurable: !0
    }), t.prototype.loadMetadata = function () {
      return e(this, void 0, void 0, function () {
        var e, t, n, a;
        return s(this, function (r) {
          switch (r.label) {
            case 0:
              return this._metadata ? [3, 2] : (e = i.of(this._iid), t = e.cacheContext, n = e.firstConnectedAt, [4, t.preference.get(this._metadataKey)]);
            case 1:
              a = r.sent(), this._metadata = {
                token: a ? a.token : n
              }, r.label = 2;
            case 2:
              return [2, this._metadata];
          }
        });
      });
    }, t.prototype.saveMetadata = function () {
      return e(this, void 0, void 0, function () {
        return s(this, function (e) {
          switch (e.label) {
            case 0:
              return this._metadata ? [4, i.of(this._iid).cacheContext.preference.set(this._metadataKey, this._metadata)] : [3, 2];
            case 1:
              return e.sent(), [2, !0];
            case 2:
              return [2, !1];
          }
        });
      });
    }, t.prototype.clearMetadata = function () {
      return e(this, void 0, void 0, function () {
        return s(this, function (e) {
          switch (e.label) {
            case 0:
              return [4, i.of(this._iid).cacheContext.preference.remove(this._metadataKey)];
            case 1:
              return e.sent(), this._metadata = void 0, [2];
          }
        });
      });
    }, t.prototype.on = function (e, t) {
      return this._subscribers.on(e, t);
    }, t.prototype.resume = function () {
      i.of(this._iid).sessionManager.session.hasSession && this._sync.start(0);
    }, t.prototype.pause = function () {
      this._sync.stop();
    }, t.prototype.close = function () {
      this.ref--, this.ref <= 0 && (this.ref = 0, this.pause(), this._connectionEventContext.close(), delete Re[this._iid]);
    }, t;
  }(),
  Ke = function (e, t) {
    return e.findIndex(function (e) {
      return e.isIdentical(t);
    });
  },
  Qe = function (e, t, n) {
    if (e.length > 0) {
      for (var a = Ke(e, t), r = 0, i = e.length - 1, s = Math.floor((r + i) / 2); r < i;) {
        var o = je(e[s], t, n);
        if (o > 0) i = s, s = Math.floor((r + i) / 2);else {
          if (!(o < 0)) return {
            place: s,
            oldPosition: a
          };
          r = s + 1, s = Math.floor((r + i) / 2);
        }
      }
      return {
        place: je(e[s], t, n) >= 0 ? s : s + 1,
        oldPosition: a
      };
    }
    return {
      place: e.length,
      oldPosition: -1
    };
  },
  je = function (e, t, n) {
    switch (n) {
      case Ge.LATEST_LAST_MESSAGE:
        return e.lastMessage && t.lastMessage ? 0 === (a = t.lastMessage.createdAt - e.lastMessage.createdAt) ? t.createdAt - e.createdAt : a : e.lastMessage ? -1 : t.lastMessage ? 1 : t.createdAt - e.createdAt;
      case Ge.CHRONOLOGICAL:
        return t.createdAt - e.createdAt;
      case Ge.CHANNEL_NAME_ALPHABETICAL:
        var a;
        return 0 === (a = e.name.localeCompare(t.name)) ? e.createdAt - t.createdAt : a;
      default:
        return 0;
    }
  },
  ze = function () {
    function t(t, n) {
      var a = n.filter,
        r = void 0 === a ? new ge() : a,
        o = n.order,
        l = void 0 === o ? Ge.LATEST_LAST_MESSAGE : o,
        u = n.limit,
        c = void 0 === u ? ke : u,
        d = n.includeChangesOnInitialLoad,
        p = void 0 !== d && d,
        _ = this;
      this.channels = [], this._isInitialized = !1, this._iid = t, this._key = "gcc-".concat(I()), this._isDisposed = !1, this._isGetRemoteChannelsSucceeded = !0, this._includeChangesOnInitialLoad = p, this.filter = r, this.order = l, this._hasMore = !0, this._token = "", this._limit = c;
      var v = i.of(this._iid),
        m = v.sdkState,
        g = v.cacheContext,
        y = v.dispatcher;
      g.localCacheEnabled && (this._backgroundSync = He.of(t), this._backgroundSync.resume()), this._changelogSync = Ve.of(t), this._changelogSync.resume(), ve.of(this._iid).subscribeChannelEvent(this._key, {
        onUpdate: function (e, t) {
          var n = t.source;
          if (me(n)) {
            var a = e.filter(function (e) {
                return _.filter.match(e, m.userId);
              }),
              r = e.filter(function (e) {
                return !_.filter.match(e, m.userId);
              }).map(function (e) {
                return e.url;
              });
            a.length > 0 && _._addChannelsToView(a, t), r.length > 0 && _._removeChannelsFromView(r, t);
          }
        },
        onRemove: function (e, t) {
          _._removeChannelsFromView(e, t);
        }
      }), y.on(function (t) {
        t instanceof h && (t.stateType === f.CONNECTED ? _._isGetRemoteChannelsSucceeded || e(_, void 0, void 0, function () {
          return s(this, function (e) {
            switch (e.label) {
              case 0:
                return [4, this._revokeLoadMore()];
              case 1:
                return e.sent(), [2];
            }
          });
        }) : t.stateType === f.LOGOUT && _.dispose());
      });
    }
    return Object.defineProperty(t.prototype, "hasMore", {
      get: function () {
        return !this._isDisposed && this._hasMore;
      },
      enumerable: !1,
      configurable: !0
    }), t.prototype.setGroupChannelCollectionHandler = function (e) {
      this._handler = e;
    }, t.prototype._addChannelsToView = function (t, n, a) {
      var r,
        i,
        o,
        l,
        u = this;
      void 0 === a && (a = !1);
      var c = [],
        h = [],
        d = [];
      try {
        for (var p = F(t), f = p.next(); !f.done; f = p.next()) {
          var _ = f.value,
            v = Ke(this.channels, _);
          v >= 0 && this.channels.splice(v, 1);
          var m = Qe(this.channels, _, this.order).place;
          if (v < 0) m === this.channels.length ? !a && this._hasMore || (c.push(_), this.channels.push(_)) : (c.push(_), this.channels.splice(m, 0, _));else switch (n.source) {
            case A.EVENT_CHANNEL_UPDATED:
            case A.EVENT_MESSAGE_RECEIVED:
            case A.SYNC_CHANNEL_CHANGELOGS:
            case A.EVENT_MESSAGE_SENT:
              this.order === Ge.CHANNEL_NAME_ALPHABETICAL && this._hasMore && m === this.channels.length ? d.push(_) : (this.channels.splice(m, 0, _), h.push(_));
              break;
            default:
              this.channels.splice(m, 0, _), h.push(_);
          }
        }
      } catch (e) {
        r = {
          error: e
        };
      } finally {
        try {
          f && !f.done && (i = p.return) && i.call(p);
        } finally {
          if (r) throw r.error;
        }
      }
      if (d.length > 0) try {
        for (var g = F(d), y = g.next(); !y.done; y = g.next()) {
          _ = y.value;
          var C = Ke(this.channels, _);
          -1 !== C && this.channels.splice(C, 1);
        }
      } catch (e) {
        o = {
          error: e
        };
      } finally {
        try {
          y && !y.done && (l = g.return) && l.call(g);
        } finally {
          if (o) throw o.error;
        }
      }
      me(n.source) && N(function () {
        return e(u, void 0, void 0, function () {
          var e, t, a;
          return s(this, function (r) {
            return c.length > 0 && (null === (e = this._handler) || void 0 === e ? void 0 : e.onChannelsAdded) && this._handler.onChannelsAdded(n, c), h.length > 0 && (null === (t = this._handler) || void 0 === t ? void 0 : t.onChannelsUpdated) && this._handler.onChannelsUpdated(n, h), d.length > 0 && (null === (a = this._handler) || void 0 === a ? void 0 : a.onChannelsDeleted) && this._handler.onChannelsDeleted(n, d.map(function (e) {
              return e.url;
            })), [2];
          });
        });
      });
    }, t.prototype._removeChannelsFromView = function (t, n) {
      var a,
        r,
        i = this,
        o = [],
        l = function (e) {
          var t = u.channels.findIndex(function (t) {
            return t.url === e;
          });
          t >= 0 && (o.push(u.channels[t].url), u.channels.splice(t, 1));
        },
        u = this;
      try {
        for (var c = F(t), h = c.next(); !h.done; h = c.next()) {
          l(h.value);
        }
      } catch (e) {
        a = {
          error: e
        };
      } finally {
        try {
          h && !h.done && (r = c.return) && r.call(c);
        } finally {
          if (a) throw a.error;
        }
      }
      return me(n.source) && o.length > 0 && N(function () {
        return e(i, void 0, void 0, function () {
          var e;
          return s(this, function (t) {
            return (null === (e = this._handler) || void 0 === e ? void 0 : e.onChannelsDeleted) && this._handler.onChannelsDeleted(n, o), [2];
          });
        });
      }), o;
    }, t.prototype._getLocalChannels = function () {
      return e(this, void 0, void 0, function () {
        var e, t;
        return s(this, function (n) {
          switch (n.label) {
            case 0:
              return e = ve.of(this._iid), t = this.channels.length > 0 ? function (e, t) {
                var n, a, r, i;
                switch (t) {
                  case Ge.LATEST_LAST_MESSAGE:
                    return null !== (a = null === (n = e.lastMessage) || void 0 === n ? void 0 : n.createdAt) && void 0 !== a ? a : e.createdAt;
                  case Ge.CHRONOLOGICAL:
                    return e.createdAt;
                  case Ge.CHANNEL_NAME_ALPHABETICAL:
                    return e.name;
                  default:
                    return null !== (i = null === (r = e.lastMessage) || void 0 === r ? void 0 : r.createdAt) && void 0 !== i ? i : e.createdAt;
                }
              }(this.channels[this.channels.length - 1], this.order) : null, [4, e.getChannelsFromCache(t, this.filter, this.order, this._limit, t ? this.channels[this.channels.length - 1].url : void 0)];
            case 1:
              return [2, n.sent()];
          }
        });
      });
    }, t.prototype._getRemoteChannels = function () {
      return e(this, void 0, void 0, function () {
        var e, t, n;
        return s(this, function (a) {
          switch (a.label) {
            case 0:
              return [4, ve.of(this._iid).getMyGroupChannels(this._token, R(z(z({}, this.filter), {
                userIdsFilter: this.filter.userIdsFilter,
                searchFilter: this.filter.searchFilter,
                order: this.order
              })), this._limit)];
            case 1:
              return e = a.sent(), t = e.channels, n = e.token, this._token = n, this._hasMore = !!n, [2, t];
          }
        });
      });
    }, t.prototype._revokeLoadMore = function () {
      return e(this, void 0, void 0, function () {
        var e;
        return s(this, function (t) {
          switch (t.label) {
            case 0:
              if (this._isDisposed) return [2];
              t.label = 1;
            case 1:
              return t.trys.push([1, 3,, 4]), [4, this._getRemoteChannels()];
            case 2:
              return e = t.sent(), this._isGetRemoteChannelsSucceeded = !0, this._addChannelsToView(e, {
                source: A.REQUEST_CHANNEL
              }, !0), [3, 4];
            case 3:
              return t.sent(), this._isGetRemoteChannelsSucceeded = !1, [3, 4];
            case 4:
              return [2];
          }
        });
      });
    }, t.prototype.loadMore = function () {
      var t;
      return e(this, void 0, void 0, function () {
        var n,
          a,
          r,
          o,
          l,
          u,
          c,
          h,
          d,
          p,
          f,
          _,
          m,
          y,
          C,
          b,
          S,
          M,
          E,
          w = this;
        return s(this, function (L) {
          switch (L.label) {
            case 0:
              if (this._isDisposed) throw new g({
                code: J.COLLECTION_DISPOSED,
                message: "Collection has been disposed."
              });
              return n = null, this._includeChangesOnInitialLoad && 0 === this.channels.length && this._changelogSync.isRunning ? [4, new Promise(function (e) {
                w._changelogSyncSubscriberContext = w._changelogSync.on("progress", function (t) {
                  return e(t);
                }).on("pause", function () {
                  return e(null);
                });
              })] : [3, 2];
            case 1:
              n = L.sent(), null === (t = this._changelogSyncSubscriberContext) || void 0 === t || t.close(), L.label = 2;
            case 2:
              return a = i.of(this._iid), r = a.cacheContext, o = a.connectionManager, l = a.statManager, this._isInitialized ? [3, 5] : (this._isInitialized = !0, r.localCacheEnabled ? [4, this._backgroundSync.loadMetadata()] : [3, 4]);
            case 3:
              L.sent(), L.label = 4;
            case 4:
              l.put(new B({
                type: v.FEATURE_LOCALCACHE,
                data: {
                  use_local_cache: r.localCacheEnabled,
                  collection_interface: {
                    group_channel: !0
                  }
                }
              })), L.label = 5;
            case 5:
              return u = [], this._hasMore ? !r.localCacheEnabled || o.isConnected && !this._backgroundSync.completed ? [3, 7] : [4, $(function () {
                return e(w, void 0, void 0, function () {
                  return s(this, function (e) {
                    switch (e.label) {
                      case 0:
                        return [4, this._getLocalChannels()];
                      case 1:
                        return u = e.sent(), [2];
                    }
                  });
                });
              })] : [3, 11];
            case 6:
              return L.sent(), this._hasMore = u.length >= this._limit, [3, 10];
            case 7:
              return L.trys.push([7, 9,, 10]), [4, this._getRemoteChannels()];
            case 8:
              return u = L.sent(), this._isGetRemoteChannelsSucceeded = !0, [3, 10];
            case 9:
              return L.sent(), this._isGetRemoteChannelsSucceeded = !1, [3, 10];
            case 10:
              this._addChannelsToView(u, {
                source: A.REQUEST_CHANNEL
              }, !0), L.label = 11;
            case 11:
              if (n) {
                try {
                  for (c = F(n.updatedChannels), h = c.next(); !h.done; h = c.next()) d = h.value, (C = Ke(u, d)) >= 0 ? u[C] = d : (p = Qe(u, d, this.order).place, u.splice(p, 0, d));
                } catch (e) {
                  b = {
                    error: e
                  };
                } finally {
                  try {
                    h && !h.done && (S = c.return) && S.call(c);
                  } finally {
                    if (b) throw b.error;
                  }
                }
                f = u.map(function (e) {
                  return e.url;
                });
                try {
                  for (_ = F(n.deletedChannelUrls), m = _.next(); !m.done; m = _.next()) y = m.value, (C = f.indexOf(y)) >= 0 && (u.splice(C, 1), f.splice(C, 1));
                } catch (e) {
                  M = {
                    error: e
                  };
                } finally {
                  try {
                    m && !m.done && (E = _.return) && E.call(_);
                  } finally {
                    if (M) throw M.error;
                  }
                }
              }
              return [2, u];
          }
        });
      });
    }, t.prototype.dispose = function () {
      var e, t, n;
      this._isDisposed || (this._isDisposed = !0, this.channels.length > 0 && this.channels.splice(0, this.channels.length), null === (e = this._backgroundSync) || void 0 === e || e.close(), null === (t = this._changelogSync) || void 0 === t || t.close(), null === (n = this._changelogSyncSubscriberContext) || void 0 === n || n.close(), ve.of(this._iid).unsubscribeChannelEvent(this._key));
    }, t;
  }(),
  We = function (e) {
    function t(t) {
      var n = this,
        a = t.token,
        r = t.limit,
        i = t.order,
        s = t.includeEmpty,
        o = t.membershipFilter,
        l = t.channelNameContainsFilter,
        u = t.channelUrlsFilter,
        c = t.customTypesFilter,
        h = t.customTypeStartsWithFilter,
        d = t.superChannelFilter,
        p = t.metadataOrderKeyFilter,
        f = t.metadataKey,
        _ = t.metadataValues,
        v = t.metadataValueStartsWith,
        m = t.includeFrozen,
        g = t.includeMetaData;
      return (n = e.call(this) || this).method = ee.GET, n.path = te, n.params = ne(R({
        token: a,
        limit: r,
        order: i,
        show_member: !0,
        show_read_receipt: !0,
        show_delivery_receipt: !0,
        show_empty: s,
        public_mode: ye.PUBLIC,
        public_membership_mode: o,
        name_contains: l,
        channel_urls: u,
        custom_types: c,
        custom_type_startswith: h,
        super_mode: d,
        metadata_order_key: p,
        metadata_key: f,
        metadata_values: _,
        metadata_value_startswith: v,
        show_frozen: m,
        show_metadata: g
      })), n;
    }
    return Z(t, e), t;
  }(ae),
  Be = function (e) {
    function t(t, n) {
      var a = e.call(this, t, n) || this;
      a.channels = [];
      var r = n.next,
        i = n.channels,
        s = n.ts;
      return a.token = r, i && i.length > 0 && (a.channels = i.map(function (e) {
        return e.ts = s, new Ce(t, e);
      })), a.ts = "number" == typeof s ? s : 0, a;
    }
    return Z(t, e), t;
  }(re);
!function (e) {
  e.ALL = "all", e.JOINED = "joined";
}(De || (De = {}));
var qe = function (t) {
    function n(e, n) {
      var a,
        r,
        i,
        s,
        o,
        l,
        u,
        c,
        h,
        d,
        p,
        f,
        _,
        v,
        m = this;
      return (m = t.call(this, e, n) || this).includeEmpty = !1, m.includeFrozen = !0, m.includeMetaData = !0, m.channelUrlsFilter = null, m.customTypesFilter = null, m.customTypeStartsWithFilter = null, m.channelNameContainsFilter = null, m.membershipFilter = De.ALL, m.superChannelFilter = Se.ALL, m.metadataKey = null, m.metadataValues = null, m.metadataOrderKeyFilter = null, m.metadataValueStartsWith = null, m.order = Ue.CHRONOLOGICAL, m.includeEmpty = null !== (a = n.includeEmpty) && void 0 !== a && a, m.includeFrozen = null === (r = n.includeFrozen) || void 0 === r || r, m.includeMetaData = null === (i = n.includeMetaData) || void 0 === i || i, m.channelUrlsFilter = null !== (s = n.channelUrlsFilter) && void 0 !== s ? s : null, m.customTypesFilter = null !== (o = n.customTypesFilter) && void 0 !== o ? o : null, m.customTypeStartsWithFilter = null !== (l = n.customTypeStartsWithFilter) && void 0 !== l ? l : null, m.channelNameContainsFilter = null !== (u = n.channelNameContainsFilter) && void 0 !== u ? u : null, m.membershipFilter = null !== (c = n.membershipFilter) && void 0 !== c ? c : De.ALL, m.superChannelFilter = null !== (h = n.superChannelFilter) && void 0 !== h ? h : Se.ALL, m.metadataKey = null !== (d = n.metadataKey) && void 0 !== d ? d : null, m.metadataValues = null !== (p = n.metadataValues) && void 0 !== p ? p : null, m.metadataOrderKeyFilter = null !== (f = n.metadataOrderKeyFilter) && void 0 !== f ? f : null, m.metadataValueStartsWith = null !== (_ = n.metadataValueStartsWith) && void 0 !== _ ? _ : null, m.order = null !== (v = n.order) && void 0 !== v ? v : Ue.CHRONOLOGICAL, m;
    }
    return Z(n, t), n.prototype._validate = function () {
      return t.prototype._validate.call(this) && ie("boolean", this.includeEmpty) && ie("boolean", this.includeFrozen) && ie("boolean", this.includeMetaData) && ie("string", this.channelNameContainsFilter, !0) && se("string", this.channelUrlsFilter, !0) && se("string", this.customTypesFilter, !0) && ie("string", this.customTypeStartsWithFilter, !0) && oe(De, this.membershipFilter) && oe(Se, this.superChannelFilter) && oe(Ue, this.order) && ie("string", this.metadataOrderKeyFilter, !0) && ie("string", this.metadataKey, !0) && se("string", this.metadataValues, !0) && ie("string", this.metadataValueStartsWith, !0);
    }, n.prototype.next = function () {
      return e(this, void 0, void 0, function () {
        var e, t, n, a, r, o, l;
        return s(this, function (s) {
          switch (s.label) {
            case 0:
              return this._validate() ? this._isLoading ? [3, 3] : (e = [], this._hasNext ? (this._isLoading = !0, t = i.of(this._iid).requestQueue, n = new We(R(z(z({}, this), {
                token: this._token
              }))), [4, t.send(n)]) : [3, 2]) : [3, 5];
            case 1:
              return a = s.sent(), r = a.as(Be), o = r.channels, l = r.token, this._token = l, this._hasNext = !!l, this._isLoading = !1, [2, o];
            case 2:
              return [2, e];
            case 3:
              throw g.queryInProgress;
            case 4:
              return [3, 6];
            case 5:
              throw g.invalidParameters;
            case 6:
              return [2];
          }
        });
      });
    }, n;
  }(le),
  Ye = function (e) {
    function t(t) {
      void 0 === t && (t = {});
      var n = e.call(this) || this;
      return Object.keys(t).forEach(function (e) {
        n.hasOwnProperty(e) && (n[e] = t[e]);
      }), n;
    }
    return Z(t, e), t;
  }(function (e) {
    function t() {
      var t = null !== e && e.apply(this, arguments) || this;
      return t.onUserJoined = ue, t.onUserLeft = ue, t.onUserReceivedInvitation = ue, t.onUserDeclinedInvitation = ue, t.onChannelHidden = ue, t.onUnreadMemberStatusUpdated = ue, t.onUndeliveredMemberStatusUpdated = ue, t.onTypingStatusUpdated = ue, t.onPollUpdated = ue, t.onPollVoted = ue, t.onPollDeleted = ue, t.onPinnedMessageUpdated = ue, t;
    }
    return Z(t, e), t;
  }(Ie)),
  Xe = function (e) {
    function t(t) {
      var n = this,
        a = t.token,
        r = t.limit,
        i = t.order,
        s = t.reverse,
        o = t.channelUrl,
        l = t.messageTypeFilter,
        u = t.scheduledStatus;
      return (n = e.call(this) || this).method = ee.GET, n.path = "".concat(ce), n.params = ne(R({
        token: a,
        limit: r,
        reverse: s,
        channel_url: o,
        order: i,
        message_type: l,
        status: u
      })), n;
    }
    return Z(t, e), t;
  }(ae),
  Je = function (e) {
    function t(t, n) {
      var a = e.call(this, t, n) || this;
      a.scheduledMessages = [];
      var r = n.next,
        i = n.scheduled_messages;
      return a.token = r, a.scheduledMessages = i.map(function (e) {
        return Ne(t, e);
      }), a;
    }
    return Z(t, e), t;
  }(re),
  $e = function (t) {
    function n(e, n) {
      var a,
        r,
        i,
        s,
        o,
        l = this;
      return (l = t.call(this, e, n) || this).channelUrl = null, l.order = null, l.reverse = !1, l.scheduledStatus = null, l.messageTypeFilter = he.ALL, l.channelUrl = null !== (a = n.channelUrl) && void 0 !== a ? a : null, l.order = null !== (r = n.order) && void 0 !== r ? r : null, l.reverse = null !== (i = n.reverse) && void 0 !== i && i, l.scheduledStatus = null !== (s = n.scheduledStatus) && void 0 !== s ? s : null, l.messageTypeFilter = null !== (o = n.messageTypeFilter) && void 0 !== o ? o : he.ALL, l;
    }
    return Z(n, t), n.prototype._validate = function () {
      return t.prototype._validate.call(this) && ie("string", this.channelUrl, !0) && (oe(Oe, this.order) || null === this.order) && ie("boolean", this.reverse) && (se(xe, this.scheduledStatus) || null === this.scheduledStatus) && oe(he, this.messageTypeFilter);
    }, n.prototype.next = function () {
      return e(this, void 0, void 0, function () {
        var e, t, n, a, r, o;
        return s(this, function (s) {
          switch (s.label) {
            case 0:
              return this._validate() ? this._isLoading ? [3, 3] : this._hasNext ? (this._isLoading = !0, e = i.of(this._iid).requestQueue, t = new Xe(R(z(z({}, this), {
                token: this._token
              }))), [4, e.send(t)]) : [3, 2] : [3, 5];
            case 1:
              return n = s.sent(), a = n.as(Je), r = a.scheduledMessages, o = a.token, this._token = o, this._hasNext = !!o, this._isLoading = !1, [2, r];
            case 2:
              return [2, []];
            case 3:
              throw g.queryInProgress;
            case 4:
              return [3, 6];
            case 5:
              throw g.invalidParameters;
            case 6:
              return [2];
          }
        });
      });
    }, n;
  }(le),
  Ze = function (t) {
    function n() {
      var e = null !== t && t.apply(this, arguments) || this;
      return e.name = "groupChannel", e;
    }
    return Z(n, t), n.prototype.init = function (e, n) {
      var a = n.sdkState,
        r = n.dispatcher,
        i = n.sessionManager,
        s = n.requestQueue,
        o = n.logger,
        l = n.onlineDetector,
        u = n.cacheContext;
      t.prototype.init.call(this, e, {
        sdkState: a,
        dispatcher: r,
        sessionManager: i,
        requestQueue: s,
        logger: o,
        onlineDetector: l,
        cacheContext: u
      }), this._manager = new ve(e, {
        sdkState: a,
        cacheContext: u,
        dispatcher: r,
        sessionManager: i,
        requestQueue: s,
        logger: o
      });
    }, n.prototype.createGroupChannelCollection = function (e) {
      return void 0 === e && (e = {}), new ze(this._iid, e);
    }, n.prototype.createMyGroupChannelListQuery = function (e) {
      return void 0 === e && (e = {}), new Me(this._iid, e);
    }, n.prototype.createPublicGroupChannelListQuery = function (e) {
      return void 0 === e && (e = {}), new qe(this._iid, e);
    }, n.prototype.createScheduledMessageListQuery = function (e) {
      return void 0 === e && (e = {}), new $e(this._iid, e);
    }, n.prototype.addGroupChannelHandler = function (e, t) {
      de(ie("string", e) && t instanceof Ye).throw(g.invalidParameters), this._manager.addHandler(e, t);
    }, n.prototype.removeGroupChannelHandler = function (e) {
      de(ie("string", e)).throw(g.invalidParameters), this._manager.removeHandler(e);
    }, n.prototype.removeAllGroupChannelHandlers = function () {
      this._manager.clearHandler();
    }, n.prototype.buildGroupChannelFromSerializedData = function (e) {
      return this._manager.buildGroupChannelFromSerializedData(e);
    }, n.prototype.buildGroupChannelListQueryFromSerializedData = function (e) {
      return this._manager.buildGroupChannelListQueryFromSerializedData(e);
    }, n.prototype.buildMemberFromSerializedData = function (e) {
      return this._manager.buildMemberFromSerializedData(e);
    }, n.prototype.getChannel = function (t) {
      return e(this, void 0, void 0, function () {
        return s(this, function (e) {
          return de(ie("string", t)).throw(g.invalidParameters), [2, this._manager.getChannel(t)];
        });
      });
    }, n.prototype.getChannelWithoutCache = function (t) {
      return e(this, void 0, void 0, function () {
        return s(this, function (e) {
          return de(ie("string", t)).throw(g.invalidParameters), [2, this._manager.getChannelWithoutCache(t)];
        });
      });
    }, n.prototype.getMyGroupChannelChangeLogsByToken = function (t, n) {
      return void 0 === n && (n = {}), e(this, void 0, void 0, function () {
        var e;
        return s(this, function (a) {
          switch (a.label) {
            case 0:
              return e = z(z({}, Ee), n), de(ie("string", t) && Ae(e)).throw(g.invalidParameters), [4, this._manager.getMyGroupChannelChangeLogs(t, e)];
            case 1:
              return [2, a.sent()];
          }
        });
      });
    }, n.prototype.getMyGroupChannelChangeLogsByTimestamp = function (t, n) {
      return void 0 === n && (n = {}), e(this, void 0, void 0, function () {
        var e;
        return s(this, function (a) {
          switch (a.label) {
            case 0:
              return e = z(z({}, Ee), n), de(ie("number", t) && Ae(e)).throw(g.invalidParameters), [4, this._manager.getMyGroupChannelChangeLogs(t, e)];
            case 1:
              return [2, a.sent()];
          }
        });
      });
    }, n.prototype.getGroupChannelCount = function (t) {
      return e(this, void 0, void 0, function () {
        var e;
        return s(this, function (n) {
          return e = z(z({}, we), t), de(Le(e)).throw(g.invalidParameters), [2, this._manager.getGroupChannelCount(e)];
        });
      });
    }, n.prototype.getUnreadItemCount = function (t) {
      return void 0 === t && (t = {}), e(this, void 0, void 0, function () {
        return s(this, function (e) {
          switch (e.label) {
            case 0:
              return [4, this._manager.getUnreadItemCount(t)];
            case 1:
              return [2, e.sent()];
          }
        });
      });
    }, n.prototype.getTotalUnreadChannelCount = function () {
      return e(this, void 0, void 0, function () {
        return s(this, function (e) {
          switch (e.label) {
            case 0:
              return [4, this._manager.getTotalUnreadChannelCount()];
            case 1:
              return [2, e.sent()];
          }
        });
      });
    }, n.prototype.getTotalUnreadMessageCount = function (t) {
      return void 0 === t && (t = {}), e(this, void 0, void 0, function () {
        return s(this, function (e) {
          switch (e.label) {
            case 0:
              return [4, this._manager.getTotalUnreadMessageCount(t)];
            case 1:
              return [2, e.sent()];
          }
        });
      });
    }, n.prototype.getTotalScheduledMessageCount = function (t) {
      return void 0 === t && (t = {}), e(this, void 0, void 0, function () {
        return s(this, function (e) {
          switch (e.label) {
            case 0:
              return [4, this._manager.getTotalScheduledMessageCount(t)];
            case 1:
              return [2, e.sent()];
          }
        });
      });
    }, n.prototype.getSubscribedTotalUnreadMessageCount = function () {
      return this._manager.getSubscribedTotalUnreadMessageCount();
    }, n.prototype.getSubscribedCustomTypeTotalUnreadMessageCount = function () {
      return this._manager.getSubscribedCustomTypeTotalUnreadMessageCount();
    }, n.prototype.getSubscribedCustomTypeUnreadMessageCount = function (e) {
      return this._manager.getSubscribedCustomTypeUnreadMessageCount(e);
    }, n.prototype.createChannel = function (t) {
      return void 0 === t && (t = {}), e(this, void 0, void 0, function () {
        var e;
        return s(this, function (n) {
          return e = z(z({}, Te), t), de(Fe(e)).throw(g.invalidParameters), [2, this._manager.createChannel(e)];
        });
      });
    }, n.prototype.createDistinctChannelIfNotExist = function (t) {
      return void 0 === t && (t = {}), e(this, void 0, void 0, function () {
        var e;
        return s(this, function (n) {
          return e = z(z({}, Te), t), de(Fe(e)).throw(g.invalidParameters), e && (e.isDistinct = !0), [2, this.createChannel(e)];
        });
      });
    }, n.prototype.createChannelWithUserIds = function (t, n, a, r, i, o) {
      return void 0 === n && (n = !1), void 0 === i && (i = ""), void 0 === o && (o = ""), e(this, void 0, void 0, function () {
        var e;
        return s(this, function (s) {
          return e = z(z({}, Te), {
            invitedUserIds: t,
            isDistinct: n,
            name: a,
            data: i,
            customType: o
          }), "string" == typeof r ? e.coverUrl = r : e.coverImage = r, [2, this.createChannel(e)];
        });
      });
    }, n.prototype.markAsReadAll = function () {
      return e(this, void 0, void 0, function () {
        return s(this, function (e) {
          return this._manager.markAsReadAll(), [2];
        });
      });
    }, n.prototype.markAsReadWithChannelUrls = function (t) {
      return e(this, void 0, void 0, function () {
        return s(this, function (e) {
          return de(se("string", t)).throw(g.invalidParameters), this._manager.markAsReadWithChannelUrls(t), [2];
        });
      });
    }, n.prototype.markAsDelivered = function (t) {
      return e(this, void 0, void 0, function () {
        return s(this, function (e) {
          switch (e.label) {
            case 0:
              return de(ie("string", t)).throw(g.invalidParameters), [4, this.getChannel(t)];
            case 1:
              return [4, e.sent().markAsDelivered()];
            case 2:
              return e.sent(), [2];
          }
        });
      });
    }, n;
  }(pe);
export { ze as GroupChannelCollection, Ye as GroupChannelHandler, Ze as GroupChannelModule, De as MembershipFilter, qe as PublicGroupChannelListQuery, $e as ScheduledMessageListQuery };