import { MatPaginatorIntl } from "@angular/material/paginator";

export function fromArray(data: [any] | any[]): any | undefined {
  for (let json of data) {
    if (json === undefined) {
      return undefined;
    }
  }
  return data;
}
export function containsObject(obj: any, list: [any] | any[]): boolean {
  for (let i = 0; i < list.length; i++) {
    if (list[i] === obj) {
      return true;
    }
  }
  return false;
}
export function roundDecimal(value, decimals): number {
  return Number(Number(value).toFixed(decimals));
}
export function financial(x) {
  return Number.parseFloat(x).toFixed(2);
}
export function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}
export function containsSlashBeg(myUrl) {
  if (myUrl.substr(0, 1) === '/') {
    myUrl = myUrl.substr(1, myUrl.length);
  }

  return myUrl;
}
export function getRandomInt(min, max) {
  // public random = Math.floor(Math.random() * (999999 - 100000)) + 100000;
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
export function randomExcluded(start, end, excluded) {
  if (start >= end) {
    return start;
  }
  var n = excluded
  while (n == excluded)
    n = Math.floor((Math.random() * (end - start + 1) + start));
  return n;

}
export function parseJSON(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
}
export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
export function validateSite(email) {
  const re = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!re.test(email);
}
export function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:" || url.protocol === "rtmp:";
}
export function isValidHttpsUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "https:";
}
export function matchYoutubeUrl(url) {
  let p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  if (url.match(p)) {
    return url.match(p)[1];
  }
  return false;
}
export function matchFacebookUrl(url) {
  // let p = /^https?:\/\/www\.facebook\.com.*\/(video(s)?|watch|story)(\.php?|\/).+$/;
  let p = /^https?:\/\/(www\.)?(facebook\.com)\/(video(s)?|watch|story)?(\/)?.*(\/)?$/;
  // let p = /^https?:\/\/(www\.)?(facebook\.com|fb.watch)\/(video(s)?|watch|story)?(\/)?.*(\/)?$/;
  // let p = /^(https?://www\.facebook\.com\/(?:video\.php\?v=\d+|.*?/videos/\d+))$/;
  if (url.match(p)) {
    return url.match(p)[0];
  }
  return false;
}
export function Utf8Encode(data) {
  data = data.replace(/\r\n/g, '\n');
  let utftext = '';

  for (let n = 0; n < data.length; n++) {

    let c = data.charCodeAt(n);

    if (c < 128) {
      utftext += String.fromCharCode(c);
    } else if ((c > 127) && (c < 2048)) {
      utftext += String.fromCharCode((c >> 6) | 192);
      utftext += String.fromCharCode((c & 63) | 128);
    } else {
      utftext += String.fromCharCode((c >> 12) | 224);
      utftext += String.fromCharCode(((c >> 6) & 63) | 128);
      utftext += String.fromCharCode((c & 63) | 128);
    }

  }

  return utftext;
};
export function hexToRgbA(hex, opacity = 1) {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
  }
  throw new Error('Bad Hex');
}
export function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
export function getFileName(url, includeExtension: boolean = true) {
  var matches = url && typeof url.match === "function" && url.match(/\/?([^/.]*)\.?([^/]*)$/);
  if (!matches)
    return null;

  if (includeExtension && matches.length > 2 && matches[2]) {
    return matches.slice(1).join(".");
  }
  return matches[1];
}
export function detectMob() {
  const toMatch = [
    /Mobile/i,
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}
export function calculateRatio(num_1, num_2) {
  for (let num = num_2; num > 1; num--) {
    if ((num_1 % num) == 0 && (num_2 % num) == 0) {
      num_1 = num_1 / num;
      num_2 = num_2 / num;
    }
  }
  return [num_1, num_2];
}
export function getOtherImage(preAdd, url, postAdd = '') {
  if (url) {
    let file = url.substring(url.lastIndexOf('/') + 1);
    let fileExt = file.split('.').pop();
    let fileName = file.split('.').slice(0, -1).join('.');
    let returnUrl = url.substring(0, url.lastIndexOf("/"));
    return returnUrl + '/' + preAdd + fileName + postAdd + '.' + fileExt;
  }
  return url;
}
export function addUrlParam(url, name, value) {
  const separator = url.indexOf('?') > -1 ? '&' : '?';
  return `${url}${separator}${name}=${encodeURIComponent(value)}`;
}
export function deepEqual(obj1, obj2) {
  // Check if both arguments are objects
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
    return obj1 === obj2;
  }

  // Get the keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Check each key and its value
  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

export function sanitizeFilename(filename) {
  // Define a regular expression to match characters not allowed in filenames
  const illegalCharsRegex = /[\/\?<>\\:*|"]/g;

  // Replace illegal characters with underscores
  const sanitizedFilename = filename.replace(illegalCharsRegex, '_');

  return sanitizedFilename;
}
export function arrayToCSV(arr) {
  return arr.join(', ');
}

export function getCSSVariableValue(variableName: string) {
  let hex = getComputedStyle(document.documentElement).getPropertyValue(variableName)
  if (hex && hex.length > 0) {
    hex = hex.trim()
  }

  return hex
}

export function CustomPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.itemsPerPageLabel = 'Items Per Page';

  return customPaginatorIntl;
}

export function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

export function compareString(a: string, b: string, isAsc: boolean) {
  a = a?.toLowerCase();
  b = b?.toLowerCase();
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

export function capitalizeFirstLetter(str: string): string {
  if (!str) {
    return str;
  }
  return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
}

export function arraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
}

export function countAndGroupWords(text: string): string[] {
  const allWords = text.trim().split(/\s+/);
  let groupedWords = [];

  let tempGroup: string[] = [];
  for (let i = 0; i < allWords.length; i++) {
    const word = allWords[i];
    tempGroup.push(word);

    // Check if the group has two countable words
    if (tempGroup.filter(w => w.length > 2).length === 2) {
      groupedWords.push(tempGroup.join(' '));
      tempGroup = [];
    }
  }

  // Push any remaining words into the last group
  if (tempGroup.length > 0) {
    groupedWords.push(tempGroup.join(' '));
  }

  return groupedWords;
}

export function getMonochromeGradient(baseColor: string, length: number): string[] {
  const gradientColors = [];
  const shadeStep = 20; // Adjust step for gradient intensity

  for (let i = 0; i < length; i++) {
    // Generate lighter shades by reducing brightness
    const shade = adjustBrightness(baseColor, i * shadeStep - (shadeStep * (length / 2)));
    gradientColors.push(shade);
  }

  return gradientColors;
}

export function adjustBrightness(hex: string, percentage: number): string {
  const num = parseInt(hex.replace('#', ''), 16);

  const r = Math.max(0, Math.min(255, ((num >> 16) & 0xff) + percentage));
  const g = Math.max(0, Math.min(255, ((num >> 8) & 0xff) + percentage));
  const b = Math.max(0, Math.min(255, (num & 0xff) + percentage));

  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
}

export function isColorLightOrDark(hex: string): string {
  // Convert hex to RGB
  const rgb = hexToRgb(hex);
  if (!rgb) return 'Invalid color';

  const { r, g, b } = rgb;

  // Calculate perceived brightness
  const brightness = 0.299 * r + 0.587 * g + 0.114 * b;

  // Compare against the threshold
  return brightness > 128 ? 'light' : 'dark';
}

export function hexToRgb(hex: string): { r: number; g: number; b: number } | null {
  // Remove the '#' if present
  hex = hex.replace('#', '');

  // Parse hex values
  if (hex.length === 3) {
    // Expand shorthand format (e.g., #f0f becomes #ff00ff)
    hex = hex.split('').map(c => c + c).join('');
  }

  if (hex.length !== 6) return null;

  const bigint = parseInt(hex, 16);
  return {
    r: (bigint >> 16) & 255, // Extract red
    g: (bigint >> 8) & 255,  // Extract green
    b: bigint & 255,         // Extract blue
  };
}

export function getDarkerColor(hexColor, factor = 0.2) {
  // Remove the hash if present
  hexColor = hexColor.replace('#', '');

  // Parse the hex color into RGB components
  let r = parseInt(hexColor.substring(0, 2), 16);
  let g = parseInt(hexColor.substring(2, 4), 16);
  let b = parseInt(hexColor.substring(4, 6), 16);

  // Darken each component by the factor
  r = Math.max(0, Math.floor(r * (1 - factor)));
  g = Math.max(0, Math.floor(g * (1 - factor)));
  b = Math.max(0, Math.floor(b * (1 - factor)));

  // Convert the components back to hex and pad with zeros if necessary
  const darkerColor = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;

  return darkerColor;
}

export function numberToText(num: number): string {
  if (num >= 1_000_000_000) {
    return (num / 1_000_000_000).toFixed(1).replace(/\.0$/, '') + 'B'; // Billions
  } else if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M'; // Millions
  } else if (num >= 1_000) {
    return (num / 1_000).toFixed(1).replace(/\.0$/, '') + 'K'; // Thousands
  }
  return num.toString(); // Less than 1,000, return the number as-is
}