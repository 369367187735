import { c as e, e as t, u as n, g as s, X as r, a8 as a, C as i, U as o, ag as u, bg as c, w as l, s as h, p as d, q as _, _ as p, b as f, t as v, Q as m, a as g, i as E, ar as C, a2 as y, b9 as M, B as b, A as N, d as U, f as S, h as T, bh as A, aA as I, bd as w, W as L, al as D, bi as x, aj as R, aP as F, V as P, z as k, O, G as H, a3 as G, bf as V, aN as B, aU as z, aT as q, ao as j, aS as W, aX as Q, bj as Y, a9 as K, bk as J, E as X, D as Z, F as $, ba as ee, bb as te, az as ne, aO as se, am as re, ap as ae, bl as ie, n as oe, aB as ue, bm as ce, bc as le, aC as he, ak as de, aG as _e, a5 as pe, ab as fe, ax as ve, $ as me, aW as ge } from "./__bundle-2f7467b8.js";
import { G as Ee, a as Ce, Q as ye, V as Me, Z as be, t as Ne, u as Ue, _ as Se, $ as Te, a0 as Ae, a1 as Ie, a2 as we, K as Le, v as De, a3 as xe, a4 as Re, a5 as Fe, a6 as Pe, a7 as ke, a8 as Oe, a9 as He, aa as Ge, ab as Ve, ac as Be, ad as ze, ae as qe, af as je, ag as We, ah as Qe, ai as Ye, aj as Ke, ak as Je, al as Xe, T as Ze, am as $e, an as et, ao as tt, ap as nt, aq as st, ar as rt, as as at, at as it, au as ot, Y as ut, av as ct, aw as lt, ax as ht, ay as dt, az as _t, aA as pt, aB as ft, aC as vt, z as mt, aD as gt, aE as Et, aF as Ct, aG as yt, aH as Mt, aI as bt, aJ as Nt, aK as Ut, aL as St } from "./__bundle-d4f5ef6d.js";
import { G as Tt, g as At, D as It, c as wt, R as Lt, U as Dt, a as xt, b as Rt, A as Ft } from "./__bundle-04894092.js";
import { C as Pt, B as kt, U as Ot, a as Ht, O as Gt, P as Vt, M as Bt, T as zt, R as qt, b as jt, A as Wt } from "./__bundle-5d883d52.js";
var Qt;
!function (e) {
  e.NONE = "none", e.JOINED = "joined", e.INVITED = "invited", e.LEFT = "left";
}(Qt || (Qt = {}));
var Yt,
  Kt,
  Jt,
  Xt,
  Zt,
  $t,
  en,
  tn,
  nn = function (i) {
    function o(e, t) {
      var n = i.call(this, e, t) || this;
      return n.state = null, n.role = null, n.isMuted = !1, n.isBlockedByMe = !1, n.isBlockingMe = !1, n.state = r(Qt, t.state) ? t.state : null, n.role = r(a, t.role) ? t.role : null, "boolean" == typeof t.is_muted && (n.isMuted = t.is_muted), "boolean" == typeof t.is_blocked_by_me && (n.isBlockedByMe = t.is_blocked_by_me), "boolean" == typeof t.is_blocking_me && (n.isBlockingMe = t.is_blocking_me), n;
    }
    return e(o, i), o.payloadify = function (e) {
      return t(n(s(s({}, i.payloadify.call(this, e)), {
        state: e.state,
        role: e.role,
        is_muted: e.isMuted,
        is_blocked_by_me: e.isBlockedByMe,
        is_blocking_me: e.isBlockingMe
      })));
    }, o;
  }(Ee),
  sn = function (t) {
    function n(e, n) {
      var s,
        r,
        a = this;
      return (a = t.call(this, e) || this).channelUrl = null !== (s = n.channel_url) && void 0 !== s ? s : "", a.channelType = null !== (r = n.channel_type) && void 0 !== r ? r : i.GROUP, a.member = new o(a._iid, n.user), a.deliveryAt = n.ts, a;
    }
    return e(n, t), n;
  }(u);
!function (e) {
  e.ALL = "all", e.PUBLIC = "public", e.PRIVATE = "private";
}(Yt || (Yt = {})), function (e) {
  e.ALL = "all", e.JOINED = "joined_only", e.INVITED = "invited_only", e.INVITED_BY_FRIEND = "invited_by_friend", e.INVITED_BY_NON_FRIEND = "invited_by_non_friend";
}(Kt || (Kt = {})), function (e) {
  e.ALL = "all", e.SUPER = "super", e.NON_SUPER = "nonsuper", e.BROADCAST_ONLY = "broadcast_only", e.EXCLUSIVE_ONLY = "exclusive_only";
}(Jt || (Jt = {})), function (e) {
  e.ALL = "all", e.UNREAD_MESSAGE = "unread_message";
}(Xt || (Xt = {})), function (e) {
  e.ALL = "all", e.UNHIDDEN = "unhidden_only", e.HIDDEN = "hidden_only", e.HIDDEN_ALLOW_AUTO_UNHIDE = "hidden_allow_auto_unhide", e.HIDDEN_PREVENT_AUTO_UNHIDE = "hidden_prevent_auto_unhide";
}(Zt || (Zt = {})), function (e) {
  e.ALL = "all", e.OPERATOR = "operator", e.NONOPERATOR = "nonoperator";
}($t || ($t = {})), function (e) {
  e.AND = "AND", e.OR = "OR";
}(en || (en = {})), function (e) {
  e.MEMBER_NICKNAME = "member_nickname", e.CHANNEL_NAME = "channel_name";
}(tn || (tn = {}));
var rn,
  an = function () {
    function e(e) {
      var t, n, s, r, a, i, o, u, c, l, h, d, _, p, f;
      this._searchFilter = null, this._userIdsFilter = null, this.includeEmpty = null !== (t = null == e ? void 0 : e.includeEmpty) && void 0 !== t && t, this.nicknameContainsFilter = null !== (n = null == e ? void 0 : e.nicknameContainsFilter) && void 0 !== n ? n : null, this.nicknameStartsWithFilter = null !== (s = null == e ? void 0 : e.nicknameStartsWithFilter) && void 0 !== s ? s : null, this.nicknameExactMatchFilter = null !== (r = null == e ? void 0 : e.nicknameExactMatchFilter) && void 0 !== r ? r : null, this.channelNameContainsFilter = null !== (a = null == e ? void 0 : e.channelNameContainsFilter) && void 0 !== a ? a : "", this.myMemberStateFilter = null !== (i = null == e ? void 0 : e.myMemberStateFilter) && void 0 !== i ? i : Kt.ALL, this.customTypesFilter = null !== (o = null == e ? void 0 : e.customTypesFilter) && void 0 !== o ? o : null, this.channelUrlsFilter = null !== (u = null == e ? void 0 : e.channelUrlsFilter) && void 0 !== u ? u : null, this.superChannelFilter = null !== (c = null == e ? void 0 : e.superChannelFilter) && void 0 !== c ? c : Jt.ALL, this.publicChannelFilter = null !== (l = null == e ? void 0 : e.publicChannelFilter) && void 0 !== l ? l : Yt.ALL, this.customTypeStartsWithFilter = null !== (h = null == e ? void 0 : e.customTypeStartsWithFilter) && void 0 !== h ? h : null, this.unreadChannelFilter = null !== (d = null == e ? void 0 : e.unreadChannelFilter) && void 0 !== d ? d : Xt.ALL, this.hiddenChannelFilter = null !== (_ = null == e ? void 0 : e.hiddenChannelFilter) && void 0 !== _ ? _ : Zt.UNHIDDEN, this.includeFrozen = null === (p = null == e ? void 0 : e.includeFrozen) || void 0 === p || p, (null == e ? void 0 : e.createdAfter) && (this.createdAfter = e.createdAfter), (null == e ? void 0 : e.createdBefore) && (this.createdBefore = e.createdBefore), this.includeMetaData = null === (f = null == e ? void 0 : e.includeMetaData) || void 0 === f || f;
    }
    return e.prototype._isFriend = function (e) {
      return !(!e || !e.friendDiscoveryKey && !e.friendName);
    }, Object.defineProperty(e.prototype, "searchFilter", {
      get: function () {
        return this._searchFilter;
      },
      enumerable: !1,
      configurable: !0
    }), e.prototype.setSearchFilter = function (e, t) {
      Array.isArray(e) && 0 !== e.length && "string" == typeof t && t && (this._searchFilter = {
        query: t,
        fields: e
      });
    }, Object.defineProperty(e.prototype, "userIdsFilter", {
      get: function () {
        return this._userIdsFilter;
      },
      enumerable: !1,
      configurable: !0
    }), e.prototype.setUserIdsFilter = function (e, t, n) {
      void 0 === n && (n = en.AND), this._userIdsFilter = {
        userIds: e,
        includeMode: t,
        queryType: n
      };
    }, e.prototype.clone = function () {
      var t,
        n = new e();
      this.searchFilter && n.setSearchFilter(this.searchFilter.fields, null !== (t = this.searchFilter.query) && void 0 !== t ? t : void 0), this.userIdsFilter && n.setUserIdsFilter(this.userIdsFilter.userIds, this.userIdsFilter.includeMode, this.userIdsFilter.queryType);
      var s = JSON.parse(JSON.stringify(this));
      return Object.keys(s).forEach(function (e) {
        n[e] = s[e];
      }), n;
    }, e.prototype.match = function (e, t) {
      if (this._searchFilter) {
        var n = this._searchFilter,
          s = n.query,
          r = n.fields;
        if (s && r && r.length > 0 && !r.some(function (t) {
          switch (t) {
            case tn.CHANNEL_NAME:
              return e.name.toLowerCase().includes(s.toLowerCase());
            case tn.MEMBER_NICKNAME:
              return e.members.some(function (e) {
                return e.nickname.toLowerCase().includes(s.toLowerCase());
              });
            default:
              return !0;
          }
        })) return !1;
      }
      if (this._userIdsFilter) {
        var a = this._userIdsFilter,
          i = a.userIds,
          o = a.includeMode,
          u = a.queryType,
          l = e.members.map(function (e) {
            return e.userId;
          });
        if (o) {
          if (i.length > 0) switch (u) {
            case en.AND:
              if (i.some(function (e) {
                return !l.includes(e);
              })) return !1;
              break;
            case en.OR:
              if (i.every(function (e) {
                return !l.includes(e);
              })) return !1;
          }
        } else {
          if (i.includes(t) || i.push(t), e.members.length > i.length) return !1;
          if (!c(i, l)) return !1;
        }
      }
      if (!this.includeEmpty && !e.lastMessage) return !1;
      if (!this.includeFrozen && e.isFrozen) return !1;
      if (this.customTypesFilter && this.customTypesFilter.length > 0 && !this.customTypesFilter.includes("*") && !this.customTypesFilter.includes(e.customType)) return !1;
      if (this.customTypeStartsWithFilter && !new RegExp("^".concat(this.customTypeStartsWithFilter)).test(e.customType)) return !1;
      if (this.channelNameContainsFilter && !e.name.toLowerCase().includes(this.channelNameContainsFilter.toLowerCase())) return !1;
      if (this.nicknameContainsFilter) {
        var h = this.nicknameContainsFilter.toLowerCase();
        if (!e.members.some(function (e) {
          return e.userId !== t && e.nickname.toLowerCase().includes(h);
        })) return !1;
      }
      if (this.nicknameStartsWithFilter) {
        var d = this.nicknameStartsWithFilter.toLowerCase();
        if (!e.members.some(function (e) {
          return e.userId !== t && e.nickname.toLowerCase().startsWith(d);
        })) return !1;
      }
      if (this.nicknameExactMatchFilter) {
        var _ = this.nicknameExactMatchFilter.toLowerCase();
        if (!e.members.some(function (e) {
          return e.userId !== t && e.nickname.toLowerCase() != _;
        })) return !1;
      }
      if (this.channelUrlsFilter && this.channelUrlsFilter.length > 0 && !this.channelUrlsFilter.includes(e.url)) return !1;
      if (this.myMemberStateFilter) switch (this.myMemberStateFilter) {
        case Kt.ALL:
          if ("none" === e.myMemberState) return !1;
          break;
        case Kt.JOINED:
          if ("joined" !== e.myMemberState) return !1;
          break;
        case Kt.INVITED:
          if ("invited" !== e.myMemberState) return !1;
          break;
        case Kt.INVITED_BY_FRIEND:
          if ("invited" !== e.myMemberState || !this._isFriend(e.inviter)) return !1;
          break;
        case Kt.INVITED_BY_NON_FRIEND:
          if ("invited" !== e.myMemberState || this._isFriend(e.inviter)) return !1;
      }
      if (this.hiddenChannelFilter) switch (this.hiddenChannelFilter) {
        case Zt.UNHIDDEN:
          if (e.isHidden || "unhidden" !== e.hiddenState) return !1;
          break;
        case Zt.HIDDEN:
          if (!e.isHidden) return !1;
          break;
        case Zt.HIDDEN_ALLOW_AUTO_UNHIDE:
          if (!e.isHidden || "hidden_allow_auto_unhide" !== e.hiddenState) return !1;
          break;
        case Zt.HIDDEN_PREVENT_AUTO_UNHIDE:
          if (!e.isHidden || "hidden_prevent_auto_unhide" !== e.hiddenState) return !1;
      }
      if (this.unreadChannelFilter && this.unreadChannelFilter === Xt.UNREAD_MESSAGE) if (0 === e.unreadMessageCount) return !1;
      if (this.publicChannelFilter) switch (this.publicChannelFilter) {
        case Yt.PUBLIC:
          if (!e.isPublic) return !1;
          break;
        case Yt.PRIVATE:
          if (e.isPublic) return !1;
      }
      if (this.superChannelFilter) switch (this.superChannelFilter) {
        case Jt.SUPER:
          if (!e.isSuper) return !1;
          break;
        case Jt.NON_SUPER:
          if (e.isSuper) return !1;
      }
      return !(this.createdAfter && e.createdAt < this.createdAfter) && !(this.createdBefore && e.createdAt > this.createdBefore);
    }, e;
  }(),
  on = function (t) {
    function n(e, n) {
      var s = n.sdkState,
        r = n.cacheContext,
        a = t.call(this, e) || this;
      return a._channels = new Map(), a._sdkState = s, a._cacheContext = r, a;
    }
    return e(n, t), Object.defineProperty(n.prototype, "collection", {
      get: function () {
        var e = this._cacheContext.nestdb;
        return l(!!e).throw(h.databaseError), e.collection(Ce);
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(n.prototype, "localCacheEnabled", {
      get: function () {
        return this._cacheContext.localCacheEnabled && !!this.collection;
      },
      enumerable: !1,
      configurable: !0
    }), n.prototype._serialize = function (e, t) {
      return void 0 === t && (t = 0), s(s({}, e.serialize()), {
        lastMessageUpdatedAt: e.lastMessage ? e.lastMessage.createdAt : 0,
        syncIndex: t
      });
    }, n.prototype._deserialize = function (e) {
      return fs.of(this._iid).buildGroupChannelFromSerializedData(e);
    }, Object.defineProperty(n.prototype, "channels", {
      get: function () {
        return d([], _(this._channels.values()), !1);
      },
      enumerable: !1,
      configurable: !0
    }), n.prototype.isCachedInMemory = function (e) {
      return this._channels.has(e);
    }, n.prototype.filterOffsetChanged = function (e) {
      var t = this;
      return e.filter(function (e) {
        if (t._channels.has(e.url)) return t._channels.get(e.url).messageOffsetTimestamp < e.messageOffsetTimestamp;
      });
    }, n.prototype.get = function (e) {
      return p(this, void 0, void 0, function () {
        var t, n;
        return f(this, function (s) {
          switch (s.label) {
            case 0:
              return this._channels.has(e) ? [3, 3] : this.localCacheEnabled ? [4, this.collection.getByKey(e)] : [3, 2];
            case 1:
              if (t = s.sent()) return n = this._deserialize(t), this._channels.set(e, n), [2, n];
              s.label = 2;
            case 2:
              return [2, void 0];
            case 3:
              return [2, this._channels.get(e)];
          }
        });
      });
    }, n.prototype.fetch = function (e) {
      var t = e.token,
        n = e.limit,
        s = void 0 === n ? ye : n,
        r = e.backward,
        a = void 0 !== r && r,
        i = e.filter,
        o = void 0 === i ? new an() : i,
        u = e.order,
        c = void 0 === u ? Tt.LATEST_LAST_MESSAGE : u,
        l = e.borderlineChannelUrl;
      return p(this, void 0, void 0, function () {
        var e,
          n,
          r,
          i,
          u,
          h = this;
        return f(this, function (d) {
          switch (d.label) {
            case 0:
              return this.localCacheEnabled ? (e = !!l, n = At(c), r = {
                where: function (n) {
                  if (t) switch (c) {
                    case Tt.CHANNEL_NAME_ALPHABETICAL:
                      if (!a && n.name.localeCompare(t) < 0 || a && n.name.localeCompare(t) > 0) return !1;
                      break;
                    case Tt.CHRONOLOGICAL:
                      if (!a && n.createdAt > t || a && n.createdAt < t) return !1;
                      break;
                    case Tt.LATEST_LAST_MESSAGE:
                      if (!a && n.lastMessageUpdatedAt > t || a && n.lastMessageUpdatedAt < t) return !1;
                  }
                  return e && l && l === n.url ? (e = !1, !1) : !e && o.match(h._deserialize(n), h._sdkState.userId);
                },
                index: n,
                backward: a
              }, [4, this.collection.query(r)]) : [3, 3];
            case 1:
              return [4, d.sent().fetch({
                limit: s
              })];
            case 2:
              return i = d.sent(), (u = i.map(function (e) {
                return h._deserialize(e);
              })).forEach(function (e) {
                !o.includeMetaData && e.cachedMetaData && e._clearCachedMetaData(), h._channels.has(e.url) || h._channels.set(e.url, e);
              }), [2, u];
            case 3:
              return [2, []];
          }
        });
      });
    }, n.prototype.upsert = function (e, t) {
      return p(this, void 0, void 0, function () {
        var n,
          s,
          r,
          a = this;
        return f(this, function (i) {
          switch (i.label) {
            case 0:
              if (n = [], e.forEach(function (e) {
                if (a._channels.has(e.url)) {
                  var s = a._channels.get(e.url);
                  if (s._pinnedMessagesUpdatedAt < e._pinnedMessagesUpdatedAt && (s._pinnedMessagesUpdatedAt = e._pinnedMessagesUpdatedAt), s._messageCollectionLastAccessedAt > e._messageCollectionLastAccessedAt && Object.assign(e, {
                    _messageCollectionLastAccessedAt: void 0
                  }), e.cachedMetaData && t) s._updateCachedMetaData(e.cachedMetaData, t).isUpdated || Object.assign(e, {
                    _cachedMetaData: void 0
                  });
                  s._update(e), n.push(s);
                } else a._channels.set(e.url, e), n.push(e);
              }), !this.localCacheEnabled) return [3, 2];
              for (s = [], r = 0; r < n.length; r++) s.push(this._serialize(n[r], r));
              return [4, this.collection.upsertMany(s)];
            case 1:
              i.sent(), i.label = 2;
            case 2:
              return [2, n];
          }
        });
      });
    }, n.prototype.remove = function (e) {
      return p(this, void 0, void 0, function () {
        var t, n, s, r, a, i;
        return f(this, function (o) {
          switch (o.label) {
            case 0:
              o.trys.push([0, 5, 6, 7]), t = v(e), n = t.next(), o.label = 1;
            case 1:
              return n.done ? [3, 4] : (s = n.value, this._channels.delete(s), this.localCacheEnabled ? [4, this.collection.remove(s)] : [3, 3]);
            case 2:
              o.sent(), o.label = 3;
            case 3:
              return n = t.next(), [3, 1];
            case 4:
              return [3, 7];
            case 5:
              return r = o.sent(), a = {
                error: r
              }, [3, 7];
            case 6:
              try {
                n && !n.done && (i = t.return) && i.call(t);
              } finally {
                if (a) throw a.error;
              }
              return [7];
            case 7:
              return [2];
          }
        });
      });
    }, n.prototype.clear = function () {
      return p(this, void 0, void 0, function () {
        return f(this, function (e) {
          switch (e.label) {
            case 0:
              return this.clearMemoryCache(), this.localCacheEnabled ? [4, this.collection.clear()] : [3, 2];
            case 1:
              e.sent(), e.label = 2;
            case 2:
              return [2];
          }
        });
      });
    }, n.prototype.clearMemoryCache = function () {
      this._channels.clear();
    }, n.prototype._setBlockStateOfAllChannels = function (e, t, n) {
      return p(this, void 0, void 0, function () {
        var s, r, a, i, o, u, c, l, h, d, _, p, m, g, E, C, y, M, b;
        return f(this, function (f) {
          switch (f.label) {
            case 0:
              if (s = [], e === this._sdkState.userId) try {
                for (r = v(this._channels.values()), a = r.next(); !a.done; a = r.next()) {
                  l = a.value;
                  try {
                    for (g = void 0, i = v(l.members), o = i.next(); !o.done; o = i.next()) if ((_ = o.value).userId === t) {
                      _.isBlockedByMe = n, s.push(l);
                      break;
                    }
                  } catch (e) {
                    g = {
                      error: e
                    };
                  } finally {
                    try {
                      o && !o.done && (E = i.return) && E.call(i);
                    } finally {
                      if (g) throw g.error;
                    }
                  }
                }
              } catch (e) {
                p = {
                  error: e
                };
              } finally {
                try {
                  a && !a.done && (m = r.return) && m.call(r);
                } finally {
                  if (p) throw p.error;
                }
              } else if (t === this._sdkState.userId) try {
                for (u = v(this._channels.values()), c = u.next(); !c.done; c = u.next()) {
                  l = c.value;
                  try {
                    for (M = void 0, h = v(l.members), d = h.next(); !d.done; d = h.next()) if ((_ = d.value).userId === e) {
                      _.isBlockingMe = n, s.push(l);
                      break;
                    }
                  } catch (e) {
                    M = {
                      error: e
                    };
                  } finally {
                    try {
                      d && !d.done && (b = h.return) && b.call(h);
                    } finally {
                      if (M) throw M.error;
                    }
                  }
                }
              } catch (e) {
                C = {
                  error: e
                };
              } finally {
                try {
                  c && !c.done && (y = u.return) && y.call(u);
                } finally {
                  if (C) throw C.error;
                }
              }
              return s.length > 0 ? [4, this.upsert(s)] : [3, 2];
            case 1:
              f.sent(), f.label = 2;
            case 2:
              return [2];
          }
        });
      });
    }, n.prototype.block = function (e, t) {
      return p(this, void 0, void 0, function () {
        return f(this, function (n) {
          switch (n.label) {
            case 0:
              return [4, this._setBlockStateOfAllChannels(e, t, !0)];
            case 1:
              return n.sent(), [2];
          }
        });
      });
    }, n.prototype.unblock = function (e, t) {
      return p(this, void 0, void 0, function () {
        return f(this, function (n) {
          switch (n.label) {
            case 0:
              return [4, this._setBlockStateOfAllChannels(e, t, !1)];
            case 1:
              return n.sent(), [2];
          }
        });
      });
    }, n.prototype.markAsRead = function (e, t) {
      return void 0 === t && (t = d([], _(this._channels.keys()), !1)), p(this, void 0, void 0, function () {
        var n, s, r, a, i, o, u, c;
        return f(this, function (l) {
          switch (l.label) {
            case 0:
              n = [], l.label = 1;
            case 1:
              l.trys.push([1, 6, 7, 8]), s = v(t), r = s.next(), l.label = 2;
            case 2:
              return r.done ? [3, 5] : (a = r.value, [4, this.get(a)]);
            case 3:
              (null == (i = l.sent()) ? void 0 : i._updateUnreadMemberState(this._sdkState.userId, e)) && (i._updateUnreadCount(0, 0), n.push(i)), l.label = 4;
            case 4:
              return r = s.next(), [3, 2];
            case 5:
              return [3, 8];
            case 6:
              return o = l.sent(), u = {
                error: o
              }, [3, 8];
            case 7:
              try {
                r && !r.done && (c = s.return) && c.call(s);
              } finally {
                if (u) throw u.error;
              }
              return [7];
            case 8:
              return n.length > 0 ? [4, this.upsert(n)] : [3, 10];
            case 9:
              l.sent(), l.label = 10;
            case 10:
              return [2];
          }
        });
      });
    }, n;
  }(u),
  un = {
    invitedUserIds: void 0,
    channelUrl: void 0,
    coverUrl: void 0,
    coverImage: void 0,
    isDistinct: void 0,
    isSuper: void 0,
    isBroadcast: void 0,
    isExclusive: void 0,
    isPublic: void 0,
    isDiscoverable: void 0,
    isStrict: void 0,
    isEphemeral: void 0,
    accessCode: void 0,
    name: void 0,
    data: void 0,
    customType: void 0,
    operatorUserIds: void 0,
    messageSurvivalSeconds: void 0
  },
  cn = function (e) {
    return m("string", e.invitedUserIds, !0) && g("string", e.channelUrl, !0) && g("string", e.coverUrl, !0) && (E(e.coverImage) || g("string", e.coverImage, !0)) && g("boolean", e.isDistinct, !0) && g("boolean", e.isSuper, !0) && g("boolean", e.isBroadcast, !0) && g("boolean", e.isExclusive, !0) && g("boolean", e.isPublic, !0) && g("boolean", e.isStrict, !0) && g("boolean", e.isDiscoverable, !0) && g("boolean", e.isEphemeral, !0) && g("string", e.accessCode, !0) && g("string", e.name, !0) && g("string", e.data, !0) && g("string", e.customType, !0) && m("string", e.operatorUserIds, !0) && g("number", e.messageSurvivalSeconds, !0);
  },
  ln = {
    customTypes: void 0,
    includeEmpty: !1,
    includeFrozen: !0,
    includeMetaData: !0,
    includeChatNotification: !1
  },
  hn = function (e) {
    return m("string", e.customTypes, !0) && g("boolean", e.includeEmpty, !0) && g("boolean", e.includeFrozen, !0) && g("boolean", e.includeMetaData, !0) && g("boolean", e.includeChatNotification, !0);
  },
  dn = {
    myMemberStateFilter: Kt.ALL
  },
  _n = function (e) {
    return r(Kt, e.myMemberStateFilter);
  };
!function (e) {
  e.GROUP_CHANNEL_UNREAD_MENTION_COUNT = "group_channel_unread_mention_count", e.NONSUPER_UNREAD_MENTION_COUNT = "non_super_group_channel_unread_mention_count", e.SUPER_UNREAD_MENTION_COUNT = "super_group_channel_unread_mention_count", e.GROUP_CHANNEL_UNREAD_MESSAGE_COUNT = "group_channel_unread_message_count", e.NONSUPER_UNREAD_MESSAGE_COUNT = "non_super_group_channel_unread_message_count", e.SUPER_UNREAD_MESSAGE_COUNT = "super_group_channel_unread_message_count", e.GROUP_CHANNEL_INVITATION_COUNT = "group_channel_invitation_count", e.NONSUPER_INVITATION_COUNT = "non_super_group_channel_invitation_count", e.SUPER_INVITATION_COUNT = "super_group_channel_invitation_count";
}(rn || (rn = {}));
var pn = {
    keys: [rn.GROUP_CHANNEL_UNREAD_MENTION_COUNT, rn.NONSUPER_UNREAD_MENTION_COUNT, rn.SUPER_UNREAD_MENTION_COUNT, rn.GROUP_CHANNEL_UNREAD_MESSAGE_COUNT, rn.NONSUPER_UNREAD_MESSAGE_COUNT, rn.SUPER_UNREAD_MESSAGE_COUNT, rn.GROUP_CHANNEL_INVITATION_COUNT, rn.NONSUPER_INVITATION_COUNT, rn.SUPER_INVITATION_COUNT],
    customTypeFilters: void 0,
    customTypesFilter: void 0
  },
  fn = {
    channelCustomTypesFilter: void 0,
    superChannelFilter: Jt.ALL
  },
  vn = function (e) {
    return m("string", e.channelCustomTypesFilter, !0) && r(Jt, e.superChannelFilter);
  },
  mn = {
    channelUrl: void 0,
    scheduledStatus: void 0,
    messageTypeFilter: C.ALL
  },
  gn = s({}, y),
  En = function (e) {
    return e.startsWith("EVENT_") || e === y.SYNC_CHANNEL_CHANGELOGS || e === y.REFRESH_CHANNEL;
  },
  Cn = function (t) {
    function n(e) {
      var n = e.channels,
        s = e.context,
        r = e.isWebSocketEventComing,
        a = void 0 !== r && r,
        i = e.ts,
        o = t.call(this) || this;
      return o.channels = n, o.context = s, o.isWebSocketEventComing = a, o.ts = i, o;
    }
    return e(n, t), n;
  }(M),
  yn = function (t) {
    function n(e) {
      var n = e.channelUrls,
        s = e.context,
        r = e.isWebSocketEventComing,
        a = void 0 !== r && r,
        i = t.call(this) || this;
      return i.channelUrls = n, i.context = s, i.isWebSocketEventComing = a, i;
    }
    return e(n, t), n;
  }(M),
  Mn = function () {
    function e(e) {
      var t = e.groupChannelCache,
        n = e.messageCache,
        s = e.unsentMessageCache,
        r = e.dispatcher,
        a = this;
      this._observers = new Map(), r.on(function (e) {
        return p(a, void 0, void 0, function () {
          var r,
            a,
            i,
            o,
            u,
            c,
            l,
            h,
            d,
            _,
            m,
            g,
            E,
            C,
            M,
            N,
            U,
            S,
            T = this;
          return f(this, function (A) {
            switch (A.label) {
              case 0:
                if (!(e instanceof Cn)) return [3, 19];
                if (r = e.channels, E = e.context, C = e.isWebSocketEventComing, a = r.filter(function (e) {
                  return e instanceof cr;
                }), E.source !== y.EVENT_CHANNEL_RESET_HISTORY && E.source !== y.EVENT_CHANNEL_HIDDEN) return [3, 9];
                A.label = 1;
              case 1:
                A.trys.push([1, 6, 7, 8]), i = v(a), o = i.next(), A.label = 2;
              case 2:
                return o.done ? [3, 5] : (d = o.value, [4, n.removeUnderOffset(d.url, d.messageOffsetTimestamp)]);
              case 3:
                A.sent(), A.label = 4;
              case 4:
                return o = i.next(), [3, 2];
              case 5:
                return [3, 8];
              case 6:
                return u = A.sent(), M = {
                  error: u
                }, [3, 8];
              case 7:
                try {
                  o && !o.done && (N = i.return) && N.call(i);
                } finally {
                  if (M) throw M.error;
                }
                return [7];
              case 8:
                return [3, 17];
              case 9:
                c = t.filterOffsetChanged(a), A.label = 10;
              case 10:
                A.trys.push([10, 15, 16, 17]), l = v(c), h = l.next(), A.label = 11;
              case 11:
                return h.done ? [3, 14] : (d = h.value, [4, n.removeUnderOffset(d.url, d.messageOffsetTimestamp)]);
              case 12:
                A.sent(), A.label = 13;
              case 13:
                return h = l.next(), [3, 11];
              case 14:
                return [3, 17];
              case 15:
                return _ = A.sent(), U = {
                  error: _
                }, [3, 17];
              case 16:
                try {
                  h && !h.done && (S = l.return) && S.call(l);
                } finally {
                  if (U) throw U.error;
                }
                return [7];
              case 17:
                return [4, t.upsert(a, e.ts)];
              case 18:
                return m = A.sent(), C || this._broadcastUpdateEvent(m, E), [3, 24];
              case 19:
                return e instanceof yn ? (g = e.channelUrls, E = e.context, C = e.isWebSocketEventComing, [4, t.remove(g)]) : [3, 22];
              case 20:
                return A.sent(), [4, b(function () {
                  return p(T, void 0, void 0, function () {
                    var e, t, r, a, i, o;
                    return f(this, function (u) {
                      switch (u.label) {
                        case 0:
                          u.trys.push([0, 6, 7, 8]), e = v(g), t = e.next(), u.label = 1;
                        case 1:
                          return t.done ? [3, 5] : (r = t.value, [4, n.removeMessagesOfChannel(r)]);
                        case 2:
                          return u.sent(), [4, s.removeMessagesOfChannel(r)];
                        case 3:
                          u.sent(), u.label = 4;
                        case 4:
                          return t = e.next(), [3, 1];
                        case 5:
                          return [3, 8];
                        case 6:
                          return a = u.sent(), i = {
                            error: a
                          }, [3, 8];
                        case 7:
                          try {
                            t && !t.done && (o = e.return) && o.call(e);
                          } finally {
                            if (i) throw i.error;
                          }
                          return [7];
                        case 8:
                          return [2];
                      }
                    });
                  });
                })];
              case 21:
                return A.sent(), C || this._broadcastRemoveEvent(g, E), [3, 24];
              case 22:
                return e instanceof It ? [4, t.fetch({
                  token: Number.MAX_SAFE_INTEGER,
                  limit: Number.MAX_SAFE_INTEGER
                })] : [3, 24];
              case 23:
                A.sent(), A.label = 24;
              case 24:
                return [2];
            }
          });
        });
      });
    }
    return e.prototype._broadcastUpdateEvent = function (e, t) {
      var n, s;
      try {
        for (var r = v(this._observers.values()), a = r.next(); !a.done; a = r.next()) {
          var i = a.value;
          i.onUpdate && i.onUpdate(e, t);
        }
      } catch (e) {
        n = {
          error: e
        };
      } finally {
        try {
          a && !a.done && (s = r.return) && s.call(r);
        } finally {
          if (n) throw n.error;
        }
      }
    }, e.prototype._broadcastRemoveEvent = function (e, t) {
      var n, s;
      try {
        for (var r = v(this._observers.values()), a = r.next(); !a.done; a = r.next()) {
          var i = a.value;
          i.onRemove && i.onRemove(e, t);
        }
      } catch (e) {
        n = {
          error: e
        };
      } finally {
        try {
          a && !a.done && (s = r.return) && s.call(r);
        } finally {
          if (n) throw n.error;
        }
      }
    }, e.prototype.subscribe = function (e, t) {
      this._observers.set(e, t);
    }, e.prototype.unsubscribe = function (e) {
      this._observers.delete(e);
    }, e.prototype.unsubscribeAll = function () {
      this._observers.clear();
    }, e;
  }(),
  bn = function (r) {
    function a(e) {
      var a = e.userId,
        i = e.ts,
        o = e.token,
        u = e.filter,
        c = e.includeChatNotification,
        l = void 0 !== c && c,
        h = r.call(this) || this,
        d = s(s({}, ln), u),
        _ = d.customTypes,
        p = d.includeEmpty,
        f = d.includeFrozen;
      return h.method = N.GET, h.path = "".concat(U, "/").concat(encodeURIComponent(a), "/my_group_channels/changelogs"), h.params = t(n({
        show_delivery_receipt: !0,
        show_member: !0,
        show_read_receipt: !0,
        change_ts: i || null,
        token: o,
        custom_types: _,
        show_empty: p,
        show_frozen: f,
        include_chat_notification: l
      })), h;
    }
    return e(a, r), a;
  }(S),
  Nn = function (t) {
    function n(e, n) {
      var s = t.call(this, e, n) || this;
      return s.updatedChannels = n.updated.map(function (t) {
        return new cr(e, Object.assign(t, {
          ts: n.ts
        }));
      }), s.deletedChannelUrls = n.deleted, s.hasMore = n.has_more, s.token = n.next, s.ts = n.ts, s;
    }
    return e(n, t), n;
  }(T),
  Un = function (t) {
    function n(e) {
      var n = e.channelUrl,
        s = e.isInternalCall,
        r = e.showLatestMessage,
        a = t.call(this) || this;
      return a.method = N.GET, a.path = "".concat(s ? A : I, "/").concat(encodeURIComponent(n)), a.params = {
        show_member: !0,
        show_read_receipt: !0,
        show_delivery_receipt: !0,
        show_latest_message: null != r && r
      }, a;
    }
    return e(n, t), n;
  }(S),
  Sn = function (t) {
    function n(e, n) {
      var s = t.call(this, e, n) || this;
      return s.channel = new cr(e, n), s;
    }
    return e(n, t), n;
  }(T),
  Tn = {
    includeEmpty: !1,
    includeFrozen: !0,
    includeMetaData: !0,
    includeChatNotification: !1,
    channelUrlsFilter: void 0,
    customTypesFilter: void 0,
    customTypeStartsWithFilter: void 0,
    nicknameContainsFilter: void 0,
    nicknameStartsWithFilter: void 0,
    nicknameExactMatchFilter: void 0,
    channelNameContainsFilter: void 0,
    myMemberStateFilter: Kt.ALL,
    unreadChannelFilter: Xt.ALL,
    superChannelFilter: Jt.ALL,
    publicChannelFilter: Yt.ALL,
    hiddenChannelFilter: Zt.ALL,
    userIdsFilter: {
      userIds: [],
      includeMode: !0,
      queryType: en.AND
    },
    searchFilter: {
      query: void 0,
      fields: []
    },
    metadataKey: void 0,
    metadataValues: void 0,
    metadataOrderKeyFilter: void 0,
    metadataValueStartsWith: void 0,
    order: Tt.LATEST_LAST_MESSAGE,
    createdAfter: void 0,
    createdBefore: void 0
  },
  An = function (n) {
    function s(e) {
      var s = this,
        r = e.userId,
        a = e.token,
        i = e.limit,
        o = e.order,
        u = e.includeEmpty,
        c = e.myMemberStateFilter,
        l = e.superChannelFilter,
        h = e.publicChannelFilter,
        d = e.unreadChannelFilter,
        _ = e.nicknameContainsFilter,
        p = e.nicknameStartsWithFilter,
        f = e.nicknameExactMatchFilter,
        v = e.channelNameContainsFilter,
        m = e.channelUrlsFilter,
        g = e.customTypesFilter,
        E = e.customTypeStartsWithFilter,
        C = e.hiddenChannelFilter,
        y = e.metadataOrderKeyFilter,
        M = e.metadataKey,
        b = e.metadataValues,
        S = e.metadataValueStartsWith,
        T = e.includeFrozen,
        A = e.includeMetaData,
        I = e.searchFilter,
        w = e.userIdsFilter,
        L = e.includeChatNotification,
        D = void 0 !== L && L,
        x = e.includeLeftChannel,
        R = void 0 !== x && x,
        F = e.createdAfter,
        P = e.createdBefore;
      return (s = n.call(this) || this).method = N.GET, s.path = "".concat(U, "/").concat(encodeURIComponent(r), "/my_group_channels"), s.params = t({
        token: a,
        limit: i,
        order: null != o ? o : Tn.order,
        show_member: !0,
        show_read_receipt: !0,
        show_delivery_receipt: !0,
        show_empty: null != u ? u : Tn.includeEmpty,
        member_state_filter: null != c ? c : Tn.myMemberStateFilter,
        super_mode: null != l ? l : Tn.superChannelFilter,
        public_mode: null != h ? h : Tn.publicChannelFilter,
        unread_filter: null != d ? d : Tn.unreadChannelFilter,
        members_nickname_contains: _,
        members_nickname_startswith: p,
        members_nickname: f,
        name_contains: v,
        channel_urls: m,
        custom_types: g,
        custom_type_startswith: E,
        hidden_mode: C,
        metadata_order_key: y,
        metadata_key: M,
        metadata_values: b,
        metadata_value_startswith: S,
        show_frozen: T,
        show_metadata: A,
        include_chat_notification: D,
        include_left_channel: R,
        created_after: F,
        created_before: P
      }), I && I.query && I.fields && (s.params.search_query = I.query, s.params.search_fields = I.fields), w && w.userIds && w.userIds.length > 0 && (w.includeMode ? (s.params.members_include_in = w.userIds, s.params.query_type = w.queryType.toUpperCase()) : s.params.members_exactly_in = w.userIds), s;
    }
    return e(s, n), s;
  }(S),
  In = function (t) {
    function n(e, n) {
      var s = t.call(this, e, n) || this;
      s.channels = [];
      var r = n.next,
        a = n.channels,
        i = n.ts;
      return s.token = r, a && a.length > 0 && (s.channels = a.map(function (t) {
        return t.ts = i, new cr(e, t);
      })), s.ts = null != i ? i : 0, s;
    }
    return e(n, t), n;
  }(T),
  wn = function (t) {
    function n(e) {
      var n = e.userId,
        s = e.filter,
        r = t.call(this) || this,
        a = s.myMemberStateFilter;
      return r.method = N.GET, r.path = "".concat(U, "/").concat(encodeURIComponent(n), "/group_channel_count"), r.params = {
        state: null != a ? a : Kt.ALL
      }, r;
    }
    return e(n, t), n;
  }(S),
  Ln = function (t) {
    function n(e, n) {
      var s = t.call(this, e, n) || this;
      return s.groupChannelCount = n.group_channel_count, s;
    }
    return e(n, t), n;
  }(T),
  Dn = function (n) {
    function s(e) {
      var s = e.userId,
        r = e.filter,
        a = n.call(this) || this,
        i = r.keys,
        o = r.customTypeFilters,
        u = r.customTypesFilter;
      return a.method = N.GET, a.path = "".concat(U, "/").concat(encodeURIComponent(s), "/unread_item_count"), a.params = t({
        item_keys: i,
        custom_types: null != u ? u : o
      }), a;
    }
    return e(s, n), s;
  }(S),
  xn = function (t) {
    function n(e, n) {
      var s = t.call(this, e, n) || this;
      return "number" == typeof n[rn.GROUP_CHANNEL_UNREAD_MENTION_COUNT] && (s.groupChannelUnreadMentionCount = n[rn.GROUP_CHANNEL_UNREAD_MENTION_COUNT]), "number" == typeof n[rn.GROUP_CHANNEL_UNREAD_MESSAGE_COUNT] && (s.groupChannelUnreadMessageCount = n[rn.GROUP_CHANNEL_UNREAD_MESSAGE_COUNT]), "number" == typeof n[rn.GROUP_CHANNEL_INVITATION_COUNT] && (s.groupChannelInvitationCount = n[rn.GROUP_CHANNEL_INVITATION_COUNT]), "number" == typeof n[rn.SUPER_UNREAD_MENTION_COUNT] && (s.superGroupChannelUnreadMentionCount = n[rn.SUPER_UNREAD_MENTION_COUNT]), "number" == typeof n[rn.SUPER_UNREAD_MESSAGE_COUNT] && (s.superGroupChannelUnreadMessageCount = n[rn.SUPER_UNREAD_MESSAGE_COUNT]), "number" == typeof n[rn.SUPER_INVITATION_COUNT] && (s.superGroupChannelInvitationCount = n[rn.SUPER_INVITATION_COUNT]), "number" == typeof n[rn.NONSUPER_UNREAD_MENTION_COUNT] && (s.nonSuperGroupChannelUnreadMentionCount = n[rn.NONSUPER_UNREAD_MENTION_COUNT]), "number" == typeof n[rn.NONSUPER_UNREAD_MESSAGE_COUNT] && (s.nonSuperGroupChannelUnreadMessageCount = n[rn.NONSUPER_UNREAD_MESSAGE_COUNT]), "number" == typeof n[rn.NONSUPER_INVITATION_COUNT] && (s.nonSuperGroupChannelInvitationCount = n[rn.NONSUPER_INVITATION_COUNT]), s;
    }
    return e(n, t), n;
  }(T),
  Rn = function (t) {
    function n(e) {
      var n = e.userId,
        s = t.call(this) || this;
      return s.method = N.GET, s.path = "".concat(U, "/").concat(encodeURIComponent(n), "/unread_channel_count"), s;
    }
    return e(n, t), n;
  }(S),
  Fn = function (t) {
    function n(e, n) {
      var s = t.call(this, e, n) || this;
      return s.unreadCount = n.unread_count, s;
    }
    return e(n, t), n;
  }(T),
  Pn = function (t) {
    function n(e) {
      var n = e.userId,
        s = e.filter,
        r = e.includeFeedChannel,
        a = void 0 !== r && r,
        i = t.call(this) || this,
        o = s.channelCustomTypesFilter,
        u = s.superChannelFilter;
      return i.method = N.GET, i.path = "".concat(U, "/").concat(encodeURIComponent(n), "/unread_message_count"), i.params = {
        super_mode: null != u ? u : Jt.ALL,
        custom_types: o,
        include_feed_channel: a
      }, i;
    }
    return e(n, t), n;
  }(S),
  kn = function (t) {
    function n(e, n) {
      var s = t.call(this, e, n) || this;
      return s.unreadCount = n.unread_count, s.unreadFeedCount = n.unread_feed_count, s;
    }
    return e(n, t), n;
  }(T),
  On = function (t) {
    function n(e) {
      var n = e.channelUrl,
        s = e.scheduledStatus,
        r = e.messageTypeFilter,
        a = t.call(this) || this;
      return a.method = N.GET, a.path = "".concat(w, "/count"), a.params = {
        channel_url: n,
        status: Gn(s)
      }, r && (a.params.message_type = r), a;
    }
    return e(n, t), n;
  }(S),
  Hn = function (t) {
    function n(e, n) {
      var s = t.call(this, e, n) || this;
      return s.count = n.count, s;
    }
    return e(n, t), n;
  }(T),
  Gn = function (e) {
    if (!e) return [];
    var t = [];
    return e.forEach(function (e) {
      switch (e) {
        case Me.PENDING:
          t.push(be.PENDING);
          break;
        case Me.SENT:
          t.push(be.IN_QUEUE), t.push(be.SENT);
          break;
        case Me.CANCELED:
          t.push(be.CANCELED);
          break;
        case Me.FAILED:
          t.push(be.FAILED);
      }
    }), t;
  },
  Vn = function (n) {
    function s(e) {
      var s = this,
        r = e.userId,
        a = e.channelUrl,
        i = e.coverUrl,
        o = e.coverImage,
        u = e.isDistinct,
        c = e.isSuper,
        l = e.isBroadcast,
        h = e.isPublic,
        p = e.isExclusive,
        f = e.isDiscoverable,
        v = e.isStrict,
        m = e.isEphemeral,
        g = e.accessCode,
        E = e.name,
        C = e.data,
        y = e.customType,
        M = e.messageSurvivalSeconds,
        b = e.invitedUserIds,
        U = e.operatorUserIds;
      return (s = n.call(this) || this).method = N.POST, s.path = I, s.params = t({
        user_ids: d([r], _(null != b ? b : []), !1).filter(function (e, t, n) {
          return t === n.indexOf(e);
        }),
        channel_url: a,
        cover_url: i,
        cover_file: o,
        is_distinct: u,
        is_super: c,
        is_broadcast: l,
        is_exclusive: p,
        is_public: h,
        is_discoverable: f,
        strict: v,
        is_ephemeral: m,
        access_code: g,
        name: E,
        data: C,
        custom_type: y,
        operator_ids: U,
        message_survival_seconds: M
      }), s;
    }
    return e(s, n), s;
  }(S),
  Bn = function (t) {
    function n(e, n) {
      var s,
        r = this;
      return (r = t.call(this, e, n) || this).channel = new cr(e, n), r.isCreated = null === (s = n.is_created) || void 0 === s || s, r;
    }
    return e(n, t), n;
  }(T),
  zn = function (t) {
    function n(e) {
      var n = e.userId,
        s = e.channelUrls,
        r = t.call(this) || this;
      return r.method = N.PUT, r.path = "".concat(U, "/").concat(encodeURIComponent(n), "/mark_as_read_all"), r.params = {
        channel_urls: s
      }, r;
    }
    return e(n, t), n;
  }(S);
!function (t) {
  function n() {
    return null !== t && t.apply(this, arguments) || this;
  }
  e(n, t);
}(T);
var qn = function (t) {
    function n(e) {
      var n = this,
        s = e.channelUrl,
        r = e.userId,
        a = e.accessCode;
      return (n = t.call(this) || this).method = N.PUT, n.path = "".concat(I, "/").concat(encodeURIComponent(s), "/join"), n.params = {
        user_id: r,
        access_code: a
      }, n;
    }
    return e(n, t), n;
  }(S),
  jn = function (t) {
    function n(e, n) {
      var s = t.call(this, e, n) || this;
      return s.channel = new cr(e, n), s;
    }
    return e(n, t), n;
  }(T),
  Wn = function (t) {
    function n(e, n, s) {
      var r = t.call(this, e, n, s) || this,
        a = s.data,
        i = a.member_count,
        o = void 0 === i ? 0 : i,
        u = a.joined_member_count,
        c = void 0 === u ? 0 : u,
        l = a.users,
        h = void 0 === l ? null : l;
      return r.memberCount = o, r.joinedMemberCount = c, r.members = Array.isArray(h) ? h.map(function (t) {
        return new nn(e, t);
      }) : [new nn(e, s.data)], r;
    }
    return e(n, t), n;
  }(Pt),
  Qn = function (t) {
    function n(e) {
      var n = this,
        s = e.channelUrl,
        r = e.userId,
        a = e.shouldRemoveOperatorStatus;
      return (n = t.call(this) || this).method = N.PUT, n.path = "".concat(I, "/").concat(encodeURIComponent(s), "/leave"), n.params = {
        user_id: r,
        should_remove_operator_status: a
      }, n;
    }
    return e(n, t), n;
  }(S);
!function (t) {
  function n(e, n) {
    return t.call(this, e, n) || this;
  }
  e(n, t);
}(T);
var Yn = function (t) {
    function n(e, n, s) {
      var r = t.call(this, e, n, s) || this,
        a = s.data,
        i = a.member_count,
        o = void 0 === i ? 0 : i,
        u = a.joined_member_count,
        c = void 0 === u ? 0 : u;
      return r.memberCount = o, r.joinedMemberCount = c, r.member = new nn(r._iid, s.data), r;
    }
    return e(n, t), n;
  }(Pt),
  Kn = function (t) {
    function n(e) {
      var n = this,
        s = e.channelUrl,
        r = e.userIds;
      return (n = t.call(this) || this).method = N.POST, n.path = "".concat(I, "/").concat(encodeURIComponent(s), "/invite"), n.params = {
        user_ids: r
      }, n;
    }
    return e(n, t), n;
  }(S),
  Jn = function (t) {
    function n(e, n) {
      var s = t.call(this, e, n) || this;
      return s.channel = new cr(e, n), s;
    }
    return e(n, t), n;
  }(T),
  Xn = function (t) {
    function n(e, n, s) {
      var r = t.call(this, e, n, s) || this;
      r.inviter = null;
      var a = s.data,
        i = a.member_count,
        u = void 0 === i ? 0 : i,
        c = a.joined_member_count,
        l = void 0 === c ? 0 : c,
        h = a.inviter,
        d = a.invitees,
        _ = void 0 === d ? [] : d;
      return r.memberCount = u, r.joinedMemberCount = l, h && Object.keys(h).length > 0 && (r.inviter = new o(e, h)), r.invitees = _.map(function (t) {
        return new nn(e, t);
      }), r;
    }
    return e(n, t), n;
  }(Pt),
  Zn = function (t) {
    function n(e) {
      var n = this,
        s = e.channelUrl,
        r = e.userId;
      return (n = t.call(this) || this).method = N.PUT, n.path = "".concat(I, "/").concat(encodeURIComponent(s), "/decline"), n.params = {
        user_id: r
      }, n;
    }
    return e(n, t), n;
  }(S);
!function (t) {
  function n(e, n) {
    var s = t.call(this, e, n) || this;
    return s.channel = new cr(e, n), s.channel.myMemberState = Qt.NONE, s;
  }
  e(n, t);
}(T);
var $n = function (t) {
    function n(e, n, s) {
      var r = t.call(this, e, n, s) || this,
        a = s.data,
        i = a.member_count,
        u = a.joined_member_count,
        c = a.inviter,
        l = a.invitee;
      return r.memberCount = null != i ? i : 0, r.joinedMemberCount = null != u ? u : 0, r.inviter = new o(e, c), r.invitee = new nn(e, l), r;
    }
    return e(n, t), n;
  }(Pt),
  es = {
    hidePreviousMessages: !1,
    allowAutoUnhide: !0
  },
  ts = function (t) {
    function n(e) {
      var n = this,
        s = e.channelUrl,
        r = e.userId,
        a = e.hidePreviousMessages,
        i = e.allowAutoUnhide;
      return (n = t.call(this) || this).method = N.PUT, n.path = "".concat(I, "/").concat(encodeURIComponent(s), "/hide"), n.params = {
        user_id: r,
        hide_previous_messages: null != a ? a : es.hidePreviousMessages,
        allow_auto_unhide: null != i ? i : es.allowAutoUnhide
      }, n;
    }
    return e(n, t), n;
  }(S),
  ns = function (t) {
    function n(e, n) {
      var s = t.call(this, e, n) || this,
        r = n.ts_message_offset;
      return s.messageOffsetTimestamp = r, s;
    }
    return e(n, t), n;
  }(T),
  ss = function (t) {
    function n(e, n, s) {
      var r,
        a,
        i,
        o = this;
      return (o = t.call(this, e, "SYEV", s) || this).allowAutoUnhide = null, o.hidePreviousMessages = null, o.messageOffsetTimestamp = null, s.data && (o.allowAutoUnhide = null !== (r = s.data.allow_auto_unhide) && void 0 !== r ? r : null, o.hidePreviousMessages = null !== (a = s.data.hide_previous_messages) && void 0 !== a ? a : null), o.messageOffsetTimestamp = null !== (i = s.ts_message_offset) && void 0 !== i ? i : null, o;
    }
    return e(n, t), n;
  }(L),
  rs = function (t) {
    function n(e) {
      var n = e.channelUrl,
        s = e.time;
      return t.call(this, {
        code: "TPST",
        ackRequired: !1,
        payload: {
          channel_url: n,
          time: s
        }
      }) || this;
    }
    return e(n, t), n;
  }(D),
  as = function (t) {
    function n(e, n, s) {
      var r = t.call(this, e, "SYEV", s) || this;
      return r.user = new o(e, s.data), r;
    }
    return e(n, t), n;
  }(L),
  is = function (t) {
    function n(e) {
      var n = e.channelUrl,
        s = e.time;
      return t.call(this, {
        code: "TPEN",
        ackRequired: !1,
        payload: {
          channel_url: n,
          time: s
        }
      }) || this;
    }
    return e(n, t), n;
  }(D),
  os = function (t) {
    function n(e, n, s) {
      var r = t.call(this, e, "SYEV", s) || this;
      return r.user = new o(e, s.data), r;
    }
    return e(n, t), n;
  }(L),
  us = function (t) {
    function n(e) {
      var n = e.channelUrl,
        s = e.messageId;
      return t.call(this, {
        code: "MACK",
        ackRequired: !1,
        payload: {
          channel_url: n,
          msg_id: s
        }
      }) || this;
    }
    return e(n, t), n;
  }(D),
  cs = function (t) {
    function a(e, n) {
      var s,
        r,
        a,
        i,
        o,
        u,
        c,
        l,
        h,
        d,
        _,
        p,
        f,
        v,
        m,
        g,
        E,
        C,
        y,
        M,
        b,
        N,
        U,
        S = this;
      return (S = t.call(this, e, n) || this).includeEmpty = !1, S.includeFrozen = !0, S.includeMetaData = !0, S.includeChatNotification = !1, S.channelUrlsFilter = null, S.customTypesFilter = null, S.customTypeStartsWithFilter = null, S.nicknameContainsFilter = null, S.nicknameStartsWithFilter = null, S.nicknameExactMatchFilter = null, S.channelNameContainsFilter = "", S.myMemberStateFilter = Kt.ALL, S.unreadChannelFilter = Xt.ALL, S.superChannelFilter = Jt.ALL, S.publicChannelFilter = Yt.ALL, S.hiddenChannelFilter = Zt.UNHIDDEN, S.searchFilter = {
        fields: [],
        query: null
      }, S.userIdsFilter = {
        userIds: [],
        includeMode: !0,
        queryType: en.AND
      }, S.metadataKey = null, S.metadataValues = null, S.metadataOrderKeyFilter = null, S.metadataValueStartsWith = null, S.order = Tt.LATEST_LAST_MESSAGE, S.includeEmpty = null !== (s = n.includeEmpty) && void 0 !== s && s, S.includeFrozen = null === (r = n.includeFrozen) || void 0 === r || r, S.includeMetaData = null === (a = n.includeMetaData) || void 0 === a || a, S.includeChatNotification = null !== (i = n.includeChatNotification) && void 0 !== i && i, S.channelUrlsFilter = null !== (o = n.channelUrlsFilter) && void 0 !== o ? o : null, S.customTypesFilter = null !== (u = n.customTypesFilter) && void 0 !== u ? u : null, S.customTypeStartsWithFilter = null !== (c = n.customTypeStartsWithFilter) && void 0 !== c ? c : "", S.nicknameContainsFilter = null !== (l = n.nicknameContainsFilter) && void 0 !== l ? l : null, S.nicknameStartsWithFilter = null !== (h = n.nicknameStartsWithFilter) && void 0 !== h ? h : null, S.nicknameExactMatchFilter = null !== (d = n.nicknameExactMatchFilter) && void 0 !== d ? d : null, S.channelNameContainsFilter = null !== (_ = n.channelNameContainsFilter) && void 0 !== _ ? _ : "", S.myMemberStateFilter = null !== (p = n.myMemberStateFilter) && void 0 !== p ? p : Kt.ALL, S.unreadChannelFilter = null !== (f = n.unreadChannelFilter) && void 0 !== f ? f : Xt.ALL, S.superChannelFilter = null !== (v = n.superChannelFilter) && void 0 !== v ? v : Jt.ALL, S.publicChannelFilter = null !== (m = n.publicChannelFilter) && void 0 !== m ? m : Yt.ALL, S.hiddenChannelFilter = null !== (g = n.hiddenChannelFilter) && void 0 !== g ? g : Zt.UNHIDDEN, S.searchFilter = null !== (E = n.searchFilter) && void 0 !== E ? E : {
        fields: [],
        query: null
      }, S.userIdsFilter = null !== (C = n.userIdsFilter) && void 0 !== C ? C : {
        userIds: [],
        includeMode: !0,
        queryType: en.AND
      }, S.metadataKey = null !== (y = n.metadataKey) && void 0 !== y ? y : null, S.metadataValues = null !== (M = n.metadataValues) && void 0 !== M ? M : null, S.metadataOrderKeyFilter = null !== (b = n.metadataOrderKeyFilter) && void 0 !== b ? b : null, S.metadataValueStartsWith = null !== (N = n.metadataValueStartsWith) && void 0 !== N ? N : null, S.order = null !== (U = n.order) && void 0 !== U ? U : Tt.LATEST_LAST_MESSAGE, n.createdAfter && (S.createdAfter = n.createdAfter), n.createdBefore && (S.createdBefore = n.createdBefore), S;
    }
    return e(a, t), a.prototype._validate = function () {
      return t.prototype._validate.call(this) && g("boolean", this.includeEmpty) && g("boolean", this.includeFrozen) && g("boolean", this.includeMetaData) && g("string", this.channelNameContainsFilter) && m("string", this.channelUrlsFilter, !0) && m("string", this.customTypesFilter, !0) && g("string", this.customTypeStartsWithFilter) && g("string", this.nicknameContainsFilter, !0) && g("string", this.nicknameStartsWithFilter, !0) && g("string", this.nicknameExactMatchFilter, !0) && r(Kt, this.myMemberStateFilter) && r(Jt, this.superChannelFilter) && r(Yt, this.publicChannelFilter) && r(Xt, this.unreadChannelFilter) && r(Zt, this.hiddenChannelFilter) && m(tn, this.searchFilter.fields) && g("string", this.searchFilter.query, !0) && m("string", this.userIdsFilter.userIds) && g("boolean", this.userIdsFilter.includeMode) && r(en, this.userIdsFilter.queryType) && r(Tt, this.order) && g("string", this.metadataOrderKeyFilter, !0) && g("string", this.metadataKey, !0) && m("string", this.metadataValues, !0) && g("string", this.metadataValueStartsWith, !0) && x(this.createdAfter, !0) && x(this.createdBefore, !0);
    }, a.prototype.serialize = function () {
      return R(this);
    }, a.prototype.next = function () {
      return p(this, void 0, void 0, function () {
        var e, t, r;
        return f(this, function (a) {
          switch (a.label) {
            case 0:
              return this._validate() ? this._isLoading ? [3, 3] : this._hasNext ? (this._isLoading = !0, [4, fs.of(this._iid).getMyGroupChannels(this._token, n(s({}, this)), this.limit, y.REQUEST_CHANNEL, !0)]) : [3, 2] : [3, 5];
            case 1:
              return e = a.sent(), t = e.channels, r = e.token, this._token = r, this._hasNext = !!r, this._isLoading = !1, [2, t];
            case 2:
              return [2, []];
            case 3:
              throw h.queryInProgress;
            case 4:
              return [3, 6];
            case 5:
              throw h.invalidParameters;
            case 6:
              return [2];
          }
        });
      });
    }, a;
  }(F),
  ls = function (n) {
    function s(e) {
      var s = e.channelUrl,
        r = e.userId,
        a = n.call(this) || this;
      return a.method = N.PUT, a.path = "".concat(I, "/").concat(encodeURIComponent(s), "/messages/mark_as_delivered"), a.params = t({
        user_id: r
      }), a;
    }
    return e(s, n), s;
  }(S);
!function (t) {
  function n() {
    return null !== t && t.apply(this, arguments) || this;
  }
  e(n, t);
}(T);
var hs,
  ds = function (t) {
    function n(e, n, s) {
      var r = t.call(this, e, "DLVR", s) || this;
      return r.channelUrl = s.channel_url, r.deliveredStateUpdate = s.updated, r;
    }
    return e(n, t), n;
  }(L),
  _s = function () {
    function e(e) {
      var t = e.top,
        n = void 0 === t ? Number.MAX_SAFE_INTEGER : t,
        s = e.bottom,
        r = void 0 === s ? 0 : s;
      this.top = n, this.bottom = r;
    }
    return e.prototype.includes = function () {
      for (var e = this, t = [], n = 0; n < arguments.length; n++) t[n] = arguments[n];
      return t.every(function (t) {
        return e.top <= t && t <= e.bottom;
      });
    }, e.prototype.overlap = function (e) {
      return this.includes(e.top) || this.includes(e.bottom);
    }, e.prototype.intersect = function () {
      for (var e = this, t = [], n = 0; n < arguments.length; n++) t[n] = arguments[n];
      return t.some(function (t) {
        return e.top <= t && t <= e.bottom;
      });
    }, e.prototype.extends = function () {
      for (var e = [], t = 0; t < arguments.length; t++) e[t] = arguments[t];
      this.top = Math.min.apply(Math, d([this.top], _(e), !1)), this.bottom = Math.max.apply(Math, d([this.bottom], _(e), !1));
    }, e;
  }(),
  ps = {},
  fs = function (o) {
    function u(e, t) {
      var n,
        r = this;
      return (r = o.call(this, e, s(s({}, t), {
        channelType: i.GROUP
      })) || this)._leftChannels = new Map(), r._markAsReadAllLastSentAt = 0, r._forceDisableMack = null !== (n = t.forceDisableMack) && void 0 !== n && n, r._groupChannelHandlers = new Map(), r._groupChannelCache = new on(r._iid, {
        sdkState: t.sdkState,
        cacheContext: t.cacheContext
      }), r._groupChannelBroadcast = new Mn({
        dispatcher: t.dispatcher,
        groupChannelCache: r._groupChannelCache,
        messageCache: Ne.of(r._iid),
        unsentMessageCache: Ue.of(r._iid)
      }), setInterval(function () {
        var e,
          t,
          n = function (e) {
            e.invalidateTypingStatus() && (r._dispatcher.dispatch(new Cn({
              channels: [e],
              context: {
                source: y.EVENT_CHANNEL_TYPING_STATUS_UPDATE
              }
            })), r._groupChannelHandlers.forEach(function (t) {
              t.onTypingStatusUpdated && t.onTypingStatusUpdated(e);
            }));
          };
        try {
          for (var s = v(r._groupChannelCache.channels), a = s.next(); !a.done; a = s.next()) {
            n(a.value);
          }
        } catch (t) {
          e = {
            error: t
          };
        } finally {
          try {
            a && !a.done && (t = s.return) && t.call(s);
          } finally {
            if (e) throw e.error;
          }
        }
      }, 1e3), r._dispatcher.on(function (e) {
        e instanceof L ? r._handleEvent(e).catch(function (e) {
          if (O(e) && "foreground" === r._sdkState.appState) throw e;
        }) : e instanceof wt ? p(r, void 0, void 0, function () {
          var t, n;
          return f(this, function (s) {
            switch (s.label) {
              case 0:
                return t = e.message, [4, this.getChannelFromCache(t.channelUrl)];
              case 1:
                return (n = s.sent()) && !n.isFrozen ? [3, 3] : [4, this.getChannelWithoutCache(t.channelUrl, !0)];
              case 2:
                n = s.sent(), s.label = 3;
              case 3:
                return n.isFrozen || (t instanceof Te ? n._autoResendUserMessage(t) : t instanceof Ae && n._autoResendFileMessage(t)), [2];
            }
          });
        }) : e instanceof Lt ? r.reduceDBSize() : e instanceof Se && p(r, void 0, void 0, function () {
          var t,
            n,
            s,
            r,
            a,
            o = this;
          return f(this, function (u) {
            switch (u.label) {
              case 0:
                return t = e.channelUrl, n = e.channelType, s = e.parentMessage, r = new Ie(this._iid, {
                  channel_type: i.GROUP,
                  channel_url: t,
                  parent_message_id: s.messageId,
                  thread_info: we.payloadify(s.threadInfo)
                }), n !== i.GROUP ? [3, 2] : [4, this.getChannel(t, !0)];
              case 1:
                a = u.sent(), H(function () {
                  return p(o, void 0, void 0, function () {
                    var e, t, n, s, i;
                    return f(this, function (o) {
                      try {
                        for (e = v(this._groupChannelHandlers.values()), t = e.next(); !t.done; t = e.next()) (n = t.value).onThreadInfoUpdated && n.onThreadInfoUpdated(a, r);
                      } catch (e) {
                        s = {
                          error: e
                        };
                      } finally {
                        try {
                          t && !t.done && (i = e.return) && i.call(e);
                        } finally {
                          if (s) throw s.error;
                        }
                      }
                      return [2];
                    });
                  });
                }), u.label = 2;
              case 2:
                return [2];
            }
          });
        });
      }), ps[e] || (ps[e] = r), r;
    }
    return e(u, o), Object.defineProperty(u.prototype, "_messageCache", {
      get: function () {
        return Ne.of(this._iid);
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(u.prototype, "_unsentMessageCache", {
      get: function () {
        return Ue.of(this._iid);
      },
      enumerable: !1,
      configurable: !0
    }), u.of = function (e) {
      return ps[e] || (ps[e] = new u(e, P.of(e))), ps[e];
    }, u.clear = function (e) {
      ps[e] && delete ps[e];
    }, Object.defineProperty(u.prototype, "handlers", {
      get: function () {
        return d([], _(this._groupChannelHandlers.values()), !1);
      },
      enumerable: !1,
      configurable: !0
    }), u.prototype.buildGroupChannelFromSerializedData = function (e) {
      var t = k(e);
      return new cr(this._iid, cr.payloadify(t));
    }, u.prototype.buildGroupChannelListQueryFromSerializedData = function (e) {
      var t = k(e);
      return new cs(this._iid, t);
    }, u.prototype.buildMemberFromSerializedData = function (e) {
      var t = k(e);
      return new nn(this._iid, nn.payloadify(t));
    }, u.prototype.getChannelFromCache = function (e) {
      var t;
      return p(this, void 0, void 0, function () {
        return f(this, function (n) {
          switch (n.label) {
            case 0:
              return [4, this._groupChannelCache.get(e)];
            case 1:
              return [2, null !== (t = n.sent()) && void 0 !== t ? t : null];
          }
        });
      });
    }, u.prototype.getChannelsFromCache = function (e, t, n, s, r) {
      return p(this, void 0, void 0, function () {
        return f(this, function (a) {
          switch (a.label) {
            case 0:
              return [4, this._groupChannelCache.fetch({
                token: e,
                filter: t,
                order: n,
                limit: s,
                borderlineChannelUrl: r
              })];
            case 1:
              return [2, a.sent()];
          }
        });
      });
    }, u.prototype.upsertChannelsToCache = function (e) {
      return p(this, void 0, void 0, function () {
        return f(this, function (t) {
          switch (t.label) {
            case 0:
              return [4, this._groupChannelCache.upsert(e)];
            case 1:
              return [2, t.sent()];
          }
        });
      });
    }, u.prototype.removeChannelsFromCache = function (e) {
      return p(this, void 0, void 0, function () {
        return f(this, function (t) {
          switch (t.label) {
            case 0:
              return [4, this._groupChannelCache.remove(e)];
            case 1:
              return t.sent(), [2];
          }
        });
      });
    }, u.prototype.clearChannelsFromCache = function () {
      return p(this, void 0, void 0, function () {
        return f(this, function (e) {
          switch (e.label) {
            case 0:
              return [4, this._groupChannelCache.clear()];
            case 1:
              return e.sent(), [2];
          }
        });
      });
    }, u.prototype.reduceDBSize = function () {
      return p(this, void 0, void 0, function () {
        var e, t, n, s, r, a, i, o, u, c, l, h, d, _, p;
        return f(this, function (f) {
          switch (f.label) {
            case 0:
              return e = Ne.of(this._iid), t = P.of(this._iid).cacheContext, n = t.localCacheConfig, s = t.nestdb, t.localCacheEnabled && s && s.state == De.OPENED ? (r = 1024 * n.maxSize * 1024, [4, s.estimateUsage()]) : [2];
            case 1:
              if ((a = f.sent()) < r) return [2];
              i = [], o = this._groupChannelCache.channels, u = {}, d = 0, f.label = 2;
            case 2:
              return d < o.length ? [4, this.getMessagesFromCache(o[d].url, 0, "prev", new Le())] : [3, 5];
            case 3:
              c = f.sent(), u[o[d].url] = JSON.stringify(c).length, l = new G({
                channel: o[d],
                cachedMessageCount: c.length
              }), i.push(l), f.label = 4;
            case 4:
              return d++, [3, 2];
            case 5:
              h = i.sort(n.clearOrderComparator), d = 0, f.label = 6;
            case 6:
              return d < h.length ? [4, e.removeMessagesOfChannel(h[d].channel.url)] : [3, 10];
            case 7:
              return f.sent(), [4, e._getGroupChannelPreferenceSize(h[d].channel.url)];
            case 8:
              if (_ = f.sent(), p = u[h[d].channel.url] + _, (a -= p) < r) return [3, 10];
              f.label = 9;
            case 9:
              return d++, [3, 6];
            case 10:
              return [2];
          }
        });
      });
    }, u.prototype._isSuperGroupMackDisabled = function (e) {
      var t = P.of(this._iid).appInfo;
      return !(!e.isSuper || e.isBroadcast || !(null == t ? void 0 : t.disableSuperGroupMack));
    }, u.prototype._handleEvent = function (e) {
      var t, n;
      return p(this, void 0, void 0, function () {
        var s,
          r,
          o,
          u,
          c,
          l,
          h,
          d,
          _,
          m,
          g,
          E,
          C,
          M,
          N,
          U,
          S,
          T,
          A,
          I,
          w,
          L,
          D,
          x,
          R,
          F,
          k,
          G,
          K,
          X,
          Z,
          $,
          ee,
          te,
          ne,
          se,
          re,
          ae,
          ie,
          oe,
          ue,
          ce,
          le,
          he,
          de,
          _e,
          pe,
          fe,
          ve,
          me,
          ge,
          Ee,
          Ce,
          ye,
          Me,
          be,
          Ne,
          Ue,
          Se,
          Te,
          Ae,
          Ie,
          we,
          Le,
          De,
          Ke,
          Je,
          Xe,
          Ze,
          $e,
          et,
          tt,
          nt,
          st,
          rt,
          at,
          it,
          ot,
          ut,
          ct,
          lt,
          ht,
          dt,
          _t,
          pt,
          ft,
          vt,
          mt,
          gt,
          Et,
          Ct,
          yt,
          Mt,
          bt,
          Nt,
          Ut,
          St,
          Tt,
          At,
          It,
          wt,
          Lt,
          Ft,
          kt,
          Yt,
          Kt,
          Jt,
          Xt,
          Zt,
          $t,
          en,
          tn,
          nn,
          sn,
          rn,
          an,
          on,
          un,
          cn,
          ln,
          hn,
          dn,
          _n,
          pn,
          fn,
          vn,
          mn,
          gn,
          En,
          Mn,
          bn,
          Nn,
          Un,
          Sn,
          Tn,
          An,
          In,
          wn,
          Ln,
          Dn,
          xn = this;
        return f(this, function (Rn) {
          switch (Rn.label) {
            case 0:
              switch (Rn.trys.push([0, 80,, 81]), e.code) {
                case "MESG":
                case "FILE":
                case "ADMM":
                case "BRDM":
                  return [3, 1];
                case "MEDI":
                case "FEDI":
                case "AEDI":
                  return [3, 4];
                case "DELM":
                  return [3, 7];
                case "READ":
                  return [3, 10];
                case "DLVR":
                  return [3, 13];
                case "MRCT":
                  return [3, 15];
                case "MTHD":
                  return [3, 19];
                case "MCNT":
                  return [3, 23];
                case "PEDI":
                  return [3, 32];
                case "VOTE":
                  return [3, 35];
                case "SYEV":
                  return [3, 38];
                case "USEV":
                  return [3, 78];
              }
              return [3, 79];
            case 1:
              return d = null, "MESG" === e.code ? d = e.as(Qe) : "FILE" === e.code ? d = e.as(Ye) : "ADMM" !== e.code && "BRDM" != e.code || (d = e.as(Wt)), d ? (s = d.message, r = d.isMentioned, o = d.forceUpdateLastMessage, s.channelType !== i.GROUP ? [3, 3] : (G = this._groupChannelCache.isCachedInMemory(s.channelUrl), L = s instanceof ze && s.sender.userId === this._sdkState.userId, [4, this.getChannel(s.channelUrl, !0)])) : [3, 3];
            case 2:
              if (u = Rn.sent(), c = P.of(this._iid).useMemberInfoInMessage, this._forceDisableMack || this._isSuperGroupMackDisabled(u) || s instanceof We || L || b(function () {
                return p(xn, void 0, void 0, function () {
                  var e;
                  return f(this, function (t) {
                    switch (t.label) {
                      case 0:
                        return e = new us(s), [4, this._requestQueue.send(e)];
                      case 1:
                        return t.sent(), [2];
                    }
                  });
                });
              }), s instanceof ze) {
                try {
                  for (l = v(u.members), h = l.next(); !h.done; h = l.next()) if ((Mt = h.value).userId === s.sender.userId) {
                    c || (s.sender.nickname = Mt.nickname, s.sender.plainProfileUrl = Mt.plainProfileUrl, s.sender.metaData = Mt.metaData, s.sender.isBlockedByMe = Mt.isBlockedByMe);
                    break;
                  }
                } catch (e) {
                  bn = {
                    error: e
                  };
                } finally {
                  try {
                    h && !h.done && (Nn = l.return) && Nn.call(l);
                  } finally {
                    if (bn) throw bn.error;
                  }
                }
                !c && r && (null === (t = s.mentionedUsers) || void 0 === t || t.forEach(function (e) {
                  var t, n;
                  try {
                    for (var s = v(u.members), r = s.next(); !r.done; r = s.next()) {
                      var a = r.value;
                      if (e.userId === a.userId) {
                        e.nickname = a.nickname, e.plainProfileUrl = a.plainProfileUrl, e.metaData = a.metaData;
                        break;
                      }
                    }
                  } catch (e) {
                    t = {
                      error: e
                    };
                  } finally {
                    try {
                      r && !r.done && (n = s.return) && n.call(s);
                    } finally {
                      if (t) throw t.error;
                    }
                  }
                })), L && (M = this._sessionManager.currentUser) && (M.nickname = s.sender.nickname, M.plainProfileUrl = s.sender.plainProfileUrl, M.metaData = s.sender.metaData);
              }
              s.silent && !L || (u.isEphemeral || G) && (u._updateLastMessage(s), L || u._shouldUpdateUnreadCountWith(s) && u._updateUnreadCount(u.unreadMessageCount + 1, u.unreadMentionCount + (r ? 1 : 0))), o && u._updateLastMessage(s), this._dispatcher.dispatch(new Cn({
                channels: [u],
                context: {
                  source: y.EVENT_MESSAGE_RECEIVED
                }
              })), s.silent && !L || H(function () {
                return p(xn, void 0, void 0, function () {
                  var e, t, n, s, r;
                  return f(this, function (a) {
                    try {
                      for (e = v(this._groupChannelHandlers.values()), t = e.next(); !t.done; t = e.next()) (n = t.value).onChannelChanged && n.onChannelChanged(u);
                    } catch (e) {
                      s = {
                        error: e
                      };
                    } finally {
                      try {
                        t && !t.done && (r = e.return) && r.call(e);
                      } finally {
                        if (s) throw s.error;
                      }
                    }
                    return [2];
                  });
                });
              }), this._dispatcher.dispatch(new j({
                messages: [s],
                source: y.EVENT_MESSAGE_RECEIVED
              })), H(function () {
                return p(xn, void 0, void 0, function () {
                  var e, t, n, a, i;
                  return f(this, function (o) {
                    try {
                      for (e = v(this._groupChannelHandlers.values()), t = e.next(); !t.done; t = e.next()) (n = t.value).onMessageReceived && n.onMessageReceived(u, s), r && n.onMentionReceived && n.onMentionReceived(u, s);
                    } catch (e) {
                      a = {
                        error: e
                      };
                    } finally {
                      try {
                        t && !t.done && (i = e.return) && i.call(e);
                      } finally {
                        if (a) throw a.error;
                      }
                    }
                    return [2];
                  });
                });
              }), Rn.label = 3;
            case 3:
              return [3, 79];
            case 4:
              return d = null, "MEDI" === e.code ? d = e.as(qe) : "FEDI" === e.code ? d = e.as(je) : "AEDI" === e.code && (d = e.as(jt)), d ? (_ = d.message, m = d.mentionCountChange, _.threadInfo && (_.threadInfo.unreadReplyCount = void 0), _.channelType !== i.GROUP ? [3, 6] : (G = this._groupChannelCache.isCachedInMemory(_.channelUrl), [4, this.getChannel(_.channelUrl, !0)])) : [3, 6];
            case 5:
              g = Rn.sent(), L = _ instanceof ze && _.sender.userId === this._sdkState.userId, E = !1, L ? (C = _.sender, (M = this._sessionManager.currentUser) && (M.nickname = C.nickname, M.plainProfileUrl = C.plainProfileUrl, M.metaData = C.metaData)) : g.isReadMessage(_) || 0 !== m && !_.silent && G && (g._updateUnreadCount(g.unreadMessageCount, g.unreadMentionCount + m), E = !0), g._updateLastMessage(_) ? E = !0 : (null === (n = g.lastMessage) || void 0 === n ? void 0 : n.isIdentical(_)) && (G ? g._updateLastMessage(_) && (E = !0) : E = !0), N = !1, g.lastPinnedMessage && g.lastPinnedMessage.messageId === _.messageId && (g.lastPinnedMessage = _, E = !0, N = !0), E && (this._dispatcher.dispatch(new Cn({
                channels: [g],
                context: {
                  source: N ? y.EVENT_PINNED_MESSAGE_UPDATED : y.EVENT_MESSAGE_UPDATED
                }
              })), _.silent && !L || H(function () {
                return p(xn, void 0, void 0, function () {
                  var e, t, n, s, r;
                  return f(this, function (a) {
                    try {
                      for (e = v(this._groupChannelHandlers.values()), t = e.next(); !t.done; t = e.next()) (n = t.value).onChannelChanged && n.onChannelChanged(g);
                    } catch (e) {
                      s = {
                        error: e
                      };
                    } finally {
                      try {
                        t && !t.done && (r = e.return) && r.call(e);
                      } finally {
                        if (s) throw s.error;
                      }
                    }
                    return [2];
                  });
                });
              }), N && H(function () {
                return p(xn, void 0, void 0, function () {
                  var e, t, n, s, r;
                  return f(this, function (a) {
                    try {
                      for (e = v(this._groupChannelHandlers.values()), t = e.next(); !t.done; t = e.next()) (n = t.value).onPinnedMessageUpdated && n.onPinnedMessageUpdated(g);
                    } catch (e) {
                      s = {
                        error: e
                      };
                    } finally {
                      try {
                        t && !t.done && (r = e.return) && r.call(e);
                      } finally {
                        if (s) throw s.error;
                      }
                    }
                    return [2];
                  });
                });
              })), this._dispatcher.dispatch(new j({
                messages: [_],
                source: y.EVENT_MESSAGE_UPDATED
              })), H(function () {
                return p(xn, void 0, void 0, function () {
                  var e, t, n, s, r;
                  return f(this, function (a) {
                    try {
                      for (e = v(this._groupChannelHandlers.values()), t = e.next(); !t.done; t = e.next()) (n = t.value).onMessageUpdated && n.onMessageUpdated(g, _), m > 0 && n.onMentionReceived && n.onMentionReceived(g, _);
                    } catch (e) {
                      s = {
                        error: e
                      };
                    } finally {
                      try {
                        t && !t.done && (r = e.return) && r.call(e);
                      } finally {
                        if (s) throw s.error;
                      }
                    }
                    return [2];
                  });
                });
              }), Rn.label = 6;
            case 6:
              return [3, 79];
            case 7:
              return U = e.as(Y), me = U.channelUrl, ge = U.channelType, S = U.messageId, T = U.silent, A = U.messageCreatedAt, I = U.senderId, ge !== i.GROUP ? [3, 9] : [4, this.getChannel(me, !0)];
            case 8:
              w = Rn.sent(), L = I === this._sdkState.userId, T || L || A > 0 && w.myLastRead < A && w.unreadMessageCount > 0 && w._updateUnreadCount(w.unreadMessageCount - 1, 0), this._dispatcher.dispatch(new Q({
                messageIds: [S],
                source: y.EVENT_MESSAGE_DELETED
              })), H(function () {
                return p(xn, void 0, void 0, function () {
                  var e, t, n, s, r;
                  return f(this, function (a) {
                    try {
                      for (e = v(this._groupChannelHandlers.values()), t = e.next(); !t.done; t = e.next()) (n = t.value).onMessageDeleted && n.onMessageDeleted(w, S);
                    } catch (e) {
                      s = {
                        error: e
                      };
                    } finally {
                      try {
                        t && !t.done && (r = e.return) && r.call(e);
                      } finally {
                        if (s) throw s.error;
                      }
                    }
                    return [2];
                  });
                });
              }), Rn.label = 9;
            case 9:
              return [3, 79];
            case 10:
              return (D = e.as(Be).readStatus).channelType !== i.GROUP ? [3, 12] : (G = this._groupChannelCache.isCachedInMemory(D.channelUrl), [4, this.getChannel(D.channelUrl, !0)]);
            case 11:
              x = Rn.sent(), G && x._updateUnreadMemberState(D.reader.userId, D.readAt), D.reader.userId === this._sdkState.userId ? G ? (x.unreadMessageCount > 0 || x.unreadMentionCount > 0) && (x._updateUnreadCount(0, 0), this._dispatcher.dispatch(new Cn({
                channels: [x],
                context: {
                  source: y.EVENT_CHANNEL_READ
                }
              })), H(function () {
                return p(xn, void 0, void 0, function () {
                  var e, t, n, s, r;
                  return f(this, function (a) {
                    try {
                      for (e = v(this._groupChannelHandlers.values()), t = e.next(); !t.done; t = e.next()) (n = t.value).onChannelChanged && n.onChannelChanged(x);
                    } catch (e) {
                      s = {
                        error: e
                      };
                    } finally {
                      try {
                        t && !t.done && (r = e.return) && r.call(e);
                      } finally {
                        if (s) throw s.error;
                      }
                    }
                    return [2];
                  });
                });
              })) : 0 !== x.unreadMessageCount && 0 !== x.unreadMentionCount || (this._dispatcher.dispatch(new Cn({
                channels: [x],
                context: {
                  source: y.EVENT_CHANNEL_READ
                }
              })), H(function () {
                return p(xn, void 0, void 0, function () {
                  var e, t, n, s, r;
                  return f(this, function (a) {
                    try {
                      for (e = v(this._groupChannelHandlers.values()), t = e.next(); !t.done; t = e.next()) (n = t.value).onChannelChanged && n.onChannelChanged(x);
                    } catch (e) {
                      s = {
                        error: e
                      };
                    } finally {
                      try {
                        t && !t.done && (r = e.return) && r.call(e);
                      } finally {
                        if (s) throw s.error;
                      }
                    }
                    return [2];
                  });
                });
              })) : (this._dispatcher.dispatch(new Cn({
                channels: [x],
                context: {
                  source: y.EVENT_CHANNEL_READ
                }
              })), H(function () {
                return p(xn, void 0, void 0, function () {
                  var e, t, n, s, r;
                  return f(this, function (a) {
                    try {
                      for (e = v(this._groupChannelHandlers.values()), t = e.next(); !t.done; t = e.next()) (n = t.value).onUnreadMemberStatusUpdated && n.onUnreadMemberStatusUpdated(x);
                    } catch (e) {
                      s = {
                        error: e
                      };
                    } finally {
                      try {
                        t && !t.done && (r = e.return) && r.call(e);
                      } finally {
                        if (s) throw s.error;
                      }
                    }
                    return [2];
                  });
                });
              })), Rn.label = 12;
            case 12:
              return [3, 79];
            case 13:
              return R = e.as(ds), me = R.channelUrl, F = R.deliveredStateUpdate, k = void 0 === F ? {} : F, G = this._groupChannelCache.isCachedInMemory(me), [4, this.getChannel(me, !0)];
            case 14:
              return K = Rn.sent(), G && Object.keys(k).forEach(function (e) {
                K._updateUndeliveredMemberState(e, k[e]);
              }), Object.keys(k).some(function (e) {
                return e !== xn._sdkState.userId;
              }) && (this._dispatcher.dispatch(new Cn({
                channels: [K],
                context: {
                  source: y.EVENT_CHANNEL_DELIVERED
                }
              })), H(function () {
                return p(xn, void 0, void 0, function () {
                  var e, t, n, s, r;
                  return f(this, function (a) {
                    try {
                      for (e = v(this._groupChannelHandlers.values()), t = e.next(); !t.done; t = e.next()) (n = t.value).onUndeliveredMemberStatusUpdated && n.onUndeliveredMemberStatusUpdated(K);
                    } catch (e) {
                      s = {
                        error: e
                      };
                    } finally {
                      try {
                        t && !t.done && (r = e.return) && r.call(e);
                      } finally {
                        if (s) throw s.error;
                      }
                    }
                    return [2];
                  });
                });
              })), [3, 79];
            case 15:
              return X = e.as(qt), me = X.channelUrl, ge = X.channelType, Z = X.event, ge !== i.GROUP ? [3, 18] : [4, this.getChannel(me, !0)];
            case 16:
              return $ = Rn.sent(), [4, this.getMessageFromCache(Z.messageId)];
            case 17:
              (ne = Rn.sent()) ? ne instanceof Ve && (ne.applyReactionEvent(Z), this._dispatcher.dispatch(new j({
                messages: [ne],
                source: y.EVENT_MESSAGE_REACTION_UPDATED
              }))) : this._dispatcher.dispatch(new W({
                event: Z,
                source: y.EVENT_MESSAGE_REACTION_UPDATED
              })), H(function () {
                return p(xn, void 0, void 0, function () {
                  var e, t, n, s, r;
                  return f(this, function (a) {
                    try {
                      for (e = v(this._groupChannelHandlers.values()), t = e.next(); !t.done; t = e.next()) (n = t.value).onReactionUpdated && n.onReactionUpdated($, Z);
                    } catch (e) {
                      s = {
                        error: e
                      };
                    } finally {
                      try {
                        t && !t.done && (r = e.return) && r.call(e);
                      } finally {
                        if (s) throw s.error;
                      }
                    }
                    return [2];
                  });
                });
              }), Rn.label = 18;
            case 18:
              return [3, 79];
            case 19:
              return (ee = e.as(zt).event).channelType !== i.GROUP ? [3, 22] : [4, this.getChannel(ee.channelUrl, !0)];
            case 20:
              return te = Rn.sent(), [4, this.getMessageFromCache(ee.targetMessageId)];
            case 21:
              (ne = Rn.sent()) ? ne instanceof Ve && (ne.applyThreadInfoUpdateEvent(ee), this._dispatcher.dispatch(new j({
                messages: [ne],
                source: y.EVENT_MESSAGE_THREADINFO_UPDATED
              }))) : this._dispatcher.dispatch(new q({
                event: ee,
                source: y.EVENT_MESSAGE_THREADINFO_UPDATED
              })), H(function () {
                return p(xn, void 0, void 0, function () {
                  var e, t, n, s, r;
                  return f(this, function (a) {
                    try {
                      for (e = v(this._groupChannelHandlers.values()), t = e.next(); !t.done; t = e.next()) (n = t.value).onThreadInfoUpdated && n.onThreadInfoUpdated(te, ee);
                    } catch (e) {
                      s = {
                        error: e
                      };
                    } finally {
                      try {
                        t && !t.done && (r = e.return) && r.call(e);
                      } finally {
                        if (s) throw s.error;
                      }
                    }
                    return [2];
                  });
                });
              }), Rn.label = 22;
            case 22:
              return [3, 79];
            case 23:
              se = e.as(Bt).groupChannelMemberCounts, re = [], Rn.label = 24;
            case 24:
              Rn.trys.push([24, 29, 30, 31]), ae = v(se), ie = ae.next(), Rn.label = 25;
            case 25:
              return ie.done ? [3, 28] : (oe = ie.value, me = oe.channelUrl, lt = oe.memberCount, ht = oe.joinedMemberCount, ue = oe.updatedAt, [4, this.getChannelFromCache(me)]);
            case 26:
              (ce = Rn.sent()) && ce._setLatestMemberCount(lt, ht, ue) && re.push(ce), Rn.label = 27;
            case 27:
              return ie = ae.next(), [3, 25];
            case 28:
              return [3, 31];
            case 29:
              return le = Rn.sent(), Un = {
                error: le
              }, [3, 31];
            case 30:
              try {
                ie && !ie.done && (Sn = ae.return) && Sn.call(ae);
              } finally {
                if (Un) throw Un.error;
              }
              return [7];
            case 31:
              return re.length > 0 && (this._dispatcher.dispatch(new Cn({
                channels: re,
                context: {
                  source: y.EVENT_CHANNEL_MEMBER_COUNT_UPDATED
                }
              })), H(function () {
                return p(xn, void 0, void 0, function () {
                  var e, t, n, s, r;
                  return f(this, function (a) {
                    try {
                      for (e = v(this._groupChannelHandlers.values()), t = e.next(); !t.done; t = e.next()) (n = t.value).onChannelMemberCountChanged && n.onChannelMemberCountChanged(re);
                    } catch (e) {
                      s = {
                        error: e
                      };
                    } finally {
                      try {
                        t && !t.done && (r = e.return) && r.call(e);
                      } finally {
                        if (s) throw s.error;
                      }
                    }
                    return [2];
                  });
                });
              })), [3, 79];
            case 32:
              return he = e.as(Vt), de = he.event, _e = he.status, me = he.channelUrl, ge = he.channelType, me && ge === i.GROUP ? [4, this.getChannel(me, !0)] : [3, 34];
            case 33:
              pe = Rn.sent(), this._dispatcher.dispatch(new z({
                event: de,
                source: y.EVENT_POLL_UPDATED
              })), H(_e === J ? function () {
                return p(xn, void 0, void 0, function () {
                  var e, t, n, s, r;
                  return f(this, function (a) {
                    try {
                      for (e = v(this._groupChannelHandlers.values()), t = e.next(); !t.done; t = e.next()) (n = t.value).onPollDeleted && n.onPollDeleted(pe, de.pollId);
                    } catch (e) {
                      s = {
                        error: e
                      };
                    } finally {
                      try {
                        t && !t.done && (r = e.return) && r.call(e);
                      } finally {
                        if (s) throw s.error;
                      }
                    }
                    return [2];
                  });
                });
              } : function () {
                return p(xn, void 0, void 0, function () {
                  var e, t, n, s, r;
                  return f(this, function (a) {
                    try {
                      for (e = v(this._groupChannelHandlers.values()), t = e.next(); !t.done; t = e.next()) (n = t.value).onPollUpdated && n.onPollUpdated(pe, de);
                    } catch (e) {
                      s = {
                        error: e
                      };
                    } finally {
                      try {
                        t && !t.done && (r = e.return) && r.call(e);
                      } finally {
                        if (s) throw s.error;
                      }
                    }
                    return [2];
                  });
                });
              }), Rn.label = 34;
            case 34:
              return [3, 79];
            case 35:
              return fe = e.as(Ge), ve = fe.event, me = fe.channelUrl, ge = fe.channelType, me && ge === i.GROUP ? [4, this.getChannel(me, !0)] : [3, 37];
            case 36:
              Ee = Rn.sent(), this._dispatcher.dispatch(new B({
                event: ve,
                source: y.EVENT_POLL_VOTED
              })), H(function () {
                return p(xn, void 0, void 0, function () {
                  var e, t, n, s, r;
                  return f(this, function (a) {
                    try {
                      for (e = v(this._groupChannelHandlers.values()), t = e.next(); !t.done; t = e.next()) (n = t.value).onPollVoted && n.onPollVoted(Ee, ve);
                    } catch (e) {
                      s = {
                        error: e
                      };
                    } finally {
                      try {
                        t && !t.done && (r = e.return) && r.call(e);
                      } finally {
                        if (s) throw s.error;
                      }
                    }
                    return [2];
                  });
                });
              }), Rn.label = 37;
            case 37:
              return [3, 79];
            case 38:
              if (!(Ce = e.as(Pt).event).isGroupChannelEvent) return [3, 77];
              switch (Ce.category) {
                case Ht.CHANNEL_JOIN:
                  return [3, 39];
                case Ht.CHANNEL_LEAVE:
                  return [3, 41];
                case Ht.CHANNEL_OPERATOR_UPDATE:
                  return [3, 45];
                case Ht.CHANNEL_INVITE:
                  return [3, 47];
                case Ht.CHANNEL_DECLINE_INVITE:
                  return [3, 49];
                case Ht.TYPING_START:
                case Ht.TYPING_END:
                  return [3, 51];
                case Ht.USER_CHANNEL_MUTE:
                case Ht.USER_CHANNEL_UNMUTE:
                  return [3, 53];
                case Ht.USER_CHANNEL_BAN:
                  return [3, 55];
                case Ht.USER_CHANNEL_UNBAN:
                  return [3, 59];
                case Ht.CHANNEL_FREEZE:
                case Ht.CHANNEL_UNFREEZE:
                  return [3, 61];
                case Ht.CHANNEL_HIDE:
                  return [3, 63];
                case Ht.CHANNEL_UNHIDE:
                  return [3, 65];
                case Ht.CHANNEL_DELETED:
                  return [3, 67];
                case Ht.CHANNEL_PROP_CHANGED:
                  return [3, 69];
                case Ht.CHANNEL_META_DATA_CHANGED:
                  return [3, 71];
                case Ht.CHANNEL_META_COUNTERS_CHANGED:
                  return [3, 73];
                case Ht.PINNED_MESSAGE_CHANGED:
                  return [3, 75];
              }
              return [3, 77];
            case 39:
              return [4, this.getChannel(Ce.channelUrl, !0)];
            case 40:
              return ye = Rn.sent(), Me = e.as(Wn), be = Me.memberCount, Ne = Me.joinedMemberCount, Ue = Me.members, Se = !1, Ue.forEach(function (e) {
                ye.isExclusive || ye.isSuper || ye.isBroadcast ? Se = Se || ye._setLatestMemberCount(be, Ne, Ce.ts) : (e.state = Qt.JOINED, ye.addMember(e, Ce.ts), xn._updateJoinedMemberCount(ye)), e.userId === xn._sdkState.userId && (ye.myMemberState = Qt.JOINED);
              }), this._dispatcher.dispatch(new Cn({
                channels: [ye],
                context: {
                  source: y.EVENT_CHANNEL_JOINED,
                  users: Ue
                }
              })), H(function () {
                return p(xn, void 0, void 0, function () {
                  return f(this, function (e) {
                    return this._groupChannelHandlers.forEach(function (e) {
                      var t, n;
                      try {
                        for (var s = v(Ue), r = s.next(); !r.done; r = s.next()) {
                          var a = r.value;
                          e.onUserJoined && e.onUserJoined(ye, a);
                        }
                      } catch (e) {
                        t = {
                          error: e
                        };
                      } finally {
                        try {
                          r && !r.done && (n = s.return) && n.call(s);
                        } finally {
                          if (t) throw t.error;
                        }
                      }
                      ye.isBroadcast && Se && e.onChannelMemberCountChanged && e.onChannelMemberCountChanged([ye]);
                    }), [2];
                  });
                });
              }), [3, 77];
            case 41:
              return (bt = this._leftChannels.get(Ce.channelUrl)) ? (Ae = bt.channel, [3, 44]) : [3, 42];
            case 42:
              return [4, this.getChannel(Ce.channelUrl, !0)];
            case 43:
              Ae = Rn.sent(), Rn.label = 44;
            case 44:
              return Te = Ae, Ie = e.as(Yn), lt = Ie.memberCount, ht = Ie.joinedMemberCount, we = Ie.member, Le = !1, De = P.of(this._iid).appInfo, Te.isExclusive || Te.isSuper || Te.isBroadcast ? Le = Te._setLatestMemberCount(lt, ht, Ce.ts) : ((null == De ? void 0 : De.enabledChannelMemberShipHistory) ? ((Ke = Te.members.find(function (e) {
                return e.userId === we.userId;
              })) && (Ke.state = Qt.LEFT), Te.memberCount = lt) : Te.removeMember(we), this._updateJoinedMemberCount(Te)), we.userId === this._sdkState.userId ? (Te.myMemberState = Qt.NONE, Te.invitedAt = 0, Te.joinedAt = 0, Te._updateUnreadCount(0, 0), Te.isPublic ? this._dispatcher.dispatch(new Cn({
                channels: [Te],
                context: {
                  source: y.EVENT_CHANNEL_LEFT,
                  user: we
                }
              })) : (this._markAsLeave(Te), this._dispatcher.dispatch(new yn({
                channelUrls: [Te.url],
                context: {
                  source: y.EVENT_CHANNEL_LEFT,
                  user: we
                }
              })))) : this._dispatcher.dispatch(new Cn({
                channels: [Te],
                context: {
                  source: y.EVENT_CHANNEL_LEFT,
                  user: we
                }
              })), H(function () {
                return p(xn, void 0, void 0, function () {
                  return f(this, function (e) {
                    return this._groupChannelHandlers.forEach(function (e) {
                      e.onUserLeft && e.onUserLeft(Te, we), Te.isBroadcast && Le && e.onChannelMemberCountChanged && e.onChannelMemberCountChanged([Te]);
                    }), [2];
                  });
                });
              }), [3, 77];
            case 45:
              return [4, this.getChannel(Ce.channelUrl, !0)];
            case 46:
              Je = Rn.sent(), Xe = e.as(Gt).operators, Ze = Xe.map(function (e) {
                return e.userId;
              });
              try {
                for ($e = v(Je.members), et = $e.next(); !et.done; et = $e.next()) (Mt = et.value).role = Ze.includes(Mt.userId) ? a.OPERATOR : a.NONE;
              } catch (e) {
                Tn = {
                  error: e
                };
              } finally {
                try {
                  et && !et.done && (An = $e.return) && An.call($e);
                } finally {
                  if (Tn) throw Tn.error;
                }
              }
              return Je.myRole = Ze.includes(this._sdkState.userId) ? a.OPERATOR : a.NONE, this._dispatcher.dispatch(new Cn({
                channels: [Je],
                context: {
                  source: y.EVENT_CHANNEL_OPERATOR_UPDATED,
                  operators: Xe
                }
              })), H(function () {
                return p(xn, void 0, void 0, function () {
                  return f(this, function (e) {
                    return this._groupChannelHandlers.forEach(function (e) {
                      e.onOperatorUpdated && e.onOperatorUpdated(Je, Xe);
                    }), [2];
                  });
                });
              }), [3, 77];
            case 47:
              return [4, this.getChannel(Ce.channelUrl, !0)];
            case 48:
              tt = Rn.sent(), nt = e.as(Xn), lt = nt.memberCount, ht = nt.joinedMemberCount, st = nt.inviter, (rt = nt.invitees).forEach(function (e) {
                return e.state = Qt.INVITED;
              });
              try {
                for (at = v(rt), it = at.next(); !it.done; it = at.next()) ot = it.value, tt.isExclusive || tt.isSuper || tt.isBroadcast ? tt._setLatestMemberCount(lt, ht, Ce.ts) : tt.addMember(ot, Ce.ts), this._sdkState.userId === ot.userId && (tt.hiddenState = nr.UNHIDDEN, tt.myMemberState !== Qt.JOINED && (tt.myMemberState = Qt.INVITED), tt.invitedAt = Ce.ts);
              } catch (e) {
                In = {
                  error: e
                };
              } finally {
                try {
                  it && !it.done && (wn = at.return) && wn.call(at);
                } finally {
                  if (In) throw In.error;
                }
              }
              return this._dispatcher.dispatch(new Cn({
                channels: [tt],
                context: {
                  source: y.EVENT_CHANNEL_INVITED,
                  inviter: st,
                  invitees: rt
                }
              })), H(function () {
                return p(xn, void 0, void 0, function () {
                  return f(this, function (e) {
                    return this._groupChannelHandlers.forEach(function (e) {
                      e.onUserReceivedInvitation && e.onUserReceivedInvitation(tt, st, rt);
                    }), [2];
                  });
                });
              }), [3, 77];
            case 49:
              return [4, this.getChannel(Ce.channelUrl, !0)];
            case 50:
              return ut = Rn.sent(), ct = e.as($n), lt = ct.memberCount, ht = ct.joinedMemberCount, dt = ct.inviter, _t = ct.invitee, ut.isExclusive || ut.isSuper || ut.isBroadcast ? ut._setLatestMemberCount(lt, ht, Ce.ts) : ut.removeMember(_t), this._sdkState.userId === _t.userId ? (ut.invitedAt = 0, ut.myMemberState = Qt.NONE, ut.isPublic ? this._dispatcher.dispatch(new Cn({
                channels: [ut],
                context: {
                  source: y.EVENT_CHANNEL_DECLINED_INVITE,
                  inviter: dt,
                  invitee: _t
                }
              })) : this._dispatcher.dispatch(new yn({
                channelUrls: [ut.url],
                context: {
                  source: y.EVENT_CHANNEL_DECLINED_INVITE,
                  inviter: dt,
                  invitee: _t
                }
              }))) : this._dispatcher.dispatch(new Cn({
                channels: [ut],
                context: {
                  source: y.EVENT_CHANNEL_DECLINED_INVITE,
                  inviter: dt,
                  invitee: _t
                }
              })), H(function () {
                return p(xn, void 0, void 0, function () {
                  return f(this, function (e) {
                    return this._groupChannelHandlers.forEach(function (e) {
                      e.onUserDeclinedInvitation && e.onUserDeclinedInvitation(ut, dt, _t);
                    }), [2];
                  });
                });
              }), [3, 77];
            case 51:
              return [4, this.getChannel(Ce.channelUrl, !0)];
            case 52:
              return pt = Rn.sent(), ft = Ce.category === Ht.TYPING_START, vt = e.as(ft ? as : os).user, pt._updateTypingStatus(vt, ft ? Ce.ts : 0), this._dispatcher.dispatch(new Cn({
                channels: [pt],
                context: {
                  source: y.EVENT_CHANNEL_TYPING_STATUS_UPDATE
                }
              })), H(function () {
                return p(xn, void 0, void 0, function () {
                  return f(this, function (e) {
                    return this._groupChannelHandlers.forEach(function (e) {
                      e.onTypingStatusUpdated && e.onTypingStatusUpdated(pt);
                    }), [2];
                  });
                });
              }), [3, 77];
            case 53:
              return [4, this.getChannel(Ce.channelUrl, !0)];
            case 54:
              mt = Rn.sent(), gt = Ce.category === Ht.USER_CHANNEL_MUTE, (Et = e.as(gt ? Oe : He).user).userId === this._sdkState.userId && (mt.myMutedState = gt ? V.MUTED : V.UNMUTED, mt._myMutedRemainingTime = Et.restrictionInfo.remainingDuration);
              try {
                for (Ct = v(mt.members), yt = Ct.next(); !yt.done; yt = Ct.next()) if ((Mt = yt.value).userId === Et.userId) {
                  Mt.isMuted = gt;
                  break;
                }
              } catch (e) {
                Ln = {
                  error: e
                };
              } finally {
                try {
                  yt && !yt.done && (Dn = Ct.return) && Dn.call(Ct);
                } finally {
                  if (Ln) throw Ln.error;
                }
              }
              return this._dispatcher.dispatch(new Cn({
                channels: [mt],
                context: {
                  source: gt ? y.EVENT_CHANNEL_MUTED : y.EVENT_CHANNEL_UNMUTED,
                  user: Et
                }
              })), H(function () {
                return p(xn, void 0, void 0, function () {
                  return f(this, function (e) {
                    return this._groupChannelHandlers.forEach(function (e) {
                      gt ? e.onUserMuted && e.onUserMuted(mt, Et) : e.onUserUnmuted && e.onUserUnmuted(mt, Et);
                    }), [2];
                  });
                });
              }), [3, 77];
            case 55:
              return (bt = this._leftChannels.get(Ce.channelUrl)) ? (Ut = bt.channel, [3, 58]) : [3, 56];
            case 56:
              return [4, this.getChannel(Ce.channelUrl, !0)];
            case 57:
              Ut = Rn.sent(), Rn.label = 58;
            case 58:
              return Nt = Ut, this._markAsLeave(Nt), St = e.as(ke).user, St.userId === this._sdkState.userId && this._dispatcher.dispatch(new yn({
                channelUrls: [Nt.url],
                context: {
                  source: y.EVENT_CHANNEL_BANNED,
                  user: St
                }
              })), H(function () {
                return p(xn, void 0, void 0, function () {
                  return f(this, function (e) {
                    return this._groupChannelHandlers.forEach(function (e) {
                      e.onUserBanned && e.onUserBanned(Nt, St);
                    }), [2];
                  });
                });
              }), [3, 77];
            case 59:
              return [4, this.getChannel(Ce.channelUrl, !0)];
            case 60:
              return Tt = Rn.sent(), At = e.as(Pe).user, At.userId === this._sdkState.userId && this._dispatcher.dispatch(new yn({
                channelUrls: [Tt.url],
                context: {
                  source: y.EVENT_CHANNEL_UNBANNED,
                  user: At
                }
              })), H(function () {
                return p(xn, void 0, void 0, function () {
                  return f(this, function (e) {
                    return this._groupChannelHandlers.forEach(function (e) {
                      e.onUserUnbanned && e.onUserUnbanned(Tt, At);
                    }), [2];
                  });
                });
              }), [3, 77];
            case 61:
              return [4, this.getChannel(Ce.channelUrl, !0)];
            case 62:
              return It = Rn.sent(), wt = e.as(Fe).freeze, It.isFrozen = wt, this._dispatcher.dispatch(new Cn({
                channels: [It],
                context: {
                  source: wt ? y.EVENT_CHANNEL_FROZEN : y.EVENT_CHANNEL_UNFROZEN
                }
              })), H(function () {
                return p(xn, void 0, void 0, function () {
                  return f(this, function (e) {
                    return this._groupChannelHandlers.forEach(function (e) {
                      wt ? e.onChannelFrozen && e.onChannelFrozen(It) : e.onChannelUnfrozen && e.onChannelUnfrozen(It);
                    }), [2];
                  });
                });
              }), [3, 77];
            case 63:
              return [4, this.getChannel(Ce.channelUrl, !0)];
            case 64:
              return Lt = Rn.sent(), Ft = e.as(ss), kt = Ft.allowAutoUnhide, Yt = Ft.hidePreviousMessages, Kt = Ft.messageOffsetTimestamp, null !== kt && (Lt.hiddenState = kt ? nr.HIDDEN_ALLOW_AUTO_UNHIDE : nr.HIDDEN_PREVENT_AUTO_UNHIDE), null !== Yt && Yt && Lt._updateUnreadCount(0, 0), null !== Kt && (Lt.messageOffsetTimestamp = Kt), this._dispatcher.dispatch(new Cn({
                channels: [Lt],
                context: {
                  source: y.EVENT_CHANNEL_HIDDEN
                }
              })), H(function () {
                return p(xn, void 0, void 0, function () {
                  return f(this, function (e) {
                    return this._groupChannelHandlers.forEach(function (e) {
                      e.onChannelHidden && e.onChannelHidden(Lt);
                    }), [2];
                  });
                });
              }), [3, 77];
            case 65:
              return [4, this.getChannel(Ce.channelUrl, !0)];
            case 66:
              return (Jt = Rn.sent()).hiddenState = nr.UNHIDDEN, this._dispatcher.dispatch(new Cn({
                channels: [Jt],
                context: {
                  source: y.EVENT_CHANNEL_UNHIDDEN
                }
              })), H(function () {
                return p(xn, void 0, void 0, function () {
                  return f(this, function (e) {
                    return this._groupChannelHandlers.forEach(function (e) {
                      e.onChannelChanged && e.onChannelChanged(Jt);
                    }), [2];
                  });
                });
              }), [3, 77];
            case 67:
              return [4, this.getChannel(Ce.channelUrl, !0)];
            case 68:
              return Xt = Rn.sent(), this._dispatcher.dispatch(new yn({
                channelUrls: [Ce.channelUrl],
                context: {
                  source: y.EVENT_CHANNEL_DELETED
                }
              })), H(function () {
                return p(xn, void 0, void 0, function () {
                  return f(this, function (e) {
                    return this._groupChannelHandlers.forEach(function (e) {
                      e.onChannelDeleted && e.onChannelDeleted(Xt.url, Xt.channelType);
                    }), [2];
                  });
                });
              }), [3, 77];
            case 69:
              return [4, this.getChannelWithoutCache(Ce.channelUrl, !0)];
            case 70:
              return Zt = Rn.sent(), this._dispatcher.dispatch(new Cn({
                channels: [Zt],
                context: {
                  source: y.EVENT_CHANNEL_UPDATED
                }
              })), H(function () {
                return p(xn, void 0, void 0, function () {
                  return f(this, function (e) {
                    return this._groupChannelHandlers.forEach(function (e) {
                      e.onChannelChanged && e.onChannelChanged(Zt);
                    }), [2];
                  });
                });
              }), [3, 77];
            case 71:
              return [4, this.getChannel(Ce.channelUrl, !0)];
            case 72:
              return $t = Rn.sent(), en = e.as(Re), tn = en.created, nn = en.updated, sn = en.deleted, tn && ($t._upsertCachedMetaData(tn, Ce.ts), this._dispatcher.dispatch(new Cn({
                channels: [$t],
                context: {
                  source: y.EVENT_CHANNEL_METADATA_CREATED,
                  metaData: tn
                },
                ts: Ce.ts
              }))), nn && ($t._upsertCachedMetaData(nn, Ce.ts), this._dispatcher.dispatch(new Cn({
                channels: [$t],
                context: {
                  source: y.EVENT_CHANNEL_METADATA_UPDATED,
                  metaData: nn
                },
                ts: Ce.ts
              }))), sn && ($t._removeFromCachedMetaData(sn, Ce.ts), this._dispatcher.dispatch(new Cn({
                channels: [$t],
                context: {
                  source: y.EVENT_CHANNEL_METADATA_DELETED,
                  metaDataKeys: sn
                },
                ts: Ce.ts
              }))), H(function () {
                return p(xn, void 0, void 0, function () {
                  return f(this, function (e) {
                    return this._groupChannelHandlers.forEach(function (e) {
                      tn && e.onMetaDataCreated && e.onMetaDataCreated($t, tn), nn && e.onMetaDataUpdated && e.onMetaDataUpdated($t, nn), sn && e.onMetaDataDeleted && e.onMetaDataDeleted($t, sn);
                    }), [2];
                  });
                });
              }), [3, 77];
            case 73:
              return [4, this.getChannel(Ce.channelUrl, !0)];
            case 74:
              return rn = Rn.sent(), an = e.as(xe), on = an.created, un = an.updated, cn = an.deleted, on && this._dispatcher.dispatch(new Cn({
                channels: [rn],
                context: {
                  source: y.EVENT_CHANNEL_METACOUNTER_CREATED,
                  metaCounters: on
                }
              })), un && this._dispatcher.dispatch(new Cn({
                channels: [rn],
                context: {
                  source: y.EVENT_CHANNEL_METACOUNTER_UPDATED,
                  metaCounters: un
                }
              })), cn && this._dispatcher.dispatch(new Cn({
                channels: [rn],
                context: {
                  source: y.EVENT_CHANNEL_METACOUNTER_DELETED,
                  metaCounterKeys: cn
                }
              })), H(function () {
                return p(xn, void 0, void 0, function () {
                  return f(this, function (e) {
                    return this._groupChannelHandlers.forEach(function (e) {
                      on && e.onMetaCounterCreated && e.onMetaCounterCreated(rn, on), un && e.onMetaCounterUpdated && e.onMetaCounterUpdated(rn, un), cn && e.onMetaCounterDeleted && e.onMetaCounterDeleted(rn, cn);
                    }), [2];
                  });
                });
              }), [3, 77];
            case 75:
              return [4, this.getChannel(Ce.channelUrl, !0)];
            case 76:
              return ln = Rn.sent(), hn = e.as(Ot), dn = hn.pinnedMessageIds, _n = hn.latestPinnedMessage, (pn = hn.ts) > ln._pinnedMessagesUpdatedAt && (ln.pinnedMessageIds = null != dn ? dn : [], ln.lastPinnedMessage = _n, ln._pinnedMessagesUpdatedAt = pn, this._dispatcher.dispatch(new Cn({
                channels: [ln],
                context: {
                  source: y.EVENT_PINNED_MESSAGE_UPDATED
                }
              })), H(function () {
                return p(xn, void 0, void 0, function () {
                  var e, t, n, s, r;
                  return f(this, function (a) {
                    try {
                      for (e = v(this._groupChannelHandlers.values()), t = e.next(); !t.done; t = e.next()) (n = t.value).onChannelChanged && n.onChannelChanged(ln);
                    } catch (e) {
                      s = {
                        error: e
                      };
                    } finally {
                      try {
                        t && !t.done && (r = e.return) && r.call(e);
                      } finally {
                        if (s) throw s.error;
                      }
                    }
                    return [2];
                  });
                });
              }), H(function () {
                return p(xn, void 0, void 0, function () {
                  return f(this, function (e) {
                    return this._groupChannelHandlers.forEach(function (e) {
                      e.onPinnedMessageUpdated && e.onPinnedMessageUpdated(ln);
                    }), [2];
                  });
                });
              })), [3, 77];
            case 77:
              return [3, 79];
            case 78:
              switch ((fn = e.as(Dt).event).category) {
                case xt.USER_BLOCK:
                  vn = Rt.getDataAsUserBlockEvent(this._iid, fn), gn = vn.blocker, En = vn.blockee, this._groupChannelCache.block(gn.userId, En.userId);
                  break;
                case xt.USER_UNBLOCK:
                  mn = Rt.getDataAsUserBlockEvent(this._iid, fn), gn = mn.blocker, En = mn.blockee, this._groupChannelCache.unblock(gn.userId, En.userId);
              }
              return [3, 79];
            case 79:
              return [3, 81];
            case 80:
              if (Mn = Rn.sent(), O(Mn)) throw Mn;
              return [3, 81];
            case 81:
              return [2];
          }
        });
      });
    }, u.prototype._markAsLeave = function (e) {
      var t,
        n = this,
        s = null !== (t = this._leftChannels.get(e.url)) && void 0 !== t ? t : {
          channel: e,
          ref: 0
        };
      s.ref++, this._leftChannels.set(e.url, s), setTimeout(function () {
        s.ref--, 0 === s.ref && n._leftChannels.delete(e.url);
      }, 1e4);
    }, u.prototype.addHandler = function (e, t) {
      this._groupChannelHandlers.set(e, t);
    }, u.prototype.removeHandler = function (e) {
      this._groupChannelHandlers.delete(e);
    }, u.prototype.clearHandler = function () {
      this._groupChannelHandlers.clear();
    }, u.prototype.subscribeChannelEvent = function (e, t) {
      this._groupChannelBroadcast.subscribe(e, t);
    }, u.prototype.unsubscribeChannelEvent = function (e) {
      this._groupChannelBroadcast.unsubscribe(e);
    }, u.prototype._updateJoinedMemberCount = function (e) {
      e.joinedMemberCount = e.members.filter(function (e) {
        return e.state === Qt.JOINED;
      }).length;
    }, u.prototype.getChannel = function (e, t) {
      return void 0 === t && (t = !1), p(this, void 0, void 0, function () {
        var n;
        return f(this, function (s) {
          switch (s.label) {
            case 0:
              l(g("string", e)).throw(h.invalidParameters), s.label = 1;
            case 1:
              return s.trys.push([1, 3,, 4]), [4, this.getChannelFromCache(e)];
            case 2:
              return (n = s.sent()) ? [2, n] : [3, 4];
            case 3:
              return s.sent(), [3, 4];
            case 4:
              return [4, this.getChannelWithoutCache(e, t)];
            case 5:
              return [2, s.sent()];
          }
        });
      });
    }, u.prototype.getChannelWithoutCache = function (e, t) {
      return void 0 === t && (t = !1), p(this, void 0, void 0, function () {
        var n, s, r, a, i;
        return f(this, function (o) {
          switch (o.label) {
            case 0:
              return l(g("string", e)).throw(h.invalidParameters), n = new Un({
                channelUrl: e,
                isInternalCall: t
              }), [4, this._requestQueue.send(n)];
            case 1:
              switch (s = o.sent(), r = s.as(Sn).channel, a = r.unreadMessageCount, i = r.unreadMentionCount, r.myCountPreference) {
                case tr.UNREAD_MESSAGE_COUNT_ONLY:
                  i = 0;
                  break;
                case tr.UNREAD_MENTION_COUNT_ONLY:
                  a = 0;
                  break;
                case tr.OFF:
                  a = 0, i = 0;
              }
              return r._updateUnreadCount(a, i), [4, this.upsertChannelsToCache([r])];
            case 2:
              return [2, o.sent()[0]];
          }
        });
      });
    }, u.prototype.refreshChannel = function (e, t, n, s) {
      return void 0 === t && (t = !0), void 0 === n && (n = y.REFRESH_CHANNEL), void 0 === s && (s = !1), p(this, void 0, void 0, function () {
        var r, a, i, o, u;
        return f(this, function (c) {
          switch (c.label) {
            case 0:
              return c.trys.push([0, 5,, 6]), r = new Un({
                channelUrl: e,
                isInternalCall: t,
                showLatestMessage: s
              }), [4, this._requestQueue.send(r)];
            case 1:
              return a = c.sent(), (i = a.as(Sn).channel).myMemberState !== Qt.NONE ? [3, 2] : (this._dispatcher.dispatch(new yn({
                channelUrls: [i.url],
                context: {
                  source: n
                }
              })), [3, 4]);
            case 2:
              return [4, this.upsertChannelsToCache([i])];
            case 3:
              o = c.sent(), this._dispatcher.dispatch(new Cn({
                channels: o,
                context: {
                  source: n
                }
              })), c.label = 4;
            case 4:
              return [3, 6];
            case 5:
              return (u = c.sent()).code !== K.NON_AUTHORIZED && u.code !== K.NOT_FOUND_IN_DATABASE || this._dispatcher.dispatch(new yn({
                channelUrls: [e],
                context: {
                  source: n
                }
              })), [3, 6];
            case 6:
              return [2];
          }
        });
      });
    }, u.prototype.getMyGroupChannels = function (e, t, n, r, a) {
      return void 0 === r && (r = y.REQUEST_CHANNEL), void 0 === a && (a = !1), p(this, void 0, void 0, function () {
        var i, o, u, c, l, h, d, _, p;
        return f(this, function (f) {
          switch (f.label) {
            case 0:
              return t.createdAfter && (t.createdAfter = Math.floor(t.createdAfter / 1e3)), t.createdBefore && (t.createdBefore = Math.floor(t.createdBefore / 1e3)), i = !1, a && (o = P.of(this._iid), u = o.appInfo, (c = o.cacheContext) && !c.localCacheEnabled && (null == u ? void 0 : u.enabledChannelMemberShipHistory) && (null == u ? void 0 : u.applicationAttributes.includes("left_user_view_support")) && (i = !0)), l = new An(s(s({}, t), {
                userId: this._sdkState.userId,
                token: e,
                limit: n,
                includeLeftChannel: a && i
              })), [4, this._requestQueue.send(l)];
            case 1:
              return h = f.sent(), d = h.as(In), _ = d.channels, p = d.token, this._dispatcher.dispatch(new Cn({
                channels: _,
                context: {
                  source: r
                }
              })), [2, {
                channels: _,
                token: p
              }];
          }
        });
      });
    }, u.prototype.getMyGroupChannelChangeLogs = function (e, t, r) {
      return void 0 === r && (r = y.REQUEST_CHANNEL_CHANGELOGS), p(this, void 0, void 0, function () {
        var a, i, o, u, c, d, _, p;
        return f(this, function (f) {
          switch (f.label) {
            case 0:
              return a = s(s({}, ln), t), l((g("string", e) || g("number", e)) && hn(a)).throw(h.invalidParameters), i = new bn(n({
                userId: this._sdkState.userId,
                ts: "number" == typeof e ? e : null,
                token: "string" == typeof e ? e : null,
                filter: a
              })), [4, this._requestQueue.send(i)];
            case 1:
              return o = f.sent(), u = o.as(Nn), c = u.updatedChannels, d = u.deletedChannelUrls, _ = u.hasMore, p = u.ts, c.length > 0 && this._dispatcher.dispatch(new Cn({
                channels: c,
                context: {
                  source: r
                },
                ts: p
              })), d.length > 0 && this._dispatcher.dispatch(new yn({
                channelUrls: d,
                context: {
                  source: r
                }
              })), [2, {
                updatedChannels: c,
                deletedChannelUrls: d,
                hasMore: _,
                token: u.token
              }];
          }
        });
      });
    }, u.prototype.getGroupChannelCount = function (e) {
      return p(this, void 0, void 0, function () {
        var t, n, r;
        return f(this, function (a) {
          switch (a.label) {
            case 0:
              return t = s(s({}, dn), e), l(_n(t)).throw(h.invalidParameters), n = new wn({
                userId: this._sdkState.userId,
                filter: t
              }), [4, this._requestQueue.send(n)];
            case 1:
              return r = a.sent(), [2, r.as(Ln).groupChannelCount];
          }
        });
      });
    }, u.prototype.getUnreadItemCount = function (e) {
      return p(this, void 0, void 0, function () {
        var n, r, a, i, o, u, c, d, _, p, v, g, E, C, y, M;
        return f(this, function (f) {
          switch (f.label) {
            case 0:
              return n = s(s({}, pn), e), l(function (e) {
                return m(rn, e.keys) && m("string", e.customTypeFilters, !0) && m("string", e.customTypesFilter, !0);
              }(n)).throw(h.invalidParameters), r = P.of(this._iid), a = r.sdkState, i = r.requestQueue, o = new Dn({
                userId: a.userId,
                filter: n
              }), [4, i.send(o)];
            case 1:
              return u = f.sent(), c = u.as(xn), d = c.groupChannelUnreadMentionCount, _ = c.groupChannelUnreadMessageCount, p = c.groupChannelInvitationCount, v = c.superGroupChannelUnreadMentionCount, g = c.superGroupChannelUnreadMessageCount, E = c.superGroupChannelInvitationCount, C = c.nonSuperGroupChannelUnreadMentionCount, y = c.nonSuperGroupChannelUnreadMessageCount, M = c.nonSuperGroupChannelInvitationCount, [2, t({
                groupChannelUnreadMentionCount: d,
                groupChannelUnreadMessageCount: _,
                groupChannelInvitationCount: p,
                superGroupChannelUnreadMentionCount: v,
                superGroupChannelUnreadMessageCount: g,
                superGroupChannelInvitationCount: E,
                nonSuperGroupChannelUnreadMentionCount: C,
                nonSuperGroupChannelUnreadMessageCount: y,
                nonSuperGroupChannelInvitationCount: M
              })];
          }
        });
      });
    }, u.prototype.getTotalUnreadChannelCount = function () {
      return p(this, void 0, void 0, function () {
        var e, t, n, s, r;
        return f(this, function (a) {
          switch (a.label) {
            case 0:
              return e = P.of(this._iid), t = e.sdkState, n = e.requestQueue, s = new Rn({
                userId: t.userId
              }), [4, n.send(s)];
            case 1:
              return r = a.sent(), [2, r.as(Fn).unreadCount];
          }
        });
      });
    }, u.prototype.getTotalUnreadMessageCount = function (e) {
      return p(this, void 0, void 0, function () {
        var t, n, r, a, i, o;
        return f(this, function (u) {
          switch (u.label) {
            case 0:
              return t = s(s({}, fn), e), l(vn(t)).throw(h.invalidParameters), n = P.of(this._iid), r = n.sdkState, a = n.requestQueue, i = new Pn({
                userId: r.userId,
                filter: t
              }), [4, a.send(i)];
            case 1:
              return o = u.sent(), [2, o.as(kn).unreadCount];
          }
        });
      });
    }, u.prototype.getTotalScheduledMessageCount = function (e) {
      return void 0 === e && (e = {}), p(this, void 0, void 0, function () {
        var t, n, a, i;
        return f(this, function (o) {
          switch (o.label) {
            case 0:
              return t = s(s({}, mn), e), l(function (e) {
                return g("string", e.channelUrl, !0) && m(Me, e.scheduledStatus, !0) && r(C, e.messageTypeFilter);
              }(t)).throw(h.invalidParameters), n = P.of(this._iid).requestQueue, a = new On(t), [4, n.send(a)];
            case 1:
              return i = o.sent(), [2, i.as(Hn).count];
          }
        });
      });
    }, u.prototype.getSubscribedTotalUnreadMessageCount = function () {
      var e = P.of(this._iid).subscribedUnreadMessageCount;
      return e.all >= 0 ? e.all : 0;
    }, u.prototype.getSubscribedCustomTypeTotalUnreadMessageCount = function () {
      var e = 0,
        t = P.of(this._iid).subscribedUnreadMessageCount;
      return Object.keys(t.customTypes).forEach(function (n) {
        e += t.customTypes[n];
      }), e;
    }, u.prototype.getSubscribedCustomTypeUnreadMessageCount = function (e) {
      var t;
      return null !== (t = P.of(this._iid).subscribedUnreadMessageCount.customTypes[e]) && void 0 !== t ? t : 0;
    }, u.prototype.createChannel = function (e) {
      return p(this, void 0, void 0, function () {
        var t, n, r, a;
        return f(this, function (i) {
          switch (i.label) {
            case 0:
              return t = s(s({}, un), e), l(cn(t)).throw(h.invalidParameters), t.isPublic || (t.accessCode = void 0), n = new Vn(s({
                userId: this._sdkState.userId
              }, t)), [4, this._requestQueue.send(n)];
            case 1:
              return r = i.sent(), a = r.as(Bn).channel, [4, this.upsertChannelsToCache([a])];
            case 2:
              return i.sent(), [2, a];
          }
        });
      });
    }, u.prototype.markAsReadAll = function () {
      return p(this, void 0, void 0, function () {
        var e, t, n, s, r, a, i, o;
        return f(this, function (u) {
          switch (u.label) {
            case 0:
              return e = Date.now(), l(e - this._markAsReadAllLastSentAt >= 1e3).throw(h.markAsReadAllRateLimitExceeded), this._markAsReadAllLastSentAt = e, t = new zn({
                userId: this._sdkState.userId
              }), [4, this._requestQueue.send(t)];
            case 1:
              u.sent(), n = this._groupChannelCache.channels;
              try {
                for (s = v(n), r = s.next(); !r.done; r = s.next()) (a = r.value)._updateUnreadMemberState(this._sdkState.userId, e), a._updateUnreadCount(0, 0);
              } catch (e) {
                i = {
                  error: e
                };
              } finally {
                try {
                  r && !r.done && (o = s.return) && o.call(s);
                } finally {
                  if (i) throw i.error;
                }
              }
              return n.length > 0 ? [4, this.upsertChannelsToCache(n)] : [3, 3];
            case 2:
              u.sent(), u.label = 3;
            case 3:
              return [2];
          }
        });
      });
    }, u.prototype.markAsReadWithChannelUrls = function (e) {
      return p(this, void 0, void 0, function () {
        var t, n, s, r, a, i, o, u, c;
        return f(this, function (d) {
          switch (d.label) {
            case 0:
              return t = Date.now(), l(m("string", e) && t - this._markAsReadAllLastSentAt >= 1e3).throw(h.markAsReadAllRateLimitExceeded), this._markAsReadAllLastSentAt = t, n = new zn({
                userId: this._sdkState.userId,
                channelUrls: e
              }), [4, this._requestQueue.send(n)];
            case 1:
              d.sent(), s = this._groupChannelCache.channels, r = [];
              try {
                for (a = v(s), i = a.next(); !i.done; i = a.next()) o = i.value, e.includes(o.url) && (o._updateUnreadMemberState(this._sdkState.userId, t), o._updateUnreadCount(0, 0), r.push(o));
              } catch (e) {
                u = {
                  error: e
                };
              } finally {
                try {
                  i && !i.done && (c = a.return) && c.call(a);
                } finally {
                  if (u) throw u.error;
                }
              }
              return r.length > 0 ? [4, this.upsertChannelsToCache(r)] : [3, 3];
            case 2:
              d.sent(), d.label = 3;
            case 3:
              return [2];
          }
        });
      });
    }, u.prototype.markAsDelivered = function (e) {
      return p(this, void 0, void 0, function () {
        return f(this, function (t) {
          switch (t.label) {
            case 0:
              return [4, this.getChannel(e)];
            case 1:
              return [4, t.sent().markAsDelivered()];
            case 2:
              return t.sent(), [2];
          }
        });
      });
    }, u.prototype.getMessageFromCache = function (e) {
      var t;
      return p(this, void 0, void 0, function () {
        return f(this, function (n) {
          switch (n.label) {
            case 0:
              return [4, this._messageCache.get(e)];
            case 1:
              return [2, null !== (t = n.sent()) && void 0 !== t ? t : null];
          }
        });
      });
    }, u.prototype.getExactlyMatchingMessagesForTokenFromCache = function (e, t, n) {
      return p(this, void 0, void 0, function () {
        return f(this, function (s) {
          switch (s.label) {
            case 0:
              return [4, this._messageCache.fetch({
                channelUrl: e,
                token: t,
                filter: n,
                exactMatch: !0
              })];
            case 1:
              return [2, s.sent()];
          }
        });
      });
    }, u.prototype.getMessagesFromCache = function (e, t, n, s, r, a) {
      return void 0 === r && (r = Ke), void 0 === a && (a = !0), p(this, void 0, void 0, function () {
        return f(this, function (i) {
          switch (i.label) {
            case 0:
              return [4, this._messageCache.fetch({
                channelUrl: e,
                token: t,
                limit: r,
                filter: s,
                backward: "next" === n,
                inclusive: a
              })];
            case 1:
              return [2, i.sent()];
          }
        });
      });
    }, u.prototype.getPollMessagesFromCache = function (e, t, n, s) {
      return p(this, void 0, void 0, function () {
        return f(this, function (r) {
          switch (r.label) {
            case 0:
              return [4, this._messageCache.fetch({
                channelUrl: e,
                token: t,
                limit: s,
                filter: n,
                backward: !1,
                isPollOnly: !0
              })];
            case 1:
              return [2, r.sent()];
          }
        });
      });
    }, u.prototype.getCachedMessageCountBetween = function (e, t, n, s) {
      return p(this, void 0, void 0, function () {
        return f(this, function (r) {
          switch (r.label) {
            case 0:
              return [4, this._messageCache.countBetween(e, t, new _s({
                top: n,
                bottom: s
              }))];
            case 1:
              return [2, r.sent()];
          }
        });
      });
    }, u.prototype.getUnsentMessagesFromCache = function (e, t) {
      return p(this, void 0, void 0, function () {
        return f(this, function (n) {
          switch (n.label) {
            case 0:
              return [4, this._unsentMessageCache.fetch({
                channelUrl: e,
                filter: t
              })];
            case 1:
              return [2, n.sent()];
          }
        });
      });
    }, u.prototype.removeFailedMessageFromCache = function (e) {
      return p(this, void 0, void 0, function () {
        return f(this, function (t) {
          switch (t.label) {
            case 0:
              return [4, this._unsentMessageCache.remove([e])];
            case 1:
              return t.sent(), [2];
          }
        });
      });
    }, u;
  }(kt),
  vs = function (e, t) {
    return t instanceof ze ? e.findIndex(function (e) {
      return e instanceof ze && t.isIdentical(e);
    }) : e.findIndex(function (e) {
      return e.isIdentical(t);
    });
  },
  ms = function (e, t) {
    return e.findIndex(function (e) {
      return e instanceof Ve ? e.messageId === t : e instanceof Je ? e.notificationId === t : void 0;
    });
  },
  gs = function (e, t) {
    if (e.length > 0) {
      for (var n = 0, s = e.length - 1, r = Math.floor((n + s) / 2); n < s;) {
        var a = e[r].createdAt - t.createdAt;
        if (a > 0) s = r, r = Math.floor((n + s) / 2);else {
          if (!(a < 0)) return r;
          n = r + 1, r = Math.floor((n + s) / 2);
        }
      }
      return e[r].createdAt > t.createdAt ? r : r + 1;
    }
    return e.length;
  };
!function (e) {
  e[e.IDLE = 0] = "IDLE", e[e.RUNNING = 1] = "RUNNING", e[e.END = 2] = "END";
}(hs || (hs = {}));
var Es,
  Cs = function (t) {
    function n(e, n, s, r) {
      var a = t.call(this) || this;
      return a._state = hs.IDLE, a._retryCount = 0, a._retryLimit = 3, a.priority = 0, a._worker = n, a;
    }
    return e(n, t), Object.defineProperty(n.prototype, "isIdle", {
      get: function () {
        return this._state === hs.IDLE;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(n.prototype, "isRunning", {
      get: function () {
        return this._state === hs.RUNNING;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(n.prototype, "isDone", {
      get: function () {
        return this._state === hs.END;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(n.prototype, "retryCount", {
      get: function () {
        return this._retryCount;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(n.prototype, "retryLimit", {
      get: function () {
        return this._retryLimit;
      },
      enumerable: !1,
      configurable: !0
    }), n.prototype._run = function (e) {
      return p(this, void 0, void 0, function () {
        var t, n;
        return f(this, function (s) {
          switch (s.label) {
            case 0:
              if (!this.isRunning) return [3, 4];
              s.label = 1;
            case 1:
              return s.trys.push([1, 3,, 4]), [4, this._worker(e)];
            case 2:
              return t = s.sent(), this._retryCount = 0, this.dispatch("progress", t), t.hasNext ? this._run(t.nextToken) : this.end(), [3, 4];
            case 3:
              return n = s.sent(), this.dispatch("error", n), this._retryCount < this._retryLimit ? (this._retryCount++, this._run(e)) : this.stop(), [3, 4];
            case 4:
              return [2];
          }
        });
      });
    }, n.prototype.start = function (e) {
      this.isIdle && (this._state = hs.RUNNING, this._run(e));
    }, n.prototype.stop = function () {
      this._state = hs.IDLE, this.dispatch("stop");
    }, n.prototype.end = function () {
      this._state = hs.END, this.dispatch("end");
    }, n;
  }(X),
  ys = {},
  Ms = function () {
    function e(e) {
      var t = e._iid,
        n = e.channel,
        s = e.limit,
        r = void 0 === s ? 100 : s,
        a = this;
      this.ref = 0, this._iid = t, this._channel = n, this._limit = r, this._prevSyncLoopCount = 0;
      var i,
        o,
        u = P.of(this._iid),
        c = u.sdkState,
        l = u.cacheContext,
        v = u.dispatcher,
        m = u.logger,
        g = u.statManager,
        E = u.messageBackgroundSyncThrottleController;
      this._metadataKey = (i = c.userId, o = n.url, "sendbird:".concat(i, "@groupchannel/").concat(o, "/message/sync.meta"));
      var C = function (e, t) {
        return "sendbird:".concat(e, "@groupchannel/").concat(t, "/message/sync");
      }(c.userId, n.url);
      this._prevSync = new Cs(C, function (e) {
        return p(a, void 0, void 0, function () {
          var t = this;
          return f(this, function (s) {
            switch (s.label) {
              case 0:
                return [4, E.run("message-background-sync-".concat(n.url, "-prev"), function () {
                  return p(t, void 0, void 0, function () {
                    var t, s, r, a, i, o, u, c, p, v, E, C;
                    return f(this, function (f) {
                      switch (f.label) {
                        case 0:
                          return t = {
                            hasNext: !0,
                            nextToken: 0
                          }, this._prevSyncLoopCount++, [4, this.loadMetadata()];
                        case 1:
                          if (f.sent(), m.debug("message background prev sync from", null === (o = this._metadata) || void 0 === o ? void 0 : o.range.top), null === (u = this._metadata) || void 0 === u ? void 0 : u.previousComplete) return [3, 10];
                          f.label = 2;
                        case 2:
                          return f.trys.push([2, 5, 8, 9]), [4, Xe.of(this._iid).getMessagesByTimestamp(this._channel.url, this._channel.channelType, (null === (p = null === (c = this._metadata) || void 0 === c ? void 0 : c.range) || void 0 === p ? void 0 : p.top) ? this._metadata.range.top : e, {
                            prevResultSize: this._limit,
                            nextResultSize: 0,
                            replyType: ne.ALL,
                            includeReactions: !0,
                            includeMetaArray: !0,
                            includeParentMessageInfo: !0,
                            includeThreadInfo: !0,
                            isInclusive: !0
                          }, y.SYNC_MESSAGE_BACKGROUND)];
                        case 3:
                          return (s = f.sent()).length > 0 && (r = s.map(function (e) {
                            return e.createdAt;
                          }), (null === (v = this._metadata) || void 0 === v ? void 0 : (i = v.range).intersect.apply(i, d([], _(r), !1))) ? this.extendRange(s) : this._metadata = {
                            range: new _s({
                              top: Math.min.apply(Math, d([], _(r), !1)),
                              bottom: Math.max.apply(Math, d([], _(r), !1))
                            }),
                            previousComplete: !1
                          }), t.hasNext = s.length >= this._limit && this._prevSyncLoopCount < 1, this._metadata && (t.nextToken = this._metadata.range.top, this._metadata.previousComplete = s.length < this._limit), m.debug("message background prev sync progress", t), [4, this.saveMetadata()];
                        case 4:
                          return f.sent(), [3, 9];
                        case 5:
                          return a = f.sent(), m.debug("message background prev sync error", a), a instanceof h && a.isInvalidTokenError ? [4, this.clearMetadata()] : [3, 7];
                        case 6:
                          f.sent(), f.label = 7;
                        case 7:
                          throw a;
                        case 8:
                          return g.put(new ee({
                            type: te.FEATURE_LOCALCACHE_EVENT,
                            ts: Date.now(),
                            data: {
                              channel_url: n.url,
                              measured_on: "back_sync",
                              event: "cache_fetch",
                              max_db_size: null !== (C = null === (E = l.localCacheConfig) || void 0 === E ? void 0 : E.maxSize) && void 0 !== C ? C : 0,
                              use_local_cache: l.localCacheEnabled,
                              starting_point: 0,
                              message_init_policy: ""
                            }
                          })), [7];
                        case 9:
                          return [3, 11];
                        case 10:
                          t.hasNext = !1, f.label = 11;
                        case 11:
                          return [2, t];
                      }
                    });
                  });
                })];
              case 1:
                return [2, s.sent()];
            }
          });
        });
      }), this._nextSync = new Cs(C, function (e) {
        return p(a, void 0, void 0, function () {
          var t = this;
          return f(this, function (s) {
            switch (s.label) {
              case 0:
                return [4, E.run("message-background-sync-".concat(n.url, "-next"), function () {
                  return p(t, void 0, void 0, function () {
                    var t, s, r, a, i, o, u, c, h, p, v;
                    return f(this, function (f) {
                      switch (f.label) {
                        case 0:
                          return t = {
                            hasNext: !0,
                            nextToken: 0
                          }, [4, this.loadMetadata()];
                        case 1:
                          f.sent(), m.debug("message background next sync from", null === (o = this._metadata) || void 0 === o ? void 0 : o.range.bottom), f.label = 2;
                        case 2:
                          return f.trys.push([2, 5, 6, 7]), [4, Xe.of(this._iid).getMessagesByTimestamp(this._channel.url, this._channel.channelType, (null === (c = null === (u = this._metadata) || void 0 === u ? void 0 : u.range) || void 0 === c ? void 0 : c.bottom) ? this._metadata.range.bottom : e, {
                            prevResultSize: 0,
                            nextResultSize: this._limit,
                            replyType: ne.ALL,
                            includeReactions: !0,
                            includeMetaArray: !0,
                            includeParentMessageInfo: !0,
                            includeThreadInfo: !0,
                            isInclusive: !0
                          }, y.SYNC_MESSAGE_BACKGROUND)];
                        case 3:
                          return (s = f.sent()).length > 0 && (r = s.map(function (e) {
                            return e.createdAt;
                          }), (null === (h = this._metadata) || void 0 === h ? void 0 : (i = h.range).intersect.apply(i, d([], _(r), !1))) ? this.extendRange(s) : this._metadata = {
                            range: new _s({
                              top: Math.min.apply(Math, d([], _(r), !1)),
                              bottom: Math.max.apply(Math, d([], _(r), !1))
                            }),
                            previousComplete: !1
                          }), t.hasNext = s.length >= this._limit, this._metadata && (t.nextToken = this._metadata.range.bottom), m.debug("message background next sync progress", t), [4, this.saveMetadata()];
                        case 4:
                          return f.sent(), [3, 7];
                        case 5:
                          throw a = f.sent(), m.debug("message background next sync error", a), a;
                        case 6:
                          return g.put(new ee({
                            type: te.FEATURE_LOCALCACHE_EVENT,
                            ts: Date.now(),
                            data: {
                              channel_url: n.url,
                              measured_on: "back_sync",
                              event: "cache_fetch",
                              max_db_size: null !== (v = null === (p = l.localCacheConfig) || void 0 === p ? void 0 : p.maxSize) && void 0 !== v ? v : 0,
                              use_local_cache: l.localCacheEnabled,
                              starting_point: 0,
                              message_init_policy: ""
                            }
                          })), [7];
                        case 7:
                          return [2, t];
                      }
                    });
                  });
                })];
              case 1:
                return [2, s.sent()];
            }
          });
        });
      }), this._connectionEventContext = v.on(function (e) {
        if (e instanceof Z) if (e.stateType === $.CONNECTED) a.resume();else a.pause();
      });
    }
    return e.of = function (t, n) {
      return ys[t] || (ys[t] = {}), ys[t][n.url] || (ys[t][n.url] = new e({
        _iid: t,
        channel: n
      })), ys[t][n.url].ref++, ys[t][n.url];
    }, e.clear = function (e, t) {
      ys[e] && ys[e][t] && (ys[e][t].close(), delete ys[e]);
    }, Object.defineProperty(e.prototype, "range", {
      get: function () {
        var e, t;
        return null !== (t = null === (e = this._metadata) || void 0 === e ? void 0 : e.range) && void 0 !== t ? t : new _s({});
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(e.prototype, "previousComplete", {
      get: function () {
        var e;
        return !!(null === (e = this._metadata) || void 0 === e ? void 0 : e.previousComplete);
      },
      enumerable: !1,
      configurable: !0
    }), e.prototype.isWrappingMessages = function (e) {
      var t;
      return null === (t = this.range) || void 0 === t ? void 0 : t.includes.apply(t, d([], _(e.map(function (e) {
        return e.createdAt;
      })), !1));
    }, e.prototype.extendRange = function (e) {
      var t;
      this._metadata && (t = this._metadata.range).extends.apply(t, d([], _(e.map(function (e) {
        return e.createdAt;
      })), !1));
    }, e.prototype.loadMetadata = function () {
      return p(this, void 0, void 0, function () {
        var e;
        return f(this, function (t) {
          switch (t.label) {
            case 0:
              return this._metadata ? [3, 2] : [4, P.of(this._iid).cacheContext.preference.get(this._metadataKey)];
            case 1:
              e = t.sent(), this._metadata = e ? {
                range: new _s(e.range),
                previousComplete: e.previousComplete
              } : {
                range: new _s({}),
                previousComplete: !1
              }, t.label = 2;
            case 2:
              return [2, this._metadata];
          }
        });
      });
    }, e.prototype.saveMetadata = function () {
      return p(this, void 0, void 0, function () {
        return f(this, function (e) {
          switch (e.label) {
            case 0:
              return this._metadata ? [4, P.of(this._iid).cacheContext.preference.set(this._metadataKey, this._metadata)] : [3, 2];
            case 1:
              return e.sent(), [2, !0];
            case 2:
              return [2, !1];
          }
        });
      });
    }, e.prototype.clearMetadata = function () {
      return p(this, void 0, void 0, function () {
        return f(this, function (e) {
          switch (e.label) {
            case 0:
              return [4, P.of(this._iid).cacheContext.preference.remove(this._metadataKey)];
            case 1:
              return e.sent(), this._metadata = void 0, [2];
          }
        });
      });
    }, e.prototype.resume = function (e) {
      var t, n, s, r;
      void 0 === e && (e = Date.now());
      var a = P.of(this._iid),
        i = a.logger,
        o = a.sessionManager;
      a.cacheContext.localCacheEnabled && o.session.hasSession && (i.debug("message background sync resume()"), this._prevSyncLoopCount = 0, this._metadata && this._metadata.previousComplete || this._prevSync.start(null !== (n = null === (t = this._metadata) || void 0 === t ? void 0 : t.range.top) && void 0 !== n ? n : e), this._nextSync.start(null !== (r = null === (s = this._metadata) || void 0 === s ? void 0 : s.range.bottom) && void 0 !== r ? r : e));
    }, e.prototype.pause = function () {
      P.of(this._iid).logger.debug("message background sync stop()"), this._prevSync.stop(), this._nextSync.stop();
    }, e.prototype.close = function () {
      this.ref--, this.ref <= 0 && (this.ref = 0, this.pause(), this._connectionEventContext.close(), delete ys[this._iid][this._channel.url]);
    }, e;
  }(),
  bs = {},
  Ns = function () {
    function e(e) {
      var t = e._iid,
        n = e.channel,
        r = e.includeParams,
        a = this;
      this.ref = 0, this._iid = t, this._channel = n, this._includeParams = r;
      var i,
        o,
        u = P.of(this._iid),
        c = u.logger,
        l = u.sdkState,
        d = u.dispatcher;
      this._metadataKey = (i = l.userId, o = n.url, "sendbird:".concat(i, "@groupchannel/").concat(o, "/message/changelogs.meta"));
      var _ = function (e, t) {
        return "sendbird:".concat(e, "@groupchannel/").concat(t, "/message/changelogs");
      }(l.userId, this._channel.url);
      this._sync = new Cs(_, function () {
        return p(a, void 0, void 0, function () {
          var e, t, n, r, a, i, o, u, l;
          return f(this, function (d) {
            switch (d.label) {
              case 0:
                return e = {
                  hasNext: !0,
                  nextToken: 0
                }, [4, this.loadMetadata()];
              case 1:
                d.sent(), c.debug("message changelog sync from", null === (l = this._metadata) || void 0 === l ? void 0 : l.token), d.label = 2;
              case 2:
                return d.trys.push([2, 5,, 8]), [4, Xe.of(this._iid).getMessageChangelogs(this._channel.url, this._channel.channelType, this._metadata.token, s({
                  replyType: ne.ALL
                }, this._includeParams), y.SYNC_MESSAGE_CHANGELOGS)];
              case 3:
                return t = d.sent(), n = t.updatedMessages, r = t.deletedMessageIds, a = t.hasMore, i = t.token, o = t.forceUseNextToken, e.hasNext = a, e.nextToken = i, n.length > 0 || r.length > 0 ? this._metadata && (this._metadata.token = i) : o && this._metadata && (this._metadata.token = i), c.debug("message changelog sync progress", e), [4, this.saveMetadata()];
              case 4:
                return d.sent(), [3, 8];
              case 5:
                return u = d.sent(), c.debug("message changelog sync error", u), u instanceof h && u.isInvalidTokenError ? [4, this.clearMetadata()] : [3, 7];
              case 6:
                d.sent(), d.label = 7;
              case 7:
                throw u;
              case 8:
                return [2, e];
            }
          });
        });
      }), this._connectionEventContext = d.on(function (e) {
        if (e instanceof Z) if (e.stateType === $.CONNECTED) a.resume();else a.pause();
      });
    }
    return e.of = function (t, n, s) {
      return bs[t] || (bs[t] = {}), bs[t][n.url] || (bs[t][n.url] = new e({
        _iid: t,
        channel: n,
        includeParams: s
      })), bs[t][n.url].ref++, bs[t][n.url];
    }, e.clear = function (e, t) {
      bs[e] && bs[e][t] && (bs[e][t].close(), delete bs[e]);
    }, e.prototype.loadMetadata = function () {
      return p(this, void 0, void 0, function () {
        var e, t, n, s;
        return f(this, function (r) {
          switch (r.label) {
            case 0:
              return this._metadata ? [3, 2] : (e = P.of(this._iid), t = e.cacheContext, n = e.firstConnectedAt, [4, t.preference.get(this._metadataKey)]);
            case 1:
              s = r.sent(), this._metadata = {
                token: s ? s.token : n
              }, r.label = 2;
            case 2:
              return [2, this._metadata];
          }
        });
      });
    }, e.prototype.saveMetadata = function () {
      return p(this, void 0, void 0, function () {
        return f(this, function (e) {
          switch (e.label) {
            case 0:
              return this._metadata ? [4, P.of(this._iid).cacheContext.preference.set(this._metadataKey, this._metadata)] : [3, 2];
            case 1:
              return e.sent(), [2, !0];
            case 2:
              return [2, !1];
          }
        });
      });
    }, e.prototype.clearMetadata = function () {
      return p(this, void 0, void 0, function () {
        return f(this, function (e) {
          switch (e.label) {
            case 0:
              return [4, P.of(this._iid).cacheContext.preference.remove(this._metadataKey)];
            case 1:
              return e.sent(), this._metadata = void 0, [2];
          }
        });
      });
    }, e.prototype.resume = function () {
      var e = P.of(this._iid),
        t = e.logger;
      e.sessionManager.session.hasSession && (t.debug("message changelog sync resume()"), this._sync.start(0));
    }, e.prototype.pause = function () {
      P.of(this._iid).logger.debug("message changelog sync pause()"), this._sync.stop();
    }, e.prototype.close = function () {
      this.ref--, this.ref <= 0 && (this.ref = 0, this.pause(), this._connectionEventContext.close(), delete bs[this._iid][this._channel.url]);
    }, e;
  }(),
  Us = {},
  Ss = function () {
    function e(e) {
      var t = e._iid,
        n = e.channel,
        s = e.hasPollMessage,
        r = this;
      this.ref = 0, this._iid = t, this._channel = n;
      var a,
        i,
        o = P.of(this._iid),
        u = o.logger,
        c = o.sdkState,
        l = o.dispatcher;
      this._metadataKey = (a = c.userId, i = n.url, "sendbird:".concat(a, "@groupchannel/").concat(i, "/poll/changelogs.meta"));
      var d = function (e, t) {
        return "sendbird:".concat(e, "@groupchannel/").concat(t, "/poll/changelogs");
      }(c.userId, this._channel.url);
      this._sync = new Cs(d, function () {
        return p(r, void 0, void 0, function () {
          var e, t, n, r, a, i, o, c;
          return f(this, function (l) {
            switch (l.label) {
              case 0:
                return e = {
                  hasNext: !0,
                  nextToken: 0
                }, [4, this.loadMetadata()];
              case 1:
                return l.sent(), u.debug("poll changelog sync from", null === (c = this._metadata) || void 0 === c ? void 0 : c.token), (t = !this._metadata || !this._metadata.token) ? [4, s()] : [3, 3];
              case 2:
                t = !l.sent(), l.label = 3;
              case 3:
                if (t) return [2, {
                  hasNext: !1,
                  nextToken: 0
                }];
                this._metadata || (n = P.of(this._iid).firstConnectedAt, this._metadata = {
                  token: n
                }), l.label = 4;
              case 4:
                return l.trys.push([4, 7,, 10]), [4, se.of(this._iid).getPollChangeLogs(this._channel.url, this._channel.channelType, this._metadata.token)];
              case 5:
                return r = l.sent(), a = r.hasMore, i = r.token, e.hasNext = a, e.nextToken = i, this._metadata.token = i, u.debug("poll changelog sync progress", e), [4, this.saveMetadata()];
              case 6:
                return l.sent(), [3, 10];
              case 7:
                return o = l.sent(), u.debug("poll changelog sync error", o), o instanceof h && o.isInvalidTokenError ? [4, this.clearMetadata()] : [3, 9];
              case 8:
                l.sent(), l.label = 9;
              case 9:
                throw o;
              case 10:
                return [2, e];
            }
          });
        });
      }), this._connectionEventContext = l.on(function (e) {
        if (e instanceof Z) if (e.stateType === $.CONNECTED) r.resume();else r.pause();
      });
    }
    return e.of = function (t, n, s) {
      return Us[t] || (Us[t] = {}), Us[t][n.url] || (Us[t][n.url] = new e({
        _iid: t,
        channel: n,
        hasPollMessage: s
      })), Us[t][n.url].ref++, Us[t][n.url];
    }, e.prototype.loadMetadata = function () {
      return p(this, void 0, void 0, function () {
        var e;
        return f(this, function (t) {
          switch (t.label) {
            case 0:
              return this._metadata ? [3, 2] : [4, P.of(this._iid).cacheContext.preference.get(this._metadataKey)];
            case 1:
              e = t.sent(), this._metadata = e ? {
                token: e.token
              } : void 0, t.label = 2;
            case 2:
              return [2];
          }
        });
      });
    }, e.prototype.saveMetadata = function () {
      return p(this, void 0, void 0, function () {
        return f(this, function (e) {
          switch (e.label) {
            case 0:
              return this._metadata ? [4, P.of(this._iid).cacheContext.preference.set(this._metadataKey, this._metadata)] : [3, 2];
            case 1:
              e.sent(), e.label = 2;
            case 2:
              return [2];
          }
        });
      });
    }, e.prototype.clearMetadata = function () {
      return p(this, void 0, void 0, function () {
        return f(this, function (e) {
          switch (e.label) {
            case 0:
              return [4, P.of(this._iid).cacheContext.preference.remove(this._metadataKey)];
            case 1:
              return e.sent(), this._metadata = void 0, [2];
          }
        });
      });
    }, e.prototype.resume = function () {
      P.of(this._iid).logger.debug("poll changelog sync resume()"), this._sync.start(0);
    }, e.prototype.pause = function () {
      P.of(this._iid).logger.debug("poll changelog sync pause()"), this._sync.stop();
    }, e.prototype.close = function () {
      this.ref--, this.ref <= 0 && (this.ref = 0, this.pause(), this._connectionEventContext.close(), delete Us[this._iid][this._channel.url]);
    }, e;
  }(),
  Ts = function (n) {
    function s(e) {
      var s,
        r,
        a,
        i,
        o,
        u,
        c,
        l,
        h,
        d,
        _ = this;
      return (_ = n.call(this) || this).method = N.GET, _.path = "".concat(re(e.channelType), "/").concat(e.channelUrl, "/messages_gap"), _.params = t({
        prev_start_ts: e.prevStart,
        prev_end_ts: e.prevEnd,
        prev_cache_count: e.prevCount,
        next_start_ts: e.nextStart,
        next_end_ts: e.nextEnd,
        next_cache_count: e.nextCount,
        huge_gap_threshold: null !== (s = e.threshold) && void 0 !== s ? s : null,
        reverse: !0,
        custom_types: null !== (r = e.customTypes) && void 0 !== r ? r : ["*"],
        message_type: null !== (a = e.messageType) && void 0 !== a ? a : null,
        include_reply_type: null !== (i = e.replyType) && void 0 !== i ? i : ne.NONE,
        include_reactions_summary: null === (o = e.includeReactions) || void 0 === o || o,
        include_meta_array: null === (u = e.includeMetaArray) || void 0 === u || u,
        include_thread_info: null === (c = e.includeThreadInfo) || void 0 === c || c,
        include_parent_message_info: null === (l = e.includeParentMessageInfo) || void 0 === l || l,
        with_sorted_meta_array: null === (h = e.includeMetaArray) || void 0 === h || h,
        show_subchannel_messages_only: null !== (d = e.showSubchannelMessagesOnly) && void 0 !== d && d,
        include_poll_details: !0,
        checking_continuous_messages: e.checkingContinuousMessages
      }), _;
    }
    return e(s, n), s;
  }(S),
  As = function (t) {
    function n(e, n) {
      var s,
        r,
        a,
        i,
        o,
        u,
        c = this;
      return (c = t.call(this, e, n) || this).isHugeGap = n.is_huge_gap, c.prevMessages = (null !== (s = n.prev_messages) && void 0 !== s ? s : []).map(function (t) {
        return Ze(e, t);
      }), c.prevHasMore = null !== (r = n.prev_hasmore) && void 0 !== r && r, c.isContinuousPrevMessages = null !== (a = n.is_continuous_prev_messages) && void 0 !== a && a, c.nextMessages = (null !== (i = n.next_messages) && void 0 !== i ? i : []).map(function (t) {
        return Ze(e, t);
      }), c.nextHasmore = null !== (o = n.next_hasmore) && void 0 !== o && o, c.isContinuousNextMessages = null !== (u = n.is_continuous_next_messages) && void 0 !== u && u, c;
    }
    return e(n, t), n;
  }(T),
  Is = {
    includeMetaArray: !0,
    includeReactions: !0,
    includeThreadInfo: !0,
    includeParentMessageInfo: !0
  };
!function (e) {
  e.CACHE_AND_REPLACE_BY_API = "cache_and_replace_by_api";
}(Es || (Es = {}));
var ws,
  Ls,
  Ds,
  xs = function () {
    function e() {
      this._onCacheResult = ue, this._onApiResult = ue;
    }
    return e.prototype._invokeResponse = function (e, t, n) {
      var s = this;
      H(function () {
        return p(s, void 0, void 0, function () {
          return f(this, function (s) {
            switch (e) {
              case "local":
                this._onCacheResult(t, n);
                break;
              case "remote":
                this._onApiResult(t, n);
            }
            return [2];
          });
        });
      });
    }, e.prototype.onCacheResult = function (e) {
      return this._onCacheResult = e, this;
    }, e.prototype.onApiResult = function (e) {
      return this._onApiResult = e, this;
    }, e;
  }(),
  Rs = function () {
    function e(e, t) {
      var n = t.filter,
        s = t.startingPoint,
        r = t.limit,
        a = t.prevResultLimit,
        i = t.nextResultLimit,
        o = t.channel,
        u = t.channelManager,
        c = t.disableBackgroundSync,
        l = void 0 !== c && c,
        h = this;
      this._messages = [], this._unsentMessages = [], this._isLoadingPrevious = !1, this._isLoadingNext = !1, this._iid = e, this._key = "mc-".concat(oe()), this._isDisposed = !1, this.filter = null != n ? n : new Le(), this._channel = o, this._syncRange = new _s({}), this._hasPrevious = !0, this._hasNext = !0, this._startingPoint = "number" == typeof s && Number.isFinite(s) ? s : Number.MAX_SAFE_INTEGER, this._limit = r || Ke, this._prevResultLimit = null != a ? a : Math.floor(this._limit / 2), this._nextResultLimit = null != i ? i : Math.floor(this._limit / 2), this._channelManager = u;
      var m = P.of(this._iid).statManager;
      this._channelManager.subscribeChannelEvent(this._key, {
        onUpdate: function (e, t) {
          var n = t.source,
            s = e.findIndex(function (e) {
              return e.isIdentical(h.channel);
            });
          s >= 0 && (h._replaceChannelOfCollection(e[s]), h.channel._runIfHandleableWithGroupChannel(function (e) {
            switch (n) {
              case y.EVENT_CHANNEL_UPDATED:
                for (var s = !1, r = 0; r < h._messages.length; r++) {
                  if (h._messages[r].createdAt >= e.messageOffsetTimestamp) {
                    s = !0;
                    var a = r;
                    if (a > 0) {
                      var i = h._messages.splice(0, a);
                      h._removeMessagesFromView(i.map(function (e) {
                        return h.keyOf(e);
                      }), y.EVENT_MESSAGE_OFFSET_UPDATED);
                    }
                    break;
                  }
                }
                !s && h._messages.length > 0 && h._removeMessagesFromView(h._messages.map(function (e) {
                  return h.keyOf(e);
                }), y.EVENT_MESSAGE_OFFSET_UPDATED);
                break;
              case y.EVENT_CHANNEL_UNMUTED:
                var o = P.of(h._iid).sdkState,
                  u = t.user;
                o.userId === u.userId && h._clearCheckMyMutedTimer();
                break;
              case y.EVENT_CHANNEL_MUTED:
                o = P.of(h._iid).sdkState, u = t.user;
                o.userId === u.userId && -1 !== e._myMutedRemainingTime && h._startCheckMyMutedTimer(e._myMutedRemainingTime);
                break;
              case y.EVENT_CHANNEL_LEFT:
                e.isPublic && h._clearCheckMyMutedTimer();
            }
            H(function () {
              return p(h, void 0, void 0, function () {
                var e;
                return f(this, function (s) {
                  return En(n) && (null === (e = this._handler) || void 0 === e ? void 0 : e.onChannelUpdated) && this._handler.onChannelUpdated(t, this.channel), [2];
                });
              });
            });
          }), h._postprocessChannelUpdateEvent(e[s], n));
        },
        onRemove: function (e, t) {
          var n = e.indexOf(h.channel.url);
          n >= 0 && (h._clearCheckMyMutedTimer(), H(function () {
            return p(h, void 0, void 0, function () {
              var e;
              return f(this, function (n) {
                return (null === (e = this._handler) || void 0 === e ? void 0 : e.onChannelDeleted) && this._handler.onChannelDeleted(t, this.channel.url), [2];
              });
            });
          }), h._postprocessChannelRemoveEvent(e[n]));
        }
      }), this._channelManager.subscribeMessageEvent(this._key, {
        onUpdate: function (e, t) {
          var n,
            s,
            r = [],
            a = [];
          try {
            for (var i = v(e), o = i.next(); !o.done; o = i.next()) {
              var u = o.value;
              u.channelUrl === h._channel.url && (h.filter.match(u) ? r.push(u) : a.push(h.keyOf(u)));
            }
          } catch (e) {
            n = {
              error: e
            };
          } finally {
            try {
              o && !o.done && (s = i.return) && s.call(i);
            } finally {
              if (n) throw n.error;
            }
          }
          if (ie(t)) {
            if (r.length > 0) switch (t) {
              case y.LOCAL_MESSAGE_CANCELED:
              case y.LOCAL_MESSAGE_RESEND_STARTED:
              case y.EVENT_MESSAGE_SENT_FAILED:
              case y.EVENT_MESSAGE_SENT_SUCCESS:
              case y.EVENT_MESSAGE_UPDATED:
              case y.EVENT_MESSAGE_THREADINFO_UPDATED:
              case y.EVENT_MESSAGE_REACTION_UPDATED:
              case y.EVENT_MESSAGE_FEEDBACK_ADDED:
              case y.EVENT_MESSAGE_FEEDBACK_UPDATED:
              case y.EVENT_MESSAGE_FEEDBACK_DELETED:
              case y.SYNC_MESSAGE_CHANGELOGS:
                h._updateMessagesToView(r, t);
                break;
              case y.EVENT_MESSAGE_SENT_PENDING:
                h._addMessagesToView(r, t);
                break;
              case y.EVENT_MESSAGE_RECEIVED:
                h.hasNext || h._addMessagesToView(r, t);
                break;
              case y.SYNC_MESSAGE_FILL:
                h._addMessagesToView(r, t);
            }
            a.length > 0 && h._removeMessagesFromView(a, t);
          }
          h._postprocessMessageUpdateEvent(e, t);
        },
        onRemove: function (e, t) {
          h._removeMessagesFromView(e, t), h._postprocessMessageRemoveEvent(e);
        },
        onRemoveUnsent: function (e, t) {
          h._removeUnsentMessageFromView(e, t);
        },
        onPollChangeLogUpdate: function (e, t) {
          h._updatePollsToView(e, t);
        },
        onPollUpdate: function (e, t) {
          h._applyPollUpdateEventToView(e, t);
        },
        onPollVote: function (e, t) {
          h._applyPollVoteEventToView(e, t);
        },
        onReactionUpdate: function (e, t) {
          h._applyReactionEventToView(e, t);
        },
        onThreadInfoUpdate: function (e, t) {
          h._applyThreadInfoEventToView(e, t);
        }
      });
      var g = P.of(this._iid),
        E = g.cacheContext,
        C = g.dispatcher,
        M = g.messageBackgroundSyncThrottleController;
      this._channel._updateMessageCollectionLastAccessedAt(), C.dispatch(new Cn({
        channels: [this._channel],
        context: {
          source: y.CHANNEL_LASTACCESSEDAT_UPDATED
        }
      })), this._backgroundSync = Ms.of(this._iid, this._channel), !l && this._shouldStartBackgroundSync() && this._backgroundSync.resume(this._startingPoint), this._changelogSync = Ns.of(this._iid, this._channel, this.changelogIncludeParams), this._changelogSync.resume(), this._pollChangelogSync = Ss.of(this._iid, this._channel, this._hasPollMessage.bind(this)), this._pollChangelogSync.resume(), this._prevFill = new Cs(this._key, function (e) {
        return p(h, void 0, void 0, function () {
          var t = this;
          return f(this, function (n) {
            switch (n.label) {
              case 0:
                return [4, M.run("message-fill-sync-".concat(o.url, "-prev"), function () {
                  return p(t, void 0, void 0, function () {
                    var t, n, s, r, a, i, o;
                    return f(this, function (u) {
                      switch (u.label) {
                        case 0:
                          return [4, this._getRemoteMessages(e, {
                            prevLimit: this._prevResultLimit,
                            source: y.SYNC_MESSAGE_FILL,
                            checkingContinuousMessages: E.localCacheEnabled
                          })];
                        case 1:
                          return t = u.sent(), n = t.messages, s = t.isContinuousMessages, n.length > 0 ? (r = Math.min.apply(Math, d([], _(n.map(function (e) {
                            return e.createdAt;
                          })), !1)), this._syncRange.extends(r), s && (null === (a = this._backgroundSync) || void 0 === a || a.range.extends(r)), [2, {
                            hasNext: n.length >= this._prevResultLimit && this.viewTop < r,
                            nextToken: this._syncRange.top
                          }]) : (E.localCacheEnabled && m.put(new ee({
                            type: te.FEATURE_LOCALCACHE_EVENT,
                            ts: Date.now(),
                            data: {
                              channel_url: this.channel.url,
                              measured_on: "gap_check",
                              event: "cache_fetch",
                              max_db_size: null !== (o = null === (i = E.localCacheConfig) || void 0 === i ? void 0 : i.maxSize) && void 0 !== o ? o : 0,
                              use_local_cache: E.localCacheEnabled,
                              starting_point: 0,
                              message_init_policy: ""
                            }
                          })), [2, {
                            hasNext: !1,
                            nextToken: 0
                          }]);
                      }
                    });
                  });
                })];
              case 1:
                return [2, n.sent()];
            }
          });
        });
      }), this._nextFill = new Cs(this._key, function (e) {
        return p(h, void 0, void 0, function () {
          var t = this;
          return f(this, function (n) {
            switch (n.label) {
              case 0:
                return [4, M.run("message-fill-sync-".concat(o.url, "-next"), function () {
                  return p(t, void 0, void 0, function () {
                    var t, n, s, r, a, i, o, u;
                    return f(this, function (c) {
                      switch (c.label) {
                        case 0:
                          return [4, this._getRemoteMessages(e, {
                            nextLimit: this._nextResultLimit,
                            source: y.SYNC_MESSAGE_FILL,
                            checkingHasNext: !0,
                            checkingContinuousMessages: E.localCacheEnabled
                          })];
                        case 1:
                          return t = c.sent(), n = t.messages, s = t.isContinuousMessages, r = t.hasNext, n.length > 0 ? (a = Math.max.apply(Math, d([], _(n.map(function (e) {
                            return e.createdAt;
                          })), !1)), this._syncRange.extends(a), s && (null === (i = this._backgroundSync) || void 0 === i || i.range.extends(a)), [2, {
                            hasNext: r,
                            nextToken: this._syncRange.bottom
                          }]) : (E.localCacheEnabled && m.put(new ee({
                            type: te.FEATURE_LOCALCACHE_EVENT,
                            ts: Date.now(),
                            data: {
                              channel_url: this.channel.url,
                              measured_on: "gap_check",
                              event: "cache_fetch",
                              max_db_size: null !== (u = null === (o = E.localCacheConfig) || void 0 === o ? void 0 : o.maxSize) && void 0 !== u ? u : 0,
                              use_local_cache: E.localCacheEnabled,
                              starting_point: 0,
                              message_init_policy: ""
                            }
                          })), [2, {
                            hasNext: !1,
                            nextToken: 0
                          }]);
                      }
                    });
                  });
                })];
              case 1:
                return [2, n.sent()];
            }
          });
        });
      }), this._connectionEventContext = C.on(function (e) {
        if (e instanceof Z) switch (e.stateType) {
          case $.CONNECTED:
            b(function () {
              return p(h, void 0, void 0, function () {
                return f(this, function (e) {
                  return this._activate(), [2];
                });
              });
            });
            break;
          case $.LOGOUT:
            h.dispose();
            break;
          default:
            h._clearCheckMyMutedTimer(), h._prevFill.stop(), h._nextFill.stop();
        }
      });
    }
    return e.prototype.keyOf = function (e) {
      return 0;
    }, Object.defineProperty(e.prototype, "changelogIncludeParams", {
      get: function () {
        return {
          includeReactions: !0,
          includeThreadInfo: !0,
          includeMetaArray: !0,
          includeParentMessageInfo: !0
        };
      },
      enumerable: !1,
      configurable: !0
    }), e.prototype._postprocessChannelUpdateEvent = function (e, t) {}, e.prototype._postprocessChannelRemoveEvent = function (e) {}, e.prototype._postprocessMessageUpdateEvent = function (e, t) {}, e.prototype._postprocessMessageRemoveEvent = function (e) {}, Object.defineProperty(e.prototype, "channel", {
      get: function () {
        return this._channel;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(e.prototype, "succeededMessages", {
      get: function () {
        return d([], _(this._messages), !1);
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(e.prototype, "failedMessages", {
      get: function () {
        return this._unsentMessages.filter(function (e) {
          return e.sendingStatus === ae.FAILED;
        });
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(e.prototype, "pendingMessages", {
      get: function () {
        return this._unsentMessages.filter(function (e) {
          return e.sendingStatus === ae.PENDING;
        });
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(e.prototype, "hasPrevious", {
      get: function () {
        return this._hasPrevious;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(e.prototype, "hasNext", {
      get: function () {
        return this._hasNext;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(e.prototype, "viewTop", {
      get: function () {
        return Math.min.apply(Math, d(d([], _(this._messages.map(function (e) {
          return e.createdAt;
        })), !1), [Number.MAX_SAFE_INTEGER], !1));
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(e.prototype, "viewBottom", {
      get: function () {
        return Math.max.apply(Math, d(d([], _(this._messages.map(function (e) {
          return e.createdAt;
        })), !1), [0], !1));
      },
      enumerable: !1,
      configurable: !0
    }), e.prototype._activate = function () {
      return p(this, void 0, void 0, function () {
        return f(this, function (e) {
          switch (e.label) {
            case 0:
              return P.of(this._iid).logger.debug("check huge gap"), this._checkHugeGap(), [4, this._refreshChannel(y.SYNC_CHANNEL_CHANGELOGS)];
            case 1:
              return e.sent(), [2];
          }
        });
      });
    }, e.prototype._replaceChannelOfCollection = function (e) {
      this._channel.isGroupChannel() ? this._channel = e : this._channel.isFeedChannel() && (this._channel._groupChannel = e._groupChannel);
    }, e.prototype._shouldStartBackgroundSync = function () {
      var e = P.of(this._iid).cacheContext;
      return this.channel.isGroupChannel() ? e.localCacheEnabled && !this.channel.isSuper : e.localCacheEnabled;
    }, e.prototype._setBaseMessageCollectionHandler = function (e) {
      this._handler = e;
    }, e.prototype._filterUnderOffsetMessage = function (e) {
      return e;
    }, e.prototype._updateChildMessagesInView = function (e) {
      var t = [];
      return this._messages.forEach(function (n) {
        n instanceof Ve && n.parentMessageId === e.messageId && n.applyParentMessage(e) && t.push(n);
      }), t;
    }, e.prototype._updatePollsToView = function (e, t) {
      var n,
        s,
        r = this,
        a = [];
      try {
        for (var i = v(e), o = i.next(); !o.done; o = i.next()) {
          var u = o.value,
            c = ms(this._messages, u.messageId);
          if (c >= 0) {
            var l = this._messages[c];
            l.isUserMessage() && l.applyPoll(u), a.push(l);
          }
        }
      } catch (e) {
        n = {
          error: e
        };
      } finally {
        try {
          o && !o.done && (s = i.return) && s.call(i);
        } finally {
          if (n) throw n.error;
        }
      }
      return a.length > 0 && ie(t) && H(function () {
        return p(r, void 0, void 0, function () {
          var e, n, s;
          return f(this, function (r) {
            return e = {
              source: t
            }, a.length > 0 && (null === (s = (n = this._handler).onMessagesUpdated) || void 0 === s || s.call(n, e, this.channel, a)), [2];
          });
        });
      }), a;
    }, e.prototype._applyPollUpdateEventToView = function (e, t) {
      var n = this,
        s = ms(this._messages, e.messageId);
      if (s >= 0) {
        var r = this._messages[s];
        r && r.isUserMessage() && r.poll && r.poll.applyPollUpdateEvent(e) && ie(t) && H(function () {
          return p(n, void 0, void 0, function () {
            var e, n, s;
            return f(this, function (a) {
              return e = {
                source: t
              }, null === (s = null === (n = this._handler) || void 0 === n ? void 0 : n.onMessagesUpdated) || void 0 === s || s.call(n, e, this.channel, [r]), [2];
            });
          });
        });
      }
    }, e.prototype._applyPollVoteEventToView = function (e, t) {
      var n = this,
        s = ms(this._messages, e.messageId);
      if (s >= 0) {
        var r = this._messages[s];
        r && r.isUserMessage() && r.poll && r.poll.applyPollVoteEvent(e) && ie(t) && H(function () {
          return p(n, void 0, void 0, function () {
            var e, n, s;
            return f(this, function (a) {
              return e = {
                source: t
              }, null === (s = null === (n = this._handler) || void 0 === n ? void 0 : n.onMessagesUpdated) || void 0 === s || s.call(n, e, this.channel, [r]), [2];
            });
          });
        });
      }
    }, e.prototype._applyReactionEventToView = function (e, t) {
      var n = this,
        s = ms(this._messages, e.messageId);
      if (s >= 0) {
        var r = this._messages[s];
        r && (r.isUserMessage() || r.isFileMessage() || r.isMultipleFilesMessage()) && ie(t) && (r.applyReactionEvent(e), H(function () {
          return p(n, void 0, void 0, function () {
            var e, n, s;
            return f(this, function (a) {
              return e = {
                source: t
              }, null === (s = null === (n = this._handler) || void 0 === n ? void 0 : n.onMessagesUpdated) || void 0 === s || s.call(n, e, this.channel, [r]), [2];
            });
          });
        }));
      }
    }, e.prototype._applyThreadInfoEventToView = function (e, t) {
      var n = this,
        s = ms(this._messages, e.targetMessageId);
      if (s >= 0) {
        var r = this._messages[s];
        r && (r.isUserMessage() || r.isFileMessage() || r.isMultipleFilesMessage()) && ie(t) && (r.applyThreadInfoUpdateEvent(e), H(function () {
          return p(n, void 0, void 0, function () {
            var e, n, s;
            return f(this, function (a) {
              return e = {
                source: t
              }, null === (s = null === (n = this._handler) || void 0 === n ? void 0 : n.onMessagesUpdated) || void 0 === s || s.call(n, e, this.channel, [r]), [2];
            });
          });
        }));
      }
    }, e.prototype._removeMessagesFromView = function (e, t) {
      var n,
        s,
        r = this,
        a = [],
        i = [],
        o = function (e) {
          var t = u._messages.findIndex(function (t) {
            return r.keyOf(t) === e;
          });
          if (t >= 0) {
            var n = u._messages[t];
            a.push(u.keyOf(n)), i.push(n), u._messages.splice(t, 1);
          }
        },
        u = this;
      try {
        for (var c = v(e), l = c.next(); !l.done; l = c.next()) {
          o(l.value);
        }
      } catch (e) {
        n = {
          error: e
        };
      } finally {
        try {
          l && !l.done && (s = c.return) && s.call(c);
        } finally {
          if (n) throw n.error;
        }
      }
      return ie(t) && i.length > 0 && H(function () {
        return p(r, void 0, void 0, function () {
          var e, n, s;
          return f(this, function (r) {
            return e = {
              source: t
            }, null === (s = null === (n = this._handler) || void 0 === n ? void 0 : n.onMessagesDeleted) || void 0 === s || s.call(n, e, this.channel, a, i), [2];
          });
        });
      }), a;
    }, e.prototype._removeUnsentMessageFromView = function (e, t) {
      var n = this._unsentMessages.findIndex(function (t) {
        return t.reqId === e;
      });
      n >= 0 && this._unsentMessages.splice(n, 1);
    }, e.prototype._getLocalMessages = function (e, t) {
      var n = t.prevLimit,
        s = void 0 === n ? 0 : n,
        r = t.nextLimit,
        a = void 0 === r ? 0 : r,
        i = t.inclusive,
        o = void 0 === i || i;
      return p(this, void 0, void 0, function () {
        var t, n, r, i, u;
        return f(this, function (c) {
          switch (c.label) {
            case 0:
              return t = [], o ? [4, this._channelManager.getExactlyMatchingMessagesForTokenFromCache(this._channel.url, e, this.filter)] : [3, 2];
            case 1:
              t = c.sent(), c.label = 2;
            case 2:
              return s > 0 ? [4, this._channelManager.getMessagesFromCache(this._channel.url, e, "prev", this.filter, s, !1)] : [3, 4];
            case 3:
              return r = c.sent(), [3, 5];
            case 4:
              r = [], c.label = 5;
            case 5:
              return n = r, a > 0 ? [4, this._channelManager.getMessagesFromCache(this._channel.url, e, "next", this.filter, a, !1)] : [3, 7];
            case 6:
              return u = c.sent(), [3, 8];
            case 7:
              u = [], c.label = 8;
            case 8:
              return i = u, [2, {
                messages: d(d(d([], _(t), !1), _(n), !1), _(i), !1).sort(function (e, t) {
                  return t.createdAt - e.createdAt;
                }),
                prevMessagesCount: n.length,
                nextMessagesCount: i.length
              }];
          }
        });
      });
    }, e.prototype._getRemoteMessages = function (e, t) {
      var r = t.prevLimit,
        a = void 0 === r ? 0 : r,
        i = t.nextLimit,
        o = void 0 === i ? 0 : i,
        u = t.source,
        c = void 0 === u ? y.REQUEST_MESSAGE : u,
        l = t.reverse,
        h = void 0 !== l && l,
        d = t.checkingHasNext,
        _ = void 0 !== d && d,
        v = t.checkingContinuousMessages,
        m = void 0 !== v && v;
      return p(this, void 0, void 0, function () {
        var t, r;
        return f(this, function (i) {
          switch (i.label) {
            case 0:
              return t = Xe.of(this._iid), a > 0 || o > 0 ? [4, t._getMessagesByTimestampForCollection(this._channel.url, this._channel.channelType, e, n(s(s(s({}, this.filter), Is), {
                isInclusive: !0,
                reverse: h,
                prevResultSize: a,
                nextResultSize: o
              })), c, _, m)] : [3, 2];
            case 1:
              return r = i.sent(), [3, 3];
            case 2:
              r = {
                messages: [],
                isContinuousMessages: !1
              }, i.label = 3;
            case 3:
              return [2, r];
          }
        });
      });
    }, e.prototype._checkHugeGap = function () {
      var e, t, n, r, a;
      return p(this, void 0, void 0, function () {
        var i,
          o,
          u,
          c,
          l,
          v,
          m,
          g,
          E,
          C,
          M,
          b,
          N,
          U,
          S,
          T,
          A,
          I,
          w,
          L,
          D,
          x,
          R,
          F,
          k,
          O = this;
        return f(this, function (G) {
          switch (G.label) {
            case 0:
              return i = P.of(this._iid), o = i.dispatcher, u = i.requestQueue, c = i.cacheContext, l = i.statManager, v = i.logger, this._messages.length > 0 ? (m = this._syncRange.top, g = this.viewTop, E = this._syncRange.bottom, C = this.hasNext ? this.viewBottom : Number.MAX_SAFE_INTEGER, [4, this._channelManager.getCachedMessageCountBetween(this._channel.url, this.filter, g, m)]) : [3, 8];
            case 1:
              return M = G.sent(), [4, this._channelManager.getCachedMessageCountBetween(this._channel.url, this.filter, E, C)];
            case 2:
              b = G.sent(), G.label = 3;
            case 3:
              return G.trys.push([3, 5, 6, 7]), [4, ce(function () {
                return p(O, void 0, void 0, function () {
                  var e,
                    t,
                    n,
                    r,
                    a,
                    i,
                    l,
                    h,
                    v,
                    N,
                    U,
                    S,
                    T,
                    A,
                    I,
                    w,
                    L,
                    D = this;
                  return f(this, function (x) {
                    switch (x.label) {
                      case 0:
                        return e = new Ts(s(s({
                          channelUrl: this._channel.url,
                          channelType: this._channel.channelType,
                          prevStart: g,
                          prevEnd: m,
                          prevCount: M,
                          nextStart: E,
                          nextEnd: C,
                          nextCount: b,
                          checkingContinuousMessages: c.localCacheEnabled
                        }, this.filter), Is)), [4, u.send(e)];
                      case 1:
                        return t = x.sent(), n = t.as(As), r = n.isHugeGap, a = n.prevMessages, i = void 0 === a ? [] : a, l = n.prevHasMore, h = n.isContinuousPrevMessages, v = n.nextMessages, N = void 0 === v ? [] : v, U = n.nextHasmore, S = n.isContinuousNextMessages, r ? H(function () {
                          return p(D, void 0, void 0, function () {
                            var e;
                            return f(this, function (t) {
                              return (null === (e = this._handler) || void 0 === e ? void 0 : e.onHugeGapDetected) && this._handler.onHugeGapDetected(), [2];
                            });
                          });
                        }) : (T = this.viewTop, A = this.viewBottom, I = Math.min.apply(Math, d([Number.MAX_SAFE_INTEGER, T], _(i.map(function (e) {
                          return e.createdAt;
                        })), !1)), w = Math.max.apply(Math, d([0, A], _(N.map(function (e) {
                          return e.createdAt;
                        })), !1)), o.dispatch(new j({
                          messages: i,
                          source: y.SYNC_MESSAGE_FILL
                        })), o.dispatch(new j({
                          messages: N,
                          source: y.SYNC_MESSAGE_FILL
                        })), this._syncRange.extends(I, w), (h || S) && (null === (L = this._backgroundSync) || void 0 === L || L.range.extends(I, w)), l && this._prevFill.start(I), U && this._nextFill.start(w)), [2];
                    }
                  });
                });
              }, 1)];
            case 4:
              return G.sent(), [3, 7];
            case 5:
              return N = G.sent(), v.debug("Failed HugeGap Check ", N), [3, 7];
            case 6:
              return c.localCacheEnabled && l.put(new ee({
                type: te.FEATURE_LOCALCACHE_EVENT,
                ts: Date.now(),
                data: {
                  channel_url: this.channel.url,
                  measured_on: "gap_check",
                  event: "cache_fetch",
                  max_db_size: null !== (t = null === (e = c.localCacheConfig) || void 0 === e ? void 0 : e.maxSize) && void 0 !== t ? t : 0,
                  use_local_cache: c.localCacheEnabled,
                  starting_point: 0,
                  message_init_policy: ""
                }
              })), [7];
            case 7:
              return [3, 13];
            case 8:
              U = Date.now(), G.label = 9;
            case 9:
              return G.trys.push([9, 11, 12, 13]), [4, this._getRemoteMessages(U, {
                prevLimit: this._prevResultLimit,
                nextLimit: this._nextResultLimit,
                source: y.SYNC_MESSAGE_FILL,
                checkingHasNext: !0,
                checkingContinuousMessages: c.localCacheEnabled
              })];
            case 10:
              if (S = G.sent(), T = S.messages, A = S.isContinuousMessages, I = S.hasNext, T.length > 0) {
                for (w = T.map(function (e) {
                  return e.createdAt;
                }), L = 0, D = 0, x = 0; x < w.length; x++) (R = w[x]) <= U ? L++ : R >= U && D++;
                this._hasPrevious = L >= this._prevResultLimit, this._hasNext = null != I ? I : D >= this._nextResultLimit, (k = this._syncRange).extends.apply(k, d([], _(w), !1)), A && (null === (n = this._backgroundSync) || void 0 === n || n.range.extends(this._syncRange.top, this._syncRange.bottom)), this._addMessagesToView(T, y.SYNC_MESSAGE_FILL);
              } else this._hasPrevious = !1, this._hasNext = !1;
              return [3, 13];
            case 11:
              return (F = G.sent()) instanceof h && F.code === K.NOT_FOUND_IN_DATABASE && (this._hasPrevious = !1, this._hasNext = !1), [3, 13];
            case 12:
              return c.localCacheEnabled && l.put(new ee({
                type: te.FEATURE_LOCALCACHE_EVENT,
                ts: Date.now(),
                data: {
                  channel_url: this.channel.url,
                  measured_on: "gap_check",
                  event: "cache_fetch",
                  max_db_size: null !== (a = null === (r = c.localCacheConfig) || void 0 === r ? void 0 : r.maxSize) && void 0 !== a ? a : 0,
                  use_local_cache: c.localCacheEnabled,
                  starting_point: 0,
                  message_init_policy: ""
                }
              })), [7];
            case 13:
              return [2];
          }
        });
      });
    }, e.prototype._loadUnsentMessages = function () {
      return p(this, void 0, void 0, function () {
        var e;
        return f(this, function (t) {
          switch (t.label) {
            case 0:
              return e = this, [4, this._channelManager.getUnsentMessagesFromCache(this._channel.url, this.filter)];
            case 1:
              return e._unsentMessages = t.sent(), [2];
          }
        });
      });
    }, e.prototype._hasPollMessage = function () {
      return p(this, void 0, void 0, function () {
        return f(this, function (e) {
          switch (e.label) {
            case 0:
              return [4, this._channelManager.getPollMessagesFromCache(this._channel.url, Date.now() + 6e5, this.filter, 1)];
            case 1:
              return e.sent().length > 0 ? [2, !0] : [2, !1];
          }
        });
      });
    }, e.prototype._refreshChannel = function (e, t) {
      return void 0 === t && (t = !1), p(this, void 0, void 0, function () {
        var n,
          s,
          r = this;
        return f(this, function (a) {
          switch (a.label) {
            case 0:
              n = P.of(this._iid).logger, a.label = 1;
            case 1:
              return a.trys.push([1, 3,, 4]), [4, this._channelManager.refreshChannel(this.channel.url, !0, e, t)];
            case 2:
              return a.sent(), this.channel._runIfHandleableWithGroupChannel(function (e) {
                e.myMutedState === V.MUTED && b(function () {
                  return p(r, void 0, void 0, function () {
                    var e;
                    return f(this, function (t) {
                      switch (t.label) {
                        case 0:
                          return [4, this.channel.getMyMutedInfo()];
                        case 1:
                          return (e = t.sent()).isMuted && -1 !== e.remainingDuration && this._startCheckMyMutedTimer(e.remainingDuration), [2];
                      }
                    });
                  });
                });
              }), [3, 4];
            case 3:
              return s = a.sent(), n.warn("Failed to refresh channel", s), [3, 4];
            case 4:
              return [2];
          }
        });
      });
    }, e.prototype._startCheckMyMutedTimer = function (e) {
      var t = this;
      this._clearCheckMyMutedTimer(), this._checkMyMutedStateTimer = setTimeout(function () {
        return p(t, void 0, void 0, function () {
          var e,
            t,
            n,
            s = this;
          return f(this, function (r) {
            switch (r.label) {
              case 0:
                this._checkMyMutedStateTimer = void 0, e = !0, r.label = 1;
              case 1:
                return r.trys.push([1, 3, 4, 5]), [4, this._channel.getMyMutedInfo()];
              case 2:
                return t = r.sent(), e = !t.isMuted, [3, 5];
              case 3:
                return r.sent(), e = !0, [3, 5];
              case 4:
                return e && (n = P.of(this._iid).sessionManager, this.channel._runIfHandleableWithGroupChannel(function (e) {
                  var t, r;
                  e.myMutedState = V.UNMUTED;
                  var a = {
                    source: y.EVENT_CHANNEL_UNMUTED,
                    user: n.currentUser
                  };
                  null === (r = null === (t = s._handler) || void 0 === t ? void 0 : t.onChannelUpdated) || void 0 === r || r.call(t, a, s.channel);
                })), [7];
              case 5:
                return [2];
            }
          });
        });
      }, e + 1e3);
    }, e.prototype._clearCheckMyMutedTimer = function () {
      this._checkMyMutedStateTimer && (clearTimeout(this._checkMyMutedStateTimer), this._checkMyMutedStateTimer = void 0);
    }, e.prototype._setHasNextAndHasPrevious = function (e, t) {
      for (var n = e.map(function (e) {
          return e.createdAt;
        }), s = 0, r = 0, a = 0; a < n.length; a++) {
        var i = n[a];
        i < this._startingPoint ? s++ : i > this._startingPoint && r++;
      }
      this._hasPrevious = s >= this._prevResultLimit, this._hasNext = null != t ? t : r >= this._nextResultLimit;
    }, e.prototype.initialize = function (e) {
      var t = this,
        n = new xs();
      this._messages = [], this._unsentMessages = [], this._syncRange = new _s({}), this._hasNext = !0, this._hasPrevious = !0;
      var s = [],
        r = {
          messages: [],
          prevMessagesCount: 0,
          nextMessagesCount: 0
        };
      return H(function () {
        return p(t, void 0, void 0, function () {
          var t,
            a,
            i,
            o,
            u,
            c = this;
          return f(this, function (l) {
            switch (l.label) {
              case 0:
                return [4, null === (u = this._backgroundSync) || void 0 === u ? void 0 : u.loadMetadata()];
              case 1:
                if (t = l.sent(), a = P.of(this._iid), i = a.cacheContext, o = a.statManager, e === Es.CACHE_AND_REPLACE_BY_API) this._getLocalMessages(this._startingPoint, {
                  prevLimit: this._prevResultLimit,
                  nextLimit: this._nextResultLimit
                }).then(function (e) {
                  return p(c, void 0, void 0, function () {
                    return f(this, function (t) {
                      switch (t.label) {
                        case 0:
                          return r = e, s = this._filterUnderOffsetMessage(r.messages), this._addMessagesToView(s, y.REQUEST_MESSAGE), [4, this._loadUnsentMessages()];
                        case 1:
                          return t.sent(), n._invokeResponse("local", null, s), [2];
                      }
                    });
                  });
                }).catch(function (e) {
                  if (O(e)) throw e;
                  n._invokeResponse("local", e, null);
                }).finally(function () {
                  return p(c, void 0, void 0, function () {
                    var a,
                      i,
                      u,
                      c,
                      l,
                      h,
                      p,
                      v = this;
                    return f(this, function (f) {
                      switch (f.label) {
                        case 0:
                          return a = P.of(this._iid).cacheContext, i = !0, u = !0, c = !1, a.localCacheEnabled && s.length > 0 && (l = t.range).includes.apply(l, d([], _(s.map(function (e) {
                            return e.createdAt;
                          })), !1)) && (!t.previousComplete && r.prevMessagesCount < this._prevResultLimit ? u = !0 : this._startingPoint > t.range.bottom || r.nextMessagesCount < this._nextResultLimit ? c = !0 : u = !1), [4, this._refreshChannel(y.REFRESH_CHANNEL, c)];
                        case 1:
                          return f.sent(), c && this._channel._runIfHandleableWithGroupChannel(function (e) {
                            var t = Math.max.apply(Math, d([], _(s.map(function (e) {
                              return e.createdAt;
                            })), !1));
                            e._latestMessageInfo && t >= e._latestMessageInfo.createdAt && (u = !1);
                          }), u ? (i = !1, this._getRemoteMessages(this._startingPoint, {
                            prevLimit: this._prevResultLimit,
                            nextLimit: this._nextResultLimit,
                            reverse: !0,
                            checkingHasNext: !0,
                            checkingContinuousMessages: a.localCacheEnabled
                          }).then(function (e) {
                            var t,
                              s,
                              r,
                              a = e.messages,
                              i = e.isContinuousMessages,
                              o = e.hasNext,
                              u = v._filterUnderOffsetMessage(a);
                            v._messages = [], u.length > 0 ? (v._setHasNextAndHasPrevious(u, o), (t = v._syncRange).extends.apply(t, d([], _(u.map(function (e) {
                              return e.createdAt;
                            })), !1)), i && (null === (s = v._backgroundSync) || void 0 === s ? void 0 : s.range.overlap(v._syncRange)) && (null === (r = v._backgroundSync) || void 0 === r || r.range.extends(v._syncRange.top, v._syncRange.bottom)), v._addMessagesToView(u, y.REQUEST_MESSAGE)) : (v._hasPrevious = !1, v._hasNext = !1), n._invokeResponse("remote", null, u);
                          }).catch(function (e) {
                            if (O(e)) throw e;
                            n._invokeResponse("remote", e, null);
                          })) : (this._setHasNextAndHasPrevious(s), n._invokeResponse("remote", null, s)), a.localCacheEnabled && o.put(new ee({
                            type: te.FEATURE_LOCALCACHE_EVENT,
                            ts: Date.now(),
                            data: {
                              channel_url: this.channel.url,
                              starting_point: this._startingPoint,
                              measured_on: "initial_load",
                              event: i ? "cache_hit" : "cache_miss",
                              max_db_size: null !== (p = null === (h = a.localCacheConfig) || void 0 === h ? void 0 : h.maxSize) && void 0 !== p ? p : 0,
                              message_init_policy: e,
                              use_local_cache: a.localCacheEnabled,
                              collection_id: this._key
                            }
                          })), [2];
                      }
                    });
                  });
                });
                return o.put(new le({
                  type: te.FEATURE_LOCALCACHE,
                  data: {
                    use_local_cache: i.localCacheEnabled,
                    collection_interface: {
                      message_init_policy: e,
                      message: !0
                    }
                  }
                })), [2];
            }
          });
        });
      }), n;
    }, e.prototype.loadPrevious = function () {
      var e, t, n;
      return p(this, void 0, void 0, function () {
        var s,
          r,
          a,
          i,
          o,
          u,
          c,
          l,
          v = this;
        return f(this, function (m) {
          switch (m.label) {
            case 0:
              if (this._isDisposed) throw new h({
                code: K.COLLECTION_DISPOSED,
                message: "Collection has been disposed."
              });
              return s = P.of(this._iid), r = s.cacheContext, a = s.statManager, i = s.logger, this._hasPrevious ? this._isLoadingPrevious ? (i.warn("Messages are already fetching."), [2, []]) : (this._isLoadingPrevious = !0, o = this.viewTop, u = [], [4, b(function () {
                return p(v, void 0, void 0, function () {
                  var e;
                  return f(this, function (t) {
                    switch (t.label) {
                      case 0:
                        return [4, this._getLocalMessages(o, {
                          prevLimit: this._prevResultLimit,
                          inclusive: !1
                        })];
                      case 1:
                        return e = t.sent(), u = this._filterUnderOffsetMessage(e.messages), [2];
                    }
                  });
                });
              })]) : [2, []];
            case 1:
              return m.sent(), c = u.length >= this._prevResultLimit, r.localCacheEnabled && a.put(new ee({
                type: te.FEATURE_LOCALCACHE_EVENT,
                ts: Date.now(),
                data: {
                  channel_url: this.channel.url,
                  starting_point: this._startingPoint,
                  measured_on: "load_prev",
                  event: c ? "cache_hit" : "cache_miss",
                  max_db_size: null !== (t = null === (e = r.localCacheConfig) || void 0 === e ? void 0 : e.maxSize) && void 0 !== t ? t : 0,
                  use_local_cache: r.localCacheEnabled,
                  collection_id: this._key,
                  message_init_policy: ""
                }
              })), c && (null === (n = this._backgroundSync) || void 0 === n ? void 0 : n.isWrappingMessages(u)) ? [3, 3] : (l = !1, [4, b(function () {
                return p(v, void 0, void 0, function () {
                  var e,
                    t,
                    n,
                    s,
                    r,
                    i,
                    c,
                    h = this;
                  return f(this, function (p) {
                    switch (p.label) {
                      case 0:
                        return e = P.of(this._iid).cacheContext, [4, this._getRemoteMessages(o, {
                          prevLimit: this._prevResultLimit,
                          reverse: !0,
                          checkingContinuousMessages: e.localCacheEnabled
                        })];
                      case 1:
                        return t = p.sent(), (u = this._filterUnderOffsetMessage(t.messages)).length > 0 && ((n = this._syncRange).extends.apply(n, d([], _(u.map(function (e) {
                          return e.createdAt;
                        })), !1)), (null === (s = this._backgroundSync) || void 0 === s ? void 0 : s.range.overlap(this._syncRange)) && t.isContinuousMessages && (l = !0, null === (r = this._backgroundSync) || void 0 === r || r.range.extends(this._syncRange.top))), u = u.filter(function (e) {
                          return vs(h._messages, e) < 0;
                        }), this._hasPrevious = u.length >= this._prevResultLimit, l && a.put(new ee({
                          type: te.FEATURE_LOCALCACHE_EVENT,
                          ts: Date.now(),
                          data: {
                            channel_url: this.channel.url,
                            starting_point: this._startingPoint,
                            measured_on: "load_prev",
                            event: "cache_fetch",
                            max_db_size: null !== (c = null === (i = e.localCacheConfig) || void 0 === i ? void 0 : i.maxSize) && void 0 !== c ? c : 0,
                            use_local_cache: e.localCacheEnabled,
                            collection_id: this._key,
                            message_init_policy: ""
                          }
                        })), [2];
                    }
                  });
                });
              })]);
            case 2:
              return m.sent(), this._addMessagesToView(u, y.REQUEST_MESSAGE), [3, 4];
            case 3:
              this._hasPrevious = u.length >= this._prevResultLimit, u.length > 0 && this._addMessagesToView(u, y.REQUEST_MESSAGE), m.label = 4;
            case 4:
              return this._isLoadingPrevious = !1, [2, u];
          }
        });
      });
    }, e.prototype.loadNext = function () {
      var e, t, n;
      return p(this, void 0, void 0, function () {
        var s,
          r,
          a,
          i,
          o,
          u,
          c,
          l,
          v = this;
        return f(this, function (m) {
          switch (m.label) {
            case 0:
              if (this._isDisposed) throw new h({
                code: K.COLLECTION_DISPOSED,
                message: "Collection has been disposed."
              });
              return s = P.of(this._iid), r = s.cacheContext, a = s.statManager, i = s.logger, this._hasNext ? this._isLoadingNext ? (i.warn("Messages are already fetching."), [2, []]) : (this._isLoadingNext = !0, o = this.viewBottom, u = [], [4, b(function () {
                return p(v, void 0, void 0, function () {
                  var e;
                  return f(this, function (t) {
                    switch (t.label) {
                      case 0:
                        return [4, this._getLocalMessages(o, {
                          nextLimit: this._nextResultLimit,
                          inclusive: !1
                        })];
                      case 1:
                        return e = t.sent(), u = this._filterUnderOffsetMessage(e.messages), [2];
                    }
                  });
                });
              })]) : [2, []];
            case 1:
              return m.sent(), c = u.length >= this._nextResultLimit, r.localCacheEnabled && a.put(new ee({
                type: te.FEATURE_LOCALCACHE_EVENT,
                ts: Date.now(),
                data: {
                  channel_url: this.channel.url,
                  starting_point: this._startingPoint,
                  measured_on: "load_next",
                  event: c ? "cache_hit" : "cache_miss",
                  max_db_size: null !== (t = null === (e = r.localCacheConfig) || void 0 === e ? void 0 : e.maxSize) && void 0 !== t ? t : 0,
                  use_local_cache: r.localCacheEnabled,
                  collection_id: this._key,
                  message_init_policy: ""
                }
              })), c && (null === (n = this._backgroundSync) || void 0 === n ? void 0 : n.isWrappingMessages(u)) ? [3, 3] : (l = !1, [4, b(function () {
                return p(v, void 0, void 0, function () {
                  var e,
                    t,
                    n,
                    s,
                    r,
                    i,
                    c,
                    h,
                    p = this;
                  return f(this, function (f) {
                    switch (f.label) {
                      case 0:
                        return e = P.of(this._iid).cacheContext, [4, this._getRemoteMessages(o, {
                          nextLimit: this._nextResultLimit,
                          reverse: !0,
                          checkingHasNext: !0,
                          checkingContinuousMessages: e.localCacheEnabled
                        })];
                      case 1:
                        return t = f.sent(), (u = this._filterUnderOffsetMessage(t.messages)).length > 0 && ((n = this._syncRange).extends.apply(n, d([], _(u.map(function (e) {
                          return e.createdAt;
                        })), !1)), (null === (s = this._backgroundSync) || void 0 === s ? void 0 : s.range.overlap(this._syncRange)) && t.isContinuousMessages && (l = !0, null === (r = this._backgroundSync) || void 0 === r || r.range.extends(this._syncRange.bottom))), u = u.filter(function (e) {
                          return vs(p._messages, e) < 0;
                        }), this._hasNext = null !== (i = t.hasNext) && void 0 !== i ? i : u.length >= this._nextResultLimit, l && a.put(new ee({
                          type: te.FEATURE_LOCALCACHE_EVENT,
                          ts: Date.now(),
                          data: {
                            channel_url: this.channel.url,
                            starting_point: this._startingPoint,
                            measured_on: "load_next",
                            event: "cache_fetch",
                            max_db_size: null !== (h = null === (c = e.localCacheConfig) || void 0 === c ? void 0 : c.maxSize) && void 0 !== h ? h : 0,
                            use_local_cache: e.localCacheEnabled,
                            collection_id: this._key,
                            message_init_policy: ""
                          }
                        })), [2];
                    }
                  });
                });
              })]);
            case 2:
              return m.sent(), this._addMessagesToView(u, y.REQUEST_MESSAGE), [3, 4];
            case 3:
              this._hasNext = u.length >= this._nextResultLimit, u.length > 0 && this._addMessagesToView(u, y.REQUEST_MESSAGE), m.label = 4;
            case 4:
              return this._isLoadingNext = !1, [2, u];
          }
        });
      });
    }, e.prototype.removeFailedMessage = function (e) {
      return p(this, void 0, void 0, function () {
        var t;
        return f(this, function (n) {
          switch (n.label) {
            case 0:
              if (this._isDisposed) throw new h({
                code: K.COLLECTION_DISPOSED,
                message: "Collection has been disposed."
              });
              return [4, this._channelManager.removeFailedMessageFromCache(e)];
            case 1:
              return n.sent(), (t = this._unsentMessages.findIndex(function (t) {
                return t.reqId === e;
              })) > -1 && this._unsentMessages.splice(t, 1), [2];
          }
        });
      });
    }, e.prototype.dispose = function () {
      var e,
        t,
        n,
        s = this;
      if (!this._isDisposed) {
        this._isDisposed = !0;
        var r = P.of(this._iid),
          a = r.cacheContext,
          i = r.dispatcher;
        this._messages = [], this._clearCheckMyMutedTimer(), this._channel._runIfHandleableWithGroupChannel(function (e) {
          e.myMemberState !== Qt.NONE && (s._channel._updateMessageCollectionLastAccessedAt(), i.dispatch(new Cn({
            channels: [s._channel],
            context: {
              source: y.CHANNEL_LASTACCESSEDAT_UPDATED
            }
          })));
        }), a.localCacheEnabled && (this._prevFill.stop(), this._nextFill.stop()), null === (e = this._backgroundSync) || void 0 === e || e.close(), null === (t = this._changelogSync) || void 0 === t || t.close(), null === (n = this._pollChangelogSync) || void 0 === n || n.close(), this._channelManager.unsubscribeChannelEvent(this._key), this._channelManager.unsubscribeMessageEvent(this._key), this._connectionEventContext && this._connectionEventContext.close();
      }
    }, e;
  }(),
  Fs = function (t) {
    function n(e, n) {
      return t.call(this, e, s(s({}, n), {
        channelManager: fs.of(e)
      })) || this;
    }
    return e(n, t), n.prototype.keyOf = function (e) {
      return e.messageId;
    }, n.prototype.initialize = function (e) {
      return t.prototype.initialize.call(this, e);
    }, n.prototype.setMessageCollectionHandler = function (e) {
      this._setBaseMessageCollectionHandler(e);
    }, n.prototype._addMessagesToView = function (e, t) {
      var n,
        s,
        r = this,
        a = this._filterUnderOffsetMessage(e),
        i = [],
        o = [];
      try {
        for (var u = v(a), c = u.next(); !c.done; c = u.next()) {
          var l = c.value;
          if (t === y.SYNC_MESSAGE_FILL) {
            if (l.messageId > 0) {
              if ((E = vs(this._messages, l)) < 0) {
                vs(this._unsentMessages, l) < 0 && i.push(l);
                var h = gs(this._messages, l);
                this._messages.splice(h, 0, l);
              }
            } else if (l instanceof ze) {
              (E = vs(this._unsentMessages, l)) < 0 && vs(this._messages, l) < 0 && (this._unsentMessages.push(l), i.push(l));
            }
          } else if (l.messageId > 0) {
            if ((E = vs(this._messages, l)) < 0) {
              var m = vs(this._unsentMessages, l);
              m < 0 ? i.push(l) : (this._unsentMessages.splice(m, 1), o.push(l));
              h = gs(this._messages, l);
              this._messages.splice(h, 0, l);
            } else o.push(l), this._messages[E] = l;
            if (l.updatedAt > 0) {
              var g = this._updateChildMessagesInView(l);
              o.push.apply(o, d([], _(g), !1));
            }
          } else if (l instanceof ze) {
            var E;
            (E = vs(this._unsentMessages, l)) < 0 ? vs(this._messages, l) < 0 && (this._unsentMessages.push(l), i.push(l)) : (o.push(l), this._unsentMessages[E] = l);
          }
        }
      } catch (e) {
        n = {
          error: e
        };
      } finally {
        try {
          c && !c.done && (s = u.return) && s.call(u);
        } finally {
          if (n) throw n.error;
        }
      }
      ie(t) && H(function () {
        return p(r, void 0, void 0, function () {
          var e, n, s, r, a;
          return f(this, function (u) {
            return e = {
              source: t
            }, i.length > 0 && (null === (s = null === (n = this._handler) || void 0 === n ? void 0 : n.onMessagesAdded) || void 0 === s || s.call(n, e, this.channel, i)), o.length > 0 && (null === (a = null === (r = this._handler) || void 0 === r ? void 0 : r.onMessagesUpdated) || void 0 === a || a.call(r, e, this.channel, o)), [2];
          });
        });
      });
    }, n.prototype._updateMessagesToView = function (e, t) {
      var n,
        s,
        r = this,
        a = [],
        i = [],
        o = [];
      try {
        for (var u = v(e), c = u.next(); !c.done; c = u.next()) {
          var l,
            h = c.value;
          if (h.messageId > 0) {
            if ((l = vs(this._messages, h)) >= 0) i.push(h), this._messages[l] = h;else {
              var m = vs(this._unsentMessages, h);
              if (m >= 0) {
                var g = _(this._unsentMessages.splice(m, 1), 1)[0];
                if (this.hasNext && g) o.push(g);else {
                  i.push(h);
                  var E = gs(this._messages, h);
                  this._messages.splice(E, 0, h);
                }
              } else {
                var C = this._messages.map(function (e) {
                  return e.createdAt;
                });
                (h.createdAt < Math.min.apply(Math, d([], _(C), !1)) && !this._hasPrevious || h.createdAt > Math.max.apply(Math, d([], _(C), !1)) && !this._hasNext) && a.push(h);
              }
            }
          } else if (h instanceof ze) (l = vs(this._unsentMessages, h)) >= 0 && (i.push(h), this._unsentMessages[l] = h);
        }
      } catch (e) {
        n = {
          error: e
        };
      } finally {
        try {
          c && !c.done && (s = u.return) && s.call(u);
        } finally {
          if (n) throw n.error;
        }
      }
      return ie(t) && H(function () {
        return p(r, void 0, void 0, function () {
          var e, n, s, r, u;
          return f(this, function (c) {
            return e = {
              source: t
            }, i.length > 0 ? null === (s = null === (n = this._handler) || void 0 === n ? void 0 : n.onMessagesUpdated) || void 0 === s || s.call(n, e, this.channel, i) : o.length > 0 ? null === (u = null === (r = this._handler) || void 0 === r ? void 0 : r.onMessagesDeleted) || void 0 === u || u.call(r, e, this.channel, [], o) : a.length > 0 && this._addMessagesToView(a, t), [2];
          });
        });
      }), i;
    }, n;
  }(Rs),
  Ps = {
    coverUrl: void 0,
    coverImage: void 0,
    isDistinct: void 0,
    isPublic: void 0,
    isDiscoverable: void 0,
    accessCode: void 0,
    name: void 0,
    data: void 0,
    customType: void 0,
    operatorUserIds: void 0,
    messageSurvivalSeconds: void 0
  },
  ks = function (t) {
    function n(e) {
      var n = this,
        s = e.channelUrl,
        r = e.token,
        a = e.limit,
        i = e.order,
        o = e.mutedMemberFilter,
        u = e.memberStateFilter,
        c = e.nicknameStartsWithFilter,
        l = e.operatorFilter;
      return (n = t.call(this) || this).method = N.GET, n.path = "".concat(I, "/").concat(encodeURIComponent(s), "/members"), n.params = {
        token: r,
        limit: a,
        order: i,
        muted_member_filter: o,
        member_state_filter: u,
        nickname_startswith: c,
        operator_filter: l,
        show_member_is_muted: !0,
        show_read_receipt: !0,
        show_delivery_receipt: !0
      }, n;
    }
    return e(n, t), n;
  }(S),
  Os = function (t) {
    function n(e, n) {
      var s = t.call(this, e, n) || this;
      s.members = [];
      var r = n.next,
        a = n.members;
      return s.token = r, a && a.length > 0 && (s.members = a.map(function (t) {
        return new nn(e, t);
      })), s;
    }
    return e(n, t), n;
  }(T);
!function (e) {
  e.ALL = "all", e.MUTED = "muted", e.UNMUTED = "unmuted";
}(ws || (ws = {})), function (e) {
  e.MEMBER_NICKNAME_ALPHABETICAL = "member_nickname_alphabetical", e.OPERATOR_THEN_MEMBER_ALPHABETICAL = "operator_then_member_alphabetical";
}(Ls || (Ls = {})), function (e) {
  e.ALL = "all", e.JOINED = "joined_only", e.INVITED = "invited_only", e.INVITED_BY_FRIEND = "invited_by_friend", e.INVITED_BY_NON_FRIEND = "invited_by_non_friend";
}(Ds || (Ds = {}));
var Hs = function (t) {
    function a(e, n, s) {
      var r,
        a,
        o,
        u,
        c = this;
      return (c = t.call(this, e, n, i.GROUP, s) || this).mutedMemberFilter = ws.ALL, c.memberStateFilter = Ds.ALL, c.nicknameStartsWithFilter = null, c.operatorFilter = $t.ALL, c.order = Ls.MEMBER_NICKNAME_ALPHABETICAL, c.mutedMemberFilter = null !== (r = s.mutedMemberFilter) && void 0 !== r ? r : ws.ALL, c.memberStateFilter = null !== (a = s.memberStateFilter) && void 0 !== a ? a : Ds.ALL, c.nicknameStartsWithFilter = null !== (o = s.nicknameStartsWithFilter) && void 0 !== o ? o : null, c.order = null !== (u = s.order) && void 0 !== u ? u : Ls.MEMBER_NICKNAME_ALPHABETICAL, c;
    }
    return e(a, t), a.prototype._validate = function () {
      return t.prototype._validate.call(this) && r(ws, this.mutedMemberFilter) && r(Ds, this.memberStateFilter) && (g("string", this.nicknameStartsWithFilter) || null === this.nicknameStartsWithFilter) && r($t, this.operatorFilter) && r(Ls, this.order);
    }, a.prototype.next = function () {
      return p(this, void 0, void 0, function () {
        var e, t, r, a, i, o;
        return f(this, function (u) {
          switch (u.label) {
            case 0:
              return this._validate() ? this._isLoading ? [3, 3] : this._hasNext ? (this._isLoading = !0, e = P.of(this._iid).requestQueue, t = new ks(n(s(s({}, this), {
                token: this._token
              }))), [4, e.send(t)]) : [3, 2] : [3, 5];
            case 1:
              return r = u.sent(), a = r.as(Os), i = a.members, o = a.token, this._token = o, this._hasNext = !!o, this._isLoading = !1, [2, i];
            case 2:
              return [2, []];
            case 3:
              throw h.queryInProgress;
            case 4:
              return [3, 6];
            case 5:
              throw h.invalidParameters;
            case 6:
              return [2];
          }
        });
      });
    }, a;
  }(he),
  Gs = function (t) {
    function n(e) {
      var n = this,
        s = e.channelUrl,
        r = e.userId,
        a = e.accessCode;
      return (n = t.call(this) || this).method = N.PUT, n.path = "".concat(I, "/").concat(encodeURIComponent(s), "/accept"), n.params = {
        user_id: r,
        access_code: a
      }, n;
    }
    return e(n, t), n;
  }(S),
  Vs = function (t) {
    function n(e, n) {
      var s = t.call(this, e, n) || this;
      return s.channel = new cr(e, n), s.channel.myMemberState = Qt.JOINED, s;
    }
    return e(n, t), n;
  }(T),
  Bs = function (n) {
    function s(e) {
      var s = this,
        r = e.channelUrl,
        a = e.isDistinct,
        i = e.isPublic,
        o = e.isDiscoverable,
        u = e.coverUrl,
        c = e.coverImage,
        l = e.accessCode,
        h = e.name,
        d = e.data,
        _ = e.customType,
        p = e.operatorUserIds,
        f = e.messageSurvivalSeconds;
      return (s = n.call(this) || this).method = N.PUT, s.path = "".concat(I, "/").concat(encodeURIComponent(r)), s.params = t({
        is_distinct: a,
        is_public: i,
        is_discoverable: o,
        name: h,
        data: d,
        custom_type: _,
        cover_url: u,
        cover_file: c,
        access_code: l,
        operator_ids: p,
        message_survival_seconds: f
      }), s;
    }
    return e(s, n), s;
  }(S),
  zs = function (t) {
    function n(e, n) {
      var s = t.call(this, e, n) || this;
      return s.channel = new cr(e, n), s;
    }
    return e(n, t), n;
  }(T),
  qs = function (t) {
    function n(e) {
      var n = this,
        s = e.channelUrl;
      return (n = t.call(this) || this).method = N.DELETE, n.path = "".concat(I, "/").concat(encodeURIComponent(s)), n;
    }
    return e(n, t), n;
  }(S);
!function (t) {
  function n(e, n) {
    return t.call(this, e, n) || this;
  }
  e(n, t);
}(T);
var js = function (t) {
  function n(e) {
    var n = this,
      s = e.channelUrl;
    return (n = t.call(this) || this).method = N.DELETE, n.path = "".concat(I, "/").concat(encodeURIComponent(s), "/hide"), n;
  }
  return e(n, t), n;
}(S);
!function (t) {
  function n() {
    return null !== t && t.apply(this, arguments) || this;
  }
  e(n, t);
}(T);
var Ws = function (t) {
    function n(e) {
      var n = e.userId,
        s = e.channelUrl,
        r = e.countPreference,
        a = t.call(this) || this;
      return a.method = N.PUT, a.path = "".concat(U, "/").concat(encodeURIComponent(n), "/count_preference/").concat(encodeURIComponent(s)), a.params = {
        count_preference: r
      }, a;
    }
    return e(n, t), n;
  }(S),
  Qs = function (t) {
    function n(e, n) {
      var s = t.call(this, e, n) || this;
      return s.countPreference = n.count_preference, s;
    }
    return e(n, t), n;
  }(T),
  Ys = function (n) {
    function s(e) {
      var s = this,
        r = e.channelUrl,
        a = e.locale;
      return (s = n.call(this) || this).method = N.PUT, s.path = "".concat(I, "/").concat(encodeURIComponent(r), "/reset_user_history"), s.params = t({
        locale: a
      }), s;
    }
    return e(s, n), s;
  }(S),
  Ks = function (t) {
    function n(e, n) {
      var s = t.call(this, e, n) || this,
        r = n.ts_message_offset;
      return s.messageOffsetTimestamp = r, s;
    }
    return e(n, t), n;
  }(T),
  Js = s(s({}, $e), {
    scheduledAt: void 0,
    file: void 0,
    fileUrl: void 0,
    fileName: void 0,
    mimeType: void 0,
    fileSize: void 0,
    thumbnailSizes: void 0,
    requireAuth: !1
  }),
  Xs = s(s({}, tt), {
    scheduledAt: void 0
  }),
  Zs = function (s) {
    function r(e) {
      var r,
        a,
        i = this;
      i = s.call(this) || this;
      var o = [];
      e.mentionType === de.USERS && (e.mentionedUserIds ? o = e.mentionedUserIds : e.mentionedUsers && (o = e.mentionedUsers.map(function (e) {
        return e.userId;
      })));
      var u = e.channelType,
        c = e.channelUrl,
        l = e.scheduledMessageId;
      return i.method = N.PUT, i.path = "".concat(re(u), "/").concat(encodeURIComponent(c), "/scheduled_messages/").concat(encodeURIComponent(l)), i.params = t(n({
        req_id: e.reqId,
        scheduled_at: e.scheduledAt,
        message_type: _e.FILE,
        url: e.fileUrl,
        file_name: e.fileName,
        file_size: e.fileSize,
        file_type: e.mimeType,
        thumbnails: null === (r = e._thumbnails) || void 0 === r ? void 0 : r.map(function (e) {
          return st.payloadify(e);
        }),
        custom_type: e.customType,
        data: e.data,
        require_auth: e.requireAuth,
        mention_type: e.mentionType,
        mentioned_user_ids: o,
        sorted_metaarray: null === (a = e.metaArrays) || void 0 === a ? void 0 : a.map(function (e) {
          return rt.payloadify(e);
        }),
        apple_critical_alert_options: e.appleCriticalAlertOptions ? at.payloadify(e.appleCriticalAlertOptions) : null,
        push_option: e.pushNotificationDeliveryOption
      })), i;
    }
    return e(r, s), r;
  }(S),
  $s = function (t) {
    function n(e, n) {
      var s = t.call(this, e, n) || this;
      return s.message = new Ae(e, n), s;
    }
    return e(n, t), n;
  }(T),
  er = function (s) {
    function r(e) {
      var r,
        a = this;
      a = s.call(this) || this;
      var i = [];
      e.mentionType === de.USERS && (e.mentionedUserIds ? i = e.mentionedUserIds : e.mentionedUsers && (i = e.mentionedUsers.map(function (e) {
        return e.userId;
      })));
      var o = e.channelType,
        u = e.channelUrl,
        c = e.scheduledMessageId;
      return a.method = N.PUT, a.path = "".concat(re(o), "/").concat(encodeURIComponent(u), "/scheduled_messages/").concat(encodeURIComponent(c)), a.params = t(n({
        req_id: e.reqId,
        scheduled_at: e.scheduledAt,
        message_type: _e.USER,
        message: e.message,
        custom_type: e.customType,
        data: e.data,
        mention_type: e.mentionType,
        mentioned_user_ids: i,
        sorted_metaarray: null === (r = e.metaArrays) || void 0 === r ? void 0 : r.map(function (e) {
          return rt.payloadify(e);
        }),
        apple_critical_alert_options: e.appleCriticalAlertOptions ? at.payloadify(e.appleCriticalAlertOptions) : null,
        target_langs: e.translationTargetLanguages,
        push_option: e.pushNotificationDeliveryOption
      })), a;
    }
    return e(r, s), r;
  }(S);
!function (t) {
  function n(e, n) {
    var s = t.call(this, e, n) || this;
    return s.message = new Te(e, n), s;
  }
  e(n, t);
}(T);
var tr,
  nr,
  sr = function (t) {
    function n(e) {
      var n = t.call(this) || this,
        s = e.channelType,
        r = e.channelUrl,
        a = e.scheduledMessageId;
      return n.method = N.DELETE, n.path = "".concat(re(s), "/").concat(encodeURIComponent(r), "/scheduled_messages/").concat(encodeURIComponent(a)), n;
    }
    return e(n, t), n;
  }(S),
  rr = function (t) {
    function n(e) {
      var n = t.call(this) || this,
        s = e.channelType,
        r = e.channelUrl,
        a = e.scheduledMessageId;
      return n.method = N.POST, n.path = "".concat(re(s), "/").concat(encodeURIComponent(r), "/scheduled_messages/").concat(encodeURIComponent(a), "/send_now"), n;
    }
    return e(n, t), n;
  }(S),
  ar = function (t) {
    function n(e) {
      var n = e.userId,
        s = e.channelUrl,
        r = e.pushTriggerOption,
        a = t.call(this) || this;
      return a.method = N.PUT, a.path = "".concat(U, "/").concat(encodeURIComponent(n), "/push_preference/").concat(encodeURIComponent(s)), a.params = {
        push_trigger_option: r
      }, a;
    }
    return e(n, t), n;
  }(S),
  ir = function (t) {
    function n(e, n) {
      var s = t.call(this, e, n) || this;
      return s.pushTriggerOption = n.push_trigger_option, s.enabled = n.enable, s;
    }
    return e(n, t), n;
  }(T),
  or = function (t) {
    function n(e) {
      var n = e.userId,
        s = e.channelUrl,
        r = t.call(this) || this;
      return r.method = N.GET, r.path = "".concat(U, "/").concat(encodeURIComponent(n), "/push_preference/").concat(encodeURIComponent(s)), r;
    }
    return e(n, t), n;
  }(S),
  ur = function (t) {
    function n(e, n) {
      var s = t.call(this, e, n) || this;
      return s.pushTriggerOption = n.push_trigger_option, s.enabled = n.enable, s;
    }
    return e(n, t), n;
  }(T);
!function (e) {
  e.ALL = "all", e.UNREAD_MESSAGE_COUNT_ONLY = "unread_message_count_only", e.UNREAD_MENTION_COUNT_ONLY = "unread_mention_count_only", e.OFF = "off";
}(tr || (tr = {})), function (e) {
  e.UNHIDDEN = "unhidden", e.HIDDEN_ALLOW_AUTO_UNHIDE = "hidden_allow_auto_unhide", e.HIDDEN_PREVENT_AUTO_UNHIDE = "hidden_prevent_auto_unhide";
}(nr || (nr = {}));
var cr = function (u) {
  function c(e, t) {
    var n,
      c,
      l,
      h,
      p,
      f,
      v,
      m,
      E,
      C,
      y,
      M,
      b,
      N,
      U,
      S,
      T,
      A,
      I,
      w,
      L = this;
    return (L = u.call(this, e, t) || this)._unreadMemberStateMap = new Map(), L._undeliveredMemberStateMap = new Map(), L._typingStatus = new Map(), L._lastMemberCountUpdated = 0, L._typingStarted = 0, L._typingEnded = 0, L._hasBotInfo = {
      general: !1,
      ai: !1
    }, L.isDistinct = !1, L.isSuper = !1, L.isBroadcast = !1, L.isExclusive = !1, L.isPublic = !1, L.isDiscoverable = !0, L.isChatNotification = !1, L.isAccessCodeRequired = !1, L.isPushEnabled = !1, L.unreadMessageCount = 0, L.unreadMentionCount = 0, L.totalUnreadReplyCount = 0, L.members = [], L.memberCount = 0, L.joinedMemberCount = 0, L.hiddenState = nr.UNHIDDEN, L.lastMessage = null, L.messageOffsetTimestamp = 0, L.messageSurvivalSeconds = -1, L.myMemberState = Qt.NONE, L.myRole = a.NONE, L.myMutedState = V.UNMUTED, L.myLastRead = 0, L.myCountPreference = tr.ALL, L.myPushTriggerOption = me.DEFAULT, L.inviter = null, L.invitedAt = 0, L.joinedAt = 0, L.lastPinnedMessage = null, L._latestMessageInfo = null, L._pinnedMessagesUpdatedAt = 0, L._myMutedRemainingTime = -1, L.channelType = i.GROUP, L.isDistinct = null !== (c = t.is_distinct) && void 0 !== c && c, L.isSuper = null !== (l = t.is_super) && void 0 !== l && l, L.isBroadcast = null !== (h = t.is_broadcast) && void 0 !== h && h, L.isExclusive = null !== (p = t.is_exclusive) && void 0 !== p && p, L.isPublic = null !== (f = t.is_public) && void 0 !== f && f, L.isDiscoverable = null !== (v = t.is_discoverable) && void 0 !== v ? v : L.isPublic, L.isChatNotification = null !== (m = t.is_chat_notification) && void 0 !== m && m, L.isAccessCodeRequired = null !== (E = t.is_access_code_required) && void 0 !== E && E, L.isPushEnabled = null !== (C = t.is_push_enabled) && void 0 !== C && C, Array.isArray(t.members) && (n = L.members).push.apply(n, d([], _(t.members.map(function (e) {
      return new nn(L._iid, e);
    })), !1)), L.memberCount = null !== (y = t.member_count) && void 0 !== y ? y : 0, L.joinedMemberCount = null !== (M = t.joined_member_count) && void 0 !== M ? M : 0, L.hiddenState = r(nr, t.hidden_state) ? t.hidden_state : nr.UNHIDDEN, L.messageOffsetTimestamp = null !== (b = t.ts_message_offset) && void 0 !== b ? b : 0, L.messageSurvivalSeconds = null !== (N = t.message_survival_seconds) && void 0 !== N ? N : -1, L.lastMessage = t.last_message ? Ze(L._iid, s({
      channel_type: L.channelType
    }, t.last_message)) : null, t.read_receipt && Object.keys(t.read_receipt).forEach(function (e) {
      g("number", t.read_receipt[e]) && L._updateUnreadMemberState(e, t.read_receipt[e]);
    }), t.delivery_receipt && Object.keys(t.delivery_receipt).forEach(function (e) {
      g("number", t.delivery_receipt[e]) && L._updateUndeliveredMemberState(e, t.delivery_receipt[e]);
    }), L.myMemberState = r(Qt, t.member_state) ? t.member_state : Qt.NONE, L.myRole = r(a, t.my_role) ? t.my_role : a.NONE, r(V, t.is_muted) ? L.myMutedState = t.is_muted : g("boolean", t.is_muted) ? L.myMutedState = t.is_muted ? V.MUTED : V.UNMUTED : L.myMutedState = V.UNMUTED, L.myCountPreference = r(tr, t.count_preference) ? t.count_preference : tr.ALL, L.myPushTriggerOption = r(me, t.push_trigger_option) ? t.push_trigger_option : me.ALL, L.myLastRead = null !== (U = t.user_last_read) && void 0 !== U ? U : 0, L.inviter = t.inviter ? new o(L._iid, t.inviter) : null, L.invitedAt = null !== (S = t.invited_at) && void 0 !== S ? S : 0, L.joinedAt = null !== (T = t.joined_ts) && void 0 !== T ? T : 0, L._updateUnreadCount(null !== (A = t.unread_message_count) && void 0 !== A ? A : 0, null !== (I = t.unread_mention_count) && void 0 !== I ? I : 0), L.totalUnreadReplyCount = null !== (w = t.total_unread_thread_message_count) && void 0 !== w ? w : 0, L.lastPinnedMessage = t.latest_pinned_message ? Ze(L._iid, s({
      channel_type: L.channelType
    }, t.latest_pinned_message)) : null, t.latest_message && (L._latestMessageInfo = {
      messageId: t.latest_message.message_id,
      createdAt: t.latest_message.created_at
    }), L._hasBotInfo = {
      general: !!t.has_bot,
      ai: !!t.has_ai_bot
    }, L;
  }
  return e(c, u), Object.defineProperty(c.prototype, "isHidden", {
    get: function () {
      return this.hiddenState !== nr.UNHIDDEN;
    },
    enumerable: !1,
    configurable: !0
  }), Object.defineProperty(c.prototype, "isTyping", {
    get: function () {
      return this._typingStatus.size > 0;
    },
    enumerable: !1,
    configurable: !0
  }), Object.defineProperty(c.prototype, "cachedUnreadMemberState", {
    get: function () {
      var e,
        t,
        n = {};
      try {
        for (var s = v(this._unreadMemberStateMap), r = s.next(); !r.done; r = s.next()) {
          var a = _(r.value, 2),
            i = a[0],
            o = a[1];
          n[i] = o;
        }
      } catch (t) {
        e = {
          error: t
        };
      } finally {
        try {
          r && !r.done && (t = s.return) && t.call(s);
        } finally {
          if (e) throw e.error;
        }
      }
      return n;
    },
    enumerable: !1,
    configurable: !0
  }), Object.defineProperty(c.prototype, "cachedUndeliveredMemberState", {
    get: function () {
      var e,
        t,
        n = {};
      try {
        for (var s = v(this._undeliveredMemberStateMap), r = s.next(); !r.done; r = s.next()) {
          var a = _(r.value, 2),
            i = a[0],
            o = a[1];
          n[i] = o;
        }
      } catch (t) {
        e = {
          error: t
        };
      } finally {
        try {
          r && !r.done && (t = s.return) && t.call(s);
        } finally {
          if (e) throw e.error;
        }
      }
      return n;
    },
    enumerable: !1,
    configurable: !0
  }), Object.defineProperty(c.prototype, "hasBot", {
    get: function () {
      return this._hasBotInfo.general;
    },
    enumerable: !1,
    configurable: !0
  }), Object.defineProperty(c.prototype, "hasAiBot", {
    get: function () {
      return this._hasBotInfo.ai;
    },
    enumerable: !1,
    configurable: !0
  }), c.payloadify = function (e) {
    return t(n(s(s({}, u.payloadify.call(this, e)), {
      is_access_code_required: e.isAccessCodeRequired,
      is_distinct: e.isDistinct,
      is_super: e.isSuper,
      is_broadcast: e.isBroadcast,
      is_exclusive: e.isExclusive,
      is_public: e.isPublic,
      is_discoverable: e.isDiscoverable,
      is_muted: e.myMutedState,
      is_push_enabled: e.isPushEnabled,
      unread_message_count: e.unreadMessageCount,
      unread_mention_count: e.unreadMentionCount,
      total_unread_thread_message_count: e.totalUnreadReplyCount,
      push_trigger_option: e.myPushTriggerOption,
      count_preference: e.myCountPreference,
      hidden_state: e.hiddenState,
      member_count: e.memberCount,
      joined_member_count: e.joinedMemberCount,
      member_state: e.myMemberState,
      my_role: e.myRole,
      user_last_read: e.myLastRead,
      ts_message_offset: e.messageOffsetTimestamp,
      message_survival_seconds: e.messageSurvivalSeconds,
      read_receipt: e.cachedUnreadMemberState,
      delivery_receipt: e.cachedUndeliveredMemberState,
      members: e.members.map(function (e) {
        return nn.payloadify(e);
      }),
      last_message: e.lastMessage ? it(e.lastMessage) : null,
      inviter: e.inviter ? o.payloadify(e.inviter) : null,
      invited_at: e.invitedAt,
      joined_ts: e.joinedAt,
      latest_pinned_message: e.lastPinnedMessage ? it(e.lastPinnedMessage) : null,
      latest_message: e._latestMessageInfo ? {
        message_id: e._latestMessageInfo.messageId,
        created_at: e._latestMessageInfo.createdAt
      } : null,
      has_bot: e.hasBot,
      has_ai_bot: e.hasAiBot
    })));
  }, c.prototype._shouldUpdateLastMessageWith = function (e) {
    if (e.silent) return !1;
    var t = P.of(this._iid).appInfo;
    switch (null == t ? void 0 : t.lastMessageThreadingPolicy) {
      case pe.NONE:
      case pe.INCLUDE_REPLY:
        break;
      case pe.EXCLUDE_REPLY:
        if (e.parentMessageId > 0) return !1;
        break;
      case pe.INCLUDE_REPLY_TO_CHANNEL:
        if (e instanceof ze && e.parentMessageId > 0 && !e.replyToChannel) return !1;
    }
    return !this.lastMessage || this.lastMessage.createdAt < e.createdAt || this.lastMessage.createdAt === e.createdAt && this.lastMessage.messageId === e.messageId && this.lastMessage.updatedAt < e.updatedAt;
  }, c.prototype._tryUpdateLastMessageAndCallEvents = function (e, t) {
    var n = P.of(this._iid).dispatcher,
      s = fs.of(this._iid);
    this._updateLastMessage(t), s.handlers.map(function (t) {
      t.onChannelChanged && t.onChannelChanged(e);
    }), n.dispatch(new Cn({
      channels: [e],
      context: {
        source: y.EVENT_MESSAGE_SENT
      }
    }));
  }, c.prototype._shouldUpdateUnreadCountWith = function (e) {
    var t = P.of(this._iid).appInfo;
    switch (null == t ? void 0 : t.unreadCountThreadingPolicy) {
      case fe.NONE:
      case fe.INCLUDE_REPLY:
        break;
      case fe.EXCLUDE_REPLY:
        if (e.parentMessageId > 0) return !1;
        break;
      case fe.INCLUDE_REPLY_TO_CHANNEL:
        if (e instanceof ze && e.parentMessageId > 0 && !e.replyToChannel) return !1;
    }
    return !0;
  }, c.prototype._updateLastMessage = function (e) {
    return !!this._shouldUpdateLastMessageWith(e) && (this.lastMessage = e, !0);
  }, c.prototype._updateUnreadCount = function (e, t) {
    if ("number" == typeof e && e >= 0) {
      if (this.myCountPreference === tr.ALL || this.myCountPreference === tr.UNREAD_MESSAGE_COUNT_ONLY) {
        if (this.isExclusive || this.isSuper || this.isBroadcast) {
          var n = P.of(this._iid).maxSuperGroupChannelUnreadCount;
          this.unreadMessageCount = n && e >= n ? n : e;
        } else this.unreadMessageCount = e;
      } else this.unreadMessageCount = 0;
    } else this.unreadMessageCount = 0;
    "number" == typeof t && t >= 0 && (this.myCountPreference === tr.ALL || this.myCountPreference === tr.UNREAD_MENTION_COUNT_ONLY) ? this.unreadMentionCount = t : this.unreadMentionCount = 0;
  }, c.prototype._updateUnreadMemberState = function (e, t) {
    var n = this._unreadMemberStateMap.get(e);
    return (!n || n < t) && (this._unreadMemberStateMap.set(e, t), P.of(this._iid).sdkState.userId === e && (this.myLastRead = t), !0);
  }, c.prototype._updateUndeliveredMemberState = function (e, t) {
    var n = this._undeliveredMemberStateMap.get(e);
    return (!n || n < t) && (this._undeliveredMemberStateMap.set(e, t), !0);
  }, c.prototype._updateTypingStatus = function (e, t) {
    void 0 === t && (t = new Date().getTime()), t > 0 ? this._typingStatus.set(e.userId, {
      user: e,
      ts: t
    }) : this._typingStatus.delete(e.userId);
  }, c.prototype._clearTypingStatus = function () {
    this._typingStatus.clear(), this._typingStarted = 0, this._typingEnded = 0;
  }, c.prototype._setLatestMemberCount = function (e, t, n) {
    var s = !1;
    return n >= this._lastMemberCountUpdated && (this._lastMemberCountUpdated = n, s = e !== this.memberCount || t !== this.joinedMemberCount, this.memberCount = e, this.joinedMemberCount = t), s;
  }, c.prototype.isReadMessage = function (e) {
    var t = P.of(this._iid).sdkState,
      n = this._unreadMemberStateMap.get(t.userId);
    return !!n && n >= e.createdAt;
  }, c.prototype.serialize = function () {
    var e = this;
    return R(this, function (t) {
      t.cachedUnreadMemberState = e.cachedUnreadMemberState, t.cachedUndeliveredMemberState = e.cachedUndeliveredMemberState, Object.assign(t, e._serializeCachedMetaData());
    });
  }, c.prototype.createMessageCollection = function (e) {
    return void 0 === e && (e = {}), new Fs(this._iid, s(s({}, e), {
      channel: this
    }));
  }, c.prototype.createMemberListQuery = function (e) {
    return void 0 === e && (e = {}), new Hs(this._iid, this.url, e);
  }, c.prototype.createThreadedParentMessageListQuery = function (e) {
    return void 0 === e && (e = {}), new ot(this._iid, this.url, this.channelType, e);
  }, c.prototype.addMember = function (e, t) {
    if (void 0 === t && (t = 0), !this.isExclusive && !this.isSuper && !this.isBroadcast) {
      var n = this.members.findIndex(function (t) {
        return t.userId === e.userId;
      });
      if (n > -1) {
        var s = this.members[n];
        s.state === Qt.JOINED && (e.state = s.state), this.members.splice(n, 1), this.memberCount--;
      }
      this.members.push(e), this.memberCount++, this._updateUnreadMemberState(e.userId, t), this._updateUndeliveredMemberState(e.userId, t);
    }
  }, c.prototype.removeMember = function (e) {
    if (!this.isExclusive && !this.isSuper && !this.isBroadcast) {
      var t = e instanceof nn ? e.userId : e,
        n = this.members.findIndex(function (e) {
          return e.userId === t;
        });
      if (n > -1) return this.members.splice(n, 1), this.memberCount--, !0;
    }
    return !1;
  }, c.prototype.getUnreadMemberCount = function (e) {
    var t, n;
    if (e instanceof ze && !this.isExclusive && !this.isSuper && !this.isBroadcast) {
      var s = P.of(this._iid).sdkState,
        r = e.createdAt,
        a = 0;
      try {
        for (var i = v(this.members), o = i.next(); !o.done; o = i.next()) {
          var u = o.value;
          if (s.userId !== u.userId && u.state === Qt.JOINED && e.sender.userId !== u.userId) (this.cachedUnreadMemberState[u.userId] || 0) < r && a++;
        }
      } catch (e) {
        t = {
          error: e
        };
      } finally {
        try {
          o && !o.done && (n = i.return) && n.call(i);
        } finally {
          if (t) throw t.error;
        }
      }
      return a;
    }
    return 0;
  }, c.prototype.getUndeliveredMemberCount = function (e) {
    var t, n;
    if (e instanceof ze && !this.isExclusive && !this.isSuper && !this.isBroadcast) {
      var s = P.of(this._iid).sdkState,
        r = e.createdAt,
        a = 0;
      try {
        for (var i = v(this.members), o = i.next(); !o.done; o = i.next()) {
          var u = o.value;
          if (s.userId !== u.userId && u.state === Qt.JOINED && e.sender.userId !== u.userId) (this.cachedUndeliveredMemberState[u.userId] || 0) < r && a++;
        }
      } catch (e) {
        t = {
          error: e
        };
      } finally {
        try {
          o && !o.done && (n = i.return) && n.call(i);
        } finally {
          if (t) throw t.error;
        }
      }
      return a;
    }
    return 0;
  }, c.prototype.getReadMembers = function (e, t) {
    var n = this;
    void 0 === t && (t = !1);
    var s = P.of(this._iid).sdkState;
    if (!s.userId || this.isExclusive || this.isSuper || this.isBroadcast) return [];
    var r = e instanceof ze ? e.sender : null,
      a = [];
    return this.members.forEach(function (i) {
      if (t || i.userId !== s.userId && i.userId !== (null == r ? void 0 : r.userId)) {
        var o = n._unreadMemberStateMap.get(i.userId);
        o && o >= e.createdAt && a.push(i);
      }
    }), a;
  }, c.prototype.getUnreadMembers = function (e, t) {
    var n = this;
    void 0 === t && (t = !1);
    var s = P.of(this._iid).sdkState;
    if (!s.userId || this.isExclusive || this.isSuper || this.isBroadcast) return [];
    var r = e instanceof ze ? e.sender : null,
      a = [];
    return this.members.forEach(function (i) {
      if (t || i.userId !== s.userId && i.userId !== (null == r ? void 0 : r.userId)) {
        var o = n._unreadMemberStateMap.get(i.userId);
        o && o < e.createdAt && a.push(i);
      }
    }), a;
  }, c.prototype.getReadStatus = function (e) {
    var t = this;
    void 0 === e && (e = !1);
    var n = P.of(this._iid).sdkState;
    if (!n.userId || this.isExclusive || this.isSuper || this.isBroadcast) return null;
    var s = {};
    return this.members.forEach(function (r) {
      if (e || r.userId !== n.userId) {
        var a = t._unreadMemberStateMap.get(r.userId);
        s[r.userId] = new ut(t._iid, {
          channel_url: t.url,
          channel_type: t.channelType,
          user: nn.payloadify(r),
          ts: null != a ? a : 0
        });
      }
    }), s;
  }, c.prototype.getDeliveryStatus = function (e) {
    var t = this;
    void 0 === e && (e = !0);
    var n = P.of(this._iid).sdkState;
    if (!n.userId || this.isExclusive || this.isSuper || this.isBroadcast) return null;
    var s = {};
    return this.members.forEach(function (r) {
      if (e || r.userId !== n.userId) {
        var a = t._undeliveredMemberStateMap.get(r.userId);
        s[r.userId] = new sn(t._iid, {
          channel_url: t.url,
          channel_type: t.channelType,
          user: nn.payloadify(r),
          ts: null != a ? a : 0
        });
      }
    }), s;
  }, c.prototype.getTypingUsers = function () {
    var e = [];
    return this._typingStatus.forEach(function (t) {
      var n = t.user;
      e.push(n);
    }), e;
  }, c.prototype.invalidateTypingStatus = function () {
    var e = this,
      t = P.of(this._iid).typingIndicatorInvalidateTime,
      n = Date.now(),
      s = !1;
    return this._typingStatus.forEach(function (r, a) {
      var i = r.ts;
      n - i >= t && (e._typingStatus.delete(a), s = !0);
    }), s;
  }, c.prototype.refresh = function () {
    return p(this, void 0, void 0, function () {
      return f(this, function (e) {
        return [2, this._refresh()];
      });
    });
  }, c.prototype._refresh = function (e) {
    return void 0 === e && (e = !1), p(this, void 0, void 0, function () {
      var t, n, s, r, a, i;
      return f(this, function (o) {
        switch (o.label) {
          case 0:
            return t = P.of(this._iid), n = t.requestQueue, s = t.dispatcher, r = new Un({
              channelUrl: this.url
            }), [4, n.send(r)];
          case 1:
            return a = o.sent(), i = a.as(Sn).channel, this._update(i), e || s.dispatch(new Cn({
              channels: [i],
              context: {
                source: y.REFRESH_CHANNEL
              }
            })), [2, this];
        }
      });
    });
  }, c.prototype.freeze = function () {
    return p(this, void 0, void 0, function () {
      return f(this, function (e) {
        switch (e.label) {
          case 0:
            return [4, u.prototype.freeze.call(this)];
          case 1:
            return e.sent(), P.of(this._iid).dispatcher.dispatch(new Cn({
              channels: [this],
              context: {
                source: y.EVENT_CHANNEL_FROZEN
              },
              isWebSocketEventComing: !0
            })), [2];
        }
      });
    });
  }, c.prototype.unfreeze = function () {
    return p(this, void 0, void 0, function () {
      return f(this, function (e) {
        switch (e.label) {
          case 0:
            return [4, u.prototype.unfreeze.call(this)];
          case 1:
            return e.sent(), P.of(this._iid).dispatcher.dispatch(new Cn({
              channels: [this],
              context: {
                source: y.EVENT_CHANNEL_UNFROZEN
              },
              isWebSocketEventComing: !0
            })), [2];
        }
      });
    });
  }, c.prototype.updateChannel = function (e) {
    return p(this, void 0, void 0, function () {
      var t, n, r, a, i, o, u;
      return f(this, function (c) {
        switch (c.label) {
          case 0:
            return t = s(s({}, Ps), e), l(function (e) {
              return g("string", e.coverUrl, !0) && (E(e.coverImage) || g("string", e.coverImage, !0)) && g("boolean", e.isDistinct, !0) && g("boolean", e.isPublic, !0) && g("boolean", e.isDiscoverable, !0) && g("string", e.accessCode, !0) && g("string", e.name, !0) && g("string", e.data, !0) && g("string", e.customType, !0) && m("string", e.operatorUserIds, !0) && g("number", e.messageSurvivalSeconds, !0);
            }(t)).throw(h.invalidParameters), n = P.of(this._iid), r = n.dispatcher, a = n.requestQueue, i = new Bs(s({
              channelUrl: this.url
            }, t)), [4, a.send(i)];
          case 1:
            return o = c.sent(), u = o.as(zs).channel, this._update(u), r.dispatch(new Cn({
              channels: [u],
              context: {
                source: y.EVENT_CHANNEL_UPDATED
              },
              isWebSocketEventComing: !0
            })), [2, this];
        }
      });
    });
  }, c.prototype.invite = function (e) {
    return p(this, void 0, void 0, function () {
      return f(this, function (t) {
        return l(e.every(function (e) {
          return e instanceof o;
        })).throw(h.invalidParameters), [2, this.inviteWithUserIds(e.map(function (e) {
          return e.userId;
        }))];
      });
    });
  }, c.prototype.inviteWithUserIds = function (e) {
    return p(this, void 0, void 0, function () {
      var t, n, s, r, a, i, o;
      return f(this, function (u) {
        switch (u.label) {
          case 0:
            return l(m("string", e)).throw(h.invalidParameters), t = P.of(this._iid), n = t.dispatcher, s = t.requestQueue, r = t.sessionManager, a = new Kn({
              channelUrl: this.url,
              userIds: e
            }), [4, s.send(a)];
          case 1:
            return i = u.sent(), o = i.as(Jn).channel, this._update(o), n.dispatch(new Cn({
              channels: [o],
              context: {
                source: y.EVENT_CHANNEL_INVITED,
                inviter: r.currentUser,
                invitees: []
              },
              isWebSocketEventComing: !0
            })), [2, this];
        }
      });
    });
  }, c.prototype.join = function (e) {
    return p(this, void 0, void 0, function () {
      var t, n, s, r, a, i, o;
      return f(this, function (u) {
        switch (u.label) {
          case 0:
            return l(g("string", e, !0)).throw(h.invalidParameters), t = P.of(this._iid), n = t.dispatcher, s = t.sdkState, r = t.requestQueue, a = new qn({
              channelUrl: this.url,
              userId: s.userId,
              accessCode: e
            }), [4, r.send(a)];
          case 1:
            return i = u.sent(), (o = i.as(jn).channel).myMemberState = this.myMemberState = Qt.JOINED, this._update(o), n.dispatch(new Cn({
              channels: [o],
              context: {
                source: y.EVENT_CHANNEL_JOINED,
                users: []
              },
              isWebSocketEventComing: !0
            })), [2, this];
        }
      });
    });
  }, c.prototype.leave = function (e) {
    return void 0 === e && (e = !1), p(this, void 0, void 0, function () {
      var t, n, s, r;
      return f(this, function (a) {
        switch (a.label) {
          case 0:
            return t = P.of(this._iid), n = t.sdkState, s = t.requestQueue, r = new Qn({
              channelUrl: this.url,
              userId: n.userId,
              shouldRemoveOperatorStatus: e
            }), [4, s.send(r)];
          case 1:
            return a.sent(), this.myMemberState = Qt.NONE, [2];
        }
      });
    });
  }, c.prototype.acceptInvitation = function (e) {
    return p(this, void 0, void 0, function () {
      var t, n, s, r, a, i, o;
      return f(this, function (u) {
        switch (u.label) {
          case 0:
            return l(g("string", e, !0)).throw(h.invalidParameters), t = P.of(this._iid), n = t.dispatcher, s = t.sdkState, r = t.requestQueue, a = new Gs({
              channelUrl: this.url,
              userId: s.userId,
              accessCode: e
            }), [4, r.send(a)];
          case 1:
            return i = u.sent(), (o = i.as(Vs).channel).myMemberState = this.myMemberState = Qt.JOINED, this._update(o), n.dispatch(new Cn({
              channels: [o],
              context: {
                source: y.EVENT_CHANNEL_ACCEPTED_INVITE
              },
              isWebSocketEventComing: !0
            })), [2, this];
        }
      });
    });
  }, c.prototype.declineInvitation = function () {
    return p(this, void 0, void 0, function () {
      var e, t, n, s;
      return f(this, function (r) {
        switch (r.label) {
          case 0:
            return e = P.of(this._iid), t = e.sdkState, n = e.requestQueue, s = new Zn({
              channelUrl: this.url,
              userId: t.userId
            }), [4, n.send(s)];
          case 1:
            return r.sent(), this.myMemberState = Qt.NONE, [2, this];
        }
      });
    });
  }, c.prototype.sendUserMessage = function (e) {
    var t = this,
      n = new yt(),
      s = P.of(this._iid).dispatcher,
      r = Ft.of(this._iid);
    return u.prototype.sendUserMessage.call(this, e).onPending(function (e) {
      r.completeCurrentAndProcessNextAutoResend(e), n._trigger(e);
    }).onFailed(function (e, t) {
      t && r.completeCurrentAndProcessNextAutoResend(t), n._triggerFailed(e, t);
    }).onSucceeded(function (e) {
      r.completeCurrentAndProcessNextAutoResend(e), t._updateLastMessage(e), fs.of(t._iid).handlers.map(function (e) {
        e.onChannelChanged && e.onChannelChanged(t);
      }), s.dispatch(new Cn({
        channels: [t],
        context: {
          source: y.EVENT_MESSAGE_SENT
        }
      })), n._trigger(e);
    }), n;
  }, c.prototype.updateUserMessage = function (e, t) {
    return p(this, void 0, void 0, function () {
      var n,
        s,
        r,
        a,
        i = this;
      return f(this, function (o) {
        switch (o.label) {
          case 0:
            return n = P.of(this._iid).dispatcher, [4, u.prototype.updateUserMessage.call(this, e, t)];
          case 1:
            return s = o.sent(), r = this._updateLastMessage(s), a = !1, this.lastPinnedMessage && this.lastPinnedMessage.messageId === s.messageId && (this.lastPinnedMessage = s, r = !0, a = !0), r && (fs.of(this._iid).handlers.map(function (e) {
              e.onChannelChanged && e.onChannelChanged(i);
            }), n.dispatch(new Cn({
              channels: [this],
              context: {
                source: a ? y.EVENT_PINNED_MESSAGE_UPDATED : y.EVENT_MESSAGE_UPDATED
              }
            }))), a && fs.of(this._iid).handlers.map(function (e) {
              e.onPinnedMessageUpdated && e.onPinnedMessageUpdated(i);
            }), n.dispatch(new j({
              messages: [s],
              source: y.EVENT_MESSAGE_UPDATED
            })), [2, s];
        }
      });
    });
  }, c.prototype._autoResendUserMessage = function (e) {
    var t = this,
      n = new yt(),
      s = P.of(this._iid).dispatcher,
      r = Ft.of(this._iid);
    return u.prototype._autoResendUserMessage.call(this, e).onPending(function (e) {
      r.completeCurrentAndProcessNextAutoResend(e), n._trigger(e);
    }).onFailed(function (e, t) {
      r.completeCurrentAndProcessNextAutoResend(t), n._triggerFailed(e, t);
    }).onSucceeded(function (e) {
      var a = fs.of(t._iid);
      r.completeCurrentAndProcessNextAutoResend(e), t._updateLastMessage(e), a.handlers.map(function (e) {
        e.onChannelChanged && e.onChannelChanged(t);
      }), s.dispatch(new Cn({
        channels: [t],
        context: {
          source: y.EVENT_MESSAGE_SENT
        }
      })), n._trigger(e);
    }), n;
  }, c.prototype.sendFileMessage = function (e) {
    var t = this,
      n = new yt(),
      s = P.of(this._iid).dispatcher,
      r = Ft.of(this._iid);
    return u.prototype.sendFileMessage.call(this, e).onPending(function (e) {
      r.completeCurrentAndProcessNextAutoResend(e), n._trigger(e);
    }).onFailed(function (e, t) {
      t && r.completeCurrentAndProcessNextAutoResend(t), n._triggerFailed(e, t);
    }).onSucceeded(function (e) {
      var a = fs.of(t._iid);
      r.completeCurrentAndProcessNextAutoResend(e), t._updateLastMessage(e), a.handlers.map(function (e) {
        e.onChannelChanged && e.onChannelChanged(t);
      }), s.dispatch(new Cn({
        channels: [t],
        context: {
          source: y.EVENT_MESSAGE_SENT
        }
      })), n._trigger(e);
    }), n;
  }, c.prototype.sendMultipleFilesMessage = function (e) {
    var t = this,
      n = new ct(),
      s = P.of(this._iid).dispatcher;
    return u.prototype.sendMultipleFilesMessage.call(this, e).onPending(function (e) {
      n._trigger(e);
    }).onFailed(function (e, t) {
      n._triggerFailed(e, t);
    }).onSucceeded(function (e) {
      var r = fs.of(t._iid);
      t._updateLastMessage(e), r.handlers.map(function (e) {
        e.onChannelChanged && e.onChannelChanged(t);
      }), s.dispatch(new Cn({
        channels: [t],
        context: {
          source: y.EVENT_MESSAGE_SENT
        }
      })), n._trigger(e);
    }).onFileUploaded(function (e, t, s, r) {
      n._triggerOnFileUploaded(e, t, s, r);
    }), n;
  }, c.prototype.updateFileMessage = function (e, t) {
    return p(this, void 0, void 0, function () {
      var n,
        s,
        r,
        a,
        i = this;
      return f(this, function (o) {
        switch (o.label) {
          case 0:
            return n = P.of(this._iid).dispatcher, [4, u.prototype.updateFileMessage.call(this, e, t)];
          case 1:
            return s = o.sent(), r = this._updateLastMessage(s), a = !1, this.lastPinnedMessage && this.lastPinnedMessage.messageId === s.messageId && (this.lastPinnedMessage = s, r = !0, a = !0), r && (fs.of(this._iid).handlers.map(function (e) {
              e.onChannelChanged && e.onChannelChanged(i);
            }), n.dispatch(new Cn({
              channels: [this],
              context: {
                source: a ? y.EVENT_PINNED_MESSAGE_UPDATED : y.EVENT_MESSAGE_UPDATED
              }
            }))), a && fs.of(this._iid).handlers.map(function (e) {
              e.onPinnedMessageUpdated && e.onPinnedMessageUpdated(i);
            }), n.dispatch(new j({
              messages: [s],
              source: y.EVENT_MESSAGE_UPDATED
            })), [2, s];
        }
      });
    });
  }, c.prototype._autoResendFileMessage = function (e) {
    var t = this,
      n = new yt(),
      s = P.of(this._iid).dispatcher,
      r = Ft.of(this._iid);
    return u.prototype._autoResendFileMessage.call(this, e).onPending(function (e) {
      r.completeCurrentAndProcessNextAutoResend(e), n._trigger(e);
    }).onFailed(function (e, t) {
      r.completeCurrentAndProcessNextAutoResend(t), n._triggerFailed(e, t);
    }).onSucceeded(function (e) {
      var a = fs.of(t._iid);
      r.completeCurrentAndProcessNextAutoResend(e), t._updateLastMessage(e), a.handlers.map(function (e) {
        e.onChannelChanged && e.onChannelChanged(t);
      }), s.dispatch(new Cn({
        channels: [t],
        context: {
          source: y.EVENT_MESSAGE_SENT
        }
      })), n._trigger(e);
    }), n;
  }, c.prototype.deleteMessage = function (e) {
    return p(this, void 0, void 0, function () {
      return f(this, function (t) {
        switch (t.label) {
          case 0:
            return [4, u.prototype.deleteMessage.call(this, e)];
          case 1:
            return t.sent(), 0 === e.messageId && e instanceof ze && P.of(this._iid).dispatcher.dispatch(new ge({
              reqId: e.reqId,
              source: y.EVENT_MESSAGE_DELETED
            })), [2];
        }
      });
    });
  }, c.prototype.hide = function (e) {
    return p(this, void 0, void 0, function () {
      var t, n, r, a, i, o, u, c;
      return f(this, function (d) {
        switch (d.label) {
          case 0:
            return t = s(s({}, es), e), l(function (e) {
              return g("boolean", e.hidePreviousMessages, !0) && g("boolean", e.allowAutoUnhide, !0);
            }(t)).throw(h.invalidParameters), n = P.of(this._iid), r = n.dispatcher, a = n.sdkState, i = n.requestQueue, o = new ts(s({
              channelUrl: this.url,
              userId: a.userId
            }, t)), [4, i.send(o)];
          case 1:
            return u = d.sent(), c = u.as(ns).messageOffsetTimestamp, this.hiddenState = t.allowAutoUnhide ? nr.HIDDEN_ALLOW_AUTO_UNHIDE : nr.HIDDEN_PREVENT_AUTO_UNHIDE, t.hidePreviousMessages && this._updateUnreadCount(0, 0), c && (this.messageOffsetTimestamp = c), r.dispatch(new Cn({
              channels: [this],
              context: {
                source: y.EVENT_CHANNEL_HIDDEN
              },
              isWebSocketEventComing: !0
            })), [2, this];
        }
      });
    });
  }, c.prototype.unhide = function () {
    return p(this, void 0, void 0, function () {
      var e, t, n, s;
      return f(this, function (r) {
        switch (r.label) {
          case 0:
            return e = P.of(this._iid), t = e.dispatcher, n = e.requestQueue, s = new js({
              channelUrl: this.url
            }), [4, n.send(s)];
          case 1:
            return r.sent(), this.hiddenState = nr.UNHIDDEN, t.dispatch(new Cn({
              channels: [this],
              context: {
                source: y.EVENT_CHANNEL_UNHIDDEN
              },
              isWebSocketEventComing: !0
            })), [2, this];
        }
      });
    });
  }, c.prototype.delete = function () {
    return p(this, void 0, void 0, function () {
      var e, t;
      return f(this, function (n) {
        switch (n.label) {
          case 0:
            return e = P.of(this._iid).requestQueue, t = new qs({
              channelUrl: this.url
            }), [4, e.send(t)];
          case 1:
            return n.sent(), [2];
        }
      });
    });
  }, c.prototype.markAsRead = function () {
    return p(this, void 0, void 0, function () {
      var e,
        t,
        n,
        s,
        r,
        a,
        i,
        o = this;
      return f(this, function (u) {
        switch (u.label) {
          case 0:
            return e = P.of(this._iid), t = e.sdkState, n = e.dispatcher, s = e.requestQueue, r = new Mt({
              channelUrl: this.url
            }), [4, s.send(r)];
          case 1:
            return a = u.sent(), i = a.as(Be).readStatus, this._updateUnreadMemberState(t.userId, i.readAt), (this.unreadMessageCount > 0 || this.unreadMentionCount > 0) && (this._updateUnreadCount(0, 0), fs.of(this._iid).handlers.map(function (e) {
              e.onChannelChanged && e.onChannelChanged(o);
            })), n.dispatch(new Cn({
              channels: [this],
              context: {
                source: y.EVENT_CHANNEL_READ
              }
            })), [2];
        }
      });
    });
  }, c.prototype.markAsDelivered = function () {
    return p(this, void 0, void 0, function () {
      var e, t, n, s;
      return f(this, function (r) {
        switch (r.label) {
          case 0:
            return e = P.of(this._iid), t = e.sdkState, n = e.requestQueue, s = new ls({
              channelUrl: this.url,
              userId: t.userId
            }), [4, n.send(s)];
          case 1:
            return r.sent(), [2];
        }
      });
    });
  }, c.prototype.startTyping = function () {
    return p(this, void 0, void 0, function () {
      var e, t, n, s, r;
      return f(this, function (a) {
        switch (a.label) {
          case 0:
            return e = P.of(this._iid), t = e.requestQueue, n = e.typingIndicatorThrottle, (s = new Date().getTime()) - this._typingStarted >= n ? (this._typingStarted = s, this._typingEnded = 0, r = new rs({
              channelUrl: this.url,
              time: this._typingStarted
            }), [4, t.send(r)]) : [3, 2];
          case 1:
            a.sent(), a.label = 2;
          case 2:
            return [2];
        }
      });
    });
  }, c.prototype.endTyping = function () {
    return p(this, void 0, void 0, function () {
      var e, t, n, s, r;
      return f(this, function (a) {
        switch (a.label) {
          case 0:
            return e = P.of(this._iid), t = e.requestQueue, n = e.typingIndicatorThrottle, (s = new Date().getTime()) - this._typingEnded >= n ? (this._typingStarted = 0, this._typingEnded = s, r = new is({
              channelUrl: this.url,
              time: this._typingStarted
            }), [4, t.send(r)]) : [3, 2];
          case 1:
            a.sent(), a.label = 2;
          case 2:
            return [2];
        }
      });
    });
  }, c.prototype.createScheduledUserMessage = function (e) {
    e = s(s({}, lt), e), l(ht(e)).throw(h.invalidParameters);
    var t = new yt();
    return this._createScheduledUserMessage(e, t), t;
  }, c.prototype.updateScheduledUserMessage = function (e, t) {
    return p(this, void 0, void 0, function () {
      var n, r, a, i;
      return f(this, function (o) {
        switch (o.label) {
          case 0:
            return n = s(s({}, Xs), t), l(function (e) {
              return nt(e) && g("number", e.scheduledAt, !0);
            }(n)).throw(h.invalidParameters), r = P.of(this._iid).requestQueue, a = new er(s({
              reqId: this._generateRequestId(),
              scheduledMessageId: e,
              channelType: this.channelType,
              channelUrl: this.url
            }, n)), [4, r.send(a)];
          case 1:
            return i = o.sent(), [2, i.as(bt).message];
        }
      });
    });
  }, c.prototype.createScheduledFileMessage = function (e) {
    var t = this;
    e = s(s({}, dt), e), l(_t(e)).throw(h.invalidParameters);
    var n = Date.now(),
      r = this._generateRequestId(),
      a = new yt();
    return ve(Nt).then(function () {
      var s = t._createPendingScheduledFileMessage(e, r, n);
      H(function () {
        return p(t, void 0, void 0, function () {
          return f(this, function (e) {
            return [2, a._trigger(s)];
          });
        });
      });
    }), E(e.file) ? this._uploadFileAndUpdateParams(e).then(function () {
      return t._createScheduledFileMessage(e, a, r, n);
    }) : this._createScheduledFileMessage(e, a, r, n), a;
  }, c.prototype.updateScheduledFileMessage = function (e, t) {
    return p(this, void 0, void 0, function () {
      var n, r, a;
      return f(this, function (i) {
        switch (i.label) {
          case 0:
            return n = s(s({}, Js), t), l(function (e) {
              return et(e) && g("number", e.scheduledAt, !0) && (E(e.file) || g("string", e.fileUrl)) && g("string", e.fileName, !0) && g("string", e.mimeType, !0) && g("number", e.fileSize, !0) && (null === e.thumbnailSizes || void 0 === e.thumbnailSizes || e.thumbnailSizes.every(function (e) {
                return g("object", e) && e.maxWidth > 0 && e.maxHeight > 0;
              }));
            }(n)).throw(h.invalidParameters), E(n.file) ? [4, this._uploadFileAndUpdateParams(n)] : [3, 2];
          case 1:
            i.sent(), i.label = 2;
          case 2:
            return r = new Zs(s({
              reqId: this._generateRequestId(),
              scheduledMessageId: e,
              channelType: this.channelType,
              channelUrl: this.url
            }, n)), [4, P.of(this._iid).requestQueue.send(r)];
          case 3:
            return a = i.sent(), [2, a.as($s).message];
        }
      });
    });
  }, c.prototype.cancelScheduledMessage = function (e) {
    return p(this, void 0, void 0, function () {
      var t;
      return f(this, function (n) {
        switch (n.label) {
          case 0:
            return t = new sr({
              scheduledMessageId: e,
              channelType: this.channelType,
              channelUrl: this.url
            }), [4, P.of(this._iid).requestQueue.send(t)];
          case 1:
            return n.sent(), [2];
        }
      });
    });
  }, c.prototype.sendScheduledMessageNow = function (e) {
    return p(this, void 0, void 0, function () {
      var t;
      return f(this, function (n) {
        switch (n.label) {
          case 0:
            return t = new rr({
              scheduledMessageId: e,
              channelType: this.channelType,
              channelUrl: this.url
            }), [4, P.of(this._iid).requestQueue.send(t)];
          case 1:
            return n.sent(), [2];
        }
      });
    });
  }, c.prototype.getMyPushTriggerOption = function () {
    return p(this, void 0, void 0, function () {
      var e, t, n, s, r, a;
      return f(this, function (i) {
        switch (i.label) {
          case 0:
            return e = P.of(this._iid), t = e.sdkState, n = e.requestQueue, s = new or({
              userId: t.userId,
              channelUrl: this.url
            }), [4, n.send(s)];
          case 1:
            return r = i.sent(), a = r.as(ur).pushTriggerOption, this.myPushTriggerOption = a, [2, a];
        }
      });
    });
  }, c.prototype.setMyPushTriggerOption = function (e) {
    return p(this, void 0, void 0, function () {
      var t, n, s, a, i, o, u;
      return f(this, function (c) {
        switch (c.label) {
          case 0:
            return l(r(me, e)).throw(h.invalidParameters), t = P.of(this._iid), n = t.dispatcher, s = t.sdkState, a = t.requestQueue, i = new ar({
              userId: s.userId,
              channelUrl: this.url,
              pushTriggerOption: e
            }), [4, a.send(i)];
          case 1:
            return o = c.sent(), u = o.as(ir).pushTriggerOption, this.myPushTriggerOption = u, n.dispatch(new Cn({
              channels: [this],
              context: {
                source: y.EVENT_CHANNEL_UPDATED
              },
              isWebSocketEventComing: !0
            })), [2, u];
        }
      });
    });
  }, c.prototype.setMyCountPreference = function (e) {
    return p(this, void 0, void 0, function () {
      var t, n, s, a, i, o, u;
      return f(this, function (c) {
        switch (c.label) {
          case 0:
            return l(r(tr, e)).throw(h.invalidParameters), t = P.of(this._iid), n = t.dispatcher, s = t.sdkState, a = t.requestQueue, i = new Ws({
              channelUrl: this.url,
              userId: s.userId,
              countPreference: e
            }), [4, a.send(i)];
          case 1:
            return o = c.sent(), u = o.as(Qs).countPreference, this.myCountPreference = u, this._updateUnreadCount(this.unreadMessageCount, this.unreadMentionCount), n.dispatch(new Cn({
              channels: [this],
              context: {
                source: y.EVENT_CHANNEL_UPDATED
              },
              isWebSocketEventComing: !0
            })), [2, u];
        }
      });
    });
  }, c.prototype.resetMyHistory = function () {
    return p(this, void 0, void 0, function () {
      var e, t, n, s, r, a, i;
      return f(this, function (o) {
        switch (o.label) {
          case 0:
            return e = P.of(this._iid), t = e.dispatcher, n = e.requestQueue, s = e.sdkState, r = new Ys({
              channelUrl: this.url,
              locale: s.localeForChatbot
            }), [4, n.send(r)];
          case 1:
            return a = o.sent(), i = a.as(Ks).messageOffsetTimestamp, this.messageOffsetTimestamp = i, this.lastMessage && this.lastMessage.createdAt < i && (this.lastMessage = null), this._updateUnreadCount(0, 0), this.totalUnreadReplyCount = 0, t.dispatch(new Cn({
              channels: [this],
              context: {
                source: y.EVENT_CHANNEL_RESET_HISTORY
              },
              isWebSocketEventComing: !0
            })), [2, this];
        }
      });
    });
  }, c.prototype._uploadFileAndUpdateParams = function (e) {
    return p(this, void 0, void 0, function () {
      var t, n, s, r, a, i, o, u, c, l;
      return f(this, function (h) {
        switch (h.label) {
          case 0:
            return E(e.file) ? (t = P.of(this._iid).requestQueue, n = new St({
              file: e.file,
              channelUrl: this.url,
              thumbnailSizes: e.thumbnailSizes,
              requestId: this._generateRequestId()
            }), [4, t.send(n)]) : [3, 2];
          case 1:
            s = h.sent(), r = s.as(Ut), a = r.url, i = r.fileSize, o = void 0 === i ? e.fileSize : i, u = r.thumbnails, c = r.requireAuth, l = void 0 !== c && c, e.fileUrl = a, e.fileSize = o, e.requireAuth = l, e._thumbnails = u, h.label = 2;
          case 2:
            return [2];
        }
      });
    });
  }, c.prototype.resendMessage = function (e, t) {
    var n,
      s = this;
    if (l(e instanceof ze && !e.scheduledInfo && e.isResendable).throw(h.invalidParameters), e.isUserMessage()) {
      var r = null !== (n = e.messageParams) && void 0 !== n ? n : pt(e),
        a = new yt();
      return this._sendUserMessage(r, ft.RESEND, e.reqId).onPending(function (e) {
        a._trigger(e);
      }).onFailed(function (e, t) {
        a._triggerFailed(e, t);
      }).onSucceeded(function (e) {
        s._tryUpdateLastMessageAndCallEvents(s, e), a._trigger(e);
      }), a;
    }
    if (e.isFileMessage()) {
      var i = this._validateFailedFileMessageHasFile(e, t);
      l(i).throw(h.invalidParameters);
      r = vt(e, t);
      var o = new yt();
      return this._sendFileMessage(r, ft.RESEND, e.reqId).onPending(function (e) {
        o._trigger(e);
      }).onFailed(function (e, t) {
        o._triggerFailed(e, t);
      }).onSucceeded(function (e) {
        s._tryUpdateLastMessageAndCallEvents(s, e), o._trigger(e);
      }), o;
    }
    if (e.isMultipleFilesMessage()) {
      r = e.messageParams;
      var u = new ct();
      return this._sendMultipleFilesMessage(r, ft.RESEND, e.reqId).onPending(function (e) {
        u._trigger(e);
      }).onFailed(function (e, t) {
        u._triggerFailed(e, t);
      }).onSucceeded(function (e) {
        s._tryUpdateLastMessageAndCallEvents(s, e), u._trigger(e);
      }).onFileUploaded(function (e, t, n, s) {
        u._triggerOnFileUploaded(e, t, n, s);
      }), u;
    }
  }, c.prototype.copyMessage = function (e, t) {
    var n = this;
    if (l(e instanceof mt && t instanceof ze && t.sendingStatus === ae.SUCCEEDED && this.url === t.channelUrl && !t.scheduledInfo).throw(h.invalidParameters), t.isUserMessage()) {
      l(!t.poll).throw(h.notSupportedError);
      var s = gt(t),
        r = new yt();
      return e._sendUserMessage(s).onPending(function (e) {
        r._trigger(e);
      }).onFailed(function (e, t) {
        r._triggerFailed(e, t);
      }).onSucceeded(function (t) {
        e.isGroupChannel() && n._tryUpdateLastMessageAndCallEvents(e, t), r._trigger(t);
      }), r;
    }
    if (t.isFileMessage()) {
      s = Et(t);
      var a = new yt();
      return e._sendFileMessage(s).onPending(function (e) {
        a._trigger(e);
      }).onFailed(function (e, t) {
        a._triggerFailed(e, t);
      }).onSucceeded(function (t) {
        e.isGroupChannel() && n._tryUpdateLastMessageAndCallEvents(e, t), a._trigger(t);
      }), a;
    }
    if (t.isMultipleFilesMessage()) {
      if (e.isGroupChannel()) {
        s = Ct(t);
        var i = new ct();
        return e._sendMultipleFilesMessage(s, ft.COPY).onPending(function (e) {
          i._trigger(e);
        }).onFailed(function (e, t) {
          i._triggerFailed(e, t);
        }).onSucceeded(function (t) {
          n._tryUpdateLastMessageAndCallEvents(e, t), i._trigger(t);
        }).onFileUploaded(function (e, t, n, s) {
          i._triggerOnFileUploaded(e, t, n, s);
        }), i;
      }
      throw h.channelTypeNotSupportedError;
    }
  }, c;
}(mt);
export { vn as A, Pn as B, tr as C, sn as D, vs as E, gs as F, fs as G, Zt as H, Rs as I, nn as M, $t as O, Yt as P, en as Q, Cs as S, _s as T, Xt as U, an as a, cr as b, Jt as c, cs as d, ln as e, dn as f, _n as g, un as h, cn as i, gn as j, tn as k, nr as l, Ls as m, Hs as n, Qt as o, Ds as p, Kt as q, Fs as r, En as s, xs as t, Es as u, hn as v, ws as w, rn as x, kn as y, fn as z };