import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { RequestService, MenuConfigService } from '../../../shared/services';
import * as objectPath from 'object-path';
import { ActivatedRoute, NavigationEnd, NavigationError, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseHandlersService } from '../../services/firebase-handlers.service';
import { MatDialog } from '@angular/material/dialog';
// import { MsalService } from '@azure/msal-angular';
import { NotificationService } from '../../services/notification.service';
import { SendbirdService } from '../../services/sendbird.service';
import { trigger, state, style, AUTO_STYLE, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('collapse', [
      state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state('true', style({ height: '0', visibility: 'hidden' })),
      transition('false => true', animate(300 + 'ms ease-in')),
      transition('true => false', animate(300 + 'ms ease-out'))
    ])
  ]
})
export class SidebarComponent implements OnInit, AfterViewInit {
  private subscriptions: Subscription[] = [];
  public selectedUser: any;
  public menuConfigs: any[] = [];
  public currentRoute: string = '';
  public topNavHeight: any = undefined;
  public hasNew: boolean = false;
  public showSubmenu: boolean = false;
  public isAgent: boolean = false;
  public hasNewMessages: boolean = false;

  constructor(private requestService: RequestService, public router: Router, private menuConfigService: MenuConfigService, private translate: TranslateService, private firebaseHandler: FirebaseHandlersService, private activatedRoute: ActivatedRoute, public dialog: MatDialog, private changeDetectorRef: ChangeDetectorRef, private notificationService: NotificationService, private sendbirdService: SendbirdService) {
    this.menuConfigs = objectPath.get(this.menuConfigService.getMenus(), 'aside.items');

    router.events.subscribe((data: any) => {
      if (data?.routerEvent instanceof NavigationEnd ||
        data?.routerEvent instanceof NavigationError) {
        this.currentRoute = activatedRoute.snapshot['_routerState'].url;
        // console.log(`[${this.currentRoute}]`)

        if (this.currentRoute.includes('/community')) {
          this.showSubmenu = true;
          // this.notificationService.showChatUnRead.next(false);
        }

        this.clickingOutside();
      }
    });
  }

  ngOnInit() {
    this.subscriptions.push(
      this.menuConfigService.onConfigUpdated$.subscribe((data) => {
        if (data) {
          this.menuConfigs = objectPath.get(this.menuConfigService.getMenus(), 'aside.items');
        }
      })
    );

    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          this.isAgent = this.requestService.isUserAgent();
        }
      })
    );

    this.subscriptions.push(this.notificationService.showNotificationUnRead.subscribe((show: any) => {
      if (this.currentRoute !== '/notifications' || !show)
        this.hasNew = show;
    }));

    this.subscriptions.push(this.notificationService.showChatUnRead.subscribe((unread: any) => {
      this.hasNewMessages = unread?.length || false;
    }));

    this.hasNewMessages = !!this.sendbirdService.getUnreadChats().length;
  }

  ngAfterViewInit(): void {
    // this.calculateHeight();
    // setTimeout(() => this.calculateHeight(), 1000);
  }

  private calculateHeight() {
    // console.log(document.getElementById('top-nav'))
    if (document.getElementById('top-nav')) {
      // let height = document.getElementById('top-nav').clientHeight + 10;
      // console.log(height)
      // this.topNavHeight = `calc(100% - ${height}px)`;
      this.topNavHeight = 'calc(100% - 20px)';
      this.changeDetectorRef.detectChanges();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  public logout() {
    // this.adAuthService.logout();
    this.firebaseHandler.logout();
  }

  clickingOutside() {
    const dom: any = document.querySelector('.menu-mobile');
    if (dom)
      dom.classList.add('hide-mobile-menu');
  }

  toggleSidebar() {
    const dom: any = document.querySelector('.menu-mobile');
    if (dom)
      dom.classList.toggle('hide-mobile-menu');
  }

  toggleSubmenu() {
    this.showSubmenu = !this.showSubmenu;
  }
}
