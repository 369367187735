import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
// NGX Permissions
import { NgxPermissionsModule } from './shared/modules/ngx-permissions';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthGuard, RequestService, StoreService, LoggerService, GlobalErrorHandler, LoaderService } from './shared';
import { LayoutUtilsService, MenuConfigService, ResizeService, RollbarService, SubheaderService, rollbarFactory } from './shared/services';
import { UserDefaultsService } from './shared';
import { PageScopeService, UrlHelperService } from './shared/services';
import { SharedModule } from './shared/shared.module';
import { LayoutComponentModule } from './shared/layout.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { GoogleMapsModule } from "@angular/google-maps";
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { FirebaseHandlersService } from './shared/services/firebase-handlers.service';
import { DateLocaleProvider, MAT_DATE_LOCALE_PROVIDER } from './shared/providers';
import {
    MAT_MOMENT_DATE_FORMATS,
    MomentDateAdapter,
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { CustomPaginatorIntl } from './shared/providers/custom-paginator-intl';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
import { provideDatabase, getDatabase } from '@angular/fire/database';
import { FirebaseAuthService } from './shared/services/firebase-auth.service';
import { provideAuth, getAuth } from '@angular/fire/auth';
// import { MsalModule, MsalInterceptor, MsalService, MsalGuard } from '@azure/msal-angular';
// import { msalInstance, guardConfig, interceptorConfig } from '../app/auth-config';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ChartsService } from './shared/services/charts.service';

// AoT requires an exported function for factories
export const createTranslateLoader = (http: HttpClient) => {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

@NgModule({
    declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        // NgxGraphModule,
        GoogleMapsModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        LayoutModule,
        OverlayModule,
        SharedModule,
        LayoutComponentModule,
        NgApexchartsModule,
        ImageCropperModule,
        NgIdleKeepaliveModule.forRoot(),
        NgxPermissionsModule.forRoot(),
        // MsalModule.forRoot(msalInstance, guardConfig, interceptorConfig),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        })], providers: [
            AuthGuard,
            RequestService,
            LoaderService,
            StoreService,
            LayoutUtilsService,
            MenuConfigService,
            SubheaderService,
            ChartsService,
            LoggerService,
            UserDefaultsService,
            PageScopeService,
            UrlHelperService,
            { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl },
            {
                provide: ErrorHandler,
                useClass: GlobalErrorHandler
            },
            FirebaseHandlersService,
            // { provide: MAT_DATE_LOCALE, useValue: 'en' },
            DateLocaleProvider, MAT_DATE_LOCALE_PROVIDER,
            {
                provide: DateAdapter,
                useClass: MomentDateAdapter,
                deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
            },
            { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
            { provide: RollbarService, useFactory: rollbarFactory },
            FirebaseAuthService,
            ResizeService,
            // {
            //     provide: HTTP_INTERCEPTORS,
            //     useClass: MsalInterceptor,
            //     multi: true,
            // },
            // MsalService,
            // MsalGuard,
            provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
            provideAuth(() => getAuth()),
            provideFirestore(() => getFirestore()),
            provideStorage(() => getStorage()),
            provideMessaging(() => getMessaging()),
            provideDatabase(() => getDatabase()),
            provideHttpClient(withInterceptorsFromDi()),
        ]
})
export class AppModule { }
