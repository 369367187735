import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { RequestService, StoreService, LoggerService, LoaderService, MenuConfigService, LayoutUtilsService } from './shared/services';
import { MenuConfig } from './menu.config';
import { environment } from '../environments/environment';
import { interval } from "rxjs";
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { FirebaseHandlersService } from './shared/services/firebase-handlers.service';
import { FirebaseAuthService } from './shared/services/firebase-auth.service';
import { NotificationService } from './shared/services/notification.service';
import { SendbirdService } from './shared/services/sendbird.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public subscriptions: any[] = <any>[];
  public showLoader: boolean = false;
  public showError: boolean = true;
  public languageSelected: string = environment.lang;
  private repeatIntervalSeconds: number = 60;
  private dataSeenOnce: boolean = false;
  private messageSeenOnce: boolean = false;
  private idleState = 'Not started.';
  private activityDialog: any = undefined;
  private aliveAppIntervalMinutes: number = environment.aliveAppIntervalMinutes;
  private aliveCheckAppIntervalMinutes: number = environment.aliveCheckAppIntervalMinutes;
  private message;
  protected networkDialog: any = undefined;

  constructor(private menuConfigService: MenuConfigService, private loaderService: LoaderService,
    public snackBar: MatSnackBar, private translate: TranslateService, private requestService: RequestService,
    private logger: LoggerService, private router: Router, private dialog: MatDialog, private layoutUtilsService: LayoutUtilsService,
    private idle: Idle, private firebaseService: FirebaseHandlersService,
    private storeService: StoreService, private activatedRoute: ActivatedRoute, private route: ActivatedRoute, private firebaseAuthService: FirebaseAuthService, private notificationService: NotificationService, private sendbirdService: SendbirdService) {
    if (localStorage.getItem('languageSelected')) {
      this.languageSelected = localStorage.getItem('languageSelected');
    } else {
      localStorage.setItem('languageSelected', environment.lang)
    }
    this.setLanguage();
    // this.getMe();
    this.setIdleIntervals();
  }

  ngOnInit() {

    this.subscriptions.push(
      this.requestService.authenticatedUser.subscribe((event: boolean) => {
        // console.log('authenticatedUser', event);
        if (event) {
          // let rememberMe = false;
          // if (localStorage.getItem('rememberMe')) {
          //   rememberMe = JSON.parse(localStorage.getItem('rememberMe') || '');
          // }
          // if (!rememberMe) {
          //   this.resetActivity();
          // } else {
          //   this.idle.stop();
          //   console.log('Idle Activity Cancelled');
          // }
          // console.log('authenticatedUser' ,event);
          // console.log('this.dataSeenOnce' ,this.dataSeenOnce);
          // this.resetActivity();
          // if (!this.dataSeenOnce) {
          //   this.getMe();
          // }
        } else {
          this.idle.stop();
        }
      }
      ));
    // this.subscriptions.push(
    //   this.firebaseAuthService.user$.subscribe((status) => {
    //     console.log('status', status);
    //   }));

    // this.subscriptions.push(interval(1000 * this.repeatIntervalSeconds).subscribe(() => {
    //   if (this.requestService.authenticatedUser.getValue()) {
    //     if (!this.requestService.validateMe()) {
    //       // window.location.reload();
    //     }
    //   }
    // }));

    this.subscriptions.push(
      this.requestService.appStatusSubject.subscribe((data: any) => {
        if (data) {
          if (data === 'login') {
            this.firebaseService.logout(true);
          }
        }
      })
    );
    this.subscriptions.push(
      this.router.events.subscribe((event: any) => {

        if (event instanceof NavigationStart) {
          // Show loading indicator
          this.loaderService.display(false);
        }

        if (event instanceof NavigationEnd) {
          // Hide loading indicator
        }

        if (event instanceof NavigationError) {
          // Hide loading indicator

          // Present error to user
          console.log('NavigationError:', event.error);
        }
      })
    );
    this.subscriptions.push(
      this.logger.errorObject.subscribe((error) => {
        if (error) {
          // console.log('Global Error: ', error);
          // this.loaderService.display(false);
          if (this.showError) {
            this.showError = false;
            this.openAlert('An Error occured:' + error);
          }
        }
      })
    );
    this.menuConfigService.loadConfigs(new MenuConfig().configs);

    this.subscribeActivity();
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
  setLanguage() {
    this.translate.setDefaultLang(this.languageSelected);
    this.translate.use(this.languageSelected);
  }
  getQueryParam(param) {
    var result = window.location.href.match(
      new RegExp("(\\?|&)" + param + "(\\[\\])?=([^&]*)")
    );

    return result ? result[3] : false;
  }
  public getMe() {
    // let urlToken = this.getQueryParam('token');//this.route.snapshot.queryParams['token'] || '';
    if (localStorage.getItem('currentUser') && localStorage.getItem('o') && localStorage.getItem('a') && localStorage.getItem('l')) {
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      let orgId = JSON.parse(localStorage.getItem('o'));
      let appId = JSON.parse(localStorage.getItem('a'));
      let locId = JSON.parse(localStorage.getItem('l'));
      let orgData = JSON.parse(localStorage.getItem('org'));
      this.requestService.orgId = orgId;
      this.requestService.appId = appId;
      this.requestService.locId = locId;
      this.requestService.pageOrganization.next(orgData);
      this.requestService.currentUser = currentUser;
      let resource = this.requestService.getItemFromListContains(currentUser.resources, orgId, 'organizationId');
      if (resource) {
        this.requestService.updatePermissions(resource._id);
        this.dataSeenOnce = true;
        this.validateMeByApi();
      }
    } else {
      this.dataSeenOnce = false;
    };
  }
  public validateMeByApi() {
    this.requestService.getMe((data, error) => {
      if (error) {
        // this.requestService.logout();
        //console.log(error);
      }
      if (data) {
        this.notificationService.dnd.next(data.dnd);
        sessionStorage.setItem('live', JSON.stringify(true));
        this.requestService.authenticatedUser.next(true);
        // valid
      } else {
        this.dataSeenOnce = false;
        // this.requestService.logout();
      }
    });
  }
  public openAlert(message, title = 'Error') {
    const _title: string = title;
    const _description: string = message;

    const dialogRef = this.layoutUtilsService.errorElement(_title, _description);
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      window.location.reload();
    });
  }

  setIdleIntervals() {
    if (localStorage.getItem('idleInterval') && localStorage.getItem('timeoutInterval')) {
      this.aliveCheckAppIntervalMinutes = JSON.parse(localStorage.getItem('idleInterval'));
      this.aliveAppIntervalMinutes = JSON.parse(localStorage.getItem('timeoutInterval'));
    } else {
      localStorage.setItem('idleInterval', JSON.stringify(this.aliveCheckAppIntervalMinutes));
      localStorage.setItem('timeoutInterval', JSON.stringify(this.aliveAppIntervalMinutes))
    }
  }

  resetActivity() {
    if (window.location.pathname.indexOf('/mobile/') == -1) {
      // console.log(window.location.pathname);
      this.idle.watch();
      this.idleState = 'Running.';
      console.log('Idle Activity', this.idleState);
      // }
    }
  }

  subscribeActivity() {
    console.log('Idle Activity initialized');
    this.idle.setIdle(this.aliveCheckAppIntervalMinutes * 60);
    this.idle.setTimeout(this.aliveAppIntervalMinutes * 60);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.subscriptions.push(
      this.idle.onIdleEnd.subscribe(() => {
        this.idleState = 'No longer idle.'
        console.log(this.idleState);
        this.resetActivity();
      })
    );
    this.subscriptions.push(
      this.idle.onTimeout.subscribe(() => {
        this.idleState = 'Timed out!';
        console.log(this.idleState);
        this.dataSeenOnce = false;
        this.activityDialog = undefined;
        // this.requestService.logout();
        this.firebaseService.logout();
      })
    );
    this.subscriptions.push(
      this.idle.onIdleStart.subscribe(() => {
        this.idleState = 'You\'ve gone idle!'
        console.log(this.idleState);
        this.noActivity(this.translate.instant('No Activity'), this.translate.instant('You have been idle for a long time. Please log in again to continue'));
      })
    );
    this.subscriptions.push(
      this.idle.onTimeoutWarning.subscribe((countdown) => {
        this.idleState = 'You will time out in ' + countdown + ' seconds!'
        console.log(this.idleState);
      })
    );
  }

  noActivity(title: string, msg: string) {
    if (!this.activityDialog) {
      let alertSetting = {};
      alertSetting['overlayClickToClose'] = false;
      alertSetting['showCloseButton'] = false;
      alertSetting['confirmText'] = this.translate.instant('Ok');
      // alertSetting['confirmText'] = this.translate.instant('Yes');
      // alertSetting['declineText'] = this.translate.instant('No');
      // alertSetting['timerEvent'] = 120;

      this.activityDialog = this.layoutUtilsService.alertActionElement(title, msg, alertSetting);
      this.activityDialog.afterClosed().subscribe(res => {
        this.dataSeenOnce = false;
        this.activityDialog = undefined;
        // this.requestService.logout();
        this.firebaseService.logout();
      });
    }
  };

  @HostListener('window:offline', ['$event'])
  OfflineEvent(event: Event) {
    if (!navigator?.onLine) {
      this.sendbirdService.getSendbird().setBackgroundState();
    }
    else {
      this.sendbirdService.getSendbird().setForegroundState();
    }

    setTimeout(() => {
      this.requestService.isNetworkConnected = navigator?.onLine;
      if (!navigator?.onLine) {
        this.networkDialog = this.showNetworkDisconnectedDialog();
      }
      else {
        this.networkDialog = undefined;
      }
    }, 2000);
  }

  @HostListener('window:online', ['$event'])
  OnlineEvent(event: Event) {
    this.requestService.isNetworkConnected = true;
    if (this.networkDialog) {
      window.location.reload();
    }
  }

  private showNetworkDisconnectedDialog() {
    if (this.networkDialog)
      this.networkDialog.close();

    this.networkDialog = this.layoutUtilsService.alertActionElement('', this.translate.instant('Network Disconnected'), {
      overlayClickToClose: false,
      showCloseButton: false,
      confirmText: this.translate.instant('Refresh')
    });
    this.networkDialog.afterClosed().subscribe(res => {
      if (res) {
        if (res.action === 'confirmText') {
          window.location.reload();
        }
      }
    });
    return this.networkDialog;
  }
}
