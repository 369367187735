import { c as t, aO as e, _ as r, a$ as a, b as n, g as o, b1 as i, w as s, b2 as l, s as u, b3 as c, b4 as p, b5 as d, b6 as h } from "./lib/__bundle-2f7467b8.js";
export { as as Poll, x as PollListQuery, b7 as PollOption, b8 as PollStatus, aJ as PollVoteEvent, y as PollVoterListQuery } from "./lib/__bundle-2f7467b8.js";
export { P as PollUpdateEvent } from "./lib/__bundle-acd77193.js";
var b = function (a) {
  function b() {
    var t = null !== a && a.apply(this, arguments) || this;
    return t.name = "poll", t;
  }
  return t(b, a), b.prototype.init = function (t, r) {
    var n = r.sdkState,
      o = r.dispatcher,
      i = r.sessionManager,
      s = r.requestQueue,
      l = r.logger,
      u = r.onlineDetector,
      c = r.cacheContext;
    a.prototype.init.call(this, t, {
      sdkState: n,
      dispatcher: o,
      sessionManager: i,
      requestQueue: s,
      logger: l,
      onlineDetector: u,
      cacheContext: c
    }), this._manager = new e(t, {
      sdkState: n,
      dispatcher: o,
      sessionManager: i,
      requestQueue: s,
      logger: l,
      onlineDetector: u,
      cacheContext: c
    });
  }, b.prototype.create = function (t) {
    return r(this, void 0, void 0, function () {
      var e;
      return n(this, function (r) {
        return e = o(o({}, i), t), s(l(e)).throw(u.invalidParameters), [2, this._manager.create(e)];
      });
    });
  }, b.prototype.get = function (t) {
    return r(this, void 0, void 0, function () {
      var e;
      return n(this, function (r) {
        return e = o(o({}, c), t), s(p(e)).throw(u.invalidParameters), [2, this._manager.get(e)];
      });
    });
  }, b.prototype.getOption = function (t) {
    return r(this, void 0, void 0, function () {
      var e;
      return n(this, function (r) {
        return e = o(o({}, d), t), s(h(e)).throw(u.invalidParameters), [2, this._manager.getOption(e)];
      });
    });
  }, b.prototype.buildPollFromSerializedData = function (t) {
    return this._manager.buildPollFromSerializedData(t);
  }, b;
}(a);
export { b as PollModule };