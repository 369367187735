import { i as e, a as t, _ as i, b as a, C as u, c, E as d, A as l, d as h, e as f, u as p, f as v, U as g, g as m, h as b, P as _, j as w, k, l as I, m as A, S as M, L as N, n as Q, o as j, V as q, p as x, q as K, v as W, r as V, s as Z, t as X, w as ee, x as te, y as ne, z as re, B as ie, D as se, F as oe, W as ue, G as ce, H as de, I as le, J as he, K as fe, M as pe, N as ve, O as ge, Q as me, R as be, T as _e, X as ye, Y as we, Z as ke, $ as Se, a0 as Ie } from "./lib/__bundle-2f7467b8.js";
export { a1 as AppInfo, a3 as CachedChannelInfo, a4 as CachedDataClearOrder, C as ChannelType, a2 as CollectionEventSource, r as ConnectionState, Y as DeviceOsPlatform, a5 as LastMessageThreadingPolicy, L as LocalCacheConfig, a6 as NotificationInfo, a0 as PushTemplate, T as PushTokenRegistrationState, P as PushTokenType, $ as PushTriggerOption, a7 as ReportCategoryInfo, a8 as Role, S as SendbirdChatOptions, s as SendbirdError, a9 as SendbirdErrorCode, ae as SendbirdPlatform, ad as SendbirdProduct, aa as UIKitConfigInfo, ab as UnreadCountThreadingPolicy, U as User, ac as UserOnlineState } from "./lib/__bundle-2f7467b8.js";
import { N as Ce, a as Ee, b as Te, c as Pe, g as De, M as Ue, d as Oe, e as Ae, f as Me, h as Ne, i as Le, j as Re, k as ze, l as Qe, m as je, L as Fe, n as qe, o as He, p as xe, A as Ke, I as Ge, q as We, U as Ve, C as Be, r as Je, B as Ye, F as Ze, s as $e, S as Xe, t as et, u as tt, v as nt, w as rt, x as it } from "./lib/__bundle-d4f5ef6d.js";
export { r as ApplicationUserListQuery, y as BannedUserListQuery, z as BaseChannel, B as BlockedUserListQuery, C as ConnectionHandler, F as FriendListQuery, n as LogLevel, o as MemoryStore, D as MutedUserListQuery, O as OperatorListQuery, P as Participant, E as Plugin, R as ReportCategory, G as RestrictedUser, H as RestrictionInfo, J as RestrictionType, S as SessionHandler, U as UserEventHandler } from "./lib/__bundle-d4f5ef6d.js";
import { PollModule as st } from "./poll.js";
import { g as ot, G as at, A as ut, U as ct, a as dt, b as lt, D as ht, R as ft } from "./lib/__bundle-04894092.js";
import { g as pt, N as vt } from "./lib/__bundle-27dc6a30.js";
import "./lib/__bundle-acd77193.js";
var gt = function (e) {
    this.key = e.key, this.url = e.url;
  },
  mt = function (e) {
    this.id = e.id, this.name = e.name, this.url = e.url, this.emojis = e.emojis ? e.emojis.map(function (e) {
      return new gt(e);
    }) : [];
  },
  bt = function (e) {
    this.emojiHash = e.emoji_hash || "", this.emojiCategories = e.emoji_categories ? e.emoji_categories.map(function (e) {
      return new mt(e);
    }) : [];
  },
  _t = {
    profileImage: void 0,
    profileUrl: void 0,
    nickname: void 0
  },
  yt = function (e) {
    return function (t, n) {
      return i(void 0, void 0, void 0, function () {
        var r, s, o, c;
        return a(this, function (d) {
          switch (d.label) {
            case 0:
              switch (t) {
                case 1:
                case 2:
                case 3:
                case 4:
                  return [3, 1];
                case 5:
                  return [3, 2];
                case 6:
                  return [3, 4];
              }
              return [3, 11];
            case 1:
              return n(), [3, 12];
            case 2:
              return [4, e.reset()];
            case 3:
              return d.sent(), n(), [3, 12];
            case 4:
              return d.trys.push([4, 9,, 10]), r = function (e) {
                return {
                  v2: function () {
                    return i(void 0, void 0, void 0, function () {
                      return a(this, function (t) {
                        switch (t.label) {
                          case 0:
                            return [4, e.commitSchema([{
                              collectionName: Ee,
                              keyName: Te,
                              index: [ot(at.LATEST_LAST_MESSAGE), ot(at.CHRONOLOGICAL), ot(at.CHANNEL_NAME_ALPHABETICAL)]
                            }, {
                              collectionName: Ce,
                              keyName: Pe,
                              index: [De(Ue.CHANNEL_LATEST), De(Ue.NEWEST_CHILD_MESSAGE)]
                            }, {
                              collectionName: Oe,
                              keyName: Ae,
                              index: [De(Ue.CHANNEL_LATEST), De(Ue.NEWEST_CHILD_MESSAGE)]
                            }, {
                              collectionName: Me,
                              keyName: Ne
                            }])];
                          case 1:
                            return [2, t.sent()];
                        }
                      });
                    });
                  },
                  v3: function () {
                    return i(void 0, void 0, void 0, function () {
                      return a(this, function (t) {
                        switch (t.label) {
                          case 0:
                            return [4, e.commitSchema([{
                              collectionName: Le,
                              keyName: Re,
                              index: [pt("latest_last_message")]
                            }])];
                          case 1:
                            return [2, t.sent()];
                        }
                      });
                    });
                  },
                  v4: function () {
                    return i(void 0, void 0, void 0, function () {
                      return a(this, function (t) {
                        switch (t.label) {
                          case 0:
                            return [4, e.commitSchema([{
                              collectionName: ze,
                              keyName: Qe,
                              index: [je()]
                            }])];
                          case 1:
                            return [2, t.sent()];
                        }
                      });
                    });
                  }
                };
              }(e), s = r.v2, o = r.v3, c = r.v4, [4, s()];
            case 5:
              return d.sent(), [4, o()];
            case 6:
              return d.sent(), [4, c()];
            case 7:
              return d.sent(), [4, e.collection(Ce).removeIf({
                where: {
                  channelType: u.OPEN
                }
              })];
            case 8:
            case 9:
              return d.sent(), [3, 10];
            case 10:
              return n(), [3, 12];
            case 11:
              n(), d.label = 12;
            case 12:
              return [2];
          }
        });
      });
    };
  },
  wt = function () {
    return "undefined" != typeof document ? document.visibilityState : "visible";
  },
  kt = function (e) {
    function t(t) {
      var n = t.getVisibilityState,
        r = void 0 === n ? wt : n,
        i = t.initialState,
        s = void 0 === i ? "visible" : i,
        o = t.isEnabled,
        a = void 0 === o || o,
        u = t.pauseCheckDelay,
        c = void 0 === u ? 3e4 : u,
        d = e.call(this) || this;
      return d._pauseCheckDelay = 3e4, d._currentState = "visible", d._getVisibilityState = r, d._pauseCheckDelay = c, d._currentState = s, d._isEnabled = a, d._boundToggleState = d._toggleState.bind(d), d;
    }
    return c(t, e), Object.defineProperty(t.prototype, "currentState", {
      get: function () {
        return this._currentState;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t.prototype, "isAvailable", {
      get: function () {
        return "undefined" != typeof window && !!window.addEventListener && !!window.removeEventListener;
      },
      enumerable: !1,
      configurable: !0
    }), t.prototype._toggleState = function () {
      var e = this,
        t = this._getVisibilityState();
      "hidden" !== t ? "hidden" === this._currentState && (this._pauseDelayTimer && (clearTimeout(this._pauseDelayTimer), this._pauseDelayTimer = void 0), this.dispatch("resume")) : "hidden" !== this._currentState && (this._pauseDelayTimer = setTimeout(function () {
        e._pauseDelayTimer = void 0, e.dispatch("pause");
      }, this._pauseCheckDelay)), this._currentState = t;
    }, t.prototype.start = function () {
      this._isEnabled && this.isAvailable && (this._boundToggleState = this._toggleState.bind(this), window.addEventListener("visibilitychange", this._boundToggleState, {
        capture: !0
      }));
    }, t.prototype.stop = function () {
      this._isEnabled && this.isAvailable && this._boundToggleState && window.removeEventListener("visibilitychange", this._boundToggleState, {
        capture: !0
      });
    }, t;
  }(d),
  St = function () {
    function e(e) {
      var t = e.logger,
        n = e.connectionDelegate,
        r = void 0 === n ? null : n,
        i = this;
      this._onlineWorker = function () {
        var e;
        return null === (e = i.connectionDelegate) || void 0 === e ? void 0 : e.reconnect();
      }, this._offlineWorker = function () {
        var e;
        return null === (e = i.connectionDelegate) || void 0 === e ? void 0 : e.disconnect();
      }, this.unsubscribes = [], this._onlineListener = function (e) {
        if (i.isAvailableOnWeb) return window.addEventListener("online", e), function () {
          return window.removeEventListener("online", e, !1);
        };
      }, this._offlineListener = function (e) {
        if (i.isAvailableOnWeb) return window.addEventListener("offline", e), function () {
          return window.removeEventListener("offline", e, !1);
        };
      }, this.logger = t, r && (this.connectionDelegate = r);
    }
    return Object.defineProperty(e.prototype, "isAvailableOnWeb", {
      get: function () {
        return "undefined" != typeof window && !!window.addEventListener && "ononline" in window && "onoffline" in window && "undefined" != typeof navigator && void 0 !== navigator.onLine;
      },
      enumerable: !1,
      configurable: !0
    }), e.prototype.setOnlineListener = function (e) {
      Boolean(e) && "function" == typeof e && (this._onlineListener = e);
    }, e.prototype.setOfflineListener = function (e) {
      Boolean(e) && "function" == typeof e && (this._offlineListener = e);
    }, e.prototype.start = function () {
      this.unsubscribes = [this._onlineListener(this._onlineWorker), this._offlineListener(this._offlineWorker)];
    }, e.prototype.stop = function () {
      var e = this;
      this.unsubscribes.forEach(function (t) {
        try {
          null == t || t();
        } catch (t) {
          e.logger.warn(t.message);
        }
      }), this.unsubscribes = [];
    }, e.prototype.isOnline = function () {
      return i(this, void 0, void 0, function () {
        return a(this, function (e) {
          return this.isAvailableOnWeb ? [2, navigator.onLine] : [2, new Promise(function (e) {
            fetch("https://www.google.com", {
              method: l.GET,
              mode: "no-cors"
            }).then(function () {
              return e(!0);
            }).catch(function () {
              return e(!1);
            });
          })];
        });
      });
    }, e;
  }(),
  It = function (e) {
    function t(t) {
      var n = t.userId,
        r = t.nickname,
        i = t.profileUrl,
        s = t.profileImage,
        o = t.preferredLanguages,
        a = e.call(this) || this;
      return a.method = l.PUT, a.path = "".concat(h, "/").concat(encodeURIComponent(n)), a.params = f(p({
        nickname: r,
        profile_url: i,
        profile_file: s,
        preferred_languages: o
      })), a;
    }
    return c(t, e), t;
  }(v),
  Ct = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.user = new g(t, m({}, n)), r;
    }
    return c(t, e), t;
  }(b),
  Et = function (e) {
    function t(t) {
      var n = t.userId,
        r = t.token,
        i = t.deviceOS,
        s = t.deviceManufacturer,
        o = t.systemPushEnabled,
        a = e.call(this) || this;
      return a.method = l.POST, a.path = "".concat(h, "/").concat(encodeURIComponent(n), "/push/apns"), a.params = f({
        apns_device_token: r,
        always_push: !0,
        device_os: i,
        device_manufacturer: s,
        system_push_enabled: o
      }), a;
    }
    return c(t, e), t;
  }(v);
!function (e) {
  function t(t, n) {
    var r = e.call(this, t, n) || this;
    return r.token = n.token, r.type = n.type ? _[n.type.toLowerCase()] : _.UNKNOWN, r.user = new g(t, n.user), r;
  }
  c(t, e);
}(b);
var Tt = function (e) {
  function t(t) {
    var n = t.userId,
      r = t.token,
      i = t.deviceOS,
      s = t.deviceManufacturer,
      o = t.systemPushEnabled,
      a = e.call(this) || this;
    return a.method = l.POST, a.path = "".concat(h, "/").concat(encodeURIComponent(n), "/push/gcm"), a.params = f({
      gcm_reg_token: r,
      always_push: !0,
      device_os: i,
      device_manufacturer: s,
      system_push_enabled: o
    }), a;
  }
  return c(t, e), t;
}(v);
!function (e) {
  function t(t, n) {
    var r = e.call(this, t, n) || this;
    return r.token = n.token, r.type = n.type ? _[n.type.toLowerCase()] : _.UNKNOWN, r.user = new g(t, n.user), r;
  }
  c(t, e);
}(b);
var Pt = function (e) {
  function t(t) {
    var n = t.userId,
      r = t.token,
      i = e.call(this) || this;
    return i.method = l.DELETE, i.path = "".concat(h, "/").concat(encodeURIComponent(n), "/push/apns/").concat(encodeURIComponent(r)), i;
  }
  return c(t, e), t;
}(v);
!function (e) {
  function t(t, n) {
    var r = e.call(this, t, n) || this;
    return r.token = n.token, r.user = new g(t, n.user), r.lastDeletedAt = n.device_token_last_deleted_at, r;
  }
  c(t, e);
}(b);
var Dt = function (e) {
  function t(t) {
    var n = t.userId,
      r = t.token,
      i = e.call(this) || this;
    return i.method = l.DELETE, i.path = "".concat(h, "/").concat(encodeURIComponent(n), "/push/gcm/").concat(encodeURIComponent(r)), i;
  }
  return c(t, e), t;
}(v);
!function (e) {
  function t(t, n) {
    var r = e.call(this, t, n) || this;
    return r.token = n.token, r.user = new g(t, n.user), r.lastDeletedAt = n.device_token_last_deleted_at, r;
  }
  c(t, e);
}(b);
var Ut = function (e) {
  function t(t) {
    var n = t.userId,
      r = e.call(this) || this;
    return r.method = l.DELETE, r.path = "".concat(h, "/").concat(encodeURIComponent(n), "/push/apns"), r;
  }
  return c(t, e), t;
}(v);
!function (e) {
  function t(t, n) {
    var r = e.call(this, t, n) || this;
    return r.type = n.type ? _[n.type.toLowerCase()] : _.UNKNOWN, r.user = new g(t, n.user), r.lastDeletedAt = n.device_token_last_deleted_at, r;
  }
  c(t, e);
}(b);
var Ot = function (e) {
  function t(t) {
    var n = t.userId,
      r = e.call(this) || this;
    return r.method = l.DELETE, r.path = "".concat(h, "/").concat(encodeURIComponent(n), "/push/gcm"), r;
  }
  return c(t, e), t;
}(v);
!function (e) {
  function t(t, n) {
    var r = e.call(this, t, n) || this;
    return r.type = n.type ? _[n.type.toLowerCase()] : _.UNKNOWN, r.user = new g(t, n.user), r.lastDeletedAt = n.device_token_last_deleted_at, r;
  }
  c(t, e);
}(b);
var At = function (e) {
    function t(t) {
      var n = t.userId,
        r = t.type,
        i = t.token,
        s = t.ts,
        o = e.call(this) || this;
      return o.method = l.GET, o.path = "".concat(h, "/").concat(encodeURIComponent(n), "/push/").concat(encodeURIComponent(r), "/device_tokens"), o.params = {
        created_ts: s,
        token: i
      }, o;
    }
    return c(t, e), t;
  }(v),
  Mt = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.pushTokens = {
        type: n.type ? _[n.type.toLowerCase()] : _.UNKNOWN,
        deviceTokens: n.device_tokens,
        hasMore: n.has_more,
        token: n.token
      }, r;
    }
    return c(t, e), t;
  }(b),
  Nt = function (e) {
    function t(t) {
      var n = t.userId,
        r = e.call(this) || this;
      return r.method = l.GET, r.path = "".concat(h, "/").concat(encodeURIComponent(n), "/channel_invitation_preference"), r;
    }
    return c(t, e), t;
  }(v),
  Lt = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.autoAccept = n.auto_accept, r;
    }
    return c(t, e), t;
  }(b),
  Rt = function (e) {
    function t(t) {
      var n = t.userId,
        r = t.willAutoAccept,
        i = e.call(this) || this;
      return i.method = l.PUT, i.path = "".concat(h, "/").concat(encodeURIComponent(n), "/channel_invitation_preference"), i.params = {
        auto_accept: r
      }, i;
    }
    return c(t, e), t;
  }(v),
  zt = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.autoAccept = n.auto_accept, r;
    }
    return c(t, e), t;
  }(b),
  Qt = function (e) {
    function t(t) {
      var n = t.userId,
        r = e.call(this) || this;
      return r.method = l.GET, r.path = "".concat(h, "/").concat(encodeURIComponent(n), "/push_preference"), r;
    }
    return c(t, e), t;
  }(v),
  jt = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.preference = {
        doNotDisturbOn: n.do_not_disturb,
        startHour: n.start_hour,
        startMin: n.start_min,
        endHour: n.end_hour,
        endMin: n.end_min,
        timezone: n.timezone
      }, r;
    }
    return c(t, e), t;
  }(b),
  Ft = function (e) {
    function t(t) {
      var n = t.userId,
        r = t.doNotDisturbOn,
        i = t.startHour,
        s = t.startMin,
        o = t.endHour,
        a = t.endMin,
        u = t.timezone,
        c = e.call(this) || this;
      return c.method = l.PUT, c.path = "".concat(h, "/").concat(encodeURIComponent(n), "/push_preference"), c.params = {
        do_not_disturb: r,
        start_hour: i,
        start_min: s,
        end_hour: o,
        end_min: a,
        timezone: u
      }, c;
    }
    return c(t, e), t;
  }(v),
  qt = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.preference = {
        doNotDisturbOn: n.do_not_disturb,
        startHour: n.start_hour,
        startMin: n.start_min,
        endHour: n.end_hour,
        endMin: n.end_min,
        timezone: n.timezone
      }, r;
    }
    return c(t, e), t;
  }(b),
  Ht = function (e) {
    function t(t) {
      var n = t.userId,
        r = e.call(this) || this;
      return r.method = l.GET, r.path = "".concat(h, "/").concat(encodeURIComponent(n), "/push_preference"), r;
    }
    return c(t, e), t;
  }(v),
  xt = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.snoozePeriod = {
        isSnoozeOn: n.snooze_enabled
      }, "number" == typeof n.snooze_start_ts && (r.snoozePeriod.startTs = n.snooze_start_ts), "number" == typeof n.snooze_end_ts && (r.snoozePeriod.endTs = n.snooze_end_ts), r;
    }
    return c(t, e), t;
  }(b),
  Kt = function (e) {
    function t(t) {
      var n = t.userId,
        r = t.snoozeOn,
        i = t.startTs,
        s = t.endTs,
        o = e.call(this) || this;
      return o.method = l.PUT, o.path = "".concat(h, "/").concat(encodeURIComponent(n), "/push_preference"), o.params = {
        snooze_enabled: r,
        snooze_start_ts: i,
        snooze_end_ts: s
      }, o;
    }
    return c(t, e), t;
  }(v),
  Gt = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.snoozePeriod = {
        isSnoozeOn: n.snooze_enabled
      }, "number" == typeof n.snooze_start_ts && (r.snoozePeriod.startTs = n.snooze_start_ts), "number" == typeof n.snooze_end_ts && (r.snoozePeriod.endTs = n.snooze_end_ts), r;
    }
    return c(t, e), t;
  }(b),
  Wt = function (e) {
    function t(t) {
      var n = t.userId,
        r = t.blockedUserId,
        i = e.call(this) || this;
      return i.method = l.POST, i.path = "".concat(h, "/").concat(encodeURIComponent(n), "/block"), i.params = {
        target_id: r
      }, i;
    }
    return c(t, e), t;
  }(v);
!function (e) {
  function t(t, n) {
    var r = e.call(this, t, n) || this;
    return r.user = new g(t, n), r;
  }
  c(t, e);
}(b);
var Vt = function (e) {
  function t(t) {
    var n = t.userId,
      r = t.unblockedUserId,
      i = e.call(this) || this;
    return i.method = l.DELETE, i.path = "".concat(h, "/").concat(encodeURIComponent(n), "/block/").concat(encodeURIComponent(r)), i;
  }
  return c(t, e), t;
}(v);
!function (e) {
  function t() {
    return null !== e && e.apply(this, arguments) || this;
  }
  c(t, e);
}(b);
var Bt = function (e) {
    function t(t) {
      var n = t.userId,
        r = e.call(this) || this;
      return r.method = l.GET, r.path = "".concat(h, "/").concat(encodeURIComponent(n), "/push_preference"), r;
    }
    return c(t, e), t;
  }(v),
  Jt = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.pushTriggerOption = n.push_trigger_option, r;
    }
    return c(t, e), t;
  }(b),
  Yt = function (e) {
    function t(t) {
      var n = t.userId,
        r = t.pushTriggerOption,
        i = e.call(this) || this;
      return i.method = l.PUT, i.path = "".concat(h, "/").concat(encodeURIComponent(n), "/push_preference"), i.params = {
        push_trigger_option: r
      }, i;
    }
    return c(t, e), t;
  }(v),
  Zt = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.pushTriggerOption = n.push_trigger_option, r;
    }
    return c(t, e), t;
  }(b),
  $t = function (e) {
    function t(t) {
      var n = t.userId,
        r = e.call(this) || this;
      return r.method = l.GET, r.path = "".concat(h, "/").concat(encodeURIComponent(n), "/push/template"), r;
    }
    return c(t, e), t;
  }(v),
  Xt = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.name = n.name, r;
    }
    return c(t, e), t;
  }(b),
  en = function (e) {
    function t(t) {
      var n = t.userId,
        r = t.templateName,
        i = e.call(this) || this;
      return i.method = l.PUT, i.path = "".concat(h, "/").concat(encodeURIComponent(n), "/push/template"), i.params = {
        name: r
      }, i;
    }
    return c(t, e), t;
  }(v),
  tn = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.name = n.name, r;
    }
    return c(t, e), t;
  }(b),
  nn = function (e) {
    function t(t) {
      var n = t.userId,
        r = t.token,
        i = e.call(this) || this;
      return i.method = l.GET, i.path = "".concat(h, "/").concat(encodeURIComponent(n), "/friends/changelogs"), i.params = {
        token: r
      }, i;
    }
    return c(t, e), t;
  }(v),
  rn = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.changelogs = {
        addedUsers: n.added.map(function (e) {
          return new g(t, e);
        }),
        updatedUsers: n.updated.map(function (e) {
          return new g(t, e);
        }),
        deletedUserIds: n.deleted,
        hasMore: n.has_more,
        token: n.next
      }, r;
    }
    return c(t, e), t;
  }(b),
  sn = function (e) {
    function t(t) {
      var n = t.userId,
        r = t.discoveries,
        i = e.call(this) || this;
      return i.method = l.PUT, i.path = "".concat(h, "/").concat(encodeURIComponent(n), "/friend_discoveries"), i.params = {
        friend_discoveries: r.map(function (e) {
          return {
            friend_discovery_key: e.friendDiscoveryKey,
            friend_name: e.friendName
          };
        })
      }, i;
    }
    return c(t, e), t;
  }(v),
  on = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.friendDiscoveryRequestId = n.friend_discovery_request_id, r;
    }
    return c(t, e), t;
  }(b),
  an = function (e) {
    function t(t) {
      var n = t.userId,
        r = t.discoveryKeys,
        i = e.call(this) || this;
      return i.method = l.DELETE, i.path = "".concat(h, "/").concat(encodeURIComponent(n), "/friend_discoveries"), i.params = {
        friend_discovery_keys: r
      }, i;
    }
    return c(t, e), t;
  }(v);
!function (e) {
  function t() {
    return null !== e && e.apply(this, arguments) || this;
  }
  c(t, e);
}(b);
var un = function (e) {
    function t(t) {
      var n = t.userId,
        r = t.userIds,
        i = e.call(this) || this;
      return i.method = l.POST, i.path = "".concat(h, "/").concat(encodeURIComponent(n), "/friends"), i.params = {
        user_ids: r
      }, i;
    }
    return c(t, e), t;
  }(v),
  cn = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.users = n.users.map(function (e) {
        return new g(t, e);
      }), r;
    }
    return c(t, e), t;
  }(b),
  dn = function (e) {
    function t(t) {
      var n = t.userId,
        r = t.userIds,
        i = e.call(this) || this;
      return i.method = l.DELETE, i.path = "".concat(h, "/").concat(encodeURIComponent(n), "/friends"), i.params = {
        user_ids: r
      }, i;
    }
    return c(t, e), t;
  }(v);
!function (e) {
  function t() {
    return null !== e && e.apply(this, arguments) || this;
  }
  c(t, e);
}(b);
var ln = function (e) {
    function t(t) {
      var n = t.userId,
        r = e.call(this) || this;
      return r.method = l.GET, r.path = "".concat(h, "/").concat(n, "/allow_friend_discovery"), r.params = {}, r;
    }
    return c(t, e), t;
  }(v),
  hn = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.allowFriendDiscovery = n.allow_friend_discovery, r;
    }
    return c(t, e), t;
  }(b),
  fn = function (e) {
    function t(t) {
      var n = t.userId,
        r = t.allowFriendDiscovery,
        i = e.call(this) || this;
      return i.method = l.PUT, i.path = "".concat(h, "/").concat(n, "/allow_friend_discovery"), i.params = {
        allow_friend_discovery: r
      }, i;
    }
    return c(t, e), t;
  }(v);
!function (e) {
  function t() {
    return null !== e && e.apply(this, arguments) || this;
  }
  c(t, e);
}(b);
var pn,
  vn = function (e) {
    function t() {
      var t = e.call(this) || this;
      return t.method = l.GET, t.path = w, t;
    }
    return c(t, e), t;
  }(v),
  gn = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.emojiContainer = new bt(n), r;
    }
    return c(t, e), t;
  }(b),
  mn = function (e) {
    function t(t) {
      var n = t.categoryId,
        r = e.call(this) || this;
      return r.method = l.GET, r.path = "".concat(w, "/").concat(n), r;
    }
    return c(t, e), t;
  }(v),
  bn = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.emojiCategory = new mt(n), r;
    }
    return c(t, e), t;
  }(b),
  _n = function (e) {
    function t(t) {
      var n = t.key,
        r = e.call(this) || this;
      return r.method = l.GET, r.path = "".concat(k, "/").concat(n), r;
    }
    return c(t, e), t;
  }(v),
  yn = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.emoji = new gt(n), r;
    }
    return c(t, e), t;
  }(b),
  wn = function (e) {
    function t() {
      var t = e.call(this) || this;
      return t.method = l.GET, t.path = "".concat(I), t;
    }
    return c(t, e), t;
  }(v),
  kn = function (e) {
    function t(t, n) {
      var r,
        i = this,
        s = null != n ? n : {};
      return (i = e.call(this, t, n) || this).uikitConfiguration = {
        string: JSON.stringify(s),
        json: s
      }, i.updatedAt = null !== (r = null == n ? void 0 : n.updated_at) && void 0 !== r ? r : 0, i;
    }
    return c(t, e), t;
  }(b);
!function (e) {
  e.DELIVERED = "DELIVERED", e.CLICKED = "CLICKED";
}(pn || (pn = {}));
var Sn,
  In = function (e) {
    function t(t) {
      var n = t.deviceToken,
        r = t.pushTrackingId,
        i = t.messageId,
        s = t.allowedPushNotification,
        o = t.channelKey,
        a = t.templateKey,
        u = t.eventType,
        c = t.notificationEventDeadline,
        d = e.call(this) || this;
      return d.method = l.POST, d.path = A, d.params = f({
        device_token: n,
        push_tracking_id: r,
        message_id: i,
        allowed_push_notification: s,
        channel_key: o,
        template_key: a,
        event_type: u,
        notification_event_deadline: c
      }), d;
    }
    return c(t, e), t;
  }(v);
var Cn = function () {
  function n(e, t, n) {
    var r = this;
    this._storeInitialized = !1, this._iid = e, this.options = t;
    var s = q.of(e),
      o = s.sdkState,
      u = s.cacheContext,
      c = s.dispatcher,
      d = s.sessionManager,
      l = s.requestQueue,
      h = s.logger,
      f = s.userEventHandlers,
      p = s.appStateToggleEnabled;
    this._onlineDetector = new St({
      logger: h,
      connectionDelegate: {
        reconnect: function () {
          return i(r, void 0, void 0, function () {
            var t, n, r, i, s, o;
            return a(this, function (a) {
              switch (a.label) {
                case 0:
                  if (t = q.of(e), n = t.sdkState, r = t.connectionManager, (i = t.sessionManager).session.hasSession) return [3, 5];
                  a.label = 1;
                case 1:
                  return a.trys.push([1, 3,, 4]), [4, r.connect(i.session.authToken)];
                case 2:
                  return a.sent(), [3, 4];
                case 3:
                  if (s = a.sent(), ge(s)) throw s;
                  return h.debug("connect failed by reconnect event = ", s), [3, 4];
                case 4:
                  return [3, 9];
                case 5:
                  if ("foreground" !== n.appState) return [3, 9];
                  a.label = 6;
                case 6:
                  return a.trys.push([6, 8,, 9]), [4, r.resetAndReconnect()];
                case 7:
                  return a.sent(), [3, 9];
                case 8:
                  if (o = a.sent(), ge(o)) throw o;
                  return h.debug("resetAndReconnect failed by reconnect event = ", o), [3, 9];
                case 9:
                  return c.dispatch(new vt()), [2];
              }
            });
          });
        },
        disconnect: function () {
          ie(function () {
            return i(r, void 0, void 0, function () {
              return a(this, function (t) {
                switch (t.label) {
                  case 0:
                    return [4, q.of(e).connectionManager.disconnect()];
                  case 1:
                    return t.sent(), [2];
                }
              });
            });
          });
        }
      }
    });
    var v = new ut(e, {
      localCacheEnabled: u.localCacheEnabled,
      enableAutoResend: u.localCacheConfig.enableAutoResend,
      dispatcher: c,
      sdkState: o,
      logger: h
    });
    n.forEach(function (t) {
      t.init(e, {
        sdkState: o,
        cacheContext: u,
        dispatcher: c,
        sessionManager: d,
        requestQueue: l,
        logger: h,
        onlineDetector: r._onlineDetector
      }), r[t.name] = t;
    }), this._appStateChangeDetector = new kt({
      isEnabled: p
    }), this._appStateChangeDetector.on("resume", function () {
      h.debug("the page resumes from freeze"), r.setForegroundState();
    }).on("pause", function () {
      h.debug("the page freezes"), r.setBackgroundState();
    }), c.on(function (t) {
      var n;
      if (t instanceof se) {
        if (t.stateType === oe.CONNECTED) v.processNonAutoResendRegisteredPendingMessages();
      } else if (t instanceof ue) {
        if ("USEV" === t.code) {
          var s = t.as(ct).event;
          if (s.category === dt.FRIEND_DISCOVERED) {
            var c = lt.getDataAsFriendDiscoveredEvent(e, s).friendDiscoveries;
            ce(function () {
              return i(r, void 0, void 0, function () {
                return a(this, function (e) {
                  return [2, f.forEach(function (e) {
                    e.onFriendsDiscovered && e.onFriendsDiscovered(c);
                  })];
                });
              });
            });
          }
        }
      } else if (t instanceof de) {
        var d = q.of(r._iid).subscribedUnreadMessageCount,
          l = !1,
          p = t.ts;
        "number" == typeof p && p > d.ts && (d.all !== t.all && (l = !0), d.all = t.all >= 0 ? t.all : 0, t.customTypes && Object.keys(t.customTypes).forEach(function (e) {
          d.customTypes[e] !== t.customTypes[e] && (l = !0), d.customTypes[e] = t.customTypes[e];
        }), l = l && d.ts > 0, d.ts = p), l && ce(function () {
          return i(r, void 0, void 0, function () {
            return a(this, function (e) {
              return [2, f.forEach(function (e) {
                e.onTotalUnreadMessageCountChanged && e.onTotalUnreadMessageCountChanged({
                  groupChannelCount: d.all,
                  feedChannelCount: d.feed,
                  customTypeUnreadCount: d.customTypes
                }), e.onTotalUnreadMessageCountUpdated && e.onTotalUnreadMessageCountUpdated(d.all, d.customTypes);
              })];
            });
          });
        });
      } else t instanceof le ? (u.preference.set(r._getUserProfileCacheKey(o.userId), he.payloadify(t.userProfile)), (null === (n = t.userProfile.appInfo.notificationInfo) || void 0 === n ? void 0 : n.isEnabled) && u.preference.set(fe(r.appId), {
        value: !0
      })) : t instanceof pe && t.error && t.error.isSessionInvalidatedError && (h.debug("session revoked."), r._internalDisconnect(t.error));
    });
  }
  return n.init = function (e) {
    var t = e.appId,
      r = e.appVersion,
      i = e.modules,
      s = void 0 === i ? [] : i,
      o = e.options,
      a = void 0 === o ? new M() : o,
      u = e.debugMode,
      c = void 0 !== u && u,
      d = e.customApiHost,
      l = e.customWebSocketHost,
      h = e.newInstance,
      f = void 0 !== h && h,
      p = e.logLevel,
      v = e.localCacheEnabled,
      g = void 0 !== v && v,
      m = e.localCacheConfig,
      b = void 0 === m ? new N() : m,
      _ = e.localCacheEncryption,
      y = e.useAsyncStorageStore,
      w = void 0 === y ? null : y,
      k = e.useMMKVStorageStore,
      S = void 0 === k ? null : k,
      I = e.appStateToggleEnabled,
      C = void 0 === I || I;
    if (!Sn || f) {
      var E = "su-".concat(Q()),
        T = null != _ ? _ : {
          encrypt: function (e) {
            return e;
          },
          decrypt: function (e) {
            return e;
          }
        },
        P = new Fe();
      P.level = null != p ? p : qe.WARN;
      var D = new He({
        encryption: T
      });
      c || (j() ? S ? D = new xe({
        MMKV: S,
        encryption: T
      }) : w && (D = new Ke({
        AsyncStorage: w,
        encryption: T
      }), P.warn("AsyncStorage store is deprecated due to the small size limit. Please use MMKVStorage store instead.")) : D = new Ge({
        encryption: T
      })), new q(E, {
        appId: t,
        appVersion: r,
        options: a,
        apiHost: null != d ? d : "https://api-".concat(t, ".sendbird.com"),
        websocketHost: null != l ? l : "wss://ws-".concat(t, ".sendbird.com"),
        store: D,
        encryption: T,
        logger: P,
        localCacheEnabled: g,
        localCacheConfig: b,
        debugMode: c,
        appStateToggleEnabled: C
      });
      var U = [new We(), new st()],
        O = new n(E, a, x(x([], K(s), !1), K(U), !1));
      return Sn || (Sn = O), O;
    }
    return Sn;
  }, Object.defineProperty(n, "instance", {
    get: function () {
      return Sn;
    },
    enumerable: !1,
    configurable: !0
  }), Object.defineProperty(n, "version", {
    get: function () {
      return W;
    },
    enumerable: !1,
    configurable: !0
  }), Object.defineProperty(n.prototype, "appId", {
    get: function () {
      return q.of(this._iid).sdkState.appId;
    },
    enumerable: !1,
    configurable: !0
  }), Object.defineProperty(n.prototype, "appInfo", {
    get: function () {
      var e = q.of(this._iid).appInfo;
      return null != e ? e : null;
    },
    enumerable: !1,
    configurable: !0
  }), Object.defineProperty(n.prototype, "appVersion", {
    get: function () {
      var e;
      return null !== (e = q.of(this._iid).sdkState.appVersion) && void 0 !== e ? e : "";
    },
    enumerable: !1,
    configurable: !0
  }), Object.defineProperty(n.prototype, "debugMode", {
    get: function () {
      return !!q.of(this._iid).debugMode;
    },
    enumerable: !1,
    configurable: !0
  }), Object.defineProperty(n.prototype, "logLevel", {
    get: function () {
      return q.of(this._iid).logger.level;
    },
    set: function (e) {
      q.of(this._iid).logger.level = e;
    },
    enumerable: !1,
    configurable: !0
  }), Object.defineProperty(n.prototype, "isCacheEnabled", {
    get: function () {
      return q.of(this._iid).cacheContext.localCacheEnabled;
    },
    enumerable: !1,
    configurable: !0
  }), Object.defineProperty(n.prototype, "localCacheConfig", {
    get: function () {
      var e = q.of(this._iid).cacheContext;
      return e.localCacheEnabled ? e.localCacheConfig : null;
    },
    enumerable: !1,
    configurable: !0
  }), Object.defineProperty(n.prototype, "ekey", {
    get: function () {
      return q.of(this._iid).sessionManager.ekey;
    },
    enumerable: !1,
    configurable: !0
  }), Object.defineProperty(n.prototype, "currentUser", {
    get: function () {
      var e;
      return null !== (e = q.of(this._iid).sessionManager.currentUser) && void 0 !== e ? e : null;
    },
    enumerable: !1,
    configurable: !0
  }), Object.defineProperty(n.prototype, "connectionState", {
    get: function () {
      var e = q.of(this._iid).connectionManager;
      return e.isConnected ? V.OPEN : e.isConnecting ? V.CONNECTING : V.CLOSED;
    },
    enumerable: !1,
    configurable: !0
  }), Object.defineProperty(n.prototype, "lastConnectedAt", {
    get: function () {
      var e = q.of(this._iid),
        t = e.connectedAt;
      return e.connectionManager.isConnected ? t : 0;
    },
    enumerable: !1,
    configurable: !0
  }), Object.defineProperty(n.prototype, "fcmPushToken", {
    get: function () {
      var e;
      return null !== (e = this._fcmPushToken) && void 0 !== e ? e : null;
    },
    enumerable: !1,
    configurable: !0
  }), Object.defineProperty(n.prototype, "apnsPushToken", {
    get: function () {
      var e;
      return null !== (e = this._apnsPushToken) && void 0 !== e ? e : null;
    },
    enumerable: !1,
    configurable: !0
  }), n.prototype._getNestDBVersion = function () {
    return 7;
  }, n.prototype._getPreferenceVersion = function () {
    return 8;
  }, n.prototype._getPreferenceCacheKey = function (e) {
    return "sendbird@".concat(this.appId, "/").concat(e, ".pref");
  }, n.prototype._getUserProfileCacheKey = function (e) {
    return "sendbird@".concat(this.appId, "/").concat(e, ".profile");
  }, n.prototype._internalDisconnect = function (e) {
    return i(this, void 0, void 0, function () {
      var t, n, r, i;
      return a(this, function (s) {
        switch (s.label) {
          case 0:
            return t = q.of(this._iid), n = t.connectionManager, r = t.sdkState, i = t.requestQueue, this._appStateChangeDetector.stop(), this._onlineDetector.stop(), i.cancelAll(), [4, n.logout(e)];
          case 1:
            return s.sent(), [4, this.clearCachedData()];
          case 2:
            return s.sent(), r.appState = "foreground", [2];
        }
      });
    });
  }, n.prototype.getMemoryStoreForDebugging = function () {
    var e = q.of(this._iid),
      t = e.debugMode,
      n = e.cacheContext;
    if (t) return n.store instanceof He ? n.store : null;
    throw Z.debugModeRequired;
  }, n.prototype.addExtension = function (e, t) {
    var n = q.of(this._iid).sdkState;
    ["sb_uikit", "sb_syncmanager", "device-os-platform"].indexOf(e) > -1 && n.extensions && (n.extensions[e] = t);
  }, n.prototype.addSendbirdExtensions = function (e, t, n) {
    var r,
      i,
      s = q.of(this._iid),
      o = s.logger,
      a = s.sdkState,
      u = new RegExp(ve);
    if (0 === e.length) return o.debug("sb.addSendbirdExtensions() has failed because the given sendbirdExtensions is empty."), !1;
    if (e.find(function (e) {
      return !u.test(e.version);
    })) return o.debug("sb.addSendbirdExtensions() has failed because the given version did not satisfy the SemVer specification."), !1;
    if (n) try {
      for (var c = X(Object.entries(n)), d = c.next(); !d.done; d = c.next()) {
        var l = K(d.value, 2),
          h = l[0],
          f = l[1];
        if (/&|=/.test(h) || /&|=/.test(f)) return o.debug("sb.addSendbirdExtensions() has failed because the given customData includes at least one invalid character: = or &."), !1;
      }
    } catch (e) {
      r = {
        error: e
      };
    } finally {
      try {
        d && !d.done && (i = c.return) && i.call(c);
      } finally {
        if (r) throw r.error;
      }
    }
    return a.sendbirdRuntimeEnvironment = {
      sendbirdExtensions: e,
      deviceOS: t,
      customData: n
    }, !0;
  }, n.prototype.setOnlineListener = function (e) {
    this._onlineDetector.setOnlineListener(e);
  }, n.prototype.setOfflineListener = function (e) {
    this._onlineDetector.setOfflineListener(e);
  }, n.prototype._safeInitializeStore = function (e) {
    return i(this, void 0, void 0, function () {
      var t;
      return a(this, function (n) {
        switch (n.label) {
          case 0:
            t = q.of(this._iid).cacheContext, n.label = 1;
          case 1:
            return n.trys.push([1, 3,, 5]), [4, t.store.init(e)];
          case 2:
            return n.sent(), this._storeInitialized = !0, [3, 5];
          case 3:
            return n.sent(), t.replaceStore(new He()), [4, this._safeInitializeStore(e)];
          case 4:
            return n.sent(), [3, 5];
          case 5:
            return [2];
        }
      });
    });
  }, n.prototype.setLocaleForChatbot = function (e) {
    var t = q.of(this._iid).sdkState,
      n = function (e) {
        return e.replace(/[^a-zA-Z-_.]/g, "");
      }(e.trim());
    n && (t.localeForChatbot = n);
  }, n.prototype.initializeCache = function (e) {
    return i(this, void 0, void 0, function () {
      var t, n, r, i, s, o, u, c, d;
      return a(this, function (a) {
        switch (a.label) {
          case 0:
            if (t = q.of(this._iid), n = t.sdkState, r = t.cacheContext, i = t.dispatcher, s = t.logger, n.userId && n.userId === e) return [3, 13];
            o = "sendbird@".concat(n.appId, "/").concat(e, ".db"), a.label = 1;
          case 1:
            return a.trys.push([1, 9, 11, 13]), r.localCacheEnabled ? r.nestdb && r.nestdb.state !== nt.CLOSED && r.nestdb.name === o ? [3, 3] : (u = "sendbird@".concat(n.appId, "/").concat(e, ".db"), (c = r.nestdb = new rt({
              name: u,
              version: this._getNestDBVersion(),
              store: r.store,
              config: new it({
                dbname: u,
                itemSizeLimit: 61440
              })
            })).on("upgrade", yt(c)), c.on("storeReplaced", function (e) {
              r.replaceStore(e), r.localCacheEnabled = !1, c.on("upgrade", yt(c));
            }), [4, c.open()]) : [3, 6];
          case 2:
            return a.sent(), this._storeInitialized = !0, i.dispatch(new ht(this._iid, {
              userId: e
            })), [3, 5];
          case 3:
            return [4, this._safeInitializeStore(o)];
          case 4:
            a.sent(), a.label = 5;
          case 5:
            return n.userId = e, [3, 8];
          case 6:
            return [4, this._safeInitializeStore(o)];
          case 7:
            a.sent(), n.userId = e, a.label = 8;
          case 8:
            return [3, 13];
          case 9:
            return d = a.sent(), s.warn("Nest DB Open Failed. ", d), r.store instanceof He || (r.replaceStore(new He()), r.localCacheEnabled = !1), [4, this._safeInitializeStore(o)];
          case 10:
            throw a.sent(), n.userId = e, Z.databaseError;
          case 11:
            return [4, this._setupPreference(e)];
          case 12:
            return a.sent(), [7];
          case 13:
            return [2];
        }
      });
    });
  }, n.prototype.getCacheDataSize = function () {
    return i(this, void 0, void 0, function () {
      var e;
      return a(this, function (t) {
        switch (t.label) {
          case 0:
            return (e = q.of(this._iid).cacheContext).localCacheEnabled && e.nestdb ? [4, e.nestdb.estimateUsage()] : [3, 2];
          case 1:
            return [2, t.sent()];
          case 2:
            return [2, 0];
        }
      });
    });
  }, n.prototype.clearCachedData = function () {
    return i(this, void 0, void 0, function () {
      var e;
      return a(this, function (t) {
        switch (t.label) {
          case 0:
            return (e = q.of(this._iid).cacheContext).localCacheEnabled && e.nestdb ? [4, e.nestdb.clear()] : [3, 2];
          case 1:
            t.sent(), t.label = 2;
          case 2:
            return this._storeInitialized ? [4, e.preference.clear()] : [3, 4];
          case 3:
            t.sent(), t.label = 4;
          case 4:
            return [2];
        }
      });
    });
  }, n.prototype.clearCachedMessages = function (e) {
    return i(this, void 0, void 0, function () {
      var t,
        n,
        r = this;
      return a(this, function (s) {
        switch (s.label) {
          case 0:
            return ee(me("string", e)).throw(Z.invalidParameters), t = et.of(this._iid), n = tt.of(this._iid), t && n ? [4, Promise.all(e.map(function (e) {
              return i(r, void 0, void 0, function () {
                return a(this, function (r) {
                  switch (r.label) {
                    case 0:
                      return [4, t.removeMessagesOfChannel(e)];
                    case 1:
                      return r.sent(), [4, n.removeMessagesOfChannel(e)];
                    case 2:
                      return r.sent(), [2];
                  }
                });
              });
            }))] : [3, 2];
          case 1:
            s.sent(), s.label = 2;
          case 2:
            return [2];
        }
      });
    });
  }, n.prototype._setupPreference = function (e) {
    return i(this, void 0, void 0, function () {
      var t, n, r, i, s;
      return a(this, function (o) {
        switch (o.label) {
          case 0:
            return t = q.of(this._iid), n = t.sdkState, r = t.cacheContext, i = t.statManager, [4, r.preference.init(this._getPreferenceCacheKey(e), this._getPreferenceVersion())];
          case 1:
            return o.sent(), [4, r.preference.get(this._getUserProfileCacheKey(e))];
          case 2:
            return (s = o.sent()) && new he(this._iid, s).apply(), [4, i.init("sendbird@".concat(n.appId, "/").concat(e, ".statlog"))];
          case 3:
            return o.sent(), [2];
        }
      });
    });
  }, n.prototype._ready = function (e, n, r) {
    return void 0 === r && (r = {}), i(this, void 0, void 0, function () {
      var i, s, o, u, c, d;
      return a(this, function (a) {
        switch (a.label) {
          case 0:
            return ee(t("string", e) && t("string", n, !0)).throw(Z.invalidParameters), i = q.of(this._iid), s = i.sdkState, o = i.cacheContext, u = i.connectionManager, c = r.errorForOtherUser, s.userId && s.userId !== e ? c ? [3, 2] : [4, u.logout()] : [3, 3];
          case 1:
            return a.sent(), [3, 3];
          case 2:
            throw c;
          case 3:
            return a.trys.push([3, 5,, 6]), [4, this.initializeCache(e)];
          case 4:
            return a.sent(), q.of(this._iid).dispatcher.dispatch(new ft()), [3, 6];
          case 5:
            return a.sent(), d = q.of(this._iid).logger, o.localCacheEnabled = !1, d.warn("Cache initialization failed - cache is not available."), [3, 6];
          case 6:
            return [2];
        }
      });
    });
  }, n.prototype.authenticateFeed = function (e, t) {
    return i(this, void 0, void 0, function () {
      return a(this, function (n) {
        switch (n.label) {
          case 0:
            return [4, this.authenticate(e, t)];
          case 1:
            return [2, n.sent()];
        }
      });
    });
  }, n.prototype.authenticate = function (e, t) {
    var n;
    return i(this, void 0, void 0, function () {
      var r, i, s;
      return a(this, function (o) {
        switch (o.label) {
          case 0:
            return r = q.of(this._iid), i = r.sessionManager, s = r.connectionManager, [4, this._ready(e, t, {
              errorForOtherUser: s.isConnected ? Z.alreadyConnectedAsAnotherUser : void 0
            })];
          case 1:
            return o.sent(), (null === (n = i.session.services) || void 0 === n ? void 0 : n.includes(be.CHAT)) ? [3, 3] : (t || i.handler || this.setSessionHandler(new Xe()), [4, i.authenticate(e, t)]);
          case 2:
            o.sent(), o.label = 3;
          case 3:
            return [2, this.currentUser];
        }
      });
    });
  }, n.prototype.connect = function (e, t) {
    return i(this, void 0, void 0, function () {
      return a(this, function (n) {
        switch (n.label) {
          case 0:
            return [4, this._ready(e, t)];
          case 1:
            n.sent(), n.label = 2;
          case 2:
            return n.trys.push([2,, 4, 5]), [4, q.of(this._iid).connectionManager.connect(t)];
          case 3:
            return n.sent(), [3, 5];
          case 4:
            return this._onlineDetector.start(), this._appStateChangeDetector.start(), [7];
          case 5:
            return [2, this.currentUser];
        }
      });
    });
  }, n.prototype.reconnect = function () {
    var e = q.of(this._iid),
      t = e.connectionManager,
      n = e.sdkState,
      r = e.sessionManager.session.hasSession;
    return r && (this._appStateChangeDetector.start(), this._onlineDetector.start(), t.resetAndReconnect().then(function () {
      n.appState = "foreground";
    }).catch(function () {})), r;
  }, n.prototype.disconnect = function () {
    return i(this, void 0, void 0, function () {
      return a(this, function (e) {
        switch (e.label) {
          case 0:
            return [4, this._internalDisconnect()];
          case 1:
            return e.sent(), [2];
        }
      });
    });
  }, n.prototype.disconnectWebSocket = function () {
    return i(this, void 0, void 0, function () {
      var e, t, n;
      return a(this, function (r) {
        switch (r.label) {
          case 0:
            return e = q.of(this._iid), t = e.connectionManager, n = e.requestQueue, this._appStateChangeDetector.stop(), this._onlineDetector.stop(), n.cancelAll(), [4, t.disconnectWebSocket(!0)];
          case 1:
            return r.sent(), [2];
        }
      });
    });
  }, n.prototype.setBackgroundState = function () {
    var e = q.of(this._iid),
      t = e.connectionManager,
      n = e.sdkState,
      r = e.logger;
    "foreground" === n.appState && (n.appState = "background", r.debug("going background state"), t.background().catch(function (e) {
      r.debug("setBackgroundState error = ", e);
    }));
  }, n.prototype.setForegroundState = function () {
    var e = q.of(this._iid),
      t = e.connectionManager,
      n = e.sdkState,
      r = e.dispatcher,
      i = e.logger;
    "background" === n.appState && (n.appState = "foreground", i.debug("going foreground state"), t.resetAndReconnect().catch(function (e) {
      i.debug("setForgroundState error = ", e);
    }), r.dispatch(new vt()));
  }, n.prototype.setSessionHandler = function (e) {
    ee(e instanceof Xe).throw(Z.invalidParameters), q.of(this._iid).sessionManager.handler = e;
  }, n.prototype.addUserEventHandler = function (e, n) {
    ee(t("string", e) && n instanceof Ve).throw(Z.invalidParameters), q.of(this._iid).userEventHandlers.set(e, n);
  }, n.prototype.removeUserEventHandler = function (e) {
    q.of(this._iid).userEventHandlers.delete(e);
  }, n.prototype.removeAllUserEventHandler = function () {
    q.of(this._iid).userEventHandlers.clear();
  }, n.prototype.addConnectionHandler = function (e, n) {
    ee(t("string", e) && n instanceof Be).throw(Z.invalidParameters), q.of(this._iid).connectionHandlers.set(e, n);
  }, n.prototype.removeConnectionHandler = function (e) {
    q.of(this._iid).connectionHandlers.delete(e);
  }, n.prototype.removeAllConnectionHandler = function () {
    q.of(this._iid).connectionHandlers.clear();
  }, n.prototype.createApplicationUserListQuery = function (e) {
    return void 0 === e && (e = {}), new Je(this._iid, e);
  }, n.prototype.createBlockedUserListQuery = function (e) {
    return void 0 === e && (e = {}), new Ye(this._iid, e);
  }, n.prototype.createFriendListQuery = function (e) {
    return void 0 === e && (e = {}), new Ze(this._iid, e);
  }, n.prototype.createMessageSearchQuery = function (e) {
    return new $e(this._iid, e);
  }, n.prototype.createPollListQuery = function (e) {
    return new te(this._iid, m({}, e));
  }, n.prototype.createPollVoterListQuery = function (e) {
    return new ne(this._iid, m({}, e));
  }, n.prototype.buildUserFromSerializedData = function (e) {
    var t = re(e);
    return new g(this._iid, g.payloadify(t));
  }, n.prototype.updateCurrentUserInfo = function (n) {
    return void 0 === n && (n = {}), i(this, void 0, void 0, function () {
      var r, i, s, o, u, c, d;
      return a(this, function (a) {
        switch (a.label) {
          case 0:
            return r = m(m({}, _t), n), ee(function (n) {
              return e(n.profileImage, !0) && t("string", n.profileUrl, !0) && t("string", n.nickname, !0);
            }(r)).throw(Z.invalidParameters), this.currentUser ? (i = q.of(this._iid), s = i.sdkState, o = i.requestQueue, u = new It(m({
              userId: s.userId
            }, r)), [4, o.send(u)]) : [3, 2];
          case 1:
            return c = a.sent(), d = c.as(Ct).user, this.currentUser && (r.nickname && (this.currentUser.nickname = d.nickname), (r.profileUrl || r.profileImage) && (this.currentUser.plainProfileUrl = d.profileUrl)), [2, d];
          case 2:
            throw Z.connectionRequired;
        }
      });
    });
  }, n.prototype.updateCurrentUserInfoWithPreferredLanguages = function (e) {
    return i(this, void 0, void 0, function () {
      var t, n, r, i, s, o;
      return a(this, function (a) {
        switch (a.label) {
          case 0:
            return ee(me("string", e)).throw(Z.invalidParameters), this.currentUser ? (t = q.of(this._iid), n = t.sdkState, r = t.requestQueue, i = new It({
              userId: n.userId,
              preferredLanguages: e
            }), [4, r.send(i)]) : [3, 2];
          case 1:
            return s = a.sent(), o = s.as(Ct).user, this.currentUser && (this.currentUser.preferredLanguages = o.preferredLanguages), [2, o];
          case 2:
            throw Z.connectionRequired;
        }
      });
    });
  }, n.prototype.registerFCMPushTokenForCurrentUser = function (e, n) {
    var r, s;
    return i(this, void 0, void 0, function () {
      var o,
        u,
        c,
        d,
        l,
        h,
        f = this;
      return a(this, function (p) {
        switch (p.label) {
          case 0:
            if (ee(t("string", e) && t("string", null == n ? void 0 : n.deviceManufacturer, !0)).throw(Z.invalidParameters), (null == n ? void 0 : n.deviceOS) && ee(ye(we, n.deviceOS.platform, !0) && t("string", n.deviceOS.version, !0)).throw(Z.invalidParameters), !this.currentUser) return [3, 6];
            o = {
              deviceOS: "".concat((null === (r = null == n ? void 0 : n.deviceOS) || void 0 === r ? void 0 : r.platform) || we.ANDROID, "-").concat((null === (s = null == n ? void 0 : n.deviceOS) || void 0 === s ? void 0 : s.version) || "unknown"),
              deviceManufacturer: null == n ? void 0 : n.deviceManufacturer,
              systemPushEnabled: null == n ? void 0 : n.systemPushEnabled
            }, p.label = 1;
          case 1:
            return p.trys.push([1, 4,, 5]), u = q.of(this._iid), c = u.sessionManager, d = u.sdkState, l = u.requestQueue, c.hasDeviceToken(_.FCM, e) ? [2, _e.SUCCESS] : (h = new Tt({
              userId: d.userId,
              token: e,
              deviceOS: o.deviceOS,
              deviceManufacturer: o.deviceManufacturer,
              systemPushEnabled: o.systemPushEnabled
            }), [4, l.send(h)]);
          case 2:
            return p.sent(), [4, ie(function () {
              return i(f, void 0, void 0, function () {
                return a(this, function (t) {
                  switch (t.label) {
                    case 0:
                      return c.setDeviceToken(_.FCM, e, o), [4, c.saveDeviceToken()];
                    case 1:
                      return t.sent(), [2];
                  }
                });
              });
            })];
          case 3:
            return p.sent(), this._fcmPushToken = "", [2, _e.SUCCESS];
          case 4:
            return p.sent(), [2, _e.ERROR];
          case 5:
            return [3, 7];
          case 6:
            return this._fcmPushToken = e, [2, _e.PENDING];
          case 7:
            return [2];
        }
      });
    });
  }, n.prototype.unregisterFCMPushTokenForCurrentUser = function (e) {
    return i(this, void 0, void 0, function () {
      var n,
        r,
        s,
        o,
        u,
        c,
        d = this;
      return a(this, function (l) {
        switch (l.label) {
          case 0:
            if (ee(t("string", e)).throw(Z.invalidParameters), !this.currentUser) return [3, 6];
            l.label = 1;
          case 1:
            return l.trys.push([1, 4,, 5]), n = q.of(this._iid), r = n.sessionManager, s = n.sdkState, o = n.requestQueue, u = new Dt({
              userId: s.userId,
              token: e
            }), [4, o.send(u)];
          case 2:
            return c = l.sent().lastDeletedAt, [4, ie(function () {
              return i(d, void 0, void 0, function () {
                return a(this, function (t) {
                  switch (t.label) {
                    case 0:
                      return r.unsetDeviceToken(_.FCM, e), r.setDeviceTokenDeletedAt(c), [4, r.saveDeviceToken()];
                    case 1:
                      return t.sent(), [2];
                  }
                });
              });
            })];
          case 3:
            return l.sent(), this._fcmPushToken = "", [2, _e.SUCCESS];
          case 4:
            return l.sent(), [2, _e.ERROR];
          case 5:
            return [3, 7];
          case 6:
            return this._fcmPushToken = e, [2, _e.PENDING];
          case 7:
            return [2];
        }
      });
    });
  }, n.prototype.unregisterFCMPushTokenAllForCurrentUser = function () {
    return i(this, void 0, void 0, function () {
      var e,
        t,
        n,
        r,
        s,
        o,
        u = this;
      return a(this, function (c) {
        switch (c.label) {
          case 0:
            return this.currentUser ? (e = q.of(this._iid), t = e.sessionManager, n = e.sdkState, r = e.requestQueue, s = new Ot({
              userId: n.userId
            }), [4, r.send(s)]) : [3, 3];
          case 1:
            return o = c.sent().lastDeletedAt, [4, ie(function () {
              return i(u, void 0, void 0, function () {
                return a(this, function (e) {
                  switch (e.label) {
                    case 0:
                      return t.unsetDeviceTokens(_.FCM), t.setDeviceTokenDeletedAt(o), [4, t.saveDeviceToken()];
                    case 1:
                      return e.sent(), [2];
                  }
                });
              });
            })];
          case 2:
            c.sent(), c.label = 3;
          case 3:
            return [2];
        }
      });
    });
  }, n.prototype.registerAPNSPushTokenForCurrentUser = function (e, n) {
    var r, s;
    return i(this, void 0, void 0, function () {
      var o,
        u,
        c,
        d,
        l,
        h,
        f = this;
      return a(this, function (p) {
        switch (p.label) {
          case 0:
            if (ee(t("string", e) && t("string", null == n ? void 0 : n.deviceManufacturer, !0)).throw(Z.invalidParameters), (null == n ? void 0 : n.deviceOS) && ee(ye(we, n.deviceOS.platform, !0) && t("string", n.deviceOS.version, !0)).throw(Z.invalidParameters), !this.currentUser) return [3, 6];
            o = {
              deviceOS: "".concat((null === (r = null == n ? void 0 : n.deviceOS) || void 0 === r ? void 0 : r.platform) || we.IOS, "-").concat((null === (s = null == n ? void 0 : n.deviceOS) || void 0 === s ? void 0 : s.version) || "unknown"),
              deviceManufacturer: null == n ? void 0 : n.deviceManufacturer,
              systemPushEnabled: null == n ? void 0 : n.systemPushEnabled
            }, p.label = 1;
          case 1:
            return p.trys.push([1, 4,, 5]), u = q.of(this._iid), c = u.sessionManager, d = u.sdkState, l = u.requestQueue, c.hasDeviceToken(_.APNS, e) ? [2, _e.SUCCESS] : (h = new Et({
              userId: d.userId,
              token: e,
              deviceOS: o.deviceOS,
              deviceManufacturer: o.deviceManufacturer,
              systemPushEnabled: o.systemPushEnabled
            }), [4, l.send(h)]);
          case 2:
            return p.sent(), [4, ie(function () {
              return i(f, void 0, void 0, function () {
                return a(this, function (t) {
                  switch (t.label) {
                    case 0:
                      return c.setDeviceToken(_.APNS, e, o), [4, c.saveDeviceToken()];
                    case 1:
                      return t.sent(), [2];
                  }
                });
              });
            })];
          case 3:
            return p.sent(), this._apnsPushToken = "", [2, _e.SUCCESS];
          case 4:
            return p.sent(), [2, _e.ERROR];
          case 5:
            return [3, 7];
          case 6:
            return this._apnsPushToken = e, [2, _e.PENDING];
          case 7:
            return [2];
        }
      });
    });
  }, n.prototype.unregisterAPNSPushTokenForCurrentUser = function (e) {
    return i(this, void 0, void 0, function () {
      var n,
        r,
        s,
        o,
        u,
        c,
        d = this;
      return a(this, function (l) {
        switch (l.label) {
          case 0:
            if (ee(t("string", e)).throw(Z.invalidParameters), !this.currentUser) return [3, 6];
            l.label = 1;
          case 1:
            return l.trys.push([1, 4,, 5]), n = q.of(this._iid), r = n.sessionManager, s = n.sdkState, o = n.requestQueue, u = new Pt({
              userId: s.userId,
              token: e
            }), [4, o.send(u)];
          case 2:
            return c = l.sent().lastDeletedAt, [4, ie(function () {
              return i(d, void 0, void 0, function () {
                return a(this, function (t) {
                  switch (t.label) {
                    case 0:
                      return r.unsetDeviceToken(_.APNS, e), r.setDeviceTokenDeletedAt(c), [4, r.saveDeviceToken()];
                    case 1:
                      return t.sent(), [2];
                  }
                });
              });
            })];
          case 3:
            return l.sent(), this._apnsPushToken = "", [2, _e.SUCCESS];
          case 4:
            return l.sent(), [2, _e.ERROR];
          case 5:
            return [3, 7];
          case 6:
            return this._apnsPushToken = e, [2, _e.PENDING];
          case 7:
            return [2];
        }
      });
    });
  }, n.prototype.unregisterAPNSPushTokenAllForCurrentUser = function () {
    return i(this, void 0, void 0, function () {
      var e,
        t,
        n,
        r,
        s,
        o,
        u = this;
      return a(this, function (c) {
        switch (c.label) {
          case 0:
            return this.currentUser ? (e = q.of(this._iid), t = e.sessionManager, n = e.sdkState, r = e.requestQueue, s = new Ut({
              userId: n.userId
            }), [4, r.send(s)]) : [3, 3];
          case 1:
            return o = c.sent().lastDeletedAt, [4, ie(function () {
              return i(u, void 0, void 0, function () {
                return a(this, function (e) {
                  switch (e.label) {
                    case 0:
                      return t.unsetDeviceTokens(_.APNS), t.setDeviceTokenDeletedAt(o), [4, t.saveDeviceToken()];
                    case 1:
                      return e.sent(), [2];
                  }
                });
              });
            })];
          case 2:
            c.sent(), c.label = 3;
          case 3:
            return [2];
        }
      });
    });
  }, n.prototype.markPushNotificationAsDelivered = function (e, t) {
    var n;
    return void 0 === t && (t = !0), i(this, void 0, void 0, function () {
      var r, i, s, o, u, c, d, l, h, f, p, v, g, m, b, _, y, w, k;
      return a(this, function (a) {
        switch (a.label) {
          case 0:
            if (r = q.of(this._iid), i = r.logger, s = r.sessionManager, o = r.requestQueue, u = r.pushNotificationAckManager, i.debug("markPushNotificationAsDelivered:", e), c = pn.DELIVERED, d = u.parseData(e), l = d.pushTrackingId, h = d.sessionTopics, f = d.sessionKey, p = d.messageId, v = d.channelKey, g = d.templateKey, m = d.notificationEventDeadline, !f) throw i.info("Can't use markPushNotificationAsDelivered() feature."), Z.malformedDataError;
            if (!h.includes(ke.PUSH_ACKNOWLEDGEMENT)) throw i.info("Can't use markPushNotificationAsDelivered() feature."), Z.malformedDataError;
            if (u.isAcked(l, c)) return i.info("already delivered push notification:", l), [2];
            b = Boolean(v), _ = t && b, y = null === (n = s.deviceTokens[0]) || void 0 === n ? void 0 : n.token, w = new In({
              deviceToken: null != y ? y : "",
              pushTrackingId: l,
              eventType: c,
              messageId: p,
              channelKey: v,
              templateKey: g,
              notificationEventDeadline: m,
              allowedPushNotification: _
            }), f && (w.headers["Session-Key"] = f), a.label = 1;
          case 1:
            return a.trys.push([1, 3,, 4]), [4, o.send(w)];
          case 2:
            return a.sent(), u.markAsAcked(l, c), [3, 4];
          case 3:
            throw k = a.sent(), i.debug("failed to mark push notification as delivered. push tracking id: ".concat(l, ". error: ").concat(k)), k;
          case 4:
            return [2];
        }
      });
    });
  }, n.prototype.markPushNotificationAsClicked = function (e) {
    var t;
    return i(this, void 0, void 0, function () {
      var n, r, i, s, o, u, c, d, l, h, f, p, v, g, m, b, _;
      return a(this, function (a) {
        switch (a.label) {
          case 0:
            if (n = q.of(this._iid), r = n.logger, i = n.sessionManager, s = n.requestQueue, o = n.pushNotificationAckManager, r.debug("markPushNotificationAsClicked:", e), u = pn.CLICKED, c = o.parseData(e), d = c.pushTrackingId, l = c.sessionTopics, h = c.sessionKey, f = c.messageId, p = c.channelKey, v = c.templateKey, g = c.notificationEventDeadline, !h) throw r.info("Can't use markPushNotificationAsClicked() feature."), Z.malformedDataError;
            if (!l.includes(ke.PUSH_ACKNOWLEDGEMENT)) throw r.info("Can't use markPushNotificationAsClicked() feature."), Z.malformedDataError;
            if (o.isAcked(d, u)) return r.info("already clicked push notification:", d), [2];
            m = null === (t = i.deviceTokens[0]) || void 0 === t ? void 0 : t.token, b = new In({
              deviceToken: null != m ? m : "",
              pushTrackingId: d,
              eventType: u,
              messageId: f,
              channelKey: p,
              templateKey: v,
              notificationEventDeadline: g
            }), h && (b.headers["Session-Key"] = h), a.label = 1;
          case 1:
            return a.trys.push([1, 3,, 4]), [4, s.send(b)];
          case 2:
            return a.sent(), o.markAsAcked(d, u), [3, 4];
          case 3:
            throw _ = a.sent(), r.debug("failed to mark push notification as clicked. push tracking id: ".concat(d, ". error: ").concat(_)), _;
          case 4:
            return [2];
        }
      });
    });
  }, n.prototype.getChannelInvitationPreference = function () {
    return i(this, void 0, void 0, function () {
      var e, t, n, r, i;
      return a(this, function (s) {
        switch (s.label) {
          case 0:
            return e = q.of(this._iid), t = e.sdkState, n = e.requestQueue, r = new Nt({
              userId: t.userId
            }), [4, n.send(r)];
          case 1:
            return i = s.sent(), [2, {
              autoAccept: i.as(Lt).autoAccept
            }];
        }
      });
    });
  }, n.prototype.setChannelInvitationPreference = function (e) {
    return i(this, void 0, void 0, function () {
      var n, r, i, s, o;
      return a(this, function (a) {
        switch (a.label) {
          case 0:
            return ee(t("boolean", e)).throw(Z.invalidParameters), n = q.of(this._iid), r = n.sdkState, i = n.requestQueue, s = new Rt({
              userId: r.userId,
              willAutoAccept: e
            }), [4, i.send(s)];
          case 1:
            return o = a.sent(), [2, {
              autoAccept: o.as(zt).autoAccept
            }];
        }
      });
    });
  }, n.prototype.getDoNotDisturb = function () {
    return i(this, void 0, void 0, function () {
      var e, t, n, r, i;
      return a(this, function (s) {
        switch (s.label) {
          case 0:
            return e = q.of(this._iid), t = e.sdkState, n = e.requestQueue, r = new Qt({
              userId: t.userId
            }), [4, n.send(r)];
          case 1:
            return i = s.sent(), [2, i.as(jt).preference];
        }
      });
    });
  }, n.prototype.setDoNotDisturb = function (e, n, r, s, o, u) {
    return void 0 === n && (n = 0), void 0 === r && (r = 0), void 0 === s && (s = 0), void 0 === o && (o = 0), void 0 === u && (u = ""), i(this, void 0, void 0, function () {
      var i, c, d, l, h;
      return a(this, function (a) {
        switch (a.label) {
          case 0:
            return ee(t("boolean", e) && t("number", n) && t("number", r) && t("number", s) && t("number", o) && t("string", u)).throw(Z.invalidParameters), i = q.of(this._iid), c = i.sdkState, d = i.requestQueue, l = new Ft({
              userId: c.userId,
              doNotDisturbOn: e,
              startHour: n,
              startMin: r,
              endHour: s,
              endMin: o,
              timezone: u
            }), [4, d.send(l)];
          case 1:
            return h = a.sent(), [2, h.as(qt).preference];
        }
      });
    });
  }, n.prototype.getSnoozePeriod = function () {
    return i(this, void 0, void 0, function () {
      var e, t, n, r, i;
      return a(this, function (s) {
        switch (s.label) {
          case 0:
            return e = q.of(this._iid), t = e.sdkState, n = e.requestQueue, r = new Ht({
              userId: t.userId
            }), [4, n.send(r)];
          case 1:
            return i = s.sent(), [2, i.as(xt).snoozePeriod];
        }
      });
    });
  }, n.prototype.setSnoozePeriod = function (e, n, r) {
    return void 0 === n && (n = 0), void 0 === r && (r = 0), i(this, void 0, void 0, function () {
      var i, s, o, u, c;
      return a(this, function (a) {
        switch (a.label) {
          case 0:
            return ee(t("boolean", e) && t("number", n) && t("number", r)).throw(Z.invalidParameters), i = q.of(this._iid), s = i.sdkState, o = i.requestQueue, u = new Kt({
              userId: s.userId,
              snoozeOn: e,
              startTs: n,
              endTs: r
            }), [4, o.send(u)];
          case 1:
            return c = a.sent(), [2, c.as(Gt).snoozePeriod];
        }
      });
    });
  }, n.prototype.getMyPushTokensByToken = function (e, n) {
    return i(this, void 0, void 0, function () {
      var r, i, s, o, u, c, d, l, h, f, p, v;
      return a(this, function (a) {
        switch (a.label) {
          case 0:
            return ee(t("string", e) && ye(_, n)).throw(Z.invalidParameters), r = q.of(this._iid), i = r.sdkState, s = r.requestQueue, o = r.sessionManager, u = new At({
              userId: i.userId,
              type: n,
              token: e
            }), [4, s.send(u)];
          case 1:
            c = a.sent(), d = c.as(Mt).pushTokens;
            try {
              for (l = X(d.deviceTokens), h = l.next(); !h.done; h = l.next()) f = h.value, o.setDeviceToken(d.type, f);
            } catch (e) {
              p = {
                error: e
              };
            } finally {
              try {
                h && !h.done && (v = l.return) && v.call(l);
              } finally {
                if (p) throw p.error;
              }
            }
            return [4, o.saveDeviceToken()];
          case 2:
            return a.sent(), [2, d];
        }
      });
    });
  }, n.prototype.getPushTriggerOption = function () {
    return i(this, void 0, void 0, function () {
      var e, t, n, r;
      return a(this, function (i) {
        switch (i.label) {
          case 0:
            return e = q.of(this._iid), t = e.sdkState, n = e.requestQueue, r = new Bt({
              userId: t.userId
            }), [4, n.send(r)];
          case 1:
            return [2, i.sent().as(Jt).pushTriggerOption];
        }
      });
    });
  }, n.prototype.setPushTriggerOption = function (e) {
    return i(this, void 0, void 0, function () {
      var t, n, r, i;
      return a(this, function (s) {
        switch (s.label) {
          case 0:
            return ee(ye(Se, e)).throw(Z.invalidParameters), t = q.of(this._iid), n = t.sdkState, r = t.requestQueue, i = new Yt({
              userId: n.userId,
              pushTriggerOption: e
            }), [4, r.send(i)];
          case 1:
            return [2, s.sent().as(Zt).pushTriggerOption];
        }
      });
    });
  }, n.prototype.getPushTemplate = function () {
    return i(this, void 0, void 0, function () {
      var e, t, n, r;
      return a(this, function (i) {
        switch (i.label) {
          case 0:
            return e = q.of(this._iid), t = e.sdkState, n = e.requestQueue, r = new $t({
              userId: t.userId
            }), [4, n.send(r)];
          case 1:
            return [2, i.sent().as(Xt).name];
        }
      });
    });
  }, n.prototype.setPushTemplate = function (e) {
    return i(this, void 0, void 0, function () {
      var t, n, r, i;
      return a(this, function (s) {
        switch (s.label) {
          case 0:
            return ee(ye(Ie, e)).throw(Z.invalidParameters), t = q.of(this._iid), n = t.sdkState, r = t.requestQueue, i = new en({
              userId: n.userId,
              templateName: e
            }), [4, r.send(i)];
          case 1:
            return [2, s.sent().as(tn).name];
        }
      });
    });
  }, n.prototype.blockUser = function (e) {
    return i(this, void 0, void 0, function () {
      var n, r, i, s;
      return a(this, function (o) {
        switch (o.label) {
          case 0:
            return ee(e instanceof g || t("string", e)).throw(Z.invalidParameters), n = q.of(this._iid), r = n.sdkState, i = n.requestQueue, s = new Wt({
              userId: r.userId,
              blockedUserId: e instanceof g ? e.userId : e
            }), [4, i.send(s)];
          case 1:
            return o.sent(), [2];
        }
      });
    });
  }, n.prototype.blockUserWithUserId = function (e) {
    return i(this, void 0, void 0, function () {
      return a(this, function (t) {
        return [2, this.blockUser(e)];
      });
    });
  }, n.prototype.unblockUser = function (e) {
    return i(this, void 0, void 0, function () {
      var n, r, i, s;
      return a(this, function (o) {
        switch (o.label) {
          case 0:
            return ee(e instanceof g || t("string", e)).throw(Z.invalidParameters), n = q.of(this._iid), r = n.sdkState, i = n.requestQueue, s = new Vt({
              userId: r.userId,
              unblockedUserId: e instanceof g ? e.userId : e
            }), [4, i.send(s)];
          case 1:
            return o.sent(), [2];
        }
      });
    });
  }, n.prototype.unblockUserWithUserId = function (e) {
    return i(this, void 0, void 0, function () {
      return a(this, function (t) {
        return [2, this.unblockUser(e)];
      });
    });
  }, n.prototype.getFriendChangeLogsByToken = function (e) {
    return i(this, void 0, void 0, function () {
      var n, r, i, s, o;
      return a(this, function (a) {
        switch (a.label) {
          case 0:
            return ee(t("string", e)).throw(Z.invalidParameters), n = q.of(this._iid), r = n.sdkState, i = n.requestQueue, s = new nn({
              userId: r.userId,
              token: e
            }), [4, i.send(s)];
          case 1:
            return o = a.sent(), [2, o.as(rn).changelogs];
        }
      });
    });
  }, n.prototype.getAllowFriendDiscovery = function () {
    return i(this, void 0, void 0, function () {
      var e, t, n, r, i;
      return a(this, function (s) {
        switch (s.label) {
          case 0:
            return e = q.of(this._iid), t = e.sdkState, n = e.requestQueue, r = new ln({
              userId: t.userId
            }), [4, n.send(r)];
          case 1:
            return i = s.sent(), [2, i.as(hn).allowFriendDiscovery];
        }
      });
    });
  }, n.prototype.setAllowFriendDiscovery = function (e) {
    return i(this, void 0, void 0, function () {
      var n, r, i, s;
      return a(this, function (o) {
        switch (o.label) {
          case 0:
            return ee(t("boolean", e)).throw(Z.invalidParameters), n = q.of(this._iid), r = n.sdkState, i = n.requestQueue, s = new fn({
              userId: r.userId,
              allowFriendDiscovery: e
            }), [4, i.send(s)];
          case 1:
            return o.sent(), [2, e];
        }
      });
    });
  }, n.prototype.uploadFriendDiscoveries = function (e) {
    return i(this, void 0, void 0, function () {
      var t, n, r, i, s;
      return a(this, function (o) {
        switch (o.label) {
          case 0:
            return t = q.of(this._iid), n = t.sdkState, r = t.requestQueue, i = new sn({
              userId: n.userId,
              discoveries: e
            }), [4, r.send(i)];
          case 1:
            return s = o.sent(), [2, s.as(on).friendDiscoveryRequestId];
        }
      });
    });
  }, n.prototype.deleteFriendDiscovery = function (e) {
    return i(this, void 0, void 0, function () {
      return a(this, function (t) {
        return [2, this.deleteFriendDiscoveries([e])];
      });
    });
  }, n.prototype.deleteFriendDiscoveries = function (e) {
    return i(this, void 0, void 0, function () {
      var t, n, r, i;
      return a(this, function (s) {
        switch (s.label) {
          case 0:
            return ee(me("string", e)).throw(Z.invalidParameters), t = q.of(this._iid), n = t.sdkState, r = t.requestQueue, i = new an({
              userId: n.userId,
              discoveryKeys: e
            }), [4, r.send(i)];
          case 1:
            return s.sent(), [2];
        }
      });
    });
  }, n.prototype.addFriends = function (e) {
    return i(this, void 0, void 0, function () {
      var t, n, r, i, s;
      return a(this, function (o) {
        switch (o.label) {
          case 0:
            return ee(me("string", e)).throw(Z.invalidParameters), t = q.of(this._iid), n = t.sdkState, r = t.requestQueue, i = new un({
              userId: n.userId,
              userIds: e
            }), [4, r.send(i)];
          case 1:
            return s = o.sent(), [2, s.as(cn).users];
        }
      });
    });
  }, n.prototype.deleteFriend = function (e) {
    return i(this, void 0, void 0, function () {
      return a(this, function (t) {
        return [2, this.deleteFriends([e])];
      });
    });
  }, n.prototype.deleteFriends = function (e) {
    return i(this, void 0, void 0, function () {
      var t, n, r, i;
      return a(this, function (s) {
        switch (s.label) {
          case 0:
            return ee(me("string", e)).throw(Z.invalidParameters), t = q.of(this._iid), n = t.sdkState, r = t.requestQueue, i = new dn({
              userId: n.userId,
              userIds: e
            }), [4, r.send(i)];
          case 1:
            return s.sent(), [2];
        }
      });
    });
  }, n.prototype.getAllEmoji = function () {
    return i(this, void 0, void 0, function () {
      var e, t, n;
      return a(this, function (r) {
        switch (r.label) {
          case 0:
            return e = q.of(this._iid).requestQueue, t = new vn(), [4, e.send(t)];
          case 1:
            return n = r.sent(), [2, n.as(gn).emojiContainer];
        }
      });
    });
  }, n.prototype.getEmojiCategory = function (e) {
    return i(this, void 0, void 0, function () {
      var t, n, r;
      return a(this, function (i) {
        switch (i.label) {
          case 0:
            return t = q.of(this._iid).requestQueue, n = new mn({
              categoryId: e
            }), [4, t.send(n)];
          case 1:
            return r = i.sent(), [2, r.as(bn).emojiCategory];
        }
      });
    });
  }, n.prototype.getEmoji = function (e) {
    return i(this, void 0, void 0, function () {
      var t, n, r;
      return a(this, function (i) {
        switch (i.label) {
          case 0:
            return t = q.of(this._iid).requestQueue, n = new _n({
              key: e
            }), [4, t.send(n)];
          case 1:
            return r = i.sent(), [2, r.as(yn).emoji];
        }
      });
    });
  }, n.prototype.getUIKitConfiguration = function () {
    return i(this, void 0, void 0, function () {
      var e, t, n, r;
      return a(this, function (i) {
        switch (i.label) {
          case 0:
            return e = q.of(this._iid), ee(e.hasExtension("sb_uikit")).throw(Z.notSupportedError), t = e.requestQueue, n = new wn(), [4, t.send(n)];
          case 1:
            return r = i.sent(), [2, r.as(kn).uikitConfiguration];
        }
      });
    });
  }, n.prototype.getReportCategoryInfoList = function () {
    return i(this, void 0, void 0, function () {
      return a(this, function (e) {
        switch (e.label) {
          case 0:
            return [4, q.of(this._iid).reportCategoryManager.categories()];
          case 1:
            return [2, e.sent()];
        }
      });
    });
  }, n;
}();
export { gt as Emoji, mt as EmojiCategory, bt as EmojiContainer, Cn as default };