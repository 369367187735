<div class="col-xl-12" *ngIf="data">
	<div mat-dialog-content *ngIf="data.data">
		<div>
			<div class="profile-page-header-main" fxLayout="row" fxLayout.lt-md="column">
				<div fxFlex class="row1">
					<div class="name">
						{{data.data['name']}}
					</div>
					<div class="value">
						<span class="inlineText">{{data.data['email0']}}</span>
					</div>

					<div class="value">
						<span class="inlineText">{{data.data['link_to_agencies5']}}</span>
					</div>

					<div class="value">
						<span class="inlineText">{{data.data['newUserType']}}</span>
					</div>

					<div class="value">
						<span class="inlineText">Payee ID: {{data.data.agency['payee_id'] || '-'}}</span>
					</div>

					<div class="value">
						<span class="inlineText">Contract Date: {{data.data.agency['text9'] || '-'}}</span>
					</div>

					<div class="value">
						<span class="inlineText">Business Development Manager: {{data.data.agency['people17'] ||
							'-'}}</span>
					</div>

					<div class="value">
						<span class="inlineText">Licensing Specialist: {{data.data.agency['people8'] || '-'}}</span>
					</div>

					<div class="value">
						<span class="inlineText">Personal Lines Consultant: {{data.data.agency['people2'] ||
							'-'}}</span>
					</div>

					<div class="value">
						<span class="inlineText">Commercial Business Consultant: {{data.data.agency['people4'] ||
							'-'}}</span>
					</div>

					<div class="value">
						<span class="inlineText">Accounting Contact: {{data.data.agency['people40'] || '-'}}</span>
					</div>

					<div class="value">
						<span class="inlineText">Commissions Contact: {{data.data.agency['people1'] || '-'}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div mat-dialog-actions>
		<div fxLayout="row" fxLayoutGap="1vw">
			<div fxFlex>
				<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' |
					translate}}</button>
			</div>
			<div fxFlex fxLayoutAlign="end center" class="dnd">
				<mat-slide-toggle [(ngModel)]="isDnd" color="primary" [disableRipple]="true"
					(change)="saveDnd()">{{'DND' |
					translate}}</mat-slide-toggle>
			</div>
		</div>
	</div>
</div>