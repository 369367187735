export const environment = {
  production: false,
  serverLoginUrl: 'https://staging.api.auth.interactivelife.me/api/',
  serverMainUrl: 'https://staging.api.pgi.interactivelife.me/',
  serverUrl: 'https://staging.api.pgi.interactivelife.me/api/',
  scheduleUrl: 'https://staging.api.pgi.interactivelife.me/api/',
  serverName: 'pgi',
  aliveAppIntervalMinutes: 30, // if you keep app inactive
  aliveCheckAppIntervalMinutes: 60, // if you keep app inactive pop a message
  subServerName: '',
  lang: 'en',
  productId: '40095093-5ee8-44eb-b92a-68cb5ae9d04c',
  projectName: 'pgi',
  profileImageURL: '',
  orgType: 'pgi',
  orgId: '65afbb77ecac8e05c82aff5d',
  appId: '65afbbc4ecac8e05c82aff63',
  locId: '65afbbfbecac8e05c82aff67',
  serverHostUrl: 'https://staging.pgi.interactivelife.me/',
  rollbar: {
    apiKey: '30703184edc14a549aca0166b131f686'
  },
  firebaseVapidKey: 'BDEVC_U_k--zteKIuP2woBFvGgG0msPnXegCHySpYfoMcsu2lyEZYs1PtrY8X138gYVWTaHe-JvW-CgFOjPg99g',
  firebaseConfig: {
    apiKey: "AIzaSyBUWUgZy36VF7X2n_Fx7BQW8eflNDg4s8I",
    authDomain: "pgi-insurance-5792d.firebaseapp.com",
    projectId: "pgi-insurance-5792d",
    storageBucket: "pgi-insurance-5792d.appspot.com",
    messagingSenderId: "664656426140",
    appId: "1:664656426140:web:85f2db99df8038e3fea959",
    measurementId: "G-CQJTJKCXMX",
  },
  firestore_config: {
    channelsDB: 'channels',
    notificationDb: 'notification',
    logDb: 'call_log',
  },
  customKeys: {
    roleOwner: 'Owner',
    rolePrincipleAgent: 'Principle Agent',
    roleCoOwner: 'Co-Owner',
    roleCSR: 'CSR',
    roleAdmin: 'PGI-Admin',
    roleAgent: 'Agent',
    roleAgencyAdmin: 'Agency Admin',
  },
  monday_base_url: 'https://api.monday.com/v2',
  monday_api_url: 'https://staging.api.pgi.interactivelife.me',
  environment: 'staging',
  sendBird: '68192D00-2DCB-453D-A973-B2FB65140D0D'
};
