import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RequestService } from './request.service';
import { environment } from 'src/environments/environment';
import { Messaging, deleteToken, getToken, onMessage } from '@angular/fire/messaging';
import { doc, getDoc, getFirestore, updateDoc } from '@angular/fire/firestore';
import { LoaderService } from './loader.service';
import { SendbirdService } from './sendbird.service';
import { PGIService } from './pgi.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseHandlersService {

  currentMessage = new BehaviorSubject(null);
  chatMessage = new BehaviorSubject(null);
  token: string = '';
  progressRef: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  showHighPriorityDialog: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  subscribedTopics: any = [];

  private isRegistered: boolean = false;

  constructor(private requestService: RequestService, private pgiService: PGIService, private messaging: Messaging, private loader: LoaderService, private sendBirdService: SendbirdService) {

    try {
      onMessage(this.messaging, (payload) => {
        if (payload.data?.sendbird) {
          let sendbirdData = JSON.parse(payload.data.sendbird);
          this.sendBirdService.addUnreadChat(sendbirdData.channel.channel_url);

          this.chatMessage.next(payload);
        }
        else if (payload.data?.userId != this.requestService.currentUser?._id) {
          this.currentMessage.next(payload);
          console.log(payload)
        }
      });

      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.addEventListener('message', (event: any) => {
          console.log('event', event)

          if (event.data.action == 'redirect') {
            window.location.href = event.data.redirect;
          }
          else if (event.data.action == 'incoming-chat-message') {
            let sendbirdData = JSON.parse(event.data.payload.data.sendbird);
            console.log(sendbirdData, event.data.payload)
            this.chatMessage.next(event.data.payload);
            this.sendBirdService.addUnreadChat(sendbirdData.channel.channel_url);
          }
        });
      }
    }
    catch (e) { console.log(e); }
  }

  registerDevice() {
    if ('serviceWorker' in navigator && window.location.pathname.indexOf('/mobileview') == -1 && Notification.permission === 'granted' && !this.token) {
      navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then(() => {
          // Wait until the browser says the service worker is controlling the page
          return navigator.serviceWorker.ready;
        })
        .then((registration) => {
          // this.swRegistration = registration;
          getToken(this.messaging,
            { vapidKey: environment.firebaseVapidKey, serviceWorkerRegistration: registration }).then(
              (currentToken) => {
                this.token = currentToken;
                this.tokenSubject.next(this.token);
                // this.subscribeTopic('pgi-admin', userId);
                console.log('currentToken', currentToken)
              }).catch(e => {
                console.log(e)
                // setTimeout(() => this.registerDevice(currentUserId), 5000);
              });
          console.log('Registration successful, scope is:', registration.scope);
        }).catch((err) => {
          console.log('Service worker registration failed, error:', err);
        });
    }
  }

  sendNotificationToDevice(receiverId: string, payload: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.requestService.postRequest('notify', 'firebase/' + receiverId + '/send', {
        payload: payload
      }, (data, error) => {
        if (data)
          resolve(data);
        if (error)
          reject(error);
      });
    });
  }

  sendNotificationByTopic(topics: any, userId: string, payload: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.requestService.postRequest('notify', 'topic/send', {
        userId: userId,
        topic: topics,
        payload: payload
      }, (data, error) => {
        if (data) {
          resolve(data);
        } else if (error) {
          reject(error);
        }
      });
    });
  }

  sendAlertByTopic(topics: any, userId: string, payload: any, title, body, priority, pushedBy, recipients, role): Promise<void> {
    return new Promise((resolve, reject) => {
      this.requestService.postRequest('notify', 'topic/v1', {
        userId: userId,
        topic: topics.map(topic => topic + `-${environment.environment}`),
        payload: payload,
        title: title,
        body: body,
        pushedBy: pushedBy,
        priority: priority,
        recipients: recipients,
        role: role,
      }, (data, error) => {
        if (data) {
          resolve(data);
        } else if (error) {
          reject(error);
        }
      });
    });
  }

  subscribeTopic(topics: any, userId: string): Promise<void> {
    if (topics?.length && userId) {
      return new Promise((resolve, reject) => {
        if (this.token && topics)
          this.requestService.postRequest('notify', 'subscribe/topic', {
            deviceToken: this.token,
            userId: userId,
            topic: topics.map(str => str.replace(/\s+/g, '-') + `-${environment.environment}`)
          }, (data, error) => {
            if (data) {
              localStorage.setItem('subscribed-topics', topics.join(","));
              this.subscribedTopics = topics;
              resolve(data);
            }
            else if (error)
              reject(error);
          });
      });
    }
  }

  unsubscribeTopic(topics: any, userId: string): Promise<void> {
    if (topics?.length) {
      return new Promise((resolve, reject) => {
        if (this.token && topics)
          this.requestService.postRequest('notify', 'unsubscribe/topic', {
            deviceToken: this.token,
            userId: userId,
            topic: topics
          }, (data, error) => {
            if (data) {
              this.subscribedTopics = this.subscribedTopics.filter(topic => {
                return !topics.includes(topic);
              });
              resolve(data);
            }
            else if (error)
              reject(error);
          });
      });
    }
  }

  unregisterDevice(userId: string, deviceId: string, callback: any, notValidToken: boolean = false): Promise<void> {
    this.isRegistered = false;
    return new Promise((resolve, reject) => {
      // getToken(this.messaging, { vapidKey: environment.firebaseVapidKey, serviceWorkerRegistration: this.swRegistration }).then((currentToken) => {
      deleteToken(this.messaging).then(() => {
        if (this.token && !notValidToken)
          this.requestService.postRequest('notify', 'device/delete', {
            userId: userId,
            deviceId: deviceId,
            type: "Web"
          }, (data, error) => {
            // this.unsubscribeTopic('pgi-admin', userId);
            if (data)
              resolve(data);
            else if (error)
              reject(error);

            if (callback)
              callback();
          });
        else {
          resolve(undefined);

          if (callback)
            callback();
        }
      }).catch((err) => {
        console.log(err);
        if (callback)
          callback();

        reject(err);
      });
      // });
    });
  }

  async logout(notValidToken: boolean = false) {
    this.loader.display(true);
    await this.sendBirdService.disconnect();
    // this.adAuthService.logoutPopup().subscribe(() => {
    if (this.token) {
      if (this.requestService.isUserRoleAdmin())
        await this.sendBirdService.unRegisterToken(this.requestService.currentUser._id, this.token);
      this.isRegistered = false;
      // let unregisterDevicePromise = this.unregisterDevice(this.requestService._currentUser._id, this.token, undefined, notValidToken);

      // await Promise.all([unregisterDevicePromise]).then(() => {
      this.requestService.logOutApi();
      this.pgiService.resetPagesData();
      // }).catch(() => {
      //   this.requestService.logOutApi();
      // });
    }
    else {
      this.requestService.logOutApi();
      this.pgiService.resetPagesData();
    }
  }

  registerToken(token: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (!this.isRegistered && this.requestService.currentUser?._id && token) {
        this.isRegistered = true;
        this.requestService.currentUser.deviceToken = token;
        localStorage.setItem('currentUser', JSON.stringify(this.requestService.currentUser));
        this.requestService.postRequest('notify', 'register', {
          userId: this.requestService.currentUser._id,
          type: "Web",
          deviceId: token
        }, (data, error) => {
          resolve();
        });
      }
      else {
        resolve();
      }
    });
  }

  async removeUserToken() {
    if (this.requestService.currentUser) {
      let currentUserId = this.requestService.currentUser._id;

      let fs = getFirestore();
      const docRef = doc(
        fs,
        environment.firestore_config.notificationDb +
        '/fcm_token/users/' +
        currentUserId
      );
      let userData = await (await getDoc(docRef)).data();

      if (userData) {
        let TokensArray = userData.web_device_tokens;
        if (!TokensArray) TokensArray = [];
        let IsThere = TokensArray.find(
          (str: string) => str === this.requestService.currentUser.deviceToken
        );

        if (IsThere != undefined) {
          TokensArray = TokensArray?.filter(
            (tok: string) => tok != this.requestService.currentUser.deviceToken
          );
          const docRef = doc(
            fs,
            environment.firestore_config.notificationDb +
            '/fcm_token/users/' +
            currentUserId
          );
          updateDoc(docRef, {
            web_device_tokens: TokensArray,
          })
            .then(() => {
              this.logout();
            })
            .catch((err) => {
              throw new Error(err.message || err);
            });
          // }
        } else {
          this.logout();
        }
      }
    }
  }
}