import { e, u as t, V as n, c as r, A as i, af as s, f as a, h as o, g as u, U as c, ag as l, Q as d, a as h, X as f, p, q as m, t as v, a8 as y, ah as _, ai as g, aj as b, C as I, ak as E, al as T, am as w, W as M, an as S, _ as U, b as A, ao as N, a2 as C, s as O, a9 as P, ap as k, aq as R, ar as x, w as D, as as L, at as F, au as q, av as z, aw as B, D as j, F as K, ax as Q, i as G, ay as V, az as H, aA as W, aB as Y, aC as J, aD as X, aE as Z, a7 as $, aF as ee, aG as te, aH as ne, aI as re, aJ as ie, aK as se, n as ae, G as oe, O as ue, aL as ce, x as le, y as de, aM as he, aN as fe, aO as pe, d as me, aP as ve, aQ as ye, aR as _e, aS as ge, aT as be, B as Ie, aU as Ee, aV as Te, aW as we, aX as Me, aY as Se, aZ as Ue, z as Ae, a_ as Ne, a$ as Ce } from "./__bundle-2f7467b8.js";
var Oe,
  Pe = function () {
    function r(e, t, n) {
      var r, i;
      this.width = 0, this.height = 0, this.realWidth = 0, this.realHeight = 0, this._requireAuth = !1, this._iid = e, this.plainUrl = t.url, this.width = t.width, this.height = t.height, this.realWidth = null !== (r = t.real_width) && void 0 !== r ? r : t.width, this.realHeight = null !== (i = t.real_height) && void 0 !== i ? i : t.height, this._requireAuth = n;
    }
    return r.payloadify = function (n) {
      return e(t(n instanceof r ? {
        url: n.plainUrl,
        width: n.width,
        height: n.height,
        real_width: n.realWidth,
        real_height: n.realHeight
      } : {
        url: "",
        width: n.maxWidth,
        height: n.maxHeight,
        real_width: 0,
        real_height: 0
      }));
    }, Object.defineProperty(r.prototype, "url", {
      get: function () {
        var e = n.of(this._iid).sessionManager;
        return this._requireAuth && e.ekey ? "".concat(this.plainUrl, "?auth=").concat(e.ekey) : this.plainUrl;
      },
      enumerable: !1,
      configurable: !0
    }), r;
  }(),
  ke = function (t) {
    function n(n) {
      var r = t.call(this) || this;
      if (r.method = i.POST, r.path = "".concat(s), r.params = e({
        file: n.file,
        channel_url: n.channelUrl
      }), n.thumbnailSizes) for (var a = 0; a < n.thumbnailSizes.length; a++) {
        var o = n.thumbnailSizes[a],
          u = o.maxWidth,
          c = o.maxHeight;
        r.params["thumbnail".concat(a + 1)] = "".concat(u, ",").concat(c);
      }
      return r.requestId = n.requestId, r;
    }
    return r(n, t), n;
  }(a),
  Re = function (e) {
    function t(t, n) {
      var r,
        i,
        s,
        a,
        o = this;
      return (o = e.call(this, t, n) || this).url = n.url, o.fileSize = null !== (r = n.file_size) && void 0 !== r ? r : 0, o.thumbnails = null !== (s = null === (i = n.thumbnails) || void 0 === i ? void 0 : i.map(function (e) {
        return new Pe(t, e, !1);
      })) && void 0 !== s ? s : [], o.requireAuth = null !== (a = n.require_auth) && void 0 !== a && a, o;
    }
    return r(t, e), t;
  }(o),
  xe = function (n) {
    function i(e, t) {
      var r,
        i,
        s,
        a,
        o,
        u = this;
      return (u = n.call(this, e) || this).replyCount = 0, u.memberCount = 0, u.lastRepliedAt = 0, u.updatedAt = 0, u.replyCount = null !== (r = t.reply_count) && void 0 !== r ? r : 0, u.memberCount = null !== (i = t.member_count) && void 0 !== i ? i : 0, u.mostRepliedUsers = t.most_replies && d("object", t.most_replies) ? t.most_replies.map(function (e) {
        return new c(u._iid, e);
      }) : [], u.unreadReplyCount = null !== (s = t.unread_message_count) && void 0 !== s ? s : 0, u.isPushNotificationEnabled = "number" == typeof t.push_enabled ? t.push_enabled > 0 : void 0, u.lastRepliedAt = null !== (a = t.last_replied_at) && void 0 !== a ? a : 0, u.updatedAt = null !== (o = t.updated_at) && void 0 !== o ? o : 0, u;
    }
    return r(i, n), i.payloadify = function (r) {
      return e(t(u(u({}, n.payloadify.call(this, r)), {
        reply_count: r.replyCount,
        unread_message_count: r.unreadReplyCount,
        member_count: r.memberCount,
        most_replies: Array.isArray(r.mostRepliedUsers) ? r.mostRepliedUsers.map(function (e) {
          return c.payloadify(e);
        }) : [],
        push_enabled: "boolean" == typeof r.isPushNotificationEnabled ? r.isPushNotificationEnabled ? 1 : 0 : void 0,
        last_replied_at: r.lastRepliedAt,
        updated_at: r.updatedAt
      })));
    }, Object.defineProperty(i.prototype, "_isUpdateRequired", {
      get: function () {
        return void 0 === this.unreadReplyCount || void 0 === this.isPushNotificationEnabled;
      },
      enumerable: !1,
      configurable: !0
    }), i.prototype._updateFrom = function (e) {
      var t, n;
      this.unreadReplyCount = null !== (t = e.unreadReplyCount) && void 0 !== t ? t : this.unreadReplyCount, this.isPushNotificationEnabled = null !== (n = e.isPushNotificationEnabled) && void 0 !== n ? n : this.isPushNotificationEnabled;
    }, i;
  }(l);
!function (e) {
  e.ADD = "add", e.DELETE = "delete";
}(Oe || (Oe = {}));
var De,
  Le = function (e) {
    var t;
    this.messageId = 0, this.operation = null, this.updatedAt = 0;
    var n = h("string", e.msg_id) ? parseInt(e.msg_id) : e.msg_id,
      r = e.user_id,
      i = e.operation ? e.operation.toLowerCase() : null,
      s = e.reaction,
      a = e.updated_at,
      o = e.count,
      u = e.sampled_user_ids,
      c = null !== (t = e.sampled_user_info) && void 0 !== t ? t : {};
    n && h("string", r) && h("string", i) && f(Oe, i) && h("string", s) && s && h("number", a) && (this.messageId = n, this.userId = r, this.key = s, this.operation = i, this.updatedAt = a, this._count = o, this._sampledUserIds = u, this._sampledUserInfoList = c);
  },
  Fe = function (e) {
    function t(t, n, r) {
      var i,
        s = this;
      return (s = e.call(this, t) || this).userId = n, s.nickname = r.nickname, s.plainProfileUrl = r.profile_url, s.requireAuth = null !== (i = r.require_auth_for_profile_image) && void 0 !== i && i, s;
    }
    return r(t, e), Object.defineProperty(t.prototype, "profileUrl", {
      get: function () {
        var e = n.of(this._iid).sessionManager;
        return this.requireAuth && e.ekey ? "".concat(this.plainProfileUrl, "?auth=").concat(e.ekey) : this.plainProfileUrl;
      },
      enumerable: !1,
      configurable: !0
    }), t.payloadify = function (e) {
      return {
        nickname: e.nickname,
        profile_url: e.plainProfileUrl,
        require_auth_for_profile_image: e.requireAuth
      };
    }, t;
  }(l),
  qe = function (i) {
    function s(e, t) {
      var r,
        s,
        a,
        o,
        u,
        c,
        l,
        f,
        y,
        _ = this;
      (_ = i.call(this, e) || this)._sampledUserInfoList = [], _._count = 0, _._hasCurrentUserReacted = !1;
      var g = t.key,
        b = p([], m(null !== (o = null !== (a = t.sampled_user_ids) && void 0 !== a ? a : t.user_ids) && void 0 !== o ? o : []), !1),
        I = null !== (c = null !== (u = t.last_ts) && void 0 !== u ? u : t.updated_at) && void 0 !== c ? c : 0,
        E = null !== (l = t.is_self_included) && void 0 !== l ? l : b.includes(n.of(e).sdkState.userId),
        T = null !== (f = t.sampled_user_info) && void 0 !== f ? f : {};
      h("string", g) && g && d("string", b) && b.length > 0 && h("number", I) && (_.key = g, _._sampledUserIds = b, _._updatedAt = I, _._count = null !== (y = t.count) && void 0 !== y ? y : b.length, _._hasCurrentUserReacted = E, Object.keys(T).forEach(function (t) {
        _._sampledUserInfoList.push(new Fe(e, t, T[t]));
      }));
      var w = {};
      try {
        for (var M = v(_.sampledUserIds), S = M.next(); !S.done; S = M.next()) {
          w[S.value] = _.updatedAt;
        }
      } catch (e) {
        r = {
          error: e
        };
      } finally {
        try {
          S && !S.done && (s = M.return) && s.call(M);
        } finally {
          if (r) throw r.error;
        }
      }
      return _._version = w, _;
    }
    return r(s, i), Object.defineProperty(s.prototype, "isEmpty", {
      get: function () {
        return 0 === this._count;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(s.prototype, "userIds", {
      get: function () {
        return this._sampledUserIds;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(s.prototype, "sampledUserIds", {
      get: function () {
        return this._sampledUserIds;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(s.prototype, "sampledUserInfoList", {
      get: function () {
        return this._sampledUserInfoList;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(s.prototype, "count", {
      get: function () {
        return this._count;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(s.prototype, "updatedAt", {
      get: function () {
        return this._updatedAt;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(s.prototype, "hasCurrentUserReacted", {
      get: function () {
        return this._hasCurrentUserReacted;
      },
      enumerable: !1,
      configurable: !0
    }), s.payloadify = function (n) {
      var r = {};
      return n._sampledUserInfoList.forEach(function (e) {
        r[e.userId] = Fe.payloadify(e);
      }), e(t({
        key: n.key,
        sampled_user_ids: n._sampledUserIds,
        sampled_user_info: r,
        count: n._count,
        is_self_included: n._hasCurrentUserReacted,
        last_ts: n._updatedAt
      }));
    }, s.prototype.applyEvent = function (e) {
      var t = this;
      if (e.key === this.key && this.updatedAt <= e.updatedAt) if (e._sampledUserIds) this._sampledUserIds = e._sampledUserIds, this._sampledUserInfoList = [], Object.keys(e._sampledUserInfoList).forEach(function (n) {
        t._sampledUserInfoList.push(new Fe(t._iid, n, e._sampledUserInfoList[n]));
      }), this._count = e._count, void 0 !== e._hasCurrentUserReacted && (this._hasCurrentUserReacted = e._hasCurrentUserReacted), this._updatedAt = e.updatedAt;else {
        if (!this._version[e.userId] || this._version[e.userId] <= e.updatedAt) {
          var n = this._sampledUserIds.indexOf(e.userId);
          switch (e.operation) {
            case Oe.ADD:
              n < 0 && this._sampledUserIds.push(e.userId);
              break;
            case Oe.DELETE:
              n >= 0 && this._sampledUserIds.splice(n, 1);
          }
          this._count = this._sampledUserIds.length, this._version[e.userId] = e.updatedAt;
        }
        this._updatedAt = Math.max(this.updatedAt, e.updatedAt);
      }
    }, s;
  }(l),
  ze = function () {
    function n(e) {
      this.secureUrl = null, this.type = null, this.width = 0, this.height = 0, this.alt = null, this.url = e.url, e.secure_url && (this.secureUrl = e.secure_url), e.type && (this.type = e.type), e.width && (this.width = e.width), e.height && (this.height = e.height), e.alt && (this.alt = e.alt);
    }
    return n.payloadify = function (n) {
      var r, i;
      return e(t({
        url: n.url,
        secure_url: n.secureUrl,
        type: n.type,
        width: null !== (r = n.width) && void 0 !== r ? r : 0,
        height: null !== (i = n.height) && void 0 !== i ? i : 0,
        alt: n.alt
      }));
    }, n;
  }(),
  Be = function () {
    function n(e) {
      this.title = null, this.url = null, this.description = null, this.defaultImage = null, e["og:title"] && (this.title = e["og:title"]), e["og:url"] && (this.url = e["og:url"]), e["og:description"] && (this.description = e["og:description"]), e["og:image"] && (this.defaultImage = new ze(e["og:image"]));
    }
    return n.payloadify = function (n) {
      return e(t({
        "og:title": n.title,
        "og:url": n.url,
        "og:description": n.description,
        "og:image": n.defaultImage ? ze.payloadify(n.defaultImage) : null
      }));
    }, n;
  }(),
  je = function () {
    function n(e) {
      var t, n;
      this.volume = 0, this.name = null !== (t = e.name) && void 0 !== t ? t : "default", this.volume = null !== (n = e.volume) && void 0 !== n ? n : 1;
    }
    return n.prototype.serialize = function () {
      return {
        name: this.name,
        volume: this.volume
      };
    }, n.payloadify = function (n) {
      return e(t({
        name: n.name,
        volume: n.volume
      }));
    }, n;
  }();
!function (e) {
  e.GOOD = "good", e.BAD = "bad";
}(De || (De = {}));
var Ke = function () {
    function n(e) {
      this.id = e.id, this.rating = e.rating, this.comment = e.comment;
    }
    return n._getStatus = function (e) {
      return e ? "id" in e ? "SUBMITTED" : "NO_FEEDBACK" : "NOT_APPLICABLE";
    }, n._isSubmitted = function (e) {
      return "SUBMITTED" === n._getStatus(e);
    }, n._getPayloadByStatus = function (e) {
      return "NO_FEEDBACK" === e ? {} : null;
    }, n.payloadify = function (n) {
      return e(t({
        id: n.id,
        rating: n.rating,
        comment: n.comment
      }));
    }, n;
  }(),
  Qe = function (n) {
    function i(e, t) {
      var r,
        i = this;
      return (i = n.call(this, e, t) || this).isBlockedByMe = !1, i.role = f(y, t.role) ? t.role : y.NONE, i.isBlockedByMe = null !== (r = t.is_blocked_by_me) && void 0 !== r && r, i;
    }
    return r(i, n), i.payloadify = function (r) {
      return e(t(u(u({}, n.payloadify.call(this, r)), {
        role: r.role,
        is_blocked_by_me: r.isBlockedByMe
      })));
    }, i;
  }(c),
  Ge = function () {
    function n(e) {
      this.key = e.key, this.value = d("string", e.value) ? p([], m(e.value), !1) : [];
    }
    return n.payloadify = function (n) {
      var r;
      return e(t({
        key: n.key,
        value: null !== (r = n.value) && void 0 !== r ? r : []
      }));
    }, n;
  }(),
  Ve = function (e) {
    switch (e) {
      case g.BASE:
        return "";
      case g.USER:
        return "MESG";
      case g.FILE:
        return "FILE";
      case g.ADMIN:
        return "ADMM";
    }
  },
  He = function (n) {
    function i(e, t) {
      var r,
        i,
        s,
        a,
        o,
        u,
        l,
        d,
        h,
        p = this;
      (p = n.call(this, e) || this).channelType = I.BASE, p.messageType = g.BASE, p.mentionType = null, p.mentionedUsers = null, p.mentionedUserIds = null, p.mentionedMessageTemplate = void 0, p.metaArrays = [], p.extendedMessage = {}, p.createdAt = 0, p.updatedAt = 0, p.channelUrl = t.channel_url, p.channelType = f(I, t.channel_type) ? t.channel_type : I.GROUP, t.channel && (t.channel.channel_url && (p.channelUrl = t.channel.channel_url), t.channel.channel_type && (p.channelType = t.channel.channel_type)), p.data = null !== (r = t.data) && void 0 !== r ? r : "", p.customType = null !== (i = t.custom_type) && void 0 !== i ? i : "", p.mentionType = f(E, t.mention_type) ? t.mention_type : null, p.mentionedUsers = t.mentioned_users ? t.mentioned_users.map(function (e) {
        return new c(p._iid, e);
      }) : null, p.mentionedUserIds = null !== (s = t.mentioned_user_ids) && void 0 !== s ? s : null, p.mentionedUsers && !p.mentionedUserIds && (p.mentionedUserIds = p.mentionedUsers.map(function (e) {
        return e.userId;
      })), p.mentionedMessageTemplate = t.mentioned_message_template;
      var m = null !== (a = t.metaarray) && void 0 !== a ? a : {},
        v = null !== (o = t.metaarray_key_order) && void 0 !== o ? o : Object.keys(m).sort(function (e, t) {
          return e.localeCompare(t);
        });
      p.metaArrays = [];
      for (var y = 0; y < v.length; y++) {
        var _ = v[y];
        p.metaArrays.push(new Ge({
          key: _,
          value: m[_] || []
        }));
      }
      return t.sorted_metaarray && (p.metaArrays = t.sorted_metaarray.map(function (e) {
        return new Ge(e);
      })), p.extendedMessage = null !== (u = t.extended_message) && void 0 !== u ? u : {}, p.extendedMessagePayload = t.extended_message_payload, p.createdAt = null !== (d = null !== (l = t.created_at) && void 0 !== l ? l : t.ts) && void 0 !== d ? d : 0, p.updatedAt = null !== (h = t.updated_at) && void 0 !== h ? h : 0, p;
    }
    return r(i, n), i.payloadify = function (r) {
      var i, s;
      return e(t(u(u({}, n.payloadify.call(this, r)), {
        channel_url: r.channelUrl,
        channel_type: r.channelType,
        type: Ve(r.messageType),
        data: r.data,
        custom_type: r.customType,
        mention_type: r.mentionType,
        mentioned_user_ids: r.mentionedUserIds,
        mentioned_users: null === (i = r.mentionedUsers) || void 0 === i ? void 0 : i.map(function (e) {
          return c.payloadify(e);
        }),
        mentioned_message_template: r.mentionedMessageTemplate,
        sorted_metaarray: null === (s = r.metaArrays) || void 0 === s ? void 0 : s.map(function (e) {
          return Ge.payloadify(e);
        }),
        extended_message: r.extendedMessage,
        extended_message_payload: r.extendedMessagePayload,
        created_at: r.createdAt,
        updated_at: r.updatedAt
      })));
    }, i.prototype.isIdentical = function (e) {
      return !0;
    }, i.prototype.isEqual = function (e) {
      return _(this, e);
    }, i.prototype.isUserMessage = function () {
      return this.messageType === g.USER;
    }, i.prototype.isFileMessage = function () {
      return this.messageType === g.FILE && !Object.prototype.hasOwnProperty.call(this, "fileInfoList");
    }, i.prototype.isMultipleFilesMessage = function () {
      return this.messageType === g.FILE && Object.prototype.hasOwnProperty.call(this, "fileInfoList");
    }, i.prototype.isAdminMessage = function () {
      return this.messageType === g.ADMIN;
    }, i.prototype.serialize = function () {
      return b(this);
    }, i.prototype.getMetaArraysByKeys = function (e) {
      return this.metaArrays.filter(function (t) {
        return e.includes(t.key);
      });
    }, i;
  }(l),
  We = function (e) {
    function t(t, n) {
      var r,
        i,
        s = this;
      return (s = e.call(this, t) || this).channelUrl = null !== (r = n.channel_url) && void 0 !== r ? r : "", s.channelType = null !== (i = n.channel_type) && void 0 !== i ? i : I.GROUP, s.reader = new c(s._iid, n.user), s.readAt = n.ts, s;
    }
    return r(t, e), t;
  }(l),
  Ye = function (e) {
    function t(t) {
      var n = t.channelUrl;
      return e.call(this, {
        code: "READ",
        ackRequired: !0,
        payload: {
          channel_url: n
        }
      }) || this;
    }
    return r(t, e), t;
  }(T),
  Je = function (n) {
    function s(r) {
      var s = n.call(this) || this,
        a = r.channelUrl,
        o = r.channelType,
        u = r.userId,
        c = r.notificationIds;
      return s.method = i.PUT, s.path = "".concat(w(o), "/").concat(encodeURIComponent(a), "/messages/mark_as_read"), s.params = e(t({
        user_id: u,
        message_ids: c
      })), s;
    }
    return r(s, n), s;
  }(a),
  Xe = function (e) {
    function t(t, n, r) {
      var i = e.call(this, t, "READ", r) || this;
      return i.readStatus = new We(t, r), i;
    }
    return r(t, e), t;
  }(M),
  Ze = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.readAt = n.ts, r.unreadMessageCount = n.unread_message_count, r;
    }
    return r(t, e), t;
  }(o),
  $e = function (e) {
    function t(t) {
      var n = t.channelUrl,
        r = t.messageId;
      return e.call(this, {
        code: "READ",
        ackRequired: !0,
        payload: {
          channel_url: n,
          parent_message_id: r
        }
      }) || this;
    }
    return r(t, e), t;
  }(T),
  et = function (e) {
    function t(t) {
      var n = t.channelType,
        r = t.channelUrl,
        s = t.messageId,
        a = t.pushEnabled,
        o = e.call(this) || this;
      return o.method = i.PUT, o.path = "".concat(w(n), "/").concat(encodeURIComponent(r), "/messages/").concat(s, "/thread_push"), o.params = {
        push_enabled: a
      }, o;
    }
    return r(t, e), t;
  }(a);
!function (e) {
  function t(t, n) {
    return e.call(this, t, n) || this;
  }
  r(t, e);
}(o);
var tt = function (e) {
    function t(t) {
      var n = t.channelType,
        r = t.channelUrl,
        s = t.messageId,
        a = t.rating,
        o = t.comment,
        u = e.call(this) || this;
      return u.method = i.POST, u.path = "".concat(w(n), "/").concat(encodeURIComponent(r), "/messages/").concat(s, "/feedbacks"), u.params = {
        rating: a,
        comment: o
      }, u;
    }
    return r(t, e), t;
  }(a),
  nt = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.feedback = new Ke(n), r;
    }
    return r(t, e), t;
  }(o),
  rt = function (e) {
    function t(t) {
      var n = t.channelType,
        r = t.channelUrl,
        s = t.messageId,
        a = t.feedbackId,
        o = t.rating,
        u = t.comment,
        c = e.call(this) || this;
      return c.method = i.PUT, c.path = "".concat(w(n), "/").concat(encodeURIComponent(r), "/messages/").concat(s, "/feedbacks/").concat(a), c.params = {
        rating: o,
        comment: u
      }, c;
    }
    return r(t, e), t;
  }(a),
  it = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.feedback = new Ke(n), r;
    }
    return r(t, e), t;
  }(o),
  st = function (e) {
    function t(t) {
      var n = t.channelType,
        r = t.channelUrl,
        s = t.messageId,
        a = t.feedbackId,
        o = e.call(this) || this;
      return o.method = i.DELETE, o.path = "".concat(w(n), "/").concat(encodeURIComponent(r), "/messages/").concat(s, "/feedbacks/").concat(a), o;
    }
    return r(t, e), t;
  }(a);
!function (e) {
  function t(t, n) {
    var r = e.call(this, t, n) || this;
    return r.feedback = new Ke(n), r;
  }
  r(t, e);
}(o);
var at,
  ot,
  ut = function (i) {
    function s(e, t) {
      var n,
        r,
        s,
        a,
        o,
        c = this;
      return (c = i.call(this, e) || this).plainUrl = "", c.fileName = null, c.mimeType = null, c.fileSize = 0, c.thumbnails = [], c._requireAuth = !1, c.plainUrl = null !== (n = t.url) && void 0 !== n ? n : "", c.fileName = null !== (r = t.file_name) && void 0 !== r ? r : null, c.mimeType = null !== (s = t.file_type) && void 0 !== s ? s : null, c.fileSize = null !== (a = t.file_size) && void 0 !== a ? a : 0, c._requireAuth = null !== (o = t.require_auth) && void 0 !== o && o, c.thumbnails = t.thumbnails ? t.thumbnails.map(function (e) {
        var t;
        return new Pe(c._iid, "string" == typeof e ? {
          url: e,
          width: 0,
          height: 0
        } : u(u({}, e), {
          url: (null !== (t = e.url) && void 0 !== t ? t : "").split("auth=")[0]
        }), c._requireAuth);
      }) : [], c;
    }
    return r(s, i), s.payloadify = function (n) {
      var r;
      return e(t({
        url: n.plainUrl,
        file_name: n.fileName,
        file_type: n.mimeType,
        file_size: n.fileSize,
        thumbnails: null === (r = n.thumbnails) || void 0 === r ? void 0 : r.map(function (e) {
          var t;
          return {
            url: (null !== (t = e.plainUrl) && void 0 !== t ? t : "").split("auth=")[0],
            width: e.width,
            height: e.height,
            real_width: e.realWidth,
            real_height: e.realHeight
          };
        }),
        require_auth: n._requireAuth
      }));
    }, Object.defineProperty(s.prototype, "url", {
      get: function () {
        var e = n.of(this._iid).sessionManager;
        return this._requireAuth && e.ekey ? "".concat(this.plainUrl, "?auth=").concat(e.ekey) : this.plainUrl;
      },
      enumerable: !1,
      configurable: !0
    }), s;
  }(l),
  ct = function (e) {
    var t = e.channelUrl,
      n = e.channelType,
      r = e.parentMessage;
    this.channelUrl = t, this.channelType = n, this.parentMessage = r;
  };
!function (e) {
  e.NUMBER = "number", e.ENUM = "enum", e.TEXT = "text", e.REGEX = "regex", e.DECIMAL_PLACE = "decimal_place";
}(at || (at = {})), function (e) {
  e.TEXT = "text", e.TEXTAREA = "textarea", e.NUMBER = "number", e.PHONE = "phone", e.EMAIL = "email", e.CHIP = "chip";
}(ot || (ot = {}));
var lt,
  dt,
  ht = function () {
    function n(e, t) {
      var n = t.id,
        r = t.name,
        i = t.items,
        s = t.version,
        a = t.is_submitted;
      this.messageId = e, this.id = n, this.name = r, this.items = i.map(function (e) {
        return new ft(e);
      }).sort(function (e, t) {
        return e.sortOrder - t.sortOrder;
      }), this.version = null != s ? s : 1, this._isSubmitted = a;
    }
    return Object.defineProperty(n.prototype, "isSubmitted", {
      get: function () {
        var e;
        return null !== (e = this._isSubmitted) && void 0 !== e ? e : this.items.some(function (e) {
          return Array.isArray(e.submittedValues);
        });
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(n.prototype, "isSubmittable", {
      get: function () {
        return this.items.every(function (e) {
          return e.required ? !!(Array.isArray(e.draftValues) && e.draftValues.length > 0) && e.isValid(e.draftValues) : !(Array.isArray(e.draftValues) && e.draftValues.length > 0) || e.isValid(e.draftValues);
        });
      },
      enumerable: !1,
      configurable: !0
    }), n.payloadify = function (n) {
      return e(t({
        id: n.id,
        name: n.name,
        items: n.items.map(function (e) {
          return ft.payloadify(e);
        }),
        version: n.version,
        is_submitted: n._isSubmitted
      }));
    }, n.isMessageFormPayload = function (e) {
      return Boolean(null == e ? void 0 : e.message_form);
    }, n;
  }(),
  ft = function () {
    function n(t) {
      var n = t.id,
        r = t.name,
        i = t.required,
        s = t.sort_order,
        a = t.placeholder,
        o = t.style,
        u = t.item_type,
        c = t.validators,
        l = t.value,
        d = t.values;
      this.id = n, this.name = r, this.required = i, this.sortOrder = s, this.placeholder = a, 0 === Object.keys(o).length ? this.style = {
        layout: u
      } : this.style = e({
        layout: o.layout,
        options: o.options,
        defaultOptions: o.default_options,
        resultCount: o.result_count
      }), d ? this.submittedValues = d : l && (this.submittedValues = [l]), this._validators = c;
    }
    return n.prototype.isValid = function (e) {
      var t = this;
      return e.every(function (e) {
        var n = function (e, t) {
          return t.every(function (t) {
            switch (t.key) {
              case at.NUMBER:
                var n = Number(e);
                return !(isNaN(n) || t.min && "number" != typeof t.min || t.max && "number" != typeof t.max || "number" == typeof t.min && n < t.min || "number" == typeof t.max && n > t.max);
              case at.ENUM:
                return "string" == typeof e && (!(t.enums && Array.isArray(t.enums) && t.enums.length > 0 && !t.enums.includes(e)) || t.allow_other);
              case at.TEXT:
                return !("string" != typeof e || "number" == typeof t.min_length && e.length < t.min_length || "number" == typeof t.max_length && e.length > t.max_length);
              case at.REGEX:
                if ("string" != typeof e) return !1;
                if (t.regex) {
                  if ("string" != typeof t.regex) return !1;
                  if (!new RegExp(t.regex).test(e)) return !1;
                }
                return !0;
              case at.DECIMAL_PLACE:
                return 0 !== e.length && (n = Number(e), !(isNaN(n) || (!t.max_decimal_place || "number" == typeof t.max_decimal_place) && t.max_decimal_place && !function (e, t) {
                  var n = e.toString(),
                    r = n.indexOf(".");
                  return -1 === r || n.slice(r + 1).length <= t;
                }(n, t.max_decimal_place)));
              default:
                return !0;
            }
          });
        }(e, t._validators);
        return n;
      });
    }, n.payloadify = function (n) {
      return e(t({
        id: n.id,
        name: n.name,
        required: n.required,
        sort_order: n.sortOrder,
        placeholder: n.placeholder,
        style: e({
          layout: n.style.layout,
          options: n.style.options,
          default_options: n.style.defaultOptions,
          result_count: n.style.resultCount
        }),
        item_type: n.style.layout,
        validators: n._validators,
        values: n.submittedValues
      }));
    }, n;
  }(),
  pt = function (t) {
    function n(n) {
      var r = t.call(this) || this;
      return r.method = i.POST, r.path = "".concat(S, "/").concat(n.formId, "/submit"), r.params = e({
        message_id: n.messageId,
        items: n.items
      }), r;
    }
    return r(n, t), n;
  }(a);
!function (e) {
  e.PENDING = "pending", e.SENT = "sent", e.FAILED = "failed", e.CANCELED = "canceled";
}(lt || (lt = {})), function (e) {
  e.PENDING = "pending", e.IN_QUEUE = "in_queue", e.SENT = "sent", e.FAILED = "failed", e.CANCELED = "canceled", e.REMOVED = "removed";
}(dt || (dt = {}));
var mt,
  vt = function (i) {
    function s(e, t) {
      var n,
        r,
        s,
        a,
        o,
        u,
        c,
        l,
        d,
        h = this;
      return (h = i.call(this, e, t) || this).parentMessage = null, h.silent = !1, h.isOperatorMessage = !1, h.threadInfo = null, h.reactions = [], h.appleCriticalAlertOptions = null, h.scheduledInfo = null, h.suggestedReplies = null, h.myFeedback = null, h.myFeedbackStatus = "NOT_APPLICABLE", h.messageForm = null, h._isContinuousMessages = !1, h._scheduledStatus = null, h.messageId = null !== (r = null !== (n = t.msg_id) && void 0 !== n ? n : t.message_id) && void 0 !== r ? r : 0, h.parentMessageId = null !== (s = "string" == typeof t.parent_message_id ? parseInt(t.parent_message_id) : t.parent_message_id) && void 0 !== s ? s : 0, h.threadInfo = t.thread_info ? new xe(h._iid, t.thread_info) : null, t.reactions_summary ? h.reactions = t.reactions_summary.map(function (t) {
        return new qe(e, t);
      }) : t.reactions ? h.reactions = t.reactions.map(function (t) {
        return new qe(e, t);
      }) : h.reactions = [], h.ogMetaData = t.og_tag ? new Be(t.og_tag) : null, h.silent = null !== (a = t.silent) && void 0 !== a && a, h.isOperatorMessage = null !== (o = t.is_op_msg) && void 0 !== o && o, h.appleCriticalAlertOptions = t.apple_critical_alert_options ? new je(t.apple_critical_alert_options) : null, "number" == typeof t.scheduled_message_id && "number" == typeof t.scheduled_at && t.scheduled_status && (h.scheduledInfo = {
        scheduledMessageId: t.scheduled_message_id,
        scheduledAt: t.scheduled_at
      }, h._scheduledStatus = t.scheduled_status), h._isContinuousMessages = null !== (u = t.is_continuous_messages) && void 0 !== u && u, h.myFeedback = Ke._isSubmitted(t.feedback) ? new Ke(t.feedback) : null, h.myFeedbackStatus = Ke._getStatus(t.feedback), h.suggestedReplies = null !== (l = null === (c = t.extended_message_payload) || void 0 === c ? void 0 : c.suggested_replies) && void 0 !== l ? l : null, h.messageForm = ht.isMessageFormPayload(t.extended_message_payload) ? new ht(h.messageId, t.extended_message_payload.message_form) : null, h.message = null !== (d = t.message) && void 0 !== d ? d : "", h;
    }
    return r(s, i), s.payloadify = function (n) {
      var r, s;
      return e(t(u(u({}, i.payloadify.call(this, n)), {
        message_id: n.messageId,
        parent_message_id: n.parentMessageId,
        thread_info: n.threadInfo ? xe.payloadify(n.threadInfo) : null,
        reactions_summary: n.reactions.map(function (e) {
          return qe.payloadify(e);
        }),
        og_tag: n.ogMetaData ? Be.payloadify(n.ogMetaData) : null,
        silent: n.silent,
        is_op_msg: n.isOperatorMessage,
        apple_critical_alert_options: n.appleCriticalAlertOptions ? je.payloadify(n.appleCriticalAlertOptions) : null,
        scheduled_message_id: null === (r = n.scheduledInfo) || void 0 === r ? void 0 : r.scheduledMessageId,
        scheduled_at: null === (s = n.scheduledInfo) || void 0 === s ? void 0 : s.scheduledAt,
        scheduled_status: n._scheduledStatus,
        feedback: n.myFeedback ? Ke.payloadify(n.myFeedback) : Ke._getPayloadByStatus(n.myFeedbackStatus),
        message: n.message
      })));
    }, s._getParentMessageInfoPayload = function (n) {
      return e(t({
        type: Ve(n.messageType),
        ts: n.createdAt,
        user: n.sender ? Qe.payloadify(n.sender) : null,
        message: n.message,
        file: {
          url: n.plainUrl,
          name: n.name,
          type: n.type,
          require_auth: n.requireAuth
        },
        files: Array.isArray(n.fileInfoList) ? n.fileInfoList.map(function (e) {
          return ut.payloadify(e);
        }) : null
      }));
    }, s.prototype.isIdentical = function (e) {
      return this.messageId === e.messageId;
    }, s.prototype.applyThreadInfoUpdateEvent = function (e) {
      return this.messageId === e.targetMessageId && (e.threadInfo._isUpdateRequired && this.threadInfo && e.threadInfo._updateFrom(this.threadInfo), this.threadInfo = e.threadInfo, !0);
    }, s.prototype._findReactionIndex = function (e) {
      for (var t = 0; t < this.reactions.length; t++) if (this.reactions[t].key === e) return t;
      return -1;
    }, s.prototype.applyReactionEvent = function (e) {
      var t = n.of(this._iid).sdkState;
      if (this.messageId === e.messageId) switch (e.operation) {
        case Oe.ADD:
          e.userId === t.userId && (e._hasCurrentUserReacted = !0), -1 !== (r = this._findReactionIndex(e.key)) ? this.reactions[r].applyEvent(e) : e._sampledUserIds ? this.reactions.push(new qe(this._iid, {
            key: e.key,
            sampled_user_ids: e._sampledUserIds,
            sampled_user_info: e._sampledUserInfoList,
            count: e._count,
            is_self_included: e._hasCurrentUserReacted,
            last_ts: e.updatedAt
          })) : this.reactions.push(new qe(this._iid, {
            key: e.key,
            user_ids: [e.userId],
            updated_at: e.updatedAt
          }));
          break;
        case Oe.DELETE:
          var r;
          -1 !== (r = this._findReactionIndex(e.key)) && (e._sampledUserIds ? 0 === e._count ? this.reactions.splice(r, 1) : (e.userId === t.userId && (e._hasCurrentUserReacted = !1), this.reactions[r].applyEvent(e)) : (this.reactions[r].applyEvent(e), this.reactions[r].isEmpty && this.reactions.splice(r, 1)));
      }
    }, s.prototype.applyParentMessage = function (e) {
      if (!this.parentMessage) return this.parentMessage = e, !0;
      if (this.parentMessageId === e.messageId) {
        var t = this.parentMessage.updatedAt;
        if (e.updatedAt >= t) return this.parentMessage = e, !0;
      }
      return !1;
    }, s.prototype._triggerThreadInfoUpdatedEvent = function () {
      return U(this, void 0, void 0, function () {
        var e;
        return A(this, function (t) {
          return (e = n.of(this._iid).dispatcher).dispatch(new ct({
            channelType: I.GROUP,
            channelUrl: this.channelUrl,
            parentMessage: this
          })), e.dispatch(new N({
            messages: [this],
            source: C.EVENT_MESSAGE_THREADINFO_UPDATED
          })), [2];
        });
      });
    }, s.prototype.markThreadAsRead = function () {
      return U(this, void 0, void 0, function () {
        var e, t;
        return A(this, function (r) {
          switch (r.label) {
            case 0:
              return e = n.of(this._iid).requestQueue, t = new $e({
                channelUrl: this.channelUrl,
                messageId: this.messageId
              }), [4, e.send(t)];
            case 1:
              return r.sent(), this.threadInfo && 0 !== this.threadInfo.unreadReplyCount ? (this.threadInfo.unreadReplyCount = 0, [4, this._triggerThreadInfoUpdatedEvent()]) : [3, 3];
            case 2:
              r.sent(), r.label = 3;
            case 3:
              return [2];
          }
        });
      });
    }, s.prototype.setPushNotificationEnabled = function (e) {
      return U(this, void 0, void 0, function () {
        var t, r;
        return A(this, function (i) {
          switch (i.label) {
            case 0:
              return t = n.of(this._iid).requestQueue, r = new et({
                channelUrl: this.channelUrl,
                channelType: this.channelType,
                messageId: this.messageId,
                pushEnabled: e
              }), [4, t.send(r)];
            case 1:
              return i.sent(), this.threadInfo && this.threadInfo.isPushNotificationEnabled !== e && (this.threadInfo.isPushNotificationEnabled = e), [2];
          }
        });
      });
    }, s.prototype.submitMessageForm = function (e) {
      return U(this, void 0, void 0, function () {
        var t, r, i, s, a;
        return A(this, function (o) {
          switch (o.label) {
            case 0:
              if (t = n.of(this._iid).requestQueue, r = null, i = null, s = this.messageForm, !e && s) {
                if (!s.isSubmittable) throw new O({
                  code: P.INVALID_PARAMETER,
                  message: "The form is not submittable. Please check all form items are valid before submitting."
                });
                if (s.isSubmitted) throw new O({
                  code: P.INVALID_PARAMETER,
                  message: "The form has already been submitted."
                });
                r = s.id, i = s.items.reduce(function (e, t) {
                  return e.push({
                    form_item_id: t.id,
                    values: Array.isArray(t.draftValues) ? t.draftValues : []
                  }), e;
                }, []);
              } else e && (r = e.formId, i = Object.entries(e.answers).reduce(function (e, t) {
                var n = m(t, 2),
                  r = n[0],
                  i = n[1],
                  s = Number(r);
                if (Number.isNaN(s)) throw new O({
                  code: P.INVALID_PARAMETER,
                  message: "Keys in answers must be numeric string."
                });
                return e.push({
                  form_item_id: s,
                  value: i
                }), e;
              }, []));
              if (!r || !i) throw new O({
                code: P.INVALID_PARAMETER,
                message: "Missing one or more required inputs: formId, answers."
              });
              return a = new pt({
                messageId: this.messageId,
                formId: r,
                items: i
              }), [4, t.send(a)];
            case 1:
              return o.sent(), [2];
          }
        });
      });
    }, s.prototype.submitFeedback = function (e) {
      return U(this, void 0, void 0, function () {
        var t, r, i, s, a, o;
        return A(this, function (u) {
          switch (u.label) {
            case 0:
              return t = n.of(this._iid), r = t.requestQueue, i = t.dispatcher, s = new tt({
                channelUrl: this.channelUrl,
                channelType: this.channelType,
                messageId: this.messageId,
                rating: e.rating,
                comment: e.comment
              }), [4, r.send(s)];
            case 1:
              return a = u.sent(), o = a.as(nt), this.myFeedback = o.feedback, this.myFeedbackStatus = "SUBMITTED", i.dispatch(new N({
                messages: [this],
                source: C.EVENT_MESSAGE_FEEDBACK_ADDED
              })), [2];
          }
        });
      });
    }, s.prototype.updateFeedback = function (e) {
      return U(this, void 0, void 0, function () {
        var t, r, i, s, a, o;
        return A(this, function (u) {
          switch (u.label) {
            case 0:
              return t = n.of(this._iid), r = t.requestQueue, i = t.dispatcher, s = new rt({
                channelUrl: this.channelUrl,
                channelType: this.channelType,
                messageId: this.messageId,
                feedbackId: e.id,
                rating: e.rating,
                comment: e.comment
              }), [4, r.send(s)];
            case 1:
              return a = u.sent(), o = a.as(it), this.myFeedback = o.feedback, this.myFeedbackStatus = "SUBMITTED", i.dispatch(new N({
                messages: [this],
                source: C.EVENT_MESSAGE_FEEDBACK_UPDATED
              })), [2];
          }
        });
      });
    }, s.prototype.deleteFeedback = function (e) {
      return U(this, void 0, void 0, function () {
        var t, r, i, s;
        return A(this, function (a) {
          switch (a.label) {
            case 0:
              return t = n.of(this._iid), r = t.requestQueue, i = t.dispatcher, s = new st({
                channelUrl: this.channelUrl,
                channelType: this.channelType,
                messageId: this.messageId,
                feedbackId: e
              }), [4, r.send(s)];
            case 1:
              return a.sent(), this.myFeedback = null, this.myFeedbackStatus = "NO_FEEDBACK", i.dispatch(new N({
                messages: [this],
                source: C.EVENT_MESSAGE_FEEDBACK_DELETED
              })), [2];
          }
        });
      });
    }, s;
  }(He),
  yt = function (n) {
    function i(e, t) {
      var r,
        i,
        s,
        a,
        o = this;
      if ((o = n.call(this, e, t) || this).reqId = "", o.replyToChannel = !1, o.errorCode = 0, o.sender = t.user ? new Qe(o._iid, t.user) : t.sender_id, o.reqId = null !== (i = null !== (r = t.req_id) && void 0 !== r ? r : t.request_id) && void 0 !== i ? i : "", o.replyToChannel = null !== (s = t.is_reply_to_channel) && void 0 !== s && s, t.request_state && f(k, t.request_state) && (o.sendingStatus = t.request_state), !o.sendingStatus) if (o.messageId > 0) o.sendingStatus = k.SUCCEEDED;else if (o.scheduledInfo) switch (t.scheduled_status && (o._scheduledStatus = t.scheduled_status), t.scheduled_status) {
        case dt.SENT:
        case dt.IN_QUEUE:
          o.sendingStatus = k.SUCCEEDED;
          break;
        case dt.PENDING:
          o.sendingStatus = k.SCHEDULED;
          break;
        case dt.FAILED:
        case dt.REMOVED:
          o.sendingStatus = k.FAILED;
          break;
        case dt.CANCELED:
          o.sendingStatus = k.CANCELED;
      } else o.sendingStatus = k.PENDING;
      return o.errorCode = null !== (a = t.error_code) && void 0 !== a ? a : 0, o._messageToken = t.mesg_token, o;
    }
    return r(i, n), i.payloadify = function (r) {
      return e(t(u(u({}, n.payloadify.call(this, r)), {
        user: Qe.payloadify(r.sender),
        req_id: r.reqId,
        is_reply_to_channel: r.replyToChannel,
        request_state: r.sendingStatus,
        error_code: r.errorCode,
        mesg_token: r._messageToken
      })));
    }, Object.defineProperty(i.prototype, "isResendable", {
      get: function () {
        return (this.sendingStatus === k.FAILED || this.sendingStatus === k.CANCELED) && R(this.errorCode);
      },
      enumerable: !1,
      configurable: !0
    }), i.prototype.isIdentical = function (e) {
      return this.messageId > 0 && e.messageId > 0 ? this.messageId === e.messageId : this.reqId === e.reqId;
    }, i;
  }(vt),
  _t = {
    prevResultSize: 0,
    nextResultSize: 0,
    isInclusive: !1,
    reverse: !1,
    messageTypeFilter: x.ALL,
    customTypesFilter: void 0,
    senderUserIdsFilter: void 0,
    includeReactions: !1,
    includeMetaArray: !1,
    includeParentMessageInfo: !1
  },
  gt = function (e) {
    return h("number", e.prevResultSize) && h("number", e.nextResultSize) && h("boolean", e.isInclusive) && h("boolean", e.reverse) && h("string", e.messageTypeFilter) && f(x, e.messageTypeFilter) && d("string", e.customTypesFilter, !0) && d("string", e.senderUserIdsFilter, !0) && h("boolean", e.includeMetaArray) && h("boolean", e.includeReactions) && h("boolean", e.includeParentMessageInfo);
  },
  bt = function (n) {
    function i(r, i) {
      var s,
        a,
        o,
        c = this;
      if ((c = n.call(this, r, i) || this).translations = {}, c.messageType = g.ADMIN, c.translations = null !== (s = i.translations) && void 0 !== s ? s : {}, i.parent_message_info) {
        var l = i.parent_message_info;
        c.parentMessage = Ut(r, e(t(u(u({}, l), {
          message_id: c.parentMessageId,
          channel_url: c.channelUrl,
          channel_type: c.channelType,
          file: l.file,
          url: null === (a = l.file) || void 0 === a ? void 0 : a.url,
          require_auth: null === (o = l.file) || void 0 === o ? void 0 : o.require_auth
        }))));
      }
      return c;
    }
    return r(i, n), i.payloadify = function (r) {
      return e(t(u(u({}, n.payloadify.call(this, r)), {
        translations: r.translations,
        parent_message_info: r.parentMessage ? n._getParentMessageInfoPayload.call(this, r.parentMessage) : null
      })));
    }, i.prototype.getThreadedMessagesByTimestamp = function (e, t) {
      return U(this, void 0, void 0, function () {
        var n;
        return A(this, function (r) {
          switch (r.label) {
            case 0:
              return n = u(u({}, _t), t), D(this.messageId > 0 && h("number", e) && gt(n)).throw(O.invalidParameters), [4, ra.of(this._iid).getThreadedMessagesByTimestamp(this, e, n)];
            case 1:
              return [2, r.sent()];
          }
        });
      });
    }, i;
  }(vt),
  It = function () {
    function n(e) {
      var t, n;
      this.detail = {}, this.type = null !== (t = e.type) && void 0 !== t ? t : "", this.vendor = null !== (n = e.vendor) && void 0 !== n ? n : "", e.detail && h("object", e.detail) && !Array.isArray(e.detail) && (this.detail = e.detail);
    }
    return n.payloadify = function (n) {
      return e(t({
        type: n.type,
        vendor: n.vendor,
        detail: n.detail
      }));
    }, n;
  }(),
  Et = function () {
    function t(e) {
      this.status = e.status, e.original_message_info && (this.originalMessageInfo = {
        createdAt: e.original_message_info.ts,
        messageId: e.original_message_info.id
      });
    }
    return t.payloadify = function (t) {
      return e({
        status: t.status,
        original_message_info: t.originalMessageInfo ? {
          id: t.originalMessageInfo.messageId,
          ts: t.originalMessageInfo.createdAt
        } : void 0
      });
    }, t;
  }(),
  Tt = function (n) {
    function i(r, i) {
      var s,
        a,
        o,
        c,
        l,
        d,
        h,
        f = this;
      if ((f = n.call(this, r, i) || this).messageParams = null, f.translations = {}, f.translationTargetLanguages = [], f.messageSurvivalSeconds = -1, f.plugins = [], f._poll = null, f.messageType = g.USER, f.translations = null !== (o = i.translations) && void 0 !== o ? o : {}, f.translationTargetLanguages = null !== (c = i.target_langs) && void 0 !== c ? c : [], 0 === Object.keys(f.translations).length && f.translationTargetLanguages.length > 0) try {
        for (var p = v(f.translationTargetLanguages), m = p.next(); !m.done; m = p.next()) {
          var y = m.value;
          f.translations[y] = "";
        }
      } catch (e) {
        s = {
          error: e
        };
      } finally {
        try {
          m && !m.done && (a = p.return) && a.call(p);
        } finally {
          if (s) throw s.error;
        }
      }
      if (f.messageSurvivalSeconds = null !== (l = i.message_survival_seconds) && void 0 !== l ? l : -1, f.plugins = i.plugins ? i.plugins.map(function (e) {
        return new It(e);
      }) : [], f._poll = i.poll ? new L(f._iid, i.poll) : null, i.parent_message_info) {
        var _ = i.parent_message_info;
        f.parentMessage = Ut(r, e(t(u(u({}, _), {
          created_at: _.ts,
          message_id: f.parentMessageId,
          channel_url: f.channelUrl,
          channel_type: f.channelType,
          file: _.file,
          url: null === (d = _.file) || void 0 === d ? void 0 : d.url,
          require_auth: null === (h = _.file) || void 0 === h ? void 0 : h.require_auth
        }))));
      }
      return i.review_info && (f.messageReviewInfo = new Et(i.review_info)), f;
    }
    return r(i, n), i.payloadify = function (r) {
      return e(t(u(u({}, n.payloadify.call(this, r)), {
        translations: r.translations,
        message_survival_seconds: r.messageSurvivalSeconds,
        plugins: r.plugins.map(function (e) {
          return It.payloadify(e);
        }),
        poll: r._poll ? L.payloadify(r._poll) : null,
        parent_message_info: r.parentMessage ? n._getParentMessageInfoPayload.call(this, r.parentMessage) : null,
        review_info: r.messageReviewInfo ? Et.payloadify(r.messageReviewInfo) : void 0
      })));
    }, i.prototype.getThreadedMessagesByTimestamp = function (e, t) {
      return U(this, void 0, void 0, function () {
        var n;
        return A(this, function (r) {
          switch (r.label) {
            case 0:
              return n = u(u({}, _t), t), D(this.messageId > 0 && h("number", e) && gt(n)).throw(O.invalidParameters), [4, ra.of(this._iid).getThreadedMessagesByTimestamp(this, e, n)];
            case 1:
              return [2, r.sent()];
          }
        });
      });
    }, i.prototype.applyPoll = function (e) {
      return !(this._poll && this._poll.id === e.id && this._poll.updatedAt > e.updatedAt) && (this._poll = e, !0);
    }, Object.defineProperty(i.prototype, "poll", {
      get: function () {
        return this._poll;
      },
      enumerable: !1,
      configurable: !0
    }), i;
  }(yt),
  wt = function (n) {
    function i(r, i) {
      var s,
        a,
        o,
        c,
        l,
        d,
        h,
        f,
        p = this;
      if ((p = n.call(this, r, i) || this).messageParams = null, p.fileInfoList = [], p.messageSurvivalSeconds = -1, p.messageType = g.FILE, p.data = null !== (o = null !== (a = null === (s = i.file) || void 0 === s ? void 0 : s.data) && void 0 !== a ? a : i.custom) && void 0 !== o ? o : "", p.fileInfoList = null !== (l = null === (c = i.files) || void 0 === c ? void 0 : c.map(function (e) {
        return new ut(r, e);
      })) && void 0 !== l ? l : [], p.messageSurvivalSeconds = null !== (d = i.message_survival_seconds) && void 0 !== d ? d : -1, i.parent_message_info) {
        var m = i.parent_message_info;
        p.parentMessage = Ut(r, e(t(u(u({}, m), {
          created_at: m.ts,
          message_id: p.parentMessageId,
          channel_url: p.channelUrl,
          channel_type: p.channelType,
          file: m.file,
          url: null === (h = m.file) || void 0 === h ? void 0 : h.url,
          require_auth: null === (f = m.file) || void 0 === f ? void 0 : f.require_auth
        }))));
      }
      return p;
    }
    return r(i, n), i.prototype.getThreadedMessagesByTimestamp = function (e, t) {
      return U(this, void 0, void 0, function () {
        var n;
        return A(this, function (r) {
          switch (r.label) {
            case 0:
              return n = u(u({}, _t), t), D(this.messageId > 0 && h("number", e) && gt(n)).throw(O.invalidParameters), [4, ra.of(this._iid).getThreadedMessagesByTimestamp(this, e, n)];
            case 1:
              return [2, r.sent()];
          }
        });
      });
    }, i.payloadify = function (r) {
      var i, s, a, o, c, l;
      return e(t(u(u({}, n.payloadify.call(this, r)), {
        file: {
          name: null !== (s = null === (i = r.fileInfoList[0]) || void 0 === i ? void 0 : i.fileName) && void 0 !== s ? s : "",
          size: null !== (o = null === (a = r.fileInfoList[0]) || void 0 === a ? void 0 : a.fileSize) && void 0 !== o ? o : 0,
          type: null !== (l = null === (c = r.fileInfoList[0]) || void 0 === c ? void 0 : c.mimeType) && void 0 !== l ? l : "",
          data: r.data
        },
        files: Array.isArray(r.fileInfoList) ? r.fileInfoList.map(function (e) {
          return ut.payloadify(e);
        }) : null,
        message_survival_seconds: r.messageSurvivalSeconds,
        parent_message_info: r.parentMessage ? n._getParentMessageInfoPayload.call(this, r.parentMessage) : null
      })));
    }, i._isMultipleFilesMessagePayload = function (e) {
      var t = e.files;
      return Array.isArray(t) && t.length >= 2;
    }, i._isMultipleFilesMessageSerializedData = function (e) {
      var t = e.fileInfoList;
      return Array.isArray(t);
    }, i;
  }(yt);
!function (e) {
  e.SENT = "SENT", e.READ = "READ";
}(mt || (mt = {}));
var Mt = function (n) {
    function i(e, t) {
      var r,
        i,
        s = this;
      if ((s = n.call(this, e, t) || this).notificationData = null, s.notificationId = t.notification_message_id, s.messageType = g.ADMIN, s.messageStatus = null !== (r = t.message_status) && void 0 !== r ? r : mt.SENT, s.priority = null !== (i = t.priority) && void 0 !== i ? i : F.NORMAL, s.notificationEventDeadline = t.notification_event_deadline, 0 === s.extendedMessage.sub_type) try {
        var a = JSON.parse(s.extendedMessage.sub_data),
          o = a.label,
          u = a.tags,
          c = void 0 === u ? [] : u,
          l = a.template_key,
          d = a.template_variables;
        s.notificationData = {
          label: o,
          tags: c,
          templateKey: l,
          templateVariables: d
        };
      } catch (e) {}
      return s;
    }
    return r(i, n), i.payloadify = function (r) {
      return e(t(u(u({}, n.payloadify.call(this, r)), {
        notification_message_id: r.notificationId,
        message_status: r.messageStatus,
        priority: r.priority,
        notification_event_deadline: r.notificationEventDeadline
      })));
    }, i.prototype.isIdentical = function (e) {
      return this.notificationId === e.notificationId;
    }, i;
  }(He),
  St = function (e) {
    if (e.notificationId) return Mt.payloadify(e);
    switch (e.messageType) {
      case g.USER:
        return Tt.payloadify(e);
      case g.FILE:
        return e.fileInfoList ? wt.payloadify(e) : At.payloadify(e);
      case g.ADMIN:
        return bt.payloadify(e);
      default:
        throw O.unknown;
    }
  },
  Ut = function (e, t) {
    if (t.notification_message_id) return new Mt(e, t);
    switch (t.type) {
      case "MESG":
        return new Tt(e, t);
      case "FILE":
        return wt._isMultipleFilesMessagePayload(t) ? new wt(e, t) : new At(e, t);
      case "ADMM":
      case "BRDM":
        return new bt(e, t);
      default:
        return null;
    }
  },
  At = function (i) {
    function s(n, r) {
      var s,
        a,
        o,
        c,
        l,
        d,
        h,
        f,
        p,
        m,
        v,
        y,
        _,
        b,
        I = this;
      (I = i.call(this, n, r) || this).messageParams = null, I.plainUrl = "", I.requireAuth = !1, I.thumbnails = [], I.messageSurvivalSeconds = -1, I.messageType = g.FILE;
      var E = r.file;
      if (I.plainUrl = (null !== (a = null !== (s = null == E ? void 0 : E.url) && void 0 !== s ? s : r.url) && void 0 !== a ? a : "").split("?auth=")[0], I.name = null !== (c = null !== (o = null == E ? void 0 : E.name) && void 0 !== o ? o : r.name) && void 0 !== c ? c : "File", I.size = null !== (d = null !== (l = null == E ? void 0 : E.size) && void 0 !== l ? l : r.size) && void 0 !== d ? d : 0, I.data = null !== (f = null !== (h = null == E ? void 0 : E.data) && void 0 !== h ? h : r.custom) && void 0 !== f ? f : "", I.type = E ? null !== (p = E.type) && void 0 !== p ? p : "" : null !== (m = r.type) && void 0 !== m ? m : "", I.requireAuth = null !== (v = r.require_auth) && void 0 !== v && v, I.thumbnails = r.thumbnails ? r.thumbnails.map(function (e) {
        var t;
        return new Pe(I._iid, "string" == typeof e ? {
          url: e,
          width: 0,
          height: 0
        } : u(u({}, e), {
          url: (null !== (t = e.url) && void 0 !== t ? t : "").split("auth=")[0]
        }), I.requireAuth);
      }) : [], I.messageSurvivalSeconds = null !== (y = r.message_survival_seconds) && void 0 !== y ? y : -1, r.parent_message_info) {
        var T = r.parent_message_info;
        I.parentMessage = Ut(n, e(t(u(u({}, T), {
          created_at: T.ts,
          message_id: I.parentMessageId,
          channel_url: I.channelUrl,
          channel_type: I.channelType,
          file: T.file,
          url: null === (_ = T.file) || void 0 === _ ? void 0 : _.url,
          require_auth: null === (b = T.file) || void 0 === b ? void 0 : b.require_auth
        }))));
      }
      return I;
    }
    return r(s, i), s.payloadify = function (n) {
      var r;
      return e(t(u(u({}, i.payloadify.call(this, n)), {
        url: n.plainUrl,
        require_auth: n.requireAuth,
        file: {
          name: n.name,
          size: n.size,
          type: n.type,
          data: n.data
        },
        thumbnails: null === (r = n.thumbnails) || void 0 === r ? void 0 : r.map(function (e) {
          return {
            url: e.plainUrl,
            width: e.width,
            height: e.height,
            real_width: e.realWidth,
            real_height: e.realHeight
          };
        }),
        message_survival_seconds: n.messageSurvivalSeconds,
        parent_message_info: n.parentMessage ? i._getParentMessageInfoPayload.call(this, n.parentMessage) : null
      })));
    }, Object.defineProperty(s.prototype, "url", {
      get: function () {
        var e = n.of(this._iid).sessionManager;
        return this.requireAuth && e.ekey ? "".concat(this.plainUrl, "?auth=").concat(e.ekey) : this.plainUrl;
      },
      enumerable: !1,
      configurable: !0
    }), s.prototype.getThreadedMessagesByTimestamp = function (e, t) {
      return U(this, void 0, void 0, function () {
        var n;
        return A(this, function (r) {
          switch (r.label) {
            case 0:
              return n = u(u({}, _t), t), D(this.messageId > 0 && h("number", e) && gt(n)).throw(O.invalidParameters), [4, ra.of(this._iid).getThreadedMessagesByTimestamp(this, e, n)];
            case 1:
              return [2, r.sent()];
          }
        });
      });
    }, s;
  }(yt),
  Nt = function (e) {
    function t(t, n) {
      var r = n.file,
        i = n.fileUrl,
        s = n.fileName,
        a = n.fileSize,
        o = n.mimeType,
        u = n.thumbnailSizes,
        c = n._uploadedMetaData,
        l = n._thumbnails,
        d = e.call(this, t) || this;
      return d._plainUrl = i, d.file = r, d.fileName = s, d.fileSize = a, d.mimeType = o, d.thumbnailSizes = u, d._uploadedMetaData = c, d._thumbnails = l, d;
    }
    return r(t, e), t.getPlainUrl = function (e) {
      return e instanceof t ? e._plainUrl : e.fileUrl;
    }, Object.defineProperty(t.prototype, "fileUrl", {
      get: function () {
        var e,
          t = n.of(this._iid).sessionManager;
        return this._plainUrl && (null === (e = this._uploadedMetaData) || void 0 === e ? void 0 : e.requireAuth) && t.ekey ? "".concat(this._plainUrl, "?auth=").concat(t.ekey) : this._plainUrl;
      },
      set: function (e) {
        this._plainUrl = e;
      },
      enumerable: !1,
      configurable: !0
    }), t.prototype.toJSON = function () {
      return {
        file: this.file,
        fileUrl: this._plainUrl,
        fileName: this.fileName,
        fileSize: this.fileSize,
        mimeType: this.mimeType,
        thumbnailSizes: this.thumbnailSizes,
        _thumbnails: this._thumbnails,
        _uploadedMetaData: this._uploadedMetaData
      };
    }, t;
  }(l),
  Ct = function (n) {
    function i(r) {
      var i,
        s,
        a,
        o,
        u = [];
      return r.mentionType === E.USERS && (r.mentionedUserIds ? u = r.mentionedUserIds : r.mentionedUsers && (u = r.mentionedUsers.map(function (e) {
        return e.userId;
      }))), n.call(this, {
        code: "FILE",
        ackRequired: !0,
        payload: e(t({
          channel_url: r.channelUrl,
          files: r.files ? Rt(r.files) : null,
          url: r.url,
          name: null !== (i = r.fileName) && void 0 !== i ? i : "",
          type: null !== (s = r.mimeType) && void 0 !== s ? s : "",
          size: null !== (a = r.fileSize) && void 0 !== a ? a : 0,
          custom: r.data,
          custom_type: r.customType,
          thumbnails: null === (o = r._thumbnails) || void 0 === o ? void 0 : o.map(function (e) {
            return Pe.payloadify(e);
          }),
          require_auth: r.requireAuth,
          metaarray: r.metaArrays,
          mention_type: r.mentionType,
          mentioned_user_ids: u,
          push_option: r.pushNotificationDeliveryOption && r.pushNotificationDeliveryOption !== q.DEFAULT ? r.pushNotificationDeliveryOption : void 0,
          apple_critical_alert_options: r.appleCriticalAlertOptions ? je.payloadify(r.appleCriticalAlertOptions) : null,
          silent: r.silent,
          reply_to_channel: r.isReplyToChannel,
          parent_message_id: r.parentMessageId ? r.parentMessageId : null,
          req_id: r.reqId,
          pin_message: r.isPinnedMessage,
          message: r.message
        }))
      }) || this;
    }
    return r(i, n), i;
  }(T),
  Ot = function (n) {
    function s(r) {
      var s,
        a,
        o = this;
      o = n.call(this) || this;
      var u = [];
      return r.mentionType === E.USERS && (r.mentionedUserIds ? u = r.mentionedUserIds : r.mentionedUsers && (u = r.mentionedUsers.map(function (e) {
        return e.userId;
      }))), o.method = i.POST, o.path = "".concat(w(r.channelType), "/").concat(encodeURIComponent(r.channelUrl), "/messages"), o.params = e(t({
        message_type: g.FILE,
        user_id: r.userId,
        files: r.files ? Rt(r.files) : null,
        url: r.fileUrl,
        mention_type: r.mentionType,
        mentioned_user_ids: u,
        file_name: r.fileName,
        file_size: r.fileSize,
        file_type: r.mimeType,
        data: r.data,
        custom_type: r.customType,
        thumbnails: null === (s = r._thumbnails) || void 0 === s ? void 0 : s.map(function (e) {
          return Pe.payloadify(e);
        }),
        require_auth: r.requireAuth,
        sorted_metaarray: null === (a = r.metaArrays) || void 0 === a ? void 0 : a.map(function (e) {
          return Ge.payloadify(e);
        }),
        push_option: r.pushNotificationDeliveryOption,
        parent_message_id: r.parentMessageId ? r.parentMessageId : null,
        apple_critical_alert_options: r.appleCriticalAlertOptions ? je.payloadify(r.appleCriticalAlertOptions) : null,
        reply_to_channel: r.isReplyToChannel,
        req_id: r.reqId,
        pin_message: r.isPinnedMessage
      })), o;
    }
    return r(s, n), s;
  }(a),
  Pt = function (e) {
    function t(t, r, i) {
      var s,
        a,
        o,
        u,
        c = this;
      (c = e.call(this, t, "FILE", i) || this).message = i.files && i.files.length >= 2 ? new wt(t, i) : new At(t, i);
      var l = n.of(t).sdkState;
      return c.isMentioned = z(c.message.mentionType, null !== (o = null !== (s = c.message.mentionedUserIds) && void 0 !== s ? s : null === (a = c.message.mentionedUsers) || void 0 === a ? void 0 : a.map(function (e) {
        return e.userId;
      })) && void 0 !== o ? o : [], l.userId), c.forceUpdateLastMessage = null !== (u = i.force_update_last_message) && void 0 !== u && u, c;
    }
    return r(t, e), t;
  }(M),
  kt = function (e) {
    function t(t, r) {
      var i,
        s,
        a,
        o,
        u = this;
      (u = e.call(this, t, r) || this).message = r.files && r.files.length >= 2 ? new wt(t, r) : new At(t, r);
      var c = n.of(t).sdkState;
      return u.isMentioned = z(u.message.mentionType, null !== (a = null !== (i = u.message.mentionedUserIds) && void 0 !== i ? i : null === (s = u.message.mentionedUsers) || void 0 === s ? void 0 : s.map(function (e) {
        return e.userId;
      })) && void 0 !== a ? a : [], c.userId), u.forceUpdateLastMessage = null !== (o = r.force_update_last_message) && void 0 !== o && o, u;
    }
    return r(t, e), t;
  }(o);
function Rt(n) {
  return n.map(function (n) {
    var r, i;
    return e(t({
      url: Nt.getPlainUrl(n),
      file_name: n.fileName,
      file_type: n.mimeType,
      file_size: n.fileSize,
      thumbnails: null === (r = n._thumbnails) || void 0 === r ? void 0 : r.map(function (e) {
        return Pe.payloadify(e);
      }),
      require_auth: null === (i = n._uploadedMetaData) || void 0 === i ? void 0 : i.requireAuth
    }));
  });
}
var xt;
!function (e) {
  e[e.PENDING = 0] = "PENDING", e[e.UPLOADING = 1] = "UPLOADING", e[e.UPLOADED = 2] = "UPLOADED", e[e.SENDING = 3] = "SENDING", e[e.FAILED = 4] = "FAILED";
}(xt || (xt = {}));
var Dt = function () {
  function e(e, t) {
    var n = t.sdkState,
      r = t.dispatcher,
      i = t.requestQueue,
      s = t.onlineDetector,
      a = t.cacheContext,
      o = this;
    this._queueMap = new Map(), this._iid = e, this._sdkState = n, this._requestQueue = i, this._cacheContext = a, this._dispatcher = r, this._dispatcher.on(function (e) {
      e instanceof j && (o._connectionState = e.stateType);
    }), this._onlineDetector = s;
  }
  return Object.defineProperty(e.prototype, "_shouldSendThroughWebSocket", {
    get: function () {
      return this._connectionState === K.CONNECTED || this._connectionState === K.CONNECTING || this._connectionState === K.RECONNECTING;
    },
    enumerable: !1,
    configurable: !0
  }), e.prototype._sendFileMessage = function (e, t) {
    return U(this, void 0, void 0, function () {
      var n, r, i;
      return A(this, function (s) {
        switch (s.label) {
          case 0:
            return n = this._createSendFileMessageRequestParams(e, t), this._shouldSendThroughWebSocket ? (r = new Ct(n), [4, this._requestQueue.send(r)]) : [3, 2];
          case 1:
            return i = s.sent(), [2, i.as(Pt).message];
          case 2:
            return r = new Ot(u(u({}, n), {
              userId: this._sdkState.userId
            })), [4, this._requestQueue.send(r)];
          case 3:
            return i = s.sent(), [2, i.as(kt).message];
        }
      });
    });
  }, e.prototype._createSendFileMessageRequestParams = function (e, t) {
    var n = u(u({}, t.params), {
      channelUrl: e.url,
      channelType: e.channelType,
      reqId: t.requestId,
      url: ""
    });
    if (t.multipleFileUploadInfo) {
      var r = t.params;
      n.files = r.fileInfoList;
    } else {
      r = t.params;
      n.url = r.fileUrl, n.requireAuth = r.requireAuth;
    }
    return n;
  }, e.prototype._resolveMessageQueue = function (e) {
    var t;
    return U(this, void 0, void 0, function () {
      var n, r, i, s, a, o, u, c, l, d, h, f, p;
      return A(this, function (m) {
        switch (m.label) {
          case 0:
            if (!(n = this._queueMap.get(e.url))) return [3, 21];
            if (n.isResolving) return [3, 20];
            n.isResolving = !0, r = [], i = !0, m.label = 1;
          case 1:
            m.trys.push([1, 15, 16, 17]), s = v(n.messageQueue), a = s.next(), m.label = 2;
          case 2:
            if (a.done) return [3, 14];
            switch (o = a.value, o.state) {
              case xt.PENDING:
              case xt.UPLOADING:
                return [3, 3];
              case xt.UPLOADED:
                return [3, 4];
              case xt.FAILED:
                return [3, 12];
            }
            return [3, 13];
          case 3:
            return i = !1, r.push(o), [3, 13];
          case 4:
            if (!i) return [3, 10];
            m.label = 5;
          case 5:
            return m.trys.push([5, 8,, 9]), o.state = xt.SENDING, [4, this._sendFileMessage(e, o)];
          case 6:
            return u = m.sent(), o.deferred.resolve(u), [4, Q(100)];
          case 7:
            return m.sent(), [3, 9];
          case 8:
            return c = m.sent(), o.deferred.reject(c), [3, 9];
          case 9:
            return [3, 11];
          case 10:
            r.push(o), m.label = 11;
          case 11:
            return [3, 13];
          case 12:
            return l = null !== (t = o.error) && void 0 !== t ? t : O.unknown, o.deferred.reject(l.code === P.REQUEST_CANCELED ? O.fileUploadCanceled : l), [3, 13];
          case 13:
            return a = s.next(), [3, 2];
          case 14:
            return [3, 17];
          case 15:
            return d = m.sent(), f = {
              error: d
            }, [3, 17];
          case 16:
            try {
              a && !a.done && (p = s.return) && p.call(s);
            } finally {
              if (f) throw f.error;
            }
            return [7];
          case 17:
            return h = n.isResolveRequestPending, n.messageQueue = r, n.isResolving = !1, n.isResolveRequestPending = !1, h ? [4, this._resolveMessageQueue(e)] : [3, 19];
          case 18:
            m.sent(), m.label = 19;
          case 19:
            return [3, 21];
          case 20:
            n.isResolveRequestPending = !0, m.label = 21;
          case 21:
            return [2];
        }
      });
    });
  }, e.prototype._uploadNextPendingItem = function (e) {
    var t;
    return U(this, void 0, void 0, function () {
      var n, r, i, s, a, o, u, c, l, d;
      return A(this, function (h) {
        switch (h.label) {
          case 0:
            return (n = this._queueMap.get(e.url)) && (r = n.messageQueue.find(function (e) {
              return e.state === xt.PENDING;
            })) ? r.multipleFileUploadInfo ? (i = r.multipleFileUploadInfo, s = i.uploadIndex, a = i.uploadCount, o = i.requestHandler, d = r.params, u = d.fileInfoList[s], !G(u.file) || (null === (t = u._uploadedMetaData) || void 0 === t ? void 0 : t.isUploaded) ? [3, 2] : [4, this._tryUploadNextItemAndUpdateItemState(e, n, r)]) : [3, 4] : [3, 9];
          case 1:
            return h.sent(), [3, 3];
          case 2:
            r.state = s < a - 1 ? xt.PENDING : xt.UPLOADED, h.label = 3;
          case 3:
            return c = d.fileInfoList[s], l = r.multipleFileUploadInfo.uploadIndex++, r.multipleFileUploadInfo.isCopy || null == o || o._triggerOnFileUploaded(r.requestId, l, c, r.error), [3, 7];
          case 4:
            return d = r.params, G(d.file) ? [4, this._tryUploadNextItemAndUpdateItemState(e, n, r)] : [3, 6];
          case 5:
            return h.sent(), [3, 7];
          case 6:
            d.thumbnailSizes = [], r.state = xt.UPLOADED, h.label = 7;
          case 7:
            return this._uploadNextPendingItem(e), [4, this._resolveMessageQueue(e)];
          case 8:
            h.sent(), h.label = 9;
          case 9:
            return [2];
        }
      });
    });
  }, e.prototype._tryUploadNextItemAndUpdateItemState = function (e, t, r) {
    return U(this, void 0, void 0, function () {
      var i, s, a, o, u, c;
      return A(this, function (l) {
        switch (l.label) {
          case 0:
            return [4, this._onlineDetector.isOnline()];
          case 1:
            if (i = l.sent(), !n.of(this._iid).sessionManager.currentUser || !i) return r.error = O.connectionRequired, r.state = xt.FAILED, [2];
            if (!(t.uploadQueue.length < 6)) return [3, 9];
            r.state = xt.UPLOADING, t.uploadQueue.push(r), l.label = 2;
          case 2:
            return l.trys.push([2, 7,, 8]), r.multipleFileUploadInfo ? (s = r.multipleFileUploadInfo, a = s.uploadIndex, o = s.uploadCount, u = r.params, [4, this._uploadNextFileForMultipleFilesItemAndUpdateParams(e, r, u)]) : [3, 4];
          case 3:
            return l.sent(), r.state = a < o - 1 ? xt.PENDING : xt.UPLOADED, [3, 6];
          case 4:
            return u = r.params, [4, this._uploadNextFileForSingleFileItemAndUpdateParams(e, r, u)];
          case 5:
            l.sent(), r.state = xt.UPLOADED, l.label = 6;
          case 6:
            return [3, 8];
          case 7:
            switch ((c = l.sent()).code) {
              case P.REQUEST_FAILED:
                r.error = new O({
                  code: P.NETWORK_ERROR,
                  message: "Failed to upload a file."
                });
                break;
              case P.REQUEST_CANCELED:
                r.error = O.fileUploadCanceled;
                break;
              default:
                r.error = c;
            }
            return r.state = xt.FAILED, [3, 8];
          case 8:
            this._dequeueUploadItem(t, r), l.label = 9;
          case 9:
            return [2];
        }
      });
    });
  }, e.prototype._dequeueUploadItem = function (e, t) {
    var n = e.uploadQueue.findIndex(function (e) {
      return e.requestId === t.requestId;
    });
    n >= 0 && e.uploadQueue.splice(n, 1);
  }, e.prototype._uploadNextFileForSingleFileItemAndUpdateParams = function (e, t, n) {
    var r, i, s;
    return U(this, void 0, void 0, function () {
      var a, o, u, c, l, d, h, f, p, m;
      return A(this, function (v) {
        switch (v.label) {
          case 0:
            return a = new ke({
              file: n.file,
              channelUrl: e.url,
              thumbnailSizes: n.thumbnailSizes,
              requestId: t.requestId
            }), [4, this._requestQueue.send(a)];
          case 1:
            return o = v.sent(), u = o.as(Re), c = u.url, l = u.fileSize, d = void 0 === l ? n.fileSize : l, h = u.thumbnails, f = void 0 === h ? [] : h, p = u.requireAuth, m = void 0 !== p && p, n.fileName = null !== (r = n.fileName) && void 0 !== r ? r : n.file.name, n.mimeType = null !== (i = n.mimeType) && void 0 !== i ? i : n.file.type, n.fileSize = null !== (s = n.fileSize) && void 0 !== s ? s : n.file.size, n.fileUrl = c, n.fileSize = d, n.requireAuth = m, n._thumbnails = f, [2];
        }
      });
    });
  }, e.prototype._uploadNextFileForMultipleFilesItemAndUpdateParams = function (e, t, n) {
    var r, i, s;
    return U(this, void 0, void 0, function () {
      var a, o, c, l, d, h, f, p, m, v, y;
      return A(this, function (_) {
        switch (_.label) {
          case 0:
            return a = t.multipleFileUploadInfo.uploadIndex, o = n.fileInfoList[a], c = new ke({
              file: o.file,
              channelUrl: e.url,
              thumbnailSizes: o.thumbnailSizes,
              requestId: t.requestId
            }), [4, this._requestQueue.send(c)];
          case 1:
            return l = _.sent(), d = l.as(Re), h = d.url, f = d.fileSize, p = void 0 === f ? o.fileSize : f, m = d.thumbnails, v = d.requireAuth, y = void 0 !== v && v, o.fileName = null !== (r = o.fileName) && void 0 !== r ? r : o.file.name, o.mimeType = null !== (i = o.mimeType) && void 0 !== i ? i : o.file.type, o.fileSize = null !== (s = o.fileSize) && void 0 !== s ? s : o.file.size, o.file = void 0, o.fileUrl = h, o.fileSize = p, o._thumbnails = m, o._uploadedMetaData = u(u({}, o._uploadedMetaData), {
              requireAuth: y,
              isUploaded: !0
            }), o instanceof Nt || (n.fileInfoList[a] = new Nt(this._iid, o)), [2];
        }
      });
    });
  }, e.prototype.request = function (e, t, n, r) {
    return void 0 === r && (r = {}), U(this, void 0, void 0, function () {
      var i, s, a, o, c;
      return A(this, function (l) {
        return this._queueMap.has(e.url) || (i = {
          messageQueue: [],
          uploadQueue: [],
          isResolving: !1,
          isResolveRequestPending: !1
        }, this._queueMap.set(e.url, i)), s = this._queueMap.get(e.url), a = new V(), o = {
          requestId: t,
          params: n,
          state: xt.PENDING,
          deferred: a
        }, function (e) {
          return "fileInfoList" in e;
        }(n) && (c = u({
          uploadIndex: 0,
          uploadCount: n.fileInfoList.length
        }, r), o.multipleFileUploadInfo = c), s.messageQueue.push(o), this._uploadNextPendingItem(e), [2, a.promise];
      });
    });
  }, e.prototype.cancel = function (e, t) {
    var n,
      r,
      i = this._queueMap.get(e.url);
    if (i) {
      var s = t ? [i.messageQueue.find(function (e) {
        return e.requestId === t;
      })] : p([], m(i.messageQueue), !1);
      try {
        for (var a = v(s), o = a.next(); !o.done; o = a.next()) {
          var u = o.value;
          if (u) switch (u.state) {
            case xt.PENDING:
              if (u.state = xt.FAILED, u.error = O.requestCanceled, u.multipleFileUploadInfo && !u.multipleFileUploadInfo.isCopy) {
                var c = u.multipleFileUploadInfo,
                  l = c.uploadIndex,
                  d = c.requestHandler,
                  h = u.params.fileInfoList[l];
                null == d || d._triggerOnFileUploaded(u.requestId, l, h, O.fileUploadCanceled);
              }
              this._resolveMessageQueue(e);
              break;
            case xt.UPLOADING:
              this._requestQueue.cancel(u.requestId), this._dispatcher.dispatch(new B({
                requestId: u.requestId
              }));
          }
        }
      } catch (e) {
        n = {
          error: e
        };
      } finally {
        try {
          o && !o.done && (r = a.return) && r.call(a);
        } finally {
          if (n) throw n.error;
        }
      }
    } else t && (this._requestQueue.cancel(t), this._dispatcher.dispatch(new B({
      requestId: t
    })));
  }, e;
}();
var Lt,
  Ft = {
    prevResultSize: 0,
    nextResultSize: 0,
    isInclusive: !1,
    reverse: !1,
    messageTypeFilter: x.ALL,
    customTypesFilter: void 0,
    senderUserIdsFilter: void 0,
    replyType: H.NONE,
    includeReactions: !1,
    includeMetaArray: !1,
    includeParentMessageInfo: !1,
    includeThreadInfo: !1,
    showSubchannelMessagesOnly: !1
  },
  qt = function (e) {
    return h("number", e.prevResultSize) && h("number", e.nextResultSize) && h("boolean", e.isInclusive) && h("boolean", e.reverse) && h("string", e.messageTypeFilter) && f(x, e.messageTypeFilter) && d("string", e.customTypesFilter, !0) && d("string", e.senderUserIdsFilter, !0) && f(H, e.replyType) && h("boolean", e.includeMetaArray) && h("boolean", e.includeReactions) && h("boolean", e.includeParentMessageInfo) && h("boolean", e.includeThreadInfo) && h("boolean", e.showSubchannelMessagesOnly);
  },
  zt = {
    replyType: H.NONE,
    includeReactions: !1,
    includeThreadInfo: !1,
    includeMetaArray: !1,
    includeParentMessageInfo: !1
  },
  Bt = function (e) {
    return f(H, e.replyType) && h("boolean", e.includeReactions) && h("boolean", e.includeMetaArray) && h("boolean", e.includeParentMessageInfo) && h("boolean", e.includeThreadInfo);
  },
  jt = function (e) {
    function t(t) {
      var n = t.channelType,
        r = t.channelUrl,
        s = t.messageId,
        a = t.includeMetaArray,
        o = t.includeReactions,
        u = t.includeThreadInfo,
        c = t.includeParentMessageInfo,
        l = e.call(this) || this;
      return l.method = i.GET, l.path = "".concat(w(n), "/").concat(encodeURIComponent(r), "/messages/").concat(encodeURIComponent(s)), l.params = {
        is_sdk: !0,
        with_sorted_meta_array: a,
        include_reactions_summary: null != o && o,
        include_thread_info: u,
        include_parent_message_info: c,
        include_poll_details: !0
      }, l;
    }
    return r(t, e), t;
  }(a),
  Kt = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.message = n ? Ut(t, u({}, n)) : null, r;
    }
    return r(t, e), t;
  }(o),
  Qt = function (n) {
    function s(r) {
      var s = r.channelType,
        a = r.channelUrl,
        o = r.timestamp,
        u = r.token,
        c = r.prevResultSize,
        l = r.nextResultSize,
        d = r.isInclusive,
        h = r.reverse,
        f = r.messageTypeFilter,
        p = r.customTypesFilter,
        m = r.senderUserIdsFilter,
        v = r.replyType,
        y = r.includeMetaArray,
        _ = r.includeReactions,
        g = r.parentMessageId,
        b = r.includeThreadInfo,
        I = r.includeParentMessageInfo,
        E = r.showSubchannelMessagesOnly,
        T = r.checkingHasNext,
        M = r.checkingContinuousMessages,
        S = n.call(this) || this;
      return S.method = i.GET, S.path = "".concat(w(s), "/").concat(encodeURIComponent(a), "/messages"), S.params = e(t({
        is_sdk: !0,
        prev_limit: c,
        next_limit: l,
        include: d,
        reverse: h,
        message_ts: o,
        message_id: u,
        message_type: null != f ? f : null,
        custom_types: p,
        sender_ids: m,
        include_reply_type: v,
        with_sorted_meta_array: y,
        include_reactions_summary: _,
        parent_message_id: g,
        include_thread_info: b,
        include_parent_message_info: I,
        show_subchannel_message_only: E,
        include_poll_details: !0,
        checking_has_next: T,
        checking_continuous_messages: M
      })), S;
    }
    return r(s, n), s;
  }(a),
  Gt = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return void 0 !== n.is_continuous_messages && (r.isContinuousMessages = n.is_continuous_messages), void 0 !== n.has_next && (r.hasNext = n.has_next), r.messages = n.messages.map(function (e) {
        return Ut(t, e);
      }), r;
    }
    return r(t, e), t;
  }(o),
  Vt = function (e) {
    function t(t) {
      var n = t.channelType,
        r = t.channelUrl,
        s = t.timestamp,
        a = t.token,
        o = t.replyType,
        u = t.includeMetaArray,
        c = t.includeReactions,
        l = t.includeThreadInfo,
        d = t.includeParentMessageInfo,
        h = e.call(this) || this;
      return h.method = i.GET, h.path = "".concat(w(n), "/").concat(encodeURIComponent(r), "/messages/changelogs"), h.params = {
        change_ts: s,
        token: a,
        with_sorted_meta_array: u,
        include_reactions_summary: c,
        include_thread_info: l,
        include_reply_type: o,
        include_parent_message_info: d,
        include_poll_details: !0
      }, h;
    }
    return r(t, e), t;
  }(a),
  Ht = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.updatedMessages = n.updated.map(function (e) {
        return Ut(t, e);
      }), r.deletedMessagesInfo = n.deleted.map(function (e) {
        return {
          messageId: e.message_id,
          deletedAt: e.deleted_at
        };
      }), r.hasMore = n.has_more, r.nextToken = n.next, r.forceUseNextToken = n.force_use_next_token || !1, r;
    }
    return r(t, e), t;
  }(o),
  Wt = function (e) {
    function t(t) {
      var n = t.channelUrl,
        r = t.scheduledMessageId,
        s = e.call(this) || this;
      return s.method = i.GET, s.path = "".concat(W, "/").concat(encodeURIComponent(n), "/scheduled_messages/").concat(encodeURIComponent(r)), s.params = {}, s;
    }
    return r(t, e), t;
  }(a),
  Yt = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.message = n ? Ut(t, u({}, n)) : null, r;
    }
    return r(t, e), t;
  }(o);
!function (e) {
  e.MUTED = "muted", e.BANNED = "banned";
}(Lt || (Lt = {}));
var Jt = function () {
    function n(e) {
      var t, n, r, i;
      this.restrictionType = null, f(Lt, e.restriction_type) && (this.restrictionType = e.restriction_type), this.description = null !== (t = e.description) && void 0 !== t ? t : null, this.endAt = null !== (r = null !== (n = e.end_at) && void 0 !== n ? n : e.muted_end_at) && void 0 !== r ? r : -1, this.remainingDuration = null !== (i = e.remaining_duration) && void 0 !== i ? i : -1;
    }
    return n.payloadify = function (n) {
      return e(t({
        restriction_type: n.restrictionType,
        description: n.description,
        end_at: n.endAt,
        remaining_duration: n.remainingDuration
      }));
    }, n;
  }(),
  Xt = function (n) {
    function i(e, t) {
      var r = n.call(this, e, t) || this;
      return r.restrictionInfo = new Jt(t), r;
    }
    return r(i, n), i.payloadify = function (r) {
      return e(t(u(u({}, n.payloadify.call(this, r)), Jt.payloadify(r.restrictionInfo))));
    }, i;
  }(c),
  Zt = function (e) {
    function t(t, n) {
      var r,
        i = this;
      return (i = e.call(this, t, n) || this).isMuted = !1, i.isMuted = null !== (r = n.is_muted) && void 0 !== r && r, i;
    }
    return r(t, e), t;
  }(c),
  $t = function () {
    function e() {
      this._onPending = Y, this._onFailed = Y, this._onSucceeded = Y;
    }
    return e.prototype._trigger = function (e) {
      switch (null == e ? void 0 : e.sendingStatus) {
        case k.PENDING:
          0 === e.errorCode && this._onPending(e);
          break;
        case k.SCHEDULED:
        case k.SUCCEEDED:
          this._onSucceeded(e);
      }
    }, e.prototype._triggerFailed = function (e, t) {
      switch (null == t ? void 0 : t.sendingStatus) {
        case k.FAILED:
        case k.CANCELED:
          this._onFailed(e, t.scheduledInfo ? null : t);
      }
    }, e.prototype.onPending = function (e) {
      return this._onPending = e, this;
    }, e.prototype.onFailed = function (e) {
      return this._onFailed = e, this;
    }, e.prototype.onSucceeded = function (e) {
      return this._onSucceeded = e, this;
    }, e;
  }(),
  en = {
    data: void 0,
    customType: void 0,
    mentionType: E.USERS,
    mentionedUserIds: void 0,
    mentionedUsers: void 0,
    mentionedMessageTemplate: void 0,
    metaArrays: void 0,
    parentMessageId: void 0,
    isReplyToChannel: !1,
    pushNotificationDeliveryOption: void 0,
    appleCriticalAlertOptions: void 0,
    isPinnedMessage: !1
  },
  tn = function (e) {
    return h("string", e.data, !0) && h("string", e.customType, !0) && f(E, e.mentionType) && d("string", e.mentionedUserIds, !0) && d(c, e.mentionedUsers, !0) && h("string", e.mentionedMessageTemplate, !0) && d(Ge, e.metaArrays, !0) && h("number", e.parentMessageId, !0) && h("boolean", e.isReplyToChannel) && f(q, e.pushNotificationDeliveryOption, !0) && h(je, e.appleCriticalAlertOptions, !0) && h("boolean", e.isPinnedMessage, !0);
  },
  nn = function (e) {
    return {
      isReplyToChannel: e.isReplyToChannel,
      pushNotificationDeliveryOption: e.pushNotificationDeliveryOption,
      pollId: e.pollId
    };
  },
  rn = u(u({}, en), {
    message: "",
    translationTargetLanguages: void 0,
    pollId: void 0,
    extendedMessagePayload: void 0
  });
function sn(e) {
  var n, r, i;
  return null !== (n = e.messageParams) && void 0 !== n ? n : t(u(u({}, e), {
    mentionType: e.mentionType,
    mentionedUserIds: null !== (r = e.mentionedUserIds) && void 0 !== r ? r : null === (i = e.mentionedUsers) || void 0 === i ? void 0 : i.map(function (e) {
      return e.userId;
    }),
    translationTargetLanguages: Object.keys(e.translations),
    pushNotificationDeliveryOption: q.DEFAULT,
    parentMessageId: null,
    isReplyToChannel: !1,
    isPinnedMessage: !1
  }));
}
var an,
  on = function (n) {
    var r;
    return e(t({
      data: n.data,
      customType: n.customType,
      mentionType: n.mentionType,
      mentionedUsers: n.mentionedUsers,
      mentionedUserIds: n.mentionedUserIds,
      mentionedMessageTemplate: n.mentionedMessageTemplate,
      metaArrays: n.metaArrays,
      pollId: null === (r = n.poll) || void 0 === r ? void 0 : r.id,
      parentMessageId: n.parentMessageId,
      appleCriticalAlertOptions: n.appleCriticalAlertOptions,
      message: n.message,
      translationTargetLanguages: Object.keys(n.translations),
      extendedMessagePayload: n.extendedMessagePayload
    }));
  },
  un = function (e) {
    return tn(e) && h("string", e.message) && d("string", e.translationTargetLanguages, !0) && h("number", e.pollId, !0) && h("object", e.extendedMessagePayload, !0);
  };
!function (e) {
  e.FILE = "file", e.BLOB = "blob", e.BLOB_LIKE_OBJECT = "blobLikeObject", e.URL = "url";
}(an || (an = {}));
var cn = function (e) {
    return "undefined" != typeof window && "Blob" in window && "undefined" != typeof Blob && e instanceof Blob;
  },
  ln = function (e) {
    return e === an.BLOB || e === an.FILE;
  },
  dn = function (e) {
    return {
      file: e.file,
      fileKey: e.fileKey,
      fileType: e.fileType,
      isReplyToChannel: e.isReplyToChannel,
      pushNotificationDeliveryOption: e.pushNotificationDeliveryOption
    };
  },
  hn = u(u({}, en), {
    file: void 0,
    fileKey: void 0,
    fileUrl: void 0,
    fileName: void 0,
    fileType: void 0,
    fileSize: void 0,
    mimeType: void 0,
    thumbnailSizes: void 0,
    requireAuth: !1,
    message: void 0
  });
function fn(e) {
  var n, r, i, s;
  return null !== (n = e.messageParams) && void 0 !== n ? n : t(u(u({}, e), {
    fileUrl: e.plainUrl,
    fileName: e.name,
    fileSize: e.size,
    mimeType: e.type,
    mentionType: e.mentionType,
    mentionedUserIds: null !== (r = e.mentionedUserIds) && void 0 !== r ? r : null === (i = e.mentionedUsers) || void 0 === i ? void 0 : i.map(function (e) {
      return e.userId;
    }),
    pushNotificationDeliveryOption: q.DEFAULT,
    parentMessageId: null,
    isReplyToChannel: !1,
    thumbnailSizes: null === (s = e.thumbnails) || void 0 === s ? void 0 : s.map(function (e) {
      return {
        maxWidth: e.width,
        maxHeight: e.height
      };
    }),
    requireAuth: e.requireAuth,
    isPinnedMessage: !1,
    _thumbnails: e.thumbnails,
    message: e.message
  }));
}
var pn,
  mn = function (n, r) {
    var i;
    return n.messageParams ? (!n.url && G(r) && (n.messageParams.file = r), n.messageParams) : e(t({
      data: n.data,
      customType: n.customType,
      mentionType: n.mentionType,
      mentionedUsers: n.mentionedUsers,
      mentionedUserIds: n.mentionedUserIds,
      metaArrays: n.metaArrays,
      parentMessageId: n.parentMessageId,
      appleCriticalAlertOptions: n.appleCriticalAlertOptions,
      file: r,
      fileUrl: n.url,
      fileName: n.name,
      fileSize: n.size,
      mimeType: n.type,
      thumbnailSizes: null === (i = n.thumbnails) || void 0 === i ? void 0 : i.map(function (e) {
        return {
          maxWidth: e.width,
          maxHeight: e.height
        };
      }),
      message: n.message
    }));
  },
  vn = function (e) {
    return tn(e) && (G(e.file) || h("string", e.fileUrl)) && h("string", e.fileName, !0) && h("string", e.mimeType, !0) && h("number", e.fileSize, !0) && h("string", e.message, !0) && (null === e.thumbnailSizes || void 0 === e.thumbnailSizes || Array.isArray(e.thumbnailSizes) && e.thumbnailSizes.every(function (e) {
      return h("object", e) && e.maxWidth > 0 && e.maxHeight > 0;
    }));
  },
  yn = {
    data: void 0,
    customType: void 0,
    mentionType: E.USERS,
    mentionedUserIds: void 0,
    mentionedUsers: void 0,
    mentionedMessageTemplate: void 0,
    metaArrays: void 0,
    pushNotificationDeliveryOption: void 0,
    appleCriticalAlertOptions: void 0
  },
  _n = function (e) {
    return h("string", e.data, !0) && h("string", e.customType, !0) && f(E, e.mentionType) && d("string", e.mentionedUserIds, !0) && d(c, e.mentionedUsers, !0) && h("string", e.mentionedMessageTemplate, !0) && d(Ge, e.metaArrays, !0) && f(q, e.pushNotificationDeliveryOption, !0) && h(je, e.appleCriticalAlertOptions, !0);
  },
  gn = u(u({}, yn), {
    message: void 0,
    translationTargetLanguages: void 0,
    pollId: void 0
  }),
  bn = function (e) {
    return _n(e) && h("string", e.message, !0) && d("string", e.translationTargetLanguages, !0) && h("number", e.pollId, !0);
  },
  In = u({}, yn),
  En = function (e) {
    function t(t) {
      var n = t.channelUrl,
        r = t.channelType,
        s = t.token,
        a = t.limit,
        o = e.call(this) || this;
      return o.method = i.GET, o.path = "".concat(w(r), "/").concat(encodeURIComponent(n), "/operators"), o.params = {
        token: s,
        limit: a
      }, o;
    }
    return r(t, e), t;
  }(a),
  Tn = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.operators = n.operators.map(function (e) {
        return new c(t, e);
      }), r.token = n.next, r;
    }
    return r(t, e), t;
  }(o),
  wn = function (e) {
    function t(t, n, r, i) {
      return e.call(this, t, n, r, i) || this;
    }
    return r(t, e), t.prototype._validate = function () {
      return e.prototype._validate.call(this);
    }, t.prototype.next = function () {
      return U(this, void 0, void 0, function () {
        var e, t, r, i, s, a, o;
        return A(this, function (c) {
          switch (c.label) {
            case 0:
              return this._validate() ? this._isLoading ? [3, 3] : (e = [], this._hasNext ? (this._isLoading = !0, t = n.of(this._iid).requestQueue, r = new En(u(u({}, this), {
                channelUrl: this.channelUrl,
                token: this._token
              })), [4, t.send(r)]) : [3, 2]) : [3, 5];
            case 1:
              return i = c.sent(), s = i.as(Tn), a = s.operators, o = s.token, this._token = o, this._hasNext = !!o, this._isLoading = !1, [2, a];
            case 2:
              return [2, e];
            case 3:
              throw O.queryInProgress;
            case 4:
              return [3, 6];
            case 5:
              throw O.invalidParameters;
            case 6:
              return [2];
          }
        });
      });
    }, t;
  }(J),
  Mn = function (e) {
    function n(t, n, r, i) {
      var s,
        a,
        o,
        u,
        c,
        l,
        d,
        h,
        f,
        p,
        m = this;
      return (m = e.call(this, t, n, r, i) || this).reverse = !1, m.messageTypeFilter = x.ALL, m.customTypesFilter = null, m.senderUserIdsFilter = null, m.replyType = H.NONE, m.includeMetaArray = !1, m.includeReactions = !1, m.includeParentMessageInfo = !1, m.includeThreadInfo = !1, m.showSubchannelMessagesOnly = !1, m._edge = Number.MAX_SAFE_INTEGER, m.reverse = null !== (s = i.reverse) && void 0 !== s && s, m.messageTypeFilter = null !== (a = i.messageTypeFilter) && void 0 !== a ? a : x.ALL, m.customTypesFilter = null !== (o = i.customTypesFilter) && void 0 !== o ? o : null, m.senderUserIdsFilter = null !== (u = i.senderUserIdsFilter) && void 0 !== u ? u : null, m.replyType = null !== (c = i.replyType) && void 0 !== c ? c : H.NONE, m.includeMetaArray = null !== (l = i.includeMetaArray) && void 0 !== l && l, m.includeReactions = null !== (d = i.includeReactions) && void 0 !== d && d, m.includeParentMessageInfo = null !== (h = i.includeParentMessageInfo) && void 0 !== h && h, m.includeThreadInfo = null !== (f = i.includeThreadInfo) && void 0 !== f && f, m.showSubchannelMessagesOnly = null !== (p = i.showSubchannelMessagesOnly) && void 0 !== p && p, m;
    }
    return r(n, e), n.prototype._validate = function () {
      return e.prototype._validate.call(this) && h("boolean", this.reverse) && f(x, this.messageTypeFilter) && f(H, this.replyType) && d("string", this.customTypesFilter, !0) && d("string", this.senderUserIdsFilter, !0) && h("boolean", this.includeMetaArray) && h("boolean", this.includeReactions) && h("boolean", this.includeParentMessageInfo) && h("boolean", this.includeThreadInfo) && h("boolean", this.showSubchannelMessagesOnly);
    }, n.prototype.load = function () {
      return U(this, void 0, void 0, function () {
        var e;
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return this._validate() ? this._isLoading ? [3, 3] : this._hasNext ? (this._isLoading = !0, [4, ra.of(this._iid).getMessagesByTimestamp(this.channelUrl, this.channelType, this._edge, t({
                prevResultSize: this.limit,
                nextResultSize: 0,
                isInclusive: !1,
                reverse: this.reverse,
                messageTypeFilter: this.messageTypeFilter,
                customTypesFilter: this.customTypesFilter,
                replyType: this.replyType,
                senderUserIdsFilter: this.senderUserIdsFilter,
                includeReactions: this.includeReactions,
                includeMetaArray: this.includeMetaArray,
                includeParentMessageInfo: this.includeParentMessageInfo,
                includeThreadInfo: this.includeThreadInfo,
                showSubchannelMessagesOnly: this.showSubchannelMessagesOnly
              }))]) : [3, 2] : [3, 5];
            case 1:
              return e = n.sent(), this._edge = Math.min.apply(Math, p([Number.MAX_SAFE_INTEGER], m(e.map(function (e) {
                return e.createdAt;
              })), !1)), this._hasNext = e.length >= this.limit, this._isLoading = !1, [2, e];
            case 2:
              return [2, []];
            case 3:
              throw O.queryInProgress;
            case 4:
              return [3, 6];
            case 5:
              throw O.invalidParameters;
            case 6:
              return [2];
          }
        });
      });
    }, n;
  }(J),
  Sn = function (e) {
    function t(t) {
      var n = this,
        r = t.channelUrl,
        s = t.channelType,
        a = t.limit,
        o = t.token;
      return (n = e.call(this) || this).method = i.GET, n.path = "".concat(w(s), "/").concat(encodeURIComponent(r), "/mute"), n.params = {
        limit: a,
        token: o
      }, n;
    }
    return r(t, e), t;
  }(a),
  Un = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      r.mutedUsers = [];
      var i = n.next,
        s = n.muted_list;
      return r.token = i, s && s.length > 0 && (r.mutedUsers = s.map(function (e) {
        return new Xt(t, e);
      })), r;
    }
    return r(t, e), t;
  }(o),
  An = function (e) {
    function t(t, n, r, i) {
      return e.call(this, t, n, r, i) || this;
    }
    return r(t, e), t.prototype.next = function () {
      return U(this, void 0, void 0, function () {
        var e, t, r, i, s, a;
        return A(this, function (o) {
          switch (o.label) {
            case 0:
              return this._validate() ? this._isLoading ? [3, 3] : this._hasNext ? (this._isLoading = !0, e = n.of(this._iid).requestQueue, t = new Sn(u(u({}, this), {
                token: this._token
              })), [4, e.send(t)]) : [3, 2] : [3, 5];
            case 1:
              return r = o.sent(), i = r.as(Un), s = i.mutedUsers, a = i.token, this._token = a, this._hasNext = !!a, this._isLoading = !1, [2, s];
            case 2:
              return [2, []];
            case 3:
              throw O.queryInProgress;
            case 4:
              return [3, 6];
            case 5:
              throw O.invalidParameters;
            case 6:
              return [2];
          }
        });
      });
    }, t;
  }(J),
  Nn = function (t) {
    function n(n) {
      var r = this,
        s = n.channelUrl,
        a = n.channelType,
        o = n.limit,
        u = n.token;
      return (r = t.call(this) || this).method = i.GET, r.path = "".concat(w(a), "/").concat(encodeURIComponent(s), "/ban"), r.params = e({
        limit: o,
        token: u
      }), r;
    }
    return r(n, t), n;
  }(a),
  Cn = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      r.bannedUsers = [];
      var i = n.next,
        s = n.banned_list;
      return r.token = i, s && s.length > 0 && (r.bannedUsers = s.map(function (e) {
        return new Xt(t, e.user);
      })), r;
    }
    return r(t, e), t;
  }(o),
  On = function (e) {
    function t(t, n, r, i) {
      return e.call(this, t, n, r, i) || this;
    }
    return r(t, e), t.prototype._validate = function () {
      return e.prototype._validate.call(this);
    }, t.prototype.next = function () {
      return U(this, void 0, void 0, function () {
        var e, t, r, i, s, a;
        return A(this, function (o) {
          switch (o.label) {
            case 0:
              return this._validate() ? this._isLoading ? [3, 3] : this._hasNext ? (this._isLoading = !0, e = n.of(this._iid).requestQueue, t = new Nn(u(u({}, this), {
                token: this._token
              })), [4, e.send(t)]) : [3, 2] : [3, 5];
            case 1:
              return r = o.sent(), i = r.as(Cn), s = i.bannedUsers, a = i.token, this._token = a, this._hasNext = !!a, this._isLoading = !1, [2, s];
            case 2:
              return [2, []];
            case 3:
              throw O.queryInProgress;
            case 4:
              return [3, 6];
            case 5:
              throw O.invalidParameters;
            case 6:
              return [2];
          }
        });
      });
    }, t;
  }(J);
!function (e) {
  e.SPAM = "spam", e.HARASSING = "harassing", e.SUSPICIOUS = "suspicious", e.INAPPROPRIATE = "inappropriate";
}(pn || (pn = {}));
var Pn = function (e) {
  function t(t) {
    var n = this,
      r = t.channelUrl,
      s = t.channelType,
      a = t.operatorUserIds;
    return (n = e.call(this) || this).method = i.POST, n.path = "".concat(w(s), "/").concat(encodeURIComponent(r), "/operators"), n.params = {
      operator_ids: a
    }, n;
  }
  return r(t, e), t;
}(a);
!function (e) {
  function t() {
    return null !== e && e.apply(this, arguments) || this;
  }
  r(t, e);
}(o);
var kn = function (e) {
  function t(t) {
    var n = this,
      r = t.channelUrl,
      s = t.channelType,
      a = t.operatorUserIds;
    return (n = e.call(this) || this).method = i.DELETE, n.path = "".concat(w(s), "/").concat(encodeURIComponent(r), "/operators"), n.params = {
      operator_ids: a
    }, n;
  }
  return r(t, e), t;
}(a);
!function (e) {
  function t(t, n) {
    return e.call(this, t, n) || this;
  }
  r(t, e);
}(o);
var Rn = function (e) {
    function t(t) {
      var n = this,
        r = t.channelUrl,
        s = t.channelType,
        a = t.userId;
      return (n = e.call(this) || this).method = i.GET, n.path = "".concat(w(s), "/").concat(encodeURIComponent(r), "/mute/").concat(a), n;
    }
    return r(t, e), t;
  }(a),
  xn = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      r.isMuted = !1, r.startAt = 0, r.endAt = 0;
      var i = n.is_muted,
        s = n.start_at,
        a = n.end_at,
        o = n.remaining_duration,
        u = n.description;
      return r.isMuted = i, r.startAt = s, r.endAt = a, r.remainingDuration = o, r.description = u, r;
    }
    return r(t, e), t;
  }(o),
  Dn = function (e) {
    function t(t) {
      var n = this,
        r = t.channelUrl,
        s = t.channelType,
        a = t.keys;
      return (n = e.call(this) || this).method = i.GET, n.path = "".concat(w(s), "/").concat(encodeURIComponent(r), "/metadata"), n.params = {
        keys: a,
        include_ts: !0
      }, n;
    }
    return r(t, e), t;
  }(a),
  Ln = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this,
        i = n.metadata,
        s = n.ts;
      return r.metadata = i, r.ts = s, r;
    }
    return r(t, e), t;
  }(o),
  Fn = function (e) {
    function t(t) {
      var n = this,
        r = t.channelUrl,
        s = t.channelType,
        a = t.metadata;
      return (n = e.call(this) || this).method = i.POST, n.path = "".concat(w(s), "/").concat(encodeURIComponent(r), "/metadata"), n.params = {
        metadata: a,
        include_ts: !0
      }, n;
    }
    return r(t, e), t;
  }(a),
  qn = function (e) {
    function t(t, n) {
      var r,
        i,
        s = this;
      return (s = e.call(this, t, n) || this).metaData = null !== (r = n.metadata) && void 0 !== r ? r : {}, s.ts = null !== (i = n.ts) && void 0 !== i ? i : null, s;
    }
    return r(t, e), t;
  }(o),
  zn = function (e) {
    function t(t) {
      var n = this,
        r = t.channelUrl,
        s = t.channelType,
        a = t.metadata,
        o = t.upsert;
      return (n = e.call(this) || this).method = i.PUT, n.path = "".concat(w(s), "/").concat(encodeURIComponent(r), "/metadata"), n.params = {
        metadata: a,
        include_ts: !0,
        upsert: null != o && o
      }, n;
    }
    return r(t, e), t;
  }(a),
  Bn = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this,
        i = n.metadata,
        s = n.ts;
      return r.metadata = i, r.ts = s, r;
    }
    return r(t, e), t;
  }(o),
  jn = function (e) {
    function t(t, n, r) {
      var i = e.call(this, t, "SYEV", r) || this;
      return r.data && (i.created = r.data.created, i.updated = r.data.updated, i.deleted = r.data.deleted), i;
    }
    return r(t, e), t;
  }(M),
  Kn = function (e) {
    function t(t) {
      var n = this,
        r = t.channelUrl,
        s = t.channelType,
        a = t.key;
      return (n = e.call(this) || this).method = i.DELETE, n.path = "".concat(w(s), "/").concat(encodeURIComponent(r), "/metadata/").concat(a), n.params = {
        include_ts: !0
      }, n;
    }
    return r(t, e), t;
  }(a),
  Qn = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this,
        i = n.ts;
      return r.ts = i, r;
    }
    return r(t, e), t;
  }(o),
  Gn = function (e) {
    function t(t) {
      var n = this,
        r = t.channelUrl,
        s = t.channelType;
      return (n = e.call(this) || this).method = i.DELETE, n.path = "".concat(w(s), "/").concat(encodeURIComponent(r), "/metadata"), n.params = {
        include_ts: !0
      }, n;
    }
    return r(t, e), t;
  }(a),
  Vn = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this,
        i = n.ts;
      return r.ts = i, r;
    }
    return r(t, e), t;
  }(o),
  Hn = function (e) {
    function t(t) {
      var n = this,
        r = t.channelUrl,
        s = t.channelType,
        a = t.keys;
      return (n = e.call(this) || this).method = i.GET, n.path = "".concat(w(s), "/").concat(encodeURIComponent(r), "/metacounter"), n.params = {
        keys: a
      }, n;
    }
    return r(t, e), t;
  }(a),
  Wn = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.metaCounter = n, r;
    }
    return r(t, e), t;
  }(o),
  Yn = function (e) {
    function t(t) {
      var n = this,
        r = t.channelUrl,
        s = t.channelType,
        a = t.metaCounter;
      return (n = e.call(this) || this).method = i.POST, n.path = "".concat(w(s), "/").concat(encodeURIComponent(r), "/metacounter"), n.params = {
        metacounter: a
      }, n;
    }
    return r(t, e), t;
  }(a),
  Jn = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.metaCounter = n, r;
    }
    return r(t, e), t;
  }(o),
  Xn = function (e) {
    function t(t) {
      var n = this,
        r = t.channelUrl,
        s = t.channelType,
        a = t.metaCounter,
        o = t.upsert,
        u = void 0 !== o && o,
        c = t.mode,
        l = void 0 === c ? "set" : c;
      return (n = e.call(this) || this).method = i.PUT, n.path = "".concat(w(s), "/").concat(encodeURIComponent(r), "/metacounter"), n.params = {
        metacounter: a,
        upsert: u,
        mode: l
      }, n;
    }
    return r(t, e), t;
  }(a),
  Zn = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.metaCounter = n, r;
    }
    return r(t, e), t;
  }(o),
  $n = function (e) {
    function t(t, n, r) {
      var i = e.call(this, t, "SYEV", r) || this;
      return r.data && (i.created = r.data.created, i.updated = r.data.updated, i.deleted = r.data.deleted), i;
    }
    return r(t, e), t;
  }(M),
  er = function (e) {
    function t(t) {
      var n = this,
        r = t.channelUrl,
        s = t.channelType,
        a = t.key;
      return (n = e.call(this) || this).method = i.DELETE, n.path = "".concat(w(s), "/").concat(encodeURIComponent(r), "/metacounter/").concat(a), n.params = {}, n;
    }
    return r(t, e), t;
  }(a);
!function (e) {
  function t(t, n) {
    return e.call(this, t, n) || this;
  }
  r(t, e);
}(o);
var tr = function (e) {
  function t(t) {
    var n = this,
      r = t.channelUrl,
      s = t.channelType;
    return (n = e.call(this) || this).method = i.DELETE, n.path = "".concat(w(s), "/").concat(encodeURIComponent(r), "/metacounter"), n.params = {}, n;
  }
  return r(t, e), t;
}(a);
!function (e) {
  function t() {
    return null !== e && e.apply(this, arguments) || this;
  }
  r(t, e);
}(o);
var nr = function (e) {
  function t(t) {
    var n = this,
      r = t.channelUrl,
      s = t.channelType,
      a = t.userId,
      o = t.seconds,
      u = t.description;
    return (n = e.call(this) || this).method = i.POST, n.path = "".concat(w(s), "/").concat(encodeURIComponent(r), "/mute"), n.params = {
      user_id: a,
      seconds: o,
      description: u
    }, n;
  }
  return r(t, e), t;
}(a);
!function (e) {
  function t(t, n) {
    return e.call(this, t, n) || this;
  }
  r(t, e);
}(o);
var rr = function (e) {
    function t(t, n, r) {
      var i = e.call(this, t, "SYEV", r) || this;
      return i.user = new Xt(t, r.data), i;
    }
    return r(t, e), t;
  }(M),
  ir = function (e) {
    function t(t) {
      var n = this,
        r = t.channelUrl,
        s = t.channelType,
        a = t.userId;
      return (n = e.call(this) || this).method = i.DELETE, n.path = "".concat(w(s), "/").concat(encodeURIComponent(r), "/mute/").concat(encodeURIComponent(a)), n;
    }
    return r(t, e), t;
  }(a);
!function (e) {
  function t() {
    return null !== e && e.apply(this, arguments) || this;
  }
  r(t, e);
}(o);
var sr = function (e) {
    function t(t, n, r) {
      var i = e.call(this, t, "SYEV", r) || this;
      return i.user = new Xt(t, r.data), i;
    }
    return r(t, e), t;
  }(M),
  ar = function (t) {
    function n(n) {
      var r = this,
        s = n.channelUrl,
        a = n.channelType,
        o = n.userId,
        u = n.seconds,
        c = n.description;
      return (r = t.call(this) || this).method = i.POST, r.path = "".concat(w(a), "/").concat(encodeURIComponent(s), "/ban"), r.params = e({
        user_id: o,
        seconds: u,
        description: c
      }), r;
    }
    return r(n, t), n;
  }(a);
!function (e) {
  function t() {
    return null !== e && e.apply(this, arguments) || this;
  }
  r(t, e);
}(o);
var or = function (e) {
    function t(t, n, r) {
      var i = e.call(this, t, "SYEV", r) || this;
      return i.user = new Xt(t, r.data), r.data.member_count && (i.memberCount = r.data.member_count), r.data.joined_member_count && (i.joinedMemberCount = r.data.joined_member_count), i;
    }
    return r(t, e), t;
  }(M),
  ur = function (e) {
    function t(t) {
      var n = this,
        r = t.channelUrl,
        s = t.channelType,
        a = t.userId;
      return (n = e.call(this) || this).method = i.DELETE, n.path = "".concat(w(s), "/").concat(encodeURIComponent(r), "/ban/").concat(encodeURIComponent(a)), n;
    }
    return r(t, e), t;
  }(a);
!function (e) {
  function t() {
    return null !== e && e.apply(this, arguments) || this;
  }
  r(t, e);
}(o);
var cr = function (e) {
    function t(t, n, r) {
      var i = e.call(this, t, "SYEV", r) || this;
      return i.user = new Xt(t, r.data), i;
    }
    return r(t, e), t;
  }(M),
  lr = function (e) {
    function t(t) {
      var n = this,
        r = t.channelUrl,
        s = t.channelType,
        a = t.freezing;
      return (n = e.call(this) || this).method = i.PUT, n.path = "".concat(w(s), "/").concat(encodeURIComponent(r), "/freeze"), n.params = {
        freeze: a
      }, n;
    }
    return r(t, e), t;
  }(a);
!function (e) {
  function t() {
    return null !== e && e.apply(this, arguments) || this;
  }
  r(t, e);
}(o);
var dr = function (e) {
    function t(t, n, r) {
      var i = e.call(this, t, "SYEV", r) || this;
      return i.freeze = r.data.freeze, i;
    }
    return r(t, e), t;
  }(M),
  hr = function (e) {
    return e instanceof $ ? e._custom ? "custom" : e.name : e;
  },
  fr = function (e) {
    if (e instanceof $ && e._custom) return e.name;
  },
  pr = function (t) {
    function n(n) {
      var r = this,
        s = n.channelUrl,
        a = n.channelType,
        o = n.category,
        u = n.userId,
        c = n.description;
      return (r = t.call(this) || this).method = i.POST, r.path = "".concat(X(a), "/").concat(encodeURIComponent(s)), r.params = e({
        report_category: hr(o),
        reporting_user_id: u,
        report_description: c,
        custom_report_category_name: fr(o)
      }), r;
    }
    return r(n, t), n;
  }(a),
  mr = function (t) {
    function n(n) {
      var r = this,
        s = n.channelUrl,
        a = n.channelType,
        o = n.category,
        u = n.userId,
        c = n.offendingUserId,
        l = n.description;
      return (r = t.call(this) || this).method = i.POST, r.path = "".concat(Z, "/users/").concat(c), r.params = e({
        channel_url: s,
        channel_type: a === I.OPEN ? "open_channels" : "group_channels",
        report_category: hr(o),
        reporting_user_id: u,
        report_description: l,
        custom_report_category_name: fr(o)
      }), r;
    }
    return r(n, t), n;
  }(a),
  vr = function (t) {
    function n(n) {
      var r = this,
        s = n.channelUrl,
        a = n.channelType,
        o = n.category,
        u = n.userId,
        c = n.offendingUserId,
        l = n.messageId,
        d = n.description;
      return (r = t.call(this) || this).method = i.POST, r.path = "".concat(X(a), "/").concat(encodeURIComponent(s), "/messages/").concat(l), r.params = e({
        report_category: hr(o),
        reporting_user_id: u,
        report_description: d,
        offending_user_id: c,
        custom_report_category_name: fr(o)
      }), r;
    }
    return r(n, t), n;
  }(a),
  yr = function (n) {
    function i(r) {
      var i = [];
      return r.mentionType === E.USERS && (r.mentionedUserIds ? i = r.mentionedUserIds : r.mentionedUsers && (i = r.mentionedUsers.map(function (e) {
        return e.userId;
      }))), n.call(this, {
        code: "MESG",
        ackRequired: !0,
        payload: e(t({
          channel_url: r.channelUrl,
          message: r.message,
          data: r.data,
          custom_type: r.customType,
          metaarray: r.metaArrays,
          mention_type: r.mentionType,
          mentioned_user_ids: i,
          mentioned_message_template: r.mentionedMessageTemplate,
          target_langs: r.translationTargetLanguages,
          push_option: r.pushNotificationDeliveryOption && r.pushNotificationDeliveryOption !== q.DEFAULT ? r.pushNotificationDeliveryOption : void 0,
          apple_critical_alert_options: r.appleCriticalAlertOptions,
          silent: r.silent,
          reply_to_channel: r.isReplyToChannel,
          parent_message_id: r.parentMessageId ? r.parentMessageId : null,
          req_id: r.reqId,
          poll_id: r.pollId,
          pin_message: r.isPinnedMessage,
          extended_message_payload: r.extendedMessagePayload
        }))
      }) || this;
    }
    return r(i, n), i;
  }(T),
  _r = function (e) {
    function t(t, r, i) {
      var s,
        a,
        o,
        u,
        c = this;
      (c = e.call(this, t, "MESG", i) || this).message = new Tt(t, i);
      var l = n.of(t).sdkState;
      return c.isMentioned = z(c.message.mentionType, null !== (o = null !== (s = c.message.mentionedUserIds) && void 0 !== s ? s : null === (a = c.message.mentionedUsers) || void 0 === a ? void 0 : a.map(function (e) {
        return e.userId;
      })) && void 0 !== o ? o : [], l.userId), c.forceUpdateLastMessage = null !== (u = i.force_update_last_message) && void 0 !== u && u, c;
    }
    return r(t, e), t;
  }(M),
  gr = function (n) {
    function i(r) {
      var i = null;
      return r.mentionType === E.USERS && (r.mentionedUserIds ? i = r.mentionedUserIds : r.mentionedUsers && (i = r.mentionedUsers.map(function (e) {
        return e.userId;
      }))), n.call(this, {
        code: "MEDI",
        ackRequired: !0,
        payload: e(t({
          channel_url: r.channelUrl,
          msg_id: r.messageId,
          message: r.message,
          data: r.data,
          custom_type: r.customType,
          metaarray: r.metaArrayParams,
          mention_type: r.mentionType,
          mentioned_user_ids: i,
          mentioned_message_template: r.mentionedMessageTemplate,
          apple_critical_alert_options: r.appleCriticalAlertOptions ? je.payloadify(r.appleCriticalAlertOptions) : null,
          poll_id: r.pollId
        }))
      }) || this;
    }
    return r(i, n), i;
  }(T),
  br = function (e) {
    function i(r, i, s) {
      var a,
        o,
        u,
        c,
        l,
        d = this;
      (d = e.call(this, r, "MEDI", s) || this).message = new Tt(r, s);
      var h = n.of(r).sdkState;
      return d.mentionCountChange = ee({
        mentionType: null === (a = s.old_values) || void 0 === a ? void 0 : a.mention_type,
        mentionedUserIds: null !== (u = null === (o = s.old_values) || void 0 === o ? void 0 : o.mentioned_user_ids) && void 0 !== u ? u : []
      }, t({
        mentionType: d.message.mentionType,
        mentionedUserIds: null !== (c = d.message.mentionedUserIds) && void 0 !== c ? c : null === (l = d.message.mentionedUsers) || void 0 === l ? void 0 : l.map(function (e) {
          return e.userId;
        })
      }), h.userId), d;
    }
    return r(i, e), i;
  }(M),
  Ir = function (n) {
    function i(r) {
      var i = null;
      return r.mentionType === E.USERS && (r.mentionedUserIds ? i = r.mentionedUserIds : r.mentionedUsers && (i = r.mentionedUsers.map(function (e) {
        return e.userId;
      }))), n.call(this, {
        code: "FEDI",
        ackRequired: !0,
        payload: e(t({
          channel_url: r.channelUrl,
          msg_id: r.messageId,
          data: r.data,
          custom_type: r.customType,
          metaarray: r.metaArrayParams,
          mention_type: r.mentionType,
          mentioned_user_ids: i,
          apple_critical_alert_options: r.appleCriticalAlertOptions
        }))
      }) || this;
    }
    return r(i, n), i;
  }(T),
  Er = function (e) {
    function i(r, i, s) {
      var a,
        o,
        u,
        c,
        l,
        d = this;
      (d = e.call(this, r, "FEDI", s) || this).message = new At(r, s);
      var h = n.of(r).sdkState;
      return d.mentionCountChange = ee({
        mentionType: null === (a = s.old_values) || void 0 === a ? void 0 : a.mention_type,
        mentionedUserIds: null !== (u = null === (o = s.old_values) || void 0 === o ? void 0 : o.mentioned_user_ids) && void 0 !== u ? u : []
      }, t({
        mentionType: d.message.mentionType,
        mentionedUserIds: null !== (c = d.message.mentionedUserIds) && void 0 !== c ? c : null === (l = d.message.mentionedUsers) || void 0 === l ? void 0 : l.map(function (e) {
          return e.userId;
        })
      }), h.userId), d;
    }
    return r(i, e), i;
  }(M),
  Tr = function (e) {
    function t(t) {
      var n = t.channelType,
        r = t.channelUrl,
        s = t.messageId,
        a = t.reactionKey,
        o = e.call(this) || this;
      return o.method = i.POST, o.path = "".concat(w(n), "/").concat(encodeURIComponent(r), "/messages/").concat(s, "/reactions"), o.params = {
        reaction: a
      }, o;
    }
    return r(t, e), t;
  }(a),
  wr = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.reactionEvent = new Le(n), r;
    }
    return r(t, e), t;
  }(o),
  Mr = function (e) {
    function t(t) {
      var n = t.channelType,
        r = t.channelUrl,
        s = t.messageId,
        a = t.reactionKey,
        o = e.call(this) || this;
      return o.method = i.DELETE, o.path = "".concat(w(n), "/").concat(encodeURIComponent(r), "/messages/").concat(s, "/reactions"), o.params = {
        reaction: a
      }, o;
    }
    return r(t, e), t;
  }(a),
  Sr = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.reactionEvent = new Le(u({}, n)), r;
    }
    return r(t, e), t;
  }(o),
  Ur = function (e) {
    function t(t) {
      var n = t.channelType,
        r = t.channelUrl,
        s = t.messageId,
        a = t.translationTargetLanguages,
        o = e.call(this) || this;
      return o.method = i.POST, o.path = "".concat(w(n), "/").concat(encodeURIComponent(r), "/messages/").concat(encodeURIComponent(s), "/translation"), o.params = {
        target_langs: a
      }, o;
    }
    return r(t, e), t;
  }(a),
  Ar = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.message = new Tt(t, n), r;
    }
    return r(t, e), t;
  }(o),
  Nr = function (n) {
    function s(r) {
      var s,
        a = this;
      a = n.call(this) || this;
      var o = [];
      r.mentionType === E.USERS && (r.mentionedUserIds ? o = r.mentionedUserIds : r.mentionedUsers && (o = r.mentionedUsers.map(function (e) {
        return e.userId;
      })));
      var u = r.channelType,
        c = r.channelUrl;
      return a.method = i.POST, a.path = "".concat(w(u), "/").concat(encodeURIComponent(c), "/scheduled_messages"), a.params = e(t({
        req_id: r.reqId,
        scheduled_at: r.scheduledAt,
        message_type: te.USER,
        message: r.message,
        custom_type: r.customType,
        data: r.data,
        mention_type: r.mentionType,
        mentioned_user_ids: o,
        sorted_metaarray: null === (s = r.metaArrays) || void 0 === s ? void 0 : s.map(function (e) {
          return Ge.payloadify(e);
        }),
        apple_critical_alert_options: r.appleCriticalAlertOptions ? je.payloadify(r.appleCriticalAlertOptions) : null,
        target_langs: r.translationTargetLanguages,
        push_option: r.pushNotificationDeliveryOption
      })), a;
    }
    return r(s, n), s;
  }(a),
  Cr = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.message = new Tt(t, n), r;
    }
    return r(t, e), t;
  }(o),
  Or = function (e) {
    function n(n) {
      var r,
        s,
        a = this;
      a = e.call(this) || this;
      var o = [];
      n.mentionType === E.USERS && (n.mentionedUserIds ? o = n.mentionedUserIds : n.mentionedUsers && (o = n.mentionedUsers.map(function (e) {
        return e.userId;
      })));
      var u = n.channelType,
        c = n.channelUrl;
      return a.method = i.POST, a.path = "".concat(w(u), "/").concat(encodeURIComponent(c), "/scheduled_messages"), a.params = t({
        req_id: n.reqId,
        scheduled_at: n.scheduledAt,
        message_type: te.FILE,
        url: n.fileUrl,
        file_name: n.fileName,
        file_size: n.fileSize,
        file_type: n.mimeType,
        thumbnails: null === (r = n._thumbnails) || void 0 === r ? void 0 : r.map(function (e) {
          return Pe.payloadify(e);
        }),
        custom_type: n.customType,
        data: n.data,
        require_auth: n.requireAuth,
        mention_type: n.mentionType,
        mentioned_user_ids: o,
        sorted_metaarray: null === (s = n.metaArrays) || void 0 === s ? void 0 : s.map(function (e) {
          return Ge.payloadify(e);
        }),
        apple_critical_alert_options: n.appleCriticalAlertOptions ? je.payloadify(n.appleCriticalAlertOptions) : null,
        push_option: n.pushNotificationDeliveryOption
      }), a;
    }
    return r(n, e), n;
  }(a),
  Pr = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.message = new At(t, n), r;
    }
    return r(t, e), t;
  }(o),
  kr = function (e) {
    function t(t) {
      var n = t.pollId,
        r = t.title,
        s = t.data,
        a = t.allowUserSuggestion,
        o = t.allowMultipleVotes,
        u = t.closeAt,
        c = e.call(this) || this;
      return c.method = i.PUT, c.path = "".concat(re, "/").concat(encodeURIComponent(n)), c.params = {
        title: r,
        data: s,
        allow_user_suggestion: a,
        allow_multiple_votes: o,
        close_at: u
      }, c;
    }
    return r(t, e), t;
  }(a),
  Rr = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.poll = new L(t, n), r;
    }
    return r(t, e), t;
  }(o),
  xr = function (e) {
    function t(t) {
      var n = t.pollId,
        r = e.call(this) || this;
      return r.method = i.DELETE, r.path = "".concat(re, "/").concat(encodeURIComponent(n)), r;
    }
    return r(t, e), t;
  }(a);
!function (e) {
  function t() {
    return null !== e && e.apply(this, arguments) || this;
  }
  r(t, e);
}(o);
var Dr = function (e) {
    function t(t) {
      var n = t.pollId,
        r = e.call(this) || this;
      return r.method = i.PUT, r.path = "".concat(re, "/").concat(encodeURIComponent(n), "/close"), r;
    }
    return r(t, e), t;
  }(a),
  Lr = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.poll = new L(t, n), r;
    }
    return r(t, e), t;
  }(o),
  Fr = function (e) {
    function t(t) {
      var n = t.channelUrl,
        r = t.channelType,
        s = t.pollId,
        a = t.optionText,
        o = e.call(this) || this;
      return o.method = i.POST, o.path = "".concat(re, "/").concat(encodeURIComponent(s), "/options"), o.params = {
        channel_url: n,
        channel_type: r,
        text: a
      }, o;
    }
    return r(t, e), t;
  }(a),
  qr = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.poll = new L(t, n), r;
    }
    return r(t, e), t;
  }(o),
  zr = function (e) {
    function t(t) {
      var n = t.pollId,
        r = t.pollOptionId,
        s = t.optionText,
        a = e.call(this) || this;
      return a.method = i.PUT, a.path = "".concat(re, "/").concat(encodeURIComponent(n), "/options/").concat(encodeURIComponent(r)), a.params = {
        text: s
      }, a;
    }
    return r(t, e), t;
  }(a),
  Br = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.poll = new L(t, n), r;
    }
    return r(t, e), t;
  }(o),
  jr = function (e) {
    function t(t) {
      var n = t.pollId,
        r = t.pollOptionId,
        s = e.call(this) || this;
      return s.method = i.DELETE, s.path = "".concat(re, "/").concat(encodeURIComponent(n), "/options/").concat(encodeURIComponent(r)), s;
    }
    return r(t, e), t;
  }(a);
!function (e) {
  function t() {
    return null !== e && e.apply(this, arguments) || this;
  }
  r(t, e);
}(o);
var Kr = function (t) {
    function n(n) {
      var r = n.reqId,
        i = n.channelType,
        s = n.channelUrl,
        a = n.pollId,
        o = n.pollOptionIds;
      return t.call(this, {
        code: "VOTE",
        ackRequired: !0,
        payload: e({
          req_id: r,
          channel_type: i === I.OPEN ? "open_channels" : "group_channels",
          channel_url: s,
          poll_id: a,
          option_ids: o
        })
      }) || this;
    }
    return r(n, t), n;
  }(T),
  Qr = function (e) {
    function t(t, n, r) {
      var i = e.call(this, t, "VOTE", r) || this;
      return i.event = new ie(r), i.channelUrl = r.channel_url, i.channelType = r.channel_type, i;
    }
    return r(t, e), t;
  }(M),
  Gr = 100,
  Vr = "GroupChannel",
  Hr = "url",
  Wr = 100,
  Yr = "Message",
  Jr = "messageId",
  Xr = 100,
  Zr = "NotificationMessage",
  $r = "notificationId",
  ei = "Poll",
  ti = "pollId",
  ni = 100,
  ri = "FeedChannel",
  ii = "url";
function si(e) {
  var n, r, i;
  return null !== (n = e.messageParams) && void 0 !== n ? n : t(u(u({}, e), {
    isReplyToChannel: !1,
    mentionedUserIds: null !== (r = e.mentionedUserIds) && void 0 !== r ? r : null === (i = e.mentionedUsers) || void 0 === i ? void 0 : i.map(function (e) {
      return e.userId;
    }),
    pushNotificationDeliveryOption: q.DEFAULT,
    isPinnedMessage: !1,
    fileInfoList: e.fileInfoList.map(function (e) {
      var t;
      return {
        fileUrl: e.plainUrl,
        fileName: e.fileName,
        fileSize: e.fileSize,
        mimeType: e.mimeType,
        thumbnailSizes: null === (t = e.thumbnails) || void 0 === t ? void 0 : t.map(function (e) {
          return {
            maxWidth: e.width,
            maxHeight: e.height
          };
        }),
        _thumbnails: e.thumbnails,
        _uploadedMetaData: {
          requireAuth: e._requireAuth,
          isUploaded: !0
        }
      };
    })
  }));
}
var ai = u(u({}, en), {
    fileInfoList: [],
    message: ""
  }),
  oi = function (e) {
    return (G(e.file) || h("string", e.fileUrl)) && h("string", e.fileName, !0) && h("string", e.mimeType, !0) && h("number", e.fileSize, !0) && (void 0 === e.thumbnailSizes || Array.isArray(e.thumbnailSizes) && e.thumbnailSizes.every(function (e) {
      return e.maxWidth > 0 && e.maxHeight > 0;
    }));
  },
  ui = function (e) {
    function t() {
      var t = null !== e && e.apply(this, arguments) || this;
      return t._onFileUploaded = Y, t;
    }
    return r(t, e), t.prototype._triggerOnFileUploaded = function (e, t, n, r) {
      this._onFileUploaded(e, t, n, r);
    }, t.prototype.onFileUploaded = function (e) {
      return this._onFileUploaded = e, this;
    }, t.prototype.onPending = function (t) {
      return e.prototype.onPending.call(this, t), this;
    }, t.prototype.onFailed = function (t) {
      return e.prototype.onFailed.call(this, t), this;
    }, t.prototype.onSucceeded = function (t) {
      return e.prototype.onSucceeded.call(this, t), this;
    }, t;
  }($t),
  ci = function (e) {
    function t(t, n) {
      var r = e.call(this, t) || this;
      return r.message = null, r.message = Ut(t, n.message), r;
    }
    return r(t, e), t;
  }(l),
  li = function (t) {
    function n(n) {
      var r = this,
        s = n.channelType,
        a = n.channelUrl,
        o = n.limit,
        u = n.token,
        c = n.includeReactions,
        l = n.includeMetaArray,
        d = n.includeParentMessageInfo,
        h = n.includeThreadInfo,
        f = n.includePollDetails;
      return (r = t.call(this) || this).method = i.GET, r.path = "".concat(w(s), "/").concat(encodeURIComponent(a), "/pinned_messages"), r.params = e({
        limit: o,
        token: u,
        include_reactions_summary: c,
        with_sorted_meta_array: l,
        include_thread_info: h,
        include_parent_message_info: d,
        include_poll_details: f
      }), r;
    }
    return r(n, t), n;
  }(a),
  di = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this,
        i = n.pinned_messages,
        s = n.has_more,
        a = n.next;
      return r.pinnedMessages = i.map(function (e) {
        return new ci(t, e);
      }), r.hasMore = s, r.token = a, r;
    }
    return r(t, e), t;
  }(o),
  hi = function (e) {
    function t(t, n, r, i) {
      var s = e.call(this, t, n, r, i) || this;
      return s.includeMetaArray = i.includeMetaArray, s.includeReactions = i.includeReactions, s.includeParentMessageInfo = i.includeParentMessageInfo, s.includeThreadInfo = i.includeThreadInfo, s.includePollDetails = i.includePollDetails, s;
    }
    return r(t, e), t.prototype._validate = function () {
      return e.prototype._validate.call(this) && h("boolean", this.includeMetaArray, !0) && h("boolean", this.includeReactions, !0) && h("boolean", this.includeParentMessageInfo, !0) && h("boolean", this.includeThreadInfo, !0) && h("boolean", this.includePollDetails, !0);
    }, t.prototype.next = function () {
      return U(this, void 0, void 0, function () {
        var e, t, r, i, s, a, o;
        return A(this, function (c) {
          switch (c.label) {
            case 0:
              return this._validate() ? this._isLoading ? [3, 3] : this._hasNext ? (this._isLoading = !0, e = n.of(this._iid).requestQueue, t = new li(u(u({}, this), {
                token: this._token
              })), [4, e.send(t)]) : [3, 2] : [3, 5];
            case 1:
              return r = c.sent(), i = r.as(di), s = i.pinnedMessages, a = i.hasMore, o = i.token, this._token = o, this._hasNext = !!a, this._isLoading = !1, [2, s];
            case 2:
              return [2, []];
            case 3:
              throw O.queryInProgress;
            case 4:
              return [3, 6];
            case 5:
              throw O.invalidParameters;
            case 6:
              return [2];
          }
        });
      });
    }, t;
  }(J),
  fi = function (e) {
    function t(t) {
      var n = t.channelType,
        r = t.channelUrl,
        s = t.messageId,
        a = e.call(this) || this;
      return a.method = i.POST, a.path = "".concat(w(n), "/").concat(encodeURIComponent(r), "/messages/").concat(s, "/pin"), a;
    }
    return r(t, e), t;
  }(a);
!function (e) {
  function t(t, n) {
    return e.call(this, t, n) || this;
  }
  r(t, e);
}(o);
var pi = function (e) {
  function t(t) {
    var n = t.channelType,
      r = t.channelUrl,
      s = t.messageId,
      a = e.call(this) || this;
    return a.method = i.DELETE, a.path = "".concat(w(n), "/").concat(encodeURIComponent(r), "/messages/").concat(s, "/pin"), a;
  }
  return r(t, e), t;
}(a);
!function (e) {
  function t(t, n) {
    return e.call(this, t, n) || this;
  }
  r(t, e);
}(o);
var mi,
  vi = 2;
!function (e) {
  e.SEND = "send", e.RESEND = "resend", e.COPY = "copy";
}(mi || (mi = {}));
var yi,
  _i = function (i) {
    function s(e, t) {
      var n,
        r,
        s,
        a,
        o,
        u,
        l,
        d = this;
      return (d = i.call(this, e) || this)._name = "", d._createdAt = 0, d.pinnedMessageIds = [], d.channelType = I.BASE, d.coverUrl = "", d.customType = "", d.data = "", d.isFrozen = !1, d.isEphemeral = !1, d.creator = null, d._messageCollectionLastAccessedAt = 0, d._url = t.channel_url, d._name = null !== (n = t.name) && void 0 !== n ? n : "", d._createdAt = 1e3 * t.created_at, d.coverUrl = null !== (r = t.cover_url) && void 0 !== r ? r : "", d.customType = null !== (s = t.custom_type) && void 0 !== s ? s : "", d.data = null !== (a = t.data) && void 0 !== a ? a : "", d.isFrozen = null !== (o = t.freeze) && void 0 !== o && o, d.isEphemeral = null !== (u = t.is_ephemeral) && void 0 !== u && u, d.creator = t.created_by ? new c(d._iid, t.created_by) : null, t.metadata && Object.keys(t.metadata).length > 0 && t.ts ? (d._cachedMetaData = new Map(), Object.keys(t.metadata).forEach(function (e) {
        d._cachedMetaData.set(e, {
          value: t.metadata[e],
          isRemoved: !1,
          updatedAt: t.ts
        });
      })) : "string" == typeof t.metadata && (d._cachedMetaData = new Map(Object.entries(JSON.parse(t.metadata)))), d.pinnedMessageIds = null !== (l = t.pinned_message_ids) && void 0 !== l ? l : [], d;
    }
    return r(s, i), Object.defineProperty(s.prototype, "url", {
      get: function () {
        return this._url;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(s.prototype, "name", {
      get: function () {
        return this._name;
      },
      set: function (e) {
        this._name = e;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(s.prototype, "createdAt", {
      get: function () {
        return this._createdAt;
      },
      enumerable: !1,
      configurable: !0
    }), s.prototype.toJSON = function () {
      return u(u({}, this), {
        url: this._url,
        name: this._name,
        createdAt: this._createdAt
      });
    }, s.payloadify = function (n) {
      return e(t(u(u({}, i.payloadify.call(this, n)), {
        channel_url: n.url,
        name: n.name,
        cover_url: n.coverUrl,
        custom_type: n.customType,
        data: n.data,
        freeze: n.isFrozen,
        is_ephemeral: n.isEphemeral,
        created_by: n.creator ? c.payloadify(n.creator) : null,
        created_at: n.createdAt / 1e3,
        metadata: n.cachedMetaData,
        pinned_message_ids: n.pinnedMessageIds
      })));
    }, s.prototype.isGroupChannel = function () {
      return this.channelType === I.GROUP;
    }, s.prototype.isOpenChannel = function () {
      return this.channelType === I.OPEN;
    }, s.prototype.isFeedChannel = function () {
      return this.channelType === I.FEED;
    }, Object.defineProperty(s.prototype, "cachedMetaData", {
      get: function () {
        var e = {};
        return this._cachedMetaData ? (this._cachedMetaData.forEach(function (t, n) {
          t.isRemoved || (e[n] = t.value);
        }), e) : e;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(s.prototype, "messageCollectionLastAccessedAt", {
      get: function () {
        return this._messageCollectionLastAccessedAt;
      },
      enumerable: !1,
      configurable: !0
    }), s.prototype._serializeCachedMetaData = function () {
      return this._cachedMetaData ? {
        cachedMetaData: JSON.stringify(Object.fromEntries(this._cachedMetaData))
      } : {};
    }, s.prototype._updateMessageCollectionLastAccessedAt = function () {
      this._messageCollectionLastAccessedAt = Date.now();
    }, s.prototype._update = function (t) {
      var n = e(t);
      Object.assign(this, n);
    }, s.prototype._upsertCachedMetaData = function (e, t) {
      var n = this;
      Object.keys(e).forEach(function (r) {
        n._cachedMetaData || (n._cachedMetaData = new Map());
        var i = n._cachedMetaData.get(r);
        (!i || i.updatedAt <= t) && n._cachedMetaData.set(r, {
          value: e[r],
          isRemoved: !1,
          updatedAt: t
        });
      });
    }, s.prototype._updateCachedMetaData = function (e, t) {
      var n = this,
        r = !1;
      return this._cachedMetaData ? this._cachedMetaData.forEach(function (i, s) {
        var a;
        i.updatedAt <= t && (n._cachedMetaData.set(s, {
          value: null !== (a = e[s]) && void 0 !== a ? a : i.value,
          isRemoved: !e[s],
          updatedAt: t
        }), r = !0);
      }) : (this._cachedMetaData = new Map(), Object.keys(e).forEach(function (r) {
        n._cachedMetaData.set(r, {
          value: e[r],
          isRemoved: !1,
          updatedAt: t
        });
      }), r = !0), {
        isUpdated: r
      };
    }, s.prototype._removeFromCachedMetaData = function (e, t) {
      var n = this;
      this._cachedMetaData && e.forEach(function (e) {
        var r = n._cachedMetaData.get(e);
        r && r.updatedAt < t && (r.isRemoved = !0, r.updatedAt = t);
      });
    }, s.prototype._clearCachedMetaData = function () {
      this._cachedMetaData && this._cachedMetaData.clear();
    }, s.prototype._runIfHandleableWithGroupChannel = function (e) {
      this.isGroupChannel() ? e(this) : this.isFeedChannel() && e(this.groupChannel);
    }, s.prototype._generateRequestId = function () {
      return "rq-".concat(ae());
    }, s.prototype.isIdentical = function (e) {
      return e && this.url === e.url;
    }, s.prototype.isEqual = function (e) {
      return _(this, e);
    }, s.prototype.createOperatorListQuery = function (e) {
      return void 0 === e && (e = {}), new wn(this._iid, this.url, this.channelType, e);
    }, s.prototype.createMutedUserListQuery = function (e) {
      return void 0 === e && (e = {}), new An(this._iid, this.url, this.channelType, e);
    }, s.prototype.createBannedUserListQuery = function (e) {
      return void 0 === e && (e = {}), new On(this._iid, this.url, this.channelType, e);
    }, s.prototype.createPreviousMessageListQuery = function (e) {
      return void 0 === e && (e = {}), new Mn(this._iid, this.url, this.channelType, e);
    }, s.prototype.createPinnedMessageListQuery = function (e) {
      return void 0 === e && (e = {}), new hi(this._iid, this.url, this.channelType, e);
    }, s.prototype.addOperators = function (e) {
      return U(this, void 0, void 0, function () {
        var t, r;
        return A(this, function (i) {
          switch (i.label) {
            case 0:
              return D(d("string", e)).throw(O.invalidParameters), t = n.of(this._iid).requestQueue, r = new Pn({
                channelUrl: this.url,
                channelType: this.channelType,
                operatorUserIds: e
              }), [4, t.send(r)];
            case 1:
              return i.sent(), [2];
          }
        });
      });
    }, s.prototype.removeOperators = function (e) {
      return U(this, void 0, void 0, function () {
        var t, r;
        return A(this, function (i) {
          switch (i.label) {
            case 0:
              return D(d("string", e)).throw(O.invalidParameters), t = n.of(this._iid).requestQueue, r = new kn({
                channelUrl: this.url,
                channelType: this.channelType,
                operatorUserIds: e
              }), [4, t.send(r)];
            case 1:
              return i.sent(), [2];
          }
        });
      });
    }, s.prototype.getMyMutedInfo = function () {
      return U(this, void 0, void 0, function () {
        var e, t, r, i, s, a, o, u, c, l, d;
        return A(this, function (h) {
          switch (h.label) {
            case 0:
              return e = n.of(this._iid), t = e.sdkState, r = e.requestQueue, i = new Rn({
                channelUrl: this.url,
                channelType: this.channelType,
                userId: t.userId
              }), [4, r.send(i)];
            case 1:
              return s = h.sent(), a = s.as(xn), o = a.isMuted, u = a.startAt, c = a.endAt, l = a.remainingDuration, d = a.description, [2, {
                isMuted: o,
                startAt: u,
                endAt: c,
                remainingDuration: l,
                description: d
              }];
          }
        });
      });
    }, s.prototype.getMetaData = function (e) {
      return U(this, void 0, void 0, function () {
        var t, r, i, s, a, o;
        return A(this, function (u) {
          switch (u.label) {
            case 0:
              return D(d("string", e)).throw(O.invalidParameters), t = n.of(this._iid).requestQueue, r = new Dn({
                channelUrl: this.url,
                channelType: this.channelType,
                keys: e
              }), [4, t.send(r)];
            case 1:
              return i = u.sent(), s = i.as(Ln), a = s.metadata, o = s.ts, this._upsertCachedMetaData(a, o), [2, a];
          }
        });
      });
    }, s.prototype.getAllMetaData = function () {
      return U(this, void 0, void 0, function () {
        var e, t, r, i, s, a;
        return A(this, function (o) {
          switch (o.label) {
            case 0:
              return e = n.of(this._iid).requestQueue, t = new Dn({
                channelUrl: this.url,
                channelType: this.channelType,
                keys: []
              }), [4, e.send(t)];
            case 1:
              return r = o.sent(), i = r.as(Ln), s = i.metadata, a = i.ts, this._updateCachedMetaData(s, a), [2, s];
          }
        });
      });
    }, s.prototype.createMetaData = function (e) {
      return U(this, void 0, void 0, function () {
        var t, r, i, s;
        return A(this, function (a) {
          switch (a.label) {
            case 0:
              return t = n.of(this._iid).requestQueue, r = new Fn({
                channelUrl: this.url,
                channelType: this.channelType,
                metadata: e
              }), [4, t.send(r)];
            case 1:
              return i = a.sent(), s = i.as(qn).metaData, this._upsertCachedMetaData(s, 0), [2, s];
          }
        });
      });
    }, s.prototype.updateMetaData = function (e, t) {
      return void 0 === t && (t = !1), U(this, void 0, void 0, function () {
        var r, i, s, a, o, u;
        return A(this, function (c) {
          switch (c.label) {
            case 0:
              return r = n.of(this._iid).requestQueue, i = new zn({
                channelUrl: this.url,
                channelType: this.channelType,
                metadata: e,
                upsert: t
              }), [4, r.send(i)];
            case 1:
              return s = c.sent(), a = s.as(Bn), o = a.metadata, u = a.ts, this._upsertCachedMetaData(o, u), [2, o];
          }
        });
      });
    }, s.prototype.deleteMetaData = function (e) {
      return U(this, void 0, void 0, function () {
        var t, r, i, s;
        return A(this, function (a) {
          switch (a.label) {
            case 0:
              return D(h("string", e)).throw(O.invalidParameters), t = n.of(this._iid).requestQueue, r = new Kn({
                channelUrl: this.url,
                channelType: this.channelType,
                key: e
              }), [4, t.send(r)];
            case 1:
              return i = a.sent(), s = i.as(Qn).ts, this._removeFromCachedMetaData([e], s), [2];
          }
        });
      });
    }, s.prototype.deleteAllMetaData = function () {
      return U(this, void 0, void 0, function () {
        var e, t, r, i;
        return A(this, function (s) {
          switch (s.label) {
            case 0:
              return e = n.of(this._iid).requestQueue, t = new Gn({
                channelUrl: this.url,
                channelType: this.channelType
              }), [4, e.send(t)];
            case 1:
              return r = s.sent(), i = r.as(Vn).ts, this._removeFromCachedMetaData(p([], m(this._cachedMetaData.keys()), !1), i), [2];
          }
        });
      });
    }, s.prototype.getMetaCounters = function (e) {
      return U(this, void 0, void 0, function () {
        var t, r, i;
        return A(this, function (s) {
          switch (s.label) {
            case 0:
              return D(d("string", e)).throw(O.invalidParameters), t = n.of(this._iid).requestQueue, r = new Hn({
                channelUrl: this.url,
                channelType: this.channelType,
                keys: e
              }), [4, t.send(r)];
            case 1:
              return i = s.sent(), [2, i.as(Wn).metaCounter];
          }
        });
      });
    }, s.prototype.getAllMetaCounters = function () {
      return U(this, void 0, void 0, function () {
        var e, t, r;
        return A(this, function (i) {
          switch (i.label) {
            case 0:
              return e = n.of(this._iid).requestQueue, t = new Hn({
                channelUrl: this.url,
                channelType: this.channelType,
                keys: []
              }), [4, e.send(t)];
            case 1:
              return r = i.sent(), [2, r.as(Wn).metaCounter];
          }
        });
      });
    }, s.prototype.createMetaCounters = function (e) {
      return U(this, void 0, void 0, function () {
        var t, r, i;
        return A(this, function (s) {
          switch (s.label) {
            case 0:
              return t = n.of(this._iid).requestQueue, r = new Yn({
                channelUrl: this.url,
                channelType: this.channelType,
                metaCounter: e
              }), [4, t.send(r)];
            case 1:
              return i = s.sent(), [2, i.as(Jn).metaCounter];
          }
        });
      });
    }, s.prototype.updateMetaCounters = function (e, t) {
      return void 0 === t && (t = !1), U(this, void 0, void 0, function () {
        var r, i, s;
        return A(this, function (a) {
          switch (a.label) {
            case 0:
              return r = n.of(this._iid).requestQueue, i = new Xn({
                channelUrl: this.url,
                channelType: this.channelType,
                metaCounter: e,
                upsert: t
              }), [4, r.send(i)];
            case 1:
              return s = a.sent(), [2, s.as(Zn).metaCounter];
          }
        });
      });
    }, s.prototype.increaseMetaCounters = function (e) {
      return U(this, void 0, void 0, function () {
        var t, r, i;
        return A(this, function (s) {
          switch (s.label) {
            case 0:
              return t = n.of(this._iid).requestQueue, r = new Xn({
                channelUrl: this.url,
                channelType: this.channelType,
                metaCounter: e,
                upsert: !1,
                mode: "increase"
              }), [4, t.send(r)];
            case 1:
              return i = s.sent(), [2, i.as(Zn).metaCounter];
          }
        });
      });
    }, s.prototype.decreaseMetaCounters = function (e) {
      return U(this, void 0, void 0, function () {
        var t, r, i;
        return A(this, function (s) {
          switch (s.label) {
            case 0:
              return t = n.of(this._iid).requestQueue, r = new Xn({
                channelUrl: this.url,
                channelType: this.channelType,
                metaCounter: e,
                upsert: !1,
                mode: "decrease"
              }), [4, t.send(r)];
            case 1:
              return i = s.sent(), [2, i.as(Zn).metaCounter];
          }
        });
      });
    }, s.prototype.deleteMetaCounter = function (e) {
      return U(this, void 0, void 0, function () {
        var t, r;
        return A(this, function (i) {
          switch (i.label) {
            case 0:
              return D(h("string", e)).throw(O.invalidParameters), t = n.of(this._iid).requestQueue, r = new er({
                channelUrl: this.url,
                channelType: this.channelType,
                key: e
              }), [4, t.send(r)];
            case 1:
              return i.sent(), [2];
          }
        });
      });
    }, s.prototype.deleteAllMetaCounters = function () {
      return U(this, void 0, void 0, function () {
        var e, t;
        return A(this, function (r) {
          switch (r.label) {
            case 0:
              return e = n.of(this._iid).requestQueue, t = new tr({
                channelUrl: this.url,
                channelType: this.channelType
              }), [4, e.send(t)];
            case 1:
              return r.sent(), [2];
          }
        });
      });
    }, s.prototype.muteUser = function (e, t, n) {
      return U(this, void 0, void 0, function () {
        return A(this, function (r) {
          return [2, this.muteUserWithUserId(e.userId, t, n)];
        });
      });
    }, s.prototype.muteUserWithUserId = function (e, t, r) {
      return U(this, void 0, void 0, function () {
        var i, s;
        return A(this, function (a) {
          switch (a.label) {
            case 0:
              return D(h("string", e) && h("number", t, !0) && h("string", r, !0)).throw(O.invalidParameters), i = n.of(this._iid).requestQueue, s = new nr({
                channelUrl: this.url,
                channelType: this.channelType,
                userId: e,
                seconds: t,
                description: r
              }), [4, i.send(s)];
            case 1:
              return a.sent(), [2];
          }
        });
      });
    }, s.prototype.unmuteUser = function (e) {
      return U(this, void 0, void 0, function () {
        return A(this, function (t) {
          return [2, this.unmuteUserWithUserId(e.userId)];
        });
      });
    }, s.prototype.unmuteUserWithUserId = function (e) {
      return U(this, void 0, void 0, function () {
        var t, r;
        return A(this, function (i) {
          switch (i.label) {
            case 0:
              return D(h("string", e)).throw(O.invalidParameters), t = n.of(this._iid).requestQueue, r = new ir({
                channelUrl: this.url,
                channelType: this.channelType,
                userId: e
              }), [4, t.send(r)];
            case 1:
              return i.sent(), [2];
          }
        });
      });
    }, s.prototype.banUser = function (e, t, n) {
      return U(this, void 0, void 0, function () {
        return A(this, function (r) {
          return [2, this.banUserWithUserId(e.userId, t, n)];
        });
      });
    }, s.prototype.banUserWithUserId = function (e, t, r) {
      return U(this, void 0, void 0, function () {
        var i, s;
        return A(this, function (a) {
          switch (a.label) {
            case 0:
              return D(h("string", e) && h("number", t, !0) && h("string", r, !0)).throw(O.invalidParameters), i = n.of(this._iid).requestQueue, s = new ar({
                channelUrl: this.url,
                channelType: this.channelType,
                userId: e,
                seconds: t,
                description: r
              }), [4, i.send(s)];
            case 1:
              return a.sent(), [2];
          }
        });
      });
    }, s.prototype.unbanUser = function (e) {
      return U(this, void 0, void 0, function () {
        return A(this, function (t) {
          return [2, this.unbanUserWithUserId(e.userId)];
        });
      });
    }, s.prototype.unbanUserWithUserId = function (e) {
      return U(this, void 0, void 0, function () {
        var t, r;
        return A(this, function (i) {
          switch (i.label) {
            case 0:
              return D(h("string", e)).throw(O.invalidParameters), t = n.of(this._iid).requestQueue, r = new ur({
                channelUrl: this.url,
                channelType: this.channelType,
                userId: e
              }), [4, t.send(r)];
            case 1:
              return i.sent(), [2];
          }
        });
      });
    }, s.prototype.freeze = function () {
      return U(this, void 0, void 0, function () {
        var e, t;
        return A(this, function (r) {
          switch (r.label) {
            case 0:
              return e = n.of(this._iid).requestQueue, t = new lr({
                channelUrl: this.url,
                channelType: this.channelType,
                freezing: !0
              }), [4, e.send(t)];
            case 1:
              return r.sent(), this.isFrozen = !0, [2];
          }
        });
      });
    }, s.prototype.unfreeze = function () {
      return U(this, void 0, void 0, function () {
        var e, t;
        return A(this, function (r) {
          switch (r.label) {
            case 0:
              return e = n.of(this._iid).requestQueue, t = new lr({
                channelUrl: this.url,
                channelType: this.channelType,
                freezing: !1
              }), [4, e.send(t)];
            case 1:
              return r.sent(), this.isFrozen = !1, [2];
          }
        });
      });
    }, s.prototype.getMessagesByMessageId = function (e, t) {
      return U(this, void 0, void 0, function () {
        var n;
        return A(this, function (r) {
          switch (r.label) {
            case 0:
              return n = u(u({}, Ft), t), D(h("number", e) && qt(n)).throw(O.invalidParameters), [4, ra.of(this._iid).getMessagesByMessageId(this.url, this.channelType, e, n)];
            case 1:
              return [2, r.sent()];
          }
        });
      });
    }, s.prototype.getMessagesByTimestamp = function (e, t) {
      return U(this, void 0, void 0, function () {
        var n;
        return A(this, function (r) {
          switch (r.label) {
            case 0:
              return n = u(u({}, Ft), t), D(h("number", e) && qt(n)).throw(O.invalidParameters), [4, ra.of(this._iid).getMessagesByTimestamp(this.url, this.channelType, e, n)];
            case 1:
              return [2, r.sent()];
          }
        });
      });
    }, s.prototype.getMessageChangeLogsSinceTimestamp = function (e, t) {
      return void 0 === t && (t = {}), U(this, void 0, void 0, function () {
        var n;
        return A(this, function (r) {
          switch (r.label) {
            case 0:
              return n = u(u({}, zt), t), D(h("number", e) && Bt(n)).throw(O.invalidParameters), [4, ra.of(this._iid).getMessageChangelogs(this.url, this.channelType, e, n)];
            case 1:
              return [2, r.sent()];
          }
        });
      });
    }, s.prototype.getMessageChangeLogsSinceToken = function (e, t) {
      return void 0 === t && (t = {}), U(this, void 0, void 0, function () {
        var n;
        return A(this, function (r) {
          switch (r.label) {
            case 0:
              return n = u(u({}, zt), t), D(h("string", e) && Bt(n)).throw(O.invalidParameters), [4, ra.of(this._iid).getMessageChangelogs(this.url, this.channelType, e, n)];
            case 1:
              return [2, r.sent()];
          }
        });
      });
    }, s.prototype._createPendingSendableMessagePayload = function (r, i, s) {
      var a,
        o = n.of(this._iid).sessionManager;
      return e(t({
        channel_url: this.url,
        channel_type: this.channelType,
        msg_id: 0,
        parent_message_id: r.parentMessageId,
        data: r.data,
        custom_type: r.customType,
        mention_type: r.mentionType,
        sorted_metaarray: r.metaArrays ? r.metaArrays.map(function (e) {
          return Ge.payloadify(e);
        }) : null,
        apple_critical_alert_options: r.appleCriticalAlertOptions ? je.payloadify(r.appleCriticalAlertOptions) : null,
        created_at: s,
        user: o.currentUser ? Qe.payloadify(o.currentUser) : null,
        req_id: i,
        request_state: k.PENDING,
        mentioned_user_ids: r.mentionedUserIds,
        mentioned_users: null === (a = r.mentionedUsers) || void 0 === a ? void 0 : a.map(function (e) {
          return c.payloadify(e);
        })
      }));
    }, s.prototype._createPendingUserMessage = function (t, n, r) {
      var i,
        s,
        a = {};
      if (t.translationTargetLanguages) try {
        for (var o = v(t.translationTargetLanguages), c = o.next(); !c.done; c = o.next()) {
          a[c.value] = "";
        }
      } catch (e) {
        i = {
          error: e
        };
      } finally {
        try {
          c && !c.done && (s = o.return) && s.call(o);
        } finally {
          if (i) throw i.error;
        }
      }
      var l = e(u(u({}, this._createPendingSendableMessagePayload(t, n, r)), {
          type: g.USER,
          message: t.message,
          translations: a,
          extended_message_payload: t.extendedMessagePayload
        })),
        d = new Tt(this._iid, l);
      return d.messageParams = t, d;
    }, s.prototype._createPendingScheduledUserMessage = function (e, t, n) {
      var r = this._createPendingUserMessage(e, t, n);
      return r.scheduledInfo = {
        scheduledMessageId: 0,
        scheduledAt: e.scheduledAt,
        scheduledMessageParams: e
      }, r;
    }, s.prototype._createPendingFileMessage = function (t, n, r) {
      var i,
        s,
        a,
        o,
        c,
        l,
        d,
        h = e(u(u({}, this._createPendingSendableMessagePayload(t, n, r)), {
          type: g.FILE,
          url: t.fileUrl,
          file: {
            name: null !== (i = t.fileName) && void 0 !== i ? i : null === (s = t.file) || void 0 === s ? void 0 : s.name,
            size: null !== (a = t.fileSize) && void 0 !== a ? a : null === (o = t.file) || void 0 === o ? void 0 : o.size,
            type: null !== (c = t.mimeType) && void 0 !== c ? c : null === (l = t.file) || void 0 === l ? void 0 : l.type,
            data: t.data
          },
          thumbnails: null === (d = t._thumbnails) || void 0 === d ? void 0 : d.map(function (e) {
            return Pe.payloadify(e);
          }),
          message: t.message
        })),
        f = new At(this._iid, h);
      return f.messageParams = t, f;
    }, s.prototype._createPendingMultipleFilesMessage = function (t, n, r) {
      var i = e(u(u({}, this._createPendingSendableMessagePayload(t, n, r)), {
          type: g.FILE,
          file: t.fileInfoList.length > 0 ? {
            name: t.fileInfoList[0].fileName,
            size: t.fileInfoList[0].fileSize,
            type: t.fileInfoList[0].mimeType,
            data: t.data
          } : void 0,
          message: t.message
        })),
        s = new wt(this._iid, i);
      return s.messageParams = t, s;
    }, s.prototype._validateFailedFileMessageHasFile = function (e, t) {
      var n;
      return Boolean(e.url) || G(t) || G(null === (n = e.messageParams) || void 0 === n ? void 0 : n.file);
    }, s.prototype._createPendingScheduledFileMessage = function (e, t, n) {
      var r = this._createPendingFileMessage(e, t, n);
      return r.scheduledInfo = {
        scheduledMessageId: 0,
        scheduledAt: e.scheduledAt,
        scheduledMessageParams: e
      }, r;
    }, s.prototype._markMessageAsFailed = function (e, t, n) {
      void 0 === n && (n = !1), e.errorCode = t.code, t.code === P.REQUEST_CANCELED || t.code === P.FILE_UPLOAD_CANCEL_FAILED ? e.sendingStatus = k.CANCELED : n || (e.sendingStatus = k.FAILED);
    }, s.prototype.sendUserMessage = function (e) {
      var t = u(u({}, rn), e);
      return D(un(t)).throw(O.invalidParameters), this._sendUserMessage(t);
    }, s.prototype._sendUserMessage = function (e, t, r) {
      var i = this;
      void 0 === t && (t = mi.SEND);
      var s = n.of(this._iid),
        a = s.dispatcher,
        o = s.requestQueue,
        c = null != r ? r : this._generateRequestId(),
        l = Date.now(),
        d = new $t();
      return Q(2).then(function () {
        var r = i._createPendingUserMessage(e, c, l);
        a.dispatch(new N({
          messages: [r],
          source: t === mi.RESEND ? C.LOCAL_MESSAGE_RESEND_STARTED : C.EVENT_MESSAGE_SENT_PENDING
        })), oe(function () {
          return U(i, void 0, void 0, function () {
            return A(this, function (e) {
              return [2, d._trigger(r)];
            });
          });
        });
        var s = new yr(u(u({}, e), {
          channelUrl: i.url,
          channelType: i.channelType,
          reqId: c
        }));
        o.send(s).then(function (e) {
          var t = e.as(_r).message;
          a.dispatch(new N({
            messages: [t],
            source: C.EVENT_MESSAGE_SENT_SUCCESS
          })), oe(function () {
            return U(i, void 0, void 0, function () {
              return A(this, function (e) {
                return [2, d._trigger(t)];
              });
            });
          });
        }).catch(function (t) {
          if (ue(t)) throw t;
          Q(2).then(function () {
            var r = i._createPendingUserMessage(e, c, l),
              s = n.of(i._iid).cacheContext,
              o = s.localCacheEnabled && s.localCacheConfig.enableAutoResend && ce(t.code);
            i._markMessageAsFailed(r, t, o), a.dispatch(new N({
              messages: [r],
              source: r.sendingStatus === k.PENDING ? C.REQUEST_RESEND_MESSAGE : C.EVENT_MESSAGE_SENT_FAILED
            })), oe(function () {
              return U(i, void 0, void 0, function () {
                return A(this, function (e) {
                  return [2, d._triggerFailed(t, r)];
                });
              });
            });
          });
        });
      }), d;
    }, s.prototype._autoResendUserMessage = function (e) {
      return D(e instanceof Tt && !!e.messageParams).throw(O.invalidParameters), this._sendUserMessage(e.messageParams, mi.SEND, e.reqId);
    }, s.prototype.resendUserMessage = function (e) {
      var t;
      return U(this, void 0, void 0, function () {
        var n, r;
        return A(this, function (i) {
          return D(e instanceof Tt && !e.scheduledInfo).throw(O.invalidParameters), n = new V(), r = null !== (t = e.messageParams) && void 0 !== t ? t : on(e), this._sendUserMessage(r, mi.RESEND, e.reqId).onFailed(function (e) {
            return n.reject(e);
          }).onSucceeded(function (e) {
            return n.resolve(e);
          }), [2, n.promise];
        });
      });
    }, s.prototype.updateUserMessage = function (e, t) {
      return U(this, void 0, void 0, function () {
        var r, i, s, a;
        return A(this, function (o) {
          switch (o.label) {
            case 0:
              return r = u(u({}, gn), t), D(h("number", e) && bn(r)).throw(O.invalidParameters), i = n.of(this._iid).requestQueue, s = new gr(u({
                channelType: this.channelType,
                channelUrl: this.url,
                messageId: e,
                metaArrayParams: r.metaArrays ? {
                  array: r.metaArrays,
                  mode: "add",
                  upsert: !0
                } : void 0
              }, r)), [4, i.send(s)];
            case 1:
              return a = o.sent(), [2, a.as(br).message];
          }
        });
      });
    }, s.prototype.copyUserMessage = function (e, n) {
      var r, i, a;
      return U(this, void 0, void 0, function () {
        var o, c;
        return A(this, function (l) {
          return D(e instanceof s && n instanceof Tt && n.sendingStatus === k.SUCCEEDED && this.url === n.channelUrl && !n.scheduledInfo).throw(O.invalidParameters), D(!n.poll).throw(O.notSupportedError), o = new V(), c = null !== (r = n.messageParams) && void 0 !== r ? r : t(u(u({}, n), {
            mentionType: n.mentionType,
            mentionedUserIds: null !== (i = n.mentionedUserIds) && void 0 !== i ? i : null === (a = n.mentionedUsers) || void 0 === a ? void 0 : a.map(function (e) {
              return e.userId;
            }),
            translationTargetLanguages: Object.keys(n.translations),
            pushNotificationDeliveryOption: q.DEFAULT,
            parentMessageId: null,
            isReplyToChannel: !1,
            isPinnedMessage: !1
          })), e._sendUserMessage(c).onSucceeded(function (e) {
            o.resolve(e);
          }).onFailed(function (e) {
            return o.reject(e);
          }), [2, o.promise];
        });
      });
    }, s.prototype.translateUserMessage = function (e, t) {
      return U(this, void 0, void 0, function () {
        var r, i, s;
        return A(this, function (a) {
          switch (a.label) {
            case 0:
              return D(e instanceof Tt && e.messageId > 0 && d("string", t)).throw(O.invalidParameters), r = n.of(this._iid).requestQueue, i = new Ur({
                channelType: this.channelType,
                channelUrl: this.url,
                messageId: e.messageId,
                translationTargetLanguages: t
              }), [4, r.send(i)];
            case 1:
              return s = a.sent(), [2, s.as(Ar).message];
          }
        });
      });
    }, s.prototype._createScheduledUserMessage = function (e, t) {
      var r = this,
        i = n.of(this._iid).requestQueue,
        s = Date.now(),
        a = this._generateRequestId();
      Q(2).then(function () {
        var n = r._createPendingScheduledUserMessage(e, a, s);
        oe(function () {
          return U(r, void 0, void 0, function () {
            return A(this, function (e) {
              return [2, t._trigger(n)];
            });
          });
        });
      });
      var o = new Nr(u({
        reqId: a,
        channelType: this.channelType,
        channelUrl: this.url
      }, e));
      i.send(o).then(function (e) {
        var n = e.as(Cr).message;
        oe(function () {
          return U(r, void 0, void 0, function () {
            return A(this, function (e) {
              return [2, t._trigger(n)];
            });
          });
        });
      }).catch(function (n) {
        if (ue(n)) throw n;
        Q(2).then(function () {
          var i = r._createPendingScheduledUserMessage(e, a, s);
          r._markMessageAsFailed(i, n), oe(function () {
            return U(r, void 0, void 0, function () {
              return A(this, function (e) {
                return [2, t._triggerFailed(n, i)];
              });
            });
          });
        });
      });
    }, s.prototype.sendFileMessage = function (e) {
      var t = u(u({}, hn), e);
      return D(vn(t)).throw(O.invalidParameters), this._sendFileMessage(t);
    }, s.prototype.sendMultipleFilesMessage = function (e) {
      var t,
        r = u(u({}, ai), e),
        i = n.of(this._iid);
      D(function (e, t) {
        return void 0 === t && (t = se), tn(e) && h("string", e.message, !0) && Array.isArray(e.fileInfoList) && e.fileInfoList.length >= 2 && e.fileInfoList.length <= t && e.fileInfoList.every(function (e) {
          return oi(e);
        });
      }(r, null === (t = i.appInfo) || void 0 === t ? void 0 : t.multipleFilesMessageFileCountLimit)).throw(O.invalidParameters);
      var s = !!e.fileInfoList.find(function (e) {
        var t = i.appInfo.uploadSizeLimit;
        return e.file instanceof Blob && e.file.size > t || e.fileSize > t;
      });
      return D(!s).throw(O.fileSizeLimitExceededError), this._sendMultipleFilesMessage(r);
    }, s.prototype._sendFileMessage = function (e, t, r) {
      var i = this;
      void 0 === t && (t = mi.SEND);
      var s = n.of(this._iid).dispatcher,
        a = ra.of(this._iid).fileMessageQueue,
        o = null != r ? r : this._generateRequestId(),
        u = Date.now(),
        c = new $t();
      return Q(2).then(function () {
        var r = i._createPendingFileMessage(e, o, u);
        s.dispatch(new N({
          messages: [r],
          source: t === mi.RESEND ? C.LOCAL_MESSAGE_RESEND_STARTED : C.EVENT_MESSAGE_SENT_PENDING
        })), oe(function () {
          return U(i, void 0, void 0, function () {
            return A(this, function (e) {
              return [2, c._trigger(r)];
            });
          });
        }), a.request(i, o, e).then(function (e) {
          s.dispatch(new N({
            messages: [e],
            source: C.EVENT_MESSAGE_SENT_SUCCESS
          })), oe(function () {
            return U(i, void 0, void 0, function () {
              return A(this, function (t) {
                return [2, c._trigger(e)];
              });
            });
          });
        }).catch(function (t) {
          if (ue(t)) throw t;
          Q(2).then(function () {
            var r = i._createPendingFileMessage(e, o, u),
              a = n.of(i._iid).cacheContext,
              l = a.localCacheEnabled && a.localCacheConfig.enableAutoResend && ce(t.code);
            i._markMessageAsFailed(r, t, l), s.dispatch(new N({
              messages: [r],
              source: r.sendingStatus === k.PENDING ? C.REQUEST_RESEND_MESSAGE : t.code === P.FILE_UPLOAD_CANCEL_FAILED ? C.LOCAL_MESSAGE_CANCELED : C.EVENT_MESSAGE_SENT_FAILED
            })), oe(function () {
              return U(i, void 0, void 0, function () {
                return A(this, function (e) {
                  return [2, c._triggerFailed(t, r)];
                });
              });
            });
          });
        });
      }), c;
    }, s.prototype._autoResendFileMessage = function (e) {
      D(e instanceof At && !!e.messageParams).throw(O.invalidParameters);
      var t = n.of(this._iid).logger;
      return t.debug("autoResendFileMessage pending", e), this._sendFileMessage(e.messageParams, mi.SEND, e.reqId).onFailed(function (e) {
        t.debug("autoResendFileMessage failed", e);
      }).onSucceeded(function (e) {
        t.debug("autoResendFileMessage success", e);
      });
    }, s.prototype._sendMultipleFilesMessage = function (e, t, r) {
      var i = this,
        s = n.of(this._iid).dispatcher,
        a = ra.of(this._iid).fileMessageQueue,
        o = null != r ? r : this._generateRequestId(),
        u = Date.now(),
        c = new ui();
      return Q(2).then(function () {
        var n = i._createPendingMultipleFilesMessage(e, o, u);
        s.dispatch(new N({
          messages: [n],
          source: t === mi.RESEND ? C.LOCAL_MESSAGE_RESEND_STARTED : C.EVENT_MESSAGE_SENT_PENDING
        })), oe(function () {
          return U(i, void 0, void 0, function () {
            return A(this, function (e) {
              return [2, c._trigger(n)];
            });
          });
        }), a.request(i, o, e, {
          requestHandler: c,
          isCopy: t === mi.COPY
        }).then(function (e) {
          s.dispatch(new N({
            messages: [e],
            source: C.EVENT_MESSAGE_SENT_SUCCESS
          })), oe(function () {
            return U(i, void 0, void 0, function () {
              return A(this, function (t) {
                return [2, c._trigger(e)];
              });
            });
          });
        }).catch(function (t) {
          if (ue(t)) throw t;
          Q(2).then(function () {
            var n = i._createPendingMultipleFilesMessage(e, o, u);
            i._markMessageAsFailed(n, t), s.dispatch(new N({
              messages: [n],
              source: C.EVENT_MESSAGE_SENT_FAILED
            })), oe(function () {
              return U(i, void 0, void 0, function () {
                return A(this, function (e) {
                  return [2, c._triggerFailed(t, n)];
                });
              });
            });
          });
        });
      }), c;
    }, s.prototype._createScheduledFileMessage = function (e, t, r, i) {
      var s = this,
        a = n.of(this._iid).requestQueue,
        o = new Or(u(u({
          reqId: r,
          channelType: this.channelType,
          channelUrl: this.url
        }, e), {
          fileUrl: e.fileUrl,
          requireAuth: e.requireAuth
        }));
      a.send(o).then(function (e) {
        var n = e.as(Pr).message;
        oe(function () {
          return U(s, void 0, void 0, function () {
            return A(this, function (e) {
              return [2, t._trigger(n)];
            });
          });
        });
      }).catch(function (n) {
        if (ue(n)) throw n;
        Q(2).then(function () {
          var a = s._createPendingScheduledFileMessage(e, r, i);
          s._markMessageAsFailed(a, n), oe(function () {
            return U(s, void 0, void 0, function () {
              return A(this, function (e) {
                return [2, t._triggerFailed(n, a)];
              });
            });
          });
        });
      });
    }, s.prototype.sendFileMessages = function (e) {
      var t, n;
      D(e.every(function (e) {
        return vn(u(u({}, hn), e));
      })).throw(O.invalidParameters);
      var r = new $t();
      try {
        for (var i = v(e), s = i.next(); !s.done; s = i.next()) {
          var a = s.value;
          this.sendFileMessage(a).onPending(function (e) {
            return r._trigger(e);
          }).onFailed(function (e, t) {
            return r._triggerFailed(e, t);
          }).onSucceeded(function (e) {
            return r._trigger(e);
          });
        }
      } catch (e) {
        t = {
          error: e
        };
      } finally {
        try {
          s && !s.done && (n = i.return) && n.call(i);
        } finally {
          if (t) throw t.error;
        }
      }
      return r;
    }, s.prototype.resendFileMessage = function (e, t) {
      return U(this, void 0, void 0, function () {
        var n, r, i;
        return A(this, function (s) {
          return n = this._validateFailedFileMessageHasFile(e, t), D(e instanceof At && n && !e.scheduledInfo).throw(O.invalidParameters), r = new V(), i = mn(e, t), this._sendFileMessage(i, mi.RESEND, e.reqId).onFailed(function (e) {
            return r.reject(e);
          }).onSucceeded(function (e) {
            return r.resolve(e);
          }), [2, r.promise];
        });
      });
    }, s.prototype.resendMessage = function (e, t) {
      var n, r;
      if (D(e instanceof yt && !e.scheduledInfo && e.isResendable).throw(O.invalidParameters), e.isUserMessage()) {
        var i = null !== (n = e.messageParams) && void 0 !== n ? n : on(e);
        return this._sendUserMessage(i, mi.RESEND, e.reqId);
      }
      if (e.isFileMessage()) {
        var s = this._validateFailedFileMessageHasFile(e, t);
        D(s).throw(O.invalidParameters);
        i = null !== (r = e.messageParams) && void 0 !== r ? r : mn(e, t);
        return this._sendFileMessage(i, mi.RESEND, e.reqId);
      }
      if (e.isMultipleFilesMessage()) throw O.channelTypeNotSupportedError;
    }, s.prototype.updateFileMessage = function (e, t) {
      return U(this, void 0, void 0, function () {
        var r, i, s, a;
        return A(this, function (o) {
          switch (o.label) {
            case 0:
              return r = u(u({}, In), t), D(h("number", e) && function (e) {
                return _n(e);
              }(r)).throw(O.invalidParameters), i = n.of(this._iid).requestQueue, s = new Ir(u({
                channelType: this.channelType,
                channelUrl: this.url,
                messageId: e,
                metaArrayParams: r.metaArrays ? {
                  array: r.metaArrays,
                  mode: "add",
                  upsert: !0
                } : void 0
              }, r)), [4, i.send(s)];
            case 1:
              return a = o.sent(), [2, a.as(Er).message];
          }
        });
      });
    }, s.prototype.uploadFile = function (e) {
      return U(this, void 0, void 0, function () {
        var t, r, i, s, a, o, u;
        return A(this, function (c) {
          switch (c.label) {
            case 0:
              return t = n.of(this._iid).requestQueue, r = ae(), (i = new ke({
                file: e.file,
                channelUrl: this.url,
                thumbnailSizes: e.thumbnailSizes,
                requestId: r
              })).uploadProgressHandler = e.progressHandler, e.uploadStartedHandler && e.uploadStartedHandler(r), [4, t.send(i)];
            case 1:
              return s = c.sent(), a = s.as(Re), o = a.url, u = a.thumbnails, [2, {
                requestId: r,
                url: o,
                thumbnails: u
              }];
          }
        });
      });
    }, s.prototype.cancelUploadingFileMessage = function (e) {
      return U(this, void 0, void 0, function () {
        return A(this, function (t) {
          return D(h("string", e)).throw(O.invalidParameters), ra.of(this._iid).fileMessageQueue.cancel(this, e), [2, !0];
        });
      });
    }, s.prototype.copyFileMessage = function (e, n) {
      var r, i, a;
      return U(this, void 0, void 0, function () {
        var o, c;
        return A(this, function (l) {
          return D(e instanceof s && n instanceof At && n.sendingStatus === k.SUCCEEDED && this.url === n.channelUrl && !n.scheduledInfo).throw(O.invalidParameters), o = new V(), c = t(u(u({}, n), {
            fileUrl: n.url,
            fileName: n.name,
            fileSize: n.size,
            mimeType: n.type,
            mentionType: n.mentionType,
            mentionedUserIds: null !== (r = n.mentionedUserIds) && void 0 !== r ? r : null === (i = n.mentionedUsers) || void 0 === i ? void 0 : i.map(function (e) {
              return e.userId;
            }),
            pushNotificationDeliveryOption: q.DEFAULT,
            parentMessageId: null,
            isReplyToChannel: !1,
            thumbnailSizes: null === (a = n.thumbnails) || void 0 === a ? void 0 : a.map(function (e) {
              return {
                maxWidth: e.width,
                maxHeight: e.height
              };
            }),
            requireAuth: n.requireAuth,
            isPinnedMessage: !1
          })), e._sendFileMessage(c).onSucceeded(function (e) {
            return o.resolve(e);
          }).onFailed(function (e) {
            return o.reject(e);
          }), [2, o.promise];
        });
      });
    }, s.prototype.copyMessage = function (e, t) {
      D(e instanceof s && t instanceof yt && t.sendingStatus === k.SUCCEEDED && this.url === t.channelUrl && !t.scheduledInfo).throw(O.invalidParameters);
      var n = t,
        r = e;
      if (n.isUserMessage()) {
        D(!n.poll).throw(O.notSupportedError);
        var i = sn(n);
        return r._sendUserMessage(i);
      }
      if (n.isFileMessage()) {
        i = fn(n);
        return r._sendFileMessage(i);
      }
      if (t.isMultipleFilesMessage()) throw O.channelTypeNotSupportedError;
    }, s.prototype.deleteMessage = function (e) {
      return U(this, void 0, void 0, function () {
        var t, r;
        return A(this, function (i) {
          switch (i.label) {
            case 0:
              return D(e instanceof vt).throw(O.invalidParameters), e.messageId > 0 ? (t = n.of(this._iid).requestQueue, r = new he({
                channelType: this.channelType,
                channelUrl: this.url,
                messageId: e.messageId,
                messageToken: e instanceof yt ? e._messageToken : void 0
              }), [4, t.send(r)]) : [3, 2];
            case 1:
              i.sent(), i.label = 2;
            case 2:
              return [2];
          }
        });
      });
    }, s.prototype.addReaction = function (e, t) {
      return U(this, void 0, void 0, function () {
        var r, i, s;
        return A(this, function (a) {
          switch (a.label) {
            case 0:
              return D(e instanceof vt && e.messageId > 0 && h("string", t)).throw(O.invalidParameters), r = n.of(this._iid).requestQueue, i = new Tr({
                channelType: this.channelType,
                channelUrl: this.url,
                messageId: e.messageId,
                reactionKey: t
              }), [4, r.send(i)];
            case 1:
              return s = a.sent(), [2, s.as(wr).reactionEvent];
          }
        });
      });
    }, s.prototype.deleteReaction = function (e, t) {
      return U(this, void 0, void 0, function () {
        var r, i, s;
        return A(this, function (a) {
          switch (a.label) {
            case 0:
              return D(e instanceof vt && e.messageId > 0 && h("string", t)).throw(O.invalidParameters), r = n.of(this._iid).requestQueue, i = new Mr({
                channelType: this.channelType,
                channelUrl: this.url,
                messageId: e.messageId,
                reactionKey: t
              }), [4, r.send(i)];
            case 1:
              return s = a.sent(), [2, s.as(Sr).reactionEvent];
          }
        });
      });
    }, s.prototype._updateUserMessageMetaArray = function (e, t, r, i) {
      return U(this, void 0, void 0, function () {
        var s, a, o, u, c, l;
        return A(this, function (d) {
          switch (d.label) {
            case 0:
              return s = n.of(this._iid), a = s.dispatcher, o = s.requestQueue, u = new gr({
                channelType: this.channelType,
                channelUrl: this.url,
                messageId: e,
                metaArrayParams: {
                  array: t,
                  mode: r,
                  upsert: i
                }
              }), [4, o.send(u)];
            case 1:
              return c = d.sent(), l = c.as(br).message, a.dispatch(new N({
                messages: [l],
                source: C.EVENT_MESSAGE_UPDATED
              })), [2, l];
          }
        });
      });
    }, s.prototype._updateFileMessageMetaArray = function (e, t, r, i) {
      return U(this, void 0, void 0, function () {
        var s, a, o, u, c, l;
        return A(this, function (d) {
          switch (d.label) {
            case 0:
              return s = n.of(this._iid), a = s.dispatcher, o = s.requestQueue, u = new Ir({
                channelType: this.channelType,
                channelUrl: this.url,
                messageId: e,
                metaArrayParams: {
                  array: t,
                  mode: r,
                  upsert: i
                }
              }), [4, o.send(u)];
            case 1:
              return c = d.sent(), l = c.as(Er).message, a.dispatch(new N({
                messages: [l],
                source: C.EVENT_MESSAGE_UPDATED
              })), [2, l];
          }
        });
      });
    }, s.prototype.createMessageMetaArrayKeys = function (e, t) {
      return U(this, void 0, void 0, function () {
        var n;
        return A(this, function (r) {
          return D(e instanceof vt && e.messageId > 0 && d("string", t)).throw(O.invalidParameters), n = t.map(function (e) {
            return new Ge({
              key: e
            });
          }), e instanceof At ? [2, this._updateFileMessageMetaArray(e.messageId, n, "add", !0)] : [2, this._updateUserMessageMetaArray(e.messageId, n, "add", !0)];
        });
      });
    }, s.prototype.deleteMessageMetaArrayKeys = function (e, t) {
      return U(this, void 0, void 0, function () {
        var n;
        return A(this, function (r) {
          return D(e instanceof vt && e.messageId > 0 && d("string", t)).throw(O.invalidParameters), n = t.map(function (e) {
            return new Ge({
              key: e
            });
          }), e instanceof At ? [2, this._updateFileMessageMetaArray(e.messageId, n, "remove", !0)] : [2, this._updateUserMessageMetaArray(e.messageId, n, "remove", !0)];
        });
      });
    }, s.prototype.addMessageMetaArrayValues = function (e, t) {
      return U(this, void 0, void 0, function () {
        return A(this, function (n) {
          return D(e instanceof vt && e.messageId > 0 && t.every(function (e) {
            return e instanceof Ge;
          })).throw(O.invalidParameters), e instanceof At ? [2, this._updateFileMessageMetaArray(e.messageId, t, "add", !0)] : [2, this._updateUserMessageMetaArray(e.messageId, t, "add", !0)];
        });
      });
    }, s.prototype.removeMessageMetaArrayValues = function (e, t) {
      return U(this, void 0, void 0, function () {
        return A(this, function (n) {
          return D(e instanceof vt && e.messageId > 0 && t.every(function (e) {
            return e instanceof Ge;
          })).throw(O.invalidParameters), e instanceof At ? [2, this._updateFileMessageMetaArray(e.messageId, t, "remove", !0)] : [2, this._updateUserMessageMetaArray(e.messageId, t, "remove", !0)];
        });
      });
    }, s.prototype.report = function (e, t) {
      return U(this, void 0, void 0, function () {
        var r, i, s, a;
        return A(this, function (o) {
          switch (o.label) {
            case 0:
              return D((f(pn, e) || e instanceof $) && h("string", t)).throw(O.invalidParameters), r = n.of(this._iid), i = r.sdkState, s = r.requestQueue, a = new pr({
                channelUrl: this.url,
                channelType: this.channelType,
                userId: i.userId,
                category: e,
                description: t
              }), [4, s.send(a)];
            case 1:
              return o.sent(), [2];
          }
        });
      });
    }, s.prototype.reportUser = function (e, t, r) {
      return U(this, void 0, void 0, function () {
        var i, s, a, o;
        return A(this, function (u) {
          switch (u.label) {
            case 0:
              return D(e instanceof c && (f(pn, t) || t instanceof $) && h("string", r)).throw(O.invalidParameters), i = n.of(this._iid), s = i.sdkState, a = i.requestQueue, o = new mr({
                channelUrl: this.url,
                channelType: this.channelType,
                userId: s.userId,
                offendingUserId: e.userId,
                category: t,
                description: r
              }), [4, a.send(o)];
            case 1:
              return u.sent(), [2];
          }
        });
      });
    }, s.prototype.reportMessage = function (e, t, r) {
      return U(this, void 0, void 0, function () {
        var i, s, a, o;
        return A(this, function (u) {
          switch (u.label) {
            case 0:
              return D(e instanceof yt && (f(pn, t) || t instanceof $) && h("string", r)).throw(O.invalidParameters), i = n.of(this._iid), s = i.sdkState, a = i.requestQueue, o = new vr({
                channelUrl: this.url,
                channelType: this.channelType,
                userId: s.userId,
                offendingUserId: e.sender.userId,
                messageId: e.messageId,
                category: t,
                description: r,
                messageToken: e._messageToken
              }), [4, a.send(o)];
            case 1:
              return u.sent(), [2];
          }
        });
      });
    }, s.prototype.updatePoll = function (e, t) {
      return U(this, void 0, void 0, function () {
        var r, i, s;
        return A(this, function (a) {
          switch (a.label) {
            case 0:
              return D(h("number", e) && function (e) {
                return h("string", e.title, !0) && ne(e.data) && h("boolean", e.allowUserSuggestion, !0) && h("boolean", e.allowMultipleVotes, !0) && h("number", e.closeAt, !0);
              }(t)).throw(O.invalidParameters), r = n.of(this._iid).requestQueue, i = new kr(u({
                pollId: e
              }, t)), [4, r.send(i)];
            case 1:
              return s = a.sent(), [2, s.as(Rr).poll];
          }
        });
      });
    }, s.prototype.deletePoll = function (e) {
      return U(this, void 0, void 0, function () {
        var t, r, i;
        return A(this, function (s) {
          switch (s.label) {
            case 0:
              return t = h("number", e), D(t).throw(O.invalidParameters), r = n.of(this._iid).requestQueue, i = new xr({
                pollId: e
              }), [4, r.send(i)];
            case 1:
              return s.sent(), [2];
          }
        });
      });
    }, s.prototype.closePoll = function (e) {
      return U(this, void 0, void 0, function () {
        var t, r, i, s;
        return A(this, function (a) {
          switch (a.label) {
            case 0:
              return t = h("number", e), D(t).throw(O.invalidParameters), r = n.of(this._iid).requestQueue, i = new Dr({
                pollId: e
              }), [4, r.send(i)];
            case 1:
              return s = a.sent(), [2, s.as(Lr).poll];
          }
        });
      });
    }, s.prototype.addPollOption = function (e, t) {
      return U(this, void 0, void 0, function () {
        var r, i, s, a;
        return A(this, function (o) {
          switch (o.label) {
            case 0:
              return r = h("number", e) && h("string", t) && "" !== t.trim(), D(r).throw(O.invalidParameters), i = n.of(this._iid).requestQueue, s = new Fr({
                channelUrl: this.url,
                channelType: this.channelType,
                pollId: e,
                optionText: t
              }), [4, i.send(s)];
            case 1:
              return a = o.sent(), [2, a.as(qr).poll];
          }
        });
      });
    }, s.prototype.updatePollOption = function (e, t, r) {
      return U(this, void 0, void 0, function () {
        var i, s, a, o;
        return A(this, function (u) {
          switch (u.label) {
            case 0:
              return i = h("number", e) && h("number", t) && h("string", r) && "" !== r.trim(), D(i).throw(O.invalidParameters), s = n.of(this._iid).requestQueue, a = new zr({
                pollId: e,
                pollOptionId: t,
                optionText: r
              }), [4, s.send(a)];
            case 1:
              return o = u.sent(), [2, o.as(Br).poll];
          }
        });
      });
    }, s.prototype.deletePollOption = function (e, t) {
      return U(this, void 0, void 0, function () {
        var r, i, s;
        return A(this, function (a) {
          switch (a.label) {
            case 0:
              return r = h("number", e) && h("number", t), D(r).throw(O.invalidParameters), i = n.of(this._iid).requestQueue, s = new jr({
                pollId: e,
                pollOptionId: t
              }), [4, i.send(s)];
            case 1:
              return a.sent(), [2];
          }
        });
      });
    }, s.prototype.votePoll = function (e, t) {
      return U(this, void 0, void 0, function () {
        var r, i, s, a, o, u, c;
        return A(this, function (l) {
          switch (l.label) {
            case 0:
              return r = h("number", e) && d("number", t), D(r).throw(O.invalidParameters), i = n.of(this._iid), s = i.requestQueue, a = i.dispatcher, o = new Kr({
                reqId: this._generateRequestId(),
                channelUrl: this.url,
                channelType: this.channelType,
                pollId: e,
                pollOptionIds: t
              }), [4, s.send(o)];
            case 1:
              return u = l.sent(), c = u.as(Qr).event, a.dispatch(new fe({
                event: c,
                source: C.EVENT_POLL_VOTED
              })), [2, c];
          }
        });
      });
    }, s.prototype.getPollChangeLogsSinceTimestamp = function (e) {
      return U(this, void 0, void 0, function () {
        return A(this, function (t) {
          switch (t.label) {
            case 0:
              return D(h("number", e)).throw(O.invalidParameters), [4, pe.of(this._iid).getPollChangeLogs(this.url, this.channelType, e)];
            case 1:
              return [2, t.sent()];
          }
        });
      });
    }, s.prototype.getPollChangeLogsSinceToken = function (e) {
      return U(this, void 0, void 0, function () {
        return A(this, function (t) {
          switch (t.label) {
            case 0:
              return D(h("string", e, !0)), [4, pe.of(this._iid).getPollChangeLogs(this.url, this.channelType, e)];
            case 1:
              return [2, t.sent()];
          }
        });
      });
    }, s.prototype.createPollListQuery = function (e) {
      return void 0 === e && (e = 10), new le(this._iid, {
        channelUrl: this.url,
        channelType: this.channelType,
        limit: e
      });
    }, s.prototype.createPollVoterListQuery = function (e, t, n) {
      return void 0 === n && (n = 20), new de(this._iid, {
        channelUrl: this.url,
        channelType: this.channelType,
        pollId: e,
        pollOptionId: t,
        limit: n
      });
    }, s.prototype.pinMessage = function (e) {
      return U(this, void 0, void 0, function () {
        var t, r;
        return A(this, function (i) {
          switch (i.label) {
            case 0:
              return D(h("number", e) && e > 0).throw(O.invalidParameters), t = n.of(this._iid).requestQueue, r = new fi({
                channelType: this.channelType,
                channelUrl: this.url,
                messageId: e
              }), [4, t.send(r)];
            case 1:
              return i.sent(), [2];
          }
        });
      });
    }, s.prototype.unpinMessage = function (e) {
      return U(this, void 0, void 0, function () {
        var t, r;
        return A(this, function (i) {
          switch (i.label) {
            case 0:
              return D(h("number", e) && e > 0).throw(O.invalidParameters), t = n.of(this._iid).requestQueue, r = new pi({
                channelType: this.channelType,
                channelUrl: this.url,
                messageId: e
              }), [4, t.send(r)];
            case 1:
              return i.sent(), [2];
          }
        });
      });
    }, s;
  }(l),
  gi = function (e) {
    function t(t) {
      void 0 === t && (t = {});
      var n = e.call(this) || this;
      return Object.keys(t).forEach(function (e) {
        n.hasOwnProperty(e) && (n[e] = t[e]);
      }), n;
    }
    return r(t, e), t;
  }(function () {
    this.onConnected = Y, this.onReconnectStarted = Y, this.onReconnectSucceeded = Y, this.onReconnectFailed = Y, this.onDisconnected = Y;
  }),
  bi = function (e) {
    function t(t) {
      void 0 === t && (t = {});
      var n = e.call(this) || this;
      return Object.keys(t).forEach(function (e) {
        n.hasOwnProperty(e) && (n[e] = t[e]);
      }), n;
    }
    return r(t, e), t;
  }(function () {
    this.onSessionExpired = Y, this.onSessionTokenRequired = function (e) {
      return e(null);
    }, this.onSessionError = Y, this.onSessionRefreshed = Y, this.onSessionClosed = Y;
  }),
  Ii = function (e) {
    function t(t) {
      void 0 === t && (t = {});
      var n = e.call(this) || this;
      return Object.keys(t).forEach(function (e) {
        n.hasOwnProperty(e) && (n[e] = t[e]);
      }), n;
    }
    return r(t, e), t;
  }(function () {
    this.onFriendsDiscovered = Y, this.onTotalUnreadMessageCountChanged = Y, this.onTotalUnreadMessageCountUpdated = Y;
  }),
  Ei = function (t) {
    function n(n) {
      var r = n.limit,
        s = n.token,
        a = n.userIdsFilter,
        o = n.metaDataKeyFilter,
        u = n.metaDataValuesFilter,
        c = n.nicknameStartsWithFilter,
        l = t.call(this) || this;
      return l.method = i.GET, l.path = me, l.params = e({
        limit: r,
        token: s,
        user_ids: a,
        metadatakey: o,
        metadatavalues_in: u,
        nickname_startswith: c
      }), l;
    }
    return r(n, t), n;
  }(a),
  Ti = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.users = n.users.map(function (e) {
        return new c(t, e);
      }), r.next = n.next, r;
    }
    return r(t, e), t;
  }(o),
  wi = function (e) {
    function i(t, n) {
      var r,
        i,
        s,
        a = this;
      return (a = e.call(this, t, n) || this).userIdsFilter = null, a.metaDataKeyFilter = null, a.metaDataValuesFilter = null, a.nicknameStartsWithFilter = null, a.userIdsFilter = null !== (r = n.userIdsFilter) && void 0 !== r ? r : null, a.metaDataKeyFilter = null !== (i = n.metaDataKeyFilter) && void 0 !== i ? i : null, a.metaDataValuesFilter = null !== (s = n.metaDataValuesFilter) && void 0 !== s ? s : null, a.nicknameStartsWithFilter = n.nicknameStartsWithFilter || null, a;
    }
    return r(i, e), i.prototype._validate = function () {
      return e.prototype._validate.call(this) && d("string", this.userIdsFilter, !0) && h("string", this.metaDataKeyFilter, !0) && d("string", this.metaDataValuesFilter, !0) && h("string", this.nicknameStartsWithFilter, !0);
    }, i.prototype.next = function () {
      return U(this, void 0, void 0, function () {
        var e, r, i, s, a, o;
        return A(this, function (c) {
          switch (c.label) {
            case 0:
              return this._validate() ? this._isLoading ? [3, 3] : this._hasNext ? (this._isLoading = !0, e = n.of(this._iid).requestQueue, r = new Ei(t(u(u({}, this), {
                token: this._token
              }))), [4, e.send(r)]) : [3, 2] : [3, 5];
            case 1:
              return i = c.sent(), s = i.as(Ti), a = s.users, o = s.next, this._token = o, this._hasNext = !!o, this._isLoading = !1, [2, a];
            case 2:
              return [2, []];
            case 3:
              throw O.queryInProgress;
            case 4:
              return [3, 6];
            case 5:
              throw O.invalidParameters;
            case 6:
              return [2];
          }
        });
      });
    }, i;
  }(ve),
  Mi = function (t) {
    function n(n) {
      var r = n.userId,
        s = n.limit,
        a = n.token,
        o = n.userIdsFilter,
        u = t.call(this) || this;
      return u.method = i.GET, u.path = "".concat(me, "/").concat(r, "/block"), u.params = e({
        limit: s,
        token: a,
        user_ids: o
      }), u;
    }
    return r(n, t), n;
  }(a),
  Si = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.users = n.users.map(function (e) {
        return new c(t, e);
      }), r.next = n.next, r;
    }
    return r(t, e), t;
  }(o),
  Ui = function (e) {
    function i(t, n) {
      var r,
        i = this;
      return (i = e.call(this, t, n) || this).userIdsFilter = null, i.userIdsFilter = null !== (r = n.userIdsFilter) && void 0 !== r ? r : null, i;
    }
    return r(i, e), i.prototype._validate = function () {
      return e.prototype._validate.call(this) && d("string", this.userIdsFilter, !0);
    }, i.prototype.next = function () {
      return U(this, void 0, void 0, function () {
        var e, r, i, s, a, o, c, l;
        return A(this, function (d) {
          switch (d.label) {
            case 0:
              return this._validate() ? this._isLoading ? [3, 3] : this._hasNext ? (this._isLoading = !0, e = n.of(this._iid), r = e.sdkState, i = e.requestQueue, s = new Mi(t(u(u({}, this), {
                userId: r.userId,
                token: this._token
              }))), [4, i.send(s)]) : [3, 2] : [3, 5];
            case 1:
              return a = d.sent(), o = a.as(Si), c = o.users, l = o.next, this._token = l, this._hasNext = !!l, this._isLoading = !1, [2, c];
            case 2:
              return [2, []];
            case 3:
              throw O.queryInProgress;
            case 4:
              return [3, 6];
            case 5:
              throw O.invalidParameters;
            case 6:
              return [2];
          }
        });
      });
    }, i;
  }(ve),
  Ai = function (e) {
    function t(t) {
      var n = t.userId,
        r = t.limit,
        s = t.token,
        a = e.call(this) || this;
      return a.method = i.GET, a.path = "".concat(me, "/").concat(encodeURIComponent(n), "/friends"), a.params = {
        limit: r,
        token: s
      }, a;
    }
    return r(t, e), t;
  }(a),
  Ni = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.hasMore = n.has_more, r.users = n.users.map(function (e) {
        return new c(t, e);
      }), r.next = n.next, r;
    }
    return r(t, e), t;
  }(o),
  Ci = function (e) {
    function t() {
      return null !== e && e.apply(this, arguments) || this;
    }
    return r(t, e), t.prototype._validate = function () {
      return e.prototype._validate.call(this);
    }, t.prototype.next = function () {
      return U(this, void 0, void 0, function () {
        var e, t, r, i, s, a, o, c, l;
        return A(this, function (d) {
          switch (d.label) {
            case 0:
              return this._validate() ? this._isLoading ? [3, 3] : this._hasNext ? (this._isLoading = !0, e = n.of(this._iid), t = e.sdkState, r = e.requestQueue, i = new Ai(u(u({}, this), {
                userId: t.userId,
                token: this._token
              })), [4, r.send(i)]) : [3, 2] : [3, 5];
            case 1:
              return s = d.sent(), a = s.as(Ni), o = a.users, c = a.hasMore, l = a.next, this._token = l, this._hasNext = c, this._isLoading = !1, [2, o];
            case 2:
              return [2, []];
            case 3:
              throw O.queryInProgress;
            case 4:
              return [3, 6];
            case 5:
              throw O.invalidParameters;
            case 6:
              return [2];
          }
        });
      });
    }, t;
  }(ve),
  Oi = {},
  Pi = function () {
    function e(e) {
      var t = e.dbname,
        n = e.itemSizeLimit,
        r = void 0 === n ? 1048576 : n,
        i = e.cacheLimit,
        s = void 0 === i ? 256 : i,
        a = e.blockHashBase,
        o = void 0 === a ? 2 : a,
        u = e.blockHashMultiplier,
        c = void 0 === u ? 10 : u,
        l = e.blockHashConstant,
        d = void 0 === l ? 11 : l,
        h = e.transactionApplyDelay,
        f = void 0 === h ? 200 : h,
        p = e.disableLogger,
        m = void 0 !== p && p;
      return Oi[t] || (this.itemSizeLimit = r, this.cacheLimit = s, this.blockHashBase = o, this.blockHashMultiplier = c, this.blockHashConstant = d, this.transactionApplyDelay = f, this.disableLogger = m, Oi[t] = this), Oi[t];
    }
    return e.get = function (e) {
      return Oi[e];
    }, e;
  }();
!function (e) {
  e[e.UNKNOWN_ERROR = 6e7] = "UNKNOWN_ERROR", e[e.STORE_NOT_DEFINED = 61001e3] = "STORE_NOT_DEFINED", e[e.STORE_NOT_AVAILABLE = 61001001] = "STORE_NOT_AVAILABLE", e[e.STORE_NOT_AVAILABLE_IN_PRIVATE_BROWSING = 61001002] = "STORE_NOT_AVAILABLE_IN_PRIVATE_BROWSING", e[e.STORE_IS_FULL = 61001003] = "STORE_IS_FULL", e[e.STORE_NOT_INITIALIZED = 61001004] = "STORE_NOT_INITIALIZED", e[e.STORE_INVALID_KEY_TYPE = 61002e3] = "STORE_INVALID_KEY_TYPE", e[e.STORE_BROKEN_INTEGRITY = 61002001] = "STORE_BROKEN_INTEGRITY", e[e.STORE_BROKEN_BLOB = 61002002] = "STORE_BROKEN_BLOB", e[e.STORE_ENCRYPTION_INVALID = 61002003] = "STORE_ENCRYPTION_INVALID", e[e.STORE_ITEM_SIZE_LIMIT_EXCEEDED = 61017e3] = "STORE_ITEM_SIZE_LIMIT_EXCEEDED", e[e.STORE_READ_FAILED = 61017001] = "STORE_READ_FAILED", e[e.STORE_WRITE_FAILED = 61017002] = "STORE_WRITE_FAILED", e[e.DATABASE_SCHEMA_NOT_ON_UPGRADE = 62002e3] = "DATABASE_SCHEMA_NOT_ON_UPGRADE", e[e.COLLECTION_NOT_READY = 63001e3] = "COLLECTION_NOT_READY", e[e.COLLECTION_KEY_NOT_MATCH = 63002e3] = "COLLECTION_KEY_NOT_MATCH", e[e.COLLECTION_QUERY_NOT_VALID = 63002001] = "COLLECTION_QUERY_NOT_VALID", e[e.COLLECTION_KEY_NOT_FOUND = 63004e3] = "COLLECTION_KEY_NOT_FOUND", e[e.COLLECTION_KEY_NOT_GIVEN = 63004001] = "COLLECTION_KEY_NOT_GIVEN", e[e.COLLECTION_INSERT_DUPLICATE = 63009e3] = "COLLECTION_INSERT_DUPLICATE", e[e.COLLECTION_WRITE_FAILED = 63017e3] = "COLLECTION_WRITE_FAILED", e[e.COLLECTION_ITEM_SIZE_LIMIT_EXCEEDED = 63017001] = "COLLECTION_ITEM_SIZE_LIMIT_EXCEEDED", e[e.INDEX_TABLE_IS_REQUIRED = 65001e3] = "INDEX_TABLE_IS_REQUIRED", e[e.INDEX_TYPE_NOT_MATCH = 65002e3] = "INDEX_TYPE_NOT_MATCH", e[e.COMPARE_TYPE_NOT_MATCH = 69002001] = "COMPARE_TYPE_NOT_MATCH", e[e.CIRCULAR_REFERENCE_FOUND = 69002002] = "CIRCULAR_REFERENCE_FOUND";
}(yi || (yi = {}));
var ki,
  Ri = function (e) {
    function t(n) {
      var r = n.code,
        i = void 0 === r ? yi.UNKNOWN_ERROR : r,
        s = n.message,
        a = void 0 === s ? "Unknown error occurred." : s,
        o = e.call(this, a) || this;
      return o.code = i, Object.setPrototypeOf(o, t.prototype), o;
    }
    return r(t, e), Object.defineProperty(t, "storeNotDefined", {
      get: function () {
        return new t({
          code: yi.STORE_NOT_DEFINED,
          message: "Store is not defined. Specify the store on NestDB()"
        });
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t, "storeNotAvailable", {
      get: function () {
        return new t({
          code: yi.STORE_NOT_AVAILABLE,
          message: "Store is not available. Check your environment settings."
        });
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t, "storeNotAvailableInPrivateBrowsing", {
      get: function () {
        return new t({
          code: yi.STORE_NOT_AVAILABLE_IN_PRIVATE_BROWSING,
          message: "Store is not available because it is in private browsing."
        });
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t, "storeIsFull", {
      get: function () {
        return new t({
          code: yi.STORE_IS_FULL,
          message: "Store is full."
        });
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t, "storeNotInitialized", {
      get: function () {
        return new t({
          code: yi.STORE_NOT_INITIALIZED,
          message: "Store is not initialized."
        });
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t, "storeKeyTypeIsInvalid", {
      get: function () {
        return new t({
          code: yi.STORE_INVALID_KEY_TYPE,
          message: "Store key should be string type."
        });
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t, "storeBrokenIntegrity", {
      get: function () {
        return new t({
          code: yi.STORE_BROKEN_INTEGRITY,
          message: "Data should be in a store but it does not. Integrity is broken."
        });
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t, "storeBrokenBlob", {
      get: function () {
        return new t({
          code: yi.STORE_BROKEN_BLOB,
          message: "Data should be in a store but it does not. Blob data is broken."
        });
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t, "storeEncryptionInvalid", {
      get: function () {
        return new t({
          code: yi.STORE_ENCRYPTION_INVALID,
          message: "Encryption algorithm has changed. All the store should reset."
        });
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t, "storeItemSizeExceeded", {
      get: function () {
        return new t({
          code: yi.STORE_ITEM_SIZE_LIMIT_EXCEEDED,
          message: "The size of the item exceeds the limit that the store allows."
        });
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t, "storeReadFailed", {
      get: function () {
        return new t({
          code: yi.STORE_READ_FAILED,
          message: "Failed to read from store."
        });
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t, "storeWriteFailed", {
      get: function () {
        return new t({
          code: yi.STORE_WRITE_FAILED,
          message: "Failed to write to store."
        });
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t, "databaseSchemaNotOnUpgrade", {
      get: function () {
        return new t({
          code: yi.DATABASE_SCHEMA_NOT_ON_UPGRADE,
          message: "Committing schema is not allowed when upgrade is not running."
        });
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t, "collectionNotReady", {
      get: function () {
        return new t({
          code: yi.COLLECTION_NOT_READY,
          message: "Collection is not ready due to an error during initialization."
        });
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t, "collectionKeyNotMatch", {
      get: function () {
        return new t({
          code: yi.COLLECTION_KEY_NOT_MATCH,
          message: "keyName of collection could not change."
        });
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t, "collectionQueryNotValid", {
      get: function () {
        return new t({
          code: yi.COLLECTION_QUERY_NOT_VALID,
          message: "Query parameter is not a valid format."
        });
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t, "collectionInsertDuplicate", {
      get: function () {
        return new t({
          code: yi.COLLECTION_INSERT_DUPLICATE,
          message: "The key already exists."
        });
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t, "collectionKeyNotFound", {
      get: function () {
        return new t({
          code: yi.COLLECTION_KEY_NOT_FOUND,
          message: "The key is not found."
        });
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t, "collectionKeyNotGiven", {
      get: function () {
        return new t({
          code: yi.COLLECTION_KEY_NOT_GIVEN,
          message: "The item should contain [keyName] property."
        });
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t, "collectionWriteFailed", {
      get: function () {
        return new t({
          code: yi.COLLECTION_WRITE_FAILED,
          message: "Failed to write an item."
        });
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t, "collectionItemSizeExceeded", {
      get: function () {
        return new t({
          code: yi.COLLECTION_ITEM_SIZE_LIMIT_EXCEEDED,
          message: "The size of the item exceeds the limit that a collection allows."
        });
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t, "indexTableIsRequired", {
      get: function () {
        return new t({
          code: yi.INDEX_TABLE_IS_REQUIRED,
          message: "Index table is required."
        });
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t, "indexTypesNotMatch", {
      get: function () {
        return new t({
          code: yi.INDEX_TYPE_NOT_MATCH,
          message: "Indexed column should have primitive type."
        });
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t, "compareTypesNotMatch", {
      get: function () {
        return new t({
          code: yi.COMPARE_TYPE_NOT_MATCH,
          message: "Values to compare have different types."
        });
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t, "circularReferenceFound", {
      get: function () {
        return new t({
          code: yi.CIRCULAR_REFERENCE_FOUND,
          message: "Cannot handle circular referenced object."
        });
      },
      enumerable: !1,
      configurable: !0
    }), t;
  }(Error);
!function (e) {
  e.INIT = "init", e.READY = "ready", e.CLOSED = "closed";
}(ki || (ki = {}));
var xi,
  Di = function (e, t) {
    if (void 0 === t && (t = new WeakMap()), "object" == typeof e && null !== e) {
      if (t.has(e)) throw Ri.circularReferenceFound;
      var n;
      return t.set(e, !0), Array.isArray(e) ? n = e.map(function (e) {
        return Di(e, t);
      }) : e instanceof RegExp || e instanceof Date ? n = e : (n = {}, Object.keys(e).forEach(function (r) {
        n[r] = Di(e[r], t);
      })), t.delete(e), n;
    }
    return e;
  },
  Li = function (e, t) {
    if (null == t) return 1;
    if (null == e) return -1;
    if (typeof e != typeof t) throw Ri.compareTypesNotMatch;
    var n = 0;
    switch (typeof e) {
      case "boolean":
      case "number":
        n = e - t;
        break;
      case "string":
        n = e.localeCompare(t);
    }
    return n;
  },
  Fi = function (e, t) {
    for (var n = 0, r = 0; r < e.length; r++) n = e.charCodeAt(r) + (n << 6) + (n << 16) - n;
    return (n >>> 0) % t;
  },
  qi = function (e) {
    return new Promise(function (t) {
      setTimeout(function () {
        return t();
      }, e);
    });
  },
  zi = function (e, t) {
    if (!t) return !1;
    if ("function" != typeof e) {
      for (var n in e) {
        if (["/and", "&&"].includes(n)) {
          if (e[n].some(function (e) {
            return !zi(e, t);
          })) return !1;
        } else if (["/or", "||"].includes(n)) {
          if (e[n].every(function (e) {
            return !zi(e, t);
          })) return !1;
        } else if ("/where" === n) {
          if (!(0, e[n])(t)) return !1;
        } else {
          var r = n;
          if ("object" == typeof e[r]) {
            var i = e[r];
            for (var s in i) switch (s) {
              case "/eq":
              case "=":
                if ((a = t[r]) !== (o = i[s])) return !1;
                break;
              case "/neq":
              case "!=":
                if ((a = t[r]) === (o = i[s])) return !1;
                break;
              case "/gt":
              case ">":
                var a = t[r],
                  o = i[s];
                if (!(Li(a, o) > 0)) return !1;
                break;
              case "/gte":
              case ">=":
                a = t[r], o = i[s];
                if (!(Li(a, o) >= 0)) return !1;
                break;
              case "/lt":
              case "<":
                a = t[r], o = i[s];
                if (!(Li(a, o) < 0)) return !1;
                break;
              case "/lte":
              case "<=":
                a = t[r], o = i[s];
                if (!(Li(a, o) <= 0)) return !1;
                break;
              case "/in":
                a = t[r];
                if (!(o = i[s]).includes(a)) return !1;
                break;
              case "/nin":
                a = t[r];
                if ((o = i[s]).includes(a)) return !1;
                break;
              case "/contain":
                a = t[r], o = i[s];
                if (!a.includes(o)) return !1;
                break;
              case "/regex":
                a = t[r];
                if (!(o = i[s]).test(a)) return !1;
                break;
              case "/where":
                a = t[r];
                if (!(0, i[s])(a)) return !1;
            }
          } else if ("function" == typeof e[r]) {
            if (!e[r](t[r])) return !1;
          } else if (e[r] !== t[r]) return !1;
        }
      }
      return !0;
    }
    return e(t);
  },
  Bi = function () {},
  ji = function () {
    return Promise.resolve();
  },
  Ki = function (e) {
    return e;
  },
  Qi = function (e, t) {
    t();
  };
!function (e) {
  e[e.FORWARD = 0] = "FORWARD", e[e.BACKWARD = 1] = "BACKWARD";
}(xi || (xi = {}));
var Gi,
  Vi,
  Hi,
  Wi = function () {
    function e(e) {
      var t = e.initialPrevValue,
        n = void 0 === t ? null : t,
        r = e.initialNextValue,
        i = void 0 === r ? null : r,
        s = e.iterator,
        a = e.map,
        o = void 0 === a ? Ki : a,
        u = e.backward,
        c = void 0 === u ? ji : u,
        l = e.forward,
        d = void 0 === l ? ji : l,
        h = e.complete,
        f = void 0 === h ? Bi : h;
      this._prevValue = n, this._nextValue = i, this._error = null, this._map = o, this._backward = c, this._forward = d, this._iterator = s, this._complete = f;
    }
    return Object.defineProperty(e.prototype, "prevValue", {
      get: function () {
        return this._map(this._prevValue);
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(e.prototype, "nextValue", {
      get: function () {
        return this._map(this._nextValue);
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(e.prototype, "error", {
      get: function () {
        return this._error;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(e.prototype, "hasPrevious", {
      get: function () {
        return !!this._prevValue;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(e.prototype, "hasNext", {
      get: function () {
        return !!this._nextValue;
      },
      enumerable: !1,
      configurable: !0
    }), e.prototype.prev = function () {
      return U(this, void 0, void 0, function () {
        var e, t, n;
        return A(this, function (r) {
          switch (r.label) {
            case 0:
              if (!this.hasPrevious) return [3, 6];
              r.label = 1;
            case 1:
              return r.trys.push([1, 3,, 4]), e = this._prevValue, t = this, [4, this._backward()];
            case 2:
              return t._prevValue = r.sent() || null, this._nextValue = e, [3, 4];
            case 3:
              return n = r.sent(), this._error = n, [3, 4];
            case 4:
              return [4, this._iterator(this)];
            case 5:
              return [2, r.sent()];
            case 6:
              this._complete(), r.label = 7;
            case 7:
              return [2];
          }
        });
      });
    }, e.prototype.next = function () {
      return U(this, void 0, void 0, function () {
        var e, t, n;
        return A(this, function (r) {
          switch (r.label) {
            case 0:
              if (!this.hasNext) return [3, 6];
              r.label = 1;
            case 1:
              return r.trys.push([1, 3,, 4]), e = this._nextValue, t = this, [4, this._forward()];
            case 2:
              return t._nextValue = r.sent() || null, this._prevValue = e, [3, 4];
            case 3:
              return n = r.sent(), this._error = n, [3, 4];
            case 4:
              return [4, this._iterator(this)];
            case 5:
              return [2, r.sent()];
            case 6:
              this._complete(), r.label = 7;
            case 7:
              return [2];
          }
        });
      });
    }, e.prototype.stop = function () {
      this._prevValue = null, this._nextValue = null, this._complete();
    }, e;
  }(),
  Yi = function () {
    function e(e) {
      var t = e.condition,
        n = void 0 === t ? {} : t,
        r = e.backward,
        i = void 0 !== r && r,
        s = e.blockManager,
        a = e.indexer;
      this.condition = n, this.backward = i, this._blockManager = s, this._indexer = a;
    }
    return e.prototype.findOptimizedStartPosition = function () {
      var e = this,
        t = ["=", "/eq", ">", ">=", "/gt", "/gte"],
        n = ["=", "/eq", "<", "<=", "/lt", "/lte"];
      if (this.backward) {
        var r = this._indexer.origin.length - 1;
        if ("function" != typeof this.condition) for (h = 0; h < this._indexer.fields.length; h++) {
          var i = this._indexer.fields[h],
            s = 1;
          if ("-" === i[0] && (i = i.slice(1), s = -1), this.condition[i]) if ("object" == typeof this.condition[i]) {
            var a = s > 0 ? n : t;
            for (var o in this.condition[i]) if (a.includes(o)) for (var u = r; u >= 0; u--) if (s * Li(this._indexer.origin[u].columnValues[h], this.condition[i][o]) <= 0) {
              r = u;
              break;
            }
          } else for (u = r; u >= 0; u--) if (s * Li(this._indexer.origin[u].columnValues[h], this.condition[i]) <= 0) {
            r = u;
            break;
          }
        }
        return Math.min(r + 1, this._indexer.origin.length - 1);
      }
      var c = 0;
      if ("function" != typeof this.condition) for (var l = function (r) {
          var i = d._indexer.fields[r],
            s = 1;
          if ("-" === i[0] && (i = i.slice(1), s = -1), d.condition[i]) if ("object" == typeof d.condition[i]) Object.keys(d.condition[i]).forEach(function (a) {
            if ((s > 0 ? t : n).includes(a)) for (var o = c; o < e._indexer.origin.length; o++) if (s * Li(e._indexer.origin[o].columnValues[r], e.condition[i][a]) >= 0) {
              c = o;
              break;
            }
          });else for (var a = c; a < d._indexer.origin.length; a++) if (s * Li(d._indexer.origin[a].columnValues[r], d.condition[i]) >= 0) {
            c = a;
            break;
          }
        }, d = this, h = 0; h < this._indexer.fields.length; h++) l(h);
      return Math.max(c - 1, 0);
    }, e.prototype.each = function (e) {
      return U(this, void 0, void 0, function () {
        var t,
          n,
          r,
          i,
          s,
          a,
          o,
          u = this;
        return A(this, function (c) {
          switch (c.label) {
            case 0:
              if (t = this.findOptimizedStartPosition(), n = 0, this.backward && this._indexer.origin[t] && (n = this._indexer.origin[t].keys.length - 1), r = function () {
                if (u._indexer.origin[t]) {
                  if (!u._indexer.origin[t].keys[++n]) {
                    if (!u._indexer.origin[++t]) return !1;
                    n = 0;
                  }
                  return !0;
                }
                return !1;
              }, i = function () {
                if (u._indexer.origin[t]) {
                  if (!u._indexer.origin[t].keys[--n]) {
                    if (!u._indexer.origin[--t]) return !1;
                    n = u._indexer.origin[t].keys.length - 1;
                  }
                  return !0;
                }
                return !1;
              }, s = null, !this._indexer.origin[t]) return [3, 4];
              a = this.backward ? i : r, c.label = 1;
            case 1:
              return [4, this._blockManager.getFromBlock(this._indexer.origin[t].keys[n])];
            case 2:
              if ((o = c.sent()) && zi(this.condition, o)) return s = o, [3, 4];
              c.label = 3;
            case 3:
              if (a()) return [3, 1];
              c.label = 4;
            case 4:
              return [4, new Promise(function (a) {
                var o = new Wi({
                  initialNextValue: Di(s),
                  iterator: e,
                  forward: function () {
                    return U(u, void 0, void 0, function () {
                      var e, s;
                      return A(this, function (a) {
                        switch (a.label) {
                          case 0:
                            e = this.backward ? i : r, a.label = 1;
                          case 1:
                            return e() ? [4, this._blockManager.getFromBlock(this._indexer.origin[t].keys[n])] : [3, 3];
                          case 2:
                            return (s = a.sent()) && zi(this.condition, s) ? [2, Di(s)] : [3, 1];
                          case 3:
                            return [2, null];
                        }
                      });
                    });
                  },
                  backward: function () {
                    return U(u, void 0, void 0, function () {
                      var e, s;
                      return A(this, function (a) {
                        switch (a.label) {
                          case 0:
                            e = this.backward ? r : i, a.label = 1;
                          case 1:
                            return e() ? [4, this._blockManager.getFromBlock(this._indexer.origin[t].keys[n])] : [3, 3];
                          case 2:
                            return (s = a.sent()) && zi(this.condition, s) ? [2, Di(s)] : [3, 1];
                          case 3:
                            return [2, null];
                        }
                      });
                    });
                  },
                  complete: a
                });
                e(o);
              })];
            case 5:
              return [2, c.sent()];
          }
        });
      });
    }, e;
  }(),
  Ji = function () {
    function e(e) {
      var t = e.condition,
        n = void 0 === t ? {} : t,
        r = e.backward,
        i = void 0 !== r && r,
        s = e.mutex,
        a = e.blockManager,
        o = e.indexer;
      this._mutex = s, this._iterator = new Yi({
        condition: n,
        backward: i,
        blockManager: a,
        indexer: o
      });
    }
    return e.prototype.fetch = function (e) {
      return void 0 === e && (e = {}), U(this, void 0, void 0, function () {
        var t,
          n,
          r,
          i,
          s = this;
        return A(this, function (a) {
          switch (a.label) {
            case 0:
              if (t = Math.max(e.offset || 0, 0), 0 === (n = "number" == typeof e.limit ? e.limit : Number.MAX_SAFE_INTEGER)) return [2, []];
              if (n < 0) throw Ri.collectionQueryNotValid;
              a.label = 1;
            case 1:
              return a.trys.push([1, 4,, 5]), r = [], [4, this._mutex.lock()];
            case 2:
              return a.sent(), [4, this._iterator.each(function (e) {
                return U(s, void 0, void 0, function () {
                  return A(this, function (i) {
                    return e.error ? e.stop() : e.hasNext ? 0 === t ? (r.push(e.nextValue), 0 < n && n <= r.length ? e.stop() : e.next()) : (t--, e.next()) : e.stop(), [2];
                  });
                });
              })];
            case 3:
              return a.sent(), this._mutex.unlock(), [2, r];
            case 4:
              throw i = a.sent(), this._mutex.unlock(), i;
            case 5:
              return [2];
          }
        });
      });
    }, e.prototype.count = function () {
      return U(this, void 0, void 0, function () {
        var e,
          t,
          n = this;
        return A(this, function (r) {
          switch (r.label) {
            case 0:
              return r.trys.push([0, 3,, 4]), e = 0, [4, this._mutex.lock()];
            case 1:
              return r.sent(), [4, this._iterator.each(function (t) {
                return U(n, void 0, void 0, function () {
                  return A(this, function (n) {
                    return t.error ? t.stop() : t.hasNext ? (e++, t.next()) : t.stop(), [2];
                  });
                });
              })];
            case 2:
              return r.sent(), this._mutex.unlock(), [2, e];
            case 3:
              throw t = r.sent(), this._mutex.unlock(), t;
            case 4:
              return [2];
          }
        });
      });
    }, e;
  }(),
  Xi = function (e) {
    return "".concat("nest", "@").concat(e);
  },
  Zi = function (e, t) {
    return "".concat(Xi(e), "/").concat(t);
  },
  $i = function (e, t) {
    return "".concat(Zi(e, t), ".metadata");
  },
  es = function (e, t) {
    return "".concat(Zi(e, t), "/block.");
  },
  ts = function (e, t) {
    return "".concat(Zi(e, t), "/blob.");
  },
  ns = function () {
    function e(e) {
      var t = e.dbname,
        n = e.collectionName,
        r = e.store;
      this.dbname = t, this.collectionName = n, this.store = r;
    }
    return e.prototype.get = function (e) {
      return U(this, void 0, void 0, function () {
        var t, n, r, i, s, a, o, u, c;
        return A(this, function (l) {
          switch (l.label) {
            case 0:
              return [4, this.store.get(e)];
            case 1:
              return (t = l.sent()) ? (n = t.data, r = t.type, "undefined" == typeof fetch ? [3, 4] : [4, fetch(n)]) : [3, 5];
            case 2:
              return [4, l.sent().blob()];
            case 3:
              return [2, l.sent()];
            case 4:
              for (i = [], s = atob(n.split(",")[1]), a = 0; a < s.length; a += 512) {
                for (o = s.slice(a, a + 512), u = new Array(o.length), c = 0; c < o.length; c++) u[c] = o.charCodeAt(c);
                i.push(new Uint8Array(u));
              }
              return [2, new Blob(i, {
                type: r
              })];
            case 5:
              return [2, null];
          }
        });
      });
    }, e.prototype.save = function (e, t) {
      return void 0 === t && (t = "".concat(Date.now())), U(this, void 0, void 0, function () {
        var n,
          r,
          i,
          s,
          a = this;
        return A(this, function (o) {
          switch (o.label) {
            case 0:
              return [4, new Promise(function (n) {
                var r = function (e, t, n, r) {
                    return void 0 === r && (r = 0), "".concat(ts(e, t)).concat(n, ".").concat(r);
                  }(a.dbname, a.collectionName, t),
                  i = new FileReader();
                i.onload = function () {
                  n({
                    blobId: r,
                    data: i.result,
                    type: e.type
                  });
                }, i.readAsDataURL(e);
              })];
            case 1:
              return n = o.sent(), r = n.blobId, i = n.data, s = n.type, [4, this.store.set({
                key: r,
                value: {
                  data: i,
                  type: s
                }
              })];
            case 2:
              return o.sent(), [2, r];
          }
        });
      });
    }, e.prototype.remove = function (e) {
      return U(this, void 0, void 0, function () {
        return A(this, function (t) {
          switch (t.label) {
            case 0:
              return [4, this.store.remove(e)];
            case 1:
              return t.sent(), [2];
          }
        });
      });
    }, e.prototype.clear = function () {
      return U(this, void 0, void 0, function () {
        var e,
          t,
          n = this;
        return A(this, function (r) {
          switch (r.label) {
            case 0:
              return e = ts(this.dbname, this.collectionName), [4, this.store.getAllKeys()];
            case 1:
              return t = r.sent(), [4, Promise.all(t.filter(function (t) {
                return t.startsWith(e);
              }).map(function (e) {
                return U(n, void 0, void 0, function () {
                  return A(this, function (t) {
                    switch (t.label) {
                      case 0:
                        return [4, this.store.remove(e)];
                      case 1:
                        return [2, t.sent()];
                    }
                  });
                });
              }))];
            case 2:
              return r.sent(), [2];
          }
        });
      });
    }, e;
  }();
!function (e) {
  e[e.COMMIT = 0] = "COMMIT", e[e.WRITE = 1] = "WRITE", e[e.ERROR = 2] = "ERROR";
}(Gi || (Gi = {})), function (e) {
  e.PENDING = "pending", e.PERSISTENT = "persistent", e.VOLATILE = "volatile";
}(Vi || (Vi = {})), function (e) {
  e[e.NO_CACHE = 0] = "NO_CACHE", e[e.DEFAULT = 1] = "DEFAULT", e[e.PERSISTENT = 2] = "PERSISTENT";
}(Hi || (Hi = {}));
var rs,
  is,
  ss = [Vi.PENDING, Vi.VOLATILE],
  as = {},
  os = function () {
    function e(e) {
      var t = e.dbname,
        n = e.limit,
        r = void 0 === n ? 256 : n;
      return as[t] || (this.dbname = t, this._items = [], this._limit = r, as[t] = this), as[t];
    }
    return e.get = function (e) {
      return as[e];
    }, Object.defineProperty(e.prototype, "items", {
      get: function () {
        return this._items;
      },
      enumerable: !1,
      configurable: !0
    }), e.prototype.find = function (e, t, n) {
      return void 0 === n && (n = Hi.DEFAULT), U(this, void 0, void 0, function () {
        var r, i;
        return A(this, function (s) {
          switch (s.label) {
            case 0:
              return (r = this.get(t)) ? [3, 2] : [4, e.get(t)];
            case 1:
              return (i = s.sent()) && (r = {
                key: t,
                value: i,
                state: n === Hi.PERSISTENT ? Vi.PERSISTENT : Vi.VOLATILE
              }, this.put(r)), [3, 3];
            case 2:
              n === Hi.PERSISTENT && (r.state = Vi.PERSISTENT), s.label = 3;
            case 3:
              return [2, r];
          }
        });
      });
    }, e.prototype.get = function (e, t) {
      void 0 === t && (t = Hi.DEFAULT);
      var n = this._items.map(function (e) {
        return e.key;
      }).indexOf(e);
      if (n > -1) {
        var r = this._items[n];
        return t === Hi.PERSISTENT && (r.state = Vi.PERSISTENT), t !== Hi.NO_CACHE && this.put(r), r;
      }
      return null;
    }, e.prototype.put = function (e) {
      var t, n;
      if (this._limit > 0) {
        var r = this._items.map(function (e) {
          return e.key;
        }).indexOf(e.key);
        if (r > -1) ss.includes(this._items[r].state) && ss.includes(e.state) ? (this._items.splice(r, 1), this._items.push(e)) : (this._items[r].state = e.state, this._items[r].value = e.value);else {
          this._items.push(e);
          var i = this._items.filter(function (e) {
              return e.state === Vi.VOLATILE;
            }),
            s = i.length - this._limit;
          if (s > 0) {
            var a = [];
            try {
              for (var o = v(this._items), u = o.next(); !u.done; u = o.next()) {
                var c = u.value;
                c.state === Vi.VOLATILE && s > 0 ? s-- : a.push(c);
              }
            } catch (e) {
              t = {
                error: e
              };
            } finally {
              try {
                u && !u.done && (n = o.return) && n.call(o);
              } finally {
                if (t) throw t.error;
              }
            }
            this._items = a;
          }
        }
      }
    }, e.prototype.remove = function (e) {
      var t = this._items.map(function (e) {
        return e.key;
      }).indexOf(e);
      t > -1 && this._items.splice(t, 1);
    }, e.prototype.clearByCondition = function (e) {
      this._items = this._items.filter(function (t) {
        return !e(t);
      });
    }, e.prototype.clearForDatabase = function (e) {
      this.clearByCondition(function (t) {
        return t.key.startsWith(Xi(e));
      });
    }, e.prototype.clear = function (e) {
      void 0 === e && (e = !1), this._items = e ? [] : this._items.filter(function (e) {
        return e.state !== Vi.VOLATILE;
      });
    }, e;
  }(),
  us = function () {
    function e(e) {
      var t = e.dbname,
        n = e.collectionName,
        r = e.store;
      this._requests = [], this._onCommit = new Map(), this._onWrite = new Map(), this._onError = new Map(), this.dbname = t, this.collectionName = n, this.metadataKey = function (e, t) {
        return "".concat(Zi(e, t), "/trans.metadata");
      }(t, n), this.recordsetKey = function (e, t) {
        return "".concat(Zi(e, t), "/trans.recordset");
      }(t, n), this._store = r;
    }
    return Object.defineProperty(e.prototype, "generation", {
      get: function () {
        return this._metadata ? this._metadata.generation : 0;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(e.prototype, "requestCount", {
      get: function () {
        return this._requests.length;
      },
      enumerable: !1,
      configurable: !0
    }), e.prototype._getReducedRecordset = function (e) {
      return void 0 === e && (e = []), U(this, void 0, void 0, function () {
        var t;
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return [4, this._store.get(this.recordsetKey)];
            case 1:
              return (t = n.sent() || []).push.apply(t, p([], m(e), !1)), [2, this._reduceRecordSet(t)];
          }
        });
      });
    }, e.prototype._reduceRecordSet = function (e) {
      for (var t = [], n = {}, r = e.length - 1; r >= 0; r--) {
        for (var i = e[r], s = [], a = i.requests.length - 1; a >= 0; a--) {
          var o = i.requests[a],
            u = o.data;
          n[u.key] || (s.unshift(o), n[u.key] = !0);
        }
        s.length > 0 && (i.requests = s, t.unshift(i));
      }
      return t;
    }, e.prototype._applyRecord = function (e, t) {
      return U(this, void 0, void 0, function () {
        var n, r, i, s, a, o, c, l, d;
        return A(this, function (h) {
          switch (h.label) {
            case 0:
              n = os.get(this.dbname), r = t.generation, i = t.requests, s = null, h.label = 1;
            case 1:
              return h.trys.push([1, 3,, 4]), [4, this._store.setMany(i.map(function (e) {
                return u(u({}, e.data), {
                  generation: r
                });
              }))];
            case 2:
              for (a = h.sent(), o = 0; o < i.length; o++) a[o] instanceof Error && (s || (s = a[o]), c = i[o].data, n.put(u(u({}, c), {
                state: Vi.PERSISTENT
              })));
              return [3, 4];
            case 3:
              return l = h.sent(), s = l, [3, 4];
            case 4:
              return s ? [3, 6] : (d = e.filter(function (e) {
                return e.generation !== r;
              }), [4, this._store.set({
                key: this.recordsetKey,
                value: d
              })]);
            case 5:
              return h.sent(), this._onWrite.forEach(function (e) {
                e(i.map(function (e) {
                  return e.data;
                }));
              }), [3, 7];
            case 6:
              this._onError.forEach(function (e) {
                s && e(s);
              }), h.label = 7;
            case 7:
              return [2];
          }
        });
      });
    }, e.prototype.init = function () {
      return U(this, void 0, void 0, function () {
        var e, t, n, r, i, s, a, o;
        return A(this, function (u) {
          switch (u.label) {
            case 0:
              return e = this, [4, this._store.get(this.metadataKey)];
            case 1:
              return e._metadata = u.sent() || {
                generation: 1
              }, [4, this._getReducedRecordset()];
            case 2:
              t = u.sent(), u.label = 3;
            case 3:
              u.trys.push([3, 8, 9, 10]), n = v(t), r = n.next(), u.label = 4;
            case 4:
              return r.done ? [3, 7] : (i = r.value, [4, this._applyRecord(t, i)]);
            case 5:
              u.sent(), u.label = 6;
            case 6:
              return r = n.next(), [3, 4];
            case 7:
              return [3, 10];
            case 8:
              return s = u.sent(), a = {
                error: s
              }, [3, 10];
            case 9:
              try {
                r && !r.done && (o = n.return) && o.call(n);
              } finally {
                if (a) throw a.error;
              }
              return [7];
            case 10:
              return [2];
          }
        });
      });
    }, e.prototype.on = function (e, t, n) {
      switch (e) {
        case Gi.COMMIT:
          this._onCommit.set(t, n);
          break;
        case Gi.WRITE:
          this._onWrite.set(t, n);
          break;
        case Gi.ERROR:
          this._onError.set(t, n);
      }
    }, e.prototype.requestWrite = function (e, t) {
      this._requests.push({
        data: e,
        options: t
      }), os.get(this.dbname).put(u({
        state: Vi.PENDING
      }, e));
    }, e.prototype.requestMultipleWrite = function (e, t) {
      var n,
        r,
        i = os.get(this.dbname);
      try {
        for (var s = v(e), a = s.next(); !a.done; a = s.next()) {
          var o = a.value;
          this._requests.push({
            data: o,
            options: t
          }), i.put(u({
            state: Vi.PENDING
          }, o));
        }
      } catch (e) {
        n = {
          error: e
        };
      } finally {
        try {
          a && !a.done && (r = s.return) && r.call(s);
        } finally {
          if (n) throw n.error;
        }
      }
    }, e.prototype.clear = function () {
      return U(this, void 0, void 0, function () {
        return A(this, function (e) {
          return os.get(this.dbname).clearByCondition(function (e) {
            return e.state === Vi.PENDING;
          }), this._requests = [], [2];
        });
      });
    }, e.prototype.commit = function () {
      return U(this, void 0, void 0, function () {
        var e,
          t,
          n,
          r,
          i,
          s,
          a,
          o,
          c,
          l,
          d,
          h,
          f = this;
        return A(this, function (p) {
          switch (p.label) {
            case 0:
              if (!((e = this._requests).length > 0)) return [3, 4];
              for (t = [], n = {}, o = e.length - 1; o >= 0; o--) r = e[o], l = r.data, n[l.key] || (n[l.key] = !0, t.unshift(r));
              return i = {
                generation: this.generation,
                requests: t
              }, [4, this._getReducedRecordset([i])];
            case 1:
              return s = p.sent(), [4, this._store.set({
                key: this.recordsetKey,
                value: s
              })];
            case 2:
              return p.sent(), this._metadata.generation++, [4, this._store.set({
                key: this.metadataKey,
                value: this._metadata
              })];
            case 3:
              for (p.sent(), a = os.get(this.dbname), o = 0; o < t.length; o++) c = t[o], l = c.data, d = c.options, a.put(u(u({}, l), {
                state: d && d.persistent ? Vi.PERSISTENT : Vi.VOLATILE
              }));
              this._requests = [], this._onCommit.forEach(function (t) {
                t(e.map(function (e) {
                  return e.data;
                }));
              }), h = Pi.get(this.dbname), setTimeout(function () {
                try {
                  f._applyRecord(s, i);
                } catch (e) {
                  f._onError.forEach(function (t) {
                    return t(e);
                  });
                }
              }, h.transactionApplyDelay), p.label = 4;
            case 4:
              return [2];
          }
        });
      });
    }, e;
  }(),
  cs = function () {
    function e(e) {
      var t = e.blockId,
        n = e.keyName,
        r = e.items,
        i = void 0 === r ? [] : r,
        s = e.limit;
      this.blockId = t, this.keyName = n, this.limit = s, this._items = p([], m(i), !1);
    }
    return e.createFromCacheItem = function (t) {
      return t ? new e(t.value) : null;
    }, Object.defineProperty(e.prototype, "isEmpty", {
      get: function () {
        return 0 === this._items.length;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(e.prototype, "items", {
      get: function () {
        return this._items;
      },
      enumerable: !1,
      configurable: !0
    }), e.prototype.serialize = function () {
      return {
        blockId: this.blockId,
        keyName: this.keyName,
        limit: this.limit,
        items: this._items
      };
    }, e.prototype.getItemByKey = function (e) {
      var t = this,
        n = this._items.find(function (n) {
          var r = n[t.keyName];
          return e === r;
        });
      return null != n ? n : null;
    }, e.prototype.has = function (e) {
      var t = this;
      return this._items.map(function (e) {
        return e[t.keyName];
      }).includes(e);
    }, e.prototype.add = function (e) {
      var t = this,
        n = this._items.map(function (e) {
          return e[t.keyName];
        }).indexOf(e[this.keyName]);
      return n < 0 ? this._items.length < this.limit && (this._items.push(e), !0) : (this._items[n] = e, !0);
    }, e.prototype.remove = function (e) {
      for (var t = 0; t < this._items.length; t++) if (this._items[t][this.keyName] === e) return this._items.splice(t, 1), !0;
      return !1;
    }, e.prototype.clear = function () {
      this._items = [];
    }, e;
  }(),
  ls = function () {
    function e(e) {
      var t = e.dbname,
        n = e.collectionName,
        r = e.metadata,
        i = e.hashFunction,
        s = void 0 === i ? Fi : i,
        a = e.transaction,
        o = e.store;
      this.dbname = t, this.collectionName = n, this.hashFunction = s, this.metadata = r, this._transaction = a, this._store = o;
    }
    return Object.defineProperty(e.prototype, "keyName", {
      get: function () {
        return this.metadata.keyName;
      },
      enumerable: !1,
      configurable: !0
    }), e.prototype.createBlockId = function (e, t) {
      return void 0 === t && (t = this.metadata.blockLevel), n = this.dbname, r = this.collectionName, i = t, s = "".concat(function (e, t, n) {
        var r = n.base * Math.pow(n.multiplier, t) + n.constant;
        return (n.hashFunction || Fi)(e, r);
      }(e, t, {
        hashFunction: this.hashFunction,
        base: this.metadata.blockHashBase,
        multiplier: this.metadata.blockHashMultiplier,
        constant: this.metadata.blockHashConstant
      })), "".concat(es(n, r)).concat(i, ".").concat(s);
      var n, r, i, s;
    }, e.prototype._findBlock = function (e) {
      return U(this, void 0, void 0, function () {
        var t, n, r, i, s;
        return A(this, function (a) {
          switch (a.label) {
            case 0:
              t = os.get(this.dbname), n = this.metadata.blockLevel, a.label = 1;
            case 1:
              return n > 0 ? (r = this.createBlockId(e, n), [4, t.find(this._store, r)]) : [3, 4];
            case 2:
              if ((i = a.sent()) && (s = cs.createFromCacheItem(i), null == s ? void 0 : s.getItemByKey(e))) return [2, s];
              a.label = 3;
            case 3:
              return n--, [3, 1];
            case 4:
              return [2, null];
          }
        });
      });
    }, e.prototype.getFromBlock = function (e) {
      return U(this, void 0, void 0, function () {
        var t;
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return [4, this._findBlock(e)];
            case 1:
              return [2, (t = n.sent()) ? t.getItemByKey(e) : null];
          }
        });
      });
    }, e.prototype.putToBlock = function (e, t) {
      return U(this, void 0, void 0, function () {
        var n, r, i, s, a;
        return A(this, function (o) {
          switch (o.label) {
            case 0:
              return n = Pi.get(this.dbname), r = this.createBlockId(e), i = Math.floor(this._store.itemSizeLimit / n.itemSizeLimit), [4, os.get(this.dbname).find(this._store, r)];
            case 1:
              return s = o.sent(), (null == (a = s ? cs.createFromCacheItem(s) : new cs({
                blockId: r,
                keyName: this.keyName,
                items: [],
                limit: i
              })) ? void 0 : a.add(t)) ? (this._transaction.requestWrite({
                key: a.blockId,
                value: a.serialize()
              }), [2, !0]) : [2, !1];
          }
        });
      });
    }, e.prototype.removeFromBlock = function (e) {
      return U(this, void 0, void 0, function () {
        var t;
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return [4, this._findBlock(e)];
            case 1:
              return (t = n.sent()) && t.remove(e) ? (this._transaction.requestWrite({
                key: t.blockId,
                value: t.serialize()
              }), [2, !0]) : [2, !1];
          }
        });
      });
    }, e.prototype.clearAllBlocks = function () {
      return U(this, void 0, void 0, function () {
        var e, t, n;
        return A(this, function (r) {
          switch (r.label) {
            case 0:
              return e = es(this.dbname, this.collectionName), [4, this._store.getAllKeys()];
            case 1:
              return t = r.sent(), n = t.filter(function (t) {
                return t.startsWith(e);
              }), [4, this._store.removeMany(n)];
            case 2:
              return r.sent(), [4, this._transaction.clear()];
            case 3:
              return r.sent(), os.get(this.dbname).clearByCondition(function (t) {
                return t.key.startsWith(e);
              }), [2];
          }
        });
      });
    }, e;
  }(),
  ds = function () {
    function e(e) {
      var t = e.dbname,
        n = e.collectionName,
        r = e.keyName,
        i = e.fields,
        s = e.transaction,
        a = e.store,
        o = this;
      this._origin = [], this._table = [];
      var u = function (e, t, n) {
        return "".concat(Zi(e, t), "/index.").concat(n);
      }(t, n, i.join(">"));
      this.dbname = t, this.collectionName = n, this.keyName = r, this.fields = i, this.indexerKey = u, this._store = a, this._transaction = s, this._transaction.on(Gi.COMMIT, this.indexerKey, function () {
        return o.commit();
      }), this._transaction.on(Gi.ERROR, this.indexerKey, function () {
        return o.abort();
      });
    }
    return e.createKey = function (e) {
      return e.join(">");
    }, e.parseKey = function (e) {
      return e.split(">");
    }, e.prototype._addItem = function (e) {
      var t = e[this.keyName],
        n = this.getColumnValues(e),
        r = m(this.indexOf(n), 2),
        i = r[0];
      return r[1] ? !this._table[i].keys.includes(t) && (this._table[i].keys.push(t), !0) : (this._table.splice(i, 0, {
        columnValues: n,
        keys: [t]
      }), !0);
    }, e.prototype._removeItem = function (e) {
      var t = e[this.keyName],
        n = this.getColumnValues(e),
        r = m(this.indexOf(n), 2),
        i = r[0];
      if (r[1]) {
        var s = this._table[i].keys.indexOf(t);
        if (s > -1) return this._table[i].keys.splice(s, 1), 0 === this._table[i].keys.length && this._table.splice(i, 1), !0;
      }
      return !1;
    }, Object.defineProperty(e.prototype, "origin", {
      get: function () {
        return this._origin;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(e.prototype, "table", {
      get: function () {
        return this._table;
      },
      enumerable: !1,
      configurable: !0
    }), e.prototype.getColumnValues = function (e) {
      var t,
        n,
        r,
        i,
        s = [];
      try {
        for (var a = v(this.fields), o = a.next(); !o.done; o = a.next()) {
          var u = o.value;
          if ("-" === u[0] && (u = u.slice(1)), r = e[u], i = void 0, i = typeof r, null !== r && "undefined" !== i && "boolean" !== i && "number" !== i && "string" !== i) throw Ri.indexTypesNotMatch;
          s.push(e[u]);
        }
      } catch (e) {
        t = {
          error: e
        };
      } finally {
        try {
          o && !o.done && (n = a.return) && n.call(a);
        } finally {
          if (t) throw t.error;
        }
      }
      return s;
    }, e.prototype.diff = function (e, t) {
      for (var n in this.fields) {
        var r = "-" === this.fields[n][0] ? -1 : 1,
          i = Li(e[n], t[n]);
        if (0 !== i) return r * i;
      }
      return 0;
    }, e.prototype.indexOf = function (e) {
      if (this._table.length > 0) {
        for (var t = 0, n = this._table.length - 1; t <= n;) {
          var r = Math.floor((t + n) / 2),
            i = this.diff(e, this._table[r].columnValues);
          if (i > 0) t = r + 1;else {
            if (!(i < 0)) return [r, !0];
            n = r - 1;
          }
        }
        return [t, !1];
      }
      return [0, !1];
    }, e.prototype.ensure = function () {
      return U(this, void 0, void 0, function () {
        var e, t, n, r, i, s, a, o, u, c, l, d, h, f, p, m, y;
        return A(this, function (_) {
          switch (_.label) {
            case 0:
              return [4, (e = os.get(this.dbname)).find(this._store, this.indexerKey, Hi.PERSISTENT)];
            case 1:
              return (t = _.sent()) ? [3, 11] : (n = es(this.dbname, this.collectionName), [4, this._store.getAllKeys()]);
            case 2:
              r = _.sent(), _.label = 3;
            case 3:
              _.trys.push([3, 8, 9, 10]), i = v(r), s = i.next(), _.label = 4;
            case 4:
              return s.done ? [3, 7] : (a = s.value).startsWith(n) ? [4, e.find(this._store, a, Hi.NO_CACHE)] : [3, 6];
            case 5:
              if (o = _.sent(), u = cs.createFromCacheItem(o)) try {
                for (m = void 0, c = v(u.items), l = c.next(); !l.done; l = c.next()) d = l.value, this._addItem(d);
              } catch (e) {
                m = {
                  error: e
                };
              } finally {
                try {
                  l && !l.done && (y = c.return) && y.call(c);
                } finally {
                  if (m) throw m.error;
                }
              }
              _.label = 6;
            case 6:
              return s = i.next(), [3, 4];
            case 7:
              return [3, 10];
            case 8:
              return h = _.sent(), f = {
                error: h
              }, [3, 10];
            case 9:
              try {
                s && !s.done && (p = i.return) && p.call(i);
              } finally {
                if (f) throw f.error;
              }
              return [7];
            case 10:
              return this._transaction.requestWrite({
                key: this.indexerKey,
                value: this._table
              }, {
                persistent: !0
              }), [3, 12];
            case 11:
              this._origin = t.value, this._table = Di(this._origin), _.label = 12;
            case 12:
              return [2];
          }
        });
      });
    }, e.prototype.drop = function () {
      return U(this, void 0, void 0, function () {
        return A(this, function (e) {
          switch (e.label) {
            case 0:
              return os.get(this.dbname).remove(this.indexerKey), [4, this._store.remove(this.indexerKey)];
            case 1:
              return e.sent(), [2];
          }
        });
      });
    }, e.prototype.addItem = function (e) {
      return U(this, void 0, void 0, function () {
        return A(this, function (t) {
          return this._addItem(e) && this._transaction.requestWrite({
            key: this.indexerKey,
            value: this._table
          }, {
            persistent: !0
          }), [2];
        });
      });
    }, e.prototype.removeItem = function (e) {
      return U(this, void 0, void 0, function () {
        return A(this, function (t) {
          return this._removeItem(e) && this._transaction.requestWrite({
            key: this.indexerKey,
            value: this._table
          }, {
            persistent: !0
          }), [2];
        });
      });
    }, e.prototype.clear = function () {
      return U(this, void 0, void 0, function () {
        return A(this, function (e) {
          return this._table = [], this._transaction.requestWrite({
            key: this.indexerKey,
            value: this._table
          }, {
            persistent: !0
          }), [2];
        });
      });
    }, e.prototype.commit = function () {
      this._origin = this._table, this._table = Di(this._origin);
    }, e.prototype.abort = function () {
      this._table = Di(this._origin);
    }, e;
  }(),
  hs = function () {
    return "undefined" != typeof document && "undefined" != typeof navigator && "ReactNative" !== navigator.product;
  },
  fs = function () {
    var e = new Date().getTime();
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (t) {
      var n = (e + 16 * Math.random()) % 16 | 0;
      return e = Math.floor(e / 16), ("x" === t ? n : 3 & n | 8).toString(16);
    });
  };
!function (e) {
  e[e.PROCESSING = 0] = "PROCESSING", e[e.DONE = 1] = "DONE";
}(rs || (rs = {})), function (e) {
  e.NEWNODE = "newnode", e.REMOVENODE = "removenode", e.CLAIM_HOST = "claimhost", e.SYNC_HOST = "synchost", e.REQUEST_LOCK = "requestlock", e.ACQUIRE_LOCK = "acquirelock", e.RELEASE_LOCK = "releaselock";
}(is || (is = {}));
var ps,
  ms = {},
  vs = function () {
    function e(e, t) {
      void 0 === t && (t = {});
      var n = this;
      return this._state = rs.PROCESSING, this._queue = [], this._activationQueue = [], ms[e] && !t.forceCreate || (this.nodeId = fs(), this.key = e, hs() && (t.startAsInvisible ? this.registerNode() : "visible" === document.visibilityState ? this.claimHost() : this.registerNode(), document.addEventListener("visibilitychange", function () {
        "visible" === document.visibilityState && n.claimHost();
      }), window.addEventListener("message", function (e) {
        var t,
          r,
          i = e.data;
        if (null == i ? void 0 : i.isNestDbMessage) {
          var s = i.nodeId,
            a = i.requestId,
            o = i.key,
            u = i.op,
            c = i.data;
          if (s !== n.nodeId && o === n.key) switch (u) {
            case is.NEWNODE:
              n._sendSync();
              break;
            case is.CLAIM_HOST:
              n._sendSync(), n._hostId = s;
              break;
            case is.SYNC_HOST:
              if (!n.isInSync) {
                n._activationTimeout && clearTimeout(n._activationTimeout);
                var l = c,
                  d = l.currentItemRequestId,
                  h = l.queue,
                  f = function (e) {
                    var t = n._queue.findIndex(function (t) {
                      return t.requestId === e.requestId;
                    });
                    t < 0 && n._requestLock({
                      isNestDbMessage: !0,
                      nodeId: e.nodeId,
                      requestId: e.requestId,
                      key: n.key,
                      op: is.REQUEST_LOCK,
                      ts: e.ts
                    });
                  };
                try {
                  for (var p = v(h), m = p.next(); !m.done; m = p.next()) {
                    f(m.value);
                  }
                } catch (e) {
                  t = {
                    error: e
                  };
                } finally {
                  try {
                    m && !m.done && (r = p.return) && r.call(p);
                  } finally {
                    if (t) throw t.error;
                  }
                }
                n._currentItem = n._queue.find(function (e) {
                  return e.requestId === d;
                }), n._completeSync();
              }
              break;
            case is.REMOVENODE:
              n._queue = n._queue.filter(function (e) {
                return e.nodeId !== i.nodeId;
              }), n._currentItem && n._currentItem.nodeId === i.nodeId && (n._currentItem = void 0, n._acquire(n._queue[0]));
              break;
            case is.REQUEST_LOCK:
              n._requestLock(i);
              break;
            case is.ACQUIRE_LOCK:
              var y = n._queue.find(function (e) {
                return e.requestId === a;
              });
              n._acquire(y);
              break;
            case is.RELEASE_LOCK:
              n._release(a);
          }
        }
      }), window.addEventListener("beforeunload", function () {
        n._send(is.REMOVENODE);
      })), ms[e] = this), ms[e];
    }
    return Object.defineProperty(e.prototype, "locked", {
      get: function () {
        return !!this._currentItem;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(e.prototype, "isHost", {
      get: function () {
        return this._hostId === this.nodeId;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(e.prototype, "isInSync", {
      get: function () {
        return this._state == rs.DONE;
      },
      enumerable: !1,
      configurable: !0
    }), e.prototype._send = function (e, t) {
      var n;
      void 0 === t && (t = {});
      var r = {
        isNestDbMessage: !0,
        nodeId: this.nodeId,
        requestId: null !== (n = null == t ? void 0 : t.requestId) && void 0 !== n ? n : fs(),
        key: this.key,
        op: e,
        data: t.data,
        ts: Date.now()
      };
      return hs() && window.postMessage(r, "*"), r;
    }, e.prototype._acquire = function (e) {
      e ? (this._currentItem = e, this._currentItem.onAcquired && this._currentItem.onAcquired(e.requestId)) : this._currentItem = void 0;
    }, e.prototype._release = function (e) {
      if (this._currentItem && this._currentItem.requestId === e) {
        var t = this._currentItem;
        this._currentItem = void 0, t.nodeId === this.nodeId && this._send(is.RELEASE_LOCK, {
          requestId: t.requestId
        });
        var n = this._queue.findIndex(function (t) {
          return t.requestId === e;
        });
        n > -1 && this._queue.splice(n, 1), t.onReleased && t.onReleased(e);
      }
    }, e.prototype._requestLock = function (e) {
      var t = this;
      return new Promise(function (n) {
        for (var r = {
            nodeId: e.nodeId,
            requestId: e.requestId,
            ts: e.ts,
            onAcquired: function (e) {
              t.isHost && t._send(is.ACQUIRE_LOCK, {
                requestId: e
              }), n();
            },
            onReleased: function () {
              t._acquire(t._queue[0]);
            }
          }, i = !1, s = 0; s < t._queue.length; s++) if (t._queue[s].ts > r.ts) {
          t._queue.splice(s, 0, r), i = !0;
          break;
        }
        i || t._queue.push(r), t._currentItem || t._acquire(t._queue[0]);
      });
    }, e.prototype._sendSync = function () {
      var e;
      this.isHost && this._send(is.SYNC_HOST, {
        data: {
          currentItemRequestId: null === (e = this._currentItem) || void 0 === e ? void 0 : e.requestId,
          queue: this._queue.map(function (e) {
            return {
              nodeId: e.nodeId,
              requestId: e.requestId,
              ts: e.ts
            };
          })
        }
      });
    }, e.prototype._waitUntilSyncCompleted = function () {
      return U(this, void 0, void 0, function () {
        var e = this;
        return A(this, function (t) {
          return this.isHost && !this.isInSync ? [2, new Promise(function (t) {
            e._activationQueue.push(t);
          })] : [2];
        });
      });
    }, e.prototype._waitSync = function () {
      var e = this;
      this.isInSync || (this._activationTimeout = setTimeout(function () {
        e._completeSync();
      }, 8));
    }, e.prototype._completeSync = function () {
      this.isInSync || (this._state = rs.DONE, this._activationQueue.forEach(function (e) {
        return e();
      }), this._activationQueue = []);
    }, e.prototype.registerNode = function () {
      this._send(is.NEWNODE), this._waitSync();
    }, e.prototype.claimHost = function () {
      this._hostId = this.nodeId, this._send(is.CLAIM_HOST), this._waitSync();
    }, e.prototype.lock = function () {
      return U(this, void 0, void 0, function () {
        var e;
        return A(this, function (t) {
          switch (t.label) {
            case 0:
              return [4, this._waitUntilSyncCompleted()];
            case 1:
              return t.sent(), e = this._send(is.REQUEST_LOCK), [4, this._requestLock(e)];
            case 2:
              return t.sent(), [2];
          }
        });
      });
    }, e.prototype.unlock = function () {
      var e;
      (null === (e = this._currentItem) || void 0 === e ? void 0 : e.requestId) && this._release(this._currentItem.requestId);
    }, e;
  }(),
  ys = function () {
    function e(e) {
      var t = e.dbname,
        n = e.collectionName,
        r = e.keyName,
        i = e.keyHash,
        s = e.indexes,
        a = e.store,
        o = this;
      this._state = ki.INIT, this._indexers = [], this.dbname = t, this.name = n, this.keyName = r, this.indexes = p([[r]], m(s.filter(function (e) {
        return ds.createKey(e) !== o.keyName;
      })), !1), this._keyHash = i, this._store = a, this._mutex = new vs(function (e, t) {
        return "".concat(Zi(e, t), ".lock");
      }(t, n)), this._blobContainer = new ns({
        dbname: t,
        collectionName: n,
        store: a
      }), this._transaction = new us({
        dbname: t,
        collectionName: n,
        store: a
      });
    }
    return e.metadataOf = function (e, t, n) {
      return U(this, void 0, void 0, function () {
        var r;
        return A(this, function (i) {
          switch (i.label) {
            case 0:
              return r = $i(e, t), [4, n.get(r)];
            case 1:
              return [2, i.sent()];
          }
        });
      });
    }, Object.defineProperty(e.prototype, "state", {
      get: function () {
        return this._state;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(e.prototype, "isReady", {
      get: function () {
        return this._state === ki.READY;
      },
      enumerable: !1,
      configurable: !0
    }), e.prototype.init = function () {
      return U(this, void 0, void 0, function () {
        var t,
          n,
          r,
          i,
          s,
          a,
          o,
          u,
          c,
          l,
          d,
          h,
          f,
          y,
          _ = this;
        return A(this, function (g) {
          switch (g.label) {
            case 0:
              return [4, this._mutex.lock()];
            case 1:
              g.sent(), g.label = 2;
            case 2:
              return g.trys.push([2, 9,, 10]), t = Pi.get(this.dbname), [4, e.metadataOf(this.dbname, this.name, this._store)];
            case 3:
              return n = g.sent(), this._metadata = n || {
                keyName: this.keyName,
                blockLevel: 1,
                blockHashBase: t.blockHashBase,
                blockHashMultiplier: t.blockHashMultiplier,
                blockHashConstant: t.blockHashConstant,
                indexes: this.indexes
              }, [4, this._transaction.init()];
            case 4:
              g.sent(), this._blockManager = new ls({
                dbname: this.dbname,
                collectionName: this.name,
                hashFunction: this._keyHash,
                metadata: this._metadata,
                transaction: this._transaction,
                store: this._store
              }), r = p([], m(this.indexes), !1), i = [], s = r.map(function (e) {
                return ds.createKey(e);
              }), a = n ? n.indexes.map(function (e) {
                return ds.createKey(e);
              }) : [];
              try {
                for (o = v(a), u = o.next(); !u.done; u = o.next()) c = u.value, s.includes(c) || i.push(ds.parseKey(c));
              } catch (e) {
                f = {
                  error: e
                };
              } finally {
                try {
                  u && !u.done && (y = o.return) && y.call(o);
                } finally {
                  if (f) throw f.error;
                }
              }
              return (l = []).push.apply(l, p([], m(r.map(function (e) {
                var t = new ds({
                  dbname: _.dbname,
                  collectionName: _.name,
                  keyName: _.keyName,
                  fields: e,
                  transaction: _._transaction,
                  store: _._store
                });
                return _._indexers.push(t), t.ensure();
              })), !1)), l.push.apply(l, p([], m(i.map(function (e) {
                return new ds({
                  dbname: _.dbname,
                  collectionName: _.name,
                  keyName: _.keyName,
                  fields: e,
                  transaction: _._transaction,
                  store: _._store
                }).drop();
              })), !1)), [4, Promise.all(l)];
            case 5:
              return g.sent(), [4, this._transaction.commit()];
            case 6:
              return g.sent(), s.sort().join(",") === a.sort().join(",") ? [3, 8] : (d = $i(this.dbname, this.name), this._metadata.indexes = r, [4, this._store.set({
                key: d,
                value: this._metadata
              })]);
            case 7:
              g.sent(), g.label = 8;
            case 8:
              return this._state = ki.READY, this._mutex.unlock(), [3, 10];
            case 9:
              throw h = g.sent(), this._mutex.unlock(), h;
            case 10:
              return [2];
          }
        });
      });
    }, e.prototype.close = function () {
      this._state = ki.CLOSED;
    }, e.prototype._hasPropertyOfKeyName = function (e) {
      var t = e[this.keyName];
      return "string" == typeof t && !!t;
    }, e.prototype._getIndexerBy = function (e) {
      var t, n;
      void 0 === e && (e = null), e || (e = [this.keyName]);
      var r = ds.createKey(e);
      try {
        for (var i = v(this._indexers), s = i.next(); !s.done; s = i.next()) {
          var a = s.value;
          if (r === ds.createKey(a.fields)) return a;
        }
      } catch (e) {
        t = {
          error: e
        };
      } finally {
        try {
          s && !s.done && (n = i.return) && n.call(i);
        } finally {
          if (t) throw t.error;
        }
      }
      throw Ri.indexTableIsRequired;
    }, e.prototype._upgradeBlockLevel = function () {
      return U(this, void 0, void 0, function () {
        var e;
        return A(this, function (t) {
          switch (t.label) {
            case 0:
              return e = $i(this.dbname, this.name), this._metadata.blockLevel++, [4, this._store.set({
                key: e,
                value: this._metadata
              })];
            case 1:
              return t.sent(), [2];
          }
        });
      });
    }, e.prototype._requestInsert = function (e) {
      return U(this, void 0, void 0, function () {
        var t, n, r, i, s, a;
        return A(this, function (o) {
          switch (o.label) {
            case 0:
              return t = e[this.keyName], [4, this._blockManager.getFromBlock(t)];
            case 1:
              return o.sent() ? [3, 13] : [4, this._blockManager.putToBlock(t, e)];
            case 2:
              return o.sent() ? [3, 5] : [4, this._upgradeBlockLevel()];
            case 3:
              return o.sent(), [4, this._blockManager.putToBlock(t, e)];
            case 4:
              o.sent(), o.label = 5;
            case 5:
              o.trys.push([5, 10, 11, 12]), n = v(this._indexers), r = n.next(), o.label = 6;
            case 6:
              return r.done ? [3, 9] : [4, r.value.addItem(e)];
            case 7:
              o.sent(), o.label = 8;
            case 8:
              return r = n.next(), [3, 6];
            case 9:
              return [3, 12];
            case 10:
              return i = o.sent(), s = {
                error: i
              }, [3, 12];
            case 11:
              try {
                r && !r.done && (a = n.return) && a.call(n);
              } finally {
                if (s) throw s.error;
              }
              return [7];
            case 12:
              return [3, 14];
            case 13:
              throw Ri.collectionInsertDuplicate;
            case 14:
              return [2];
          }
        });
      });
    }, e.prototype._requestUpsert = function (e) {
      return U(this, void 0, void 0, function () {
        var t, n, r, i, s, a, o, u, c, l, d, h, f;
        return A(this, function (p) {
          switch (p.label) {
            case 0:
              return t = e[this.keyName], [4, this._blockManager.getFromBlock(t)];
            case 1:
              return (n = p.sent()) ? [3, 13] : [4, this._blockManager.putToBlock(t, e)];
            case 2:
              return p.sent() ? [3, 5] : [4, this._upgradeBlockLevel()];
            case 3:
              return p.sent(), [4, this._blockManager.putToBlock(t, e)];
            case 4:
              p.sent(), p.label = 5;
            case 5:
              p.trys.push([5, 10, 11, 12]), r = v(this._indexers), i = r.next(), p.label = 6;
            case 6:
              return i.done ? [3, 9] : [4, (u = i.value).addItem(e)];
            case 7:
              p.sent(), p.label = 8;
            case 8:
              return i = r.next(), [3, 6];
            case 9:
              return [3, 12];
            case 10:
              return s = p.sent(), l = {
                error: s
              }, [3, 12];
            case 11:
              try {
                i && !i.done && (d = r.return) && d.call(r);
              } finally {
                if (l) throw l.error;
              }
              return [7];
            case 12:
              return [3, 23];
            case 13:
              return [4, this._blockManager.putToBlock(t, e)];
            case 14:
              p.sent(), p.label = 15;
            case 15:
              p.trys.push([15, 21, 22, 23]), a = v(this._indexers), o = a.next(), p.label = 16;
            case 16:
              return o.done ? [3, 20] : 0 === (u = o.value).diff(u.getColumnValues(n), u.getColumnValues(e)) ? [3, 19] : [4, u.removeItem(n)];
            case 17:
              return p.sent(), [4, u.addItem(e)];
            case 18:
              p.sent(), p.label = 19;
            case 19:
              return o = a.next(), [3, 16];
            case 20:
              return [3, 23];
            case 21:
              return c = p.sent(), h = {
                error: c
              }, [3, 23];
            case 22:
              try {
                o && !o.done && (f = a.return) && f.call(a);
              } finally {
                if (h) throw h.error;
              }
              return [7];
            case 23:
              return [2];
          }
        });
      });
    }, e.prototype._requestUpdate = function (e) {
      return U(this, void 0, void 0, function () {
        var t, n, r, i, s, a, o, u;
        return A(this, function (c) {
          switch (c.label) {
            case 0:
              return t = e[this.keyName], [4, this._blockManager.getFromBlock(t)];
            case 1:
              return (n = c.sent()) ? [4, this._blockManager.putToBlock(t, e)] : [3, 11];
            case 2:
              c.sent(), c.label = 3;
            case 3:
              c.trys.push([3, 9, 10, 11]), r = v(this._indexers), i = r.next(), c.label = 4;
            case 4:
              return i.done ? [3, 8] : 0 === (s = i.value).diff(s.getColumnValues(n), s.getColumnValues(e)) ? [3, 7] : [4, s.removeItem(n)];
            case 5:
              return c.sent(), [4, s.addItem(e)];
            case 6:
              c.sent(), c.label = 7;
            case 7:
              return i = r.next(), [3, 4];
            case 8:
              return [3, 11];
            case 9:
              return a = c.sent(), o = {
                error: a
              }, [3, 11];
            case 10:
              try {
                i && !i.done && (u = r.return) && u.call(r);
              } finally {
                if (o) throw o.error;
              }
              return [7];
            case 11:
              return [2];
          }
        });
      });
    }, e.prototype._requestRemove = function (e) {
      return U(this, void 0, void 0, function () {
        var t, n, r, i, s, a;
        return A(this, function (o) {
          switch (o.label) {
            case 0:
              return [4, this._blockManager.getFromBlock(e)];
            case 1:
              return (t = o.sent()) ? [4, this._blockManager.removeFromBlock(e)] : [3, 10];
            case 2:
              o.sent(), o.label = 3;
            case 3:
              o.trys.push([3, 8, 9, 10]), n = v(this._indexers), r = n.next(), o.label = 4;
            case 4:
              return r.done ? [3, 7] : [4, r.value.removeItem(t)];
            case 5:
              o.sent(), o.label = 6;
            case 6:
              return r = n.next(), [3, 4];
            case 7:
              return [3, 10];
            case 8:
              return i = o.sent(), s = {
                error: i
              }, [3, 10];
            case 9:
              try {
                r && !r.done && (a = n.return) && a.call(n);
              } finally {
                if (s) throw s.error;
              }
              return [7];
            case 10:
              return [2];
          }
        });
      });
    }, e.prototype._requestClear = function () {
      return U(this, void 0, void 0, function () {
        var e, t, n, r, i;
        return A(this, function (s) {
          switch (s.label) {
            case 0:
              return [4, this._blockManager.clearAllBlocks()];
            case 1:
              s.sent(), s.label = 2;
            case 2:
              s.trys.push([2, 7, 8, 9]), e = v(this._indexers), t = e.next(), s.label = 3;
            case 3:
              return t.done ? [3, 6] : [4, t.value.clear()];
            case 4:
              s.sent(), s.label = 5;
            case 5:
              return t = e.next(), [3, 3];
            case 6:
              return [3, 9];
            case 7:
              return n = s.sent(), r = {
                error: n
              }, [3, 9];
            case 8:
              try {
                t && !t.done && (i = e.return) && i.call(e);
              } finally {
                if (r) throw r.error;
              }
              return [7];
            case 9:
              return [2];
          }
        });
      });
    }, e.prototype.getByKey = function (e) {
      return U(this, void 0, void 0, function () {
        var t, n;
        return A(this, function (r) {
          switch (r.label) {
            case 0:
              return this.isReady ? [4, this._mutex.lock()] : [3, 6];
            case 1:
              r.sent(), r.label = 2;
            case 2:
              return r.trys.push([2, 4,, 5]), [4, this._blockManager.getFromBlock(e)];
            case 3:
              return t = r.sent(), this._mutex.unlock(), [2, Di(t)];
            case 4:
              throw n = r.sent(), this._mutex.unlock(), n;
            case 5:
              return [3, 7];
            case 6:
              throw Ri.collectionNotReady;
            case 7:
              return [2];
          }
        });
      });
    }, e.prototype.query = function (e) {
      if (void 0 === e && (e = {}), this.isReady) return new Ji({
        condition: e.where,
        mutex: this._mutex,
        blockManager: this._blockManager,
        indexer: this._getIndexerBy(e.index),
        backward: !!e.backward
      });
      throw Ri.collectionNotReady;
    }, e.prototype.insertOne = function (e) {
      return U(this, void 0, void 0, function () {
        var t;
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return this.isReady ? [4, this._mutex.lock()] : [3, 8];
            case 1:
              n.sent(), n.label = 2;
            case 2:
              if (n.trys.push([2, 5,, 7]), !this._hasPropertyOfKeyName(e)) throw Ri.collectionKeyNotGiven;
              return [4, this._requestInsert(Di(e))];
            case 3:
              return n.sent(), [4, this._transaction.commit()];
            case 4:
              return n.sent(), this._mutex.unlock(), [2, e];
            case 5:
              return t = n.sent(), [4, this._transaction.clear()];
            case 6:
              throw n.sent(), this._mutex.unlock(), t;
            case 7:
              return [3, 9];
            case 8:
              throw Ri.collectionNotReady;
            case 9:
              return [2];
          }
        });
      });
    }, e.prototype.insertMany = function (e) {
      return U(this, void 0, void 0, function () {
        var t,
          n,
          r,
          i,
          s,
          a,
          o,
          u = this;
        return A(this, function (c) {
          switch (c.label) {
            case 0:
              return this.isReady ? [4, this._mutex.lock()] : [3, 15];
            case 1:
              c.sent(), c.label = 2;
            case 2:
              if (c.trys.push([2, 12,, 14]), e.some(function (e) {
                return !u._hasPropertyOfKeyName(e);
              })) throw Ri.collectionKeyNotGiven;
              c.label = 3;
            case 3:
              c.trys.push([3, 8, 9, 10]), t = v(e), n = t.next(), c.label = 4;
            case 4:
              return n.done ? [3, 7] : (r = n.value, [4, this._requestInsert(Di(r))]);
            case 5:
              c.sent(), c.label = 6;
            case 6:
              return n = t.next(), [3, 4];
            case 7:
              return [3, 10];
            case 8:
              return i = c.sent(), a = {
                error: i
              }, [3, 10];
            case 9:
              try {
                n && !n.done && (o = t.return) && o.call(t);
              } finally {
                if (a) throw a.error;
              }
              return [7];
            case 10:
              return [4, this._transaction.commit()];
            case 11:
              return c.sent(), this._mutex.unlock(), [2, e];
            case 12:
              return s = c.sent(), [4, this._transaction.clear()];
            case 13:
              throw c.sent(), this._mutex.unlock(), s;
            case 14:
              return [3, 16];
            case 15:
              throw Ri.collectionNotReady;
            case 16:
              return [2];
          }
        });
      });
    }, e.prototype.upsertOne = function (e) {
      return U(this, void 0, void 0, function () {
        var t;
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return this.isReady ? [4, this._mutex.lock()] : [3, 8];
            case 1:
              n.sent(), n.label = 2;
            case 2:
              if (n.trys.push([2, 5,, 7]), !this._hasPropertyOfKeyName(e)) throw Ri.collectionKeyNotGiven;
              return [4, this._requestUpsert(Di(e))];
            case 3:
              return n.sent(), [4, this._transaction.commit()];
            case 4:
              return n.sent(), this._mutex.unlock(), [2, e];
            case 5:
              return t = n.sent(), [4, this._transaction.clear()];
            case 6:
              throw n.sent(), this._mutex.unlock(), t;
            case 7:
              return [3, 9];
            case 8:
              throw Ri.collectionNotReady;
            case 9:
              return [2];
          }
        });
      });
    }, e.prototype.upsertMany = function (e) {
      return U(this, void 0, void 0, function () {
        var t,
          n,
          r,
          i,
          s,
          a,
          o,
          u = this;
        return A(this, function (c) {
          switch (c.label) {
            case 0:
              return this.isReady ? [4, this._mutex.lock()] : [3, 15];
            case 1:
              c.sent(), c.label = 2;
            case 2:
              if (c.trys.push([2, 12,, 14]), e.some(function (e) {
                return !u._hasPropertyOfKeyName(e);
              })) throw Ri.collectionKeyNotGiven;
              c.label = 3;
            case 3:
              c.trys.push([3, 8, 9, 10]), t = v(e), n = t.next(), c.label = 4;
            case 4:
              return n.done ? [3, 7] : (r = n.value, [4, this._requestUpsert(Di(r))]);
            case 5:
              c.sent(), c.label = 6;
            case 6:
              return n = t.next(), [3, 4];
            case 7:
              return [3, 10];
            case 8:
              return i = c.sent(), a = {
                error: i
              }, [3, 10];
            case 9:
              try {
                n && !n.done && (o = t.return) && o.call(t);
              } finally {
                if (a) throw a.error;
              }
              return [7];
            case 10:
              return [4, this._transaction.commit()];
            case 11:
              return c.sent(), this._mutex.unlock(), [2, e];
            case 12:
              return s = c.sent(), [4, this._transaction.clear()];
            case 13:
              throw c.sent(), this._mutex.unlock(), s;
            case 14:
              return [3, 16];
            case 15:
              throw Ri.collectionNotReady;
            case 16:
              return [2];
          }
        });
      });
    }, e.prototype.update = function (e) {
      return U(this, void 0, void 0, function () {
        var t;
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return this.isReady ? [4, this._mutex.lock()] : [3, 8];
            case 1:
              n.sent(), n.label = 2;
            case 2:
              if (n.trys.push([2, 5,, 7]), !this._hasPropertyOfKeyName(e)) throw Ri.collectionKeyNotGiven;
              return [4, this._requestUpdate(Di(e))];
            case 3:
              return n.sent(), [4, this._transaction.commit()];
            case 4:
              return n.sent(), this._mutex.unlock(), [2, e];
            case 5:
              return t = n.sent(), [4, this._transaction.clear()];
            case 6:
              throw n.sent(), this._mutex.unlock(), t;
            case 7:
              return [3, 9];
            case 8:
              throw Ri.collectionNotReady;
            case 9:
              return [2];
          }
        });
      });
    }, e.prototype.updateIf = function (e, t) {
      return U(this, void 0, void 0, function () {
        var n,
          r,
          i,
          s,
          a,
          o,
          u,
          c,
          l,
          d,
          h,
          f,
          p,
          m,
          y = this;
        return A(this, function (_) {
          switch (_.label) {
            case 0:
              return this.isReady ? [4, this._mutex.lock()] : [3, 16];
            case 1:
              _.sent(), _.label = 2;
            case 2:
              return _.trys.push([2, 13,, 15]), n = e.where, r = void 0 === n ? {} : n, i = e.index, s = void 0 === i ? null : i, a = e.backward, o = void 0 !== a && a, u = [], [4, new Yi({
                condition: r,
                blockManager: this._blockManager,
                backward: o,
                indexer: this._getIndexerBy(s)
              }).each(function (e) {
                return U(y, void 0, void 0, function () {
                  var n;
                  return A(this, function (i) {
                    if (e.error) throw e.stop(), e.error;
                    return e.hasNext ? (n = e.nextValue, zi(r, n) && t.set && ("function" != typeof t.set ? Object.keys(t.set).forEach(function (e) {
                      var r;
                      n[e] = null === (r = t.set) || void 0 === r ? void 0 : r[e];
                    }) : t.set(n), u.push(n)), e.next()) : e.stop(), [2];
                  });
                });
              })];
            case 3:
              _.sent(), _.label = 4;
            case 4:
              _.trys.push([4, 9, 10, 11]), c = v(u), l = c.next(), _.label = 5;
            case 5:
              return l.done ? [3, 8] : (d = l.value, [4, this._requestUpdate(Di(d))]);
            case 6:
              _.sent(), _.label = 7;
            case 7:
              return l = c.next(), [3, 5];
            case 8:
              return [3, 11];
            case 9:
              return h = _.sent(), p = {
                error: h
              }, [3, 11];
            case 10:
              try {
                l && !l.done && (m = c.return) && m.call(c);
              } finally {
                if (p) throw p.error;
              }
              return [7];
            case 11:
              return [4, this._transaction.commit()];
            case 12:
              return _.sent(), this._mutex.unlock(), [2, u];
            case 13:
              return f = _.sent(), [4, this._transaction.clear()];
            case 14:
              throw _.sent(), this._mutex.unlock(), f;
            case 15:
              return [3, 17];
            case 16:
              throw this._transaction.clear(), Ri.collectionNotReady;
            case 17:
              return [2];
          }
        });
      });
    }, e.prototype.remove = function (e) {
      return U(this, void 0, void 0, function () {
        var t;
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return this.isReady ? [4, this._mutex.lock()] : [3, 8];
            case 1:
              n.sent(), n.label = 2;
            case 2:
              return n.trys.push([2, 5,, 7]), [4, this._requestRemove(e)];
            case 3:
              return n.sent(), [4, this._transaction.commit()];
            case 4:
              return n.sent(), this._mutex.unlock(), [3, 7];
            case 5:
              return t = n.sent(), [4, this._transaction.clear()];
            case 6:
              throw n.sent(), this._mutex.unlock(), t;
            case 7:
              return [3, 9];
            case 8:
              throw Ri.collectionNotReady;
            case 9:
              return [2];
          }
        });
      });
    }, e.prototype.removeIf = function (e) {
      return U(this, void 0, void 0, function () {
        var t,
          n,
          r,
          i,
          s,
          a,
          o,
          u,
          c,
          l,
          d,
          h,
          f,
          p,
          m = this;
        return A(this, function (y) {
          switch (y.label) {
            case 0:
              return this.isReady ? [4, this._mutex.lock()] : [3, 15];
            case 1:
              y.sent(), y.label = 2;
            case 2:
              return y.trys.push([2, 13,, 14]), t = e.where, n = void 0 === t ? {} : t, r = e.index, i = void 0 === r ? null : r, s = e.backward, a = void 0 !== s && s, o = [], [4, new Yi({
                condition: n,
                blockManager: this._blockManager,
                backward: a,
                indexer: this._getIndexerBy(i)
              }).each(function (e) {
                return U(m, void 0, void 0, function () {
                  var t, r;
                  return A(this, function (i) {
                    if (e.error) throw e.stop(), e.error;
                    return e.hasNext ? (t = e.nextValue, zi(n, t) && (r = t[this.keyName], o.push(r)), e.next()) : e.stop(), [2];
                  });
                });
              })];
            case 3:
              y.sent(), y.label = 4;
            case 4:
              y.trys.push([4, 9, 10, 11]), u = v(o), c = u.next(), y.label = 5;
            case 5:
              return c.done ? [3, 8] : (l = c.value, [4, this._requestRemove(l)]);
            case 6:
              y.sent(), y.label = 7;
            case 7:
              return c = u.next(), [3, 5];
            case 8:
              return [3, 11];
            case 9:
              return d = y.sent(), f = {
                error: d
              }, [3, 11];
            case 10:
              try {
                c && !c.done && (p = u.return) && p.call(u);
              } finally {
                if (f) throw f.error;
              }
              return [7];
            case 11:
              return [4, this._transaction.commit()];
            case 12:
              return y.sent(), this._mutex.unlock(), [2, o];
            case 13:
              throw h = y.sent(), this._mutex.unlock(), h;
            case 14:
              return [3, 16];
            case 15:
              throw this._transaction.clear(), Ri.collectionNotReady;
            case 16:
              return [2];
          }
        });
      });
    }, e.prototype.clear = function () {
      return U(this, void 0, void 0, function () {
        var e;
        return A(this, function (t) {
          switch (t.label) {
            case 0:
              return this.isReady ? [4, this._mutex.lock()] : [3, 8];
            case 1:
              t.sent(), t.label = 2;
            case 2:
              return t.trys.push([2, 5,, 7]), [4, this._requestClear()];
            case 3:
              return t.sent(), [4, this._transaction.commit()];
            case 4:
              return t.sent(), this._mutex.unlock(), [3, 7];
            case 5:
              return e = t.sent(), [4, this._transaction.clear()];
            case 6:
              throw t.sent(), this._mutex.unlock(), e;
            case 7:
              return [3, 9];
            case 8:
              throw Ri.collectionNotReady;
            case 9:
              return [2];
          }
        });
      });
    }, e.prototype.getBlob = function (e) {
      return U(this, void 0, void 0, function () {
        return A(this, function (t) {
          switch (t.label) {
            case 0:
              return [4, this._blobContainer.get(e)];
            case 1:
              return [2, t.sent()];
          }
        });
      });
    }, e.prototype.saveBlob = function (e, t) {
      return U(this, void 0, void 0, function () {
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return [4, this._blobContainer.save(e, t)];
            case 1:
              return [2, n.sent()];
          }
        });
      });
    }, e.prototype.removeBlob = function (e) {
      return U(this, void 0, void 0, function () {
        return A(this, function (t) {
          switch (t.label) {
            case 0:
              return [4, this._blobContainer.remove(e)];
            case 1:
              return t.sent(), [2];
          }
        });
      });
    }, e.prototype.removeAllBlobs = function () {
      return U(this, void 0, void 0, function () {
        return A(this, function (e) {
          switch (e.label) {
            case 0:
              return [4, this._blobContainer.clear()];
            case 1:
              return e.sent(), [2];
          }
        });
      });
    }, e;
  }(),
  _s = "[NESTDB]",
  gs = !0,
  bs = function () {
    function e() {}
    return e.off = function () {
      gs = !1;
    }, e.log = function () {
      for (var e = [], t = 0; t < arguments.length; t++) e[t] = arguments[t];
      gs && console.log.apply(console, p(["".concat(_s, "[LOG]")], m(e), !1));
    }, e.warning = function () {
      for (var e = [], t = 0; t < arguments.length; t++) e[t] = arguments[t];
      gs && console.warn.apply(console, p(["".concat(_s, "[WARNING]")], m(e), !1));
    }, e.error = function () {
      for (var e = [], t = 0; t < arguments.length; t++) e[t] = arguments[t];
      gs && console.error.apply(console, p(["".concat(_s, "[ERROR]")], m(e), !1));
    }, e;
  }(),
  Is = [{}, {
    a: 700400,
    n: "error"
  }],
  Es = function () {
    function e(e) {
      var t, n, r;
      this.encryption = null !== (t = e.encryption) && void 0 !== t ? t : ye, this.itemSizeLimit = null !== (n = e.itemSizeLimit) && void 0 !== n ? n : 4194304, this.metadataBuffer = null !== (r = e.metadataBuffer) && void 0 !== r ? r : 256;
    }
    return Object.defineProperty(e.prototype, "_encryptionCheckKey", {
      get: function () {
        return "".concat(this.dbname, ".encrypt");
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(e.prototype, "_reservedKeys", {
      get: function () {
        return [this._encryptionCheckKey];
      },
      enumerable: !1,
      configurable: !0
    }), e.prototype._getRawKey = function (e, t) {
      return void 0 === t && (t = ""), "".concat(e).concat(t);
    }, e.prototype._generateShardPostfixArray = function (e) {
      return void 0 === e && (e = 1), p([], m(Array(e).keys()), !1);
    }, e.prototype._shardify = function (e) {
      var t = this,
        n = e.key,
        r = e.value,
        i = JSON.stringify(this.encryption.encrypt(r)),
        s = Math.ceil(i.length / this.adjustedItemSizeLimit);
      return this._generateShardPostfixArray(s).map(function (e) {
        var r = {
          key: t._getRawKey(n, ".".concat(e)),
          data: i.substring(e * t.adjustedItemSizeLimit, (e + 1) * t.adjustedItemSizeLimit)
        };
        return 0 === e && (r.metadata = {
          shards: s
        }), r;
      });
    }, e.prototype._resetIfEncryptionChanged = function () {
      return U(this, void 0, void 0, function () {
        var e,
          t,
          n,
          r,
          i,
          s,
          a,
          o,
          u,
          c = this;
        return A(this, function (l) {
          switch (l.label) {
            case 0:
              return [4, this.get(this._encryptionCheckKey)];
            case 1:
              if (e = l.sent(), t = {
                encrypted: Is.map(function (e) {
                  var t;
                  return null === (t = c.encryption) || void 0 === t ? void 0 : t.encrypt(e);
                })
              }, !e) return [3, 8];
              if (!e.encrypted || !Array.isArray(e.encrypted)) return [3, 6];
              for (i in n = e.encrypted, r = [], n) r.push(i);
              s = 0, l.label = 2;
            case 2:
              return s < r.length ? (i = r[s]) in n ? (a = i, o = JSON.stringify(e.encrypted[a]), u = JSON.stringify(t.encrypted[a]), o === u ? [3, 4] : (bs.warning("Encryption algorithm has changed. Stored data would be cleared."), [4, this.clear()])) : [3, 4] : [3, 5];
            case 3:
              return l.sent(), [3, 5];
            case 4:
              return s++, [3, 2];
            case 5:
              return [3, 8];
            case 6:
              return [4, this.clear()];
            case 7:
              l.sent(), l.label = 8;
            case 8:
              return [4, this.set({
                key: this._encryptionCheckKey,
                value: t
              })];
            case 9:
              return l.sent(), [2];
          }
        });
      });
    }, Object.defineProperty(e.prototype, "adjustedItemSizeLimit", {
      get: function () {
        return Math.max(this.itemSizeLimit - this.metadataBuffer, 4);
      },
      enumerable: !1,
      configurable: !0
    }), e.prototype.usage = function () {
      return U(this, void 0, void 0, function () {
        var e, t, n, r, i, s, a, o, u;
        return A(this, function (c) {
          switch (c.label) {
            case 0:
              return e = 0, [4, this._getAllRawKeys()];
            case 1:
              t = c.sent(), c.label = 2;
            case 2:
              c.trys.push([2, 7, 8, 9]), n = v(t), r = n.next(), c.label = 3;
            case 3:
              return r.done ? [3, 6] : (i = r.value, [4, this._getRaw(i)]);
            case 4:
              (s = c.sent()) && (e += JSON.stringify(s).length), c.label = 5;
            case 5:
              return r = n.next(), [3, 3];
            case 6:
              return [3, 9];
            case 7:
              return a = c.sent(), o = {
                error: a
              }, [3, 9];
            case 8:
              try {
                r && !r.done && (u = n.return) && u.call(n);
              } finally {
                if (o) throw o.error;
              }
              return [7];
            case 9:
              return [2, e];
          }
        });
      });
    }, e.prototype.getAllKeys = function () {
      return U(this, void 0, void 0, function () {
        var e = this;
        return A(this, function (t) {
          switch (t.label) {
            case 0:
              return [4, this._getAllRawKeys()];
            case 1:
              return [2, t.sent().filter(function (e) {
                return e.endsWith(".0");
              }).map(function (e) {
                return e.replace(/\.0$/, "");
              }).filter(function (t) {
                return !e._reservedKeys.includes(t);
              })];
          }
        });
      });
    }, e.prototype.get = function (e) {
      return U(this, void 0, void 0, function () {
        var t,
          n,
          r,
          i,
          s,
          a,
          o = this;
        return A(this, function (u) {
          switch (u.label) {
            case 0:
              return t = this._getRawKey(e, ".0"), [4, this._getRaw(t)];
            case 1:
              if (!(n = u.sent())) return [3, 7];
              u.label = 2;
            case 2:
              return u.trys.push([2, 6,, 7]), r = n.data, (null == (i = n.metadata) ? void 0 : i.shards) && i.shards > 1 ? [4, Promise.all(this._generateShardPostfixArray(null == i ? void 0 : i.shards).map(function (t) {
                return U(o, void 0, void 0, function () {
                  var n, i;
                  return A(this, function (s) {
                    switch (s.label) {
                      case 0:
                        return t > 0 ? (n = this._getRawKey(e, ".".concat(t)), [4, this._getRaw(n)]) : [3, 2];
                      case 1:
                        if (!(i = s.sent())) throw Ri.storeBrokenIntegrity;
                        return [2, i.data];
                      case 2:
                        return [2, r];
                    }
                  });
                });
              }))] : [3, 4];
            case 3:
              return a = u.sent(), [3, 5];
            case 4:
              a = [r], u.label = 5;
            case 5:
              return s = a, [2, this.encryption.decrypt(JSON.parse(s.join("")))];
            case 6:
              return u.sent(), [2, null];
            case 7:
              return [2, null];
          }
        });
      });
    }, e.prototype.set = function (e) {
      return U(this, void 0, void 0, function () {
        var t;
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return t = this._shardify(e), [4, this._setRaw(t)];
            case 1:
              return n.sent(), [2, u({}, e.value)];
          }
        });
      });
    }, e.prototype.setMany = function (e) {
      return U(this, void 0, void 0, function () {
        var t,
          n = this;
        return A(this, function (r) {
          switch (r.label) {
            case 0:
              return t = [], [4, this._setRaw(t.concat.apply(t, p([], m(e.map(function (e) {
                return n._shardify(e);
              })), !1)))];
            case 1:
              return r.sent(), [2, e.map(function (e) {
                return e.value;
              })];
          }
        });
      });
    }, e.prototype.remove = function (e) {
      return U(this, void 0, void 0, function () {
        var t,
          n,
          r,
          i,
          s,
          a = this;
        return A(this, function (o) {
          switch (o.label) {
            case 0:
              return t = this._getRawKey(e, ".0"), [4, this._getRaw(t)];
            case 1:
              return (n = o.sent()) ? (r = n.metadata, i = this._generateShardPostfixArray(null == r ? void 0 : r.shards).map(function (t) {
                return a._getRawKey(e, ".".concat(t));
              }), [4, this._removeRaw(i)]) : [3, 3];
            case 2:
              return o.sent(), null == (s = os.get(this.dbname)) || s.remove(e), [2, !0];
            case 3:
              return [2, !1];
          }
        });
      });
    }, e.prototype.removeMany = function (e) {
      return U(this, void 0, void 0, function () {
        var t,
          n,
          r,
          i,
          s,
          a,
          o,
          u,
          c,
          l,
          d = this;
        return A(this, function (h) {
          switch (h.label) {
            case 0:
              t = os.get(this.dbname), n = [], r = function (e) {
                var r, s, a;
                return A(this, function (o) {
                  switch (o.label) {
                    case 0:
                      return r = i._getRawKey(e, ".0"), [4, i._getRaw(r)];
                    case 1:
                      return (s = o.sent()) && (a = s.metadata, n.push.apply(n, p([], m(i._generateShardPostfixArray(null == a ? void 0 : a.shards).map(function (t) {
                        return d._getRawKey(e, ".".concat(t));
                      })), !1))), null == t || t.remove(e), [2];
                  }
                });
              }, i = this, h.label = 1;
            case 1:
              h.trys.push([1, 6, 7, 8]), s = v(e), a = s.next(), h.label = 2;
            case 2:
              return a.done ? [3, 5] : (o = a.value, [5, r(o)]);
            case 3:
              h.sent(), h.label = 4;
            case 4:
              return a = s.next(), [3, 2];
            case 5:
              return [3, 8];
            case 6:
              return u = h.sent(), c = {
                error: u
              }, [3, 8];
            case 7:
              try {
                a && !a.done && (l = s.return) && l.call(s);
              } finally {
                if (c) throw c.error;
              }
              return [7];
            case 8:
              return n.length > 0 ? [4, this._removeRaw(n)] : [3, 10];
            case 9:
              h.sent(), h.label = 10;
            case 10:
              return [2, e];
          }
        });
      });
    }, e.prototype.clear = function () {
      return U(this, void 0, void 0, function () {
        var e;
        return A(this, function (t) {
          return null == (e = os.get(this.dbname)) || e.clearForDatabase(this.dbname), [2];
        });
      });
    }, e;
  }(),
  Ts = {},
  ws = function (e) {
    function t(t) {
      void 0 === t && (t = {});
      var n,
        r = this;
      r = e.call(this, u(u({}, t), {
        itemSizeLimit: null !== (n = t.itemSizeLimit) && void 0 !== n ? n : 4194304
      })) || this;
      var i = t.delay,
        s = void 0 === i ? 1 : i;
      return r.delay = s, r.observer = {}, r;
    }
    return r(t, e), Object.defineProperty(t.prototype, "rawData", {
      get: function () {
        return Ts[this.dbname];
      },
      set: function (e) {
        Ts[this.dbname] = e;
      },
      enumerable: !1,
      configurable: !0
    }), t.prototype._getAllRawKeys = function () {
      return U(this, void 0, void 0, function () {
        return A(this, function (e) {
          if (Ts[this.dbname]) return [2, Object.keys(Ts[this.dbname])];
          throw Ri.storeNotAvailable;
        });
      });
    }, t.prototype._getRaw = function (e) {
      return U(this, void 0, void 0, function () {
        return A(this, function (t) {
          switch (t.label) {
            case 0:
              return Ts[this.dbname] ? [4, qi(this.delay)] : [3, 2];
            case 1:
              return t.sent(), [2, Ts[this.dbname][e] ? u({
                key: e
              }, Ts[this.dbname][e]) : null];
            case 2:
              throw Ri.storeNotAvailable;
          }
        });
      });
    }, t.prototype._setRaw = function (e) {
      return U(this, void 0, void 0, function () {
        var t, n, r, i, s, a, o, u;
        return A(this, function (c) {
          switch (c.label) {
            case 0:
              return Ts[this.dbname] ? [4, qi(this.delay)] : [3, 2];
            case 1:
              c.sent();
              try {
                for (t = v(e), n = t.next(); !n.done; n = t.next()) r = n.value, i = r.key, s = r.data, a = r.metadata, Ts[this.dbname][i] = Object.freeze({
                  data: s,
                  metadata: a
                });
              } catch (e) {
                o = {
                  error: e
                };
              } finally {
                try {
                  n && !n.done && (u = t.return) && u.call(t);
                } finally {
                  if (o) throw o.error;
                }
              }
              return [3, 3];
            case 2:
              throw Ri.storeNotAvailable;
            case 3:
              return [2];
          }
        });
      });
    }, t.prototype._removeRaw = function (e) {
      return U(this, void 0, void 0, function () {
        var t, n, r, i, s;
        return A(this, function (a) {
          switch (a.label) {
            case 0:
              return Ts[this.dbname] ? [4, qi(this.delay)] : [3, 2];
            case 1:
              a.sent();
              try {
                for (t = v(e), n = t.next(); !n.done; n = t.next()) r = n.value, Ts[this.dbname][r] && delete Ts[this.dbname][r];
              } catch (e) {
                i = {
                  error: e
                };
              } finally {
                try {
                  n && !n.done && (s = t.return) && s.call(t);
                } finally {
                  if (i) throw i.error;
                }
              }
              return [3, 3];
            case 2:
              throw Ri.storeNotAvailable;
            case 3:
              return [2];
          }
        });
      });
    }, t.prototype.observe = function (e, t, n) {
      var r = this;
      this.observer[e] || (this.observer[e] = {}), t.forEach(function (t) {
        return r.observer[e][t] = n;
      });
    }, t.prototype.checkAvailability = function () {
      return U(this, void 0, void 0, function () {
        return A(this, function (e) {
          return [2];
        });
      });
    }, t.prototype.init = function (e) {
      return U(this, void 0, void 0, function () {
        return A(this, function (t) {
          switch (t.label) {
            case 0:
              return this.dbname = e, Ts[this.dbname] || (Ts[this.dbname] = {}), [4, this._resetIfEncryptionChanged()];
            case 1:
              return t.sent(), [2];
          }
        });
      });
    }, t.prototype.set = function (t) {
      return U(this, void 0, void 0, function () {
        var n, r;
        return A(this, function (i) {
          if ((n = this.observer[t.key]) && "function" == typeof n.set && (r = n.set())) throw r;
          return [2, e.prototype.set.call(this, t)];
        });
      });
    }, t.prototype.setMany = function (t) {
      return U(this, void 0, void 0, function () {
        var n, r, i, s, a, o, u;
        return A(this, function (c) {
          try {
            for (n = v(t), r = n.next(); !r.done; r = n.next()) if (i = r.value, (s = this.observer[i.key]) && "function" == typeof s.set && (a = s.set())) throw a;
          } catch (e) {
            o = {
              error: e
            };
          } finally {
            try {
              r && !r.done && (u = n.return) && u.call(n);
            } finally {
              if (o) throw o.error;
            }
          }
          return [2, e.prototype.setMany.call(this, t)];
        });
      });
    }, t.prototype.clear = function () {
      return U(this, void 0, void 0, function () {
        return A(this, function (t) {
          switch (t.label) {
            case 0:
              return [4, e.prototype.clear.call(this)];
            case 1:
              return t.sent(), [4, qi(this.delay)];
            case 2:
              return t.sent(), Ts[this.dbname] = {}, [2];
          }
        });
      });
    }, t;
  }(Es),
  Ms = "NestDBStore";
!function (e) {
  e[e.UNINITIALIZED = 0] = "UNINITIALIZED", e[e.OPENING = 1] = "OPENING", e[e.OPEN = 2] = "OPEN", e[e.CLOSED = 3] = "CLOSED";
}(ps || (ps = {}));
var Ss,
  Us = function (e) {
    function t(t) {
      void 0 === t && (t = {});
      var n,
        r = this;
      return (r = e.call(this, u(u({}, t), {
        itemSizeLimit: null !== (n = t.itemSizeLimit) && void 0 !== n ? n : 104857600
      })) || this)._storeName = Ms, r._state = ps.UNINITIALIZED, r._openJobQueue = [], r._window = "undefined" != typeof window ? window : void 0, r._indexedDb = r._window ? r._window.indexedDB || r._window.mozIndexedDB || r._window.webkitIndexedDB || r._window.msIndexedDB : void 0, r;
    }
    return r(t, e), Object.defineProperty(t.prototype, "state", {
      get: function () {
        return this._state;
      },
      enumerable: !1,
      configurable: !0
    }), t.prototype.isTransactionInactiveError = function (e) {
      return e instanceof Error && e.message.includes("The transaction is inactive");
    }, t.prototype._putToObjectStore = function (e, t) {
      return U(this, void 0, void 0, function () {
        return A(this, function (n) {
          return [2, new Promise(function (n, r) {
            var i = e.put(t);
            i.addEventListener("success", function (e) {
              n(e.target.result);
            }), i.addEventListener("error", function () {
              r("Failed to write.");
            });
          })];
        });
      });
    }, t.prototype._deleteFromObjectStore = function (e, t) {
      return U(this, void 0, void 0, function () {
        return A(this, function (n) {
          return [2, new Promise(function (n, r) {
            var i = e.delete(t);
            i.addEventListener("success", function () {
              return n(t);
            }), i.addEventListener("error", function (e) {
              return r(e.target.error);
            });
          })];
        });
      });
    }, t.prototype._openDatabase = function (e) {
      var t = this;
      return new Promise(function (n, r) {
        if (t._indexedDb) {
          t._state = ps.OPENING;
          var i = t._indexedDb.open(e);
          i.addEventListener("upgradeneeded", function (e) {
            e.target.result.createObjectStore(Ms, {
              keyPath: "key"
            });
          }), i.addEventListener("success", function (r) {
            t._state = ps.OPEN, t._database = r.target.result, t._openJobQueue.forEach(function (e) {
              return e();
            }), t._openJobQueue = [], t._database.onclose = function () {
              t._database = void 0, t._state = ps.OPENING, setTimeout(function () {
                t._openDatabase(e);
              }, 5);
            }, n(t._database);
          }), i.addEventListener("error", function (e) {
            t._state = ps.UNINITIALIZED, r(e.target.error);
          });
        } else r(Ri.storeNotAvailable);
      });
    }, t.prototype._getObjectStore = function (e) {
      return U(this, void 0, void 0, function () {
        var t = this;
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return this._database ? [2, this._database.transaction(this._storeName, e).objectStore(this._storeName)] : [3, 1];
            case 1:
              switch (this._state) {
                case ps.UNINITIALIZED:
                case ps.OPEN:
                  return [3, 2];
                case ps.OPENING:
                case ps.CLOSED:
                  return [3, 3];
              }
              return [3, 4];
            case 2:
              throw Ri.storeNotInitialized;
            case 3:
              return [2, new Promise(function (n) {
                t._openJobQueue.push(function () {
                  return n(t._getObjectStore(e));
                });
              })];
            case 4:
              return [4, this._getObjectStore(e)];
            case 5:
              return [2, n.sent()];
          }
        });
      });
    }, t.prototype._getAllRawKeys = function () {
      return U(this, void 0, void 0, function () {
        var e;
        return A(this, function (t) {
          switch (t.label) {
            case 0:
              return [4, this._getObjectStore("readonly")];
            case 1:
              return e = t.sent(), [4, new Promise(function (t, n) {
                var r = e.getAllKeys();
                r.addEventListener("success", function (e) {
                  t(e.target.result);
                }), r.addEventListener("error", function (e) {
                  return n(e.target.error);
                });
              })];
            case 2:
              return [2, t.sent()];
          }
        });
      });
    }, t.prototype._getRaw = function (e) {
      return U(this, void 0, void 0, function () {
        var t;
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return [4, this._getObjectStore("readonly")];
            case 1:
              return t = n.sent(), [4, new Promise(function (n, r) {
                var i = t.get(e);
                i.addEventListener("success", function (e) {
                  var t;
                  n(null === (t = null == e ? void 0 : e.target) || void 0 === t ? void 0 : t.result);
                }), i.addEventListener("error", function (e) {
                  return r(e.target.error);
                });
              })];
            case 2:
              return [2, n.sent()];
          }
        });
      });
    }, t.prototype._setRaw = function (e) {
      return U(this, void 0, void 0, function () {
        var t,
          n,
          r,
          i,
          s,
          a,
          o,
          u,
          c = this;
        return A(this, function (l) {
          switch (l.label) {
            case 0:
              return [4, this._getObjectStore("readwrite")];
            case 1:
              t = l.sent(), l.label = 2;
            case 2:
              return l.trys.push([2, 4,, 15]), [4, Promise.all(e.map(function (e) {
                return c._putToObjectStore(t, e);
              }))];
            case 3:
              return l.sent(), [3, 15];
            case 4:
              if (n = l.sent(), !this.isTransactionInactiveError(n)) return [3, 13];
              l.label = 5;
            case 5:
              l.trys.push([5, 10, 11, 12]), r = v(e), i = r.next(), l.label = 6;
            case 6:
              return i.done ? [3, 9] : (s = i.value, [4, this._putToObjectStore(t, s)]);
            case 7:
              l.sent(), l.label = 8;
            case 8:
              return i = r.next(), [3, 6];
            case 9:
              return [3, 12];
            case 10:
              return a = l.sent(), o = {
                error: a
              }, [3, 12];
            case 11:
              try {
                i && !i.done && (u = r.return) && u.call(r);
              } finally {
                if (o) throw o.error;
              }
              return [7];
            case 12:
              return [3, 14];
            case 13:
              throw n;
            case 14:
              return [3, 15];
            case 15:
              return [2];
          }
        });
      });
    }, t.prototype._removeRaw = function (e) {
      return U(this, void 0, void 0, function () {
        var t,
          n,
          r,
          i,
          s,
          a,
          o,
          u,
          c = this;
        return A(this, function (l) {
          switch (l.label) {
            case 0:
              return [4, this._getObjectStore("readwrite")];
            case 1:
              t = l.sent(), l.label = 2;
            case 2:
              return l.trys.push([2, 4,, 15]), [4, Promise.all(e.map(function (e) {
                return c._deleteFromObjectStore(t, e);
              }))];
            case 3:
              return l.sent(), [3, 15];
            case 4:
              if (n = l.sent(), !this.isTransactionInactiveError(n)) return [3, 13];
              l.label = 5;
            case 5:
              l.trys.push([5, 10, 11, 12]), r = v(e), i = r.next(), l.label = 6;
            case 6:
              return i.done ? [3, 9] : (s = i.value, [4, this._deleteFromObjectStore(t, s)]);
            case 7:
              l.sent(), l.label = 8;
            case 8:
              return i = r.next(), [3, 6];
            case 9:
              return [3, 12];
            case 10:
              return a = l.sent(), o = {
                error: a
              }, [3, 12];
            case 11:
              try {
                i && !i.done && (u = r.return) && u.call(r);
              } finally {
                if (o) throw o.error;
              }
              return [7];
            case 12:
              return [3, 14];
            case 13:
              throw n;
            case 14:
              return [3, 15];
            case 15:
              return [2];
          }
        });
      });
    }, t.prototype._triggerDatabaseClose = function () {
      this._database && this._database.onclose && this._database.onclose(new Event("dummy"));
    }, t.prototype.checkAvailability = function () {
      return U(this, void 0, void 0, function () {
        var e,
          t = this;
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              if (!((null == (e = "undefined" != typeof window ? window : null) ? void 0 : e.indexedDB) || (null == e ? void 0 : e.mozIndexedDB) || (null == e ? void 0 : e.webkitIndexedDB) || (null == e ? void 0 : e.msIndexedDB))) return [3, 6];
              if (this._indexedDb = e.indexedDB || e.mozIndexedDB || e.webkitIndexedDB || e.msIndexedDB, !this._window || !hs()) return [3, 4];
              if (!(hs() && navigator.userAgent && navigator.userAgent.includes("Edge/"))) return [3, 1];
              if (!this._window.indexedDB && (e.PointerEvent || e.MSPointerEvent)) throw Ri.storeNotAvailableInPrivateBrowsing;
              return [3, 3];
            case 1:
              return [4, new Promise(function (e, n) {
                if (t._indexedDb) try {
                  var r = t._indexedDb.open("_testMozilla");
                  r.onerror = function () {
                    return n(Ri.storeNotAvailableInPrivateBrowsing);
                  }, r.onsuccess = function (r) {
                    if (r.target.result.close(), t._indexedDb) {
                      var i = t._indexedDb.deleteDatabase("_testMozilla");
                      i.onerror = function () {
                        return e();
                      }, i.onsuccess = function () {
                        return e();
                      };
                    } else n(Ri.storeNotAvailable);
                  };
                } catch (e) {
                  n(Ri.storeNotAvailableInPrivateBrowsing);
                } else n(Ri.storeNotAvailable);
              })];
            case 2:
              n.sent(), n.label = 3;
            case 3:
              return [3, 5];
            case 4:
              throw Ri.storeNotAvailable;
            case 5:
              return [3, 7];
            case 6:
              throw Ri.storeNotAvailable;
            case 7:
              return [2];
          }
        });
      });
    }, t.prototype.init = function (e) {
      return U(this, void 0, void 0, function () {
        return A(this, function (t) {
          switch (t.label) {
            case 0:
              return this.dbname = e, [4, this.checkAvailability()];
            case 1:
              return t.sent(), [4, this._openDatabase(e)];
            case 2:
              return t.sent(), [4, this._resetIfEncryptionChanged()];
            case 3:
              return t.sent(), [2];
          }
        });
      });
    }, t.prototype.clear = function () {
      return U(this, void 0, void 0, function () {
        var t;
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return [4, e.prototype.clear.call(this)];
            case 1:
              return n.sent(), [4, this._getObjectStore("readwrite")];
            case 2:
              return t = n.sent(), [4, new Promise(function (e, n) {
                var r = t.clear();
                r.addEventListener("success", function () {
                  return e();
                }), r.addEventListener("error", function (e) {
                  return n(e.target.error);
                });
              })];
            case 3:
              return [2, n.sent()];
          }
        });
      });
    }, t;
  }(Es),
  As = function (e) {
    function t(t) {
      var n = this,
        r = t.AsyncStorage,
        i = t.itemSizeLimit,
        s = void 0 === i ? 2097152 : i,
        a = _e(t, ["AsyncStorage", "itemSizeLimit"]);
      return (n = e.call(this, u(u({}, a), {
        itemSizeLimit: s
      })) || this)._asyncStorage = r, n;
    }
    return r(t, e), t.prototype._isBelonging = function (e) {
      return e.startsWith("".concat(this.dbname, "/"));
    }, t.prototype._getActualKey = function (e) {
      return "".concat(this.dbname, "/").concat(e);
    }, t.prototype._getAllRawKeys = function () {
      return U(this, void 0, void 0, function () {
        var e,
          t = this;
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return [4, this._asyncStorage.getAllKeys()];
            case 1:
              return e = n.sent(), [2, e.filter(function (e) {
                return t._isBelonging(e);
              }).map(function (e) {
                return e.substring("".concat(t.dbname, "/").length);
              })];
          }
        });
      });
    }, t.prototype._getRaw = function (e) {
      return U(this, void 0, void 0, function () {
        var t;
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return [4, this._asyncStorage.getItem(this._getActualKey(e))];
            case 1:
              return [2, (t = n.sent()) ? JSON.parse(t) : null];
          }
        });
      });
    }, t.prototype._setRaw = function (e) {
      return U(this, void 0, void 0, function () {
        var t, n, r, i, s, a, o;
        return A(this, function (u) {
          switch (u.label) {
            case 0:
              t = [];
              try {
                for (n = v(e), r = n.next(); !r.done; r = n.next()) i = r.value, s = i.key, i.data.length <= this.adjustedItemSizeLimit && t.push([this._getActualKey(s), JSON.stringify(i)]);
              } catch (e) {
                a = {
                  error: e
                };
              } finally {
                try {
                  r && !r.done && (o = n.return) && o.call(n);
                } finally {
                  if (a) throw a.error;
                }
              }
              return [4, this._asyncStorage.multiSet(t)];
            case 1:
              return u.sent(), [2];
          }
        });
      });
    }, t.prototype._removeRaw = function (e) {
      return U(this, void 0, void 0, function () {
        var t = this;
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return [4, this._asyncStorage.multiRemove(e.map(function (e) {
                return t._getActualKey(e);
              }))];
            case 1:
              return n.sent(), [2];
          }
        });
      });
    }, t.prototype.checkAvailability = function () {
      return U(this, void 0, void 0, function () {
        return A(this, function (e) {
          if (!this._asyncStorage) throw Ri.storeNotAvailable;
          return [2];
        });
      });
    }, t.prototype.init = function (e) {
      return U(this, void 0, void 0, function () {
        return A(this, function (t) {
          switch (t.label) {
            case 0:
              return this.dbname = e, [4, this._resetIfEncryptionChanged()];
            case 1:
              return t.sent(), [2];
          }
        });
      });
    }, t.prototype.clear = function () {
      return U(this, void 0, void 0, function () {
        var t;
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return [4, e.prototype.clear.call(this)];
            case 1:
              return n.sent(), [4, this.getAllKeys()];
            case 2:
              return t = n.sent(), [4, this.removeMany(t)];
            case 3:
              return n.sent(), [2];
          }
        });
      });
    }, t;
  }(Es),
  Ns = function (e) {
    function t(t) {
      var n,
        r = this;
      return (r = e.call(this, u(u({}, t), {
        itemSizeLimit: null !== (n = t.itemSizeLimit) && void 0 !== n ? n : 6291456
      })) || this)._mmkv = t.MMKV, r;
    }
    return r(t, e), t.prototype._isBelonging = function (e) {
      return e.startsWith("".concat(this.dbname, "/"));
    }, t.prototype._getActualKey = function (e) {
      return "".concat(this.dbname, "/").concat(e);
    }, t.prototype._getAllRawKeys = function () {
      return U(this, void 0, void 0, function () {
        var e,
          t = this;
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return [4, this._mmkv.getAllKeys()];
            case 1:
              return e = n.sent(), [2, e.filter(function (e) {
                return t._isBelonging(e);
              }).map(function (e) {
                return e.substring("".concat(t.dbname, "/").length);
              })];
          }
        });
      });
    }, t.prototype._getRaw = function (e) {
      return U(this, void 0, void 0, function () {
        var t;
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return [4, this._mmkv.getString(this._getActualKey(e))];
            case 1:
              return [2, (t = n.sent()) ? JSON.parse(t) : null];
          }
        });
      });
    }, t.prototype._setRaw = function (e) {
      return U(this, void 0, void 0, function () {
        var t, n, r, i, s, a;
        return A(this, function (o) {
          try {
            for (t = v(e), n = t.next(); !n.done; n = t.next()) r = n.value, i = r.key, r.data.length <= this.adjustedItemSizeLimit && this._mmkv.set(this._getActualKey(i), JSON.stringify(r));
          } catch (e) {
            s = {
              error: e
            };
          } finally {
            try {
              n && !n.done && (a = t.return) && a.call(t);
            } finally {
              if (s) throw s.error;
            }
          }
          return [2];
        });
      });
    }, t.prototype._removeRaw = function (e) {
      return U(this, void 0, void 0, function () {
        var t, n, r, i, s;
        return A(this, function (a) {
          try {
            for (t = v(e), n = t.next(); !n.done; n = t.next()) r = n.value, this._mmkv.delete(this._getActualKey(r));
          } catch (e) {
            i = {
              error: e
            };
          } finally {
            try {
              n && !n.done && (s = t.return) && s.call(t);
            } finally {
              if (i) throw i.error;
            }
          }
          return [2];
        });
      });
    }, t.prototype.checkAvailability = function () {
      return U(this, void 0, void 0, function () {
        return A(this, function (e) {
          if (!this._mmkv) throw Ri.storeNotAvailable;
          return [2];
        });
      });
    }, t.prototype.init = function (e) {
      return U(this, void 0, void 0, function () {
        return A(this, function (t) {
          switch (t.label) {
            case 0:
              return this.dbname = e, [4, this._resetIfEncryptionChanged()];
            case 1:
              return t.sent(), [2];
          }
        });
      });
    }, t.prototype.clear = function () {
      return U(this, void 0, void 0, function () {
        var t;
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return [4, e.prototype.clear.call(this)];
            case 1:
              return n.sent(), [4, this.getAllKeys()];
            case 2:
              return t = n.sent(), [4, this.removeMany(t)];
            case 3:
              return n.sent(), [2];
          }
        });
      });
    }, t;
  }(Es);
!function (e) {
  e.INIT = "INIT", e.OPENING = "OPENING", e.OPENED = "OPENED", e.CLOSED = "CLOSED";
}(Ss || (Ss = {}));
var Cs,
  Os = function () {
    function e(e) {
      var t = e.name,
        n = e.version,
        r = e.store,
        i = e.config;
      this.name = t, this._version = n, this._state = Ss.INIT, this._config = i || new Pi({
        dbname: t
      }), this._store = r, this._event = {
        success: Bi,
        error: Bi,
        storeReplaced: Bi,
        upgrade: Qi
      }, this._collections = new Map(), this._globalMutex = new vs("".concat(this.name, ".lock")), this._config.disableLogger && bs.off(), new os({
        dbname: t,
        limit: this._config.cacheLimit
      });
    }
    return Object.defineProperty(e.prototype, "version", {
      get: function () {
        return this._version;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(e.prototype, "state", {
      get: function () {
        return this._state;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(e.prototype, "store", {
      get: function () {
        return this._store;
      },
      enumerable: !1,
      configurable: !0
    }), e.prototype.estimateUsage = function () {
      return U(this, void 0, void 0, function () {
        return A(this, function (e) {
          switch (e.label) {
            case 0:
              return [4, (t = this._store, U(void 0, void 0, void 0, function () {
                return A(this, function (e) {
                  switch (e.label) {
                    case 0:
                      return [4, t.usage()];
                    case 1:
                      return [2, e.sent()];
                  }
                });
              }))];
            case 1:
              return [2, e.sent()];
          }
          var t;
        });
      });
    }, e.prototype.commitSchema = function (e) {
      return U(this, void 0, void 0, function () {
        var t = this;
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return this._state !== Ss.OPENING ? [3, 2] : [4, Promise.all(e.map(function (e) {
                return U(t, void 0, void 0, function () {
                  var t, n, r, i, s;
                  return A(this, function (a) {
                    switch (a.label) {
                      case 0:
                        return t = e.collectionName, n = e.keyName, r = e.index, i = void 0 === r ? [] : r, this._collections.has(t) || this._collections.set(t, new ys({
                          dbname: this.name,
                          collectionName: t,
                          keyName: n,
                          indexes: i,
                          store: this._store
                        })), (s = this._collections.get(t)) ? [4, s.init()] : [3, 2];
                      case 1:
                        a.sent(), a.label = 2;
                      case 2:
                        return [2];
                    }
                  });
                });
              }))];
            case 1:
              return n.sent(), [3, 3];
            case 2:
              throw Ri.databaseSchemaNotOnUpgrade;
            case 3:
              return [2];
          }
        });
      });
    }, e.prototype.open = function () {
      var e;
      return U(this, void 0, void 0, function () {
        var t,
          n,
          r,
          i,
          s = this;
        return A(this, function (a) {
          switch (a.label) {
            case 0:
              return [4, this._globalMutex.lock()];
            case 1:
              if (a.sent(), this._state === Ss.OPENED) return [3, 12];
              this._state = Ss.OPENING, a.label = 2;
            case 2:
              return a.trys.push([2, 5,, 12]), [4, this._store.init(this.name)];
            case 3:
              return a.sent(), o = this.name, t = "".concat(Xi(o), ".metadata"), n = {
                version: 0,
                collectionNames: []
              }, [4, this._store.get(t)];
            case 4:
              return r = null !== (e = a.sent()) && void 0 !== e ? e : n, [2, new Promise(function (e, n) {
                var i = function (e) {
                    r.version < s._version ? s._event.upgrade(r.version, function (n) {
                      return U(s, void 0, void 0, function () {
                        var i;
                        return A(this, function (s) {
                          switch (s.label) {
                            case 0:
                              if (n) return [3, 5];
                              r.version++, r.collectionNames = Array.from(this._collections.keys()), s.label = 1;
                            case 1:
                              return s.trys.push([1, 3,, 4]), [4, this._store.set({
                                key: t,
                                value: r
                              })];
                            case 2:
                              return s.sent(), e({
                                continued: !0
                              }), [3, 4];
                            case 3:
                              return i = s.sent(), e({
                                continued: !1,
                                err: i
                              }), [3, 4];
                            case 4:
                              return [3, 6];
                            case 5:
                              e({
                                continued: !1,
                                err: n
                              }), s.label = 6;
                            case 6:
                              return [2];
                          }
                        });
                      });
                    }) : e({
                      continued: !1
                    });
                  },
                  a = function (t) {
                    var o = t.continued,
                      u = void 0 !== o && o,
                      c = t.err,
                      l = void 0 === c ? null : c;
                    if (u) setTimeout(function () {
                      return i(a);
                    }, 10);else if (l) bs.error(l.message), s._globalMutex.unlock(), s._event.error(l), n(l);else {
                      var d = [];
                      r.collectionNames.forEach(function (e) {
                        var t = s._collections.get(e);
                        t && t.state === ki.READY || d.push(U(s, void 0, void 0, function () {
                          var t, n;
                          return A(this, function (r) {
                            switch (r.label) {
                              case 0:
                                return [4, ys.metadataOf(this.name, e, this._store)];
                              case 1:
                                return (t = r.sent()) ? (n = new ys({
                                  dbname: this.name,
                                  collectionName: e,
                                  keyName: t.keyName,
                                  indexes: t.indexes,
                                  store: this._store
                                }), this._collections.set(e, n), [4, n.init()]) : [3, 3];
                              case 2:
                                r.sent(), r.label = 3;
                              case 3:
                                return [2];
                            }
                          });
                        }));
                      }), Promise.all(d).then(function () {
                        s._state = Ss.OPENED, s._globalMutex.unlock(), s._event.success(), e();
                      }).catch(function (e) {
                        bs.error(e.message), s._globalMutex.unlock(), s._event.error(e), n(e);
                      });
                    }
                  };
                i(a);
              })];
            case 5:
              switch (i = a.sent(), i.code) {
                case yi.STORE_NOT_AVAILABLE_IN_PRIVATE_BROWSING:
                  return [3, 6];
                case yi.STORE_NOT_AVAILABLE:
                  return [3, 8];
              }
              return [3, 10];
            case 6:
              return bs.warning("Access to the local storage is not allowed. Switched to MemoryStore automatically."), this._store = new ws({}), this._globalMutex.unlock(), this._event.error(i), this._event.storeReplaced(this._store), [4, this.open()];
            case 7:
              return a.sent(), [3, 11];
            case 8:
              return bs.warning("IndexedDB is not available in this environment. Switched to MemoryStore automatically. Consider using other store to save data persistently (e.g. AsyncStorage)."), this._store = new ws({}), this._globalMutex.unlock(), this._event.error(i), this._event.storeReplaced(this._store), [4, this.open()];
            case 9:
              return a.sent(), [3, 11];
            case 10:
              throw bs.error(i.message), this._globalMutex.unlock(), this._event.error(i), i;
            case 11:
              return [3, 12];
            case 12:
              return [2];
          }
          var o;
        });
      });
    }, e.prototype.close = function () {
      this._collections.forEach(function (e) {
        return e.close();
      }), this._state = Ss.CLOSED;
    }, e.prototype.clear = function () {
      return U(this, void 0, void 0, function () {
        return A(this, function (e) {
          switch (e.label) {
            case 0:
              return [4, Promise.all(Array.from(this._collections.values()).map(function (e) {
                return e.clear();
              }))];
            case 1:
              return e.sent(), [2];
          }
        });
      });
    }, e.prototype.reset = function () {
      return U(this, void 0, void 0, function () {
        var e;
        return A(this, function (t) {
          switch (t.label) {
            case 0:
              return (e = os.get(this.name)) && e.clearForDatabase(this.name), [4, this._store.clear()];
            case 1:
              return t.sent(), [2];
          }
        });
      });
    }, e.prototype.on = function (e, t) {
      this._event[e] = t;
    }, e.prototype.off = function (e) {
      if ("function" == typeof this._event[e]) if ("upgrade" === e) this._event[e] = Qi;else this._event[e] = Bi;
    }, e.prototype.collection = function (e) {
      var t = this._collections.get(e);
      if (t) return t;
      throw Ri.collectionNotReady;
    }, e;
  }();
!function (e) {
  e[e.VERBOSE = 0] = "VERBOSE", e[e.DEBUG = 1] = "DEBUG", e[e.INFO = 2] = "INFO", e[e.WARN = 3] = "WARN", e[e.ERROR = 4] = "ERROR", e[e.NONE = 5] = "NONE";
}(Cs || (Cs = {}));
var Ps,
  ks = function () {
    function e() {
      this.level = Cs.WARN;
    }
    return e.prototype.verbose = function () {
      for (var e = [], t = 0; t < arguments.length; t++) e[t] = arguments[t];
      this.level <= Cs.VERBOSE && console.log.apply(console, p(["[verbose]"], m(e), !1));
    }, e.prototype.debug = function () {
      for (var e = [], t = 0; t < arguments.length; t++) e[t] = arguments[t];
      this.level <= Cs.DEBUG && console.log.apply(console, p(["[debug]"], m(e), !1));
    }, e.prototype.info = function () {
      for (var e = [], t = 0; t < arguments.length; t++) e[t] = arguments[t];
      this.level <= Cs.INFO && console.log.apply(console, p(["[info]"], m(e), !1));
    }, e.prototype.warn = function () {
      for (var e = [], t = 0; t < arguments.length; t++) e[t] = arguments[t];
      this.level <= Cs.WARN && console.warn.apply(console, p(["[warn]"], m(e), !1));
    }, e.prototype.error = function () {
      for (var e = [], t = 0; t < arguments.length; t++) e[t] = arguments[t];
      this.level <= Cs.ERROR && console.error.apply(console, p(["[error]"], m(e), !1));
    }, e;
  }(),
  Rs = function () {
    function e(e) {
      var t, n, r, i;
      this.messageTypeFilter = null !== (t = null == e ? void 0 : e.messageTypeFilter) && void 0 !== t ? t : x.ALL, this.customTypesFilter = null !== (n = null == e ? void 0 : e.customTypesFilter) && void 0 !== n ? n : null, this.senderUserIdsFilter = null !== (r = null == e ? void 0 : e.senderUserIdsFilter) && void 0 !== r ? r : null, this.replyType = null !== (i = null == e ? void 0 : e.replyType) && void 0 !== i ? i : H.NONE;
    }
    return e.prototype.clone = function () {
      var t = new e(),
        n = JSON.parse(JSON.stringify(this));
      return Object.keys(n).forEach(function (e) {
        t[e] = n[e];
      }), t;
    }, e.prototype.match = function (e) {
      switch (this.messageTypeFilter) {
        case x.USER:
          if (e.messageType !== g.USER) return !1;
          break;
        case x.FILE:
          if (e.messageType !== g.FILE) return !1;
          break;
        case x.ADMIN:
          if (e.messageType !== g.ADMIN) return !1;
      }
      if (this.customTypesFilter && this.customTypesFilter.length > 0 && !this.customTypesFilter.includes("*") && !this.customTypesFilter.includes(e.customType)) return !1;
      if (this.senderUserIdsFilter && this.senderUserIdsFilter.length > 0) {
        if (!(e instanceof yt)) return !1;
        if (!this.senderUserIdsFilter.includes(e.sender.userId)) return !1;
      }
      if (e instanceof vt) switch (this.replyType) {
        case H.NONE:
          if (e.parentMessageId > 0) return !1;
          break;
        case H.ONLY_REPLY_TO_CHANNEL:
          if (e instanceof yt && e.parentMessageId > 0 && !e.replyToChannel) return !1;
      }
      return !0;
    }, e;
  }();
!function (e) {
  e.CHANNEL_LATEST = "channel_latest", e.NEWEST_CHILD_MESSAGE = "newest_child_message";
}(Ps || (Ps = {}));
var xs,
  Ds = function (e) {
    switch (e) {
      case Ps.CHANNEL_LATEST:
        return ["channelUrl", "-createdAt", "-messageId"];
      case Ps.NEWEST_CHILD_MESSAGE:
        return ["channelUrl", "-parentMessageId", "-createdAt", "-messageId"];
    }
  },
  Ls = function () {
    return ["channelUrl", "-createdAt", "-notificationId"];
  },
  Fs = u(u({}, rn), {
    scheduledAt: void 0
  }),
  qs = function (e) {
    return un(e) && h("number", e.scheduledAt, !0);
  },
  zs = u(u({}, en), {
    scheduledAt: 0,
    file: void 0,
    fileUrl: void 0,
    fileName: void 0,
    mimeType: void 0,
    fileSize: void 0,
    thumbnailSizes: void 0,
    requireAuth: !1
  }),
  Bs = function (e) {
    return tn(e) && h("number", e.scheduledAt) && (G(e.file) || h("string", e.fileUrl)) && h("string", e.fileName, !0) && h("string", e.mimeType, !0) && h("number", e.fileSize, !0) && (null === e.thumbnailSizes || void 0 === e.thumbnailSizes || e.thumbnailSizes.every(function (e) {
      return h("object", e) && e.maxWidth > 0 && e.maxHeight > 0;
    }));
  },
  js = "UnsentMessage",
  Ks = "reqId",
  Qs = {},
  Gs = function (t) {
    function i(e) {
      var n = t.call(this, e) || this;
      return n._mutex = new vs("unsendmessagecache.lock"), Qs[e] = n, n;
    }
    return r(i, t), Object.defineProperty(i.prototype, "_cacheContext", {
      get: function () {
        return n.of(this._iid).cacheContext;
      },
      enumerable: !1,
      configurable: !0
    }), i.of = function (e, t) {
      return void 0 === t && (t = !1), Qs[e] && !t || (Qs[e] = new i(e)), Qs[e];
    }, Object.defineProperty(i.prototype, "collection", {
      get: function () {
        var e = this._cacheContext.nestdb,
          t = null == e ? void 0 : e.collection(js);
        if (!t) throw O.databaseError;
        return t;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(i.prototype, "localCacheEnabled", {
      get: function () {
        return this._cacheContext.localCacheEnabled && !!this.collection;
      },
      enumerable: !1,
      configurable: !0
    }), i.prototype._serialize = function (t) {
      if (t.messageId > 0) throw O.invalidParameters;
      var n,
        r = u({}, t.serialize());
      return t instanceof Tt ? (t.messageParams && (r.messageParams = nn(t.messageParams)), t.scheduledInfo && t.scheduledInfo.scheduledMessageParams && (r.scheduledInfo.scheduledMessageParams = (n = t.scheduledInfo.scheduledMessageParams, u(u({}, nn(n)), {
        scheduledAt: n.scheduledAt
      })))) : t instanceof At ? (t.messageParams && (r.messageParams = dn(t.messageParams)), t.scheduledInfo && t.scheduledInfo.scheduledMessageParams && (r.scheduledInfo.scheduledMessageParams = function (e) {
        return u(u({}, dn(e)), {
          scheduledAt: e.scheduledAt
        });
      }(t.scheduledInfo.scheduledMessageParams))) : t instanceof wt && t.messageParams && (r.messageParams = function (t) {
        return e({
          fileInfoList: t.fileInfoList,
          isReplyToChannel: t.isReplyToChannel,
          pushNotificationDeliveryOption: t.pushNotificationDeliveryOption
        });
      }(t.messageParams)), r;
    }, i.prototype._deserialize = function (e) {
      return e = u(u({}, e), {
        messageId: parseInt(e.messageId)
      }), ra.of(this._iid).buildMessageFromSerializedData(e);
    }, i.prototype._deserializeWithMessageCreateParams = function (e) {
      var t, n, r;
      return U(this, void 0, void 0, function () {
        var i,
          s,
          a,
          o,
          u,
          c,
          l = this;
        return A(this, function (d) {
          switch (d.label) {
            case 0:
              return i = ra.of(this._iid), s = this._deserialize(e), e.messageParams ? s instanceof Tt ? (o = e.messageParams, s.messageParams = i.buildUserMessageCreateParamsFromSerializedData(o, s), [3, 6]) : [3, 1] : [3, 6];
            case 1:
              return s instanceof At ? (o = e.messageParams).fileKey && "string" == typeof o.fileKey && ln(null !== (t = o.fileType) && void 0 !== t ? t : "") ? (a = o, [4, this.collection.getBlob(o.fileKey)]) : [3, 3] : [3, 4];
            case 2:
              a.file = null !== (n = d.sent()) && void 0 !== n ? n : void 0, d.label = 3;
            case 3:
              return s.messageParams = i.buildFileMessageCreateParamsFromSerializedData(o, s), [3, 6];
            case 4:
              return s instanceof wt && (o = e.messageParams) && o.fileInfoList ? (s.messageParams = i.buildMultipleFilesMessageCreateParamsFromSerializedData(o, s), [4, Promise.all(s.messageParams.fileInfoList.map(function (e) {
                return U(l, void 0, void 0, function () {
                  var t, n, r, i, s;
                  return A(this, function (a) {
                    switch (a.label) {
                      case 0:
                        return "string" == typeof (null === (n = e._uploadedMetaData) || void 0 === n ? void 0 : n.fileKey) && ln(null !== (i = null === (r = e._uploadedMetaData) || void 0 === r ? void 0 : r.fileType) && void 0 !== i ? i : "") ? (t = e, [4, this.collection.getBlob(e._uploadedMetaData.fileKey)]) : [3, 2];
                      case 1:
                        t.file = null !== (s = a.sent()) && void 0 !== s ? s : void 0, a.label = 2;
                      case 2:
                        return [2];
                    }
                  });
                });
              }))]) : [3, 6];
            case 5:
              d.sent(), d.label = 6;
            case 6:
              return s.scheduledInfo && e.scheduledInfo && e.scheduledInfo.scheduledMessageParams ? s instanceof Tt ? (u = e.scheduledInfo.scheduledMessageParams, s.scheduledInfo.scheduledMessageParams = i.buildScheduledUserMessageCreateParamsFromSerializedData(u, s), [3, 10]) : [3, 7] : [3, 10];
            case 7:
              return s instanceof At ? (u = e.scheduledInfo.scheduledMessageParams).fileKey && "string" == typeof u.fileKey && "string" == typeof u.fileType && ln(u.fileType) ? (c = u, [4, this.collection.getBlob(u.fileKey)]) : [3, 9] : [3, 10];
            case 8:
              c.file = null !== (r = d.sent()) && void 0 !== r ? r : void 0, d.label = 9;
            case 9:
              s.scheduledInfo.scheduledMessageParams = i.buildScheduledFileMessageCreateParamsFromSerializedData(u, s), d.label = 10;
            case 10:
              return [2, s];
          }
        });
      });
    }, i.prototype._getFileInfoBlobKey = function (e, t) {
      return "".concat(e, ".").concat(t);
    }, i.prototype.get = function (e) {
      return U(this, void 0, void 0, function () {
        var t;
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return this.localCacheEnabled ? [4, this.collection.getByKey("".concat(e))] : [3, 2];
            case 1:
              if (t = n.sent()) return [2, this._deserializeWithMessageCreateParams(t)];
              n.label = 2;
            case 2:
              return [2, void 0];
          }
        });
      });
    }, i.prototype.fetch = function (e) {
      var t = e.channelUrl,
        n = e.filter,
        r = void 0 === n ? new Rs() : n,
        i = e.order,
        s = void 0 === i ? Ps.CHANNEL_LATEST : i,
        a = e.sendingStatus,
        o = e.backward,
        u = void 0 !== o && o,
        c = e.parentMessageId;
      return U(this, void 0, void 0, function () {
        var e,
          n,
          i,
          o,
          l = this;
        return A(this, function (d) {
          switch (d.label) {
            case 0:
              return this.localCacheEnabled ? (e = Ds(s), n = {
                "/where": function (e) {
                  return !!(s !== Ps.NEWEST_CHILD_MESSAGE || c && 0 !== e.parentMessageId && e.parentMessageId === c) && r.match(l._deserialize(e));
                }
              }, t && (n.channelUrl = t), a && (n.sendingStatus = a), i = {
                where: n,
                index: e,
                backward: u
              }, [4, this.collection.query(i)]) : [3, 3];
            case 1:
              return [4, d.sent().fetch({})];
            case 2:
              return o = d.sent(), [2, Promise.all(o.map(function (e) {
                return U(l, void 0, void 0, function () {
                  return A(this, function (t) {
                    switch (t.label) {
                      case 0:
                        return [4, this._deserializeWithMessageCreateParams(e)];
                      case 1:
                        return [2, t.sent()];
                    }
                  });
                });
              }))];
            case 3:
              return [2, []];
          }
        });
      });
    }, i.prototype.getAllChildMessages = function (e, t) {
      return void 0 === t && (t = new Rs()), U(this, void 0, void 0, function () {
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return [4, this.fetch({
                filter: t,
                order: Ps.NEWEST_CHILD_MESSAGE,
                channelUrl: e.channelUrl,
                backward: !1,
                parentMessageId: e.messageId
              })];
            case 1:
              return [2, n.sent()];
          }
        });
      });
    }, i.prototype.upsert = function (e) {
      return U(this, void 0, void 0, function () {
        var t = this;
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return this.localCacheEnabled ? [4, Promise.all(e.map(function (e) {
                return U(t, void 0, void 0, function () {
                  var t;
                  return A(this, function (n) {
                    switch (n.label) {
                      case 0:
                        return e instanceof At || e instanceof wt ? [4, this._mutex.lock()] : [3, 4];
                      case 1:
                        return n.sent(), [4, this.saveBlob(e)];
                      case 2:
                        return n.sent(), [4, this._mutex.unlock()];
                      case 3:
                        n.sent(), n.label = 4;
                      case 4:
                        return t = this._serialize(e), [4, this.collection.upsertOne(t)];
                      case 5:
                        return n.sent(), [2];
                    }
                  });
                });
              }))] : [3, 2];
            case 1:
              n.sent(), n.label = 2;
            case 2:
              return [2];
          }
        });
      });
    }, i.prototype.upsertChildMessages = function (e) {
      return U(this, void 0, void 0, function () {
        var t = this;
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return this.localCacheEnabled ? [4, Promise.all(e.map(function (e) {
                return U(t, void 0, void 0, function () {
                  var t;
                  return A(this, function (n) {
                    switch (n.label) {
                      case 0:
                        return t = [], e.threadInfo && e.threadInfo.replyCount > 0 ? [4, this.getAllChildMessages(e)] : [3, 2];
                      case 1:
                        t = n.sent(), n.label = 2;
                      case 2:
                        return t.length > 0 ? (t.forEach(function (t) {
                          return t.applyParentMessage(e);
                        }), [4, this.upsert(t)]) : [3, 4];
                      case 3:
                        n.sent(), n.label = 4;
                      case 4:
                        return [2];
                    }
                  });
                });
              }))] : [3, 2];
            case 1:
              n.sent(), n.label = 2;
            case 2:
              return [2];
          }
        });
      });
    }, i.prototype.remove = function (e) {
      return U(this, void 0, void 0, function () {
        var t, n, r, i, s, a;
        return A(this, function (o) {
          switch (o.label) {
            case 0:
              if (!this.localCacheEnabled) return [3, 8];
              o.label = 1;
            case 1:
              o.trys.push([1, 6, 7, 8]), t = v(e), n = t.next(), o.label = 2;
            case 2:
              return n.done ? [3, 5] : (r = n.value, [4, this.collection.remove(r)]);
            case 3:
              o.sent(), o.label = 4;
            case 4:
              return n = t.next(), [3, 2];
            case 5:
              return [3, 8];
            case 6:
              return i = o.sent(), s = {
                error: i
              }, [3, 8];
            case 7:
              try {
                n && !n.done && (a = t.return) && a.call(t);
              } finally {
                if (s) throw s.error;
              }
              return [7];
            case 8:
              return [2];
          }
        });
      });
    }, i.prototype.removeMessagesOfChannel = function (e) {
      return U(this, void 0, void 0, function () {
        return A(this, function (t) {
          switch (t.label) {
            case 0:
              return this.localCacheEnabled ? [4, this.collection.removeIf({
                where: {
                  channelUrl: e
                }
              })] : [3, 2];
            case 1:
              t.sent(), t.label = 2;
            case 2:
              return [2];
          }
        });
      });
    }, i.prototype.clear = function () {
      return U(this, void 0, void 0, function () {
        return A(this, function (e) {
          switch (e.label) {
            case 0:
              return this.localCacheEnabled ? [4, this.collection.clear()] : [3, 2];
            case 1:
              e.sent(), e.label = 2;
            case 2:
              return [2];
          }
        });
      });
    }, i.prototype.saveBlob = function (e) {
      return U(this, void 0, void 0, function () {
        var t,
          n,
          r,
          i = this;
        return A(this, function (s) {
          switch (s.label) {
            case 0:
              return e instanceof At ? e.messageParams && (r = e.messageParams).file && cn(r.file) ? [4, this.collection.saveBlob(r.file, e.reqId)] : [3, 2] : [3, 5];
            case 1:
              n = s.sent(), r.fileKey = n, r.fileType = an.BLOB, s.label = 2;
            case 2:
              return e.scheduledInfo && e.scheduledInfo.scheduledMessageParams && (t = e.scheduledInfo.scheduledMessageParams).file && cn(t.file) ? [4, this.collection.saveBlob(t.file, e.reqId)] : [3, 4];
            case 3:
              n = s.sent(), t.fileKey = n, t.fileType = an.BLOB, s.label = 4;
            case 4:
              return [3, 7];
            case 5:
              return e instanceof wt && (r = e.messageParams) && r.fileInfoList && Array.isArray(r.fileInfoList) ? [4, Promise.all(r.fileInfoList.map(function (t, n) {
                return U(i, void 0, void 0, function () {
                  var r;
                  return A(this, function (i) {
                    switch (i.label) {
                      case 0:
                        return t.file && cn(t.file) ? [4, this.collection.saveBlob(t.file, this._getFileInfoBlobKey(e.reqId, n))] : [3, 2];
                      case 1:
                        r = i.sent(), t._uploadedMetaData || (t._uploadedMetaData = {}), t._uploadedMetaData.fileKey = r, t._uploadedMetaData.fileType = an.BLOB, i.label = 2;
                      case 2:
                        return [2];
                    }
                  });
                });
              }))] : [3, 7];
            case 6:
              s.sent(), s.label = 7;
            case 7:
              return [2];
          }
        });
      });
    }, i;
  }(l),
  Vs = {},
  Hs = function (e) {
    function t(t) {
      var n = e.call(this, t) || this;
      return Vs[t] = n, n;
    }
    return r(t, e), Object.defineProperty(t.prototype, "_sdkState", {
      get: function () {
        return n.of(this._iid).sdkState;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t.prototype, "_cacheContext", {
      get: function () {
        return n.of(this._iid).cacheContext;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t.prototype, "_unsentMessageCache", {
      get: function () {
        return Gs.of(this._iid);
      },
      enumerable: !1,
      configurable: !0
    }), t.of = function (e, n) {
      return void 0 === n && (n = !1), Vs[e] && !n || (Vs[e] = new t(e)), Vs[e];
    }, Object.defineProperty(t.prototype, "collection", {
      get: function () {
        var e = this._cacheContext.nestdb;
        return D(!!e).throw(O.databaseError), e.collection(Yr);
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t.prototype, "localCacheEnabled", {
      get: function () {
        return this._cacheContext.localCacheEnabled && !!this.collection;
      },
      enumerable: !1,
      configurable: !0
    }), t.prototype._serialize = function (e) {
      return u(u({}, e.serialize()), {
        messageId: "".concat(e.messageId)
      });
    }, t.prototype._deserialize = function (e) {
      return e = u(u({}, e), {
        messageId: parseInt(e.messageId)
      }), ra.of(this._iid).buildMessageFromSerializedData(e);
    }, t.prototype.get = function (e) {
      return U(this, void 0, void 0, function () {
        var t;
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return this.localCacheEnabled ? [4, this.collection.getByKey("".concat(e))] : [3, 2];
            case 1:
              if (t = n.sent()) return [2, this._deserialize(t)];
              n.label = 2;
            case 2:
              return [2, void 0];
          }
        });
      });
    }, t.prototype.fetch = function (e) {
      var t = e.channelUrl,
        n = e.token,
        r = e.limit,
        i = void 0 === r ? 100 : r,
        s = e.filter,
        a = void 0 === s ? new Rs() : s,
        o = e.order,
        u = void 0 === o ? Ps.CHANNEL_LATEST : o,
        c = e.backward,
        l = void 0 !== c && c,
        d = e.parentMessageId,
        h = e.isPollOnly,
        f = void 0 !== h && h,
        p = e.exactMatch,
        m = void 0 !== p && p,
        v = e.inclusive,
        y = void 0 === v || v;
      return U(this, void 0, void 0, function () {
        var e,
          r,
          s,
          o = this;
        return A(this, function (c) {
          switch (c.label) {
            case 0:
              return this.localCacheEnabled ? (e = Ds(u), r = {
                where: {
                  channelUrl: t,
                  "/where": function (e) {
                    if (null != n && "" !== n) switch (u) {
                      case Ps.CHANNEL_LATEST:
                        if (m && e.createdAt !== n) return !1;
                        if (l) {
                          if (y && e.createdAt < n || !y && e.createdAt <= n) return !1;
                        } else if (y && e.createdAt > n || !y && e.createdAt >= n) return !1;
                        break;
                      case Ps.NEWEST_CHILD_MESSAGE:
                        if (!d || 0 === e.parentMessageId || e.parentMessageId !== d) return !1;
                    }
                    return !(f && !e._poll) && a.match(o._deserialize(e));
                  }
                },
                index: e,
                backward: l
              }, [4, this.collection.query(r)]) : [3, 3];
            case 1:
              return [4, c.sent().fetch({
                limit: null != i ? i : void 0
              })];
            case 2:
              return s = c.sent(), [2, Promise.all(s.map(function (e) {
                return U(o, void 0, void 0, function () {
                  return A(this, function (t) {
                    return [2, this._deserialize(e)];
                  });
                });
              }))];
            case 3:
              return [2, []];
          }
        });
      });
    }, t.prototype.getAllChildMessages = function (e, t) {
      return void 0 === t && (t = new Rs()), U(this, void 0, void 0, function () {
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return [4, this.fetch({
                channelUrl: e.channelUrl,
                token: Date.now(),
                limit: null,
                backward: !1,
                filter: t,
                order: Ps.NEWEST_CHILD_MESSAGE,
                parentMessageId: e.messageId
              })];
            case 1:
              return [2, n.sent()];
          }
        });
      });
    }, t.prototype.upsert = function (e) {
      var t;
      return U(this, void 0, void 0, function () {
        var n,
          r,
          i,
          s,
          a,
          o,
          u,
          c,
          l = this;
        return A(this, function (d) {
          switch (d.label) {
            case 0:
              return this.localCacheEnabled ? [4, this.saveBlobs(e)] : [3, 13];
            case 1:
              d.sent(), d.label = 2;
            case 2:
              d.trys.push([2, 7, 8, 9]), n = v(e), r = n.next(), d.label = 3;
            case 3:
              return r.done ? [3, 6] : (i = r.value, (null === (t = i.threadInfo) || void 0 === t ? void 0 : t._isUpdateRequired) ? [4, this.get(i.messageId)] : [3, 5]);
            case 4:
              (null == (s = d.sent()) ? void 0 : s.threadInfo) && i.threadInfo._updateFrom(s.threadInfo), d.label = 5;
            case 5:
              return r = n.next(), [3, 3];
            case 6:
              return [3, 9];
            case 7:
              return a = d.sent(), u = {
                error: a
              }, [3, 9];
            case 8:
              try {
                r && !r.done && (c = n.return) && c.call(n);
              } finally {
                if (u) throw u.error;
              }
              return [7];
            case 9:
              return o = e.map(function (e) {
                return l._serialize(e);
              }), [4, this.collection.upsertMany(o)];
            case 10:
              return d.sent(), [4, this.upsertChildMessages(e)];
            case 11:
              return d.sent(), [4, this._unsentMessageCache.upsertChildMessages(e)];
            case 12:
              d.sent(), d.label = 13;
            case 13:
              return [2];
          }
        });
      });
    }, t.prototype.upsertChildMessages = function (e) {
      return U(this, void 0, void 0, function () {
        var t = this;
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return this.localCacheEnabled ? [4, Promise.all(e.map(function (e) {
                return U(t, void 0, void 0, function () {
                  var t, n;
                  return A(this, function (r) {
                    switch (r.label) {
                      case 0:
                        return t = [], (null === (n = e.threadInfo) || void 0 === n ? void 0 : n.replyCount) && e.threadInfo.replyCount > 0 ? [4, this.getAllChildMessages(e)] : [3, 2];
                      case 1:
                        t = r.sent(), r.label = 2;
                      case 2:
                        return t.length > 0 ? (t.forEach(function (t) {
                          return t.applyParentMessage(e);
                        }), [4, this.upsert(t)]) : [3, 4];
                      case 3:
                        r.sent(), r.label = 4;
                      case 4:
                        return [2];
                    }
                  });
                });
              }))] : [3, 2];
            case 1:
              n.sent(), n.label = 2;
            case 2:
              return [2];
          }
        });
      });
    }, t.prototype.remove = function (e) {
      return U(this, void 0, void 0, function () {
        var t, n, r, i, s, a;
        return A(this, function (o) {
          switch (o.label) {
            case 0:
              if (!this.localCacheEnabled) return [3, 8];
              o.label = 1;
            case 1:
              o.trys.push([1, 6, 7, 8]), t = v(e), n = t.next(), o.label = 2;
            case 2:
              return n.done ? [3, 5] : (r = n.value, [4, this.collection.remove("".concat(r))]);
            case 3:
              o.sent(), o.label = 4;
            case 4:
              return n = t.next(), [3, 2];
            case 5:
              return [3, 8];
            case 6:
              return i = o.sent(), s = {
                error: i
              }, [3, 8];
            case 7:
              try {
                n && !n.done && (a = t.return) && a.call(t);
              } finally {
                if (s) throw s.error;
              }
              return [7];
            case 8:
              return [2];
          }
        });
      });
    }, t.prototype.removeMessagesOfChannel = function (e) {
      return U(this, void 0, void 0, function () {
        var t;
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return this.localCacheEnabled ? [4, this.collection.removeIf({
                where: {
                  channelUrl: e
                },
                index: Ds(Ps.CHANNEL_LATEST)
              })] : [3, 6];
            case 1:
              return n.sent(), [4, (t = this._cacheContext.preference).remove("sendbird:".concat(this._sdkState.userId, "@groupchannel/").concat(e, "/message/sync"))];
            case 2:
              return n.sent(), [4, t.remove("sendbird:".concat(this._sdkState.userId, "@groupchannel/").concat(e, "/message/sync.meta"))];
            case 3:
              return n.sent(), [4, t.remove("sendbird:".concat(this._sdkState.userId, "@groupchannel/").concat(e, "/message/changelogs"))];
            case 4:
              return n.sent(), [4, t.remove("sendbird:".concat(this._sdkState.userId, "@groupchannel/").concat(e, "/message/changelogs.meta"))];
            case 5:
              n.sent(), n.label = 6;
            case 6:
              return [2];
          }
        });
      });
    }, t.prototype.removeUnderOffset = function (e, t) {
      return U(this, void 0, void 0, function () {
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return this.localCacheEnabled ? [4, this.collection.removeIf({
                where: {
                  channelUrl: e,
                  createdAt: {
                    "<": t
                  }
                },
                index: Ds(Ps.CHANNEL_LATEST)
              })] : [3, 2];
            case 1:
              n.sent(), n.label = 2;
            case 2:
              return [2];
          }
        });
      });
    }, t.prototype.clear = function () {
      return U(this, void 0, void 0, function () {
        return A(this, function (e) {
          switch (e.label) {
            case 0:
              return this.localCacheEnabled ? [4, this.collection.clear()] : [3, 2];
            case 1:
              e.sent(), e.label = 2;
            case 2:
              return [2];
          }
        });
      });
    }, t.prototype.countBetween = function (e, t, n) {
      return U(this, void 0, void 0, function () {
        var r,
          i = this;
        return A(this, function (s) {
          switch (s.label) {
            case 0:
              return this.localCacheEnabled ? (r = Ds(Ps.CHANNEL_LATEST), [4, this.collection.query({
                where: {
                  channelUrl: e,
                  "/where": function (e) {
                    var r = i._deserialize(e);
                    return n.includes(r.createdAt) && t.match(r);
                  }
                },
                index: r
              }).count()]) : [3, 2];
            case 1:
              return [2, s.sent()];
            case 2:
              return [2, 0];
          }
        });
      });
    }, t.prototype.saveBlobs = function (e) {
      return U(this, void 0, void 0, function () {
        var t = this;
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return [4, Promise.all(e.map(function (e) {
                return U(t, void 0, void 0, function () {
                  var t, n;
                  return A(this, function (r) {
                    switch (r.label) {
                      case 0:
                        return e instanceof At && e.messageParams && (t = e.messageParams).file && cn(t.file) ? [4, this.collection.saveBlob(t.file, e.reqId)] : [3, 2];
                      case 1:
                        n = r.sent(), t.fileKey = n, r.label = 2;
                      case 2:
                        return [2];
                    }
                  });
                });
              }))];
            case 1:
              return n.sent(), [2];
          }
        });
      });
    }, t.prototype._getGroupChannelPreferenceSize = function (e) {
      return U(this, void 0, void 0, function () {
        var t, n, r, i, s, a;
        return A(this, function (o) {
          switch (o.label) {
            case 0:
              return t = 0, [4, (n = this._cacheContext.preference).get("sendbird:".concat(this._sdkState.userId, "@groupchannel/").concat(e, "/message/sync"))];
            case 1:
              return r = o.sent(), [4, n.get("sendbird:".concat(this._sdkState.userId, "@groupchannel/").concat(e, "/message/sync.meta"))];
            case 2:
              return i = o.sent(), [4, n.get("sendbird:".concat(this._sdkState.userId, "@groupchannel/").concat(e, "/message/changelogs"))];
            case 3:
              return s = o.sent(), [4, n.get("sendbird:".concat(this._sdkState.userId, "@groupchannel/").concat(e, "/message/changelogs.meta"))];
            case 4:
              return a = o.sent(), r && (t += JSON.stringify(r).length), i && (t += JSON.stringify(i).length), s && (t += JSON.stringify(s).length), a && (t += JSON.stringify(a).length), [2, t];
          }
        });
      });
    }, t;
  }(l),
  Ws = {},
  Ys = function (e) {
    function t(t) {
      var n = e.call(this, t) || this;
      return Ws[t] = n, n;
    }
    return r(t, e), Object.defineProperty(t.prototype, "_sdkState", {
      get: function () {
        return n.of(this._iid).sdkState;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t.prototype, "_cacheContext", {
      get: function () {
        return n.of(this._iid).cacheContext;
      },
      enumerable: !1,
      configurable: !0
    }), t.of = function (e, n) {
      return void 0 === n && (n = !1), Ws[e] && !n || (Ws[e] = new t(e)), Ws[e];
    }, Object.defineProperty(t.prototype, "collection", {
      get: function () {
        var e = this._cacheContext.nestdb;
        return D(!!e).throw(O.databaseError), e.collection(Zr);
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t.prototype, "localCacheEnabled", {
      get: function () {
        return this._cacheContext.localCacheEnabled && !!this.collection;
      },
      enumerable: !1,
      configurable: !0
    }), t.prototype._serialize = function (e) {
      return u({}, e.serialize());
    }, t.prototype._deserialize = function (e) {
      return ra.of(this._iid).buildMessageFromSerializedData(e);
    }, t.prototype.get = function (e) {
      return U(this, void 0, void 0, function () {
        var t;
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return this.localCacheEnabled ? [4, this.collection.getByKey(e)] : [3, 2];
            case 1:
              if (t = n.sent()) return [2, this._deserialize(t)];
              n.label = 2;
            case 2:
              return [2, void 0];
          }
        });
      });
    }, t.prototype.fetch = function (e) {
      var t = e.channelUrl,
        n = e.token,
        r = e.limit,
        i = void 0 === r ? 100 : r,
        s = e.filter,
        a = void 0 === s ? new Rs() : s,
        o = e.backward,
        u = void 0 !== o && o,
        c = e.exactMatch,
        l = void 0 !== c && c,
        d = e.inclusive,
        h = void 0 === d || d;
      return U(this, void 0, void 0, function () {
        var e,
          r = this;
        return A(this, function (s) {
          switch (s.label) {
            case 0:
              return this.localCacheEnabled ? (e = {
                where: {
                  channelUrl: t,
                  "/where": function (e) {
                    if (n) {
                      if (l && e.createdAt !== n) return !1;
                      if (u) {
                        if (h && e.createdAt < n || !h && e.createdAt <= n) return !1;
                      } else if (h && e.createdAt > n || !h && e.createdAt >= n) return !1;
                    }
                    return a.match(r._deserialize(e));
                  }
                },
                index: ["channelUrl", "-createdAt", "-notificationId"],
                backward: u
              }, [4, this.collection.query(e)]) : [3, 3];
            case 1:
              return [4, s.sent().fetch({
                limit: null != i ? i : void 0
              })];
            case 2:
              return [2, s.sent().map(function (e) {
                return r._deserialize(e);
              })];
            case 3:
              return [2, []];
          }
        });
      });
    }, t.prototype.upsert = function (e) {
      return U(this, void 0, void 0, function () {
        var t,
          n = this;
        return A(this, function (r) {
          switch (r.label) {
            case 0:
              return this.localCacheEnabled ? (t = e.map(function (e) {
                return n._serialize(e);
              }), [4, this.collection.upsertMany(t)]) : [3, 2];
            case 1:
              r.sent(), r.label = 2;
            case 2:
              return [2];
          }
        });
      });
    }, t.prototype.remove = function (e) {
      return U(this, void 0, void 0, function () {
        var t, n, r, i, s, a;
        return A(this, function (o) {
          switch (o.label) {
            case 0:
              if (!this.localCacheEnabled) return [3, 8];
              o.label = 1;
            case 1:
              o.trys.push([1, 6, 7, 8]), t = v(e), n = t.next(), o.label = 2;
            case 2:
              return n.done ? [3, 5] : (r = n.value, [4, this.collection.remove(r)]);
            case 3:
              o.sent(), o.label = 4;
            case 4:
              return n = t.next(), [3, 2];
            case 5:
              return [3, 8];
            case 6:
              return i = o.sent(), s = {
                error: i
              }, [3, 8];
            case 7:
              try {
                n && !n.done && (a = t.return) && a.call(t);
              } finally {
                if (s) throw s.error;
              }
              return [7];
            case 8:
              return [2];
          }
        });
      });
    }, t.prototype.markAsReadByTimestamp = function (e, t) {
      return U(this, void 0, void 0, function () {
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return this.localCacheEnabled ? [4, this.collection.updateIf({
                where: {
                  channelUrl: e,
                  messageStatus: mt.SENT,
                  createdAt: {
                    "<": t
                  }
                }
              }, {
                set: {
                  messageStatus: mt.READ
                }
              })] : [3, 2];
            case 1:
              n.sent(), n.label = 2;
            case 2:
              return [2];
          }
        });
      });
    }, t.prototype.markAsReadByMessageIds = function (e, t) {
      return U(this, void 0, void 0, function () {
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return this.localCacheEnabled ? [4, this.collection.updateIf({
                where: {
                  channelUrl: e,
                  notificationId: {
                    "/in": t
                  },
                  messageStatus: mt.SENT
                }
              }, {
                set: {
                  messageStatus: mt.READ
                }
              })] : [3, 2];
            case 1:
              return [2, n.sent().length];
            case 2:
              return [2, 0];
          }
        });
      });
    }, t.prototype.removeMessagesOfChannel = function (e) {
      return U(this, void 0, void 0, function () {
        var t;
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return this.localCacheEnabled ? [4, this.collection.removeIf({
                where: {
                  channelUrl: e
                },
                index: ["channelUrl", "-createdAt", "-notificationId"]
              })] : [3, 6];
            case 1:
              return n.sent(), [4, (t = this._cacheContext.preference).remove("sendbird:".concat(this._sdkState.userId, "@groupchannel/").concat(e, "/message/sync"))];
            case 2:
              return n.sent(), [4, t.remove("sendbird:".concat(this._sdkState.userId, "@groupchannel/").concat(e, "/message/sync.meta"))];
            case 3:
              return n.sent(), [4, t.remove("sendbird:".concat(this._sdkState.userId, "@groupchannel/").concat(e, "/message/changelogs"))];
            case 4:
              return n.sent(), [4, t.remove("sendbird:".concat(this._sdkState.userId, "@groupchannel/").concat(e, "/message/changelogs.meta"))];
            case 5:
              n.sent(), n.label = 6;
            case 6:
              return [2];
          }
        });
      });
    }, t.prototype.removeUnderOffset = function (e, t) {
      return U(this, void 0, void 0, function () {
        return A(this, function (n) {
          switch (n.label) {
            case 0:
              return this.localCacheEnabled ? [4, this.collection.removeIf({
                where: {
                  channelUrl: e,
                  createdAt: {
                    "<": t
                  }
                },
                index: Ds(Ps.CHANNEL_LATEST)
              })] : [3, 2];
            case 1:
              n.sent(), n.label = 2;
            case 2:
              return [2];
          }
        });
      });
    }, t.prototype.clear = function () {
      return U(this, void 0, void 0, function () {
        return A(this, function (e) {
          switch (e.label) {
            case 0:
              return this.localCacheEnabled ? [4, this.collection.clear()] : [3, 2];
            case 1:
              e.sent(), e.label = 2;
            case 2:
              return [2];
          }
        });
      });
    }, t.prototype.countBetween = function (e, t, n) {
      return U(this, void 0, void 0, function () {
        var r,
          i = this;
        return A(this, function (s) {
          switch (s.label) {
            case 0:
              return this.localCacheEnabled ? (r = ["channelUrl", "-createdAt", "-notificationId"], [4, this.collection.query({
                where: {
                  channelUrl: e,
                  "/where": function (e) {
                    var r = i._deserialize(e);
                    return n.includes(r.createdAt) && t.match(r);
                  }
                },
                index: r
              }).count()]) : [3, 2];
            case 1:
              return [2, s.sent()];
            case 2:
              return [2, 0];
          }
        });
      });
    }, t;
  }(l),
  Js = {},
  Xs = function (e) {
    function t(t) {
      var n = e.call(this, t) || this;
      return n._observers = new Map(), n._dispatcherContext = n._dispatcher.on(function (e) {
        return U(n, void 0, void 0, function () {
          var t,
            n,
            r,
            i,
            s,
            a,
            o,
            u,
            c,
            l,
            d,
            h,
            f,
            p,
            m,
            v,
            y,
            _,
            g,
            b = this;
          return A(this, function (E) {
            switch (E.label) {
              case 0:
                return e instanceof N ? (t = e.messages, g = e.source, a = e.isWebSocketEventComing, n = t.filter(function (e) {
                  return e instanceof vt && e.channelType !== I.OPEN && e.messageId > 0;
                }), r = t.filter(function (e) {
                  return e instanceof vt && e.channelType !== I.OPEN && 0 === e.messageId;
                }), i = t.filter(function (e) {
                  return e instanceof Mt;
                }), n.length > 0 ? [4, Ie(function () {
                  return U(b, void 0, void 0, function () {
                    return A(this, function (e) {
                      switch (e.label) {
                        case 0:
                          return [4, this._messageCache.upsert(n)];
                        case 1:
                          return e.sent(), [4, this._unsentMessageCache.remove(n.map(function (e) {
                            return e instanceof yt ? e.reqId : null;
                          }).filter(function (e) {
                            return null !== e;
                          }))];
                        case 2:
                          return e.sent(), [2];
                      }
                    });
                  });
                })] : [3, 2]) : [3, 7];
              case 1:
                E.sent(), a || this._broadcastUpdateEvent(n, g), E.label = 2;
              case 2:
                return r.length > 0 ? [4, Ie(function () {
                  return U(b, void 0, void 0, function () {
                    return A(this, function (e) {
                      switch (e.label) {
                        case 0:
                          return [4, this._unsentMessageCache.upsert(r)];
                        case 1:
                          return e.sent(), [2];
                      }
                    });
                  });
                })] : [3, 4];
              case 3:
                E.sent(), a || this._broadcastUpdateEvent(r, g), E.label = 4;
              case 4:
                return i.length > 0 ? [4, Ie(function () {
                  return U(b, void 0, void 0, function () {
                    return A(this, function (e) {
                      switch (e.label) {
                        case 0:
                          return [4, this._notificationCache.upsert(i)];
                        case 1:
                          return e.sent(), [2];
                      }
                    });
                  });
                })] : [3, 6];
              case 5:
                E.sent(), a || this._broadcastUpdateEvent(i, g), E.label = 6;
              case 6:
                return [3, 24];
              case 7:
                return e instanceof Me ? (s = e.messageIds, g = e.source, a = e.isWebSocketEventComing, o = s.filter(function (e) {
                  return "number" == typeof e;
                }), u = s.filter(function (e) {
                  return "string" == typeof e;
                }), [4, Ie(function () {
                  return U(b, void 0, void 0, function () {
                    return A(this, function (e) {
                      switch (e.label) {
                        case 0:
                          return o.length > 0 ? [4, this._messageCache.remove(o)] : [3, 2];
                        case 1:
                          e.sent(), e.label = 2;
                        case 2:
                          return u.length > 0 ? [4, this._notificationCache.remove(u)] : [3, 4];
                        case 3:
                          e.sent(), e.label = 4;
                        case 4:
                          return [2];
                      }
                    });
                  });
                })]) : [3, 9];
              case 8:
                return E.sent(), a || this._broadcastRemoveEvent(s, g), [3, 24];
              case 9:
                return e instanceof we ? (c = e.reqId, g = e.source, [4, Ie(function () {
                  return U(b, void 0, void 0, function () {
                    return A(this, function (e) {
                      switch (e.label) {
                        case 0:
                          return [4, this._unsentMessageCache.remove([c])];
                        case 1:
                          return e.sent(), [2];
                      }
                    });
                  });
                })]) : [3, 11];
              case 10:
                return E.sent(), this._broadcastRemoveUnsentEvent(c, g), [3, 24];
              case 11:
                return e instanceof Te ? (l = e.polls, g = e.source, this._cacheContext.localCacheEnabled ? (d = l.map(function (e) {
                  return e.messageId;
                }), [4, Promise.all(d.map(function (e) {
                  return b._messageCache.get(e);
                }))]) : [3, 14]) : [3, 15];
              case 12:
                return (h = E.sent().filter(function (e) {
                  return e;
                })).length > 0 && l.forEach(function (e) {
                  var t = h.find(function (t) {
                    return t.messageId === e.messageId;
                  });
                  t && t.applyPoll(e);
                }), [4, Ie(function () {
                  return U(b, void 0, void 0, function () {
                    return A(this, function (e) {
                      switch (e.label) {
                        case 0:
                          return [4, this._messageCache.upsert(h)];
                        case 1:
                          return [2, e.sent()];
                      }
                    });
                  });
                })];
              case 13:
                E.sent(), E.label = 14;
              case 14:
                return this._broadcastPollChangeLogEvent(l, g), [3, 24];
              case 15:
                return e instanceof Ee ? (f = e.event, g = e.source, [4, this._messageCache.get(f.messageId)]) : [3, 19];
              case 16:
                return (p = E.sent()) && p.isUserMessage() && p.poll && p.poll.applyPollUpdateEvent(f) ? [4, Ie(function () {
                  return U(b, void 0, void 0, function () {
                    return A(this, function (e) {
                      switch (e.label) {
                        case 0:
                          return [4, this._messageCache.upsert([p])];
                        case 1:
                          return [2, e.sent()];
                      }
                    });
                  });
                })] : [3, 18];
              case 17:
                E.sent(), E.label = 18;
              case 18:
                return this._broadcastPollUpdateEvent(f, g), [3, 24];
              case 19:
                return e instanceof fe ? (m = e.event, g = e.source, [4, this._messageCache.get(m.messageId)]) : [3, 23];
              case 20:
                return (v = E.sent()) && v.isUserMessage() && v.poll && v.poll.applyPollVoteEvent(m) ? [4, Ie(function () {
                  return U(b, void 0, void 0, function () {
                    return A(this, function (e) {
                      switch (e.label) {
                        case 0:
                          return [4, this._messageCache.upsert([v])];
                        case 1:
                          return [2, e.sent()];
                      }
                    });
                  });
                })] : [3, 22];
              case 21:
                E.sent(), E.label = 22;
              case 22:
                return this._broadcastPollVoteEvent(m, g), [3, 24];
              case 23:
                e instanceof ge ? (y = e.event, g = e.source, y.messageId > 0 && this._broadcastReactionUpdateEvent(y, g)) : e instanceof be && (_ = e.event, g = e.source, this._broadcastThreadUpdateEvent(_, g)), E.label = 24;
              case 24:
                return [2];
            }
          });
        });
      }), n;
    }
    return r(t, e), Object.defineProperty(t.prototype, "_cacheContext", {
      get: function () {
        return n.of(this._iid).cacheContext;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t.prototype, "_dispatcher", {
      get: function () {
        return n.of(this._iid).dispatcher;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t.prototype, "_messageCache", {
      get: function () {
        return Hs.of(this._iid);
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t.prototype, "_unsentMessageCache", {
      get: function () {
        return Gs.of(this._iid);
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t.prototype, "_notificationCache", {
      get: function () {
        return Ys.of(this._iid);
      },
      enumerable: !1,
      configurable: !0
    }), t.of = function (e, n) {
      var r;
      return void 0 === n && (n = !1), Js[e] && !n || (Js[e] && (null === (r = Js[e]._dispatcherContext) || void 0 === r || r.close()), Js[e] = new t(e)), Js[e];
    }, t.prototype._broadcastUpdateEvent = function (e, t) {
      var n, r;
      try {
        for (var i = v(this._observers.values()), s = i.next(); !s.done; s = i.next()) {
          var a = s.value;
          a.onUpdate && a.onUpdate(e, t);
        }
      } catch (e) {
        n = {
          error: e
        };
      } finally {
        try {
          s && !s.done && (r = i.return) && r.call(i);
        } finally {
          if (n) throw n.error;
        }
      }
    }, t.prototype._broadcastPollChangeLogEvent = function (e, t) {
      var n, r;
      try {
        for (var i = v(this._observers.values()), s = i.next(); !s.done; s = i.next()) {
          var a = s.value;
          a.onPollChangeLogUpdate && a.onPollChangeLogUpdate(e, t);
        }
      } catch (e) {
        n = {
          error: e
        };
      } finally {
        try {
          s && !s.done && (r = i.return) && r.call(i);
        } finally {
          if (n) throw n.error;
        }
      }
    }, t.prototype._broadcastPollUpdateEvent = function (e, t) {
      var n, r;
      try {
        for (var i = v(this._observers.values()), s = i.next(); !s.done; s = i.next()) {
          var a = s.value;
          a.onPollUpdate && a.onPollUpdate(e, t);
        }
      } catch (e) {
        n = {
          error: e
        };
      } finally {
        try {
          s && !s.done && (r = i.return) && r.call(i);
        } finally {
          if (n) throw n.error;
        }
      }
    }, t.prototype._broadcastPollVoteEvent = function (e, t) {
      var n, r;
      try {
        for (var i = v(this._observers.values()), s = i.next(); !s.done; s = i.next()) {
          var a = s.value;
          a.onPollVote && a.onPollVote(e, t);
        }
      } catch (e) {
        n = {
          error: e
        };
      } finally {
        try {
          s && !s.done && (r = i.return) && r.call(i);
        } finally {
          if (n) throw n.error;
        }
      }
    }, t.prototype._broadcastRemoveEvent = function (e, t) {
      var n, r;
      try {
        for (var i = v(this._observers.values()), s = i.next(); !s.done; s = i.next()) {
          var a = s.value;
          a.onRemove && a.onRemove(e, t);
        }
      } catch (e) {
        n = {
          error: e
        };
      } finally {
        try {
          s && !s.done && (r = i.return) && r.call(i);
        } finally {
          if (n) throw n.error;
        }
      }
    }, t.prototype._broadcastRemoveUnsentEvent = function (e, t) {
      var n, r;
      try {
        for (var i = v(this._observers.values()), s = i.next(); !s.done; s = i.next()) {
          var a = s.value;
          a.onRemoveUnsent && a.onRemoveUnsent(e, t);
        }
      } catch (e) {
        n = {
          error: e
        };
      } finally {
        try {
          s && !s.done && (r = i.return) && r.call(i);
        } finally {
          if (n) throw n.error;
        }
      }
    }, t.prototype._broadcastReactionUpdateEvent = function (e, t) {
      var n, r;
      try {
        for (var i = v(this._observers.values()), s = i.next(); !s.done; s = i.next()) {
          var a = s.value;
          a.onReactionUpdate && a.onReactionUpdate(e, t);
        }
      } catch (e) {
        n = {
          error: e
        };
      } finally {
        try {
          s && !s.done && (r = i.return) && r.call(i);
        } finally {
          if (n) throw n.error;
        }
      }
    }, t.prototype._broadcastThreadUpdateEvent = function (e, t) {
      var n, r;
      try {
        for (var i = v(this._observers.values()), s = i.next(); !s.done; s = i.next()) {
          var a = s.value;
          a.onThreadInfoUpdate && a.onThreadInfoUpdate(e, t);
        }
      } catch (e) {
        n = {
          error: e
        };
      } finally {
        try {
          s && !s.done && (r = i.return) && r.call(i);
        } finally {
          if (n) throw n.error;
        }
      }
    }, t.prototype.subscribe = function (e, t) {
      this._observers.set(e, t);
    }, t.prototype.unsubscribe = function (e) {
      this._observers.delete(e);
    }, t.prototype.unsubscribeAll = function () {
      this._observers.clear();
    }, t;
  }(l),
  Zs = function (t) {
    function n(n) {
      var r = this,
        s = n.reverse,
        a = void 0 !== s && s,
        o = n.limit,
        u = void 0 === o ? 20 : o,
        c = n.keys,
        l = n.token;
      return (r = t.call(this) || this).method = i.GET, r.path = "".concat(Se), r.params = e({
        token: l,
        limit: u,
        reverse: a,
        order: Ue.UPDATED_AT,
        show_ui_template: !0,
        show_color_variables: !0,
        keys: c
      }), r;
    }
    return r(n, t), n;
  }(a),
  $s = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this,
        i = n.next,
        s = n.has_more,
        a = void 0 !== s && s,
        o = n.templates;
      return r.nextToken = i, r.hasMore = a, r.templates = o.map(function (e) {
        return {
          template: JSON.stringify(e)
        };
      }), r;
    }
    return r(t, e), t;
  }(o),
  ea = function (e) {
    function t(t) {
      var n = this,
        r = t.key;
      return (n = e.call(this) || this).method = i.GET, n.path = "".concat(Se, "/").concat(r), n;
    }
    return r(t, e), t;
  }(a),
  ta = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.template = JSON.stringify(n), r;
    }
    return r(t, e), t;
  }(o),
  na = {},
  ra = function () {
    function n(e, t) {
      var n = t.sdkState,
        r = t.dispatcher,
        i = t.requestQueue,
        s = t.onlineDetector,
        a = t.cacheContext;
      this._iid = e, this._sdkState = n, this._requestQueue = i, this._dispatcher = r, this._cacheContext = a, Hs.of(e), Gs.of(e), Xs.of(e), this.fileMessageQueue = new Dt(e, {
        sdkState: n,
        dispatcher: r,
        requestQueue: i,
        onlineDetector: s,
        cacheContext: a
      }), na[e] = this;
    }
    return n.of = function (e) {
      return na[e];
    }, n.prototype.buildMessageFromSerializedData = function (e) {
      var t = Ae(e);
      if (t.notificationId) return new Mt(this._iid, Mt.payloadify(t));
      switch (t.messageType) {
        case g.USER:
          return new Tt(this._iid, Tt.payloadify(t));
        case g.FILE:
          return wt._isMultipleFilesMessageSerializedData(t) ? new wt(this._iid, wt.payloadify(t)) : new At(this._iid, At.payloadify(t));
        case g.ADMIN:
          return new bt(this._iid, bt.payloadify(t));
      }
      throw O.invalidParameters;
    }, n.prototype.buildUserMessageCreateParamsFromSerializedData = function (n, r) {
      return e(t({
        data: r.data,
        customType: r.customType,
        mentionType: r.mentionType,
        mentionedUserIds: r.mentionedUserIds,
        mentionedUsers: r.mentionedUsers,
        mentionedMessageTemplate: r.mentionedMessageTemplate,
        metaArrays: r.metaArrays,
        parentMessageId: r.parentMessageId,
        isReplyToChannel: n.isReplyToChannel,
        pushNotificationDeliveryOption: n.pushNotificationDeliveryOption,
        appleCriticalAlertOptions: r.appleCriticalAlertOptions,
        reqId: r.reqId,
        message: r.message,
        translationTargetLanguages: Object.keys(r.translations),
        pollId: n.pollId
      }));
    }, n.prototype.buildFileMessageCreateParamsFromSerializedData = function (n, r) {
      var i;
      return e(t({
        data: r.data,
        customType: r.customType,
        mentionType: r.mentionType,
        mentionedUserIds: r.mentionedUserIds,
        mentionedUsers: r.mentionedUsers,
        mentionedMessageTemplate: r.mentionedMessageTemplate,
        metaArrays: r.metaArrays,
        parentMessageId: r.parentMessageId,
        isReplyToChannel: n.isReplyToChannel,
        pushNotificationDeliveryOption: n.pushNotificationDeliveryOption,
        appleCriticalAlertOptions: r.appleCriticalAlertOptions,
        reqId: r.reqId,
        file: n.file,
        fileKey: n.fileKey,
        fileUrl: r.plainUrl,
        fileName: r.name,
        fileSize: r.size,
        mimeType: r.type,
        thumbnailSizes: null === (i = r.thumbnails) || void 0 === i ? void 0 : i.map(function (e) {
          return {
            maxWidth: e.width,
            maxHeight: e.height
          };
        }),
        fileType: n.fileType,
        requireAuth: r.requireAuth
      }));
    }, n.prototype.buildMultipleFilesMessageCreateParamsFromSerializedData = function (t, n) {
      var r = this;
      return e({
        data: n.data,
        customType: n.customType,
        mentionType: n.mentionType,
        mentionedUserIds: n.mentionedUserIds,
        mentionedUsers: n.mentionedUsers,
        mentionedMessageTemplate: n.mentionedMessageTemplate,
        metaArrays: n.metaArrays,
        parentMessageId: n.parentMessageId,
        isReplyToChannel: t.isReplyToChannel,
        pushNotificationDeliveryOption: t.pushNotificationDeliveryOption,
        appleCriticalAlertOptions: n.appleCriticalAlertOptions,
        reqId: n.reqId,
        fileInfoList: t.fileInfoList.map(function (e) {
          return new Nt(r._iid, e);
        })
      });
    }, n.prototype.buildScheduledUserMessageCreateParamsFromSerializedData = function (e, t) {
      return u(u({}, this.buildUserMessageCreateParamsFromSerializedData(e, t)), {
        scheduledAt: e.scheduledAt
      });
    }, n.prototype.buildScheduledFileMessageCreateParamsFromSerializedData = function (e, t) {
      return u(u({}, this.buildFileMessageCreateParamsFromSerializedData(e, t)), {
        scheduledAt: e.scheduledAt
      });
    }, n.prototype.buildSenderFromSerializedData = function (e) {
      var t = Ae(e);
      return new Qe(this._iid, Qe.payloadify(t));
    }, n.prototype.getMessage = function (e) {
      return U(this, void 0, void 0, function () {
        var t, n;
        return A(this, function (r) {
          switch (r.label) {
            case 0:
              return t = new jt(e), [4, this._requestQueue.send(t)];
            case 1:
              return n = r.sent(), [2, n.as(Kt).message];
          }
        });
      });
    }, n.prototype.getScheduledMessage = function (e) {
      return U(this, void 0, void 0, function () {
        var t, n;
        return A(this, function (r) {
          switch (r.label) {
            case 0:
              return t = new Wt(e), [4, this._requestQueue.send(t)];
            case 1:
              return n = r.sent(), [2, n.as(Yt).message];
          }
        });
      });
    }, n.prototype.getMessagesByMessageId = function (e, t, n, r, i) {
      return void 0 === i && (i = C.REQUEST_MESSAGE), U(this, void 0, void 0, function () {
        var s, a, o;
        return A(this, function (c) {
          switch (c.label) {
            case 0:
              return s = new Qt(u(u({
                channelType: t,
                channelUrl: e,
                token: String(n)
              }, Ft), r)), [4, this._requestQueue.send(s)];
            case 1:
              return a = c.sent(), o = a.as(Gt).messages, this._dispatcher.dispatch(new N({
                messages: o,
                source: i
              })), [2, o];
          }
        });
      });
    }, n.prototype.getMessagesByTimestamp = function (e, t, n, r, i) {
      return void 0 === i && (i = C.REQUEST_MESSAGE), U(this, void 0, void 0, function () {
        var s, a, o;
        return A(this, function (c) {
          switch (c.label) {
            case 0:
              return s = new Qt(u(u({
                channelType: t,
                channelUrl: e,
                timestamp: n
              }, Ft), r)), [4, this._requestQueue.send(s)];
            case 1:
              return a = c.sent(), o = a.as(Gt).messages, this._dispatcher.dispatch(new N({
                messages: o,
                source: i
              })), [2, o];
          }
        });
      });
    }, n.prototype._getMessagesByTimestampForCollection = function (e, t, n, r, i, s, a) {
      return void 0 === i && (i = C.REQUEST_MESSAGE), U(this, void 0, void 0, function () {
        var o, c, l, d, h, f;
        return A(this, function (p) {
          switch (p.label) {
            case 0:
              return o = new Qt(u(u(u({
                channelType: t,
                channelUrl: e,
                timestamp: n
              }, Ft), r), {
                checkingHasNext: s,
                checkingContinuousMessages: a
              })), [4, this._requestQueue.send(o)];
            case 1:
              return c = p.sent(), l = c.as(Gt), d = l.messages, h = l.isContinuousMessages, f = l.hasNext, this._dispatcher.dispatch(new N({
                messages: d,
                source: i
              })), [2, {
                messages: d,
                isContinuousMessages: h,
                hasNext: f
              }];
          }
        });
      });
    }, n.prototype.getThreadedMessagesByTimestamp = function (e, t, n, r) {
      return void 0 === r && (r = C.REQUEST_THREADED_MESSAGE), U(this, void 0, void 0, function () {
        var i, s, a, o;
        return A(this, function (c) {
          switch (c.label) {
            case 0:
              return i = new Qt(u(u(u({
                channelUrl: e.channelUrl,
                channelType: e.channelType,
                timestamp: t
              }, _t), n), {
                replyType: H.ALL,
                parentMessageId: e.messageId,
                includeThreadInfo: !0
              })), [4, this._requestQueue.send(i)];
            case 1:
              return s = c.sent(), a = s.as(Gt).messages, (o = a.slice(1)).forEach(function (t) {
                t.parentMessage = e;
              }), this._dispatcher.dispatch(new N({
                messages: o,
                source: r
              })), [2, {
                parentMessage: a[0],
                threadedMessages: o
              }];
          }
        });
      });
    }, n.prototype.getMessageTemplatesByToken = function (e, t) {
      return void 0 === t && (t = {}), U(this, void 0, void 0, function () {
        var n, r, i, s, a, o;
        return A(this, function (c) {
          switch (c.label) {
            case 0:
              return n = new Zs(u({
                token: e
              }, t)), [4, this._requestQueue.send(n)];
            case 1:
              return r = c.sent(), i = r.as($s), s = i.hasMore, a = i.nextToken, o = i.templates, [2, {
                hasMore: s,
                token: a,
                templates: o
              }];
          }
        });
      });
    }, n.prototype.getMessageTemplate = function (e) {
      return U(this, void 0, void 0, function () {
        var t, n;
        return A(this, function (r) {
          switch (r.label) {
            case 0:
              return t = new ea({
                key: e
              }), [4, this._requestQueue.send(t)];
            case 1:
              return n = r.sent(), [2, {
                template: n.as(ta).template
              }];
          }
        });
      });
    }, n.prototype.getMessageChangelogs = function (n, r, i, s, a) {
      return void 0 === a && (a = C.REQUEST_MESSAGE_CHANGELOGS), U(this, void 0, void 0, function () {
        var o, c, l, d, h, f, p, m, v;
        return A(this, function (y) {
          switch (y.label) {
            case 0:
              return o = new Vt(e(t(u(u({
                channelType: r,
                channelUrl: n,
                timestamp: "number" == typeof i ? i : null,
                token: "string" == typeof i ? i : null
              }, zt), s)))), [4, this._requestQueue.send(o)];
            case 1:
              return c = y.sent(), l = c.as(Ht), d = l.updatedMessages, h = l.deletedMessagesInfo, f = l.hasMore, p = l.nextToken, m = l.forceUseNextToken, v = h.map(function (e) {
                return e.messageId;
              }), d.length > 0 && this._dispatcher.dispatch(new N({
                messages: d,
                source: a
              })), v.length > 0 && this._dispatcher.dispatch(new Me({
                messageIds: v,
                source: a
              })), [2, {
                updatedMessages: d,
                deletedMessageIds: v,
                hasMore: f,
                token: p,
                forceUseNextToken: m
              }];
          }
        });
      });
    }, n;
  }(),
  ia = function (e) {
    function t(t, n) {
      var r = e.call(this, t) || this;
      r.targetMessageId = 0;
      var i = n.thread_info,
        s = n.parent_message_id,
        a = n.channel_url,
        o = n.channel_type;
      return i && h("object", i) && h("number", s) && h("string", a) && h("string", o) && (r.threadInfo = new xe(t, i), r.targetMessageId = s, r.channelUrl = a, r.channelType = o), r;
    }
    return r(t, e), t;
  }(l),
  sa = {
    channelUrl: "",
    channelType: I.BASE,
    messageId: 0,
    includeReactions: !1,
    includeMetaArray: !1,
    includeParentMessageInfo: !1,
    includeThreadInfo: !1
  },
  aa = function (e) {
    function t(t) {
      var n = t.channelCustomType,
        r = t.keyword,
        s = t.limit,
        a = t.reverse,
        o = t.exactMatch,
        u = t.channelUrl,
        c = t.order,
        l = t.messageTimestampFrom,
        d = t.messageTimestampTo,
        h = t.advancedQuery,
        f = t.targetFields,
        p = t.nextToken,
        m = e.call(this) || this;
      return m.method = i.GET, m.path = "".concat(Ne, "/messages"), m.params = {
        custom_type: n,
        query: r,
        limit: s,
        reverse: a,
        exact_match: o,
        channel_url: u,
        message_ts_from: l,
        message_ts_to: d,
        sort_field: c,
        advanced_query: h,
        target_fields: f,
        after: p
      }, m;
    }
    return r(t, e), t;
  }(a),
  oa = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.messages = n.results.map(function (e) {
        return Ut(t, e);
      }), r.hasNext = n.has_next, r.nextToken = n.end_cursor, r.totalCount = n.total_count, r;
    }
    return r(t, e), t;
  }(o);
!function (e) {
  e.SCORE = "score", e.TIMESTAMP = "ts";
}(xs || (xs = {}));
var ua = function (e) {
    function i(t, n) {
      var r,
        i,
        s,
        a,
        o,
        u,
        c,
        l,
        d,
        h = this;
      return (h = e.call(this, t, n) || this).keyword = "", h.reverse = !1, h.exactMatch = !1, h.channelUrl = "", h.channelCustomType = "", h.messageTimestampFrom = null, h.messageTimestampTo = null, h.order = xs.SCORE, h.advancedQuery = !1, h.targetFields = null, h._nextToken = "", h.totalCount = -1, h.keyword = n.keyword, h.reverse = null !== (r = n.reverse) && void 0 !== r && r, h.exactMatch = null !== (i = n.exactMatch) && void 0 !== i && i, h.channelUrl = null !== (s = n.channelUrl) && void 0 !== s ? s : "", h.channelCustomType = null !== (a = n.channelCustomType) && void 0 !== a ? a : "", h.messageTimestampFrom = null !== (o = n.messageTimestampFrom) && void 0 !== o ? o : null, h.messageTimestampTo = null !== (u = n.messageTimestampTo) && void 0 !== u ? u : null, h.order = null !== (c = n.order) && void 0 !== c ? c : xs.SCORE, h.advancedQuery = null !== (l = n.advancedQuery) && void 0 !== l && l, h.targetFields = null !== (d = n.targetFields) && void 0 !== d ? d : null, h;
    }
    return r(i, e), i.prototype._validate = function () {
      return e.prototype._validate.call(this) && h("string", this.keyword) && this.keyword.length > 0 && h("boolean", this.reverse) && h("boolean", this.exactMatch) && h("string", this.channelUrl) && h("string", this.channelCustomType) && (h("number", this.messageTimestampFrom) || null === this.messageTimestampFrom) && (h("number", this.messageTimestampTo) || null === this.messageTimestampTo) && f(xs, this.order) && h("boolean", this.advancedQuery) && d("string", this.targetFields, !0);
    }, i.prototype.next = function () {
      return U(this, void 0, void 0, function () {
        var e, r, i, s, a, o, c, l;
        return A(this, function (d) {
          switch (d.label) {
            case 0:
              return this._validate() ? this._isLoading ? [3, 3] : this._hasNext ? (this._isLoading = !0, e = n.of(this._iid).requestQueue, r = new aa(t(u(u({}, this), {
                nextToken: this._nextToken ? this._nextToken : null
              }))), [4, e.send(r)]) : [3, 2] : [3, 5];
            case 1:
              return i = d.sent(), s = i.as(oa), a = s.messages, o = s.hasNext, c = s.nextToken, l = s.totalCount, this._nextToken = c, this._hasNext = o, this._isLoading = !1, this.totalCount = l, [2, a];
            case 2:
              return [2, []];
            case 3:
              throw O.queryInProgress;
            case 4:
              return [3, 6];
            case 5:
              throw O.invalidParameters;
            case 6:
              return [2];
          }
        });
      });
    }, i;
  }(ve),
  ca = function (n) {
    function s(r) {
      var s = r.channelType,
        a = r.channelUrl,
        o = r.limit,
        u = r.token,
        c = n.call(this) || this;
      return c.method = i.GET, c.path = "".concat(w(s), "/").concat(encodeURIComponent(a), "/messages/parent_thread_message"), c.params = e(t({
        limit: o,
        token: u
      })), c;
    }
    return r(s, n), s;
  }(a),
  la = function (e) {
    function t(t, n) {
      var r = e.call(this, t, n) || this;
      return r.token = n.next, r.messages = n.messages.map(function (e) {
        return Ut(t, e);
      }), r;
    }
    return r(t, e), t;
  }(o),
  da = function (e) {
    function t(t, n, r, i) {
      var s = e.call(this, t, n, r, i) || this;
      return s._edge = "", s;
    }
    return r(t, e), t.prototype._validate = function () {
      return e.prototype._validate.call(this);
    }, t.prototype.load = function () {
      return U(this, void 0, void 0, function () {
        var e, t, r, i, s, a;
        return A(this, function (o) {
          switch (o.label) {
            case 0:
              return this._validate() ? this._isLoading ? [3, 3] : this._hasNext ? (this._isLoading = !0, e = n.of(this._iid).requestQueue, t = new ca({
                channelType: this.channelType,
                channelUrl: this.channelUrl,
                token: this._edge,
                limit: this.limit
              }), [4, e.send(t)]) : [3, 2] : [3, 5];
            case 1:
              return r = o.sent(), i = r.as(la), s = i.messages, a = i.token, this._edge = a, this._hasNext = !!a, this._isLoading = !1, [2, s];
            case 2:
              return [2, []];
            case 3:
              throw O.queryInProgress;
            case 4:
              return [3, 6];
            case 5:
              throw O.invalidParameters;
            case 6:
              return [2];
          }
        });
      });
    }, t;
  }(J),
  ha = {
    channelUrl: "",
    scheduledMessageId: 0
  },
  fa = {
    reverse: !1,
    limit: 20
  },
  pa = function (e) {
    function t() {
      var t = null !== e && e.apply(this, arguments) || this;
      return t.name = "message", t;
    }
    return r(t, e), t.prototype.init = function (t, n) {
      var r = n.sdkState,
        i = n.dispatcher,
        s = n.sessionManager,
        a = n.requestQueue,
        o = n.logger,
        u = n.onlineDetector,
        c = n.cacheContext;
      e.prototype.init.call(this, t, {
        sdkState: r,
        dispatcher: i,
        sessionManager: s,
        requestQueue: a,
        logger: o,
        onlineDetector: u,
        cacheContext: c
      }), this._manager = new ra(t, {
        sdkState: r,
        dispatcher: i,
        requestQueue: a,
        onlineDetector: u,
        cacheContext: c
      });
    }, t.prototype.buildMessageFromSerializedData = function (e) {
      return this._manager.buildMessageFromSerializedData(e);
    }, t.prototype.buildSenderFromSerializedData = function (e) {
      return this._manager.buildSenderFromSerializedData(e);
    }, t.prototype.getMessage = function (e) {
      return U(this, void 0, void 0, function () {
        var t, n;
        return A(this, function (r) {
          switch (r.label) {
            case 0:
              return t = u(u({}, sa), e), D(function (e) {
                return h("string", e.channelUrl) && f(I, e.channelType) && h("number", e.messageId) && h("boolean", e.includeReactions, !0) && h("boolean", e.includeMetaArray, !0) && h("boolean", e.includeParentMessageInfo, !0) && h("boolean", e.includeThreadInfo, !0);
              }(t)).throw(O.invalidParameters), [4, this._manager.getMessage(t)];
            case 1:
              if ((n = r.sent()) instanceof vt || n instanceof Mt || null === n) return [2, n];
              throw "Unknown message type is given.";
          }
        });
      });
    }, t.prototype.getScheduledMessage = function (e) {
      return U(this, void 0, void 0, function () {
        var t;
        return A(this, function (n) {
          return t = u(u({}, ha), e), D(function (e) {
            return h("string", e.channelUrl) && "" !== e.channelUrl && h("number", e.scheduledMessageId) && e.scheduledMessageId > 0;
          }(t)).throw(O.invalidParameters), [2, this._manager.getScheduledMessage(t)];
        });
      });
    }, t.prototype.getMessageTemplatesByToken = function (e, t) {
      return void 0 === t && (t = {}), U(this, void 0, void 0, function () {
        var n;
        return A(this, function (r) {
          return n = u(u({}, fa), t), D(h("string", e, !0) && function (e) {
            return h("boolean", e.reverse, !0) && h("number", e.limit, !0) && d("string", e.keys, !0);
          }(n)).throw(O.invalidParameters), [2, this._manager.getMessageTemplatesByToken(e, n)];
        });
      });
    }, t.prototype.getMessageTemplate = function (e) {
      return U(this, void 0, void 0, function () {
        return A(this, function (t) {
          return D(h("string", e)).throw(O.invalidParameters), [2, this._manager.getMessageTemplate(e)];
        });
      });
    }, t;
  }(Ce);
export { Tt as $, As as A, Ui as B, gi as C, An as D, It as E, Ci as F, Xt as G, Jt as H, Us as I, Lt as J, Rs as K, ks as L, Ps as M, Yr as N, wn as O, Zt as P, Gr as Q, pn as R, bi as S, Ut as T, Ii as U, lt as V, ci as W, hi as X, We as Y, dt as Z, ct as _, Vr as a, Oe as a$, At as a0, ia as a1, xe as a2, $n as a3, jn as a4, dr as a5, cr as a6, or as a7, rr as a8, sr as a9, on as aA, mi as aB, mn as aC, sn as aD, fn as aE, si as aF, $t as aG, Ye as aH, Cr as aI, vi as aJ, Re as aK, ke as aL, Xs as aM, Le as aN, ni as aO, Ys as aP, Xr as aQ, mt as aR, Ze as aS, Je as aT, Et as aU, xs as aV, wt as aW, ze as aX, Be as aY, Mn as aZ, qe as a_, Qr as aa, vt as ab, Xe as ac, yt as ad, br as ae, Er as af, bt as ag, _r as ah, Pt as ai, Wr as aj, Mt as ak, ra as al, yn as am, _n as an, gn as ao, bn as ap, Pe as aq, Ge as ar, je as as, St as at, da as au, ui as av, Fs as aw, qs as ax, zs as ay, Bs as az, Hr as b, Fe as b0, Qe as b1, ut as b2, Ke as b3, De as b4, ht as b5, ft as b6, ot as b7, Jr as c, js as d, Ks as e, ei as f, Ds as g, ti as h, ri as i, ii as j, Zr as k, $r as l, Ls as m, Cs as n, ws as o, Ns as p, pa as q, wi as r, ua as s, Hs as t, Gs as u, Ss as v, Os as w, Pi as x, On as y, _i as z };